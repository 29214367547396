import React, { useState, useEffect } from 'react';
import {
	useLocation,
	useHistory
} from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { Divider, Grid, Header, Menu, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import { maxTwoDecimals } from '@/helpers/number';
import InfoMessage from '../Shared/InfoMessage';
import { runOperation, tranformSubProcessByType } from '@/features/operations/slice';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import userEvent from '@testing-library/user-event';

function Exchange(): React.ReactElement {
	const { t } = useTranslation('translations');
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const [accountId] = useState<string>(searchParams.get('account'));
	const [account, setAccount] = useState<any>(null);
	const [subProcesses, setSubProcesses] = useState<any>([]);


	const { list } = useSelector(
		(state: RootState) => state.accounts
	);
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (!list.length) {
			return history.push('/wallet');
		}
		const account = list.find(l => `${l.accountId}` === accountId);
		setAccount(account);
		setSubProcesses(tranformSubProcessByType(account.subProcesses)['FX_ORDER']);
	}, [list, accountId, history]);

	const exchangeAccounts = list.filter(accounts =>
		_.keys(accounts.subProcesses)
			.filter(key => accounts.subProcesses[key].type === 'FX_ORDER' && accounts.subProcesses[key].proc === subProcesses?.proc).length > 0) || [];
	const toAccountOptions = exchangeAccounts.filter(i => i.currency !== account?.currency)
		.map(item => ({ value: item.accountId, key: item.accountId, text: `${item.name} (${item.currency} ${item.availableBalance})` }));


	const validationSchema = Yup.object({
		amount: Yup.number().required(t('form.validator.required')).
			min(1, t('form.validator.minNumber', { field: t('sendmoneyTranslation.data.exchangeAmount'), amount: '1' }))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				(value) => value <= account.availableBalance),
		toAccount: Yup.string().required(t('form.validator.required')),
	});

	const initialValues = {
		from: account?.name + ' (' + account?.currency + ' ' + account?.availableBalance + ')',
		amount: '',
		toAccount: ''
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { toAccount, amount } = formData;

		try {
			await runOperation(subProcesses?.proc, {
				docStatus: 'NEW',
				fromAccountId: accountId,
				toAccountId: toAccount,
				changeAmount: amount,
				updatedBy: user.id
			});
			setSubmitting(false);
			showSuccessNotification(t('paymentsubmitted.data.header'));
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
			history.push('/wallet');
		}
	};
	return (
		<div id="exchangetab">
			<Grid stackable columns="equal">
				<Grid.Column width={5}>
					<Menu fluid vertical className="vertical-menu">
						<Menu.Item
							name={t('sendmoneyTranslation.data.foreignExchange')}
							active={true}
							icon="chevron right" />

					</Menu>
				</Grid.Column>
				<Grid.Column width={8}>
					<Grid stackable columns="equal" id='requestMoney'>
						<Grid.Column width={16}>
							<Segment padded>
								<Header as="h3"> {t('sendmoneyTranslation.data.foreignExchange')}</Header>
								<Divider hidden />
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={submit}
									enableReinitialize={true}
								>
									{({ isSubmitting, errors }) => (
										<Form>
											<InfoMessage>
												{t('sendmoneyTranslation.exchangeInfo')}
											</InfoMessage>
											<Input
												fluid
												id="account"
												name="from"
												label={t('sendmoneyTranslation.data.from')}
												disabled
											/>
											<Input
												fluid
												name="amount"
												label={t('sendmoneyTranslation.data.exchangeAmount')}
												placeholder={`0.00 ${account?.currency}`}
												type='number'
												errorPrompt
											/>
											<Select
												fluid
												name="toAccount"
												label={t('sendmoneyTranslation.data.to')}
												options={toAccountOptions}
												errorPrompt
											/>
											<Divider section />
											<InfoMessage>
												{t('sendmoneyTranslation.exchangeFeeInfo')}
											</InfoMessage>
											<Divider hidden />
											<SubmitButton
												disabled={isSubmitting || !isEmpty(errors)}
												primary fluid type="submit">
												{t('sendmoneyTranslation.activedata.button')}
											</SubmitButton>
										</Form>)}
								</Formik>
							</Segment>
						</Grid.Column>
					</Grid>
				</Grid.Column>
			</Grid>
		</div>);
}

export default Exchange;
