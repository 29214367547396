import { approveEmployee, getEmployeeInitials, rejectEmployee } from '@/features/employee/slice';
import { Employee } from '@/features/employee/types';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Button, Grid, Icon, List } from 'semantic-ui-react';
import ShowDocument from '@/components/Business/ShowDocument';
import EmployeeCodeModal from '@/components/Business/EmployeeCodeModal';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@helpers/globalTypes';

interface EmployeeProps {
	employee: Employee;
	clearInput: () => Promise<void>;
}

export default function PendingEmployee({ employee, clearInput }: EmployeeProps): React.ReactElement {
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [initials, setInititals] = useState<string>(null);
	const [open, setOpen] = useState<boolean>(false);
	const { t } = useTranslation('translations');

	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};

	const onApprove = async () => {
		if(status.source === Agents.DTSCB) {
			setOpen(true);
		} else {
			try {
				await approveEmployee(employee);
				await clearInput();
				showSuccessNotification(t('swal.EMPLOYEE_ADDED'));
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
	};

	const declinePendingEmployee = async () => {
		try {
			await rejectEmployee(employee);
			await clearInput();
			showSuccessNotification(t('swal.EMPLOYEE_DECLINED'));
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	useEffect(() => {
		setInititals(getEmployeeInitials(employee));
	}, [employee]);

	if(open) {
		return <EmployeeCodeModal employee={employee} open={open} onClose={() => setOpen(false)} refetch={clearInput}/>;
	}

	return (
		<Accordion id='employeeAccordion' fluid >
			<Accordion.Title
				className='employeeAccordionTitle'
				index={1}
				onClick={handleClick}
			>
				<Grid container columns={1}>
					<Grid.Row columns={1}>
						<div className='employeeName' data-letters={initials}>{employee.firstName + ' ' + employee.lastName}</div>
						<Icon className='accordionOpenIcon' name='chevron down' />
					</Grid.Row>
				</Grid>
			</Accordion.Title>
			<div>
			</div>
			<Accordion.Content active={activeIndex === 1}>
				<Grid container columns={3}>
					<Grid.Row only="computer tablet" columns={3}>
						<Grid.Column>
							<List className='employeeDataList'>
								<List.Item>
									<List.Header>{t('business.employees.email')}</List.Header>
									<span>{employee.email}</span>
								</List.Item>
							</List>
						</Grid.Column>
						<Grid.Column>
							<List>
								<List.Item>
									<List.Header>{t('business.employees.code')}</List.Header>
									<span>{employee.referenceCode}</span>
								</List.Item>
							</List>
						</Grid.Column>
						<Grid.Column>
							<List>
								<List.Item>
									<List.Header>{t('business.employees.role')}</List.Header>
									<span>{employee.role}</span>
								</List.Item>
							</List>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row id='attachment-row'>
						<Grid.Column>
							<List>
								<List.Header>{t('business.employees.attachments')}</List.Header>
							</List>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row columns={3}>
						{employee.userAttachments.map(attachment => (
							<Grid.Column key={attachment.key}>
								<List className='employeeDataList'>
									<List.Item>
										<ShowDocument attachmentKey={attachment.key} employeeId={employee.employeeId} name={attachment.name}/>
									</List.Item>
								</List>
							</Grid.Column>)
						)}
					</Grid.Row>
					<Grid.Row only="mobile">
						<List className='employeeDataList'>
							<List.Item>
								<List.Header>{t('business.employees.email')}</List.Header>
								<span>{employee.email}</span>
							</List.Item>
						</List>
					</Grid.Row>
					<Grid.Row only="mobile">
						<List>
							<List.Item>
								<List.Header>{t('business.employees.code')}</List.Header>
								<span>{employee.referenceCode}</span>
							</List.Item>
						</List>
					</Grid.Row>
					<Grid.Row only="mobile">
						<List>
							<List.Item>
								<List.Header>{t('business.employees.role')}</List.Header>
								<span>{employee.role}</span>
							</List.Item>
						</List>
					</Grid.Row>
					<Grid.Row columns={1}>
						<Grid.Column>
							<Button onClick={onApprove} icon="check" primary content={t('business.employees.accept')} />
							<Button onClick={declinePendingEmployee} className='declineInnerBTN' content={t('business.employees.decline')} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Accordion.Content>
		</Accordion>
	);
}



