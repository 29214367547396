import React, { useEffect, useState } from 'react';
import './Accounts.css';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import {
	Divider
} from 'semantic-ui-react';
import { UserType } from '@features/user/types';
import AccountsPlaceholder from './Shared/AccountsPlaceholder';
import AccountsList from './Shared/ScrollableTabs/AccountsList';
import TransactionStatements from './TransactionStatements';

const Accounts = (): React.ReactElement => {
	const [type] = useState<string>('account');
	const [requiredTier, setRequiredTier] = useState<number>(1);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);
	const [accountsLoading, setAccountsLoading] = useState<boolean>(true);
	const { loading, list, error } = useSelector(
		(state: RootState) => state.accounts
	);
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);

	useEffect(() => {
		if (!user) return;
		//TODO: Need change required tier, when KYC will be implemented
		setRequiredTier(user.type === UserType.INDIVIDUAL ? 1 : 2);
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		setKycTierVerified((kycTier >= requiredTier) || isKycTierRevoked);
		setAccountsLoading(!!user && loading);
	}, [user, requiredTier, loading]);


	if (accountsLoading || !user) return <AccountsPlaceholder
		loadingMessage='Loading accounts'
		type={type} />;
	if (error) return <div>Error: {error}</div>;

	if (!kycTierVerified && user.onBehalfOf === null) {
		return <AccountsPlaceholder type={type} kyc={true} />;
	}

	if ((!list || !list.length)) return <AccountsPlaceholder type={type} />;

	return (
		<div>
			<AccountsList type={type} accounts={list} />
			{currentAccount && <Divider hidden />}
			{currentAccount && <TransactionStatements account={currentAccount} tabType={type} />}
		</div>);
};

export default Accounts;
