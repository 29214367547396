import React from 'react';
import { Divider, Grid } from 'semantic-ui-react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { enable2FA } from '@features/user/userSlice';
import { openModal } from '@features/swal/slice';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import isEmpty from 'is-empty';

const Enable = () : React.ReactElement => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const dispatch = useDispatch();
	const initialValues = {
		authenticatorCode: ''
	};
	const validationSchema = Yup.object({
		authenticatorCode: Yup.string().matches(/^\d{6}$/, t('errors.SIX_DIGITS_REQUIRED')).required(t('form.validator.required'))
	});
	const enable = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { authenticatorCode } = formData;
			await dispatch(enable2FA(authenticatorCode));
			dispatch(openModal({ header:  t('authenticator.enable.success.header'), content: t('authenticator.enable.success.text') }));
			history.push('/wallet');
		}
		catch (e) {
			dispatch(openModal({ header:  t('authenticator.error.header'), content: e.message }));
		} finally {
			setSubmitting(false);
		}
	};
	return (
		<>
			<Grid.Row centered><h2>{ t('authenticator.enable.header') }</h2></Grid.Row>
			<Grid.Row>{ t('authenticator.enable.description') }</Grid.Row>
			<Grid.Row><Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={enable}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
					>
						<Grid>
							<Grid.Row centered>
								<Grid.Column width={5} mobile={10}>
									<span>{ t('authenticator.enable.label') }</span>
									<Input
										autoComplete="off"
										autoFocus
										fluid
										name="authenticatorCode"
										placeholder={t('form.placeholder.code')}
										errorPrompt
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<Divider/>
						<SubmitButton
							disabled={isSubmitting || !isEmpty(errors) || !dirty}
							primary size="large" type="submit">
							{ t('authenticator.enable.submit') }
						</SubmitButton>
					</Form>)}
			</Formik></Grid.Row>
		</>
	);
};

export default Enable;
