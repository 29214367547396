import { runOperation } from '@/features/operations/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { getPendingTransactions, getTokens } from '@/features/tokens/slice';
import { TokenAction, TokenPendingTransaction } from '@/features/tokens/types';
import useAccounts from '@/helpers/customHook/useAccounts';
import useFee from '@/helpers/customHook/useFee'; 
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import { Form, SubmitButton, Select } from 'formik-semantic-ui-react';
import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Header, List, Modal, Segment } from 'semantic-ui-react';
import * as Yup from 'yup';

interface Props {
	transaction: TokenPendingTransaction;
	open: boolean;
	onClose: () => void
}

const TokenConfirmation = ({ transaction, open, onClose }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const { tokens } = useSelector((state: RootState) => state.tokens);
	const [toAccountId, setToAccountId] = useState<number>();

	const currency = transaction.properties.direction === TokenAction.BUY ? transaction.fromCcy : transaction.toCcy;


	const direction = transaction.properties.direction;
	const asset = transaction.properties.direction === TokenAction.BUY ? transaction.toCcy : transaction.fromCcy;
	const token = tokens.find(p => p.symbol === asset);
	const assetAmount = direction === TokenAction.BUY ? transaction.toAmount : transaction.fromAmount;
	const price = direction === TokenAction.BUY ? transaction.fromAmount : transaction.toAmount;

	const accountOptions = useAccounts(currency);
	const subProcess = token?.subProcesses[Object.keys(token?.subProcesses).find(key => token?.subProcesses[key]['type'] === 'OWN_TRANSFER')];
	const { fee } = useFee(toAccountId, subProcess?.proc, price); 

	const totalPrice = direction===TokenAction.BUY ? (price + fee) : (price);

	const confirmToken = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { walletId } = formData;
		const tokenAccount = token?.accountId ? { accountToId: token.accountId } : { accountToCreateProc: token?.proc };
		const payload = direction === TokenAction.BUY ? {
			accountFromId: walletId,
			...tokenAccount,
			currencyExchangeOrderId: transaction.id
		} : {
			accountFromId: token.accountId,
			accountToId: walletId,
			currencyExchangeOrderId: transaction.id
		};
		try {
			await runOperation(subProcess.proc, payload);
			showSuccessNotification(t('tokens.tokenRequestCreated'));
			dispatch(getPendingTransactions());
			dispatch(getTokens());
		} catch (err) {
			showErrorNotification(err);
		} finally {
			setSubmitting(false);
			onClose && onClose();
		}
	};

	const initialValues = {
		walletId: ''
	};

	const validationSchema = direction === TokenAction.BUY ?
		Yup.object({
			walletId: Yup.string().required(t('form.validator.required')).
				test('amount', t('sendmoneyTranslation.data.insufficient'), (value) => totalPrice <= (accountOptions.accountList.find(p => p.accountId.toString() === value).availableBalance))
		}) :
		Yup.object({
			walletId: Yup.string().required(t('form.validator.required'))
		});

	const handleAccountChange = (event, data) => {
		setToAccountId(data.value);
	};

	const insufficientFund = TokenAction.SELL === direction && parseFloat(token.availableHoldings) < assetAmount;
	return (
		<Modal
			id="token-final-confirm"
			size="tiny"
			open={open}
			onClose={() => { onClose(); }}>
			<Modal.Content>
				<Segment basic>
					<Header>{t('tokens.header.orderConfirmation')}</Header>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={confirmToken}
					>
						{({ isSubmitting, dirty }) => {
							return (
								<Form>
									<List relaxed>
										<List.Item>
											<List.Header>{t('tokens.history.header.asset')}</List.Header>
											{asset}
										</List.Item>
										<List.Item>
											<List.Header  >{TokenAction.BUY === direction ?
												t('tokens.tokenActions.BUY') :
												t('tokens.tokenActions.SELL')}
											</List.Header>
											<List.Item>
												<NumberFormat displayType={'text'}
													decimalScale={token.precision}
													fixedDecimalScale={false} thousandsGroupStyle='thousand'
													thousandSeparator={true} value={assetAmount} />
											</List.Item>
										</List.Item>
										<List.Item>
											<List.Header  >{t('tokens.header.fee')}</List.Header>
											<NumberFormat displayType={'text'}
												decimalScale={2}
												fixedDecimalScale={true}
												thousandsGroupStyle='thousand'
												thousandSeparator={true}
												prefix={`${currency} `} value={fee} />
										</List.Item>
										<List.Item>
											<List.Header>{TokenAction.BUY === direction ?
												t('tokens.header.youpay') :
												t('tokens.header.youreceive')}</List.Header>
											<NumberFormat displayType={'text'}
												decimalScale={2}
												fixedDecimalScale={true}
												thousandsGroupStyle='thousand'
												thousandSeparator={true}
												prefix={`${currency} `}
												value={totalPrice} />
										</List.Item>
										<List.Item>
											<Select
												fluid
												name='walletId'
												onChange={(event, data) => handleAccountChange(event, data)}
												label={TokenAction.BUY === direction ?
													t('tokens.header.fromAccount') :
													t('tokens.header.toAccount')}
												errorPrompt
												options={accountOptions.accountOptions} />
										</List.Item>
										<List.Item>
											<List.Header as='h5'>{t('tokens.history.header.reference')}</List.Header>
											<List.Item>
												{_.capitalize(direction)}
												<NumberFormat displayType={'text'}
													decimalScale={token.precision}
													fixedDecimalScale={false}
													thousandsGroupStyle='thousand'
													thousandSeparator={true}
													prefix={' '}
													value={assetAmount} />
												{` ${asset} for `}
												<NumberFormat displayType={'text'}
													decimalScale={2}
													fixedDecimalScale={true}
													thousandsGroupStyle='thousand'
													thousandSeparator={true}
													prefix={' '}
													value={totalPrice} />
												{` ${currency} `}
											</List.Item>
										</List.Item>
									</List>
									{insufficientFund &&
										<p className="color-red">
											{t('sendmoneyTranslation.data.insufficient')}
										</p>}
									<Divider className='tokens-trade-divider' />
									<SubmitButton
										className='tokens-trade-submit'
										disabled={isSubmitting || insufficientFund || !dirty}
										primary fluid size="large" type="submit">
										{t('tokens.tokenActions.' + direction)}
									</SubmitButton>
								</Form>);
						}}
					</Formik>

				</Segment>
			</Modal.Content>
		</Modal>
	);
};

export default TokenConfirmation;
