import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Message } from 'semantic-ui-react';

interface OnfidoStatusProps {
	onClose: () => void
}

const AdvisionaryKybStatus = ({ onClose }: OnfidoStatusProps) => {
	const { t } = useTranslation('translations');

	return (
		<Modal.Content>
			<Message>Thank you for opening your business account. In order to access our services we need to verify your business. Shortly you will receive email from our Compliance Team regarding your business onboarding. Thank you for your patience.</Message>
			<Button onClick={onClose}>{t('form.buttons.close')}</Button>
		</Modal.Content>
	);
};

export default AdvisionaryKybStatus;
