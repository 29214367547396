import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './TermsOfUse.css';

function TermsOfUseDTSUS(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Terms of Use DTS Money Americas</h1></Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<div>
					<p>
						Please read these terms of use carefully before using this site.
					</p>
				</div>
				<br />
				<div>
					<h1>
						About our terms
					</h1>
				</div>
				<br />
				<div>
					<p>
						These terms and conditions of use (“Terms”) explain how you may use
						this Website and any of its content (the “Site”). These Terms apply
						between Mio Payments (hereinafter “Miopay”, “us”, ”we”, ”our”), a
						Canadian Incorporated Company with incorporation number BC1333237,
						located at Suite 2500, Park Place 666, Burrard St., Vancouver, BC, V6C
						2X8, Canada, and you, the person accessing or using the Site (“you” or
						“your”).
					</p>
				</div>
				<br />
				<div>
					<p>
						You should read these Terms carefully before using the Site. By using
						the Site or otherwise indicating your consent, you agree to be bound by
						these Terms. If you do not agree with any of these Terms, you should
						stop using the Site immediately.
					</p>
				</div>
				<br />
				<div>
					<h1>
						About us
					</h1>
				</div>
				<br />
				<div>
					<p>
						We are Miopay, a Canadian Incorporated Company regulated by FINTRAC as
						a Money Service Business (MSB) under registration number M21336959.
						Miopay is your service provider. If you have any questions about the
						Site, please contact us by sending an email to&nbsp;
						<a
							href="mailto:contact@miopay.io"
							target="_blank"
							rel="noreferrer noopener"
						>
							contact@miopay.io
						</a>
					</p>
				</div>
				<br />
				<div>
					<h1>
						Using the Site
					</h1>
				</div>
				<br />
				<div>
					<p>
						The Site is for your personal and non-commercial use only. You agree
						that you are solely responsible for all costs and expenses you may
						incur in relation to your use of the Site.
					</p>
				</div>
				<br />
				<div>
					<p>
						We make no promise that the Site is appropriate or available for use in
						your location. If you choose to access the Site, you acknowledge that
						you do so at your own initiative and are responsible for compliance
						with local laws where they apply.
					</p>
				</div>
				<br />
				<div>
					<div>
						<p>
							We try to make the Site as accessible as possible. If you have any
							difficulties using the Site, please contact us using the contact
							details at the top of this page.
						</p>
					</div>
					<br />
					<div>
						<p>
							As a condition of your use of the Site, you agree not to:
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									misuse or attack our Site by knowingly introducing viruses,
									trojans, worms, logic bombs or any other material which is
									malicious or technologically harmful (such as by way of a
									denial-of-service attack), or
								</p>
							</li>
							<li>
								<p>
									attempt to gain unauthorised access to our Site, the server
									on which our Site is stored or any server, computer or
									database connected to our Site.
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div>
					<p>
						We may prevent or suspend your access to the Site if you do not comply
						with these Terms or any applicable law.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Your privacy and personal information
					</h1>
				</div>
				<br />
				<div>
					<p>
						Your privacy and personal information are important to us. Any personal
						information that you provide to us will be dealt with in line with our
						Privacy Policy available on our Site, which explains what personal
						information we collect from you, how and why we collect, store, use and
						share such information, your rights in relation to your personal
						information and how to contact us and supervisory authorities in the
						event you have a query or complaint about the use of your personal
						information.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Ownership, use and intellectual property rights
					</h1>
				</div>
				<br />
				<div>
					<p>
						The intellectual property rights in the Site and in any text, images,
						video, audio or other multimedia content, software or other information
						or material submitted to or accessible from the Site (Content) are
						owned by us and our licensors.
					</p>
				</div>
				<br />
				<div>
					<p>
						We and our licensors reserve all our intellectual property rights
						(including, but not limited to, all copyright, trade marks, domain
						names, design rights, database rights, patents and all other
						intellectual property rights of any kind) whether registered or
						unregistered anywhere in the world. This means, for example, that we
						remain owners of them and are free to use them as we see fit.
					</p>
				</div>
				<br />
				<div>
					<p>
						Nothing in these Terms grants you any legal rights in the Site or the
						Content other than as necessary for you to access it. You agree not to
						adjust, try to circumvent or delete any notices contained on the Site
						or the Content (including any intellectual property notices) and in
						particular, in any digital rights or other security technology embedded
						or contained within the Site or the Content.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Submitting information to the site
					</h1>
				</div>
				<br />
				<div>
					<p>
						While we try to make sure that the Site is secure, we do not actively
						monitor or check whether information supplied to us through the Site is
						confidential, commercially sensitive or valuable.
					</p>
				</div>
				<div>
				</div>
				<div>
					<p>
						Other than any personal information which will be dealt with in
						accordance with our Privacy Policy, we do not guarantee that
						information supplied to us through the Site will be kept confidential
						and we may use it on an unrestricted and free-of-charge basis as we
						reasonably see fit.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Accuracy of information and availability of the site
					</h1>
				</div>
				<br />
				<div>
					<p>
						We try to make sure that the Site is accurate, up-to-date and free from
						bugs, but we cannot promise that it will be. Furthermore, we cannot
						promise that the Site will be fit or suitable for any purpose. Any
						reliance that you may place on the information on the Site is at your
						own risk. We may suspend or terminate access or operation of the Site
						at any time as we see fit.
					</p>
				</div>
				<br />
				<div>
					<p>
						Any Content is provided for your general information purposes only and
						to inform you about us and our products and news, features, services
						and other websites that may be of interest, but has not been tailored
						to your specific requirements or circumstances. It does not constitute
						technical, financial or legal advice or any other type of advice and
						should not be relied on for any purposes. You should always use your
						own independent judgment when using our Site and its Content.
					</p>
				</div>
				<br />
				<div>
					<p>
						While we try to make sure that the Site is available for your use, we
						do not promise that the Site will be available at all times or that
						your use of the Site will be uninterrupted.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Hyperlinks and third party sites
					</h1>
				</div>
				<br />
				<div>
					<p>
						The Site may contain hyperlinks or references to third party
						advertising and websites other than the Site. Any such hyperlinks or
						references are provided for your convenience only. We have no control
						over third party advertising or websites and accept no legal
						responsibility for any content, material or information contained in
						them. The display of any hyperlink and reference to any third party
						advertising or website does not mean that we endorse that third party’s
						website, products or services. Your use of a third party site may be
						governed by the terms and conditions of that third-party site and is at
						your own risk.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Our responsibility to you
					</h1>
				</div>
				<br />
				<div>
					<p>
						If we breach these Terms or are negligent, we are liable to you for
						foreseeable loss or damage that you suffer as a result. By
						‘foreseeable’ we mean that, at the time these Terms were formed, it was
						either clear that such loss or damage would occur or you and we both
						knew that it might reasonably occur, as a result of something we did
						(or failed to do).
					</p>
				</div>
				<br />
				<div>
					<p>
						We are not liable to you for any loss or damage that was not
						foreseeable, any loss or damage not caused by our breach or negligence,
						or any business loss or damage.
					</p>
				</div>
				<br />
				<div>
					<p>
						Nothing in these terms excludes or limits our liability for any death
						or personal injury caused by our negligence, liability for fraud or
						fraudulent misrepresentation, or any other liability that the law does
						not allow us to exclude or limit.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Events beyond our control
					</h1>
				</div>
				<br />
				<div>
					<p>
						We are not liable to you if we fail to comply with these Terms because
						of circumstances beyond our reasonable control.
					</p>
				</div>
				<br />
				<div>
					<h1>
						No third party rights
					</h1>
				</div>
				<br />
				<div>
					<p>
						No one other than us or you has any right to enforce any of these
						Terms.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Change of these terms of use
					</h1>
				</div>
				<br />
				<div>
					<p>
						No changes to these Terms are valid or have any effect unless agreed by
						us in writing or made in accordance with this clause.
					</p>
				</div>
				<br />
				<div>
					<p>
						We reserve the right to vary these Terms from time to time. Our updated
						Terms will be displayed on the Site and by continuing to use and access
						the Site following such changes, you agree to be bound by any variation
						made by us. It is your responsibility to check these Terms from time to
						time to verify such variations.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Complaints
					</h1>
				</div>
				<br />
				<div>
					<p>
						We will try to resolve any disputes with you quickly and efficiently.
						If you are unhappy with us, please contact us as soon as possible using
						the contact details set out at the top of this page.
					</p>
				</div>
				<br />
				<div>
					<p>
						The laws of the Province of British Columbia, Canada, and any Canadian
						federal laws applicable therein.
					</p>
				</div>
				<br />
				<div>
					<p>
						In respect of a claim by either you or us arising out of or in any way
						related to these Terms, all documents incorporated by reference and
						your relationship with us, you agree to irrevocably and unconditionally
						submit to the exclusive jurisdiction of the courts and tribunals of
						British Columbia, Canada (including the Federal courts and tribunals as
						applicable) for the settlement thereof.
					</p>
				</div>
				<div>
				</div>

			</Segment>
		</Container>
	);
}

export default TermsOfUseDTSUS;
