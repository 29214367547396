import React, { useState, useEffect, useLayoutEffect } from 'react';
import Header from './Components/Header';
import { BankAccount } from '@features/account/types';
import { Card } from '@features/card/types';
import './AccountsList.css';
import { RootState } from '@/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { setAccount } from '@features/tabs/slice';
import BankAccountDetails from '../../BankAccountDetails';
import CardDetails from '../../CardDetails';
import TrustAccountDetails from '@/components/Banking/TrustAccountDetails';
import { TrustAccount } from '@features/trust/types';

interface AccountsProps {
	accounts: Array<BankAccount | Card>,
	type: string
}

const AccountsList = ({ accounts, type }: AccountsProps): React.ReactElement => {
	const dispatch = useDispatch();
	const [width, setWidth] = useState<number>(null);
	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);

	useLayoutEffect(() => {
		function updateSize() {
			setWidth(document.querySelector<HTMLElement>('#accounts-tabs').offsetWidth);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	useEffect(() => {
		setWidth(document.querySelector<HTMLElement>('#accounts-tabs').offsetWidth);
		if (!currentAccount) {
			dispatch(setAccount(accounts[0]));
			return;
		}
		if (Reflect.has(currentAccount, 'cardId') && !Reflect.has(accounts[0], 'cardId')) {
			dispatch(setAccount(accounts[0]));
			return;
		}
		if (!Reflect.has(currentAccount, 'cardId') && Reflect.has(accounts[0], 'cardId')) {
			dispatch(setAccount(accounts[0]));
			return;
		}
		const account = accounts.find(account => account.accountId === currentAccount.accountId);
		if(!account && accounts.length > 0) {
			dispatch(setAccount(accounts[0]));
		}
		if(!account) return;
		dispatch(setAccount(JSON.parse(JSON.stringify(account))));
	}, [dispatch, accounts]);

	return <div id="accounts-tabs" style={{ width: '100%' }}>
		{width && <Header type={type} width={width} accounts={accounts} />}
		<div className="ui bottom attached segment active tab">
			{currentAccount && type === 'account'&& <BankAccountDetails bankAccount={currentAccount as BankAccount} />}
			{currentAccount && type === 'card' && <CardDetails card={currentAccount as Card} />}
			{currentAccount && type === 'trust' && <TrustAccountDetails trustAccount={currentAccount as TrustAccount}/>}
		</div>
	</div>;
};

export default AccountsList;
