import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Menu, Modal, Accordion, Button, Item, Header, Icon } from 'semantic-ui-react';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { logout } from '@features/user/userSlice';
import { UserType } from '@features/user/types';
import { showErrorNotification } from '@features/swal/slice';
import Vector from '@icons/Popular/Vector';
import CloseTarget from '@icons/CloseTarget';
import AccountIconMobile from '@icons/AccountIconMobile';
import HelpIconMobile from '@icons/HelpIconMobile';
import HomeIconMobile from '@icons/HomeIconMobile';
import SecurityIconMobile from '@icons/SecurityIconMobile';
import VectorDown from '@icons/Vectordown';
import NavbarLogo from './NavbarLogo';
import EmployeeIconMobile from '@/icons/EmployeeIconMobile';
import SwitchMenuItem from './SwitchMenuItem';



type NavbarProps = {
	menu: boolean
}

const MobileNavbar = ({ menu }: NavbarProps) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const [open, setOpen] = React.useState(false);
	const [requiredTier, setRequiredTier] = useState<number>(1);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);

	const [activeIndex, setActiveIndex] = useState<number>(0);

	const { businessEnabled, allowMultipleBusinessRegistration } = useSelector(
		(state: RootState) => state.status
	);

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};

	const signout = async (key?: UserType) => {
		try {
			await dispatch(logout());
			return history.push('/auth/login' + (key ? `?mode=${key}` : ''));
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	const { user, status } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (!user) return;
		setRequiredTier(user.type === UserType.INDIVIDUAL ? 1 : 2);
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		setKycTierVerified((kycTier >= requiredTier) || isKycTierRevoked);
	}, [user, requiredTier]);

	return (
		<div>
			{/* Mobile navbar */}
			<Modal
				id='navbarModalMobile'
				className='mobileNavbarModal'
				open={open}
				trigger={<Button className={menu ? 'mobileNavbarBTN' : 'mobileNavbarBTN hide'}>
					<div className='mobileNavbarBTNDiv'>
						<Vector />
						<p className='menuP'>Menu</p>
					</div>
				</Button>}
				onClose={() => setOpen(false)}
				onOpen={() => setOpen(true)}
			>
				<Header className='navbarModalHeader'>
					<Icon as={NavLink} to="/" className="logo">
						<NavbarLogo />
					</Icon>
					<Button className='closeMobileNavbarBTN' onClick={() => setOpen(false)}>
						<CloseTarget className='closeNavbarModalIcon' />
						<p className='menuP'>Menu</p>
					</Button>
				</Header>
				<Modal.Content className='navbarModalContent'>
					<Menu id="navbar" fluid secondary borderless vertical>
						{false && <Menu.Item header as={NavLink} to="/" className="navbarItemMobile">
							mobile
						</Menu.Item>}
						{menu && (<Menu.Menu position="right">
							{((status && !status.twoFARequired) || (user && user.twoFactorAuthEnabled)) && ((status && !status.confirmPhoneRequired) || (user && user.type === UserType.INDIVIDUAL && user.confirmedMobileNumber !== null)) ? <Menu.Item onClick={() => setOpen(false)} className="navbarItemMobile" name="home" as={NavLink} to="/wallet/home">
								<HomeIconMobile className='mobileNavbarIcon' />
								{t('navbar.home')}
							</Menu.Item> : ''}
							<Menu.Item onClick={() => setOpen(false)} className="navbarItemMobile" as={NavLink} to="/wallet/security" name="register">
								<SecurityIconMobile className='mobileNavbarIcon' />
								{t('navbar.security')}
							</Menu.Item>
							<Menu.Item onClick={() => setOpen(false)} className="navbarItemMobile" as={NavLink} to="/wallet/support" name="register">
								<HelpIconMobile className='mobileNavbarIcon' />
								{t('navbar.support')}
							</Menu.Item>
							{businessEnabled && user && kycTierVerified && (user.type === UserType.BUSINESS || user.onBehalfOf !== null) ? <Menu.Item onClick={() => setOpen(false)} className="navbarItemMobile" as={NavLink} to="/wallet/employees" name="register">
								<EmployeeIconMobile className='mobileNavbarIcon' />
								{t('navbar.employees')}
							</Menu.Item> : ''}
							{user && <SwitchMenuItem user={user} isMobile={true} />}
							<Menu.Item className='accountMenuItem navbarItemMobile'>
								<Accordion id='employeeAccordion' fluid >
									<Accordion.Title
										index={1}
										onClick={handleClick}
										className='accountAccordionTitle'
									>
										<div className='accountMobileTitleDiv'>
											<AccountIconMobile className='mobileNavbarIcon' />
											<div className='accountTitleDiv'>{t('navbar.account.title')}</div>
											<VectorDown className='myAccountMobileVector' />
										</div>
									</Accordion.Title>
									<Accordion.Content active={activeIndex === 1}>
										<Item className='accordionAccountItem' onClick={() => setOpen(false)} as={NavLink} to="/wallet/account">{t('navbar.account.details')}</Item>
										<Item className='accordionAccountItem' onClick={() => setOpen(false)} as={NavLink} to="/wallet/contacts" >{t('navbar.account.contacts')}</Item>
										<Item className='accordionAccountItem' onClick={() => signout()}>{t('navbar.account.signout')}</Item>
									</Accordion.Content>
								</Accordion>
							</Menu.Item>
						</Menu.Menu>)}
					</Menu>
				</Modal.Content>
			</Modal>
		</div>
	);
};

export default MobileNavbar;
