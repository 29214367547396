import React from 'react';
import { Container, Message } from 'semantic-ui-react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import MyAccount from '../components/Dashboard/MyAccount';
import Home from '../components/Dashboard/Home';
import Support from '../components/Support/FAQ';
import Security from '../components/Dashboard/Security';
import Transfers from '../components/Banking/Transfers/Transfers';
import Create from '../components/Dashboard/Create';
import Footer from '../components/Footer/Footer';
import './Dashboard.css';
import MyEmployees from '../components/Business/MyEmployees';
import Terms from '@/components/Legal/Terms/Terms';
import AMLPolicy from '@/components/Legal/AMLPolicy/AMLPolicy';
import PrivacyPolicy from '@/components/Legal/PrivacyPolicy/PrivacyPolicy';
import MainScreen from '@/components/2FA/MainScreen';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import VerificationTransfer from '@/components/KYC/VerificationTransfer';
import EmployeeVerificationForm from '@/components/Business/EmployeeVerificationForm';
import Contacts from '@/components/Dashboard/Contacts';
import PrivacyPolicyDTSCB from '@/components/Legal/PrivacyPolicy/PrivacyPolicyDTSCB';
import { Agents } from '@helpers/globalTypes';
import TermsDTSCB from '@/components/Legal/Terms/TermsDTSCB';
import AMLPolicyDTSCB from '@/components/Legal/AMLPolicy/AMLPolicyDTSCB';
import AMLPolicyTYNCOFEX from '@/components/Legal/AMLPolicy/AMLPolicyTYNCOFEX';
import PrivacyPolicyTYNCOFEX from '@/components/Legal/PrivacyPolicy/PrivacyPolicyTYNCOFEX';
import TermsTYNCOFEX from '@/components/Legal/Terms/TermsTYNCOFEX';
import PrivacyPolicyDTS from '@/components/Legal/PrivacyPolicy/PrivacyPolicyDTS';
import TermsDTS from '@/components/Legal/Terms/TermsDTS';
import CookiesPolicyDTS from '@/components/Legal/CookiesPolicy/CookiesPolicyDTS';
import NotFound from './NotFound';
import UpgradePlan from '@/components/Dashboard/MyAccount/UpgradePlan';
import ConfirmPhoneNumber from '@/components/ConfirmPhoneNumber/ConfirmPhoneNumber';
import { UserType } from '@/features/user/types';
import TermsOfUseDTS from '@/components/Legal/TermsOfUse/TermsOfUseDTS';
import PrivacyPolicyCUSTODYFY from '@/components/Legal/PrivacyPolicy/PrivacyPolicyCUSTODYFY';
import TermsOfUseCUSTODYFY from '@/components/Legal/TermsOfUse/TermsOfUseCUSTODYFY';
import TermsCUSTODYFY from '@/components/Legal/Terms/TermsCUSTODYFY';
import { useTranslation } from 'react-i18next';
import TermsITCUSTODYFY from '@/components/Legal/Terms/TermsITCUSTODYFY';
import PrivacyPolicyITCUSTODYFY from '@/components/Legal/PrivacyPolicy/PrivacyPolicyITCUSTODYFY';
import TermsOfUseITCUSTODYFY from '@/components/Legal/TermsOfUse/TermsOfUseITCUSTODYFY';
import PrivacyPolicyDTSUS from '@/components/Legal/PrivacyPolicy/PrivacyPolicyDTSUS';
import TermsOfUseDTSUS from '@/components/Legal/TermsOfUse/TermsOfUseDTSUS';
import TermsOfUseYESSY from '@/components/Legal/TermsOfUse/TermsOfUseYESSY';
import TermsYESSY from '@/components/Legal/Terms/TermsYESSY';
import PrivacyPolicyYESSY from '@/components/Legal/PrivacyPolicy/PrivacyPolicyYESSY';
import PrivacyPolicyOlamit from '@/components/Legal/PrivacyPolicy/PrivacyPolicyOlamit';
import TermsOfUseOlamit from '@/components/Legal/TermsOfUse/TermsOfUseOlamit';
import TermsOLAMIT from '@/components/Legal/Terms/TermsOLAMIT';
import TermsDTSUS from '@/components/Legal/Terms/TermsDTSUS';

const Dashboard = (): React.ReactElement => {
	const { path } = useRouteMatch();
	const { i18n, t } = useTranslation('translations');
	const { user, status } = useSelector(
		(state: RootState) => state.user
	);
	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	return (
		<div id="dashboard">
			<Navbar menu={true} />
			<Container fluid className="app-layout">
				<Switch>
					<Route path={`${path}/home`}>
						<Home />
					</Route>
					<Route path={`${path}/verify`}>
						<EmployeeVerificationForm />
					</Route>
					<Route path={`${path}/upgrade-plan`}>
						{user && user.plan.upgradeAllowed && user.type === UserType.INDIVIDUAL ? <UpgradePlan /> : <NotFound />}
					</Route>
					<Route path={`${path}/transfers`}>
						<Transfers />
					</Route>
					<Route path={`${path}/create`}>
						<Create />
					</Route>
					<Route path={`${path}/support`}>
						<Support />
					</Route>
					<Route path={`${path}/security`}>
						<Security />
					</Route>
					<Route path={`${path}/account`}>
						<MyAccount />
					</Route>
					<Route path={`${path}/contacts`}>
						<Contacts />
					</Route>
					<Route path={`${path}/employees`}>
						<MyEmployees />
					</Route>
					<Route path={`${path}/terms`}>
						{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && agent !== Agents.DTS && agent !== Agents.DTSUS && agent !== Agents.WZ && agent !== Agents.CUSTODYFY && agent !== Agents.YESSY && agent !== Agents.OLAMIT && <Terms />}
						{agent === Agents.DTS && <TermsDTS />}
						{agent === Agents.DTSCB && <TermsDTSCB />}
						{agent === Agents.DTSUS && <TermsDTSUS />}
						{agent === Agents.YESSY && <TermsYESSY />}
						{agent === Agents.TYNCOFEX && <TermsTYNCOFEX />}
						{agent === Agents.OLAMIT && <TermsOLAMIT />}
						{agent === Agents.CUSTODYFY && i18n.language !== 'it' && <TermsCUSTODYFY />}
						{agent === Agents.CUSTODYFY && i18n.language === 'it' && <TermsITCUSTODYFY />}
					</Route>
					<Route path={`${path}/termsofuse`}>
						{agent === Agents.DTS && <TermsOfUseDTS />}
						{agent === Agents.DTSUS && <TermsOfUseDTSUS />}
						{agent === Agents.YESSY && <TermsOfUseYESSY />}
						{agent === Agents.OLAMIT && <TermsOfUseOlamit />}
						{agent === Agents.CUSTODYFY && i18n.language !== 'it' && <TermsOfUseCUSTODYFY />}
						{agent === Agents.CUSTODYFY && i18n.language === 'it' && <TermsOfUseITCUSTODYFY />}
					</Route>
					<Route path={`${path}/amlpolicy`}>
						{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && agent !== Agents.DTS && agent !== Agents.WZ && agent !== Agents.COSPAY && <AMLPolicy />}
						{agent === Agents.DTSCB && <AMLPolicyDTSCB />}
						{agent === Agents.TYNCOFEX && <AMLPolicyTYNCOFEX />}
						{agent === Agents.DTS || agent === Agents.CUSTODYFY || agent === Agents.COSPAY && <NotFound />}
					</Route>
					<Route path={`${path}/privacypolicy`}>
						{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && agent !== Agents.DTS && agent !== Agents.WZ && agent !== Agents.CUSTODYFY && agent !== Agents.DTSUS && agent !== Agents.YESSY && agent !== Agents.OLAMIT && <PrivacyPolicy />}
						{agent === Agents.DTSCB && <PrivacyPolicyDTSCB />}
						{agent === Agents.DTS && <PrivacyPolicyDTS />}
						{agent === Agents.YESSY && <PrivacyPolicyYESSY />}
						{agent === Agents.DTSUS && <PrivacyPolicyDTSUS />}
						{agent === Agents.TYNCOFEX && <PrivacyPolicyTYNCOFEX />}
						{agent === Agents.OLAMIT && <PrivacyPolicyOlamit />}
						{agent === Agents.CUSTODYFY && i18n.language !== 'it' && <PrivacyPolicyCUSTODYFY />}
						{agent === Agents.CUSTODYFY && i18n.language === 'it' && <PrivacyPolicyITCUSTODYFY />}
					</Route>
					<Route path={`${path}/cookiespolicy`}>
						{agent === Agents.DTS && <CookiesPolicyDTS />}
					</Route>
					<Route path={`${path}/2fa`}>
						<MainScreen />
					</Route>
					<Route path={`${path}/confirmphone`}>
						<ConfirmPhoneNumber />
					</Route>
					<Route path={`${path}/transfer`}>
						<VerificationTransfer />
					</Route>
					{(status && status.twoFARequired) && (user && !user.twoFactorAuthEnabled && user.type !== 'EMPLOYEE') && <Route><Redirect to={`${path}/2fa`} /></Route>}
					{(status && status.confirmPhoneRequired) && (user && user.confirmedMobileNumber === null && user.type === UserType.INDIVIDUAL) && <Route><Redirect to={`${path}/confirmphone`} /></Route>}
					{(user && (user.twoFactorAuthEnabled || user.type === 'EMPLOYEE' || !status.twoFARequired) && (user.type !== UserType.INDIVIDUAL || user.confirmedMobileNumber !== null || !status.confirmPhoneRequired)) && <Route><Redirect to={`${path}/home`} /></Route>}
				</Switch>
			</Container>
			<Footer authorized={true} />
		</div>);
};

export default Dashboard;
