import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const Links = () => {
	const { t } = useTranslation('translations');

	return (
		<Grid columns={2}>
			<Grid.Column>
				<Link to="/auth/forgot-password">{t('login.resetPassword')}</Link>
			</Grid.Column>
			<Grid.Column textAlign="right">
				<Link to="/auth">{t('registration.links.login')}</Link>
			</Grid.Column>
		</Grid>
	);
};

export default Links;
