import React from 'react';
import { Icon, List, Header, Grid, Button, Modal, Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { TransactionStatus } from '@features/transactions/types';
import { formatDate } from '@helpers/date';
import { isFiat } from '@/helpers/currency';
interface Props {
	transaction: any
}

const TransactionDetails = ({ transaction }: Props): React.ReactElement => {
	const [open, setOpen] = React.useState(false);
	const { t } = useTranslation('translations');

	const isFiatAsset = isFiat(transaction.asset);

	const statusIcon = (value: TransactionStatus) => {
		let element;
		switch (value) {
			case TransactionStatus.CANCELED:
				element = <Icon className="rejectedicon" name="close" />;
				break;
			case TransactionStatus.COMPLETED:
				element = <Icon className="approvedicon" name="check" />;
				break;
			default:
				element = <Icon className="approvalicon" name="clock outline" />;
		}
		return element;
	};

	return (
		<Modal
			key={transaction.transactionNumber}
			size='small'
			id='transactionDetailsModal'
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			trigger={<div>{t('transaction.dropdown.viewStatement')}</div>}
		>
			<Modal.Content>
				<Grid container columns={2}>
					<Header className='transactionModalHeader'>{t('transactiondetails.main.header')}</Header>
					<Grid.Row>
						<Grid.Column>
							<List relaxed>
								<List.Item>
									<List.Header>{t('transactiondetails.main.number')}</List.Header>
									<span>{transaction.transactionNumber || '---'}</span>
								</List.Item>
							</List>
						</Grid.Column>
						<Grid.Column>
							<List relaxed>
								<List.Item>
									<List.Header>{t('transactiondetails.main.date')}</List.Header>
									<span>{formatDate(transaction.date, 'T')}</span>
								</List.Item>
								<List.Item>
									<List.Header>{t('transactiondetails.main.status')}</List.Header>
									<span>{statusIcon(transaction.status)}{t('status.' + transaction.status)}</span>
								</List.Item>
							</List>
						</Grid.Column>
					</Grid.Row>
					<Divider section />
					<Grid.Row>
						<Grid.Column>
							<List relaxed>
								<Header>{t('transactiondetails.remitter.remitterheader')}</Header>
								<List.Item>
									<List.Header>{t('transactiondetails.remitter.from')}</List.Header>
									<span>{transaction.remitterAccount || '---'}</span>
								</List.Item>
								<List.Item>
									<List.Header>{t('transactiondetails.remitter.name')}</List.Header>
									<span>{transaction.remitterName || '---'}</span>
								</List.Item>
								<List.Item>
									<List.Header>{t('transactiondetails.remitter.amount')}</List.Header>
									<span>
										<NumberFormat displayType={'text'} decimalScale={isFiatAsset ? 2 : 5} fixedDecimalScale={isFiatAsset} thousandsGroupStyle='thousand' thousandSeparator={true} value={transaction.amount} />
									</span>
								</List.Item>
								<List.Item>
									<List.Header>{t('transactiondetails.remitter.currency')}</List.Header>
									<span>{transaction.asset}</span>
								</List.Item>
							</List>
						</Grid.Column>
						<Grid.Column>
							<List relaxed>
								<Header>{t('transactiondetails.beneficiary.beneficiaryheader')}</Header>
								<List.Item>
									<List.Header>{t('transactiondetails.beneficiary.name')}</List.Header>
									<span>{transaction.beneficiary || '---'}</span>
								</List.Item>
								<List.Item>
									<List.Header>{t('transactiondetails.beneficiary.id')}</List.Header>
									<span>{transaction.beneficiaryId || '---'}</span>
								</List.Item>
								<List.Item>
									<List.Header>{t('transactiondetails.beneficiary.account')}</List.Header>
									<span>{transaction.beneficiaryAccount || '---'}</span>
								</List.Item>
								<List.Item>
									<List.Header>{t('transactiondetails.beneficiary.bank')}</List.Header>
									<span>{transaction.beneficiaryBank || '---'}</span>
								</List.Item>
							</List>
						</Grid.Column>
					</Grid.Row>
					<Divider section />
					<Grid.Row >
						<Grid.Column>
							<List relaxed>
								<Header>{t('transactiondetails.details.detailsheader')}</Header>
								<List.Item>
									<List.Header>{t('transactiondetails.details.reference')}</List.Header>
									<span>{transaction.details || '---'}</span>
								</List.Item>
								<List.Item>
									<List.Header>{t('transactiondetails.details.type')}</List.Header>
									<span>{t('activity.' + transaction.activity)}</span>
								</List.Item>
							</List>
						</Grid.Column>
					</Grid.Row>
					<Divider section />
					<Grid.Row>
						<Grid.Column width={16}>
							<Button onClick={() => setOpen(false)} fluid primary content='Close' icon='close' />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Modal.Content>
		</Modal>
	);
};

export default TransactionDetails;
