import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './PrivacyPolicy.css';

function PrivacyPolicyITCUSTODYFY(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Informativa sulla privacy</h1></Grid.Row></Grid>
			<Segment className="policyContent">

				<p>Benvenuti nell&apos;Informativa sulla Privacy di&nbsp;Custodyfy&nbsp;(&quot;Informativa sulla Privacy&quot;). La invitiamo a leggerla attentamente prima di fornirci qualsiasi informazione personale su di lei o su qualsiasi altra persona.</p>
				<p>Custodyfy &egrave; uno Statutory Trust del Delaware con sede legale al 3411 Silverside Road Tatnall Building #104, Wilmington, Delaware, rappresentato dallo Sponsor del Trust, Custodyfy Kommanditbolag, una societ&agrave; svedese a responsabilit&agrave; limitata con sede legale al Central Master Samuelsgatan 60, 111 21 Stoccolma, Svezia e registrata come Professional Trustee ai sensi della Legge di prevenzione del riciclaggio di denaro e del finanziamento del terrorismo 2017:630 (di seguito - &quot;Custodyfy&quot;, &quot;noi&quot;, &quot;ci&quot;, &quot;nostro&quot;).&nbsp;</p>
				<p>Ai fini della legge sulla protezione dei dati, siamo un titolare del trattamento dei dati personali che riceviamo da voi o che raccogliamo in altro modo su di voi e siamo responsabili di garantire che utilizziamo i vostri dati personali in conformit&agrave; con le leggi sulla protezione dei dati applicabili negli Stati Uniti, nell&apos;Unione Europea, nello Spazio Economico Europeo (SEE) e nel Regno Unito, come il Regolamento 2016/679 (&quot;Regolamento generale sulla protezione dei dati&quot;). In qualit&agrave; di responsabili del trattamento dei vostri dati personali, siamo noi a determinare i &quot;mezzi&quot; e le &quot;finalit&agrave;&quot; di qualsiasi attivit&agrave; di trattamento da&nbsp;noi svolta.</p>
				<h1>Premessa</h1>
				<p>Abbiamo bisogno di raccogliere e utilizzare alcuni tipi di informazioni sulle persone fisiche e giuridiche che desiderano partecipare ai nostri servizi.<br /><br />Le informazioni personali devono essere raccolte e trattate in modo appropriato in conformit&agrave; con la politica antiriciclaggio della societ&agrave; e in conformit&agrave; con la presente Informativa sulla privacy redatta in conformit&agrave; con il GDPR e gli standard internazionali.<br /><br />In conformit&agrave; con le disposizioni del GDPR e degli standard internazionali, ogni Utente che fornisce le proprie informazioni ai sensi della presente politica conferma di comprendere e accettare il motivo della raccolta delle informazioni e acconsente al trattamento delle informazioni ai fini della prevenzione del riciclaggio di denaro.<br /><br />Ogni Utente ha il diritto di sapere chi &egrave; responsabile del trattamento dei suoi dati personali.<br /><br />Tutti i dati raccolti nel corso della nostra attivit&agrave;, siano essi cartacei, archiviati in un database informatico o registrati su altro materiale, sono soggetti alla presente politica e sono protetti dalla legge applicabile e dalle disposizioni del GDPR in materia di controllo e trattamento dei dati.</p>


				<h1>1. Cosa prevede questa Informativa?</h1>

				<p>La presente Informativa sulla Privacy si applica esclusivamente all&apos;utilizzo del sito web di Custodyfy. Il sito web pu&ograve; contenere link ad altri siti web. Si prega di notare che non abbiamo alcun controllo sul modo in cui i vostri dati vengono raccolti, memorizzati o utilizzati da altri siti web e vi consigliamo di controllare le politiche sulla privacy di tali siti web prima di fornire loro qualsiasi dato.&nbsp;</p>

				<h1>2. Che cosa sono i dati personali?</h1>

				<p>I dati personali sono definiti dalla&nbsp;legge sulla protezione delle informazioni personali e dei documenti elettronici&nbsp;come &quot;qualsiasi informazione relativa a una persona identificabile che pu&ograve; essere identificata direttamente o indirettamente in particolare con riferimento a un identificatore&quot;.</p>
				<p>I dati personali sono, in termini pi&ugrave; semplici, tutte le informazioni che vi riguardano e che permettono di identificarvi. I dati personali comprendono informazioni come il nome e i dati di contatto, ma anche informazioni meno ovvie come i numeri di identificazione, i dati di localizzazione elettronica e altri identificatori online.&nbsp;</p>

				<h1>3. Quali dati raccogliamo?</h1>

				<p>Custodyfy raccoglie informazioni personali nell&apos;ambito della propria attivit&agrave; commerciale con l&apos;utente quando quest&apos;ultimo richiede l&apos;utilizzo dei nostri prodotti e/o servizi. Le informazioni vengono raccolte con il consenso dell&apos;utente o come consentito o richiesto dalla legge.</p>
				<p>A seconda dell&apos;utilizzo del nostro sito web da parte dell&apos;utente, le informazioni personali fornite dall&apos;utente o da noi richieste e raccolte potrebbero includere quanto segue:&nbsp;</p>
				<p>Dati di identit&agrave;:</p>
				<ul>
					<li>
						<p>nome,</p>
					</li>
					<li>
						<p>cognome,</p>
					</li>
					<li>
						<p>titolo,</p>
					</li>
					<li>
						<p>data di nascita e genere,</p>
					</li>
					<li>
						<p>informazioni biometriche per il riconoscimento facciale,</p>
					</li>
					<li>
						<p>documenti d&apos;identit&agrave; come la carta d&apos;identit&agrave; nazionale, il passaporto, la patente di guida o altri documenti d&apos;identit&agrave;.</p>
					</li>
				</ul>
				<p>Dati relativi all&apos;identit&agrave;:</p>
				<ul>
					<li>
						<p>valutazione del rischio,</p>
					</li>
					<li>
						<p>valutazione della conformit&agrave;.</p>
					</li>
				</ul>
				<p>Dati di contatto:</p>
				<ul>
					<li>
						<p>dettagli della residenza,</p>
					</li>
					<li>
						<p>indirizzo di fatturazione,</p>
					</li>
					<li>
						<p>indirizzo di consegna,</p>
					</li>
					<li>
						<p>indirizzo e-mail e numeri di telefono,</p>
					</li>
					<li>
						<p>documentazione di prova dell&apos;indirizzo.</p>
					</li>
				</ul>
				<p>Dati finanziari:</p>
				<ul>
					<li>
						<p>coordinate bancarie,</p>
					</li>
					<li>
						<p>dati della carta di pagamento,</p>
					</li>
					<li>
						<p>importi associati ai conti,</p>
					</li>
					<li>
						<p>dettagli del conto esterno,</p>
					</li>
					<li>
						<p>fonte di finanziamento e relativa documentazione.</p>
					</li>
				</ul>
				<p>Dati transazionali:</p>
				<ul>
					<li>
						<p>dettagli sui pagamenti in entrata e in uscita,</p>
					</li>
					<li>
						<p>altri dettagli di qualsiasi transazione effettuata dall&apos;utente utilizzando i Servizi, il Sito web o l&apos;App.</p>
					</li>
				</ul>
				<p>Dati tecnici:</p>
				<ul>
					<li>
						<p>dati sulla connettivit&agrave; Internet,</p>
					</li>
					<li>
						<p>Indirizzo IP,&nbsp;</p>
					</li>
					<li>
						<p>dati dell&apos;operatore e del gestore,</p>
					</li>
					<li>
						<p>dati di accesso,</p>
					</li>
					<li>
						<p>tipo e versione del browser,</p>
					</li>
					<li>
						<p>tipo di dispositivo, categoria e modello,</p>
					</li>
					<li>
						<p>impostazione del fuso orario e dei dati di localizzazione,</p>
					</li>
					<li>
						<p>dati linguistici,</p>
					</li>
					<li>
						<p>tipi e versioni di plug-in del browser,</p>
					</li>
					<li>
						<p>sistema operativo e piattaforma,</p>
					</li>
					<li>
						<p>dati di diagnostica, come i registri degli arresti anomali e qualsiasi altro dato da noi raccolto ai fini della misurazione della diagnostica tecnica, e</p>
					</li>
					<li>
						<p>altre informazioni memorizzate o disponibili sui dispositivi a cui l&apos;utente ci consente di accedere quando visita il Sito web o utilizza i Servizi o l&apos;App.</p>
					</li>
				</ul>
				<p>&nbsp;</p>
				<p>Dati del profilo:</p>
				<ul>
					<li>
						<p>il vostro nome utente e la vostra password,</p>
					</li>
					<li>
						<p>il vostro numero di identificazione come nostro utente,</p>
					</li>
					<li>
						<p>richieste di prodotti o servizi da parte vostra,</p>
					</li>
					<li>
						<p>i vostri interessi, preferenze e riscontri,</p>
					</li>
					<li>
						<p>altre informazioni generate dall&apos;utente quando comunica con la nostra assistenza clienti.&nbsp;</p>
					</li>
				</ul>
				<p>Tali informazioni possono essere raccolte tramite documenti cartacei, copie scansionate o fax, telefono, e-mail, colloqui verbali, interazioni faccia a faccia o dati ricevuti tramite il nostro sito web o applicazione online. Queste informazioni includono i dati da noi raccolti su di voi e su qualsiasi altra persona con cui trattate attraverso di noi, incluse le controparti o i mittenti o i beneficiari di fondi o servizi.</p>
				<p>Raccogliamo queste informazioni attraverso i nostri dipendenti, le nostre affiliate e le nostre risorse online, le agenzie governative o i nostri partner commerciali, o le agenzie di segnalazione dei consumatori, oppure possono essere fornite da voi stessi quando vi rivolgete a noi per richiedere i nostri servizi.</p>
				<p>Utilizziamo i dati personali nell&apos;ambito della nostra attivit&agrave;, per i seguenti scopi:&nbsp;</p>
				<ul>
					<li>
						<p>Per elaborare le vostre transazioni,</p>
					</li>
					<li>
						<p>Per riscuotere gli importi a noi dovuti,&nbsp;</p>
					</li>
					<li>
						<p>Per attuare i termini e le condizioni del vostro contratto in corso con noi,</p>
					</li>
					<li>
						<p>Per soddisfare gli obblighi legali e normativi, i requisiti di gestione del rischio, per facilitare la prevenzione delle frodi e del riciclaggio di denaro e per garantire la sicurezza,</p>
					</li>
					<li>
						<p>Per scopi promozionali e di marketing, a meno che l&apos;utente non &quot;revochi&quot; o ritiri il consenso a questo scopo specifico, come descritto nella sezione intitolata &quot;Revoca&quot; di seguito,</p>
					</li>
					<li>
						<p>Requisiti di registrazione o di rendicontazione, come richiesto dalla legge o dalle agenzie statutarie,</p>
					</li>
					<li>
						<p>Per soddisfare le esigenze di revisione e assicurazione,</p>
					</li>
					<li>
						<p>Per consentire ai nostri agenti, corrispondenti o partner commerciali di elaborare o dare esecuzione alle vostre transazioni o per soddisfare i loro obblighi legali o normativi,</p>
					</li>
					<li>
						<p>Altrimenti, con il vostro consenso, per qualsiasi scopo.</p>
					</li>
				</ul>
				<p>Non trattiamo &quot;Dati Sensibili&quot;, tranne quando si tratta di dati biometrici trattati allo scopo di identificare in modo univoco una persona fisica.&nbsp;</p>

				<h1>4. Come utilizziamo i vostri dati personali?</h1>

				<p>Ai sensi della legge applicabile, dobbiamo sempre avere una base legittima per l&apos;utilizzo dei dati personali. Ci&ograve; pu&ograve; essere dovuto al fatto che i dati sono necessari per la nostra fornitura di servizi all&apos;utente e per l&apos;esecuzione di un contratto con l&apos;utente stesso, al fatto che l&apos;utente ha acconsentito all&apos;utilizzo dei suoi dati personali o al fatto che &egrave; nel nostro legittimo interesse commerciale utilizzarli. I vostri dati personali possono essere utilizzati per uno dei seguenti scopi:</p>
				<ul>
					<li>
						<p>Fornire e gestire il vostro accesso al nostro sito web;</p>
					</li>
					<li>
						<p>Fornirvi i nostri prodotti e servizi;&nbsp;</p>
					</li>
					<li>
						<p>Comunicare con voi;&nbsp;</p>
					</li>
					<li>
						<p>Fornire all&apos;utente informazioni di marketing e di altro tipo per le quali l&apos;utente ha espresso il proprio consenso (l&apos;utente pu&ograve; annullare l&apos;iscrizione o la revoca in qualsiasi momento facendo clic sul link &quot;annullamento dell&apos;iscrizione&quot; presente nelle nostre e-mail o contattandoci all&apos;indirizzo contact@custodyfy.com);</p>
					</li>
					<li>
						<p>Mantenere la propria contabilit&agrave; e i propri registri;</p>
					</li>
					<li>
						<p>Adempiere agli obblighi legali e normativi per conto nostro e vostro; e</p>
					</li>
					<li>
						<p>Analizzare l&apos;utilizzo del nostro sito web per consentirci di migliorarlo continuamente e di migliorare l&apos;esperienza dell&apos;utente.<br /><br /></p>
					</li>
				</ul>

				<h1>5. Opzione di Revoca</h1>

				<p>Possiamo utilizzare i suoi dati di contatto e le informazioni relative alle sue preferenze di servizio per scopi di marketing e di sollecitazione per inviarle offerte o promozioni di Custodyfy o delle sue affiliate. &Egrave; possibile &quot;revocare&quot; o ritirare il consenso alla divulgazione delle informazioni per gli scopi descritti in questa sezione in qualsiasi momento contattandoci all&apos;indirizzo&nbsp;contact@custodyfy.com.</p>

				<h1>6. Per quanto tempo conserveremo i vostri dati personali?</h1>

				<p>Conserveremo i dati personali dell&apos;utente per il periodo necessario a Custodyfy per fornire i servizi all&apos;utente, o per il periodo pi&ugrave; lungo che potrebbe essere richiesto per elaborare i dati al fine di garantire la nostra conformit&agrave; con tutte le leggi pertinenti e gli obblighi normativi a cui siamo soggetti.</p>
				<h1>7. &nbsp; Come e dove conserviamo i vostri dati personali?</h1>
				<p>I dati personali vengono elaborati sia manualmente che elettronicamente e possono essere conservati in una serie di luoghi diversi in linea con le operazioni di un&apos;azienda tipica. I luoghi in cui i vostri dati personali potrebbero essere conservati includono, a titolo esemplificativo e non esaustivo:&nbsp;<br /><br /></p>
				<ul>
					<li>
						<p>I nostri database e CRM;</p>
					</li>
					<li>
						<p>Email su computer;</p>
					</li>
					<li>
						<p>Telefoni cellulari e tablet;</p>
					</li>
					<li>
						<p>Rubriche di contatto;</p>
					</li>
					<li>
						<p>Biglietti da visita;</p>
					</li>
					<li>
						<p>Quaderni;</p>
					</li>
					<li>
						<p>Documenti elettronici sui nostri file server;</p>
					</li>
					<li>
						<p>Fascicoli cartacei nei nostri uffici;</p>
					</li>
					<li>
						<p>Backup e archiviazione in cloud.</p>
					</li>
				</ul>

				<p>La sicurezza dei vostri dati personali &egrave; importante per noi e per proteggerli utilizziamo e manteniamo adeguate misure tecniche e organizzative per proteggere i vostri dati personali e per prevenire la distruzione accidentale o illegale, la perdita, l&apos;alterazione, la divulgazione non autorizzata o l&apos;accesso ai dati personali trasmessi, memorizzati o altrimenti trattati. Disponiamo di un quadro di politiche, procedure e formazione che coprono la sicurezza informatica e delle informazioni, la riservatezza e la protezione dei dati.&nbsp;</p>
				<h1>8. &nbsp;Condivisione e trasferimento dei dati personali</h1>
				<p>Divulgheremo i dati personali a terzi solo nella misura in cui ci&ograve; sia necessario per fornire i nostri servizi e/o per ottemperare alle vostre istruzioni. Possiamo condividere i vostri dati personali con le nostre affiliate e filiali. Non cediamo n&eacute; vendiamo i vostri dati personali a terzi.<br /><br /></p>
				<p>Inoltre, potremmo essere obbligati per legge a condividere alcuni dati personali, che potrebbero includere i vostri, se siamo coinvolti in procedimenti legali o in ottemperanza a obblighi di legge, a un ordine del tribunale o alle istruzioni di un&apos;autorit&agrave; governativa.<br /><br />Se i vostri dati personali sono richiesti da terzi, adotteremo misure per garantire che i vostri dati personali siano trattati in modo sicuro e conforme ai vostri diritti, ai nostri obblighi e agli obblighi di legge dei terzi.</p>
				<p>Poich&eacute; alcune delle nostre terze parti esterne hanno sede al di fuori dello SEE o del Regno Unito, il trattamento dei vostri dati personali pu&ograve; comportare un trasferimento di dati al di fuori del SEE o del Regno Unito.</p>
				<p>Ogni volta che trasferiamo i vostri dati personali al di fuori del SEE o del Regno Unito, garantiamo un livello di protezione simile, assicurando l&apos;attuazione di almeno una delle seguenti misure di salvaguardia:</p>
				<ul>
					<li>
						<p>il paese in cui trasferiamo i vostri dati personali &egrave; stato ritenuto dalla Commissione Europea un livello adeguato di protezione dei dati personali;</p>
					</li>
					<li>
						<p>un contratto specifico approvato dalla Commissione Europea che fornisce garanzie per il trattamento dei dati personali, le cosiddette Clausole Contrattuali Standard.</p>
					</li>
				</ul>
				<h1>&nbsp; &nbsp;9. &nbsp; Riservatezza</h1>
				<p>Manteniamo salvaguardie fisiche, elettroniche e procedurali per proteggere le informazioni personali da perdita, furto e qualsiasi forma di accesso non autorizzato. Solo i dipendenti, gli agenti o i rappresentanti autorizzati che necessitano di accedere alle informazioni personali dell&apos;utente per adempiere alle proprie mansioni avranno accesso alle informazioni. I nostri obblighi diventeranno effettivi solo quando le informazioni saranno ricevute fisicamente da noi o dai suoi sistemi interni, ma non mentre vengono trasmesse fuori o verso il nostro possesso fisico o i nostri sistemi interni; poich&eacute; lo scambio di dati su Internet o sul sistema telefonico o sulla posta fisica non pu&ograve; essere garantito come perfettamente sicuro, qualsiasi informazione personale che ci inviate o a cui accedete elettronicamente o per telefono &egrave; fatta a vostro rischio e pericolo, e noi non garantiamo o assicuriamo la sicurezza delle informazioni cos&igrave; trasmesse a noi. L&apos;utente &egrave; tenuto a prestare attenzione per evitare che terzi, o soggetti che si spacciano fraudolentemente per noi, possano intercettare illegalmente le sue trasmissioni o indurlo a divulgare informazioni personali.</p>
				<h1>&nbsp; &nbsp;10. Consenso</h1>
				<p>Utilizzando il nostro sito web o i nostri servizi, l&apos;utente accetta l&apos;informativa sulla privacy di Custodyfy.</p>
				<p>La presente Informativa sulla Privacy illustra le nostre intenzioni in merito alle informazioni personali raccolte dall&apos;utente. Fornendoci informazioni personali, si presume che l&apos;utente acconsenta alla raccolta, all&apos;utilizzo e alla divulgazione di tali informazioni per gli scopi descritti nella presente Informativa sulla privacy, ove applicabile. Si prega di notare che il vostro consenso implica il consenso all&apos;utilizzo dei vostri indirizzi e-mail, indirizzi postali e altre risorse di contatto che ci fornite per comunicare con voi.</p>
				<p>Il vostro consenso pu&ograve; essere dato in vari modi, quali: oralmente, elettronicamente o su un documento in qualsiasi forma; o implicitamente scegliendo volontariamente di utilizzare i nostri servizi; non ritirando il consenso all&apos;utilizzo dei vostri dati personali per qualsiasi scopo identificato; non utilizzando un&apos;opzione di &quot;revoca&quot; fornita per gli scopi specificati nella presente informativa, se presente. Tale consenso pu&ograve; essere fornito direttamente dall&apos;utente o dal suo rappresentante legale</p>
				<p>La revoca del consenso sar&agrave; interamente a scelta dell&apos;utente in qualsiasi momento e sar&agrave; efficace, tranne nei casi in cui siamo soggetti a obblighi legali, contrattuali, normativi, governativi e/o statutari per la conservazione e la divulgazione dei dati; e tranne nel caso di transazioni gi&agrave; avviate che richiedono l&apos;uso delle informazioni personali dell&apos;utente per l&apos;adempimento. Si noti che in caso di revoca del consenso, potremmo non essere pi&ugrave; in grado di fornire alcuni dei nostri prodotti o servizi che richiedono i vostri dati personali.</p>
				<h1>&nbsp; 11. &nbsp;I vostri diritti</h1>
				<p>Ai sensi della presente Informativa sulla Privacy, l&apos;utente detiene i seguenti diritti:</p>
				<p>a) Il diritto di essere informati sulla nostra raccolta e sull&apos;uso dei vostri dati personali.</p>
				<p>b) Il diritto di accedere ai dati personali in nostro possesso: l&apos;utente ha il diritto di ottenere la conferma se trattiamo o meno i suoi dati personali e alcune informazioni su come e perch&eacute; li trattiamo.</p>
				<p>c) il diritto di ottenere la modifica o la rettifica dei vostri dati personali, qualora i vostri dati personali in nostro possesso siano inesatti o incompleti.&nbsp;</p>
				<p>d) il diritto alla cancellazione (noto anche come diritto all&apos;oblio), ossia il diritto di chiederci di cancellare o di disporre in altro modo dei vostri dati personali in nostro possesso.&nbsp;</p>
				<p>e) il diritto di limitare il trattamento dei vostri dati personali.</p>
				<p>f) il diritto di opporsi all&apos;utilizzo dei vostri dati personali per uno o pi&ugrave; scopi particolari.</p>
				<p>g) Il diritto alla portabilit&agrave; dei dati. Ci&ograve; significa che, se ci avete fornito direttamente i vostri dati personali, se li stiamo utilizzando con il vostro consenso o per l&apos;esecuzione di un contratto o la fornitura di servizi, e se tali dati sono trattati con mezzi automatizzati, potete chiederci una copia di tali dati personali per riutilizzarli con un altro servizio o attivit&agrave;.&nbsp;</p>
				<p>h) Il diritto di presentare un reclamo.&nbsp;Se risiedete in uno Stato membro del SEE, avete il diritto di presentare un reclamo sul modo in cui trattiamo i vostri dati personali all&apos;autorit&agrave; di vigilanza dello Stato membro del SEE in cui risiedete abitualmente, in cui lavorate o in cui &egrave; avvenuta la presunta violazione.</p>
				<p>i) Diritti relativi al processo decisionale automatizzato e alla profilazione. Non utilizziamo i vostri dati personali in questo modo.</p>
				<p>Per ulteriori informazioni sull&apos;utilizzo dei vostri dati personali da parte nostra o per esercitare i vostri diritti di cui sopra, o nel caso in cui desideriate presentare un reclamo, contattateci all&apos;indirizzo contact@custodyfy.com.</p>
				<h1>12. Cookie</h1>
				<p>Questa sezione si applica a tutti i nostri siti web (il&nbsp;&quot;Sito&quot;). Quando ci riferiamo a uno qualsiasi dei nostri siti web, intendiamo qualsiasi sito web o applicazione mobile gestita da o per conto della nostra azienda o delle sue consociate e affiliate, indipendentemente dalla modalit&agrave; di accesso alla rete.&nbsp;</p>
				<p>Accedendo al sito web, l&apos;utente accetta che questa sezione si applichi ogni volta che accede al sito web su qualsiasi dispositivo.</p>
				<p>Eventuali modifiche a questa politica saranno pubblicate qui. Ci riserviamo il diritto di variare questa sezione di volta in volta e tali modifiche entreranno in vigore non appena saranno pubblicate. L&apos;uso continuato del sito web da parte dell&apos;utente implica l&apos;accettazione di tali modifiche.</p>
				<p>La presente informativa spiega come vengono utilizzati i cookie sul nostro sito web in generale e, di seguito, come &egrave; possibile controllare i cookie che possono essere utilizzati su questi siti (non tutti sono utilizzati su ogni sito).</p>
				<p>Cosa sono i cookie e come li utilizziamo?</p>
				<p>I cookie sono piccoli file di testo memorizzati sul vostro computer dal vostro browser. Vengono utilizzati per molte cose, ad esempio per ricordare se avete gi&agrave; visitato il sito in passato, in modo che rimaniate connessi, o per aiutarci a capire quanti nuovi visitatori del sito web riceviamo ogni mese. Contengono informazioni sull&apos;utilizzo del vostro computer, ma non includono informazioni personali su di voi (non memorizzano il vostro nome, ad esempio).</p>
				<p>Utilizziamo i cookie per fornire i servizi e le funzionalit&agrave; offerte sui nostri siti web e per migliorare continuamente l&apos;esperienza dell&apos;utente.</p>

				<p>Possiamo anche utilizzare Google Analytics per tracciare e analizzare il traffico del nostro sito al fine di migliorare la funzionalit&agrave; del nostro sito web raccogliendo dati su come, quando e dove le persone utilizzano o accedono al nostro sito web. Google Analytics non raccoglie dati di identificazione personale.</p>

				<p>Come si possono gestire i cookie?</p>
				<ul>
					<li>
						<h3>Rimozione dei cookie dal dispositivo</h3>
					</li>
				</ul>
				<p>&Egrave; possibile eliminare tutti i cookie gi&agrave; presenti sul dispositivo cancellando la cronologia di navigazione del browser. In questo modo verranno rimossi tutti i cookie di tutti i siti web visitati. Tuttavia, &egrave; possibile che si perdano anche alcune informazioni salvate (ad esempio, i dati di accesso salvati, le preferenze del sito).</p>
				<ul>
					<li>
						<h3>Gestione dei cookie specifici del sito</h3>
					</li>
				</ul>
				<p>Per un controllo pi&ugrave; dettagliato sui cookie specifici del sito, controllare le impostazioni sulla privacy e sui cookie del proprio browser preferito.</p>
				<ul>
					<li>
						<h3>Blocco dei cookie</h3>
					</li>
				</ul>
				<p>&Egrave; possibile impostare la maggior parte dei browser moderni in modo da impedire l&apos;inserimento di cookie sul dispositivo, ma potrebbe essere necessario regolare manualmente alcune preferenze ogni volta che si visita un sito/pagina. Inoltre, alcuni servizi e funzionalit&agrave; potrebbero non funzionare correttamente (ad esempio, l&apos;accesso al profilo).</p>
				<ul>
					<li>
						<h3>Gestione dei cookie analitici</h3>
					</li>
				</ul>
				<p>&Egrave; possibile gestire le proprie preferenze relative ai cookie quando si accede al nostro sito web.&nbsp;</p>
				<h1>&nbsp; 13. &nbsp;Contatti</h1>
				<p>Per contattarci in merito a qualsiasi questione connessa ai vostri dati personali e la protezione dei dati, compresa una richiesta di accesso, vi preghiamo di utilizzare i seguenti dati:<br /><br /></p>
				<p>Persona responsabile: Responsabile della protezione dei dati.&nbsp;<br /><a href="mailto:dpo@custodyfy.com">dpo@custodyfy.</a>com&nbsp;</p>
				<h1>&nbsp; 14. &nbsp;Modifiche alla presente Informativa sulla privacy</h1>
				<p>La presente Informativa sulla Privacy pu&ograve; essere modificata periodicamente. Ci&ograve; potrebbe essere necessario, ad esempio, in caso di modifiche alla legge o di cambiamenti nella nostra attivit&agrave; che influiscono sulla protezione dei dati personali.</p>
				<p>Eventuali modifiche saranno pubblicate sul nostro sito web e si riterr&agrave; che l&apos;utente abbia accettato i termini dell&apos;Informativa sulla Privacy al primo utilizzo del nostro sito web successivo alle modifiche.&nbsp;</p>
				<p>Questa politica &egrave; stata aggiornata l&apos;ultima volta il 21 maggio 2022.</p>

			</Segment>
		</Container>
	);
}

export default PrivacyPolicyITCUSTODYFY;
