import React, { useEffect } from 'react';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';
import { Form, Input, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Token } from '@/features/tokens/types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { showInfoNotification } from '@/features/swal/slice';
import { tokenDescription } from '../../Tokens';
import useFileUpload from '@/helpers/customHook/useFileUpload';
import FileUpload from '@/components/Shared/FileUpload';
import { isValidTokenWallet } from '@/features/tokens/slice';


export interface SendToUserPayload {
	token: Token,
	amount: number,
	walletId: string,
	reference?: string,
	selectedFile?: any,
}
interface Props {
	token: Token,
	payload: SendToUserPayload,
	onConfirm: (value: SendToUserPayload) => void
}


const SendToUserForm = ({ token, payload, onConfirm }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const fileUpload = useFileUpload(1);

	useEffect(() => {
		if (payload?.selectedFile && fileUpload.selectedFiles.length === 0) {
			fileUpload.setSelectedFiles([payload.selectedFile]);
		}
	}, [payload?.selectedFile]);


	const initialValues = {
		userToken: tokenDescription(payload?.token ?? token),
		amount: payload?.amount ?? '',
		reference: payload?.reference ?? '',
		walletId: payload?.walletId ?? ''
	};

	const rawCurrentHoldings = parseFloat(token?.availableHoldings ?? '0');

	const validationSchema = Yup.object({
		userToken: Yup.string().required(t('form.validator.required')),
		amount: Yup.number().label(t('tokens.header.transferAmount'))
			.required(t('form.validator.required'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.min(0.00001)
			.test('amount', t('tokens.header.maxTokens', { amount: rawCurrentHoldings }), (value) => value <= rawCurrentHoldings),
		reference: Yup.string().required(t('form.validator.required')).max(140, t('sendmoneyTranslation.data.canNotExceedHundred')),
		walletId: Yup.string().required(t('form.validator.required')),
	});

	const submit = async (formData, formikProps) => {
		const { amount } = formData;
		const fiat = amount * parseFloat(token.price);
		const { setSubmitting, setFieldError } = formikProps;
		if (!fileUpload.isValidFiles(fiat)) {
			showInfoNotification('sendmoneyTranslation.data.documentRequired');
			setSubmitting(false);
			return;
		}

		const { walletId } = formData;
		const walletIsValid = await isValidTokenWallet(walletId);
		if (walletIsValid) { onConfirm({ ...formData, selectedFile: fileUpload.selectedFiles[0], token: token }); }
		else {
			setFieldError('walletId', t('tokens.header.userNotFound'));
		}

		setSubmitting(false);
	};


	return (
		<Grid.Column width={11}>
			<Segment className='token-trade-window'>
				<Header>{t('sendmoneyTranslation.data.cospayuserheader')}</Header>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting }) => {
						return (
							<Form >
								<Input fluid
									className="token-disabled"
									name="userToken"
									label={t('tokens.header.token')}
									disabled={true}
									type="text"
								/>
								<Input
									fluid
									name="amount"
									label={t('tokens.header.transferAmount')}
									type="number"
									placeholder={'0.00 '+token.symbol}
									errorPrompt
									onWheel={(e) => e.target.blur()}
								/>
								<Divider hidden />
								<Header dividing> {t('tokens.header.details')}</Header>
								<Divider hidden />
								<TextArea
									fluid
									name="reference"
									errorPrompt
									label={t('tokens.header.reference')}
									maxLength="140"
								/>
								<Input fluid
									name="walletId"
									label={t('tokens.header.cryptowalletid')}
									errorPrompt
								/>
								<FileUpload
									selectedFiles={fileUpload.selectedFiles}
									hideMaxSize={true}
									reverse={true}
									instruction={<><b> {t('tokens.header.paymentDocument')}</b> <br />{t('tokens.header.fileInstruction')}</>}
									uploadFiles={fileUpload.uploadFiles}
									deleteAttachment={fileUpload.deleteAttachment} />
								<SubmitButton
									className='tokens-trade-submit'
									disabled={!isEmpty(errors) || isSubmitting}
									primary fluid size="large" type="submit">
									{t('form.buttons.submit')}
								</SubmitButton>
							</Form>
						);
					}}
				</Formik>
			</Segment>
		</Grid.Column >
	);
};


export default SendToUserForm;
