import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Credentials } from './types';
const credentialsKey = process.env.REACT_APP_CREDENTIALS_KEY;

const savedCredentials = sessionStorage.getItem(process.env.REACT_APP_CREDENTIALS_KEY);

const initialState: Credentials = { email: null, token: null };
if (savedCredentials) {
	try {
		const credentials = JSON.parse(savedCredentials);
		initialState.email = credentials.email;
		initialState.token = credentials.token;
	}
	catch (e) {
		console.log(e);
	}
}

const credentialsSlice = createSlice({
	name: 'credentials',
	initialState,
	reducers: {
		setCreditials(state, action: PayloadAction<Credentials>) {
			state.email = action.payload.email;
			state.token = action.payload.token;
			sessionStorage.setItem(credentialsKey, JSON.stringify({ email: action.payload.email, token: action.payload.token }));
		},
		clearCreditials(state,) {
			state.email = null;
			state.token = null;
			sessionStorage.removeItem(credentialsKey);
		}
	}
});

export const { setCreditials, clearCreditials } = credentialsSlice.actions;

export const credentialsSelector = (state: { credentialsStore: Credentials }) =>
	state.credentialsStore;

export default credentialsSlice.reducer;
