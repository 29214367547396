import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '@services/request';
import { AppThunk } from '@/store';
import { KycCalendar, KycCalendarStore } from './types';
import { SocketService } from '@/services/socketService';
import { Subscription } from 'rxjs';


const initialState: KycCalendarStore = {
	loading: false,
	error: null,
	booked: [],
	reservation: null
};

const slice = createSlice({
	name: 'kycCalendar',
	initialState,
	reducers: {
		setBooked(state, action: PayloadAction<Array<string>>) {
			state.booked = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setKycCalendar: (state, { payload }: PayloadAction<KycCalendar>) => {
			state.reservation = payload;
		}
	}
});

export const { setBooked, setLoading, setError, setKycCalendar } = slice.actions;

export const getReservedTimeSlots = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/identity/calendar/reserved');
			const { data } = response;
			dispatch(setBooked(data));
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			console.log(e);
		}
	};
};

export const getUserReservation = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/identity/calendar/me/reservation');
			const { data } = response;
			dispatch(setKycCalendar(data));
			return data;
		} catch (e) {
			console.log(e);
		}
	};
};

let socketService: SocketService;
let bookedSubscriber: Subscription;
let reservationSubscriber: Subscription;


export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('kyc-calendar');
	}
};

export const disconnect = (): void => {
	socketService = null;
};

export const unsubscribe = (): void => {
	bookedSubscriber.unsubscribe();
	reservationSubscriber.unsubscribe();
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		try {
			bookedSubscriber = socketService.listen('calendar.booked', {}).subscribe((data) => {
				if (data === 'booked') {
					dispatch(getReservedTimeSlots());
				}
			});
			reservationSubscriber = socketService.listen('calendar.reservation', {}).subscribe((data) => {
				dispatch(setKycCalendar(data));
			});
		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const scheduleMeeting = async (start: number, language: string) => {
	const response = await request.post('/api/identity/calendar', { start, language });
	const { data } = response;
	return data;
};

export const cancelMeeting = async (id: number) => {
	const response = await request.delete(`/api/identity/calendar/me/reservation/${id}`);
	const { data } = response;
	return data;
};

export default slice.reducer;


