import React, { useState, useEffect } from 'react';
import { Segment, Header, Grid, Container, Button, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { ixopayComplete } from '@features/operations/slice';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { SucessStatus } from '@/icons';
import Loading from '@/components/Shared/Loading';


const CardTransferSuccess = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const [activityId] = useState<string>(searchParams.get('ActivityId'));
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [syncCompleted, setSyncCompleted] = useState(false);

	useEffect(() => {
		const get = async () => {
			try {
				setLoading(true);
				setError(false);
				await ixopayComplete(activityId);
			}
			catch (e) {
				setError(true);
			}
			finally {
				setLoading(false);
				setSyncCompleted(true);
			}

		};
		if (activityId) {
			get();
		}

	}, [activityId]);

	return (
		<Grid>
			<Grid.Row centered>
				<Container text textAlign="center">
					<Segment padded="very" className="submitSegment">
						<SucessStatus id="successCheckIcon" />
						<Header as="h2">{t(error ? 'paymentsubmitted.error' : 'paymentsubmitted.topupSuccess')}</Header>
						{loading && <Loader>{'Loading'}</Loader>}
						<Button as={Link} to="/wallet" basic className='retrunDashboard' disabled={loading || syncCompleted === false}>{t('paymentsubmitted.button')}</Button>
					</Segment>
				</Container>
			</Grid.Row>
		</Grid>
	);
};
export default CardTransferSuccess;
