import React, { useState } from 'react';
import {
	Segment,
	Grid,
	Header,
	Button,
} from 'semantic-ui-react';

interface Props {
    message: string
}

enum Tiket {
    ToCreate = 0,
    Creating = 1,
    Created = 2
}

const AccountsLoading = ({ message }: Props) => {
	const [tiket, setTiket] = useState<Tiket>(Tiket.ToCreate);

	let button;
	if (tiket === Tiket.Created) {
		button = <Button primary disabled>We will take care of your request</Button>;
	} else {
		button = <Button primary loading={tiket === Tiket.Creating} onClick={() => { setTiket(Tiket.Creating); setTimeout(() => { setTiket(Tiket.Created); }, 500); }}>Create ticket</Button>;
	}

	return (
		<Segment padded="very" basic>
			<Grid>
				<Grid.Column textAlign="center">
					<Header>Something was wrong</Header>
					<div>{message}</div>
					<br />
					{button}
				</Grid.Column>
			</Grid>
		</Segment>
	);
};

export default AccountsLoading;
