import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TokenTransactionStatus } from '@/features/tokens/types';


interface Props {
	status: TokenTransactionStatus
}

const statusIcon = (value: TokenTransactionStatus) => {
	let element;
 
	switch (value) {
		case TokenTransactionStatus.OFFER:
			element = <Icon className="approvalicon" name="clock outline" />;
			break;
		case TokenTransactionStatus.REQUEST:
			element = <Icon className="approvalicon" name="clock outline" />;
			break; 
		case TokenTransactionStatus.USED:
			element = <Icon className="approvedicon" name="check" />;
			break;
		default:
			element = <Icon className="approvalicon" name="clock outline" />;
	}
	return element;
};

const TransactionStatus = ({ status }: Props) => {
	const { t } = useTranslation('translations');
	return (<>
		{statusIcon(status)}  {t('tokens.status.'+status)}

	</>
	);
};

export default TransactionStatus;
