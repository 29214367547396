import React from 'react';
import { Grid, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface Props {
	twoFactorCode: string
}

const ScanCode = ({ twoFactorCode }: Props) : React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<>
			<Grid.Row centered><h2>{ t('authenticator.code.header') }</h2></Grid.Row>
			<Grid.Row>{ t('authenticator.code.description') }</Grid.Row>
			<Grid.Row><Grid.Column>
				<Image src={twoFactorCode} style={{ margin: '0 auto' }} />
			</Grid.Column></Grid.Row>
		</>
	);
};

export default ScanCode;
