import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../Profile.css';
import {
	Header,
	Divider,
	Modal,
	Checkbox,
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import { registerBusiness } from '@features/user/userSlice';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import { alpha2ToAlpha3, alpha3ToAlpha2 } from 'i18n-iso-countries';
import { RootState } from '@/rootReducer';
import { getAllowedCountries } from '@/features/countries/slice';
interface Props {
	open: boolean,
	onClose: () => void,
	countries: any
}

const OpenBusinessProfile = ({ countries, open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [liablePerson, setLiablePerson] = useState(false);
	const dispatch = useDispatch();
	const [countryOptions, setCountryOptions] = useState<Array<any>>();

	useEffect(() => {
		setCountryOptions(Object.keys(countries)
			.map($code => ({
				code: $code,
				label: countries[$code],
			})));
	}, [countries]);

	useEffect(() => {
		dispatch(getAllowedCountries());
	}, []);

	const allowedCountries = useSelector(
		(state: RootState) => state.allowedCountries
	);

	const initialValues = {
		companyName: '',
		companyRegNumber: '',
		companyAddress: '',
		companyCountry: ''
	};

	const validationSchema = Yup.object({
		companyName: Yup.string()
			.min(2, t('form.validator.companymin'))
			.max(99, t('form.validator.maxChars', { chars: 100 }))
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-&@£$€#:;.,'`\s])+$/, t('form.validator.companyName')),
		companyRegNumber: Yup.string()
			.min(2, t('form.validator.companymin'))
			.max(49, t('form.validator.maxChars', { chars: 50 }))
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		companyAddress: Yup.string()
			.min(2, t('form.validator.companymin'))
			.max(299, t('form.validator.maxChars', { chars: 300 }))
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		companyCountry: Yup.string()
			.required(t('form.validator.required'))
			.test('available', '', (value) => {
				return allowedCountries.list.includes(alpha3ToAlpha2(value));
			})
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			await dispatch(registerBusiness(formData));
			showSuccessNotification(t('swal.SUCCESS'));
			onClose();
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Modal
			id='openBusinessModal'
			size='tiny'
			onClose={onClose}
			open={open}
		>
			<Modal.Content>
				<Header content={t('profile.data.openBusiness')} />
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>{({ errors, values, isSubmitting, dirty }) => {
					return (
						<Form
							size="large">
							<Input
								fluid
								name="companyName"
								label={t('form.fields.legalname')}
								errorPrompt
							/>
							<Input
								fluid
								name="companyRegNumber"
								label={t('form.fields.registrationnumber')}
								errorPrompt
							/>
							<Input
								fluid
								name="companyAddress"
								label={t('form.fields.legaladress')}
								errorPrompt
							/>
							<Select
								fluid
								name="companyCountry"
								label={t('form.fields.companycountry')}
								errorPrompt
								search
								clearable
								options={countryOptions.map(c => ({ key: alpha2ToAlpha3(c.code), value: alpha2ToAlpha3(c.code), text: c.label }))} />
							{values?.companyCountry && (
								errors?.companyCountry === '' ?
									<p className="color-red">
										{t('registration.restriction')}
									</p> :
									<p className="color-green">
										{t('registration.available')}
									</p>)
							}
							<Header content='Compliance' />
							<Checkbox label="I'm an authorised signatory person for the company" value={liablePerson ? 1 : 0} onChange={() => { setLiablePerson(!liablePerson); }} />
							<Divider clearing hidden />
							<SubmitButton
								className="registerbutton"
								disabled={isSubmitting || !isEmpty(errors) || !dirty || !liablePerson}
								primary size="large" type="submit">
								{t('registration.submit')}
							</SubmitButton>
						</Form>);
				}
					}
				</Formik>
			</Modal.Content>
		</Modal>);
};

export default OpenBusinessProfile;
