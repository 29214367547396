import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Divider, Grid, Header, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import isEmpty from 'is-empty';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import Activity from '@icons/Notifications/Activity';
import { CardType } from '@features/card/types';
import { Link } from 'react-router-dom';

interface PanLast6DigitsModalProps {
	open: boolean,
	onClose: (value?: string) => void,
	type: CardType
}

function PanLast6DigitsModal({ open, onClose, type }: PanLast6DigitsModalProps): React.ReactElement {
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const [code, setCode] = useState<string>('');
	const { t } = useTranslation('translations');
	const initialValues = { panLast6digits: '' };
	const validationSchema = Yup.object({
		panLast6digits: Yup.string().min(6, t('form.validator.codeSixDigits')).max(6, t('form.validator.codeSixDigits'))
	});

	const submit = (formData) => {
		const { panLast6digits } = formData;
		onClose(panLast6digits);
	};

	const handleChange = (...args) => {
		const newValue: string = args[1].value.toString(); 
		const re = /^[0-9]+$/;
		if (newValue === '' || re.test(newValue)) {
			setCode(newValue);
		}
	};
 
	return <Modal
		size="tiny"
		onClose={() => onClose()}
		open={open}
	>
		<Modal.Content >
			<Grid padded centered columns="1">
				<Grid.Row>
					<Grid.Column width="16">
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="10" textAlign="center">
						<Activity />
						<Modal.Content image>
							<Modal.Description>
								<Header>{t('cards.inactive.modal.header')}</Header>
								{type === CardType.VIRTUAL && <p>{t('cards.inactive.modal.descriptionStart')}<strong>{user.mobileNumber}</strong>{t('cards.inactive.modal.descriptionEnd')}</p>}
								{type === CardType.PHYSICAL && <p>{t('cards.inactive.modal.physicalStart')}<b>{t('cards.inactive.modal.lastDigits')}</b>{t('cards.inactive.modal.physicalEnd')}</p>}
							</Modal.Description>
						</Modal.Content>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width="16">
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting, dirty }) => (
								<Form
									className="twofaform"
									size="large"
									style={{ margin: '0 auto 2rem' }}
								>
									<Input
										autoComplete="off"
										fluid
										autoFocus
										value={code}
										name="panLast6digits"
										placeholder={t('form.placeholder.code')}
										errorPrompt
										onChange={handleChange}
									/>
									{type === CardType.VIRTUAL && <>
										<span>{t('cards.inactive.modal.label')}</span>
										<br/>
										<span>{t('cards.inactive.modal.go')}<Link to='/wallet/account'>{t('cards.inactive.modal.profile')}</Link></span>
									</>}
									<Divider clearing />
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary size="large" type="submit">
										{t('form.buttons.submit')}
									</SubmitButton>
								</Form>)}
						</Formik>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</Modal.Content >
	</Modal >;
}

export default PanLast6DigitsModal;
