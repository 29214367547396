import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import { Contact, ContactBody, ContactStore } from './types';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import request from '@services/request';

const initialState: ContactStore = {
	loading: true,
	contacts: [],
	error: null
};

const slice = createSlice({
	name: 'contacts',
	initialState,
	reducers: {
		setContacts(state, action: PayloadAction<Array<Contact>>) {
			state.contacts = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		}
	}
});

export const { setContacts, setLoading, setError } = slice.actions;

export const contactsSelector = (state: { store: ContactStore }): ContactStore =>
	state.store;

let socketService: SocketService;
let updateSubscriber: Subscription;

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('account');
	}
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		updateSubscriber = socketService.listen('contacts', {}).subscribe(() => {
			dispatch(getContacts());
		});
	};
};

export const unsubscribe = (): void => {
	updateSubscriber.unsubscribe();
};

export const getContacts = (): AppThunk => {
	return async (dispatch) => {
		try {
			const response = await request.get('/api/beneficiary');
			const { data } = response;
			dispatch(setContacts(data.filter(contact => contact.paymentType !== 'CRYPTO')));
			return data;
		} catch (e) {
			dispatch(setError(e));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const addContact = (payload: ContactBody): AppThunk => {
	return async dispatch => {
		const response = request.post('/api/beneficiary',
			payload
		);
		await response;
		dispatch(setError(null));
	};
};

export const editContact = (id: number, payload: ContactBody): AppThunk => {
	return async dispatch => {
		const response = request.put('/api/beneficiary/' + id,
			payload
		);
		await response;
		dispatch(setError(null));
	};
};

export const deleteContact = (id: number): AppThunk => {
	return async dispatch => {
		const response = request.delete('/api/beneficiary/' + id);
		await response;
		dispatch(setError(null));
	};
};

export const searchContact = async (text: string) => {
	const { data } = await request.get(`/api/beneficiary/lookup?text=${text}`
	);
	return data;
};

export const findContact = async (text: string, type: string) => {
	const { data } = await request.get(`/api/beneficiary/lookup/${type}?text=${text}`
	);
	return data;
};


export const lookupContact = async (walletId: string, currency?: string) => {
	let optionalParams = '';
	if (currency) { optionalParams = `&ccy=${currency}`; }

	const { data } = await request.get(`/api/accounts/lookup?walletId=${walletId}${optionalParams}`);
	return data;
};

export const getDataByIban = async (iban: string) => {
	const { data } = await request.get(`/api/beneficiary/iban/${iban}`
	);
	return data;
};

export const getBicUs = async (bic: string): Promise<boolean> => {
	try {
		await request.get(`/api/beneficiary//bic/us/${bic}`);
		return true;
	}
	catch (e) {
		return false;
	}
};

export default slice.reducer;
