import request from '@services/request';
import { Partner, TransferRequest } from '@features/identity/types';

export const isVerifiedForPartner = async (): Promise<boolean> => {
	const response = await request.get('/api/identity/verified-for-partner');
	const { data } = response;
	const { verifiedForPartner } = data;
	return verifiedForPartner;
};

export const getPartnerList = async (): Promise<Partner[]> => {
	const response = await request.get('/api/partners');
	const { data } = response;
	return data;
};

export const transferVerification = async (payload: TransferRequest): Promise<void> => {
	await request.post('/api/identity/copy-from-partner', payload);
};
