import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';
import * as Onfido from 'onfido-sdk-ui';
import { openModal } from '@features/swal/slice';
import { createOnfidoCheck } from '@/features/kyc/slice';
import { showErrorNotification } from '@/features/swal/slice';

import './Onfido.css';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';

interface OnfidoProps {
	token: string,
	onClose: () => void
}

const OnfidoForm = ({ token, onClose }: OnfidoProps) => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();

	const { agent } = useSelector(
		(state: RootState) => state.status
	);
	const [loading, setLoading] = useState(false);
	const [onfidoInstance, setOnfidoInstance] = useState<Onfido.SdkHandle | null>(null);

	const initOnfido = async () => {
		try {
			setLoading(true);

			const instance = Onfido.init({
				useModal: false,
				token,
				onComplete: async () => {
					try {
						await dispatch(createOnfidoCheck());
					}
					catch (e) {
						showErrorNotification(e);
					}
				},
				onUserExit: (data) => { if (data === 'USER_CONSENT_DENIED') { onClose(); } },
				onError: (data) => {
					// this is Onfido error datatype
					dispatch(openModal({ header: 'Error:' + data.type, content: data.message }));
				},
				steps: [
					'userConsent',
					{
						type: 'welcome',
						options: {
							title: 'Verify your identity',
						},
					},
					{
						type: 'document',
						options: {
							documentTypes: {
								passport: true,
								national_identity_card: true,
								driving_licence: agent === Agents.DTSUS ? true : false
							}
						}
					},
					'face',
					// {
					// 	type: 'poa',
					// 	options: {
					// 		country: 'AUS'
					// 	}
					// },
					'complete',
				],
			});

			setOnfidoInstance(instance);
			setLoading(false);
		} catch (e) {
			setLoading(false);
			showErrorNotification(e);
		}
	};

	useEffect(() => {
		initOnfido();
		return () => {
			onfidoInstance && onfidoInstance.tearDown();
		};
	}, []);

	return (
		<Modal.Content>
			<div id="onfido-mount">{loading && <div>Loading...</div>}</div>
			<Button onClick={onClose}>{t('form.buttons.close')}</Button>
		</Modal.Content>
	);
};

export default OnfidoForm;
