import React, { useCallback, useEffect, useState } from 'react';
import { Dimmer, Grid, Header, Icon, Loader, Menu, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import BulkTopUpConfirmation from '@/components/Banking/Card/TopUp/Bulk/BulkTopUpConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getActiveEmployees } from '@features/employee/slice';
import { showErrorNotification } from '@features/swal/slice';
import { postBulkTopUp } from '@features/files/slice';
import './BulkTopUp.css';
import { getBulkTopUp } from '@features/card/slice';

const BulkTopUp = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [transfers, setTransfers] = useState(null);
	const [loading, setLoading] = useState(false);
	//TODO Store externally
	const xlsxTemplateUrl = process.env.REACT_APP_BULK_CARD_TOP_UP_XLSX_URL;
	const csvTemplateUrl = process.env.REACT_APP_BULK_CARD_TOP_UP_CSV_URL;

	const { activeEmployees } = useSelector(
		(state: RootState) => state.employee
	);

	const fetchActiveEmployees = async () => {
		try {
			await dispatch(getActiveEmployees());
		} catch (e) {
			showErrorNotification(e);
		}
	};
	const dispatch = useDispatch();
	useEffect(() => {
		fetchActiveEmployees();
	}, [dispatch]);

	const onUpload = async(file) => {
		const formData = new FormData();
		formData.append('transactions', file);

		try {
			const response = await postBulkTopUp(formData);
			setTransfers(response);
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	const onDrop = useCallback((acceptedFiles) => {
		setLoading(true);
		acceptedFiles.forEach((file) => {
			onUpload(file);
		});
	}, []);

	const { getRootProps, getInputProps } = useDropzone({ onDrop });

	const onDiscard = () => {
		//TODO Get api to cancel bulk
		setTransfers(null);
	};

	const onChange = async () => {
		const response = await getBulkTopUp(transfers.sessionId);
		setTransfers(response);
	};

	if(loading) {
		return <Dimmer active inverted>
			<Loader />
		</Dimmer>;
	}

	if(transfers) {
		return <BulkTopUpConfirmation transfers={transfers} employees={activeEmployees} onDiscard={onDiscard} onChange={onChange}/>;
	}

	return (
		<>
			<div id='bulk-top-up'>
				<Grid stackable columns="equal">
					<Grid.Column width={5}>
						<Menu fluid vertical className="vertical-menu">
							<Menu.Item
								name={t('cards.bulk.header')}
								active={true}
								icon="chevron right" />
						</Menu>
					</Grid.Column>
					<Grid.Column>
						<Segment className='container'>
							<Grid padded>
								<Grid.Row>
									<Header className="section-header">{ t('cards.bulk.header') }</Header>
								</Grid.Row>
								<Grid.Row>
									<div className='text'>Download bulk payment upload templates:</div>
								</Grid.Row>
								<div className='template-download'>
									<a target='_blank' rel='noreferrer' href={xlsxTemplateUrl} className='text'><Icon size="large" name="file outline"/>{t('cards.bulk.xlsx')}</a>
									<a target='_blank' rel='noreferrer' href={csvTemplateUrl} className='text padding-left'><Icon size="large" name="file outline"/>{t('cards.bulk.csv')}</a>
								</div>
								<Grid.Row>
									<div { ...getRootProps() } id='file-container'>
										<input { ...getInputProps() } id='file-drop'/>
										<span><Icon name='upload'/>{ t('employee.verification.form.document_front.upload') }</span>
									</div>
								</Grid.Row>
							</Grid>
						</Segment>
					</Grid.Column>
				</Grid>
			</div>
		</>
	);
};
export default BulkTopUp;
