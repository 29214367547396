import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './TermsOfUse.css';

function TermsOfUseYESSY(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered>
					<h1>Terms of Use</h1>
				</Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<p>
					Please read these terms of use carefully before using this site.
				</p>
				<h1>1. About our terms</h1>
				<p>
					These terms and conditions of use (&quot;Terms&quot;) explain how you may use this
					website and any of its content (&quot;Site&quot;). These Terms apply between,
					respectively,
				</p>
				<ol type="i">
					<li>
						XWARD PAY (hereinafter &quot;XWARD&quot;), a Canadian Incorporated Money Service
						Business Company with incorporation number BC1332850 and FINTRAC
						registration number M21791680, located at Suite 2500, Park Place 666,
						Burrard St., Vancouver, BC, V6C 2X8, Canada;
					</li>

					<li>
						Get in touch s.r.l, (hereinafter &quot;GIT&quot;) an Italian incorporated Company
						with incorporation number 03044290306, registered under the &quot;Organismo
						Agenti e Mediatori&quot; (hereinafter &quot;OAM&quot;) as a Virtual Currency Operator
						OAM under registration number 165635, and located at Via Alpe Adria 6,
						33010 Tavagnacco (UD) Italy, ((i) and (ii) together &quot;us&quot; or &quot;we&quot;), and;
					</li>

					<li>
						You, the person accessing or using the Site (&quot;you&quot; or &quot;your&quot;).
					</li>
				</ol>
				<p>
					You should read these Terms carefully before using the Site. By using the
					Site or otherwise indicating your consent, you agree to be bound by these
					Terms. If you do not agree with any of these Terms, you should stop using
					the Site immediately.
				</p>
				<p>
					<h1>2. About us</h1>
				</p>
				<p>
					Yessy Pay (<a href="http://www.yessypay.com">www.yessypay.com</a>)
					(hereinafter the &quot;Platform&quot; or &quot;Site&quot;) is a platform owned by GIT and
					operated by GIT and its partners from time to time.
				</p>
				<p>
					For Digital Assets related services, GIT is your service provider pursuant
					to its registration under the OAM as a Virtual Currency Operator.
				</p>
				<p>
					For cards, digital assets, e-wallets and associated payment services, GIT
					helps customers to reach XWARD and XWARD’s partners services and create
					legal relationship between Clients and XWARD and/or XWARD and its
					respective partners. For the services and products listed above, GIT acts
					as clients’ introducer.
				</p>
				<p>
					If you have any questions about the Site, please contact us by sending an
					email to <a href="mailto:assistenza@platformeconomy.net">assistenza@platformeconomy.net</a>
				</p>
				<h1>3. Using the Site</h1>
				<p>
					The Site is for your personal and non-commercial use only.
				</p>
				<p>
					You agree that you are solely responsible for all costs and expenses you
					may incur in relation to your use of the Site.
				</p>
				<p>
					We make no promise that the Site is appropriate or available for use in
					locations outside of the EEA. If you choose to access the Site from
					locations outside the EEA, you acknowledge that you do so at your own
					initiative and are responsible for compliance with local laws where they
					apply.
				</p>
				<p>
					We try to make the Site as accessible as possible. If you have any
					difficulties using the Site, please contact us using the contact details at
					the top of this page.
				</p>
				<p>
					As a condition of your use of the Site, you agree not to:
				</p>
				<ul>
					<li>
						misuse or attack our Site by knowingly introducing viruses,
						trojans, worms, logic bombs or any other material which is
						malicious or technologically harmful (such as by way of a
						denial-of-service attack), or;
					</li>
					<li>
						attempt to gain unauthorised access to our Site, the server on
						which our Site is stored or any server, computer or database
						connected to our Site.
					</li>
				</ul>
				<p>
					We may prevent or suspend your access to the Site if you do not comply with
					these Terms or any applicable law.
				</p>
				<h1>4. Your privacy and personal information</h1>
				<p>
					Your privacy and personal information are important to us. Any personal
					information that you provide to us will be dealt with in line with our
					Privacy Policy available on our website<strong>, </strong>which explains
					what personal information we collect from you, how and why we collect,
					store, use and share such information, your rights in relation to your
					personal information and how to contact us and supervisory authorities in
					the event you have a query or complaint about the use of your personal
					information.
				</p>
				<h1>5. Ownership, use and intellectual property rights</h1>
				<p>
					The intellectual property rights in the Site and in any text, images,
					video, audio or other multimedia content, software or other information or
					material submitted to or accessible from the Site (Content) are owned by us
					and our licensors.
				</p>
				<p>
					We and our licensors reserve all our intellectual property rights
					(including, but not limited to, all copyright, trade marks, domain names,
					design rights, database rights, patents and all other intellectual property
					rights of any kind) whether registered or unregistered anywhere in the
					world. This means, for example, that we remain owners of them and are free
					to use them as we see fit.
				</p>
				<p>
					Nothing in these Terms grants you any legal rights in the Site or the
					Content other than as necessary for you to access it. You agree not to
					adjust, try to circumvent or delete any notices contained on the Site or
					the Content (including any intellectual property notices) and in
					particular, in any digital rights or other security technology embedded or
					contained within the Site or the Content.
				</p>
				<h1>6. Submitting information to the site</h1>
				<p>
					While we try to make sure that the Site is secure, we do not actively
					monitor or check whether information supplied to us through the Site is
					confidential, commercially sensitive or valuable.
				</p>
				<p>
					Other than any personal information which will be dealt with in accordance
					with our Privacy Policy, we do not guarantee that information supplied to
					us through the Site will be kept confidential and we may use it on an
					unrestricted and free-of-charge basis as we reasonably see fit.
				</p>
				<h1>7. Accuracy of information and availability of the site</h1>
				<p>
					We try to make sure that the Site is accurate, up-to-date and free from
					bugs, but we cannot promise that it will be. Furthermore, we cannot promise
					that the Site will be fit or suitable for any purpose. Any reliance that
					you may place on the information on the Site is at your own risk.
				</p>
				<p>
					We may suspend or terminate access or operation of the Site at any time as
					we see fit.
				</p>
				<p>
					Any Content is provided for your general information purposes only and to
					inform you about us and our products and news, features, services and other
					websites that may be of interest, but has not been tailored to your
					specific requirements or circumstances. It does not constitute technical,
					financial or legal advice or any other type of advice and should not be
					relied on for any purposes. You should always use your own independent
					judgment when using our Site and its Content.
				</p>
				<p>
					While we try to make sure that the Site is available for your use, we do
					not promise that the Site will be available at all times or that your use
					of the Site will be uninterrupted.
				</p>
				<h1>8. Hyperlinks and third-party sites</h1>
				<p>
					The Site may contain hyperlinks or references to third party advertising
					and websites other than the Site. Any such hyperlinks or references are
					provided for your convenience only. We have no control over third party
					advertising or websites and accept no legal responsibility for any content,
					material or information contained in them. The display of any hyperlink and
					reference to any third-party advertising or website does not mean that we
					endorse that third party’s website, products or services. Your use of a
					third-party site may be governed by the terms and conditions of that
					third-party site and is at your own risk.
				</p>
				<h1>9. Our responsibility to you</h1>
				<p>
					If we breach these Terms or are negligent, we are liable to you for
					foreseeable loss or damage that you suffer as a result. By ‘foreseeable’ we
					mean that, at the time these Terms were formed, it was either clear that
					such loss or damage would occur or you and we both knew that it might
					reasonably occur, as a result of something we did (or failed to do).
				</p>
				<p>
					We are not liable to you for any loss or damage that was not foreseeable,
					any loss or damage not caused by our breach or negligence, or any business
					loss or damage.
				</p>
				<p>
					Nothing in these terms excludes or limits our liability for any death or
					personal injury caused by our negligence, liability for fraud or fraudulent
					misrepresentation, or any other liability that the law does not allow us to
					exclude or limit.
				</p>
				<h1>10. Events beyond our control</h1>
				<p>
					We are not liable to you if we fail to comply with these Terms because of
					circumstances beyond our reasonable control.
				</p>
				<h1>11. No third-party rights</h1>
				<p>
					No one other than us or you have any right to enforce any of these Terms.
				</p>
				<h1>12. Change of these terms and conditions</h1>
				<p>
					No changes to these Terms are valid or have any effect unless agreed by us
					in writing or made in accordance with this clause.
				</p>
				<p>
					We reserve the right to vary these Terms from time to time. Our updated
					Terms will be displayed on the Site and by continuing to use and access the
					Site following such changes, you agree to be bound by any variation made by
					us. It is your responsibility to check these Terms from time to time to
					verify such variations.
				</p>
				<h1>13. Complaints</h1>
				<p>
					We will try to resolve any disputes with you quickly and efficiently. If
					you are unhappy with us, please contact us as soon as possible using the
					contact details set out below:
				</p>
				<p>
					Contact email: <a href="mailto:assistenza@platformeconomy.net">assistenza@platformeconomy.net</a>
				</p>

			</Segment>
		</Container>
	);
}

export default TermsOfUseYESSY;
