import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './PrivacyPolicy.css';

function PrivacyPolicyTYNCOFEX(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Privacy policy</h1></Grid.Row></Grid>
			<Segment className="policyContent">
				<p>TYNCOFEX LIMITED has developed this privacy policy to explain how we may collect, retain, process, share and transfer your personal data when you visit our website. We are committed to protecting and respecting your privacy.</p>
				<p>This policy (together with our Terms and Conditions) constitutes the basis on which any personal data we collect from you, or that you provide to us, will be processed by us. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it. By visiting our website www.tyncofex.com (&quot;our website&quot;) you are accepting and consenting to the practices described below.</p>
				<p>TYNCOFEX LIMITED is a company incorporated in England and Wales with company number 07805564 and whose registered office is at 17 Hanover Square 17 Hanover Square, London, England, W1S 1BN. TYNCOFEX LIMITED is authorised by the Financial Conduct Authority (FCA) as an Electronic Money Institution under the Electronic Money Regulations 2011 for the issuing of electronic money. We are included in the FCA&rsquo;s Register of Electronic Money Institution firms (Firm Reference Number 900088) which can be found on the FCA website.</p>
				<p>This policy governs our use of personal data collected from individuals and corporate entities through their browsing or provided by them completing and submitting the application form on our website.</p>
				<p>Within the meaning of the applicable data protection regulations, including UK Data Protection Act 2018, TYNCOFEX LIMITED is to be considered a data controller.</p>

				<h3>Information you provide us.</h3>
				<p>You may provide us information about you by filling in forms on our website or by contacting us &nbsp;via phone, e-mail or otherwise. This includes information you provide when you submit application &nbsp;on our website, subscribe to our service, search for a product or service on our website, and when &nbsp;you report a problem with our site. The information you provide us may include your name, birth &nbsp;date, address, e-mail address and phone number, financial and credit card information and personal &nbsp;description, as well as information regarding your business, types of goods and services you &nbsp;provide, information on the representatives and shareholders of your business.&nbsp;</p>
				<p>By submitting the application, you confirm that you are authorised and have the necessary consents &nbsp;to disclose the personal data provided in the application to us. We shall not be liable for any claim &nbsp;brought by any individual arising from an act or omission resulting from your failure to ensure that &nbsp;the transfer of the personal data was lawful.&nbsp;</p>


				<h3>Information we collect about you.</h3>
				<p>Every time you visit our website, we may automatically collect the following information:&nbsp;</p>
				<ul>
					<li>technical information, including the Internet Protocol (IP) address used to connect your &nbsp;device to the Internet, your login information, browser type and version, time zone setting, &nbsp;browser plug-in types and versions, operating system and platform;&nbsp;</li>
					<li>information regarding your visit, including the full Uniform Resource Locators (URL) &nbsp;clickstream to, through and from our site (including date and time);</li>
					<li>products and services you viewed or searched for;&nbsp;</li>
					<li>page response times, download errors, length of visits to certain pages, page interaction &nbsp;information (such as clicks, scrolling, and mouse-overs), and methods used to browse away &nbsp;from the page and any phone number used to call us. </li>
				</ul>


				<h2>Cookies</h2>
				<p>Our website uses cookies to distinguish you from other visitors of our website. This helps us to &nbsp;provide you with a good and quality service when you browse our website and allows us to improve our website. For detailed information on the cookies we use and the purposes for which we use &nbsp;them see our Cookie Policy.&nbsp;</p>
				<h4>Use of the received and collected information.</h4>
				<p>We may use the information about you in the following ways:</p>
				<h4>Information you provide us.</h4>
				<p>We may use this information:</p>
				<ul>
					<li><p>to evaluate your application to receive our services;</p></li>
					<li><p>to carry out our obligations arising from any contract concluded between you and us and to provide you with the information, products and services that you request from us;</p></li>
					<li><p>to enable fraud protection and risk management processes such as checking third party sanctions lists and other databases to ensure that you are eligible to receive our services;</p></li>
					<li><p>to comply with our legal compliance obligations;</p></li>
					<li><p>to provide you with information about other products and services we offer that are similar to those that you have already purchased or enquired about;</p></li>
					<li><p>to provide you with information about other products or services that may interest you;</p></li>
					<li><p>to inform you about changes to our service;</p></li>
					<li><p>and to ensure that the content of our website is presented in the most convenient manner for you and for your device. </p></li>
				</ul>
				<h4>Disclosure of your information</h4>
				<p>We may share your personal data with any member of our group, which means our affiliated companies, our subsidiaries, our ultimate beneficial owners and their subsidiaries, as defined in section 1159 of the UK Companies Act 2006.</p>
				<p>We may share your personal data with selected third parties including:</p>
				<ul>
					<li><p>Business partners, suppliers and sub-contractors for the performance of any contract we enter into with them or you.</p></li>
					<li><p>Advertisers and advertising networks that need the data to select and serve relevant adverts to you and others.</p></li>
					<li><p>Analytics and search engine providers that assist us in the improvement and optimisation of our site.</p></li>
					<li><p>Credit reference agencies for the purpose of assessing your credit score where this is a condition of us entering into a contract with you.</p></li>
				</ul>
				<p>We may disclose your personal information to third parties:</p>
				<ul>
					<li><p>In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets.</p></li>
					<li><p>If TYNCOFEX LIMITED or substantially all of its assets are acquired by a third party, in which case personal data held by it about its customers will be one of the transferred assets.</p></li>
					<li><p>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce or apply our Terms and Conditions and other agreements.</p></li>
					<li><p>or to protect the rights, property.</p></li>
					<li><p>or safety of TYNCOFEX LIMITED, our clients, business partners.</p></li>
					<li><p>or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction.</p></li>
				</ul>

				<h3>Storage of personal data</h3>
				<p>We maintain technical, physical, and administrative security measures designed to provide reasonable protection for your personal data against loss, misuse, unauthorised access, disclosure, and alteration. The security measures include firewalls, data encryption, physical access controls to our data centres, and information access authorisation controls. While we are dedicated to securing our systems and services, you are responsible for securing and maintaining the privacy of your password(s) and account/profile registration information and verifying that the personal data we maintain about you is accurate and current. We ask you not to share a password with anyone. We are not responsible for protecting any personal data that we share with a third-party based on an account connection that you have authorised.</p>
				<p>Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.</p>

				<h3>Your rights</h3>
				<p>You have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data) if we intend to use your data for such purposes or if we intend to disclose your information to any third party for such purposes. You can exercise your right to prevent such processing by checking certain boxes on the forms we use to collect your data. You can also exercise the right at any time by contacting us at info@tyncofex.com.</p>
				<p>Our website may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies. Please check these policies before you submit any personal data to these websites.</p>
				<h3>Access to information</h3>
				<p>You have the right to access information held about you. Your right of access can be exercised in accordance with the applicable legislation. Any access request may be subject to a fee to meet our costs in providing you with details of the information we hold about you.</p>
				<h3>Changes to our privacy policy</h3>
				<p>Any changes we may make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy.</p>
				<h3>Contact</h3>
				<p>If you have any questions regarding our privacy policy, please do not hesitate to address them to info@tyncofex.com.</p>

			</Segment>
		</Container>
	);
}

export default PrivacyPolicyTYNCOFEX;
