import React, { useCallback, useEffect, useState } from 'react';
import './TransactionStatements.css';
import TransactionDetails from '../Banking/Transactions/TransactionDetails';
import {
	Button,
	Container,
	Dimmer,
	Dropdown,
	Grid,
	Header,
	Icon,
	Input,
	Loader,
	Menu,
	Modal,
	Popup,
	Segment,
	Table
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { showErrorNotification } from '@features/swal/slice';
import {
	getCsv,
	getExcel,
	getPdf,
	getExchangePdf,
	getStatementPdf,
	getTransactions,
	getTransactionsByAccountType,
	resolveTemplateType,
	getTransactionsLazy
} from '@features/transactions/slice';
import {
	StatementExportPayload, 
	TemplatePayload,
	TransactionsPayload,
	TransactionStatus,
	TransactionType,
	TransactionsLazyPayload
} from '@features/transactions/types';
import { addDays, addMonths, addWeeks, endOfDay, getTime, startOfDay } from 'date-fns';
import { formatDate } from '@helpers/date';
import CloseTarget from '@icons/CloseTarget';
import { DateRangePicker } from 'react-date-range';
import ShareTransaction from '@/components/Banking/Transactions/ShareTransaction';
import AddPaymentReceipt from '@/components/Banking/Transactions/AddPaymentReceipt';
import ShowAttachment from '@/components/Banking/Transactions/ShowAttachment';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { isFiat } from '@/helpers/currency';
import { roundFlexible } from '@/helpers/round';
import { runOperation, tranformSubProcessByType } from '@/features/operations/slice';

enum Period {
	DAY = 'DAY',
	WEEK = 'WEEK',
	MONTH = 'MONTH',
	CUSTOM = 'CUSTOM'
}

interface Props {
	account: any,
	type?: string,
	hideTitle?: boolean,
	tabType?: string
}

const TransactionStatements = ({ account, type, hideTitle, tabType }: Props) => {

	const [open, setOpen] = React.useState(false);
	const { t } = useTranslation('translations');
	const [today] = useState<Date>(new Date());
	const [period, setPeriod] = useState<Period>(Period.DAY);
	const [loading, setLoading] = useState<boolean>(true);
	const [transactions, setTransactions] = useState<Array<any>>([]);
	const [search, setSearch] = useState<string>('');
	const [searchRequest, setSearchRequest] = useState<string>('');
	const [openDatePicker, setOpenDatePicker] = React.useState(false);
	const [range, setRange] = useState<any>({
		startDate: new Date(),
		endDate: new Date(),
		key: 'selection',
	});
	const [exportRange, setExportRange] = useState(null);
	const [selectedRange, setSelectedRange] = useState<any>(null);
	const [documentLoading, setDocumentLoading] = useState<boolean>(false);
	const [exportOptions, setExportOptions] = useState<any>([
		{
			key: t('transaction.export.excel'),
			text: t('transaction.export.excel'),
			value: t('transaction.export.excel'),
			onClick: () => fetchExcelSheet()
		},
		{
			key: t('transaction.export.csv'),
			text: t('transaction.export.csv'),
			value: t('transaction.export.csv'),
			onClick: () => fetchCsvSheet()
		},
		{
			key: t('transaction.export.pdf'),
			text: t('transaction.export.pdf'),
			value: t('transaction.export.pdf'),
			onClick: () => fetchPdfSheet()
		}
	]);


	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const minDate = new Date(new Date().getFullYear() - 1, 0, 1);
	const maxDate = new Date(new Date());

	const statusIcon = (value: TransactionStatus) => {
		let element;
		switch (value) {
			case TransactionStatus.CANCELED:
				element = <Icon className="rejectedicon" name="close" />;
				break;
			case TransactionStatus.FAILED:
				element = <Icon className="rejectedicon" name="close" />;
				break;
			case TransactionStatus.ERROR:
				element = <Icon className="rejectedicon" name="close" />;
				break;
			case TransactionStatus.REJECTED:
				element = <Icon className="rejectedicon" name="close" />;
				break;
			case TransactionStatus.COMPLETED:
				element = <Icon className="approvedicon" name="check" />;
				break;
			default:
				element = <Icon className="approvalicon" name="clock outline" />;
		}
		return element;
	};

	const isBusiness = () => {
		if (user) {
			return user.type === 'EMPLOYEE' || user.onBehalfOf;
		} else {
			return false;
		}
	};

	const isEnabled = (transactionType: TransactionType, transactionStatus) => {
		return (!(transactionType === TransactionType.TRANSFER ||
			transactionType === TransactionType.PAYMENT ||
			transactionType === TransactionType.DEPOSIT ||
			transactionType === TransactionType.OWN_TRANSFER ||
			transactionType === TransactionType.CARD_TOP_UP ||
			transactionType === TransactionType.PURCHASE ||
			transactionType === TransactionType.FX_TRANSACTION)) ||
			!(transactionStatus === 'COMPLETED');
	};

	const fetchCsvSheet = useCallback(() => {
		const get = async () => {
			try {
				setDocumentLoading(true);
				const payload: TemplatePayload = {
					templateIdentifier: 'transactions.sheet.template',
					payload: transactions
				};
				await getCsv(payload);
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setDocumentLoading(false);
			}
		};
		get();
	}, [transactions]);

	const fetchExcelSheet = useCallback(() => {
		const get = async () => {
			try {
				setDocumentLoading(true);
				const payload: TemplatePayload = {
					templateIdentifier: 'transactions.sheet.template',
					payload: transactions
				};
				await getExcel(payload);
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setDocumentLoading(false);
			}
		};
		get();
	}, [transactions]);




	const fetchPdfSheet = useCallback(() => {
		const get = async () => {
			try {
				setDocumentLoading(true);
				let to: any = today;
				let from: any = today;
				switch (period) {
					case Period.DAY:
						from = addDays(from, -1);
						break;
					case Period.WEEK:
						from = addWeeks(from, -1);
						break;
					case Period.MONTH:
						from = addMonths(from, -1);
						break;
					case Period.CUSTOM:
						from = selectedRange.startDate;
						to = selectedRange.endDate;
						break;
				}
				from = getTime(startOfDay(from));
				to = getTime(endOfDay(to));
				const payload: TransactionsPayload = tabType === 'card' ?
					{
						accountId: account.accountId,
						from: `${from}`,
						to: `${to}`,
						search: searchRequest,
						type: 'CARD'
					}
					: {
						accountId: account.accountId,
						from: `${from}`,
						to: `${to}`,
						search: searchRequest
					};
				await getStatementPdf(payload, `${account.name}`);
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setDocumentLoading(false);
			}
		};
		get();
	}, [exportRange, period, account]);

	const downloadTransactionPdf = useCallback((transaction) => {
		const get = async () => {
			try {
				let walletId;
				let accountType;
				const transactionType: TransactionType = transaction.activity;
				const amount: number = transaction.amount;
				const template = resolveTemplateType(transactionType, amount);

				if (tabType === 'card') {
					walletId = account.walletId;
					accountType = 'CARD';
				}
				if (transactionType === TransactionType.FX_TRANSACTION) {
					const payload: StatementExportPayload = {
						templateIdentifier: template,
						transactionNumber: transaction.docNumber,
						walletId,
						accountType

					};
					await getExchangePdf(payload, transaction.transactionNumber);
				} else {
					const payload: StatementExportPayload = {
						templateIdentifier: template,
						transactionNumber: transaction.transactionNumber,
						walletId,
						accountType
					};
					await getPdf(payload, transaction.transactionNumber);
				}
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [transactions]);

	const fetchData = useCallback(() => {
		if (!today) return;
		const get = async () => {
			setLoading(true);
			try {
				let to: any = today;
				let from: any = today;
				switch (period) {
					case Period.DAY:
						from = addDays(from, -1);
						break;
					case Period.WEEK:
						from = addWeeks(from, -1);
						break;
					case Period.MONTH:
						from = addMonths(from, -1);
						break;
					case Period.CUSTOM:
						from = selectedRange.startDate;
						to = selectedRange.endDate;
						break;
				}
				from = getTime(startOfDay(from));
				to = getTime(endOfDay(to));



				const payload: TransactionsPayload = {
					from: `${from}`,
					to: `${to}`,
					search: searchRequest
				};

				let result = [];

				if (type) {
					payload.type = type;
					result = await getTransactionsByAccountType(payload);
				} else if (account) {

					if (tabType === 'card') {
						const payload: TransactionsLazyPayload = {
							search: searchRequest,
							from: `${from}`,
							to: `${to}`,
							accountId: `${account.accountId}`,
							initial: false
						};

						result = await getTransactionsLazy(payload);
					}
					else {
						payload.accountId = account.accountId;
						result = await getTransactions(payload);
					}
				}

				setTransactions(result);
				setExportRange({
					startDate: from,
					endDate: to,
				});
			} catch (e) {
				setTransactions([]);
				showErrorNotification(e);
			} finally {
				setLoading(false);
			}
		};
		get();
	}, [today, period, searchRequest, selectedRange, account, type]);

	useEffect(() => {
		if (search !== searchRequest && (search.length > 2 || search.length === 0)) {
			setSearchRequest(search);
		}
	}, [search]);

	useEffect(() => {
		if (!account && !type) return;
		if (tabType) {
			if (tabType === 'card' && account.providerType !== 'CARD') {
				return;
			} else if (tabType === 'trust' && account.providerType !== 'TRUST') {
				return;
			} else if (tabType === 'account' && (account.providerType === 'TRUST' || account.providerType === 'CARD')) {
				return;
			}
		}
		fetchData();
	}, [account, type, fetchData, tabType]);

	useEffect(() => {
		setRange({
			startDate: today,
			endDate: today,
			key: 'selection',
		});
	}, [today]);


	useEffect(() => {
		if (!type) {
			setExportOptions([
				{
					key: t('transaction.export.excel'),
					text: t('transaction.export.excel'),
					value: t('transaction.export.excel'),
					onClick: () => fetchExcelSheet()
				},
				{
					key: t('transaction.export.csv'),
					text: t('transaction.export.csv'),
					value: t('transaction.export.csv'),
					onClick: () => fetchCsvSheet()
				},
				{
					key: t('transaction.export.pdf'),
					text: t('transaction.export.pdf'),
					value: t('transaction.export.pdf'),
					onClick: () => fetchPdfSheet()
				}
			]);
		}
		else {
			setExportOptions([]);
		}
	}, [type, account, period, transactions]);

	const accountOptions = [
		{
			key: account?.name ?? type.toLowerCase(),
			text: account?.name ?? type.toLowerCase(),
			value: account?.name ?? type.toLowerCase()
		}
	];

	const cancelExchangeOrder = async (documentId: number) => {

		try {
			const exchangeProcess = tranformSubProcessByType(account.subProcesses)['FX_ORDER'];
			await runOperation(exchangeProcess.proc, {
				documentId,
				docStatus: 'CANCEL'
			});
			fetchData();
		} catch (e) {
			showErrorNotification(e);
		}
	};

	return (
		<div id="transactionstatements">
			<Container>
				<Segment className="transactionSegment">
					<Dimmer active={loading} inverted>
						<Loader />
					</Dimmer>
					<Grid columns="4" padded>
						{!hideTitle && <Grid.Column only="computer tablet">
							<Header className="headerMargin">{type === 'VAULT' ? t('transaction.header.vaulttableheader') : t('transaction.header.tableheader')}</Header>
						</Grid.Column>
						}
						<Grid.Row only="computer tablet">
							<Grid.Column width={8}>
								{type !== 'VAULT' && exportOptions?.length > 0 && <Dropdown className="exportSelect" selection disabled={documentLoading}
									text={t('transaction.header.exportbutton')} loading={documentLoading}
									options={exportOptions} />}
							</Grid.Column>
							<Grid.Column width={8}>
								<Menu floated="right" secondary className="filters">
									<Menu.Item name='day' active={period === Period.DAY} onClick={() => {
										setPeriod(Period.DAY);
									}}>
										{t('transaction.menu.day')}
									</Menu.Item>
									<Menu.Item name='week' active={period === Period.WEEK} onClick={() => {
										setPeriod(Period.WEEK);
									}}>
										{t('transaction.menu.week')}
									</Menu.Item>
									<Menu.Item name='month' active={period === Period.MONTH} onClick={() => {
										setPeriod(Period.MONTH);
									}}>
										{t('transaction.menu.month')}
									</Menu.Item>
									<Popup size='tiny' open={openDatePicker} on={['click']} onClose={() => {
										setOpenDatePicker(false);
									}} trigger={<Menu.Item onClick={() => {
										setOpenDatePicker(true);
									}} name='custom' active={period === Period.CUSTOM}>
										{t('transaction.menu.custom')}
									</Menu.Item>}>
										<DateRangePicker minDate={minDate} maxDate={maxDate} ranges={range ? [range] : []} onChange={(value) => {
											setRange(value.selection);
										}} />
										<Button primary onClick={() => {
											setSelectedRange(range);
											setPeriod(Period.CUSTOM);
											setOpenDatePicker(false);
										}}>Apply range</Button>
									</Popup>
									<Menu.Item position="right">
										<Input placeholder={t('transaction.placeholder.search')}
											icon={{ name: 'search', link: true, onClick: fetchData }}
											onChange={(event) => {
												setSearch(event.target.value);
											}} />
									</Menu.Item>
								</Menu>
							</Grid.Column>
						</Grid.Row>
					</Grid>
					{/*  Mobile search field and button */}
					<Grid columns={2} className="inputbuttonmobilegrid">
						<Grid.Row only="mobile">
							<Grid.Column mobile={16}>
								<Input fluid iconPosition="left" icon='search'
									placeholder={t('transaction.placeholder.search')} />
							</Grid.Column>
						</Grid.Row>
						<Grid.Row className='mobileBTNRow'>
							<Grid.Column className='filterMobileCLMN' only="mobile">
								{/* Modal is still WIP */}
								<Modal className='filtersModal' onClose={() => setOpen(false)}
									onOpen={() => setOpen(true)} open={open}
									trigger={<Button fluid primary basic className='btnsMobile'>Show filter</Button>}>
									<Modal.Header>
										<Button className='closeFiltersBTN' onClick={() => setOpen(false)}>
											<CloseTarget className='closeTargetIcon' />
										</Button>
										Filters
									</Modal.Header>
									<Modal.Content scrolling image>
										<Modal.Description>
											<Dropdown fluid selection className="dropdownMargin" simple item
												text={account?.name ?? type.toLowerCase()} options={accountOptions} />
										</Modal.Description>
										<Menu className='filtersModalMenu' stackable floated="right" secondary>
											<Menu.Item name='day' active={period === Period.DAY} onClick={() => {
												setPeriod(Period.DAY);
											}}>
												{t('transaction.menu.day')}
											</Menu.Item>
											<Menu.Item name='week' active={period === Period.WEEK} onClick={() => {
												setPeriod(Period.WEEK);
											}}>
												{t('transaction.menu.week')}
											</Menu.Item>
											<Menu.Item name='month' active={period === Period.MONTH} onClick={() => {
												setPeriod(Period.MONTH);
											}}>
												{t('transaction.menu.month')}
											</Menu.Item>
											<Popup open={openDatePicker} on={['click']} onClose={() => {
												setOpenDatePicker(false);
											}} trigger={<Menu.Item onClick={() => {
												setOpenDatePicker(true);
											}} name='custom' active={period === Period.CUSTOM}>
												{t('transaction.menu.custom')}
											</Menu.Item>}>
												<DateRangePicker minDate={minDate} maxDate={maxDate} ranges={range ? [range] : []} onChange={(value) => {
													setRange(value.selection);
												}} />
												<Button primary onClick={() => {
													setSelectedRange(range);
													setPeriod(Period.CUSTOM);
													setOpenDatePicker(false);
												}}>Apply range</Button>
											</Popup>
										</Menu>
									</Modal.Content>

									<Modal.Actions className='filtersModalActions'>
										<Grid columns={2}>
											<Grid.Row>
												<Grid.Column className='mobileApplyCLMN' mobile={11}>
													<Button onClick={() => setOpen(false)} className='mobileApplyBTN'
														primary content='Apply filters' fluid />
												</Grid.Column>
												<Grid.Column className='mobileClearCLMN' mobile={5}>
													<Button onClick={() => setOpen(false)} className='mobileClearBTN'
														content='Clear' fluid />
												</Grid.Column>
											</Grid.Row>
										</Grid>
									</Modal.Actions>
								</Modal>
							</Grid.Column>
							<Grid.Column className='exportMobileCLMN' only='mobile'>
								{type !== 'VAULT' && <Dropdown fluid className="exportSelect btnsMobile" selection
									disabled={documentLoading} text={t('transaction.header.exportbutton')}
									loading={documentLoading} options={exportOptions} />}
							</Grid.Column>
						</Grid.Row>
					</Grid>
					<Table className="tableLocation" basic="very" padded="very">
						<Table.Header>
							<Table.Row className="transactionTableHeaders">
								<Table.HeaderCell>{t('transaction.header.reference')}</Table.HeaderCell>
								<Table.HeaderCell>{t('transaction.header.activity')}</Table.HeaderCell>
								<Table.HeaderCell>{t('transaction.header.asset')}</Table.HeaderCell>
								<Table.HeaderCell>{t('transaction.header.amount')}</Table.HeaderCell>
								<Table.HeaderCell>{t('transaction.header.date')}</Table.HeaderCell>
								<Table.HeaderCell>{t('transaction.header.status')}</Table.HeaderCell>
								{isBusiness() &&
									<Table.HeaderCell>{t('transaction.header.attachments')}</Table.HeaderCell>}
								{/* The last headercell needs to be empty for layout reasons */}
								<Table.HeaderCell />
							</Table.Row>
						</Table.Header>
						<Table.Body className="transactiontable">
							{!transactions.length && <Table.Row>
								<Table.Cell colSpan={isBusiness() ? '8' : '7'}>
									<Segment placeholder basic>
										<Header icon>
											<Icon name="inbox" />{t('transaction.table.noTransactions')}
										</Header>
									</Segment></Table.Cell></Table.Row>}
							{transactions.map(transaction => {

								const isFiatAsset = isFiat(transaction.asset);
								return (
									<Table.Row key={transaction.transactionNumber}
										className={transaction.amount < 0 ? 'credit' : 'debit'}>
										<Table.Cell>
											<Header as='h5'>
												<Header.Content>
													{transaction.transactionNumber}
													<Header.Subheader>{transaction.note}</Header.Subheader>
												</Header.Content>
											</Header>
										</Table.Cell>
										<Table.Cell
											className="tableactivitymobile">{type === 'TOKEN' && transaction.activity === 'OWN_TRANSFER' ? transaction.amount !== null && transaction.amount > 0 ? t('activity.tokens.BUY_PURCHASE') : t('activity.tokens.SELL_PURCHASE') : t('activity.' + transaction.activity)}</Table.Cell>
										<Table.Cell className="tablecurrencymobile">{transaction.asset}</Table.Cell>
										<Table.Cell className="amount">{transaction.amount !== null && transaction.amount > 0 ? '+' : ''}
											{isFiatAsset ?
												<NumberFormat displayType={'text'} decimalScale={2}
													fixedDecimalScale={true} thousandsGroupStyle='thousand'
													thousandSeparator={true} value={transaction.amount} /> :
												roundFlexible(transaction.amount)
											}
										</Table.Cell>
										<Table.Cell
											className="tabledatemobile">{formatDate(transaction.date, 'T')}</Table.Cell>
										<Table.Cell className="tablestatusmobile">
											{statusIcon(transaction.status)} {type === 'TOKEN' && transaction.status === 'FAILED' ? t('status.tokens.REJECTED') : t('status.' + transaction.status)}</Table.Cell>
										{isBusiness() && transaction?.attachments?.length > 0 &&
											<Table.Cell>{transaction.attachments.map((attachment) =>
												<ShowAttachment
													key={attachment.fileId} attachmentKey={attachment.fileId}
													name={attachment.filename} />)}</Table.Cell>
										}
										<Table.Cell className="statementsdropdown">
											{transaction.activity === TransactionType.FX_TRANSACTION && transaction?.documentId && transaction.status === 'PENDING' ?
												<Dropdown
													className="transactiondropdownicon" icon="ellipsis horizontal">
													<Dropdown.Menu>
														<Dropdown.Item onClick={() => cancelExchangeOrder(transaction.documentId)}>
															{t('transaction.dropdown.cancel')}
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
												:
												<Dropdown disabled={isEnabled(transaction.activity, transaction.status) || type === 'TOKEN'}
													className="transactiondropdownicon" icon="ellipsis horizontal">
													<Dropdown.Menu>
														<Dropdown.Item>
															<TransactionDetails transaction={transaction} />
														</Dropdown.Item>
														<Dropdown.Item
															disabled={transaction?.activity === TransactionType.CARD_TOP_UP}
															onClick={() => downloadTransactionPdf(transaction)}>{t('transaction.dropdown.export')}</Dropdown.Item>
														<Dropdown.Item
															disabled={transaction?.activity === TransactionType.CARD_TOP_UP}>
															<ShareTransaction transaction={transaction}
																walletId={account?.walletId}
																accountType={tabType==='card'? 'CARD':undefined} />
														</Dropdown.Item>
														<Dropdown.Item
															disabled={transaction?.activity === TransactionType.CARD_TOP_UP}>
															<AddPaymentReceipt transaction={transaction} />
														</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											}
										</Table.Cell>
									</Table.Row>
								);
							})}
						</Table.Body>
					</Table>
				</Segment>
			</Container>
		</div>
	);
};

export default TransactionStatements;
