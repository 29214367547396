import React, { useEffect, useState } from 'react';
import { Divider, Grid, Header, List, Radio } from 'semantic-ui-react';
import isEmpty from 'is-empty';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../Dashboard/CardTab.css';
import localCallingCodes from '../../Registration/Parts/callingCodes.json';
import NumberFormat from 'react-number-format';
import { UserType } from '@/features/user/types';
import { Agents } from '@/helpers/globalTypes';
import { isValidPhoneNumber } from 'react-phone-number-input';

const callingCodes = [];
for (const code of localCallingCodes) {
	if (callingCodes.includes(code) !== true) {
		callingCodes.push(code);
	}
}

interface Props {
	onSubmit: (payload: any) => void,
	operation: any
}

const CardConfirmation = ({ onSubmit, operation }: Props): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const [orderFee, setOrderFee] = useState<any>(null);
	const [fees, setFees] = useState<any[]>(null);
	const [feesAmount, setFeesAmount] = useState<number>(0);
	const [feesCcy, setFeesCcy] = useState<number>(0);
	const [_accounts, setAccounts] = useState<any[]>([]);
	const [shipmentsOptions, setShipmentsOptions] = useState<any[]>([]);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [assignCard, setAssignCard] = useState<boolean>(false);

	const { user, status } = useSelector(
		(state: RootState) => state.user
	);

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const { accounts } = useSelector(
		(state: RootState) => state.trust
	);

	const { activeEmployees } = useSelector(
		(state: RootState) => state.employee
	);

	useEffect(() => {
		if (!list || !operation) return;

		let acc = [];

		if (list) {
			if (status.source === Agents.DTS) {
				acc = list.filter(account => account.currency === operation.processProperties.ccy && account.providerType !== 'POOLING');
			} else {
				acc = list.filter(account => account.currency === operation.processProperties.ccy);
			}
		}

		if (accounts && (status.source === Agents.CUSTODYFY || status.source === Agents.WZ)) {
			accounts
				.filter(account => account.currency === operation.processProperties.ccy)
				.map(account => {
					acc.push(account);
				});
		}


		setAccounts(acc);

	}, [list, operation]);

	useEffect(() => {
		if (operation && operation.subProcesses) {
			const shipOptions = [];
			const processList = Object.keys(operation.subProcesses).map((key) => operation.subProcesses[key]);
			if (processList.length) {
				processList.forEach(subProcess => {
					if (subProcess.type === 'SHIPMENT') {
						const shipData = {
							desc: subProcess.description,
							name: subProcess.name,
							proc: subProcess.proc,
							fee: subProcess.fees[0]
						};
						shipOptions.push(shipData);
					}
				});
				setShipmentsOptions(shipOptions);
				if (shipOptions) {
					setOrderFee(shipOptions[0]);
				}
			}
		}
		if (operation && operation.fees && operation.fees.length) {
			let allFeesAmount = 0;
			operation.fees.forEach(fee => {
				if (fee.type === 'FIXED') {
					allFeesAmount = allFeesAmount + fee.feeAmount;
				} else if (fee.type === 'FIXED_MONTH') {
					allFeesAmount = allFeesAmount + fee.feeAmount + fee.feeMonth;
				}
			});
			setFeesAmount(allFeesAmount);
			setFeesCcy(operation.fees[0].ccy);
		}
		setFees(operation.fees);
	}, [operation]);


	const haveEnoughFunds = (accountId: string, fee: number) => {
		const account = _accounts.find(value => value.accountId === parseInt(accountId));
		if (!account) {
			return;
		}
		return account.availableBalance >= fee;
	};

	const validationSchema = Yup.object({
		account: Yup.string().required(t('form.validator.required')).test('account', 'Not enough funds',
			(value) => haveEnoughFunds(value, feesAmount)),
		phoneNumber:
			Yup.string()
				.matches(/^([+]|[1-9])[0-9]+$/, t('form.validator.phoneinvalid'))
				.min(4, t('form.validator.phonemin'))
				.test('phoneNumber',
					t('form.validator.phoneinvalid'),
					(value) => isValidPhoneNumber(`+${value}`))
				.required(t('form.validator.required')),
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { employeeId, phoneNumber } = formData;
		const account = selectedAccount;
		onSubmit({ action: 3, data: { account, setSubmitting, orderFee, employeeId, phoneNumber } });
	};

	return (
		<div id="newcard">
			<div className="newcardmainCLMN">
				<Formik
					initialValues={{
						account: '',
						countryCode: '',
						phoneNumber: user?.mobileNumber?.replace('+', '') ?? '',
						assignCardToEmployee: false,
						employeeId: null
					}}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ setFieldValue, errors, isSubmitting, dirty }) => (<Form>
						<Select
							fluid
							name="account"
							label={t('cards.newCard.confirmation.payLabel')}
							options={_accounts.map(item => ({ value: item.accountId, key: item.accountId, text: `${item.name} (${item.currency} ${item.availableBalance})` }))}
							onChange={(element, { value }) => setSelectedAccount(value)}
							errorPrompt
						/>
						{user && (user.type === UserType.BUSINESS || user.onBehalfOf !== null) &&
							<div>
								<Select
									fluid
									name="assignCardToEmployee"
									label={t('cards.newCard.confirmation.assignEmployee')}
									options={
										[
											{ key: 'yes', value: true, text: t('cards.newCard.confirmation.yes') },
											{ key: 'no', value: false, text: t('cards.newCard.confirmation.no') }
										]}
									onChange={(element, { value }) => {
										setAssignCard(value ? true : false);
										if (!value) { setFieldValue('employeeId', null); }
									}}
									errorPrompt
								/>
								{assignCard && <Select
									name="employeeId"
									placeholder={t('business.assignCard.employeePlaceholder')}
									label={t('business.assignCard.employee')}
									options={activeEmployees.map(item => ({ value: item.employeeId, key: item.employeeId, text: `${item.firstName} ${item.lastName}` }))}
									fluid
									errorPromt
								/>}
							</div>
						}
						<Grid className="phone" columns={1} stackable>
							<Grid.Column className="phonenumber">
								<Input
									fluid
									name="phoneNumber"
									errorPrompt
									type='number'
									icon='plus'
									iconPosition='left'
									disabled={user?.mobileNumber && user.type === UserType.INDIVIDUAL}
									label={t('form.fields.phone')} />
							</Grid.Column>
						</Grid>
						<Divider section />
						<List relaxed="very" verticalAlign='middle'>
							<List.Item>
								<List.Header style={{ marginBottom: '0.4em' }}>{t('cards.newCard.confirmation.cardLabel')}</List.Header>
							</List.Item>
							{fees &&
								fees.map(f =>
									<>
										{f.type === 'FIXED' &&
											<List.Item
												key={f.id}
												style={{ border: 0 }}>
												<List.Content floated='right'>
													<NumberFormat
														displayType={'text'}
														decimalScale={2}
														fixedDecimalScale={true}
														thousandsGroupStyle='thousand'
														thousandSeparator={true}
														prefix={f.ccy + ' '}
														value={f.feeAmount} />
												</List.Content>
												<List.Content>{t('fees.' + f.fee)}</List.Content>
											</List.Item>}
										{f.type === 'FIXED_MONTH' &&
											<>
												<List.Item
													key={f.id}
													style={{ border: 0 }}>
													<List.Content floated='right'>
														<NumberFormat
															displayType={'text'}
															decimalScale={2}
															fixedDecimalScale={true}
															thousandsGroupStyle='thousand'
															thousandSeparator={true}
															prefix={f.ccy + ' '}
															value={f.feeAmount} />
													</List.Content>
													<List.Content>{t('fees.oneTimeFee')}</List.Content>
												</List.Item>
												<List.Item
													key={f.id}
													style={{ border: 0 }}>
													<List.Content floated='right'>
														<NumberFormat
															displayType={'text'}
															decimalScale={2}
															fixedDecimalScale={true}
															thousandsGroupStyle='thousand'
															thousandSeparator={true}
															prefix={f.ccy + ' '}
															value={f.feeMonth} />
													</List.Content>
													<List.Content>{t('fees.oneTimePlusMonthlyFee')}</List.Content>
												</List.Item>
											</>}
									</>)
							}

							{!operation.processProperties.virtual && shipmentsOptions &&
								shipmentsOptions.map(shipOpt => <List.Item key={shipOpt.proc}
									style={{ border: 0 }}>
									<List.Content floated='right'>
										<NumberFormat
											displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`${shipOpt?.fee?.ccy ?? ''} `}
											value={shipOpt?.fee?.feeAmount ?? 0} />
									</List.Content>
									<List.Header style={{ marginBottom: '0.4em' }}>
										<Radio
											value={shipOpt}
											onChange={(e, { value }) => setOrderFee(value)}
											checked={orderFee.proc === shipOpt.proc}
											label={t('process.' + shipOpt.proc)}
										/></List.Header>
								</List.Item>)}

							<List.Item style={{ border: 0 }}>
								<List.Content floated='right'>
									<Header>{t('cards.newCard.confirmation.total_card_price')}{operation.processProperties.virtual ? '' : t('cards.newCard.confirmation.icnluding_delivery')}</Header>
									<Header className="totalpriceheader" as="h1">
										{orderFee && <NumberFormat
											style={{ float: 'right' }}
											displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`${orderFee?.fee?.ccy ?? ''} `}
											value={(orderFee?.fee?.feeAmount ?? 0) + feesAmount} />}

										{!orderFee && fees && <NumberFormat
											style={{ float: 'right' }}
											displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={feesCcy + ' '}
											value={feesAmount} />}
									</Header>
								</List.Content>
							</List.Item>
						</List>
						<Divider section />
						<SubmitButton disabled={isSubmitting || !isEmpty(errors) || !dirty} fluid primary basic type="submit">{t('form.buttons.confirm')}</SubmitButton>
					</Form>)}
				</Formik></div>
		</div>
	);
};

export default CardConfirmation;
