import React, { useState } from 'react';
import { Breadcrumb, Container, Header, Icon, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import './EmployeeVerificationForm.css';
import SelectDocumentType from '@/components/Business/SelectDocumentType';
import DocumentFront from '@/components/Banking/Card/DocumentFront';
import DocumentBack from '@/components/Banking/Card/DocumentBack';
import IndividualVerificationConfirmation from '@/components/Banking/Card/IndividualVerificationConfirmation';
import { useHistory } from 'react-router-dom';
import ResidenceProof from '@/components/Banking/Card/ResidenceProof';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import { postEmployeeDocuments } from '@features/files/slice';

const EmployeeVerificationForm = () : React.ReactElement => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const [active, setActive] = useState(1);
	const [type, setType] = useState();
	const [documentFront, setDocumentFront] = useState();
	const [documentBack, setDocumentBack] = useState();
	const [residenceProof, setResidenceProof] = useState();

	const handleTypeSelection = (event, data) => {
		const { value } = data;
		setType(value);
	};

	const handleContinue = () => {
		setActive(active + 1);
	};

	const handleDocumentFrontUpload = (file) => {
		setDocumentFront(file);
	};

	const handleDocumentBackUpload = (file) => {
		setDocumentBack(file);
	};

	const handleUtilityUpload = (file) => {
		setResidenceProof(file);
	};

	const handleBackClick = () => {
		if(active === 1) {
			history.push('/wallet');
		} else {
			setActive(active -1);
		}
	};

	const handleConfirm = () => {
		const post = async () => {
			try {
				const formData = new FormData();
				formData.append('files', documentBack);
				formData.append('files', documentFront);
				formData.append('files', residenceProof);
				await postEmployeeDocuments(formData);
				showSuccessNotification('Success!');
			} catch (e) {
				showErrorNotification(e);
			} finally {
				history.push('/wallet');
			}
		};
		post();
	};

	return (
		<Container id='individual-verification'>
			<Breadcrumb size="large">
				<Breadcrumb.Section className="backchevronitem" link onClick={() => handleBackClick()}><Icon name="chevron left" className="backIcon" />{t('business.back')}</Breadcrumb.Section>
			</Breadcrumb>
			<Header>{t('employee.verification.form.header')}</Header>
			<Segment padded='very' compact>
				{active === 1 && <SelectDocumentType type={type} onSelect={handleTypeSelection} onContinue={handleContinue}/>}
				{active === 2 && <DocumentFront onContinue={handleContinue} onUpload={handleDocumentFrontUpload}/>}
				{active === 3 && <DocumentBack onContinue={handleContinue} onUpload={handleDocumentBackUpload}/>}
				{active === 4 && <ResidenceProof onContinue={handleContinue} onUpload={handleUtilityUpload}/>}
				{active === 5 && <IndividualVerificationConfirmation front={documentFront} back={documentBack} utility={residenceProof} onConfirm={handleConfirm}/>}
			</Segment>
		</Container>
	);
};

export default EmployeeVerificationForm;
