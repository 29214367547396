import React, { useState } from 'react';
import { Segment, Header, Divider, Accordion, Icon } from 'semantic-ui-react';
import { Faq } from '../../features/faq/types';

interface Props {
	faq: Faq[]
}

const FAQList = ({ faq }: Props): React.ReactElement => {
	return (

		<Segment padded basic>
			{faq.map(item =>
				(<Questions {...item} key={item.name}></Questions>)
			)}
			<Divider hidden />
		</Segment>

	);
};



const Questions = (faq: Faq) => {
	const [state, setState] = useState({ activeIndex: -1 });

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const { activeIndex } = state;
		const newIndex = activeIndex === index ? -1 : index;

		setState({ activeIndex: newIndex });
	};

	return (
		<div>
			<Header className="faqitemsheader" as="h3" content={faq.name} />
			{faq.faqs.map((item, index) => (
				<Accordion fluid styled key={item.question + index} className="faqAccordian">
					<Accordion.Title
						active={state.activeIndex === index}
						index={index}
						onClick={handleClick}
					>
						<Icon name='dropdown' />
						{item.question}
					</Accordion.Title>
					<Accordion.Content active={state.activeIndex === index} dangerouslySetInnerHTML={{ __html: item.answer }}>
					</Accordion.Content>
				</Accordion>))}
			<Divider hidden />
		</div>
	);
};

export default FAQList;
