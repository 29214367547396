import React, { useEffect, useState } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { RootState } from './rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import { logout, status, refreshToken, connect, subscribe, unsubscribe, clearUser, disconnect } from '@features/user/userSlice';
import { getOperations } from '@features/operations/slice';
import { SocketService } from '@services/socketService';
import LoadingPage from './pages/LoadingPage';
import { connect as connectTicket, subscribe as subscribeTicket, unsubscribe as unsubscribeTicket } from '@features/tickets/slice';
import { connect as connectContacts, subscribe as subscribeContacts, unsubscribe as unsubscribeContacts } from '@features/contacts/slice';
import InactiveModal from './components/Shared/InactiveModal';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
// eslint-disable-next-line react/prop-types
const ProtectedRoute = ({ component: Component, ...rest }): React.ReactElement => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(true);
	const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
	const [openInactiveModal, setOpenInactiveModal] = useState<boolean>(false);
	const history = useHistory();
	const { token } = useSelector(
		(state: RootState) => state.credentials
	);

	const { clientId } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (!token) {
			setIsAuthorized(false);
			return setLoading(false);
		}

		async function getAuthorizationData() {
			try {
				await dispatch(refreshToken());
				const authStatus = await dispatch(status());
				const loggedIn = authStatus['loggedIn'];
				if (!loggedIn) {
					await dispatch(logout());
					setIsAuthorized(false);
					return setLoading(false);
				}
				setIsAuthorized(true);
				return setLoading(false);
			} catch (e) {
				await dispatch(logout());
				setIsAuthorized(false);
				return setLoading(false);
			}
		}

		getAuthorizationData();

	}, [dispatch]);

	useEffect(() => {
		let refreshTimeout;
		if (!isAuthorized) return;

		const getRefreshedToken = () => {
			refreshTimeout && clearTimeout();
			refreshTimeout = setTimeout(() => {
				dispatch(refreshToken());
				getRefreshedToken();
			}, 60 * 1000);
		};
		getRefreshedToken();
		connect();
		dispatch(subscribe());
		dispatch(getOperations());
		return () => {
			unsubscribe();
			dispatch(clearUser());
			disconnect();
			//disconnectTicket();
			SocketService.disconnect();
			refreshTimeout && clearTimeout(refreshTimeout);
		};
	}, [dispatch, isAuthorized, clientId]);

	useEffect(() => {
		if (!isAuthorized) return;
		connectTicket();
		dispatch(subscribeTicket());
		return () => {
			unsubscribeTicket();
		};
	}, [dispatch, isAuthorized, clientId]);

	useEffect(() => {
		if (!isAuthorized) return;
		connectContacts();
		dispatch(subscribeContacts());
		return () => {
			unsubscribeContacts();
		};
	}, [dispatch, isAuthorized, clientId]);


	const handleOnIdle = async (event) => {
		setOpenInactiveModal(true);
	};

	const handleOnActive = event => {
		//console.log('user is active', event);

	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleOnAction = event => {
		//console.log('user did something', event);
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { getRemainingTime, getLastActiveTime } = useIdleTimer({
		timeout: 1000 * 60 * 58.5,
		onIdle: handleOnIdle,
		onActive: handleOnActive,
		onAction: handleOnAction,
		debounce: 500
	});

	const handleInactiveModalClose = async (co?: boolean) => {
		setOpenInactiveModal(false);

		if (co) {
			await dispatch(refreshToken());
		} else {
			dispatch(logout());
			return history.push('/auth/login?mode=INACTIVE');
		}
	};

	if (loading) {
		return <LoadingPage />;
	}
	return (
		<>
			<InactiveModal
				open={openInactiveModal}
				onClose={handleInactiveModalClose}
			/>
			<Route
				{...rest}
				render={props =>
					isAuthorized ? (
						<Component {...props} />
					) : (
						<Redirect
							to={{
								pathname: '/auth',
								// eslint-disable-next-line react/prop-types
								state: { from: props.location }
							}}
						/>
					)
				}
			/>
		</>

	);
};

export default ProtectedRoute;
