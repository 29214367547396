import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { KYC, ProfilePayload } from './types';
import request from '../../services/request';

const initialState: KYC = {
	token: null
};

const kycSlice = createSlice({
	name: 'status',
	initialState,
	reducers: {
		setToken(state, action: PayloadAction<string>) {
			state.token = action.payload;
		}
	}
});

export const { setToken } = kycSlice.actions;

export const kycSelector = (state: { kycSelector: KYC }) =>
	state.kycSelector;

export const getToken = (kycService: string): AppThunk => {
	return async dispatch => {
		const response = await request.get(`/api/identity/${kycService}/accesstoken`);
		const { data } = response;
		dispatch(setToken(data.token)); 
	};
};

export const getRefreshedToken = async () => {
	const response = await request.get('/api/identity/sumsub/accesstoken');
	const { data } = response;
	return data.token;
};

export const getRefreshedVideoToken = async () => {
	const response = await request.get('/api/identity/video-sumsub/accesstoken');
	const { data } = response;
	return data.token;
};

export const requestFinalizeReviewStatus = async () => {
	return async () => {
		await request.get('/api/identity/sumsub/finalizeReviewStatus');
	};
};

export const profileUpdate = (payload: ProfilePayload): AppThunk => {
	return async () => {
		await request.post('/api/identity/profile', payload);
	};
};

export const createOnfidoCheck = (): AppThunk => {
	return async () => {
		await request.get('/api/identity/onfido/check'); 
	};
};

export default kycSlice.reducer;
