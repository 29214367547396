import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankAccount } from '../account/types';
import { Card } from '../card/types';
import { Vault } from '../earn/types';

interface State {
	currentAccount: BankAccount | Card,
	currentVault: Vault
}

const initialState: State = {
	currentAccount: null,
	currentVault: null
};

const stateSlice = createSlice({
	name: 'tabs',
	initialState,
	reducers: {
		setAccount(state, action: PayloadAction<BankAccount | Card>) {
			state.currentAccount = action.payload;
		}, 
		setVault(state, action: PayloadAction<Vault>) {
			state.currentVault = action.payload;
		}
	}
});

export const { setAccount, setVault } = stateSlice.actions;

export const stateSelector = (state: { stateStore: State }) =>
	state.stateStore;

export default stateSlice.reducer;
