import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Icon, Container, Segment, Button, Header, Grid, Input, List, Divider, Dropdown, Confirm, Dimmer, Loader } from 'semantic-ui-react';
import './MyContacts.css';
import SendMoney from '@/components/Banking/Shared/SendMoney';
import RequestMoney from '@/components/Banking/Shared/RequestMoney';
import { deleteContact, getContacts, searchContact, lookupContact } from '@features/contacts/slice';
import { Contact } from '@features/contacts/types';
import { TickerRequestMoney, TicketType } from '@features/tickets/types';
import { showErrorNotification } from '@features/swal/slice';
import Loading from '@/pages/LoadingPage';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { getAccounts } from '@/features/account/slice';
import { useTranslation } from 'react-i18next';
import { ContactTypes } from './Contacts/CreateContact';
import ContactDetailsModal from './Contacts/ContactDetailsModal';

const MyContacts = (): React.ReactElement => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { path } = useRouteMatch();
	const [e, setE] = useState<Error>(null);
	const [contactList, setContacts] = useState<Array<Contact>>([]);
	const [confirmDeleting, setConfirmDeleting] = useState<boolean | number>(false);
	const [running, setRunning] = useState<boolean>(false);
	const [search, setSearch] = useState<string>('');
	const [tiket, setTicket] = useState<TickerRequestMoney>();
	const [request, setRequest] = useState<TickerRequestMoney>();
	const [contactDetails, setContactDetails] = useState<Contact>(null);
	const { t } = useTranslation('translations');


	const { loading, contacts, error } = useSelector(
		(state: RootState) => state.contacts
	);

	const sendMoney = async (contact: Contact) => {
		setRunning(true);
		const { walletId } = contact;
		try {
			const tiket: TickerRequestMoney = {
				id: 0,
				userId: 0,
				title: '',
				status: '',
				createdDate: new Date(),
				incidentType: TicketType.TRANSFER_REQUEST,
				isRead: true,
				ticketMessages: []
			};
			const data = await lookupContact(walletId);
			tiket.requestMeta = JSON.stringify({ account: data, user: data, amount: '', contactType: contact.type });
			setTicket(tiket);
		} catch (e) {
			showErrorNotification(e);
		}
		finally {
			setRunning(false);
		}
	};

	const requestMoney = async (contact: Contact) => {
		setRunning(true);
		const { walletId } = contact;
		try {
			const tiket: TickerRequestMoney = {
				id: 0,
				userId: 0,
				title: 'PAYMENT_REQUEST',
				status: '',
				createdDate: new Date(),
				incidentType: TicketType.TRANSFER_REQUEST,
				isRead: true,
				ticketMessages: []
			};
			const data = await lookupContact(walletId);
			tiket.requestMeta = JSON.stringify({ account: data, user: data, amount: 10, contactType: contact.type });
			tiket.userId = data.userId;
			setRequest(tiket);
		} catch (e) {
			showErrorNotification(e);
		}
		finally {
			setRunning(false);
		}
	};

	const create = () => {
		history.push('/wallet/create/contact?from=' + path);
	};

	const remove = async (id: number) => {
		setConfirmDeleting(false);
		setRunning(true);
		try {
			await dispatch(deleteContact(id));
			await dispatch(getContacts());
		}
		catch (e) {
			setE(e);
		} finally {
			setRunning(false);
		}
	};

	const handleSearchChange = async (...args) => {
		setSearch(args[1].value);
	};

	useEffect(() => {
		if (!search.length) {
			dispatch(getContacts());
			return;
		}
		if (search.length < 3) return;
		const fetch = async () => {
			const list = await searchContact(search);
			setContacts(list as Array<Contact>);
		};
		fetch();
	}, [search]);
	useEffect(() => {
		contacts && setContacts(contacts.filter(contact => contact.paymentType !== 'CRYPTO'));
	}, [contacts]);
	useEffect(() => { error && showErrorNotification(error); }, [error]);
	useEffect(() => { e && showErrorNotification(e); }, [e]);
	useEffect(() => { dispatch(getContacts()); dispatch(getAccounts()); }, []);

	return (
		<Container id="mycontacts">

			<Dimmer inverted active={running} ><Loader /></Dimmer>
			<ContactDetailsModal
				open={!!contactDetails}
				onClose={() => setContactDetails(null)}
				contact={contactDetails}
			/>
			<RequestMoney contact={request} onClose={() => { setRequest(null); }} open={!!request} />
			<SendMoney message={tiket} open={!!tiket} onClose={() => { setTicket(null); dispatch(getAccounts()); }} />
			<Confirm
				open={!!confirmDeleting}
				onCancel={() => setConfirmDeleting(false)}
				onConfirm={() => remove(confirmDeleting as number)}
				size='mini'
			>
				<Dimmer inverted active={running} ><Loader /></Dimmer>
			</Confirm>
			<Header className='myContactsHeader'>{t('contacts.my.header')}</Header>
			<Segment>
				<Grid fluid columns="equal" stackable>
					<Grid.Row only='computer tablet'>
						<Grid.Column floated="left">
							<Button className='createContactBTN' primary onClick={create} content={t('contacts.my.createBtn')} />
						</Grid.Column>
						<Grid.Column floated="right">
							<Input onChange={handleSearchChange} fluid placeholder={t('contacts.my.search')} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Grid stackable>
					<Grid.Row columns={2} only='mobile'>
						<Grid.Column >
							<Input onChange={handleSearchChange} fluid placeholder={t('contacts.my.search')} />
						</Grid.Column>
						<Grid.Column className='contactBTNCLMN'>
							<Button className='createContactBTN' primary onClick={create} content={t('contacts.my.createBtn')} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<List className="mycontacts" size="large" selection relaxed="very" divided verticalAlign='middle'>
					<List.Header className='contactListHeader'>{t('contacts.my.listHeader')}</List.Header>
					{loading && <Segment placeholder><Loading /></Segment>}
					{!loading && !contactList.length && (
						<Segment placeholder>
							<Header icon>
								<Icon name='user outline' />{search !== '' ? t('contacts.my.notFound') : t('contacts.my.none')}</Header>
							<Button primary onClick={create}>{t('contacts.my.add')}</Button>
						</Segment>
					)}
					{!!contactList.length && contactList.map((contact) => {
						return (
							<List.Item id='desktopContactList' key={contact.beneficiaryAccountId} onClick={() => setContactDetails(contact)}>
								<List.Content floated='right'>
									<Dropdown className='listDropdown' icon="ellipsis horizontal">
										<Dropdown.Menu direction="left" >
											<Dropdown.Item onClick={() => setContactDetails(contact)} text={t('contacts.menu.view')} />
											{contact.paymentType === ContactTypes.LOCAL && <Dropdown.Item onClick={() => sendMoney(contact)} text={t('contacts.menu.send')} />}
											{contact.paymentType === ContactTypes.LOCAL && <Dropdown.Item onClick={() => requestMoney(contact)} text={t('contacts.menu.request')} />}
											<Dropdown.Item onClick={() => setConfirmDeleting(contact.beneficiaryAccountId)} text={t('contacts.menu.delete')} />
										</Dropdown.Menu>
									</Dropdown>
								</List.Content>
								<List.Content className="contactName">{contact.name} ({contact.paymentType === ContactTypes.LOCAL ? t('contacts.create.internalWithType', { type: contact.type === 'BANK' ? t('contacts.create.bankType') : t('contacts.create.trustType') }) : contact.paymentType === ContactTypes.UK_CHAPS ? 'CHAPS' : t('contacts.create.sepa')})</List.Content>
							</List.Item>
						);
					})}
				</List>
			</Segment>
		</Container>
	);
};

export default MyContacts;
