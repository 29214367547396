import * as React from "react";

function SvgHelpIconMobile(props) {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={12}
        cy={12}
        r={10}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.09 8.461a3 3 0 015.83 1c0 2-2.92 2.91-2.92 2.91v.908"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={12} cy={16.75} r={1.25} fill="#1C2630" />
    </svg>
  );
}

export default SvgHelpIconMobile;
