import { KycStatus } from '@/features/user/types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button, Message } from 'semantic-ui-react';

interface OnfidoStatusProps {
	kycStatus: string,
	checkCount: number
	onClose: () => void
}

const OnfidoStatus = ({ kycStatus, checkCount, onClose }: OnfidoStatusProps) => {
	const { t } = useTranslation('translations');

	const count = checkCount ?? 0;
	return (
		<Modal.Content>
			{kycStatus === KycStatus.IN_PROGRESS && <Message> KYC in progress.</Message>}
			{kycStatus === KycStatus.REJECTED && count >= 2 && <Message> KYC rejected.</Message>}
			{kycStatus === KycStatus.REJECTED && count < 2 && <Message> KYC rejected, please retry. Last attempt left. </Message>}
			{kycStatus === KycStatus.VERIFIED && <Message> KYC verification completed.</Message>}
			<Button onClick={onClose}>{t('form.buttons.close')}</Button>
		</Modal.Content>
	);
};

export default OnfidoStatus;
