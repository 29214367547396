import React, { useState } from 'react';
import { Modal, Checkbox, Grid } from 'semantic-ui-react';



const DTSCBAnnouncement = (): React.ReactElement => {
	const [confirm1, setConfirm1] = useState(false);
	const open1 = (!confirm1);


	const [confirm2, setConfirm2] = useState(false);
	const open2 = confirm1 && !confirm2;


	const [confirm3, setConfirm3] = useState(false);
	const open3 = confirm1 && confirm2 && !confirm3;


	return (
		<>
			<Modal
				size="large"
				open={open1}
				closeOnDimmerClick={false}
				closeOnEscape={false}
			>
				<Modal.Content>
					<Modal.Description>
						<h3>Dear Customer!</h3>
						<p>Thank you for being a valued client of DTSCB! </p>
						<p>To achieve the strategic goals of the company&apos;s development, DTSCB is changing the vector of the company&apos;s further development and switches the provision of services for individuals and legal entities.</p>
						<p>With  that  being  said,  we  would  like  to  let  you  know  that  your  existing  profile, will be switched to the financial institution Xward Pay Inc,c after 01. May 2023.
							More information about Xward Pay Inc accessible on its website - <a href="https://dtsmoney.com/">https://dtsmoney.com/</a></p>
						<p>You have 30 days, until May 31, 2023, to continue to use services in the best possible way.  After  this  date,  your  profile  will  remain  accessible  without  the possibility of using provided products and services for the period of 60 days. 
							All transactions will be limited, and you will not be able to send and receive payments, money transfers and use your cards. 
							After 60 days period access to your profile will be closed.</p>
							<p>Please  apply  for  XwardPay  account  within  30  days  of  receiving  this  notice  by  completing  the registration process at the link - <a href="https://portal.dtsmoney.com/auth/signup">https://portal.dtsmoney.com/auth/signup</a>. Keep in mind that you will need to go through а short verification process to verify your identity.</p>
						<p>If you have funds in your account and/or card on 31 May 2023, the account balance will be transferred to the account provided by Xward Pay Inc. After the transfer of the balance, the current account is closed.</p>
						<p>If  you  have  questions  regarding  the  profile  switching  process  or  closed  accounts,  cards  or transactions history, you can contact us at  <a href="https://dtcirclesupport.com/">https://dtcirclesupport.com/</a> </p>
						<p>If you do not want to accept the changes and switch to Xward Pay Inc, you have the right to cancel your current service before the 31th of May 2023 and terminate your Legal agreement by notifying us of your decision with a free format message sent through your personal profile. </p>
						<p>Please clear your account and card balance prior to termination notice.</p>
						<p>Your funds are safe and secure and would be available to you until the full transfer or withdrawn. </p>
						<p>Kind Regards,
							<br />DTSCB Team
						</p>
					</Modal.Description>
				</Modal.Content>
				<Grid columns={1} padded>
					<Grid.Column floated="left">
						<Grid.Column width={12} style={{ paddingBottom: '10px' }}>
							<Checkbox
								label='I agree: I hereby confirm that the conditions are fully understood and accepted by me'
								onChange={() => setConfirm1(!confirm1)}
								checked={confirm1}
							/>
						</Grid.Column>
					</Grid.Column>
				</Grid>
			</Modal>
			<Modal
				size="large"
				open={open2}
				closeOnDimmerClick={false}
				closeOnEscape={false}
			>
				<Modal.Content>
					<Modal.Description>
						<h3>Dear valued customer!</h3>
						<p>We inform you that starting from 01 July 2023, a new fee position will be entered the Table of Commissions and Limits. </p>
						<p>Please be informed that a monthly Fee fee of EUR 150.00 will be charged for the storage of funds in the account after the decision to terminate business relationship with the Client. </p>
						<p>The charge is applied from the next working day following the date on which the provision of services is ceased in accordance with the decision to terminate business relationship.</p>
						<p>Kind Regards,
							<br />DTSCB
						</p>
					</Modal.Description>
				</Modal.Content>
				<Grid columns={1} padded>
					<Grid.Column floated="left">
						<Grid.Column width={12} style={{ paddingBottom: '10px' }}>
							<Checkbox
								label='I agree'
								onChange={() => setConfirm2(!confirm2)}
								checked={confirm2}
							/>
						</Grid.Column>
					</Grid.Column>
				</Grid>
			</Modal>
			<Modal
				size="large"
				open={open3}
				closeOnDimmerClick={false}
				closeOnEscape={false}
			>
				<Modal.Content>
					<Modal.Description>
						<h3>Dear valued customer!</h3>
						<p>Following previous notices posted on your personal  profile, we remind you that the provision of services and business relationships were terminated.</p>
						<p>Please be informed that starting 01 September 2023, a monthly commission fee of EUR 150.00 is charged for the storage of funds in the account after the decision to terminate business relationship with the Client.</p>
						<p>The charge is applied from the next working day following the date on which the provision of services is ceased in accordance with the decision to terminate business relationship.</p>
						<p>Kind Regards,
							<br />DTSCB
						</p>
					</Modal.Description>
				</Modal.Content>
				<Grid columns={1} padded>
					<Grid.Column floated="left">
						<Grid.Column width={12} style={{ paddingBottom: '10px' }}>
							<Checkbox
								label='I hereby confirm that I am informed about applied Fees'
								onChange={() => setConfirm3(!confirm3)}
								checked={confirm3}
							/>
						</Grid.Column>
					</Grid.Column>
				</Grid>
			</Modal>
		</>);
};

export default DTSCBAnnouncement;
