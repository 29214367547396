import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js';
import 'raf/polyfill';
import './i18n';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'semantic-ui-less/semantic.less';
import './index.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function render() {
	ReactDOM.render(
		<React.StrictMode>
			<Provider store={store}>
				<App />
				<ToastContainer
					position="bottom-right"
					autoClose={3000}
					hideProgressBar={false}
					closeOnClick={true}
					pauseOnHover={true}
					draggable={false}
					theme='light'
				/>
			</Provider>
		</React.StrictMode>,
		document.getElementById('root')
	);
}
setTimeout(() => render());
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


if (process.env.NODE_ENV === 'development' && module.hot) {
	module.hot.accept('./App', render);
}
