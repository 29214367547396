import React, { useState } from 'react';
import { Modal, Checkbox, Grid } from 'semantic-ui-react';
import { Agents } from '@/helpers/globalTypes';

interface Props {
	agent: string
}

const CospayAnnouncement = ({ agent }: Props): React.ReactElement => {
	const [confirm1, setConfirm1] = useState(false);
	const [confirm2, setConfirm2] = useState(false);
	const [confirm3, setConfirm3] = useState(false);
	const open = agent === Agents.COSPAY && (!confirm1 || !confirm2 || !confirm3);

	return <Modal
		size="large"
		open={open}
		closeOnDimmerClick={false}
		closeOnEscape={false}
	>
		<Modal.Content>
			<Modal.Description>
				<p>Dear Customer!</p>
				<p>Thank you for being a valued client of Cospay Limited! </p>
				<p>To achieve the strategic goals of the company&apos;s development, Cospay is changing the vector of the company&apos;s further development and <strong>switches the provision of services</strong> for individuals and legal entities, concentrating on serving institutional clients.</p>
				<p>With that being said, we would like to let you know that your existing profile accessed on Cospay.com, will be <strong>switched to the financial institution Xward Pay Inc,c after 01. May 2023</strong>
					<br />More information about Xward Pay Inc accessible on its website - <a href="https://dtsmoney.com/"> https://dtsmoney.com/</a></p>
				<p>You have <strong>30 days, until May 31, 2023</strong>, to continue to use Cospay services in the best possible way. After this date, your profile accessed on Cospay.com will remain accessible <strong>without the possibility</strong> of using provided products and services for the period of 60 days. All transactions will be limited, and you will not be able to send and receive payments, money transfers and use your cards. After 60 days period access to your profile will be closed.</p>
				<p>Please apply for XwardPay account within <strong>30 days</strong> of receiving this notice by completing the registration process at the link - <a href="https://portal.dtsmoney.com/auth/signup/"> https://portal.dtsmoney.com/auth/signup</a>. Keep in mind that you will need to go through а short verification process to verify your identity.</p>
				<p>If you have funds in your Cospay account and/or card on 31 May 2023, the account balance will be transferred to the account provided by Xward Pay Inc. After the transfer of the balance, the current account in Cospay is closed.
					<br />If you have questions regarding the profile switching process or closed accounts, cards or transactions history, you can contact us at info@cospay.com.
					<br />If you do not want to accept the changes and switch to Xward Pay Inc, you have the right to cancel your current service before the 31th of May 2023 and terminate your Legal agreement by notifying us of your decision with a free format message sent through your personal profile. Please clear your account and card balance prior to termination notice</p>
				<p>Your funds are safe and secure and would be available to you until the full transfer or withdrawn.</p>
				<p>Kind Regards,
					<br />The Cospay Team
				</p>
			</Modal.Description>
		</Modal.Content>
		<Grid columns={1} padded>
			<Grid.Column floated="left">
				<Grid.Column width={12} style={{ paddingBottom: '10px' }}>
					<Checkbox
						label='I agree: I hereby confirm that the conditions are fully understood and accepted by me'
						onChange={() => setConfirm1(!confirm1)}
						checked={confirm1}
					/>
				</Grid.Column>
				<Grid.Column width={12} style={{ paddingBottom: '10px' }}>
					<Checkbox
						label='I agree: I understand that if I want to offer to use the service, I can open a new account for 30 days on - https://dtsmoney.com/ (if I am not already registered)'
						onChange={() => setConfirm2(!confirm2)}
						checked={confirm2}
					/>
				</Grid.Column>
				<Grid.Column width={12} style={{ paddingBottom: '10px' }}>
					<Checkbox
						label='I agree: If I do not transfer my personal funds on my own, then my account will be transferred to https://dtsmoney.com / - and I will get access to the account only after repeated identification procedure.'
						onChange={() => setConfirm3(!confirm3)}
						checked={confirm3}
					/>
				</Grid.Column>
			</Grid.Column>
		</Grid>
	</Modal>;
};

export default CospayAnnouncement;
