import React from 'react';
import '../Footer/Footer.css';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import AMLPolicy from '@/components/Legal/AMLPolicy/AMLPolicy';
import PrivacyPolicy from '@/components/Legal/PrivacyPolicy/PrivacyPolicy';
import Terms from '@/components/Legal/Terms/Terms';
import Footer from '@/components/Footer/Footer';
import Navbar from '@/components/Navbar/Navbar';
import './Legal.css';
import PrivacyPolicyDTSCB from '@/components/Legal/PrivacyPolicy/PrivacyPolicyDTSCB';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@helpers/globalTypes';
import TermsDTSCB from '@/components/Legal/Terms/TermsDTSCB';
import AMLPolicyDTSCB from './AMLPolicy/AMLPolicyDTSCB';
import AMLPolicyTYNCOFEX from './AMLPolicy/AMLPolicyTYNCOFEX';
import TermsTYNCOFEX from './Terms/TermsTYNCOFEX';
import PrivacyPolicyTYNCOFEX from './PrivacyPolicy/PrivacyPolicyTYNCOFEX';
import PrivacyPolicyDTS from './PrivacyPolicy/PrivacyPolicyDTS';
import TermsDTS from './Terms/TermsDTS';
import CookiesPolicyDTS from './CookiesPolicy/CookiesPolicyDTS';
import NotFound from '@/pages/NotFound';
import TermsOfUseDTS from './TermsOfUse/TermsOfUseDTS';
import PrivacyPolicyCUSTODYFY from './PrivacyPolicy/PrivacyPolicyCUSTODYFY';
import TermsOfUseCUSTODYFY from './TermsOfUse/TermsOfUseCUSTODYFY';
import TermsCUSTODYFY from './Terms/TermsCUSTODYFY';
import { useTranslation } from 'react-i18next';
import TermsITCUSTODYFY from './Terms/TermsITCUSTODYFY';
import PrivacyPolicyITCUSTODYFY from './PrivacyPolicy/PrivacyPolicyITCUSTODYFY';
import TermsOfUseITCUSTODYFY from './TermsOfUse/TermsOfUseITCUSTODYFY';
import PrivacyPolicyDTSUS from './PrivacyPolicy/PrivacyPolicyDTSUS';
import TermsOfUseDTSUS from './TermsOfUse/TermsOfUseDTSUS';
import TermsOfUseYESSY from './TermsOfUse/TermsOfUseYESSY';
import TermsYESSY from './Terms/TermsYESSY';
import PrivacyPolicyYESSY from './PrivacyPolicy/PrivacyPolicyYESSY';
import TermsDTSUS from './Terms/TermsDTSUS';
import PrivacyPolicyOlamit from './PrivacyPolicy/PrivacyPolicyOlamit';
import TermsOfUseOlamit from './TermsOfUse/TermsOfUseOlamit';
import TermsOLAMIT from './Terms/TermsOLAMIT';

const Legal = (): React.ReactElement => {
	const { path } = useRouteMatch();
	const { i18n } = useTranslation('translations');
	const { agent } = useSelector(
		(state: RootState) => state.status
	);
	return (
		<div id='legal'>
			<Navbar menu={false} />
			<Switch>
				<Route path={`${path}/amlpolicy`}>
					{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && agent !== Agents.DTS && agent !== Agents.WZ && agent !== Agents.OLAMIT && agent !== Agents.COSPAY && <AMLPolicy />}
					{agent === Agents.DTSCB && <AMLPolicyDTSCB />}
					{agent === Agents.TYNCOFEX && <AMLPolicyTYNCOFEX />}
					{agent === Agents.DTS || agent === Agents.CUSTODYFY || agent === Agents.COSPAY && <NotFound />}
				</Route>
				<Route path={`${path}/privacypolicy`}>
					{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && agent !== Agents.DTS && agent !== Agents.WZ && agent !== Agents.CUSTODYFY && agent !== Agents.DTSUS && agent !== Agents.YESSY && agent !== Agents.OLAMIT && <PrivacyPolicy />}
					{agent === Agents.DTSCB && <PrivacyPolicyDTSCB />}
					{agent === Agents.DTS && <PrivacyPolicyDTS />}
					{agent === Agents.YESSY && <PrivacyPolicyYESSY />}
					{agent === Agents.DTSUS && <PrivacyPolicyDTSUS />}
					{agent === Agents.TYNCOFEX && <PrivacyPolicyTYNCOFEX />}
					{agent === Agents.OLAMIT && <PrivacyPolicyOlamit />}
					{agent === Agents.CUSTODYFY && i18n.language !== 'it' && <PrivacyPolicyCUSTODYFY />}
					{agent === Agents.CUSTODYFY && i18n.language === 'it' && <PrivacyPolicyITCUSTODYFY />}
				</Route>
				<Route path={`${path}/terms`}>
					{agent !== Agents.DTSCB && agent !== Agents.TYNCOFEX && agent !== Agents.DTS && agent !== Agents.DTSUS && agent !== Agents.WZ && agent !== Agents.CUSTODYFY && agent !== Agents.YESSY && agent !== Agents.OLAMIT && <Terms />}
					{agent === Agents.DTSCB && <TermsDTSCB />}
					{agent === Agents.DTS && <TermsDTS />}
					{agent === Agents.DTSUS && <TermsDTSUS />}
					{agent === Agents.YESSY && <TermsYESSY />}
					{agent === Agents.OLAMIT && <TermsOLAMIT />}
					{agent === Agents.CUSTODYFY && i18n.language !== 'it' && <TermsCUSTODYFY />}
					{agent === Agents.CUSTODYFY && i18n.language === 'it' && <TermsITCUSTODYFY />}
					{agent === Agents.TYNCOFEX && <TermsTYNCOFEX />}
				</Route>
				<Route path={`${path}/termsofuse`}>
					{agent === Agents.DTS && <TermsOfUseDTS />}
					{agent === Agents.DTSUS && <TermsOfUseDTSUS />}
					{agent === Agents.YESSY && <TermsOfUseYESSY />}
					{agent === Agents.OLAMIT && <TermsOfUseOlamit />}
					{agent === Agents.CUSTODYFY && i18n.language !== 'it' && <TermsOfUseCUSTODYFY />}
					{agent === Agents.CUSTODYFY && i18n.language === 'it' && <TermsOfUseITCUSTODYFY />}
				</Route>
				<Route path={`${path}/cookiespolicy`}>
					{agent === Agents.DTS && <CookiesPolicyDTS />}
				</Route>
			</Switch>
			<Footer authorized={false} />
		</div>
	);
};

export default Legal;
