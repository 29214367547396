import request from '@/services/request';
import { AppThunk } from '../../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Vault, VaultsStore } from './types';

const findVaultByProc = (proc, list) => {
	const id = list.findIndex(v => v.proc === proc);
	return id;
};


const initialState: VaultsStore = {
	loading: true,
	list: [],
	error: null
};

const slice = createSlice({
	name: 'vaults',
	initialState,
	reducers: {
		setVaults(state, action: PayloadAction<Array<Vault>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		}
	}
});

export const { setVaults, setLoading, setError } = slice.actions;



// export const getDeposits = async (proc: string): Promise<string> => {
// 	const response = await request.get('/api/vaults');
// 	const { data } = response;
// 	return data.filter(d => d.proc === proc);
// };

export const getVaults = (): AppThunk => {
	return async (dispatch, state) => {
		const { vaults } = state();
		const { list } = vaults;
		const currentVaults = JSON.parse(JSON.stringify(list));
		try {
			const response = await request.get('/api/vaults');
			const { data } = response;
			data.forEach(vault => {

				const id = findVaultByProc(vault.proc, currentVaults);
				if (id < 0) {
					currentVaults.push(vault);
				} else {
					currentVaults[id] = vault;
				}

			});
			currentVaults.sort((a, b) => parseFloat(a.config.yearlyInterestRate) - parseFloat(b.config.yearlyInterestRate));
			dispatch(setVaults(currentVaults));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};


	// const response = await request.get('/api/vaults');
	// const { data } = response;
	// return data;
};

export default slice.reducer;
