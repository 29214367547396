import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Header, Icon, Segment, Message } from 'semantic-ui-react';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import './BankAccount.css';
import { TransferType } from '../SendMoney';
import BankAccountConfirmation from './BankAccountConfirmation';
import { showErrorNotification, showInfoNotification } from '@features/swal/slice';
import { runOperation } from '@features/operations/slice';
import { postFile } from '@features/files/slice';
import { maxTwoDecimals } from '@/helpers/number';
import { AccountLimits } from '@/features/account/types';
import { Agents } from '@/helpers/globalTypes';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

enum SystemType {
	SEPA = 'SEPA_STEP2', SWIFT = 'SWIFT', UK_CHAPS = 'UK_CHAPS', US_WIRE = 'US_WIRE'
}

type Props = {
	onSend: (type: TransferType, value: any) => void,
	account: any,
	subProcesses: any,
	integration: string,
	accountLimits: AccountLimits
}

interface Payload {
	accountId: number,
	beneficiaryName: string,
	beneficiaryIdentityNo?: string,
	beneficiaryIban?: string,
	beneficiaryBicSwift?: string,
	beneficiaryBankCountry?: string,
	beneficiaryBankName?: string,
	beneficiaryBankAddress?: string,
	beneficiarySortCode?: string,
	beneficiaryAccountNumber?: string
	amount: number,
	ccy: string,
	details: string,
	saveToContacts: boolean,
	attachments: any,
	beneficiaryAccountId?: number,
	isToBusiness: boolean,
	sortCode?: string,
	beneficiaryRoutingNumber?: string,
	beneficiaryAddressCountry?: string,
	beneficiaryAddressStreet?: string,
	beneficiaryAddressCity?: string,
	beneficiaryRegion?: string,
	beneficiaryPostalCode?: string,
	beneficiaryFurtherCreditAccountName?: string,
	beneficiaryFurtherCreditAccountNumber?: string,
}

interface Country {
	code: string,
	label: string,
}

/* eslint-disable  @typescript-eslint/no-unused-vars */
const TrustDomesticPayment = ({ onSend, account, subProcesses, integration, accountLimits }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [payload, setPayload] = useState<any>();
	const [process, setProcess] = useState<any>();
	const [type] = useState<SystemType>(SystemType.US_WIRE);
	const [name, setName] = useState<string>('');
	const [reference, setReference] = useState<string>('');
	const [amount, setAmount] = useState<string>('');
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [saveToContacts, setSaveToContacts] = useState<boolean>(false);
	const [accountToCredit, setAccountToCredit] = useState<boolean>(false);
	const [systems, setSystems] = useState([
	]);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const [confirmed, setConfirmed] = useState<boolean>(integration === 'TRUST' && agent !== Agents.DTSUS ? false : true);

	const contriesList = getNames('en');
	const [countries] = useState<Array<Country>>(
		Object.keys(contriesList)
			.map($code => ({
				code: $code,
				label: contriesList[$code],
			})));


	const fileTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	];
	const [selectedFiles, setSelectedFiles] = useState([]);


	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 1) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 1) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};
	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};

	const hiddenFileInput = React.useRef(null);

	useEffect(() => {
		if (!subProcesses) return;
		const filtered = Object.keys(subProcesses).filter(key => subProcesses[key].type === 'PAYMENT').map(key => subProcesses[key]);
		setSystems(filtered);
	}, [subProcesses]);

	useEffect(() => {
		setProcess(systems.find(s => s.processProperties.paymentType === type));
	}, [type, systems]);


	const validationSchema = Yup.object({
		accountNumber: Yup.string().required(t('form.validator.required')),
		routingNumber: Yup.string().required(t('form.validator.required')),
		amount: Yup.number()
			.required(t('form.validator.required'))
			.min(0.01, t('sendmoneyTranslation.data.wrongAmount'))
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				(value) => value <= account.availableBalance)
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('sendmoneyTranslation.data.limitReached', { ccy: accountLimits.ccy, availableBalanceNow: accountLimits.availableBalanceNow }), (value) => value <= accountLimits.availableBalanceNow),
		to: Yup.string().required(t('form.validator.required')),
		reference: Yup.string().required(t('form.validator.required')).max(140, t('sendmoneyTranslation.data.canNotExceedHundred')),
		country: Yup.string().required(t('form.validator.required')),
		address: Yup.string().required(t('form.validator.required')),
		city: Yup.string().required(t('form.validator.required')),
		region: Yup.string().required(t('form.validator.required')),
		postalCode: Yup.string().required(t('form.validator.required')),

	});

	const isValidFiles = () => {
		if (integration === 'TRUST' && parseInt(amount) >= 10000) {
			return selectedFiles.length === 1;
		} else if (parseInt(amount) >= 5000) {
			return selectedFiles.length === 1;
		}
		else {
			return true;
		}
	};

	useEffect(() => {
		setSubmitting(submitting);
	}, [selectedFiles]);

	const initialValues = {
		from: account.name + ' (' + account.currency + ' ' + account.availableBalance + ')',
		amount: amount,
		to: name,
		reference: reference,
		accountNumber: '',
		routingNumber: '',
		country: '',
		address: '',
		city: '',
		region: '',
		postalCode: '',
		accountToCreditName: '',
		accountToCreditNumber: ''
	};


	const handleNameChange = async (...args) => {
		setName(args[1].value);
	};

	const handleAmountChange = async (...args) => {
		setAmount(args[1].value);
	};

	const handleReferenceChange = async (...args) => {
		setReference(args[1].value);
	};

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		if (!isValidFiles()) {
			showInfoNotification('sendmoneyTranslation.data.documentRequired');
			setSubmitting(false);
			return;
		}

		const { to, amount, reference, accountNumber, routingNumber, country, city, address, postalCode, region, accountToCreditNumber, accountToCreditName } = formData;


		const payload = {
			...{ to, account, amount, reference, addToContacts: saveToContacts, accountNumber, routingNumber, country, city, address, postalCode, region, accountToCreditNumber, accountToCreditName },
			...{ currency: account.currency },
		};

		console.log(payload);
		setPayload(payload);
		formikProps.resetForm(initialValues);
	};

	const send = async () => {
		try {
			setSubmitting(true);
			const attachments: Map<string, string> = new Map<string, string>();
			if (selectedFiles.length === 1) {
				const formData = new FormData();
				formData.append('file', selectedFiles[0]);
				const fileRecord = await postFile(formData);
				const { key } = fileRecord;
				attachments.set(key, selectedFiles[0].name);
			}



			const dataBody: Payload = {
				accountId: account.accountId,
				beneficiaryName: name,
				beneficiaryIdentityNo: '',
				amount: payload.amount,
				ccy: account.currency,
				details: payload.reference,
				saveToContacts: false,
				attachments: Object.fromEntries(attachments),
				isToBusiness: false,
				beneficiaryPostalCode: payload.postalCode,
				beneficiaryAddressCountry: alpha2ToAlpha3(payload.country),
				beneficiaryAddressStreet: payload.address,
				beneficiaryAddressCity: payload.city,
				beneficiaryRegion: payload.region,
				beneficiaryAccountNumber: payload.accountNumber,
				beneficiaryRoutingNumber: payload.routingNumber,
			};

			if (accountToCredit) {
				dataBody.beneficiaryFurtherCreditAccountName = payload.accountToCreditName;
				dataBody.beneficiaryFurtherCreditAccountNumber = payload.accountToCreditNumber;
			}

			await runOperation(process.proc, dataBody);

			setSubmitting(false);
			onSend(TransferType.EXTERNAL, payload);
		} catch (e: any) {
			const validtionError = (e?.data?.errors[0]?.error?.message?.indexOf('Invalid') !== -1) || false;
			setSubmitting(false);
			showErrorNotification(e, validtionError ? 'errors.DATA_ISSUE' : 'errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE');
			setPayload(null);
		}
	};

	if (payload) {
		console.log('process', process);
		return (
			<div id="sendmoneynew">
				<BankAccountConfirmation payload={payload} process={process} onConfirm={send} submitting={submitting} files={selectedFiles} isDomesticTrustPayment={true} />
			</div>
		);
	}

	return (
		<div id="sendmoneynew">
			<Grid>
				<Grid.Column width={16}>
					<Segment padded>
						<Header className="cospayuserheader" as="h3">{integration === 'TRUST' ? agent === Agents.DTSUS ? t('sendmoneyTranslation.data.transfer') : t('sendmoneyTranslation.data.order') : t('sendmoneyTranslation.data.header')}</Header>
						{(integration === 'TRUST' && agent !== Agents.DTSUS) && <Message>
							{t('sendmoneyTranslation.trustInfo')}
						</Message>}
						<Divider hidden />
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ setFieldValue, errors, isSubmitting, validateForm }) => {
								return (
									<Form>
										<Input
											fluid
											id="account"
											name="from"
											label={t('sendmoneyTranslation.data.from')}
											disabled
										/>
										<Input
											className="transferamountinput"
											fluid
											onChange={handleAmountChange}
											name="amount"
											label={t('sendmoneyTranslation.data.transferamount')}
											placeholder={`0.00 ${account.currency}`}
											type='number'
											onWheel={(e) => e.target.blur()}
											errorPrompt
										/>
										<Divider section />
										<Input
											fluid
											name="to"
											onChange={handleNameChange}
											label={t('sendmoneyTranslation.data.beneficiary')}
											errorPrompt
										/>

										<Input
											fluid
											name="accountNumber"
											label={t('sendmoneyTranslation.data.accountNumber')}
											errorPrompt
										/>
										<Input
											fluid
											name="routingNumber"
											label={t('sendmoneyTranslation.data.routingNumber')}
											errorPrompt
										/>
										<Divider section />

										<Select
											fluid
											name="country"
											label={t('form.fields.country')}
											placeholder={t('form.placeholder.country')}
											errorPrompt
											list="countries"
											options={countries.map(c => ({ key: c.code, value: c.code, text: c.label }))}
											clearable
											search
										/>

										<Input
											fluid
											name="address"
											label={t('form.fields.justAddress')}
											errorPrompt
										/>
										<Input
											fluid
											name="city"
											label={t('form.fields.town')}
											errorPrompt
										/>
										<Input
											fluid
											name="region"
											label={t('sendmoneyTranslation.data.region')}
											errorPrompt
										/>
										<Input
											fluid
											name="postalCode"
											label={t('form.fields.postalcode')}
											errorPrompt
										/>
										<Divider section />
										<Checkbox
											name='addAccountToCredit'
											onChange={() => { setAccountToCredit(!accountToCredit); }}
											checked={accountToCredit}
											label={t('sendmoneyTranslation.data.accountToCreditCbx')}
										/>
										{accountToCredit && <>
											<Input
												fluid
												name="accountToCreditName"
												label={t('sendmoneyTranslation.data.accountToCreditName')}
												errorPrompt
											/>
											<Input
												fluid
												name="accountToCreditNumber"
												label={t('sendmoneyTranslation.data.accountToCreditNumber')}
												errorPrompt
											/>
										</>}
										<Divider section />
										<TextArea
											name="reference"
											onChange={handleReferenceChange}
											value={reference}
											label={t('sendmoneyTranslation.data.reference')}
											errorPrompt
											maxLength="140"
										/>

										<Divider hidden />
										{integration === 'TRUST' ? <p><b>{t('sendmoneyTranslation.data.document', { ccy: account.currency, amount: '10 000' })}</b></p> : <p><b>{t('sendmoneyTranslation.data.document', { ccy: account.currency, amount: '5 000' })}</b></p>}

										<input type="file"
											multiple
											accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
											name="files"
											ref={hiddenFileInput}
											onChange={e => {
												uploadFiles(e.currentTarget.files);
												setFieldValue('files', selectedFiles);
											}}
											style={{ display: 'none' }}
										/>
										<Button id='uploadButton' basic type="button" onClick={handleUploadClick} content={t('sendmoneyTranslation.activedata.upload')} icon="upload" />
										<Divider hidden />
										<div id='security-info'>
											<Grid>
												<Grid.Column verticalAlign='middle'><Icon size='large' name='info circle' color='blue' /></Grid.Column>
												<Grid.Column width={15}>{t('sendmoneyTranslation.data.fileTypes')}</Grid.Column>
											</Grid>
										</div>
										{selectedFiles.length > 0 && (
											<Grid className="uploadedFiles" padded>
												<Divider hidden />
												{selectedFiles.map((file, index) =>
													<Grid.Row className="uploadedFile" key={file.name}>
														<Icon size="large" name="file outline" />
														<div className="fileName">{file.name}</div>
														<Grid.Column floated='right' className="deleteAttachment">
															<Icon
																onClick={() => {
																	deleteAttachment(index);
																	setFieldValue('files', selectedFiles);
																}}
																size="large"
																name="times" />
														</Grid.Column>
													</Grid.Row>
												)}
											</Grid>
										)}
										<Divider section />
										<SubmitButton disabled={isSubmitting || !isEmpty(errors)} primary fluid type="submit">{integration === 'TRUST' && agent !== Agents.DTSUS ? t('sendmoneyTranslation.activedata.trustButton') : t('sendmoneyTranslation.activedata.button')}</SubmitButton>
									</Form>);
							}}
						</Formik>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>);
};

export default TrustDomesticPayment;
