import request from '../../services/request';
import { TemplatePayload, TransactionsLazyPayload, TransactionsPayload, TransactionType } from './types';
import { saveAs } from 'file-saver';

export const getTransactions = async (payload: TransactionsPayload) => {
    const response = await request.post('/api/transactions', payload);
    const { data } = response;
    return data;
};

export const getTransactionsByAccountType = async (payload: TransactionsPayload) => {
    const response = await request.post('/api/transactions/by-account-type', payload);
    const { data } = response;
    return data;
};

export const getTransactionsLazy = async (payload: TransactionsLazyPayload): Promise<any[]> => {
	const response = await request.post('/api/transactions/v3/lazy', payload);
	const { data } = response;
	return data?.list||[];
};

export const getCsv = async (payload: TemplatePayload): Promise<void> => {
    await request.post('/api/notifications/templates/csv',
        payload, { responseType: 'text' }).then((response) => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        saveAs(blob, 'transactions.csv');
    });
};

export const getExcel = async (payload: TemplatePayload): Promise<void> => {
    await request.post('/api/notifications/templates/excel',
        payload, { responseType: 'arraybuffer' }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'transactions.xlsx');
    });
};

export const getExchangePdf = async (payload: any, filename: string): Promise<void> => {
	await request.post('/api/notifications/templates/pdf/exchange',
		payload, { responseType: 'arraybuffer' }).then((response) => {
		const blob = new Blob([response.data], { type: 'application/pdf' });
		saveAs(blob, filename);
	});
};

export const getPdf = async (payload: any, filename: string): Promise<void> => {
    await request.post('/api/notifications/templates/pdf',
        payload, { responseType: 'arraybuffer' }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, filename);
    });
};

export const getStatementPdf = async (payload: any, filename: string): Promise<void> => {
    await request.post('/api/notifications/statements/pdf',
        payload,
        { responseType: 'arraybuffer' }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        saveAs(blob, filename);
    });
};

export const getPdfData = async (payload: any,  transactionActivity: TransactionType): Promise<ArrayBuffer> => {
    const response = transactionActivity === TransactionType.FX_TRANSACTION ?
	await request.post('/api/notifications/templates/pdf/exchange',  payload, { responseType: 'arraybuffer' })
	: await request.post('/api/notifications/templates/pdf',  payload, { responseType: 'arraybuffer' });
    return response.data;
};

export const resolveTemplateType = (transactionType: TransactionType, amount: number): string => {
    switch (transactionType) {
        case TransactionType.TRANSFER:
            if (amount > 0) {
                return 'transactions.internal.incoming';
            } else {
                return 'transactions.internal.outgoing';
            }
        case TransactionType.PAYMENT:
            return 'transactions.outgoing.template';
        case TransactionType.DEPOSIT:
            return 'transactions.incoming.template';
        case TransactionType.PURCHASE:
            return 'transactions.payment.template';
        case TransactionType.CARD_TOP_UP:
            return 'transactions.card.topup.template';
        case TransactionType.OWN_TRANSFER:
            return 'transactions.self.template';
        case TransactionType.FX_TRANSACTION:
            return 'transactions.exchange.template';
    }
};
