import React, { useState } from 'react';
import { Button, Divider, Grid, Segment, } from 'semantic-ui-react';
import '@/components/Dashboard/MyContacts.css';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { ContactTypes } from './CreateContact';
import { addContact } from '@/features/contacts/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { useHistory } from 'react-router';


const NewChapsContact = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const history = useHistory();
	const [iban, setIban] = useState<string>('');
	const [contactName, setContactName] = useState<string>('');
	const [identificationNo, setIdentificationNo] = useState<string>(null);
	const [isToBusiness, setIsToBusiness] = useState<boolean>(false);


	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required')),
		sortCode: Yup.string().required(t('form.validator.required')),
		accountNumber: Yup.string().required(t('form.validator.required'))
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { name, sortCode, accountNumber } = formData;


			await dispatch(addContact({ name, paymentType: ContactTypes.UK_CHAPS, identification: identificationNo, sortCode, accountNumber }));
			history.push('/wallet/contacts');
		} catch (error) { showErrorNotification(error); }
		finally { setSubmitting(false); }
	};



	return (
		<Grid id='createContact' container>
			<Grid.Column width={16}>
				<Segment padded>
					<Formik
						initialValues={{
							name: contactName,
							accountNumber: '',
							sortCode: ''
						}}
						enableReinitialize={true}
						validationSchema={validationSchema}
						validateOnMount={true}
						onSubmit={submit}
					>{({ errors, isSubmitting }) => (<Form
						size="large">
						<div className='field'>
							<label>{t('contacts.create.beneType')}</label>
							<Button.Group>
								<Button type='button' active={!isToBusiness} content={t('contacts.create.individual')} onClick={() => setIsToBusiness(false)} primary={!isToBusiness} />
								<Button.Or />
								<Button type='button' active={isToBusiness} content={t('contacts.create.business')} onClick={() => setIsToBusiness(true)} primary={isToBusiness} />
							</Button.Group>
						</div>
						<Input
							label={isToBusiness ? t('contacts.create.companyName') : t('contacts.create.name')}
							fluid
							name="name"
							placeholder={isToBusiness ? 'Company LTD' : 'John Doe'}
							onChange={(...args) => setContactName(args[1].value)}
							errorPrompt
						/>
						<Input
							label={t('sendmoneyTranslation.data.accountNumber')}
							fluid
							name="accountNumber"
						/>
						<Input
							label={t('sendmoneyTranslation.data.sortCode')}
							name='sortCode'
							fluid
						/>
						<Input
							label={isToBusiness ? t('contacts.create.companyRegNumber') : t('sendmoneyTranslation.activedata.identity')}
							fluid
							name="identification"
							onChange={(...args) => setIdentificationNo(args[1].value)}
						/>
						<Divider section />
						<SubmitButton
							disabled={isSubmitting || !isEmpty(errors)}
							primary fluid type="submit">
							{t('contacts.create.save')}
						</SubmitButton>
					</Form>)}
					</Formik>
				</Segment>
			</Grid.Column>
		</Grid>
	);

};
export default NewChapsContact;



