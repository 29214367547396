import React, { useState } from 'react';
import {
	Switch,
	Route,
	Link,
	useRouteMatch
} from 'react-router-dom';
import { Container, Breadcrumb, Header, Icon } from 'semantic-ui-react';
import RequestMoney from './RequestMoney';
import SendMoney, { TransferType } from './SendMoney';
import Deposit from './Deposit';
import PaymentSubmitted from './SendMoney/PaymentSubmitted';
import './Transfers.css';
import { useTranslation } from 'react-i18next';
import BulkTopUp from '@/components/Banking/Card/TopUp/Bulk/BulkTopUp';
import Exchange from './Exchange';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';
import CardTransferSuccess from './SendMoney/CardTransfer/CardTransferSuccess';
import CardTransferError from './SendMoney/CardTransfer/CardTransferError';
import CardTransferCancel from './SendMoney/CardTransfer/CardTransferCancel';

function Transfers(): React.ReactElement {
	const { path } = useRouteMatch();
	const [payment, setPayment] = useState<string>(null);
	const { t } = useTranslation('translations');

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const onSend = (type, value?) => {
		const payment: any = {};
		switch (type) {
			case TransferType.INTERNAL:
				payment.amount = `${value.from.currency} ${value.amount}`;
				payment.account = value.to.name;
				payment.type = TransferType.INTERNAL;
				break;
			case TransferType.CONTACT:
				payment.amount = `${value.currency} ${value.amount}`;
				payment.account = value.user.firstName + ' ' + value.user.lastName;
				payment.type = TransferType.CONTACT;
				break;
			case TransferType.REQUEST:
				payment.amount = `${value.currency} ${value.amount}`;
				payment.account = value.toName;
				payment.type = TransferType.REQUEST;
				break;
			case TransferType.EXTERNAL:
				payment.amount = `${value.currency} ${value.amount}`;
				payment.account = value.to;
				payment.type = TransferType.EXTERNAL;
				break;
			case TransferType.TRUST:
				payment.amount = `${value.currency} ${value.amount}`;
				payment.account = value.to;
				payment.type = TransferType.EXTERNAL;
				break;
		}
		setPayment(payment);
	};

	if (payment) return <PaymentSubmitted payload={payment} />;

	return (
		<div id="sendmoneyactivemenu">
			<Container>
				<Breadcrumb size="large">
					<Breadcrumb.Section className="backchevronitem" link as={Link} to="/wallet/home"><Icon name="chevron left" /> {t('inbox.back')}</Breadcrumb.Section>
				</Breadcrumb>
				<Switch>
					<Route path={`${path}/send`}>
						<Header as="h2">{t('banking.data.sendmoney')}</Header>
						<SendMoney onSend={onSend} />
					</Route>
					<Route path={`${path}/trustee`}>
						<Header as="h2">{agent === Agents.DTSUS ? t('banking.data.sendmoneybutton') : t('banking.data.instructTrustee')}</Header>
						<SendMoney onSend={onSend} />
					</Route>
					<Route path={`${path}/request`}>
						<Header as="h2">{t('banking.data.requestmoneybutton')}</Header>
						<RequestMoney onSend={onSend} />
					</Route>
					<Route path={`${path}/deposit`}>
						<Header as="h2">{t('banking.data.depositmoneybutton')}</Header>
						<Deposit onSend={onSend} />
					</Route>
					<Route path={`${path}/bulk/top-up`}>
						<Header as="h2">{t('banking.data.depositmoneybutton')}</Header>
						<BulkTopUp />
					</Route>
					<Route path={`${path}/exchange`}>
						<Header as="h2">{t('banking.data.exchangebutton')}</Header>
						<Exchange />
					</Route>
					<Route path={`${path}/addmoneycard/success`}>
						<Header as="h2">{t('sendmoneyTranslation.data.card')}</Header>
						<CardTransferSuccess />
					</Route>
					<Route path={`${path}/addmoneycard/error`}>
						<Header as="h2">{t('sendmoneyTranslation.data.card')}</Header>
						<CardTransferError />
					</Route>
					<Route path={`${path}/addmoneycard/cancel`}>
						<Header as="h2">{t('sendmoneyTranslation.data.card')}</Header>
						<CardTransferCancel />
					</Route>
					<Route component={SendMoney} />
				</Switch>
			</Container>
		</div>
	);
}

export default Transfers;
