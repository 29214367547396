import React, { useEffect, useState } from 'react';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import { Formik } from 'formik';
import { Button, Divider, Grid, Segment } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import { addContact, editContact } from '@/features/contacts/slice';
import { Contact } from '@/features/contacts/types';
import { showErrorNotification } from '@/features/swal/slice';
import { useHistory } from 'react-router-dom';
import '@/components/Dashboard/MyContacts.css';
import FormikDropdown from '@/components/Shared/FormikDropdown';

interface Props {
	fullWidth?: boolean,
	from?: string,
	contact?: Contact,
	user?: any,
	onSave?: () => void
}

const paymentOption = [
	{ key: 'swift', value: 'swift', text: 'SWIFT' },
];

const CreateContact = ({ from, contact, onSave, fullWidth, user }: Props): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const history = useHistory();
	const [countryOptions, setCountryOptions] = useState<any>();
	const [countries, setCountries] = useState<any>({});
	const [defaultValue] = useState(contact && contact.country || null);

	useEffect(() => {
		const list = getNames('en');
		const countries = {};
		Object.keys(list).forEach(k => countries[alpha2ToAlpha3(k)] = list[k]);
		const countryOptions = Object.keys(countries)
			.map($code => ({
				key: $code,
				value: $code,
				text: countries[$code],
			}));
		setCountryOptions(countryOptions);
		setCountries(countries);
	}, [user]);

	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required')),
		walletId: Yup.string().required(t('form.validator.required')),
		account: Yup.string().required(t('form.validator.required')),
		bank: Yup.string().required(t('form.validator.required')),
		bic: Yup.string().required(t('form.validator.required')),
		identification: Yup.string().optional()
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { name, identification, walletId, bic, bank, country, account, address } = formData;
			if (contact) {
				await dispatch(editContact(contact.beneficiaryAccountId, { name, identification, walletId, bic, bank, country: country, paymentType: 'SWIFT', account, address }));
				onSave();
			}
			else {
				await dispatch(addContact({ name, identification, walletId, bic, bank, country: country, paymentType: 'SWIFT', account, address }));
				onSave ? onSave() : history.push(from);
			}
		}
		catch (error) { showErrorNotification(error); }
		finally { setSubmitting(false); }
	};

	return (
		<div id='createContact'>
			<Grid stackable columns={1} container>
				<Grid.Column className='createContactCLMN' widescreen={(contact || fullWidth) ? 16 : 9}>
					<Segment padded className='createContactSGMNT'>
						<Formik
							initialValues={{
								country: contact && contact.country ? countries[contact.country] : '',
								name: contact && contact.name || (user ? user.name : ''),
								walletId: contact && contact.walletId || (user ? user.walletId : ''),
								account: contact && contact.account || '',
								bank: contact && contact.bank || '',
								bic: contact && contact.bic || '',
								identification: contact && contact.identification || '',
								address: contact && contact.address || '',
							}}
							validationSchema={validationSchema}
							onSubmit={submit}
						>{({ errors, isSubmitting, dirty }) => (<Form
								size="large">
								<Input
									label={t('contacts.create.name')}
									fluid
									name="name"
									placeholder='Input Text'
								/>
								<Input
									label={t('contacts.create.id')}
									fluid
									name="walletId"
								/>
								<Input
									label={t('contacts.create.iban')}
									fluid
									name="account"
								/>
								<Select
									icon='chevron down'
									name='paymentoption'
									label={t('contacts.create.payment')}
									options={paymentOption}
									defaultValue={'swift'}
								/>
								<Input
									label={t('contacts.create.bank')}
									fluid
									name="bank"
								/>
								<Input
									label={t('contacts.create.bankAddress')}
									name='bankAddress'
								/>
								<FormikDropdown label={t('contacts.create.country')} name='country' options={countryOptions} fluid={true} defaultValue={defaultValue} labelId={'my-contacts-country-label'}/>
								<Divider hidden/>
								<Input
									label={t('contacts.create.address')}
									fluid
									name="address"
								/>
								<Input
									label={t('contacts.create.bicSwift')}
									fluid
									name="bic"
								/>
								<Input
									label={t('contacts.create.contact')}
									fluid
									name="identification"
								/>
								<Divider section />
								<SubmitButton
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									primary fluid type="submit">
									{t('contacts.create.save')}
								</SubmitButton>
								<Divider section />
								<Button secondary onClick={onSave}>{t('contacts.create.cancel')}</Button>
							</Form>)}
						</Formik>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};
export default CreateContact;
