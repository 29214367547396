import React from 'react';
import { Container, Message, Icon, Divider } from 'semantic-ui-react';
import Links from './Parts/Links';
import { useTranslation } from 'react-i18next';

const Bussines = (): React.ReactElement => {
    const { t } = useTranslation('translations');
    return (
        <Container>
            <Message info>
                <Message.Header><Icon name='info circle'/>{ t('registration.businessHeader') }</Message.Header>
                <Message.Content>{ t('registration.businessText') }</Message.Content>
            </Message>
            <Divider hidden/>
            <Links/>
        </Container>
    );
};

export default Bussines;
