import React, { useCallback, useEffect, useState } from 'react';
import { Container, Divider, Grid, Header, Message, Segment, Transition } from 'semantic-ui-react';
import Activity from '@icons/Notifications/Activity';
import { getPartnerList, transferVerification } from '@features/identity/slice';
import { Formik } from 'formik';
import isEmpty from 'is-empty';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Partner, TransferRequest } from '@features/identity/types';
import './VerificationTransfer.css';
import { useHistory } from 'react-router-dom';
import { showErrorNotification } from '@features/swal/slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

const VerificationTransfer = (): React.ReactElement => {

	const [credentialsError, setCredentialsError] = useState<boolean>(false);
	const { t } = useTranslation('translations');
	const [partners, setPartners] = useState<Partner[]>([]);
	
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const initialValues = {
		partner: '',
		email: user.email,
		password: ''
	};
	const validationSchema = Yup.object({
		partner: Yup.string().required(t('form.validator.required')),
		email: Yup.string().email(t('form.validator.email')).required(t('form.validator.required')),
		password: Yup.string().required(t('form.validator.required'))
	});
	const history = useHistory();

	const getPartners = useCallback(() => {
		const get = async () => {
			const partnerList = await getPartnerList();
			setPartners(partnerList);
		};
		get();
	}, []);

	useEffect(() => {
		getPartners();
	}, [getPartners]);

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { email, password, partner } = formData;
			const payload: TransferRequest = {
				email: email,
				password: password,
				partner: partner
			};
			await transferVerification(payload);
			history.push('/wallet');
		}
		catch (e) {
			setSubmitting(false);
			const { data  } = e;
			if (data.errors.length > 0 && data.errors[0].error === 'email_or_password_invalid') {
				setCredentialsError(true);
			} else {
				await showErrorNotification(e);
			}
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Container >
			<Grid textAlign={'center'}>
				<Segment id='verification-transfer'>
					<Grid textAlign='center'>
						<Grid.Row><Activity/></Grid.Row>
						<Grid.Row><Header>{t('kyc.header.transfer')}</Header></Grid.Row>
						<Grid.Row><p>{t('kyc.label.transfer')}</p></Grid.Row>
					</Grid>
					<Divider hidden/>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={submit}
					>
						{({ errors, isSubmitting, dirty }) => (
							<Form>
								<Transition visible={credentialsError} animation='scale' duration={500}>
									<Message
										error
										content={t('errors.EMAIL_OR_PASSWORD_INCORRECT')} />
								</Transition>
								<Select
									fluid
									name="partner"
									placeholder='Select value'
									label='Partner Platform'
									options={partners.map(item => ({ value: item.code, key: item.url, text: `${item.url}` }))}
									errorPrompt
								/>
								<Input
									fluid
									name="email"
									icon="envelope"
									label={t('form.placeholder.email')}
									iconPosition="left"
									placeholder={t('form.placeholder.email')}
									errorPrompt
									disabled
								/>
								<Input
									fluid
									name="password"
									icon="lock"
									iconPosition="left"
									label={t('form.placeholder.password')}
									placeholder={t('form.placeholder.password')}
									type="password"
									errorPrompt
								/>
								<Divider section />
								<SubmitButton disabled={isSubmitting || !isEmpty(errors) || !dirty} primary fluid type="submit">{t('kyc.button.continue')}</SubmitButton>
							</Form>)}
					</Formik>
				</Segment>
			</Grid>
		</Container>
	);
};

export default VerificationTransfer;
