import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { setVault } from '@features/tabs/slice';
import HeaderTab from './HeaderTab';
import { Vault } from '@/features/earn/types';
import useOnScreen from '@/helpers/customHook/useOnScreen';

enum Direction {
	Right = 1,
	Left = -1,
	Center = 0
}

const tabWidth = 272;

interface HeaderProps {
	width: number;
	vaults: Array<Vault>,
}

const VaultsListHeader = ({ width, vaults }: HeaderProps): ReactElement => {
	const dispatch = useDispatch();
	const [areChevronsRequired, setAreChevronsRequired] = useState<boolean>(false); 
	const [scrollerWidth, setScrollerWidth] = useState<number>(0);
	const [currentScroll, setCurrentScroll] = useState<number>(0);
	const [tabs, setTabs] = useState<any>([]);
	const ref = useRef<HTMLDivElement>(null);

	const rightRef = useRef(null);
	const rightVisiable = useOnScreen(rightRef);

	const leftRef = useRef(null);
	const leftVisiable = useOnScreen(leftRef);

	const { currentVault } = useSelector(
		(state: RootState) => state.tabs
	);

	const getTabIndex = (element: any) => {
		const index = vaults.findIndex((a) => a.proc === element.proc);
		return index;
	};

	const slide = (direction: Direction) => {

		function getElementPosition(index: number) {
			if ((index * tabWidth) > currentScroll + width) {
				return Direction.Right;
			} else if (((index - 1) * tabWidth) < currentScroll) {
				return Direction.Left;
			}
			return Direction.Center;
		}
		const index = getTabIndex(currentVault);
		const buttonElemen = getElementPosition(vaults.length + 1);

		if (direction !== Direction.Center) {
			const scroll = direction * (tabWidth - (index === 0 ? 100 : 0));
			setCurrentScroll(currentScroll + scroll);
			ref.current.scrollBy({
				left: scroll,
				behavior: 'smooth'
			});
		}
		if ((index + direction) < vaults.length - 1 && (index + direction) >= 0) {
			dispatch(setVault(vaults[index + direction]));
		}
		if (index === vaults.length - 1 && (direction === Direction.Right && buttonElemen !== Direction.Center)) {
			const scroll = direction * (tabWidth);
			setCurrentScroll(currentScroll + scroll);
			ref.current.scrollBy({
				left: scroll,
				behavior: 'smooth'
			});
		}
	};

	useEffect(() => {
		setTabs(vaults);
		const index = getTabIndex(currentVault);
		// position scroll to selected tab
		if (index !== 0) {
			const scroll = ((index - 1) * tabWidth) - (172 * -1); //*-1 scroll value is negative 
			setCurrentScroll(scroll);
			ref.current.scrollBy({
				left: scroll,
				behavior: 'smooth'
			});
		}
	}, [vaults]); 

	useEffect(() => {
		setScrollerWidth(width + tabWidth);
	}, [width]);

	useEffect(() => {
		setAreChevronsRequired(width < tabs.length * tabWidth);
	}, [width, tabs]);

	useEffect(() => {
		function getTabIndex(element: any) {
			const index = vaults.findIndex((a) => a.proc === element.proc) + 1;
			return index;
		}

		function getElementPosition(index: number) {
			if ((index * tabWidth) > currentScroll + width) {
				return Direction.Right;
			} else if (((index - 1) * tabWidth) < currentScroll) {
				return Direction.Left;
			}
			return Direction.Center;
		}

		const position = getElementPosition(getTabIndex(currentVault));
		//comment out : after tab change this set current srcoll to wrong value
		if (position !== Direction.Center) {
			const scroll = tabWidth * position * 1.5;
			setCurrentScroll(currentScroll + scroll);
			ref.current.scrollBy({
				left: scroll,
				behavior: 'smooth'
			});
		}

	}, [currentVault, width, scrollerWidth, vaults]);


	return (
		<div id="scrollable-tabs">
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				{areChevronsRequired && !!currentScroll && leftVisiable===false && (<div className="tabs-arrow left-arrow"><Button secondary onClick={() => { slide(Direction.Left); }} circular icon='arrow left' /></div>)}
				<div ref={ref}
					className="tabs ui attached tabular menu"
					style={{ width: scrollerWidth }}>
					<div ref={leftRef} />
					{tabs.map(tab => {
						const vault = tab as Vault;
						return <HeaderTab vault={vault} key={vault.proc} />;
					})}
					<div ref={rightRef} />
				</div>
				{areChevronsRequired && rightVisiable===false && (<div className="tabs-arrow right-arrow"><Button secondary onClick={() => { slide(Direction.Right); }} circular icon='arrow right' /></div>)}
			</div>
		</div>
	);
};

export default VaultsListHeader;
