import React, { useState, useEffect } from 'react';
import { Segment, Divider, Header, Grid, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { runOperation } from '@features/operations/slice';
import { showErrorNotification } from '@features/swal/slice';
import { maxTwoDecimals } from '@/helpers/number';
import { useRouteMatch } from 'react-router-dom';
import CardTransferRedirect from './CardTransferRedirect';

interface CardTopUpResponse {
	userId?: number,
	properties: {
		process: string,
		activityId: string,
		workflowId: string,
	},
	value: {
		REDIRECT_URL: string
	}
}

interface CardTransferPayload {
	successUrl: string,
	cancelUrl: string,
	errorUrl: string,
	targetAccountId: number,
	amount: number,
	ccy: string
}


type SendProps = {
	account: any,
	subProcesses: any,
}

const CardTransfer = ({ account, subProcesses }: SendProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [process, setProcess] = useState<any>();
	const [redirectUrl, setRedirectUrl] = useState<string>('');
	const [confirm, setConfirm] = useState<boolean>(false);

	useEffect(() => {
		if (!subProcesses) return;
		setProcess(subProcesses[Object.keys(subProcesses).find(key => subProcesses[key]['type'] === 'DEPOSIT_FROM_CARD')]);
	}, [subProcesses]);

	const maxAmount = process?.config?.maxTransactionAmount ?? 0;
	const minAmount = process?.config?.minTransactionAmount ?? 0;

	const validationSchema = Yup.object({
		amount: Yup.number().required(t('form.validator.required'))
			.min(minAmount, t('form.validator.minNumber', { field: t('sendmoneyTranslation.data.transferamount'), amount: minAmount }))
			.max(maxAmount, t('form.validator.maxNumber', { field: t('sendmoneyTranslation.data.transferamount'), amount: maxAmount }))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
	});

	const initialValues = {
		to: account.name + ' (' + account.currency + ' ' + account.availableBalance + ')',
		amount: ''
	};

	const { path } = useRouteMatch();
	const pathStart = window.location.href.split(path)[0];

	const submit = async (formData, formikProps) => {
		const { amount } = formData;
		const { setSubmitting } = formikProps;
		setSubmitting(true);
		try {
			const payload: CardTransferPayload = {
				successUrl: `${pathStart}/wallet/transfers/addmoneycard/success`,
				errorUrl: `${pathStart}/wallet/transfers/addmoneycard/error`,
				cancelUrl: `${pathStart}/wallet/transfers/addmoneycard/cancel`,
				targetAccountId: account.accountId,
				amount: amount,
				ccy: account.currency
			};

			const result: CardTopUpResponse = await runOperation(process.proc, payload);
			setRedirectUrl(result.value.REDIRECT_URL);
		}
		catch (e) {
			showErrorNotification(e);
		}
		finally {
			setSubmitting(false);
		}

	};



	return (
		<div id="tranfer-by-card">

			<Grid>
				<Grid.Column width={16}>

					<Segment padded>
						<Header as="h3">{t('sendmoneyTranslation.data.card')}</Header>
						<Divider hidden />
						{redirectUrl ?
							<CardTransferRedirect url={redirectUrl} /> :
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submit}
							>
								{({ errors, isSubmitting, dirty, values, setFieldValue }) => (
									<Form>
										<Input
											fluid
											id="account"
											name="to"
											label={t('sendmoneyTranslation.data.to')}
											disabled
										/>
										<Input
											fluid
											name="amount"
											label={t('sendmoneyTranslation.data.transferamount')}
											placeholder={`0.00 ${account.currency}`}
											errorPrompt
											type="number"
											onWheel={(e) => e.target.blur()}
										/>
										<Checkbox
											checked={confirm}
											onChange={() => { setConfirm(!confirm); }}
											label={t('sendmoneyTranslation.data.cardConfirmTopUp')} />
										<Divider section />
										<SubmitButton disabled={isSubmitting || !isEmpty(errors) || !dirty || !confirm} primary fluid type="submit">{t('sendmoneyTranslation.activedata.button')}</SubmitButton>
									</Form>)}
							</Formik>}
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};
export default CardTransfer;
