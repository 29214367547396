import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Pagination, PaginationProps } from 'semantic-ui-react';
import './Pagination.css';

export interface PaginatorParams {
	activePage: number,
}

interface Props {
	totalPages: number,
	paginator: PaginatorParams,
	customClassName: string,
	handlePaginationChange: (e, { activePage }: PaginationProps) => void
}
const CustomPagination = ({ totalPages, paginator, customClassName, handlePaginationChange }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<Pagination
			id='pagination'
			className={customClassName}
			totalPages={totalPages}
			boundaryRange="0"
			onPageChange={handlePaginationChange}
			activePage={paginator.activePage}
			nextItem={{
				disabled: paginator.activePage >= totalPages,
				icon: true,
				content: <span>{t('pagination.nextItem')}<Icon className="paginationIcon" name="angle right" /></span>
			}}
			prevItem={{
				disabled: paginator.activePage === 1,
				icon: true,
				content: <span><Icon className="paginationIcon" name="angle left" />{t('pagination.prevItem')}</span>
			}}
			firstItem={null}
			lastItem={null} />
	);
};

export default CustomPagination;
