import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Segment, Button, Header, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { SucessStatus } from '../../../services/icons';

const PaymentSubmitted = () => {
	const { t } = useTranslation('translations');

	return (
		<Segment padded="very" className="submitSegment">
			<Grid>
				<Grid.Row centered>
					<Container text textAlign="center">
						<SucessStatus id="successCheckIcon" />
						<Header as="h2">{t('createaccounttab.created')}</Header>
						<p></p>
						<Button as={Link} to="/wallet" basic>{t('paymentsubmitted.button')}</Button>
					</Container>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default PaymentSubmitted;
