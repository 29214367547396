import * as React from "react";

function ConfirmPhoneIcon(props) {
    return (
        <svg
            width={34}
            height={42}
            //viewBox="0 0 34 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M17 41C17 41 33 33 33 21V7L17 1L1 7V21C1 33 17 41 17 41Z"
                fill="#EB8A34"
                stroke="#EB8A34"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}

export default ConfirmPhoneIcon;
