import React, { useEffect, useState } from 'react';
import {
	Modal,
	Header,
	Button,
	Divider,
	List
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/rootReducer';
import { AvailablePlan } from '@/features/user/types';
import { useSelector } from 'react-redux';
import { chooseUserLevel } from '@/features/user/userSlice';
import { getErrorWithParams, showErrorNotification } from '@/features/swal/slice';
import { Agents } from '@/helpers/globalTypes';


interface Props {
	open: boolean;
	onClose: (accountFrom?: number, plan?: AvailablePlan) => void;
	plan: AvailablePlan;
}

const ChoosePlan = ({ open, onClose, plan }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [accounts, setAccounts] = useState<Array<any>>([]);
	const { list } = useSelector(
		(state: RootState) => state.accounts
	);
	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const initialValues = {
		accountFrom: '',
	};

	const validationSchema = Yup.object({
		accountFrom: Yup.string().required(t('form.validator.required'))
	});

	useEffect(() => {
		if (!plan || !list) return;
		if(status.source === Agents.DTS) {
			setAccounts(list.filter(account => plan.ccy === account.currency && account.providerType !== 'POOLING'));
		} else {
			setAccounts(list.filter(account => plan.ccy === account.currency));
		}
	}, [plan, list]);

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;
		setSubmitting(false);
		const { accountFrom } = formData;

		try {
			await chooseUserLevel(accountFrom, plan.userLevelId, null);
		} catch (e) {
			const err = getErrorWithParams(e);
			if (err && err.error === 'insufficient' && err.error_param === 'funds') {
				setFieldError('accountFrom', t('sendmoneyTranslation.data.insufficient'));
			} else {
				showErrorNotification(e);
			}
			return;
		}

		onClose(accountFrom, plan);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Header textAlign='center' content={t('plans.upgradePlan')} />
			<Divider hidden />
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						className='choosePlan'
						size="large">
						<Select
							fluid
							name="accountFrom"
							label={t('plans.accountFrom')}
							options={accounts.map(item => ({ value: item.accountId, key: item.accountId, text: `${item.name} (${item.currency} ${item.availableBalance})` }))}
							errorPrompt
						/>
						{/* <Input
							fluid
							name="discountCode"
							label="Discount code"
							errorPrompt
						/> */}
						{plan && <List relaxed>
							<List.Item>
								<List.Header>{t('plans.plan')}</List.Header>
								{plan.name}
							</List.Item>
							<List.Item>
								<List.Header>{t('plans.price')}</List.Header>
								{plan.currencySymbol}{plan.price}
							</List.Item>
						</List>}
						<Divider />
						<div className='editBTNS'>
							<SubmitButton
								disabled={isSubmitting || !isEmpty(errors) || !dirty}
								primary size="large" type="submit">
								{t('plans.getPlan')}
							</SubmitButton>
							<Button className='discardBTN' content={t('plans.discard')} onClick={() => onClose()} />
						</div>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default ChoosePlan;


