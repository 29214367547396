import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import request from '../../services/request';
import { Maintenance, MaintenanceStatus, MaintenanceStore } from './maintenanceType';


const initialState: MaintenanceStore = {
	loading: true,
	list: [],
	isFullyDown: false,
	isPartiallyDown: false,
	isPlanned: false,
	error: null
};

const slice = createSlice({
	name: 'maintenance',
	initialState,
	reducers: {
		setMaintenanceList(state, action: PayloadAction<Maintenance[]>) {
			state.list = action.payload;
			state.isFullyDown = action.payload.some(p => p?.integration === 'ALL' && p.status === MaintenanceStatus.ACTIVE);
			state.isPartiallyDown = action.payload.some(p => p.status === MaintenanceStatus.ACTIVE);
			state.isPlanned = action.payload.some(p => p.status === MaintenanceStatus.UPCOMMING);
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
	}
});

export const { setMaintenanceList, setLoading, setError } = slice.actions;

export const getMaintenance = (status?: MaintenanceStatus): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/operations/system/maintenance', { params: { status: status } });
			if (response?.data) {
				dispatch(setMaintenanceList(response.data));
			}
		} catch (e) {
			dispatch(setError('Failed to get maintenance status'));
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export default slice.reducer;
