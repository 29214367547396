export interface Vault {
	name: string,
	description: string,
	proc: string,
	fees: any[],
	processProperties: {
		ccy: string,
		currencySymbol: string
	},
	config: {
		yearlyInterestRate: string,
		depositPeriodMonths: string,
		minimalTransferableAmount: string,
		minDepositAmount: number,
	},
	deposits: Array<any>,
	totalBalance: number,
}

export interface VaultsStore {
    loading: boolean,
    list: Array<Vault>,
    error: string
}

export enum DepositStatus {
    TERMINATED = 'TERMINATED',
	ACTIVE = 'ACTIVE'
}

