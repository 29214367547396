import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './PrivacyPolicy.css';

function PrivacyPolicy(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered>
					<h1>Privacy policy</h1>
				</Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<h1>Who are we? </h1>
				<p>
					Cospay Limited (<strong>COSPAY</strong> and
					<strong> we</strong>) is a fast-growing technology company
					and e-money payments institution with office in the UK.
					Cospay is authorised and regulated by the Financial Conduct
					Authority in the UK, as an electronic money institution,
					under reference number 900088. We provide own label and
					white label e-money financial solutions, including
					e-wallets, prepaid cards, and current accounts. Cospay
					provides complete end to end solutions for clients by
					designing, developing, implementing, and managing these
					programmes.
				</p>
				<h1>What, Why? </h1>
				<p>
					It is important that you know exactly what we do with the
					personal information you and others make available to us,
					why we collect it and what it means for you. This document
					outlines the COSPAY approach to Data Privacy; specifically
					how COSPAY collects and processes your personal data you may
					provide through this website when you purchase a service or
					take part in a competition / survey.
				</p>
				<p>
					We were delighted to welcome the advent of General Data
					Protection Regulation and the Data Protection Act 2018, as
					it provided COSPAY with a further an opportunity to reassure
					our customers of the importance we place on keeping your
					personal data secure, and of the strict guidelines we apply
					to its use.
				</p>
				<p>
					COSPAY is the data controller and responsible for your
					personal data. For any requests to exercise your legal
					rights, please contact the DPO using the details set out at
					the end of this Policy.
				</p>
				<p>
					We keep our privacy policy under regular review. This
					version was last updated on 24.03.2022.
				</p>
				<p>
					It is important that the personal data we hold about you is
					accurate and current. Please keep us informed if your
					personal data changes during your relationship with us.
				</p>
				<h1>Third-Party Links</h1>
				<p>
					This website may include links to third-party websites,
					plug-ins and applications. Clicking on those links or
					enabling those connections may allow third parties to
					collect or share data about you. We do not control these
					third-party websites and are not responsible for their
					privacy statements. When you leave our website, we encourage
					you to read the privacy policy of every website you visit.
				</p>
				<h1>The Personal Data We Collect From You</h1>
				<p>
					Personal data, or personal information, means any
					information about an individual from which that person can
					be identified. It does not include data where the identity
					has been removed (anonymous data).
				</p>
				<p>
					We may collect, use, store and transfer different kinds of
					personal data about you which we have set out below:
				</p>
				<ul>
					<li>First Name and Surname (with title);</li>
					<li>Date of birth;</li>
					<li>Email;</li>
					<li>Telephone number (including home phone and mobile);</li>
					<li>Proof of address documents;</li>
					<li>Residential address;</li>
					<li>ID Documents;</li>
					<li>
						Other personal information such as telephone recordings;
					</li>
					<li>Security questions and your answers;</li>
					<li>
						Profile data (including User ID, user name, password,
						interests, preferences, feedback and survey responses);
					</li>
					<li>Address;</li>
					<li>Bank Account details;</li>
					<li>Card details;</li>
					<li>
						Technical data (including internet protocol (IP)
						address, your login data, browser type and version, time
						zone setting and location, browser plug-in types and
						versions, operating system and platform, and other
						technology on the devices you use to access this
						website);
					</li>
					<li>
						Usage data (information about how you use our website
						and services);
					</li>
					<li>
						Marketing and Communications data (includes your
						preferences in receiving marketing from us )
					</li>
					<li>Transactional information; and</li>
				</ul>
				<p>
					We also collect, use and share&nbsp; 
					<strong>Aggregated Data</strong> such as statistical or
					demographic data for any purpose. Aggregated Data could be
					derived from your personal data but is not considered
					personal data in law as this data will not directly or
					indirectly reveal your identity. For example, we may
					aggregate your Usage data to calculate the percentage of
					users accessing a specific website feature. However, if we
					combine or connect Aggregated Data with your personal data
					so that it can directly or indirectly identify you, we treat
					the combined data as personal data which will be used in
					accordance with this privacy policy.
				</p>
				<p>
					We do not collect any Special Categories of Personal Data
					about you (this includes details about your race or
					ethnicity, religious or philosophical beliefs, sex life,
					sexual orientation, political opinions, trade union
					membership, information about your health, and genetic and
					biometric data). We have access to and use information about
					criminal convictions and offences as part of our due
					diligence procedures.
				</p>
				<p>
					We will only use your personal data when the law allows us
					to. Most commonly, we will use your personal data in the
					following circumstances:
				</p>
				<ul>
					<li>
						Where we need to perform the contract we are about to
						enter into or have entered into with you.
					</li>
					<li>
						Where it is necessary for our legitimate interests (or
						those of a third party) and your interests and
						fundamental rights do not override those interests.
					</li>
					<li>Where we need to comply with a legal obligation.</li>
				</ul>
				<p>Some examples of this are as follows:</p>
				<table>
					<tbody>
						<tr>
							<td>
								<p>
									<strong>Purpose/Activity</strong>
								</p>
							</td>
							<td>
								<p>
									<strong>Type of data</strong>
								</p>
							</td>
							<td>
								<p>
									<strong>
										Lawful basis for processing including
										basis of legitimate interest
									</strong>
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>To register you as a new customer</p>
							</td>
							<td>
								<p>(a) Identity</p>
								<p>(b) Contact</p>
							</td>
							<td>
								<p>Performance of a contract with you</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>
									To process and deliver your order including:
								</p>
								<ol>
									<li>Manage payments, fees and charges</li>
								</ol>
								<ol>
									<li>
										Collect and recover money owed to us
									</li>
								</ol>
							</td>
							<td>
								<p>(a) Identity</p>
								<p>(b) Contact</p>
								<p>(c) Financial</p>
								<p>(d) Transaction</p>
								<p>(e) Marketing and Communications</p>
							</td>
							<td>
								<p>(a) Performance of a contract with you</p>
								<p>
									(b) Necessary for our legitimate interests
									(to recover debts due to us)
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>
									To manage our relationship with you which
									will include:
								</p>
								<ol>
									<li>
										Notifying you about changes to our terms
										or privacy policy
									</li>
								</ol>
								<ol>
									<li>
										Asking you to leave a review or take a
										survey
									</li>
								</ol>
							</td>
							<td>
								<p>(a) Identity</p>
								<p>(b) Contact</p>
								<p>(c) Profile</p>
								<p>(d) Marketing and Communications</p>
							</td>
							<td>
								<p>(a) Performance of a contract with you</p>
								<p>
									(b) Necessary to comply with a legal
									obligation
								</p>
								<p>
									(c) Necessary for our legitimate interests
									(to keep our records updated and to study
									how customers use our products/services)
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>
									To enable you to partake in a prize draw,
									competition or complete a survey
								</p>
							</td>
							<td>
								<p>(a) Identity</p>
								<p>(b) Contact</p>
								<p>(c) Profile</p>
								<p>(d) Usage</p>
								<p>(e) Marketing and Communications</p>
							</td>
							<td>
								<p>(a) Performance of a contract with you</p>
								<p>
									(b) Necessary for our legitimate interests
									(to study how customers use our
									products/services, to develop them and grow
									our business)
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>
									To administer and protect our business and
									this website (including troubleshooting,
									data analysis, testing, system maintenance,
									support, reporting and hosting of data)
								</p>
							</td>
							<td>
								<p>(a) Identity</p>
								<p>(b) Contact</p>
								<p>(c) Technical</p>
							</td>
							<td>
								<p>
									(a) Necessary for our legitimate interests
									(for running our business, provision of
									administration and IT services, network
									security, to prevent fraud and in the
									context of a business reorganisation or
									group restructuring exercise)
								</p>
								<p>
									(b) Necessary to comply with a legal
									obligation
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>
									To deliver relevant website content and
									advertisements to you and measure or
									understand the effectiveness of the
									advertising we serve to you
								</p>
							</td>
							<td>
								<p>(a) Identity</p>
								<p>(b) Contact</p>
								<p>(c) Profile</p>
								<p>(d) Usage</p>
								<p>(e) Marketing and Communications</p>
								<p>(f) Technical</p>
							</td>
							<td>
								<p>
									Necessary for our legitimate interests (to
									study how customers use our
									products/services, to develop them, to grow
									our business and to inform our marketing
									strategy)
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>
									To use data analytics to improve our
									website, services, marketing, customer
									relationships and experiences
								</p>
							</td>
							<td>
								<p>(a) Technical</p>
								<p>(b) Usage</p>
							</td>
							<td>
								<p>
									Necessary for our legitimate interests (to
									define types of customers for our products
									and services, to keep our website updated
									and relevant, to develop our business and to
									inform our marketing strategy)
								</p>
							</td>
						</tr>
						<tr>
							<td>
								<p>
									To make suggestions and recommendations to
									you about services that may be of interest
									to you
								</p>
							</td>
							<td>
								<p>(a) Identity</p>
								<p>(b) Contact</p>
								<p>(c) Technical</p>
								<p>(d) Usage</p>
								<p>(e) Profile</p>
								<p>(f) Marketing and Communications</p>
							</td>
							<td>
								<p>
									Necessary for our legitimate interests (to
									develop our products/services and grow our
									business)
								</p>
							</td>
						</tr>
					</tbody>
				</table>
				<p>
					Generally, we do not rely on consent as a legal basis for
					processing your personal data although we will get your
					consent before sending third party direct marketing
					communications to you via email or text message. You have
					the right to withdraw consent to marketing at any time by
					contacting us.
				</p>
				<h1>Marketing</h1>
				<p>
					We strive to provide you with choices regarding certain
					personal data uses, particularly around marketing and
					advertising. Please contact our DPO if you have any
					questions regarding COSPAY’s marketing.
				</p>
				<h1>Promotional Offers From Us</h1>
				<p>
					We may use your personal data to form a view on what we
					think you may want or need, or what may be of interest to
					you. This is how we decide which products, services and
					offers may be relevant for you (we call this marketing).
				</p>
				<p>
					You will receive marketing communications from us if you
					have requested information from us or purchased services
					from us and you have not opted out of receiving that
					marketing.
				</p>

				<h1>Third-Party Marketing</h1>
				<p>
					We will get your express opt-in consent before we share your
					personal data with any third party for marketing purposes.
				</p>
				<h1>Cookies</h1>
				<p>
					We do not use cookies to collect or store information about
					you.
				</p>
				<h1>Withdrawal of Consent Conditions </h1>
				<p>
					You may withdraw consent from direct marketing at any time
					by contacting our Data Protection Officer. As a payment
					service provider, COSPAY are obliged to retain data
					concerning financial transactions for 6 years in accordance
					with national law for the purpose of preventing, detecting
					and investigating, possible money laundering or terrorist
					financing.
				</p>
				<h1>Change Of Purpose</h1>
				<p>
					We will only use your personal data for the purposes for
					which we collected it, unless we reasonably consider that we
					need to use it for another reason and that reason is
					compatible with the original purpose. If you wish to get an
					explanation as to how the processing for the new purpose is
					compatible with the original purpose, please contact us.
				</p>
				<p>
					If we need to use your personal data for an unrelated
					purpose, we will notify you and we will explain the legal
					basis which allows us to do so.
				</p>
				<p>
					Please note that we may process your personal data without
					your knowledge or consent, in compliance with the above
					rules, where this is required or permitted by law.
				</p>
				<h1>Disclosures Of Your Personal Data</h1>
				<p>
					We may share your personal data with the parties set out
					below for the purposes set out in the table above.
				</p>
				<p>External Third Parties:</p>
				<ul>
					<li>
						Service providers acting as data processors (such as
						Decta Limited) based in the United Kingdom or Gibraltar,
						Austria, Latvia, Lithuania, Malta, Luxembourg who
						provide IT, system administration, identification and
						authentication services.
					</li>
					<li>
						HM Revenue &amp; Customs, Government Digital Service
						(UK), regulators and supervisory bodies, court
						authorities, electoral registers and other authorities
						acting as processors or joint controllers based in the
						United Kingdom and the EU countries, to comply with Law
						requirements;
					</li>
					<li>
						KYC-AML and Due Diligence partners (such as Sum and
						Substance Limited), who act as our data processors and
						are based in the United Kingdom, Gibraltar, Austria,
						Latvia, Malta, to ensure the processing of applications
						and verification of applicants, and fraud prevention.
					</li>
					<li>
						Our banking partners and financial institutions (such as
						Banking Circle), who act as controllers and are based in
						the United Kingdom, Germany, Luxembourg, Lithuania,
						Latvia, to provide you with necessary financial
						services.
					</li>
					<li>
						To governmental and fraud prevention agencies for
						assistance in conducting or co-operating in
						investigations of fraud, crime prevention or other
						illegal actions where we believe it is reasonable and
						appropriate to do so;
					</li>
					<li>
						Third parties to whom we may choose to sell, transfer or
						merge parts of our business or our assets.
						Alternatively, we may seek to acquire other businesses
						or merge with them. If a change happens to our business,
						then the new owners may use your personal data in the
						same way as set out in this privacy policy.
					</li>
				</ul>
				<p>Internal Third Parties:</p>
				<ul>
					<li>
						such as other entities within the Cospay Limited group
						acting as joint controllers or processors based in the
						UK and the EU providing communications, compliance, IT
						and infrastructure administration and reporting
						services.
					</li>
				</ul>
				<p>
					We require all third parties to respect the security of your
					personal data and to treat it in accordance with the law. We
					do not allow our third-party service providers to use your
					personal data for their own purposes and only permit them to
					process your personal data for specified purposes and in
					accordance with our instructions.
				</p>
				<p>
					We do not have a specific list of all third parties with
					which we share your data, as this will depend on your
					specific use of our Services. However, if you would like
					more information about who we shared your data with or to be
					provided with a list that applies to you, we can provide the
					information upon your request sent to legal@cospay.com.
				</p>
				<h1>
					International Data Transfers &amp; Third-Party Disclosures
				</h1>
				<p>
					In limited situations where COSPAY stores or transfers
					personal information outside the European Economic Area
					(including the UK), we ensure that robust procedures and
					safeguarding measures apply to secure, encrypt and maintain
					the integrity of your personal data.
				</p>
				<p>
					COSPAY complete regular reviews of the countries with
					sufficient adequacy decisions grant by the EU Commission,
					provisions for binding corporate rules, standard data
					protection clauses or approved codes of conduct.
				</p>
				<p>
					COSPAY will further perform due diligence checks with all
					recipients of personal data to assess and verify that they
					have appropriate safeguards in place to protect the personal
					data prior to transfer.
				</p>
				<p>
					Whenever we transfer your personal data out of the UK, we
					ensure a similar degree of protection is afforded to it by
					ensuring at least one of the following safeguards is
					implemented:
				</p>
				<ul>
					<li>
						We will only transfer your personal data to countries
						that have been deemed to provide an adequate level of
						protection for personal data.
					</li>
					<li>
						Where we use certain service providers, we may use
						specific contracts approved for use in the UK which give
						personal data the same protection it has in the UK. For
						further details
					</li>
				</ul>
				<h1>Data Security</h1>
				<p>
					We have put in place appropriate security measures to
					prevent your personal data from being accidentally lost,
					used or accessed in an unauthorised way, altered or
					disclosed. In addition, we limit access to your personal
					data to those employees, agents, contractors and other third
					parties who have a business need to know. They will only
					process your personal data on our instructions and they are
					subject to a duty of confidentiality.
				</p>
				<p>
					We have put in place procedures to deal with any suspected
					personal data breach and will notify you and any applicable
					regulator of a breach where we are legally required to do
					so.
				</p>
				<h1>Retention Period </h1>
				<p>
					COSPAY will process personal data for the duration of the
					contract for services and will store the personal data for
					six (6) years after that date of termination of the
					contract. By law we have to keep basic information about our
					customers (including contact, identity, financial and
					transaction data) for six (6) years after they cease being
					customers for tax purposes.
				</p>
				<p>
					In some circumstances you can ask us to delete your data:
					see your legal rights below for further information.
				</p>
				<p>
					In some circumstances we will anonymise your personal data
					(so that it can no longer be associated with you) for
					research or statistical purposes, in which case we may use
					this information indefinitely without further notice to you.
				</p>
				<h1>Your Rights as a Data Subject </h1>
				<p>
					At any point while we are in possession of or processing
					your personal data, you, the data subject, have the
					following rights:
				</p>
				<ul>
					<li>
						Right of access – you have the right to request a copy
						of the information that we hold about you;
					</li>
					<li>
						Right of rectification – you have a right to correct
						data that we hold about you that is inaccurate or
						incomplete;
					</li>
					<li>
						Right to be forgotten – in certain circumstances you can
						ask for the data we hold about you to be erased from our
						records. Your data relating to financial transactions,
						accounts or cards cannot be deleted due to national law
						associated with the prevention of fraud, money
						laundering, counter terrorist financing or misuse of
						services for crime;
					</li>
					<li>
						Right to restriction of processing – where certain
						conditions apply to have a right to restrict the
						processing;
					</li>
					<li>
						Right of portability – you have the right to have the
						data we hold about you transferred to another
						organisation;
					</li>
					<li>
						Right to object – you have the right to object to
						certain types of processing such as direct marketing;
					</li>
					<li>
						Right to object to automated processing, including
						profiling – you also have the right to be subject to the
						legal effects of automated processing or profiling; and
						right to judicial review, in the event that COSPAY
						refuses your request under rights of access, we will
						provide you with a reason as to why. You have the right
						to complain as outlined below. All of the above requests
						will be forwarded on should there be a third party
						involved in the processing of your personal data. You
						may request a copy of our Subject Access Request Form at
						legal@cospay.com; and
					</li>
					<li>
						Right to withdraw your consent - where we are relying on
						consent to process your personal data. However, this
						will not affect the lawfulness of any processing carried
						out before you withdraw your consent. If you withdraw
						your consent, we may not be able to provide certain
						products or services to you. We will advise you if this
						is the case at the time you withdraw your consent.
					</li>
				</ul>
				<h1>No Fee Usually Required</h1>
				<p>
					You will not have to pay a fee to access your personal data
					(or to exercise any of the other rights). However, we may
					charge a reasonable fee if your request is clearly
					unfounded, repetitive or excessive. Alternatively, we could
					refuse to comply with your request in these circumstances.
				</p>
				<h1>What We May Need From You</h1>
				<p>
					We may need to request specific information from you to help
					us confirm your identity and ensure your right to access
					your personal data (or to exercise any of your other
					rights). This is a security measure to ensure that personal
					data is not disclosed to any person who has no right to
					receive it. We may also contact you to ask you for further
					information in relation to your request to speed up our
					response.
				</p>
				<h1>Time Limit To Respond</h1>
				<p>
					We try to respond to all legitimate requests within one
					month. Occasionally it could take us longer than a month if
					your request is particularly complex or you have made a
					number of requests. In this case, we will notify you and
					keep you updated.
				</p>
				<h1>Complaints </h1>
				<p>
					In the event that you wish to make a complaint about how
					your personal data is being processed by COSPAY (or third
					parties as above), or how your complaint has been handled,
					you have the right to lodge a complaint directly with the
					supervisory authority and COSPAY’s Data Protection Officer
					by email to&nbsp; 
					<a href="mailto:legal@cospay.com">legal@cospay.com</a>.
				</p>
			</Segment>
		</Container>
	);
}

export default PrivacyPolicy;
