import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import { HomeIcon, SecurityIcon, SupportIcon, UserIcon, EmployeeIcon } from '@services/icons';
import NavbarLogo from './NavbarLogo';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { logout } from '@features/user/userSlice';
import { UserType } from '@features/user/types';
import { showErrorNotification } from '@features/swal/slice';
import MobileNavBar from './MobileNavBar';
import SwitchMenuItem from './SwitchMenuItem';
import { Agents } from '@/helpers/globalTypes';


type NavbarProps = {
	menu: boolean
}

const Navbar = ({ menu }: NavbarProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const [requiredTier, setRequiredTier] = useState<number>(1);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);

	const { businessEnabled, allowMultipleBusinessRegistration } = useSelector(
		(state: RootState) => state.status
	);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const signout = async (key?: UserType) => {
		try {
			dispatch(logout());
			return history.push('/auth/login' + (key ? `?mode=${key}` : ''));
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	const { user, status } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (!user) return;
		setRequiredTier(user.type === UserType.INDIVIDUAL ? 1 : 2);
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		setKycTierVerified((kycTier >= requiredTier) || isKycTierRevoked);
	}, [user, requiredTier]);

	return (
		<Menu id="navbar" borderless stackable>
			<div className='mobileNavbarLogo'>
				<Menu.Item header>
					<Icon as={NavLink} to="/" className="logo">
						<NavbarLogo />
					</Icon>
					<Icon as={NavLink} to="/" className='mobileLogo' >
					</Icon>
					<div id='mobileNavbar'>
						<MobileNavBar menu={menu} />
					</div>
				</Menu.Item>
			</div>
			{menu && (<Menu.Menu position="right">
				{((status && !status.twoFARequired) || (user && user.twoFactorAuthEnabled)) && ((status && !status.confirmPhoneRequired) || (user && user.type !== UserType.INDIVIDUAL || user && user.confirmedMobileNumber !== null)) ? <Menu.Item className="navbaritem" name="home" as={NavLink} to="/wallet/home">
					<HomeIcon width="24px" height="24px" />
					{t('navbar.home')}
				</Menu.Item> : ''}
				<Menu.Item className="navbaritem" as={NavLink} to="/wallet/security" name="register">
					<SecurityIcon width="24px" height="24px" />
					{t('navbar.security')}
				</Menu.Item>
				<Menu.Item className="navbaritem" as={NavLink} to="/wallet/support" name="register">
					<SupportIcon width="24px" height="24px" />
					{t('navbar.support')}
				</Menu.Item>
				{businessEnabled && user && kycTierVerified && (user.type === UserType.BUSINESS || user.onBehalfOf !== null) ? <Menu.Item className="navbaritem" as={NavLink} to="/wallet/employees" name="register">
					<EmployeeIcon width="24px" height="24px" />
					{t('navbar.employees')}
				</Menu.Item> : ''}
				{user && <SwitchMenuItem user={user} />}
				<Dropdown className="navbarDropdown" item trigger={(
					<Menu.Item className="navbaritem" >
						<UserIcon /> {t('navbar.account.title')}
					</Menu.Item>)}>
					<Dropdown.Menu>
						<Dropdown.Item as={NavLink} to="/wallet/account" >	{t('navbar.account.details')}</Dropdown.Item>
						<Dropdown.Item as={NavLink} to="/wallet/contacts" >	{t('navbar.account.contacts')}</Dropdown.Item>
						<Dropdown.Item onClick={() => signout()}>{t('navbar.account.signout')}</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Menu>)}

		</Menu>
	);
};

export default Navbar;
