import React, { useEffect, useState } from 'react';
import { Button, Header, Icon, Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TokenAction, TokenPendingTransaction, TokenTransactionStatus } from '@/features/tokens/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getPendingTransactions } from '@/features/tokens/slice';
import NumberFormat from 'react-number-format';
import TransactionStatus from './TransactionStatus';
import TokenConfirmation from './TokenConfirmation';
import { formatDateWithPattern } from '@/helpers/date'; 

const tokenDecimalPoint=5;

const PendingTransactions = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const { pendingTransactionList } = useSelector((state: RootState) => state.tokens);
	const [tokenModal, setTokenModal] = useState<boolean>(false);
	const [transaction, setTransaction] = useState<TokenPendingTransaction>();


	useEffect(() => {
		dispatch(getPendingTransactions());
	}, []);


	const handleConfirm = (transaction: TokenPendingTransaction) => {
		setTokenModal(true);
		setTransaction(transaction);
	};

	return (<>
		{transaction && <TokenConfirmation transaction={transaction} open={tokenModal} onClose={() => { setTokenModal(false); }} />}
		<Table basic className="token-table-headers">
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell>
						{t('tokens.history.header.activity')}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t('tokens.history.header.asset')}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t('tokens.history.header.amount')}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t('tokens.history.header.price')}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t('tokens.history.header.total')}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t('tokens.history.header.date')}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t('tokens.history.header.status')}
					</Table.HeaderCell>
					<Table.HeaderCell>
						{t('tokens.history.header.actions')}
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			{pendingTransactionList && pendingTransactionList.length > 0 &&
				<Table.Body>
					{pendingTransactionList?.map((trx, idx) => {
						const price = trx.properties.direction === TokenAction.BUY ? (trx.fromAmount / trx.toAmount) : (trx.toAmount / trx.fromAmount); 
						return (
							<Table.Row key={`${trx.id}-${idx}`}>
								<Table.Cell>{t('tokens.tokenActions.' + trx.properties.direction)}</Table.Cell>
								<Table.Cell>{trx.properties.direction === TokenAction.BUY ? trx.toCcy : trx.fromCcy}</Table.Cell>
								<Table.Cell className={trx.properties.direction === TokenAction.BUY ? 'purchased' : 'sold'}>
									<NumberFormat displayType={'text'}
										decimalScale={tokenDecimalPoint}
										fixedDecimalScale={false}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										value={trx.properties.direction === TokenAction.BUY ? trx.toAmount : trx.fromAmount} />
								</Table.Cell>
								<Table.Cell>
									<NumberFormat displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true} value={price} prefix={trx.properties.direction === TokenAction.BUY ? ` ${trx.fromCcy} ` : ` ${trx.toCcy} `} />
								</Table.Cell>
								<Table.Cell>
									<NumberFormat displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true} value={trx.properties.direction === TokenAction.BUY ? ` ${trx.fromAmount}` : ` ${trx.toAmount}`} prefix={trx.properties.direction === TokenAction.BUY ? `${trx.fromCcy} ` : `${trx.toCcy} `} />
								</Table.Cell>
								<Table.Cell> {formatDateWithPattern(trx.createdDate, 'dd MMM yyyy')}</Table.Cell>
								<Table.Cell> <TransactionStatus status={trx.status} /></Table.Cell>
								<Table.Cell>
									<Button primary
										disabled={trx.status !== TokenTransactionStatus.OFFER}
										content={t('form.buttons.confirm')}
										onClick={() => handleConfirm(trx)}
									/></Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>}
			{!pendingTransactionList || !pendingTransactionList.length &&
				<Table.Body>
					<Table.Row>
						<Table.Cell colSpan={8}>
							<Segment placeholder basic>
								<Header icon>
									<Icon name="inbox" />{t('transaction.table.noTransactions')}
								</Header>
							</Segment>
						</Table.Cell></Table.Row>
				</Table.Body>}
		</Table>
	</>
	);
};

export default PendingTransactions;
