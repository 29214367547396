import React from 'react';
import {
	Button,
	Modal, Segment
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { addEmployeeCode } from '@/features/employee/slice';
import { getErrorWithParams, showErrorNotification } from '@/features/swal/slice';

interface Props {
	open: boolean;
	onClose: (value?: string) => void
}

const AddEmployeeCode = ({ open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const initialValues = {
		code: ''
	};

	const validationSchema = Yup.object({
		code: Yup.string().required(t('form.validator.required'))
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;
		setSubmitting(false);
		const { code } = formData;
		if (code) {
			try {
				await addEmployeeCode(code);
			}
			catch (e) {
				const err = getErrorWithParams(e);
				if (err && err.error === 'invalid' && err.error_param === 'code') {
					setFieldError('code', t('employee.invalidEmployeeCode'));
				} else if (err && err.error === 'duplicate' && err.error_param === 'code') {
					setFieldError('code', t('employee.duplicateEmployeeCode'));
				} else {
					showErrorNotification(e);
				}
				return;
			}
		}
		onClose(code);
	};


	return <Modal
		size="mini"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Segment basic>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting, dirty }) => (
						<Form
							size="large">
							<Input
								label={t('business.addEmployeeCode.add')}
								fluid
								name="code"
								placeholder={t('business.addEmployeeCode.addPlaceholder')}
								errorPrompt
							/>
							<div className='editBTNS'>
								<SubmitButton
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									primary size="large" type="submit">
									{t('form.buttons.save')}
								</SubmitButton>
								<Button className='discardBTN' content={t('form.buttons.cancel')} onClick={() => onClose()} />
							</div>
						</Form>)}
				</Formik>
			</Segment>
		</Modal.Content>
	</Modal>;
};

export default AddEmployeeCode;
