import React from 'react';
import { Container, Segment, Item, Icon } from 'semantic-ui-react';
import { Faq } from '../../features/faq/types';
import './FAQ.css';

interface Props {
	faq: Faq,
	onClick: () => void
}

const FAQItem = ({faq, onClick}: Props): React.ReactElement =>
	<div id="faqitem" onClick={onClick}>
		<Container>
			<Segment>
				<Item.Group>
					<Item className="faqitem">
						<Icon className="questionIcon" size="huge" name="circle"></Icon> 
						<Item.Content className="faqitemcontent">
							<Item.Header as="h3">{faq.name}</Item.Header>
						</Item.Content>
						<Icon className="chervonRightIcon" size="large" name="angle right"></Icon>
					</Item>
				</Item.Group>
			</Segment>
		</Container>
	</div>;

export default FAQItem;
