import React, { useState } from 'react';
import { Segment, Divider, Button, Header, List, Grid, Message } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { postFile } from '@/features/files/slice';
import { TokenWithdrawPayload } from '@/features/tokens/types';
import { getTokens, postTokenWithdraw } from '@/features/tokens/slice';
import { getErrorWithParams, showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { useDispatch } from 'react-redux';
import { tranformSubProcessByType } from '@/features/operations/slice';
import { SendToExternalPayload } from './SendToExternalForm';

type ConfirmationProps = {
	onConfirm: () => void,
	payload: SendToExternalPayload
}

const SendToExternalConfirmation = ({ payload, onConfirm }: ConfirmationProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [addressError, setAddressError] = useState<string>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const subProcess = tranformSubProcessByType(payload?.token?.subProcesses)['PAYMENT'];

	const send = async () => {
		setSubmitting(true);
		try {
			const attachments: Map<string, string> = new Map<string, string>();
			if (payload.selectedFile) {
				const formData = new FormData();
				formData.append('file', payload.selectedFile);
				const fileRecord = await postFile(formData);
				const { key } = fileRecord;
				attachments.set(key, payload.selectedFile.name);
			}

			const data: TokenWithdrawPayload = {
				accountId: payload.token.accountId,
				amount: payload.amount,
				tokenId: payload.token.id,
				reference: payload.reference,
				operation: subProcess.proc,
				saveToContacts: false,
				beneficiaryName: payload.beneficiaryName,
				beneficiaryCryptoAddress: payload.beneficiaryCryptoAddress,
				beneficiaryCryptoNetwork: payload.beneficiaryCryptoNetwork,
				beneficiaryIdentityNo: payload.beneficiaryIdentityNo,
				attachments: Object.fromEntries(attachments)
			};

			await postTokenWithdraw(data);

			showSuccessNotification(t('tokens.tokenRequestCreated'));
			dispatch(getTokens());
			onConfirm();
		} catch (e) {
			const err = getErrorWithParams(e);
			if (err && (err.error === 'invalid') && err.error_param === 'beneficiaryCryptoAddress') {
				setAddressError(t('tokens.header.cryptoAddressInvalid'));
			} else {
				await showErrorNotification(e);
			}
			return;
		}
		finally { setSubmitting(false); }
	};

	return (
		<Grid.Column width={11}>
			<Segment className='token-trade-window'>
				<Header as="h3">{t('tokens.header.confirmation')}</Header>
				<Divider hidden />
				<List relaxed>
					<List.Item>
						<List.Header>{t('tokens.header.transferAmount')}</List.Header>
						<NumberFormat
							displayType={'text'}
							decimalScale={payload.token.precision}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							value={payload.amount} />
					</List.Item>
					<List.Item>
						<List.Header>{t('tokens.header.token')}</List.Header>
						{payload.token.symbol}
					</List.Item>
					<Divider />
					<List.Item>
						<List.Header>{t('tokens.header.beneficiaryName')}</List.Header>
						{payload.beneficiaryName}
					</List.Item>
					{payload.beneficiaryIdentityNo &&
						<List.Item>
							<List.Header>{t('tokens.header.beneficiaryId')}</List.Header>
							{payload.beneficiaryIdentityNo}
						</List.Item>}
					<List.Item>
						<List.Header>{t('tokens.header.network')}</List.Header>
						{payload.beneficiaryCryptoNetwork}
					</List.Item>
					<List.Item>
						<List.Header>{t('tokens.header.withdrawalAddress')}</List.Header>
						{payload.beneficiaryCryptoAddress}
						{addressError && <Message negative>{addressError}</Message>}
					</List.Item>
					{payload.selectedFile?.name && <List.Item>
						<List.Header>{t('sendmoney.data.document')}</List.Header>
						{payload.selectedFile?.name}
					</List.Item>
					}
				</List>
				<Divider section />
				<Button className='tokens-trade-submit'
					onClick={send}
					disabled={!subProcess.proc || submitting}
					primary fluid size="large">{t('form.buttons.confirm')}</Button>
			</Segment>
		</Grid.Column>
	);
};

export default SendToExternalConfirmation;
