import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Header, Label} from 'semantic-ui-react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { Card as ICard, CardSubProcesses } from '@features/card/types';
import { showErrorNotification, showInfoNotification } from '@features/swal/slice';
import { runOperation } from '@features/operations/slice';
import { Agents } from '@/helpers/globalTypes';
import { maxTwoDecimals } from '@/helpers/number';
type Props = {
	card: ICard,
	cardDetail: any
}

const TopUp = ({ card, cardDetail }: Props): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const [_accounts, setAccounts] = useState<any[]>([]);
	const [subProcesses, setSubProcesses] = useState<any>({});
	const [operation, setOperation] = useState<any>(null);

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const { accounts } = useSelector(
		(state: RootState) => state.trust
	);

	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const initialValues = {
		amount: '',
		accountFromId: ''
	};

	const validationSchema = Yup.object({
		accountFromId: Yup.string().required(t('form.validator.required')),
		amount: Yup.number()
			.label(t('sendmoney.data.amount'))
			.required(t('form.validator.required')).min(1)
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting, resetForm } = formikProps;
		const { amount, accountFromId } = formData;
		const { accountId, currency } = card;
		const allAccounts = [...list, ...accounts];
		const account = allAccounts.find(entry => entry.accountId === parseInt(accountFromId));
		if (account.availableBalance < amount) {
			showInfoNotification('sendmoneyTranslation.data.insufficient');
			return;
		}
		try {
			const payload = { amount, accountFromId, accountToId: accountId, ccy: currency };
			await runOperation(operation, payload);
			resetForm();
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
		}
	};

	useEffect(() => {
		let acc = [];
		if (list) {
			acc = (list
				.filter(account => account.currency === card.currency)
				.map(account => ({
					text: `${account.name} (${account.availableBalance} ${account.currency})`, value: account.accountId
				})));
		}


		if (accounts && (status.source === Agents.CUSTODYFY || status.source === Agents.WZ)) {
			accounts
				.filter(account => account.currency === card.currency)
				.map(account => {
					acc.push({ text: `${account.name} (${account.availableBalance} ${account.currency})`, value: account.accountId });
				});
		}

		setAccounts(acc);
	}, [list, accounts]);

	useEffect(() => {
		cardDetail && setSubProcesses(cardDetail.subProcesses);
	}, [cardDetail]);

	useEffect(() => {
		const operation = Object.keys(subProcesses).find(k => subProcesses[k]['type'] === CardSubProcesses.CARD_TOP_UP);
		setOperation(operation);
	}, [subProcesses]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
		>
			{({ errors, isSubmitting, dirty }) => (
				<Form
					size="large">
					<Label className='LBLCode' content={t('cards.topUpCard.topUp')} />
					<div className="moneyTopupAmount">
						<Header className="currencyH2" as="h2">EUR</Header>
						<Input name="amount" className="currencyInput" transparent placeholder="0.00" size="massive" type='number' autoFocus={(window.screen.width <= 767) ? false : true} />
					</div>
					{errors.amount &&
						<div className="amount-error-prompt ui label color-red"   aria-atomic="true" >
							{errors.amount}
						</div>}
					<div className="fromAccountLabel">
						<Label className='LBLCode' content={t('cards.topUpCard.label')} />
					</div>
					<Select
						disabled={card.status === 'SUSPENDED'}
						className="accountTopupSelect"
						options={_accounts}
						icon="chevron down"
						name="accountFromId"
						errorPrompt
					/>
					<SubmitButton
						className="topUpBTN"
						disabled={isSubmitting || !isEmpty(errors) || !dirty}
						primary fluid size="large" type="submit">
						Top up
					</SubmitButton>
				</Form>)}
		</Formik>
	);
};

export default TopUp;
