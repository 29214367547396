import React, { useState, useEffect } from 'react';
import {
	Switch,
	Route,
	Link,
	useRouteMatch,
	useLocation
} from 'react-router-dom';
import { Container, Breadcrumb, Header, Icon } from 'semantic-ui-react';
import qs from 'qs';
import CreateAccount from './Create/CreateAccount';
import CreateCard from './Create/CreateCard';
import CreateContact from './Contacts/CreateContact';
import CreateEmployee from '@/components/Business/CreateEmployee';
import { useTranslation } from 'react-i18next';

function Create(): React.ReactElement {
	const { t } = useTranslation(['translations']);
	const { path } = useRouteMatch();
	const { search } = useLocation();
	const [from, setFrom] = useState<string>('/wallet');

	useEffect(() => {
		const query = qs.parse(search, { ignoreQueryPrefix: true });
		if (query.from) {
			setFrom(query.from);
		}
	}, [search]);

	return (
		<Container>
			<Breadcrumb size="large">
				<Breadcrumb.Section className="backchevronitem" link as={Link} to={from}><Icon name="chevron left" /> {t('cards.newCard.cardMenu.back')}</Breadcrumb.Section>
			</Breadcrumb>
			<Switch>
				<Route path={`${path}/card`}>
					<Header as="h2">{t('create.newCard')}</Header>
					<CreateCard from={from} />
				</Route>
				<Route path={`${path}/contact`}>
					<Header as="h2">{t('create.createContact')}</Header>
					<CreateContact />
				</Route>
				<Route path={`${path}/employee`}>
					<Header as="h2">{t('create.createEmployee')}</Header>
					<CreateEmployee from={from} />
				</Route>
				<Route>
					<Header as="h2">{t('create.createAccount')}</Header>
					<CreateAccount from={from} />
				</Route>
			</Switch>
		</Container>);
}

export default Create;
