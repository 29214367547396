import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './CookiesPolicy.css';

function CookiesPolicyDTS(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Cookies Policy</h1></Grid.Row></Grid>
			<Segment className="policyContent">

				<p>This Cookie Policy applies to all of our websites (the <strong>“Website”</strong>). When we refer to any of our
					websites, we mean any website or mobile application operated by or on behalf of our company
					or its subsidiaries and affiliates, regardless of how you access the network. This Cookie Policy
					forms part of and is incorporated into our website Terms and Conditions.</p>
				<p>By accessing the website, you agree that this Cookie Policy will apply whenever you access the
					website on any device.</p>
				<p>Any changes to this policy will be posted here. We reserve the right to vary this Cookie Policy
					from time to time and such changes shall become effective as soon as they are posted. Your
					continued use of the website constitutes your agreement to all such changes.</p>
				<p>This policy explains how cookies are used on our website in general – and, below, how you can
					control the cookies that may be used on these sites (not all of them are used on every site).</p>

				<h4>What are cookies and how do we use them?</h4>

				<p>Cookies are small text files stored on your computer by your browser. They’re used for many
					things, such as remembering whether you’ve visited the site before, so that you remain logged in
					– or to help us work out how many new website visitors we get each month. They contain
					information about the use of your computer but don’t include personal information about you
					(they don’t store your name, for instance).</p>
				<p>We use cookies to provide the services and features offered on our websites, and to continually
					enhance the user experience.</p>
				<p>We may also use Google Analytics to track and analyze our site traffic in order to improve our
					website functionality by collecting data regarding how, when and where people use or access our
					website. Google Analytics does not collect personally identifiable data.</p>

				<h4>How can you manage cookies?</h4>

				<ul>
					<li><strong>Removing cookies from your device</strong></li>
					<p>You can delete all cookies that are already on your device by clearing the browsing history of
						your browser. This will remove all cookies from all websites you have visited.</p>
					<p>Be aware though that you may also lose some saved information (e.g. saved login details, site
						preferences).</p>
					<li><strong>Managing site-specific cookies</strong></li>
					<p>For more detailed control over site-specific cookies, check the privacy and cookie settings in
						your preferred browser</p>
					<li><strong>Blocking cookies</strong></li>
					<p>You can set most modern browsers to prevent any cookies being placed on your device, but you
						may then have to manually adjust some preferences every time you visit a site/page. And some
						services and functionalities may not work properly at all (e.g. profile logging-in).</p>
					<li><strong>Managing our analytics cookies</strong></li>
					<p>You can manage your preferences concerning cookies when you enter our Website.</p>
				</ul>

				<p>This Cookie Policy was last updated on 21th March 2022.</p>

			</Segment>
		</Container>
	);
}

export default CookiesPolicyDTS;
