import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './PrivacyPolicy.css';

function PrivacyPolicyYESSY(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered>
					<h1>Privacy Policy</h1>
				</Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<p>
					Welcome to Yessy Pay (&quot;Yessy&quot;) Privacy Policy. Please take a moment to
					read it carefully before providing us with any personal information
					about you or any other person.
				</p>
				<p>
					Yessy Pay is a Platform operated by Xward Pay Inc., (hereinafter
					&quot;XWARD&quot;, &quot;we&quot;, &quot;our&quot; or &quot;us&quot;) and its partners from time to time. For
					the purposes of data protection law, XWARD is a data controller in
					respect of the personal data we receive from you, or otherwise collect
					about you, and we are responsible for ensuring that the use of your
					personal data is compliant with applicable data protection laws
					(‘General Data Protection Regulation’). As the controller of your
					personal data, we determine the &quot;means&quot; and the &quot;purposes&quot; of any
					processing activities that are carried out by us.
				</p>
				<p>
					The European representative of XWARD is Afacomp UAB, located A. Tumeno
					str. 4, 01109 Vilnius, Lithuania.
				</p>
				<h1>Background</h1>
				<p>
					We need to collect and use certain types of information about the
					physical and legal persons that wish to participate in our services.
				</p>
				<p>
					The personal information must be collected and dealt with appropriately
					in accordance with the company AML Policy and in accordance with this
					Privacy Policy drawn up in accordance with the GDPR and International
					standards.
				</p>
				<p>
					In accordance with the provisions of GDPR and International standards,
					any User who provides his information under the present policy confirms
					that he understands and accepts the reason for the gathering of
					information and consents to the processing of information for the
					purposes of money laundering prevention.
				</p>
				<p>
					Any User is entitled to know who is responsible for the processing of
					his or her personal data.
				</p>
				<p>
					Any data collected during the course of our business whether it is
					collected on paper, stored in a computer database, or recorded on other
					material is subject to this policy and is protected under the
					applicable law and the GDPR provisions regarding data control and
					processing.
				</p>
				<h1>What Does This Policy Cover?</h1>
				<p>
					This Privacy Policy applies only to your use of our website. The
					website may contain links to other websites. Please note that we have
					no control over how your data is collected, stored, or used by other
					websites and we advise you to check the privacy policies of any such
					websites before providing any data to them.
				</p>
				<h1>What is Personal Data?</h1>
				<p>
					Personal data is defined by the Personal Information Protection and
					Electronic Documents Act as ‘any information relating to an
					identifiable person who can be directly or indirectly identified in
					particular by reference to an identifier’.
				</p>
				<p>
					Personal data is, in simpler terms, any information about you that
					enables you to be identified. Personal data covers information such as
					your name and contact details, but it also covers less obvious
					information such as identification numbers, electronic location data,
					and other online identifiers.
				</p>
				<h1>What Data Do We Collect?</h1>
				<p>
					We collect personal information in the course of its business with you
					when you apply for the use of our products and/or services. The
					information is collected with your consent, or as permitted or required
					by law.
				</p>
				<p>
					Depending upon your use of our website, the personal information that
					you provide or that we ask for and collect could include the following:
				</p>
				<p>
					Identity Data:
				</p>
				<ul>
					<li>
						first name,
					</li>
					<li>
						last name,
					</li>
					<li>
						title,
					</li>
					<li>
						date of birth and gender,
					</li>
					<li>
						biometric information for facial recognition purposes,
					</li>
					<li>
						I.D documents such as national I.D card, passports, driving
						licences or other forms of I.D documents.
					</li>
				</ul>
				<p>
					Identity Related Data:
				</p>
				<ul>
					<li>
						risk assessment,
					</li>
					<li>
						compliance assessment.
					</li>
				</ul>
				<p>
					Contact Data:
				</p>
				<ul>
					<li>
						residence details,
					</li>
					<li>
						billing address,
					</li>
					<li>
						delivery address,
					</li>
					<li>
						email address and telephone numbers,
					</li>
					<li>
						proof of address documentation.
					</li>
				</ul>
				<p>
					Financial Data:
				</p>
				<ul>
					<li>
						bank account details,
					</li>
					<li>
						payment card details,
					</li>
					<li>
						amounts associated with accounts,
					</li>
					<li>
						external account details,
					</li>
					<li>
						source of funds and related documentation.
					</li>
				</ul>
				<p>
					Transactional Data:
				</p>
				<ul>
					<li>
						details about incoming and outgoing payments,
					</li>
					<li>
						other details of any transactions you enter into using the
						Services, Website or App.
					</li>
				</ul>
				<p>
					Technical Data:
				</p>
				<ul>
					<li>
						internet connectivity data,
					</li>
					<li>
						IP address,
					</li>
					<li>
						operator and carrier data,
					</li>
					<li>
						login data,
					</li>
					<li>
						browser type and version,
					</li>
					<li>
						device type, category and model,
					</li>
					<li>
						time zone setting and location data,
					</li>
					<li>
						language data,
					</li>
					<li>
						browser plug-in types and versions,
					</li>
					<li>
						operating system and platform,
					</li>
					<li>
						diagnostics data such as crash logs and any other data we collect
						for the purposes of measuring technical diagnostics, and
					</li>
					<li>
						other information stored on or available regarding the devices you
						allow us access to when you visit the Website, or use the Services
						or the App.
					</li>
				</ul>
				<p>
					Profile Data:
				</p>
				<ul>
					<li>
						your username and password,
					</li>
					<li>
						your identification number as our user,
					</li>
					<li>
						requests by you for products or services,
					</li>
					<li>
						your interests, preferences and feedback,
					</li>
					<li>
						other information generated by you when you communicate with our
						customer support.
					</li>
				</ul>
				<p>
					This information may be collected by way of paper documents, scanned or
					faxed copies, telephone, emails, verbal interviews, face to face
					interactions, or data received through our Website or App. This
					information will include the information collected by us about
					yourself, and any other individual with whom you deal through us, which
					include counterparties or senders or beneficiaries of funds or
					services.
				</p>
				<p>
					We collect this information through our employees, affiliates, and
					online resources, government agencies, or business partners, or
					consumer reporting agencies, or may be provided by yourself upfront
					when you approach us for applying to our services.
				</p>
				<p>
					We do not process &quot;Sensitive Data&quot; except when it comes to biometric
					data processed for the purpose of uniquely identifying a natural
					person.
				</p>
				<h1>How Do We Use Your Personal Data?</h1>
				<p>
					Under the Applicable law, we must always have a lawful basis for using
					personal data. This may be because the data is necessary for our
					provision of services to you and the performance of a contract with
					you, because you have consented to our use of your personal data, or
					because it is in our legitimate business interests to use it. Your
					personal data may be used for one of the following purposes:
				</p>
				<ul>
					<li>
						Providing and managing your access to our Website or Application;
					</li>
					<li>
						Supplying our products and services to you;
					</li>
					<li>
						Communicating with you;
					</li>
					<li>
						Maintaining our own accounts and records;
					</li>
					<li>
						Complying with legal and regulatory obligations on our and your
						behalf; and
					</li>
					<li>
						Analyzing your use of our website to enable us to continually
						improve it and your user experience.
					</li>
				</ul>
				<h1>Option Out Right</h1>
				<p>
					We may use your contact details and information related to your service
					preferences for marketing and solicitation purposes to send you offers
					or promotions of us or our affiliates. You may &quot;opt out&quot; or withdraw
					consent to disclosure of information for the purposes described in this
					section at any time by contacting us at
					<a href="mailto:assistenza@platformeconomy.net">assistenza@platformeconomy.net</a>.
				</p>
				<h1>How Long Will We Keep Your Personal Data?</h1>
				<p>
					We will keep your personal data for such a period as is required for us
					to provide services to you, or such a longer period as we may be
					required to process the data to ensure our compliance with all relevant
					laws and regulatory obligations to which we are subject.
				</p>
				<h1>How and Where Do We Store Your Personal Data?</h1>
				<p>
					Personal data is processed both manually and electronically and may be
					held in a number of different places in line with the operations of a
					typical business. The locations your personal data could be stored
					include, but are not limited to:
				</p>
				<ul>
					<li>
						Our databases and CRM;
					</li>
					<li>
						Emails on computers;
					</li>
					<li>
						Mobile phones and tablets;
					</li>
					<li>
						Contact address books;
					</li>
					<li>
						Business cards;
					</li>
					<li>
						Notebooks;
					</li>
					<li>
						Electronic documents on our file servers;
					</li>
					<li>
						Paper files in our offices;
					</li>
					<li>
						Cloud back-up and storage.
					</li>
				</ul>
				<p>
					The security of your personal data is important to us, and to protect
					your data we use and maintain appropriate technical and organizational
					measures to protect your personal data and to prevent the accidental or
					unlawful destruction, loss, alteration, unauthorized disclosure of or
					access to personal data transmitted, stored or otherwise processed. We
					have a framework of policies, procedures and training to cover
					information and cyber security, confidentiality and data protection.
				</p>
				<h1>Sharing and Transfer of Personal Data</h1>
				<p>
					We will only disclose personal data to third parties as is necessary to
					provide our services and/or to comply with your instructions. We may
					share your personal data with our affiliates and subsidiaries. We do
					not give or sell your personal information to third parties.
				</p>
				<p>
					In addition, we may be legally required to share certain personal data,
					which might include yours, if we are involved in legal proceedings or
					complying with legal obligations, a court order, or the instructions of
					a government authority.
				</p>
				<p>
					If any of your personal data is required by a third-party, we will take
					steps to ensure that your personal data is handled safely, securely,
					and in accordance with your rights, our obligations, and the
					third-party’s obligations under the law.
				</p>
				<p>
					As some of our external third parties are based outside the EEA, the
					processing of your personal data may involve a transfer of data outside
					the EEA.
				</p>
				<p>
					Whenever we transfer your personal data out of the EEA, we ensure a
					similar degree of protection is afforded to it by ensuring at least one
					of the following safeguards is implemented:
				</p>
				<ul>
					<li>
						the country to which we transfer your personal data has been deemed
						to provide an adequate level of protection for personal data by the
						European Commission;
					</li>
					<li>
						a specific contract approved by the European Commission which gives
						safeguards to the processing of personal data, the so called
						Standard Contractual Clauses.
					</li>
				</ul>
				<h1>Confidentiality</h1>
				<p>
					We maintain physical, electronic, and procedural safeguards to protect
					personal information against loss, theft, and any form of unauthorized
					access. Only those who are authorized employees, agents, or
					representatives that require access to your personal information in
					order to fulfill their job requirements will have access to the
					information. Our obligations will become effective only when the
					information is received by us physically or by its internal systems,
					but not while it is in transmission out of or to our physical
					possession or internal systems; because data exchange over the internet
					or the telephone system or physical mail cannot be guaranteed to be
					perfectly secure, any personal information you submit to us or access
					electronically or over the telephone is done at your own risk, and we
					does not guarantee or warrant the security of information so
					transmitted to us. You should exercise caution to prevent any third
					parties, or parties fraudulently posing as us, from unlawfully
					intercepting your transmissions or causing you to disclose personal
					information.
				</p>
				<h1>Consent</h1>
				<p>
					By using our website, mobile application and related services, you
					signify your acceptance of our privacy policy.
				</p>
				<p>
					This Privacy Statement provides our intentions in regard to your
					personal information collected by us from you. By providing us with
					personal information, we will assume your consent to our collection,
					use and disclosure of such information for the purposes described in
					this Privacy Policy wherever applicable. Please note that your consent
					implies the consent to use your email addresses, postal addresses, and
					other contact resources that you provide to us to communicate with you.
				</p>
				<p>
					Your consent may be given in various ways, such as: Orally,
					electronically or on a document in any form; or implied by voluntarily
					choosing to use our services; by not withdrawing consent to use your
					personal information for any identified purpose; by not using an &quot;opt
					out&quot; option provided for the specified purposes in this statement, if
					any. Such consent may be given by you directly or by your legal
					representative.
				</p>
				<p>
					Withdrawal of your consent will be entirely at your choice at any time,
					and will be effective, except in cases where we are subject to legal,
					contractual, regulatory, governmental, and/ or statutory obligations
					for record maintenance and disclosure; and except in case of
					transactions already initiated which need the use of your personal
					information for fulfillment. Note that in case of such withdrawal of
					consent we may no longer be able to provide certain of our products or
					services which require your personal information.
				</p>
				<h1>Your Rights</h1>
				<p>
					Under this Privacy Policy, you have the following rights:
				</p>
				<ol type="a">
					<li>
						The right to be informed about our collection and use of your
						personal data.
					</li>
					<li>
						The right to access the personal data we hold about you – you have
						the right to obtain confirmation as to whether we process personal
						data about you and certain information about how and why we process
						your personal data.
					</li>
					<li>
						The right to have your personal data amended or rectified if any of
						your personal data held by us is inaccurate or incomplete.
					</li>
					<li>
						The right to erasure (also known as the right to be forgotten),
						i.e. the right to ask us to delete or otherwise dispose of any of
						your personal data that we have.
					</li>
					<li>
						The right to restrict the processing of your personal data.
					</li>
					<li>
						The right to object to us using your personal data for a particular
						purpose or purposes.
					</li>
					<li>
						The right to data portability. This means that, if you have
						provided personal data to us directly, we are using it with your
						consent or for the performance of a contract or the provision of
						services, and that data is processed using automated means, you can
						ask us for a copy of that personal data to re-use with another
						service or business.
					</li>
					<li>
						The right to file a complaint. If you reside in an EEA Member
						State, you have the right to make a complaint about the way we
						process your personal data to the supervisory authority in the EEA
						Member State of your habitual residence, place of work or place of
						the alleged infringement.
					</li>
					<li>
						Rights relating to automated decision-making and profiling. We do
						not use your personal data in this way.
					</li>
				</ol>
				<p>
					For more information about our use of your personal data or exercising
					your rights as outlined above, or in the event that you wish to make a
					complaint, please contact us at
					<a href="mailto:assistenza@platformeconomy.net">assistenza@platformeconomy.net</a>.
				</p>
				<h1>Cookies </h1>
				<p>
					This section applies to our Website and mobile Application.
				</p>
				<p>
					By accessing the website, you agree that this section will apply
					whenever you access the website on any device.
				</p>
				<p>
					Any changes to this policy will be posted here. We reserve the right to
					vary this section from time to time and such changes shall become
					effective as soon as they are posted. Your continued use of the website
					constitutes your agreement to all such changes.
				</p>
				<p>
					This policy explains how cookies are used on our website in general –
					and, below, how you can control the cookies that may be used on these
					sites (not all of them are used on every site).
				</p>
				<h1>What are cookies and how do we use them?</h1>
				<p>
					Cookies are small text files stored on your computer by your browser.
					They’re used for many things, such as remembering whether you’ve
					visited the site before, so that you remain logged in – or to help us
					work out how many new website visitors we get each month. They contain
					information about the use of your computer but don’t include personal
					information about you (they don’t store your name, for instance).
				</p>
				<p>
					We use cookies to provide the services and features offered on our
					websites, and to continually enhance the user experience.
				</p>
				<p>
					We may also use Google Analytics to track and analyze our site traffic
					in order to improve our website functionality by collecting data
					regarding how, when and where people use or access our website. Google
					Analytics does not collect personally identifiable data.
				</p>
				<h1>How can you manage cookies?</h1>

				<h2>Removing cookies from your device</h2>
				<p>
					You can delete all cookies that are already on your device by clearing
					the browsing history of your browser. This will remove all cookies from
					all websites you have visited. Be aware though that you may also lose
					some saved information (e.g. saved login details, site preferences).
				</p>
				<h2>Managing site-specific cookies</h2>
				<p>
					For more detailed control over site-specific cookies, check the privacy
					and cookie settings in your preferred browser<strong>.</strong>
				</p>
				<h2>Blocking cookies</h2>
				<p>
					You can set most modern browsers to prevent any cookies being placed on
					your device, but you may then have to manually adjust some preferences
					every time you visit a site/page. And some services and functionalities
					may not work properly at all (e.g. profile logging-in).
				</p>
				<h2>Managing our analytics cookies</h2>
				<p>
					You can manage your preferences concerning cookies when you enter our
					Website.
				</p>
				<h2>Changes to this Privacy Policy</h2>
				<p>
					We may change this Privacy Notice from time to time. This may be
					necessary, for example, if the law changes, or if we change our
					business in a way that affects personal data protection.
				</p>
				<p>
					Any changes will be posted on our website and you will be deemed to
					have accepted the terms of the Privacy Policy on your first use of our
					website following the alterations.
				</p>
				<p>
					This Policy was last updated on 13<sup>th</sup> January 2023.
				</p>
			</Segment>
		</Container>
	);
}

export default PrivacyPolicyYESSY;
