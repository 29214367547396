import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Icon, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { lookupContact } from '@features/contacts/slice';
import { postPaymentRequest } from '@features/tickets/slice';
import { showErrorNotification } from '@features/swal/slice';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { TransferType } from './SendMoney';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import { ContactTypes } from '@/components/Dashboard/Contacts/CreateContact';
import { Contact } from '@/features/contacts/types';
import { maxTwoDecimals } from '@/helpers/number';

interface SendMoneyProps {
	onSend: (type: TransferType, value: any) => void,
}

function RequestMoney({ onSend }: SendMoneyProps): React.ReactElement {
	const { t } = useTranslation('translations');
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const [accountId] = useState<string>(searchParams.get('account'));
	const [account, setAccount] = useState<any>(null);
	const [type, setType] = useState<string | null>(null);
	const [initialValues, setInitialValues] = useState<any>({});
	const [internalContacts, setInternalContacts] = useState<Array<Contact>>([]);

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const { accounts } = useSelector(
		(state: RootState) => state.trust
	);

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const getAccount = () => {
		const bankAccount = list.find(l => `${l.accountId}` === accountId);
		if (!bankAccount) {
			setType('trust');
			return accounts.find(l => `${l.accountId}` === accountId);
		} else {
			setType('account');
			return bankAccount;
		}
	};

	useEffect(() => {
		if (!list.length && !accounts.length) {
			return history.push('/wallet');
		}
		const account = getAccount();
		setAccount(account);
	}, [list, accountId, history]);

	useEffect(() => {
		if (!account) {
			return;
		}
		setInitialValues({ to: account.name + ' (' + account.currency + ' ' + account.availableBalance + ')', from: '', amount: '', reference: '' });
	}, [account]);

	const validationSchema = Yup.object({
		amount: Yup.number().required(t('form.validator.required')).
		min(0.01, t('form.validator.minNumber', { field: t('sendmoneyTranslation.data.transferamount'), amount: '0.01' }))
		.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		from: Yup.string().required(t('form.validator.required')),
		reference: Yup.string().label( t('sendmoneyTranslation.data.referenceField')).required(t('form.validator.required'))
		.min(3, t('form.validator.minChars', { field: t('sendmoneyTranslation.data.referenceField'), chars: '3' })).max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;
		const { from, reference, amount } = formData;

		try {
			const data = await lookupContact(from, account.currency); 

			if (account?.proc === undefined || data.proc == undefined || account.proc !== data.proc) {
				setFieldError('from', t('sendmoneyTranslation.data.wrongProcess'));
				return;
			}

			const formData = new FormData();
			formData.append('toUserId', data.userId);
			formData.append('reference', reference);
			formData.append('requestMeta', JSON.stringify({ user: { id: user.id, firstName: user.firstName, lastName: user.lastName }, amount, account: { accountId: account.accountId, currency: account.currency, currencySymbol: account.currencySymbol } }));

			if (selectedFiles.length > 0) {
				selectedFiles.map(file => {
					formData.append('files', file);
				});
			}
			await postPaymentRequest(formData);
			onSend(TransferType.REQUEST, { amount, currency: account.currency, toName: `${data.firstName} ${data.lastName}` });
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
		}
	};
	const hiddenFileInput = React.useRef(null);
	const fileTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	];
	const [selectedFiles, setSelectedFiles] = useState([]);
	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 1) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 1) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};
	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};
	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};
	const { contacts } = useSelector(
		(state: RootState) => state.contacts
	);

	useEffect(() => {
		if (!contacts) {
			return;
		}
		setInternalContacts(contacts.filter(entry => entry.paymentType === ContactTypes.LOCAL && entry.type === 'BANK'));
	}, [contacts]);

	return (
		<Grid stackable columns="equal" id='requestMoney'>
			<Grid.Column width={10}>
				<Segment>
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={submit}
						enableReinitialize={true}
					>
						{({ setFieldValue }) => (
							<Form>
								<Input
									fluid
									id="to"
									name="to"
									label={t('sendmoneyTranslation.data.to')}
									disabled
								/>
								<Input
									fluid
									name="amount"
									label={t('sendmoneyTranslation.data.transferamount')}
									placeholder='0.00 EUR'
									type='number'
									errorPrompt
								/>
								<Select
									fluid
									name="from"
									label={t('sendmoneyTranslation.data.cospayuser')}
									options={internalContacts ? internalContacts.map(contact => ({ key: contact.beneficiaryAccountId, value: contact.walletId, text: contact.name })) : []}
									errorPrompt
								/>
								<Input
									fluid
									name="from"
									label={t('sendmoneyTranslation.data.walletid')}
									errorPrompt
								/>
								<TextArea name="reference"
									errorPrompt
									label={t('sendmoneyTranslation.data.reference')}
									maxLength="140" />
								{type === 'trust' && <div>
									<p><b>{t('sendmoneyTranslation.data.attachment')}</b></p>

									<input type="file"
										multiple
										accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
										name="files"
										ref={hiddenFileInput}
										onChange={e => {
											uploadFiles(e.currentTarget.files);
											setFieldValue('files', selectedFiles);
										}}
										style={{ display: 'none' }}
									/>
									<Button id='uploadButton' basic type="button" onClick={handleUploadClick} content={t('sendmoneyTranslation.activedata.upload')} icon="upload" />

									{selectedFiles.length > 0 && (
										<Grid className="uploadedFiles" padded>
											<Divider hidden />
											{selectedFiles.map((file, index) =>
												<Grid.Row className="uploadedFile" key={file.name}>
													<Icon size="large" name="file outline" />
													<div className="fileName">{file.name}</div>
													<Grid.Column floated='right' className="deleteAttachment">
														<Icon
															onClick={() => {
																deleteAttachment(index);
																setFieldValue('files', selectedFiles);
															}}
															size="large"
															name="times" />
													</Grid.Column>
												</Grid.Row>
											)}
										</Grid>
									)}
								</div>}
								<Divider section />
								<SubmitButton primary fluid type="submit">{t('sendmoneyTranslation.activedata.button')}</SubmitButton>
							</Form>)}
					</Formik>
				</Segment>
			</Grid.Column>
		</Grid>
	);
}

export default RequestMoney;
