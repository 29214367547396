import React, { useEffect, useState } from 'react';
import './Accounts.css';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
	Divider, Grid, Segment
} from 'semantic-ui-react';
import { UserType } from '@features/user/types';
import AccountsPlaceholder from './Shared/AccountsPlaceholder';
import AccountsList from './Shared/ScrollableTabs/AccountsList';
import TransactionStatements from './TransactionStatements';
import { getAccounts } from '@features/trust/slice';
import { connect as connectTrust, subscribe as subscribeTrust, unsubscribe as unsubscribeTrust } from '@features/trust/slice';

const Trust = (): React.ReactElement => {
	const dispatch = useDispatch();
	const [type] = useState<string>('trust');
	const [requiredTier, setRequiredTier] = useState<number>(1);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);
	const [accountsLoading, setAccountsLoading] = useState<boolean>(true);
	const { loading, accounts, error } = useSelector(
		(state: RootState) => state.trust
	);
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);

	useEffect(() => {
		connectTrust();
		dispatch(subscribeTrust());
		return () => {
			unsubscribeTrust();
		};
	}, [dispatch, user]);

	useEffect(() => {
		if (!user) return;
		setRequiredTier(user.type === UserType.INDIVIDUAL ? 1 : 2);
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		setKycTierVerified((kycTier >= requiredTier) || isKycTierRevoked);
		setAccountsLoading(!!user && loading);
	}, [user, requiredTier, loading]);

	// useEffect(() => {
	// 	if(!user) return;
	// 	dispatch(getAccounts());
	// },[getAccounts, user]);

	if (accountsLoading || !user) return <AccountsPlaceholder
		loadingMessage='Loading accounts'
		type={type} />;
	if (error) return <div>Error: {error}</div>;

	if (!kycTierVerified && user.onBehalfOf === null) {
		return <AccountsPlaceholder type={type} kyc={true} />;
	}

	if (user.taxFormRequired.taxFormRequired && !user.taxFormRequired.taxFormSigned) {
		return (<Segment padded="very">
			<Grid textAlign='center'>
				<Grid.Column width={10} >
				<div><strong>Thank you for creating your account in DTS Money Americas.</strong></div>
				<br />
				<div>In order to complete your registration we require all United States citizens to sign W-9 form (Request for Taxpayer Identification Number and Certification).</div>
				<br />
				<div>Shortly you will receive an email where you will find instructions how to sign W-9 form. After successfully signing your account will be activated.</div>
				</Grid.Column>
			</Grid>
		</Segment>);
	}

	if ((!accounts || !accounts.length)) return <AccountsPlaceholder type={type} />;

	return (
		<div>
			<AccountsList type={type} accounts={accounts} />
			{currentAccount && <Divider hidden />}
			{currentAccount && <TransactionStatements account={currentAccount} tabType={type} />}
		</div>);
};

export default Trust;
