import React from 'react';
import isEmpty from 'is-empty';
import { SubmitButton } from 'formik-semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';

type SubmitProps = {
	toc: boolean,
	aml: boolean,
	usResident: boolean,
	dirty: boolean,
	serviceAllowed: boolean,
	isSubmitting: boolean,
	politicalExposed: boolean,
	errors: any
}

const RegisterButton = ({ toc, aml, isSubmitting, errors, dirty, usResident, serviceAllowed, politicalExposed }: SubmitProps) => {
	const { t } = useTranslation('translations');

	const allowedCountries = useSelector(
		(state: RootState) => state.allowedCountries
	);

	const serviceIsAllowedInUSA = allowedCountries.list.includes('US');

	return (
		<SubmitButton
			className="registerbutton"
			disabled={isSubmitting || !isEmpty(errors) || !dirty || !toc || !aml || (usResident && !serviceIsAllowedInUSA) || !serviceAllowed || politicalExposed}
			primary size="large" type="submit">
			{t('registration.submit')}
		</SubmitButton>
	);
};

export default RegisterButton;
