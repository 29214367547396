import { useState, useEffect } from 'react';
import { KycTier } from './types';

export const useKYCTier = (kyc, tier) => {
	const [KYCTier, setKYCTier] = useState<KycTier>(null);

	useEffect(() => {
		if (kyc && kyc.length > 0) {
			setKYCTier(kyc.find(r => r.tier === tier));
		}
	}, [kyc, tier]);

	return KYCTier;
};
