import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Segment,
	Image,
} from 'semantic-ui-react';
import { format, parse, isValid } from 'date-fns';
import './CardImage.css';

interface Props {
	number?: string,
	expirationDate?: string,
	image: string,
	logoTextColor?: string,
	cardHolder?: string
}

const CardImage = ({ number, expirationDate, image, logoTextColor, cardHolder }: Props): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const [valid, setValid] = useState<string>('--/--');
	const [textColor, setTextColor] = useState<string>('#99999c');

	useEffect(() => {
		const date = parse(expirationDate, 'yyyy-MM-dd', new Date());
		if (isValid(date)) {
			setValid(format(date, 'MM/yy'));
		}
	}, [
		expirationDate
	]);

	useEffect(() => {
		if (logoTextColor !== null && logoTextColor !== undefined) {
			setTextColor(logoTextColor);
		}
	}, []);

	return (
		<Segment basic className="cardImage" id='card-image'>
			<Image src={image} />
			{number && expirationDate && <div id='card-data'>
				<Segment basic id='card-container'>
					<div id='card-number' style={{ color: textColor }}>{number}</div>
					<div id='card-holder' style={{ color: textColor }}>
						<div></div>
						<div style={{ fontSize: '2em' }}>{cardHolder}</div></div>
					<div id='valid-thru' style={{ color: textColor }}>
						<div>{t('cards.valid')}</div>
						<div style={{ fontSize: '2em' }}>{valid}</div></div>
				</Segment>
			</div>}
		</Segment>
	);
};

export default CardImage;
