import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { Trans, useTranslation } from 'react-i18next';
import './VaultsList.css';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
	open: boolean;
	onClose: () => void;
}

const IAcceptVaultModal = ({ open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	return <Modal
		size="mini"
		open={open}
		onClose={() => onClose()}
		closeOnDimmerClick={false}
		closeOnEscape={false}
	>
		<Modal.Content>
			<Modal.Description>
				<Trans i18nKey='vaults.iaccept.description'
					t={t}
					components={{
						termlink: <RouterLink to='/legal/terms' target='_blank' />,
						termOfUse: <RouterLink to='/legal/termsofuse' target='_blank' />,
						policylink: <RouterLink to='/legal/privacypolicy' target='_blank' />
					}}
				/> 
				</Modal.Description>
		</Modal.Content>
		<Modal.Actions>
			<Button
				content={t('vaults.iaccept.button')}
				labelPosition='right'
				icon='checkmark'
				onClick={() => onClose()}
				positive
			/>
		</Modal.Actions>
	</Modal>;
};

export default IAcceptVaultModal;
