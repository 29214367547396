import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Header, Segment } from 'semantic-ui-react';
import '../CardDetails.css';
import Acivatenewcard from '../../../icons/ActivateNewCard';
import { CardSubProcesses, CardType } from '@features/card/types';
import { runOperation } from '@features/operations/slice';
import { showErrorNotification } from '@features/swal/slice';
import { useTranslation } from 'react-i18next';
import PanLast6DigitsModal from '@/components/Banking/Card/PanLast6DigitsModal';
import { getCards } from '@/features/card/slice';
import { useDispatch } from 'react-redux';

type Props = {
	card: any,
	cardId: number,
	deliveryAddress?: any
}

function Inactive({ card, cardId, deliveryAddress }: Props): React.ReactElement {
	const { t } = useTranslation('translations');
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [operation, setOperation] = useState<any>(null);
	const [processProperties, setProcessProperties] = useState<any>({});
	const [subProcesses, setSubProcesses] = useState<any>({});
	const [cardType, setCardType] = useState<CardType>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		setProcessProperties(card.processProperties);
		setSubProcesses(card.subProcesses);
		setCardType(card.type);
	}, [card]);

	useEffect(() => {
		if (processProperties.virtual) {
			setCardType(CardType.VIRTUAL);
		} else {
			setCardType(CardType.PHYSICAL);
		}
	}, [processProperties]);

	useEffect(() => {
		const operation = Object.keys(subProcesses).find(k => subProcesses[k]['type'] === CardSubProcesses.CARD_ACTIVATE);
		setOperation(operation);
	}, [subProcesses]);

	const activate = async (panLast6digits: string) => {
		try {
			setLoading(true);
			const payload = { cardId, panLast6digits };
			await runOperation(operation, payload);
			dispatch(getCards());
		} catch (e) {
			await showErrorNotification(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div id="cardDashboard">
			<PanLast6DigitsModal open={modalOpen} type={cardType} onClose={(value?: string) => {
				setModalOpen(false);
				if (value) {
					activate(value);
				}
			}} />
			<Grid centered columns="1" container>
				<Grid.Row>
					{cardType === CardType.PHYSICAL && <Grid.Column width="16" textAlign="center">
						<div style={{ margin: '0 auto', width: 'fit-content' }}>
							<Segment basic padded>
								<span className='secondary'>{t('cards.inactive.address')}</span><br />
								{deliveryAddress && <span>{deliveryAddress.city}, {deliveryAddress.address}, {deliveryAddress.zip}</span>}<br />
							</Segment>
						</div>
						<Divider hidden />
					</Grid.Column>}
					<Divider hidden />
					<Grid.Column width="16" textAlign='center'>
						<Acivatenewcard id='inactive-card' viewBox="0 0 500 150"/>
					</Grid.Column>
					<Grid.Column textAlign="center" width="16">
						{cardType === CardType.PHYSICAL && <Header className="activatecardHeader">{t('cards.inactive.physicalHeader')}</Header>}
						{cardType === CardType.VIRTUAL && <Header className="activatecardHeader">{t('cards.inactive.virtualHeader')}</Header>}
						<Button loading={loading} onClick={() => { setModalOpen(true); }} disabled={!operation || loading} className="activatecardBTN" primary content={t('cards.inactive.activate')} />
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
}

export default Inactive;
