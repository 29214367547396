import React from 'react';
import { Divider, Grid, Segment } from 'semantic-ui-react';
import '@/components/Dashboard/MyContacts.css';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ContactTypes } from './CreateContact';
import { addContact, lookupContact } from '@/features/contacts/slice';
import { getErrorWithParams, showErrorNotification } from '@/features/swal/slice';
import { useHistory } from 'react-router';
import { RootState } from '@/rootReducer';


const NewInternalContact = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const history = useHistory();

	const { contacts } = useSelector(
		(state: RootState) => state.contacts
	);

	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required')),
		walletId: Yup.string().required(t('form.validator.required'))
			.test('already',
				t('sendmoneyTranslation.data.alreadyInContacts'),
				(value) => isNotInContacts(value)),
	});

	const isNotInContacts = (walletId: string) => {
		const alreadyInContacts = contacts.filter(entry => entry.paymentType === ContactTypes.LOCAL && entry.walletId === walletId);

		if (alreadyInContacts.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;
		try {
			const { name, walletId } = formData;
			const user = await lookupContact(walletId);

			await dispatch(addContact({ name, walletId, paymentType: ContactTypes.LOCAL }));
			history.push('/wallet/contacts');
		} catch (error) {
			const err = getErrorWithParams(error);
			if (err && err.error === 'notFound' && err.error_param === 'walletId') {
				setFieldError('walletId', t('sendmoneyTranslation.data.userNotFound'));
			} else {
				showErrorNotification(error);
			}
		}
		finally { setSubmitting(false); }
	};

	return (
		<Grid id='createContact' container>
			<Grid.Column width={16}>
				<Segment padded>
					<Formik
						initialValues={{
							name: '',
							walletId: ''
						}}
						validationSchema={validationSchema}
						onSubmit={submit}
					>{({ errors, isSubmitting, dirty }) => (<Form
						size="large">
						<Input
							label={t('contacts.create.name')}
							fluid
							name="name"
							placeholder='John Doe'
							errorPrompt
						/>
						<Input
							label={t('contacts.create.id')}
							fluid
							name="walletId"
							errorPrompt
						/>
						<Divider section />
						<SubmitButton
							disabled={isSubmitting || !isEmpty(errors) || !dirty}
							primary fluid type="submit">
							{t('contacts.create.save')}
						</SubmitButton>
					</Form>)}
					</Formik>
				</Segment>
			</Grid.Column>
		</Grid>
	);

};
export default NewInternalContact;



