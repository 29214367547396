import React from 'react';
import {
	Switch, Route, Redirect, useRouteMatch
} from 'react-router-dom';
import Email from '../components/Confirmation/Email';
import NotFound from './NotFound';

const Confirmation = (): React.ReactElement => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}/email/:code`}>
				<Email />
			</Route>
			<Route path={`${path}/login`}>
				<NotFound />
			</Route>
			<Route path={`${path}/device`}>
				<NotFound />
			</Route>
			<Route path={`${path}/contact`}>
				<NotFound />
			</Route>
			<Route><Redirect to={'/'} /> </Route>
		</Switch>);
};

export default Confirmation;
