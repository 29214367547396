import React, { useCallback, useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import AccountError from './Shared/AccountError';
import Inactive from './Card/Inactive';
import Pending from './Card/Pending';
import Loading from './Card/Loading';
import TopUp from './Card/TopUp/TopUp';
import { Card as ICard, CardStatuses, CardSubProcesses, CardType } from '@features/card/types';
import { cancelToken, getCard, getCards } from '@features/card/slice';
import { Button, Dimmer, Dropdown, Grid, Icon, List, Loader, Message, Segment } from 'semantic-ui-react';
import CardImage from './Shared/CardImage';
import { useTranslation } from 'react-i18next';
import { runOperation } from '@features/operations/slice';
import { showErrorNotification, showInfoNotification, showSuccess, showSuccessNotification } from '@features/swal/slice';
import NumberFormat from 'react-number-format';
import { setAccount } from '@features/tabs/slice';
import './CardDetails.css';
import PanLast6DigitsModal from '@/components/Banking/Card/PanLast6DigitsModal';
import { useHistory } from 'react-router-dom';
import { UserType } from '@/features/user/types';
import EditMobile from '../Dashboard/MyAccount/Profile/EditMobile';

type Props = {
	card: ICard
}

const CardDetails = ({ card }: Props): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const [, setSource] = useState<any>(null); 
	const [loading, setLoading] = useState<boolean>(true);
	const [running, setRunning] = useState<boolean>(false);
	const [error, setError] = useState<string>(null);
	const [cardDetail, setCardDetail] = useState<any>(null);
	const [cardType, setCardType] = useState<CardType | null>(null);
	const phone=card.phone;
	const initialOperations = {
		CARD_SUSPEND: { content: t('cards.freezeCard') },
		CARD_BLOCK_AND_PULL_OUT: { content: t('cards.terminateCard.btn') },
		CARD_GET_CVV: { content: t('cards.cardDetails') },
		CARD_GET_PIN: { content: t('cards.getPin') }
	};
	const [operations, setOperations] = useState<any>(initialOperations);
	const [dropdownRef] = useState<any>(React.createRef());
	const [, setMobileMenuOpen] = useState<boolean>(false);
	const [editPhone, setEditPhone] = useState<boolean>(false);
	const history = useHistory();

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const cardsList = (useSelector(
		(state: RootState) => state.cards
	)).list;

	const [modalOpen, setModalOpen] = useState<boolean>(false);

	const activate = async (panLast6digits: string) => {
		try {
			const { subProcesses } = cardDetail;
			const { cardId } = card;
			const payload = { cardId, panLast6digits };
			const operation = Object.keys(subProcesses).find(k => subProcesses[k]['type'] === CardSubProcesses.CARD_ACTIVATE);
			await runOperation(operation, payload);
			dispatch(getCards());
		} catch (e) {
			await showErrorNotification(e);
		}
	};

	useEffect(() => {
		if (!cardDetail) return;
		const updated = { ...initialOperations };
		Object.keys(cardDetail.subProcesses).forEach(key => {
			if (updated[cardDetail.subProcesses[key].type]) {
				updated[cardDetail.subProcesses[key].type].proc = key;
			}
		});
		setOperations(updated);
	}, [cardDetail]);

	const getDetails = useCallback(() => {
		setLoading(true);
		setError(null);
		cancelToken();
		const get = async () => {
			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			setSource(source);
			try {
				const detail = await getCard(card.accountId, source);
				setCardDetail(detail);
				const { processProperties } = detail;
				const { virtual } = processProperties;
				if (virtual) {
					setCardType(CardType.VIRTUAL);
				} else {
					setCardType(CardType.PHYSICAL);
				}
				setError(null);
			}
			catch (e) {
				if (e && e.data) {
					setError(e.data.message);
				}
			} finally {
				setLoading(false);
			}
		};
		get();
	}, [card.accountId, card.status]);

	useEffect(() => {
		getDetails();
	}, [getDetails]);

	const activity = async (operation: string): Promise<void> => {
		if (!cardDetail) return;
		setRunning(true);
		try {
			const payload: any = { cardId: card.cardId };
			if (operation === 'CARD_PULL_OUT') {
				const account = list.find(account => account.currency === card.currency);
				if (!account) throw new Error('No account with currency: ' + card.currency);
				payload.accountFromId = card.accountId;
				payload.accountToId = account.accountId;
				payload.amount = card.availableBalance;
				payload.details = `Card balance pullout to ${account.walletId} account.`;

			} else if (operation === 'CARD_BLOCK_AND_PULL_OUT') {
				if (card.balanceOnHold > 0) {
					await showInfoNotification(t('cards.terminateOnHold'));
					return;
				}
				const account = list.find(account => account.currency === card.currency);
				if (!account) throw new Error('No account with currency: ' + card.currency);
				payload.accountToId = account.accountId;
			}
			await runOperation(operations[operation].proc, payload);
			switch (operation) {
				case 'CARD_GET_CVV':
					showSuccessNotification(t('cards.cardSettings.sentto') + phone);
					break;
				case 'CARD_GET_PIN':
					showSuccessNotification(t('cards.cardSettings.sentto') + phone);
					break;
				case 'CARD_PULL_OUT':
					showSuccessNotification('Terminated card ' + card.number);
					break;
				case 'CARD_SUSPEND':
					await dispatch(getCards());
					showSuccessNotification('Froze card ' + card.number);
					break;
			}
		} catch (e) {
			await showErrorNotification(e);
		} finally {
			setRunning(false);
		}
	};

	const onCloseEditMobileModal = async (mobileNumber?: string) => {
		setEditPhone(false);
		if (mobileNumber) {
			setRunning(true);
			try {
				await runOperation('dc-card-update-phone', { cardId: card.cardId, phone: mobileNumber });
				showSuccess({ timer: null, text: 'Card phone number successfully updated' });
			}
			catch (e) {
				await showErrorNotification(e);
			} finally {
				setRunning(false);
			}
		}
	};
 
	const activateCardWithouCode = async () => {
		try {
			setRunning(true);
			const payload = { cardId: card.cardId};
			const operation = Object.keys(cardDetail.subProcesses).find(k => cardDetail.subProcesses[k]['type'] === CardSubProcesses.CARD_ACTIVATE);
			await runOperation(operation, payload);
			dispatch(getCards());
		} catch (e) {
			await showErrorNotification(e);
		} finally {
			setRunning(false);
		}
	};


	const handleActive = async () => {
		if (card.status === CardStatuses.INACTIVE) {
			setModalOpen(true);
		}
		else {
			await activateCardWithouCode();
		}
	};

    let cardInner = (
        <Grid.Row>
            <Grid.Column only="computer tablet" className="cardOptionCLMN" computer={ 5 }>
                <Segment className="cardOptionSegment">
                    <CardImage number={ card.number } expirationDate={ card.expirationDate } image={ card.logo }
                        logoTextColor={ card.logoTextColor } cardHolder={ card.cardholder }/>
                    { card.status === 'SUSPENDED' &&
                    <Button key='CARD_ACTIVATE' onClick={ handleActive } className='terminateCardBTN' fluid content={ t('cards.activate') }/> }
                    { Object.keys(operations).map(operation => <>{operations[operation].proc && <Button disabled={ !operations[operation].proc }
                        key={ operation } onClick={ () => {
                            activity(operation);
                        } } className='terminateCardBTN' fluid content={ operations[operation].content }/>}</>
                    ) }
                    { user && (user.type === UserType.BUSINESS || user.onBehalfOf !== null) &&
                    <List className="cardPhone">
                        <List.Item>
                            <List.Content>
                                <List.Header>
                                    Phone number
                                </List.Header>
                                <List.Description className="cardPhoneNumber">
                                    +{ card.phone }
                                    <Button onClick={ () => setEditPhone(true) } floated='right' size="large" primary
                                        basic icon='edit' className="edit-card-phone link-button"/>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    </List> }
                </Segment>
            </Grid.Column>
            <Grid.Column computer={ 6 }>
                { (user && user.type != 'EMPLOYEE' && card.status !== 'SUSPENDED') &&
                <TopUp card={ card } cardDetail={ cardDetail }/> }
                { card.status === 'SUSPENDED' &&
                <Message attached='top'><Icon name='info'/>
                    { t('cards.suspended') }
                </Message>
                }
            </Grid.Column>
            <Grid.Column computer={ 5 }>
                <Grid.Row className="cardType">
                    <p>{t('cards.cardType')}</p>
                    <p className="cardTypeValue">{ card.virtual ? t('cards.virtual') : t('cards.physical') }</p>
                    { card && card.assignedTo && card.assignedTo.company !== null &&
                    <p className="cardTypeValue">-</p> }
                    { card && card.assignedTo && card.assignedTo.company !== null &&
                    <p className="cardTypeValue">{ card.assignedTo.company }</p> }
                </Grid.Row>
            </Grid.Column>
        </Grid.Row>
    );

	if (error) { cardInner = <AccountError message={error} />; }

	else if (loading) {
		cardInner = <Loading />;
	}

	else if ([CardStatuses.PENDING, CardStatuses.ORDERED].indexOf(card.status) !== -1 && cardDetail) {
		cardInner = <Pending image={card.logo} />;
	}

	else if (card.status === CardStatuses.INACTIVE && cardDetail) {
		cardInner = <Inactive card={cardDetail} cardId={card.cardId} deliveryAddress={card.deliveryAddress} />;
	}

	return (
		<div id="cardsettingsDashboard">
			<PanLast6DigitsModal open={modalOpen} onClose={(value?: string) => {
				setModalOpen(false);
				if (value) {
					activate(value);
				}
			}} type={cardType} />
			<EditMobile
				onClose={onCloseEditMobileModal}
				open={editPhone}
			/>
			<Segment className="cardsettingSegment" basic>
				<Dimmer active={running} inverted><Loader /></Dimmer>
				<Grid stackable>
					{user.type === UserType.INDIVIDUAL && <Grid.Row className="mobileaccountdetailsRow" only="mobile">
						<Grid.Column>
							<Segment className="myaccountsegment" >
								<Dropdown
									ref={dropdownRef}
									value={card.accountId}
									closeOnChange
									as={() => {
										return (<div style={{ width: '100%' }} id="mobile-account-dropdown" onClick={() => { 
											setMobileMenuOpen(true);
										}}>
											<div>
												<div className="tab-name">{card.name}</div>
												<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${card.currency} `} value={card.availableBalance} /></div>
												<div className="tab-hold">{t('cards.balanceOnHold')}<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${card.currency} `} value={card.balanceOnHold} /></div>
											</div>
											<Dropdown fluid icon='chevron down' value={card.accountId}>
												<Dropdown.Menu fluid>
													{cardsList.map(account => <Dropdown.Item fluid onClick={() => {
														setMobileMenuOpen(false);
														dispatch(setAccount(account));
													}} key={account.accountId}>
														<div style={{ width: '100%' }}>
															<div>
																<div className="tab-name">{account.name}</div>
																<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${account.currency} `} value={account.availableBalance} /></div>
																<div className="tab-hold">{t('cards.balanceOnHold')}<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${account.currency} `} value={account.balanceOnHold} /></div>
															</div>
														</div>
													</Dropdown.Item>)}
												</Dropdown.Menu>
											</Dropdown>
										</div>);
									}} >
								</Dropdown>
							</Segment>
						</Grid.Column>
					</Grid.Row>}
					<Grid.Row>
						{user.type === UserType.INDIVIDUAL && <Grid.Column only="mobile">
							<Button labelPosition="left" icon="plus circle" primary fluid content={t('create.createNewCard')} onClick={() => history.push('/wallet/create/card')} />
						</Grid.Column>}
					</Grid.Row>
					{cardInner}
				</Grid>
			</Segment>
		</div>
	);
};
export default CardDetails;
