import React from 'react';
import { Grid, Icon } from 'semantic-ui-react';

interface Props {
	children?: React.ReactNode;
}

const InfoMessage: React.FC = ({
	children,
}: Props) => {
	return (
		<div style={{ marginBottom: '1em' }}>
			<Grid className='info-notification' columns={2} padded style={{ borderRadius: '6px' }}>
				<Grid.Row>
					<Grid.Column style={{ width: 'fit-content', paddingRight: '0' }} only='computer tablet'>
						<Icon size='large' name='info circle' color='blue' />
					</Grid.Column>
					<Grid.Column width={16} only='mobile' style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }}>
						<Icon size='large' name='info circle' color='blue' />
					</Grid.Column>
					<Grid.Column width={15}>
						{children}
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default InfoMessage;
