import React, { useEffect, useState } from 'react';
import {
	Modal, Segment, Divider, List
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TickerRequestMoney, TicketType } from '@features/tickets/types';
import { getAccount, getAccountLimit } from '@features/account/slice';
import { runOperation } from '@features/operations/slice';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import CospayAccountConfirmation from '../Transfers/SendMoney/CospayAccountConfirmation';
import { maxTwoDecimals } from '@/helpers/number';
import { AccountLimits } from '@/features/account/types';

interface Props {
	message: TickerRequestMoney;
	open: boolean;
	onClose: () => void
}

const SendMoney = ({ message, open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [meta, setMeta] = useState<any>();
	const [_accounts, setAccounts] = useState<Array<any>>([]);
	const [accountFrom, setAccountFrom] = useState<any>(null);
	const [payload, setPayload] = useState<any>(null);
	const [submitting, setSubmitting] = useState<boolean>(false);

	const validationSchema = Yup.object({
		from: Yup.string().required(t('form.validator.required')),
		amount: Yup.number().label('Amount').required(t('form.validator.required')).min(0.1)
			.test('amount', t('sendmoneyTranslation.data.insufficient'), (value, ctx) => { return (value <= _accounts?.find(a => a.accountId.toString() === ctx.parent.from)?.availableBalance); })
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		details: Yup.string().max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
	});

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const { accounts } = useSelector(
		(state: RootState) => state.trust
	);

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;
		const { amount, from, details } = formData;
		const accountDetails = await getAccount(from);
		const accountFrom = _accounts.find(el => el.accountId === from);
		setAccountFrom(accountFrom);
		const operation = Object.keys(accountDetails.subProcesses).find(key => accountDetails.subProcesses[key].type === 'TRANSFER');

		const limit: AccountLimits = await getAccountLimit(accountFrom.accountId);
		if (amount > limit.availableBalanceNow) {
			setFieldError('amount', t('sendmoneyTranslation.data.limitReached', { ccy: limit.ccy, availableBalanceNow: limit.availableBalanceNow }));
			return;
		}

		const payload = { from: accountFrom, accountToId: meta.account.accountId, amount: parseFloat(amount), details, operation, currency: accountDetails.currency };
		setSubmitting(false);
		setPayload(payload);
	};

	const confirm = async () => {
		setSubmitting(true);
		try {
			await runOperation(payload.operation, { accountFromId: payload.from.accountId, accountToId: payload.accountToId, amount: payload.amount, details: payload.details, ccy: payload.from.currency });
			onClose();
			showSuccessNotification(t('swal.MONEY_SENT'));
		}
		catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
			setPayload(null);
		}
	};

	useEffect(() => {
		if (!meta || !list) return;
		if (meta?.contactType === 'BANK') {
			setAccounts(list.filter(account => meta.account.currency === account.currency));
		} else if (meta?.contactType === 'TRUST') {
			setAccounts(accounts.filter(account => meta.account.currency === account.currency));
		} else {
			setAccounts(list.filter(account => meta.account.currency === account.currency));
		}
	}, [meta, list]);

	useEffect(() => {
		if (!message) return;
		switch (message.incidentType) {
			case TicketType.TRANSFER_REQUEST:
				try {
					setMeta(JSON.parse(message.requestMeta));
				}
				catch (e) {
					setMeta(null);
				}
				break;
		}
	}, [message]);

	return <Modal
		size="mini"
		open={open}
		onClose={() => { setPayload(null); onClose(); }}>
		<Modal.Content>
			<Segment basic>
				{payload && <CospayAccountConfirmation inModal={true} payload={payload} process={process} onConfirm={confirm} submitting={submitting} availableAmount={accountFrom.availableBalance} />}
				{!payload && <Formik
					enableReinitialize={true}
					initialValues={{ amount: meta ? meta.amount : 0, from: '', details: '' }}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting, dirty, setFieldTouched }) => (
						<Form
							size="large">
							<Input
								label={t('sendmoney.data.amount')}
								fluid
								name="amount"
								type="number"
								placeholder={0}
								errorPrompt
							/>
							<Select
								fluid
								name="from"
								label={t('sendmoneyTranslation.data.from')}
								options={_accounts.map(item => ({ value: item.accountId, key: item.accountId, text: `${item.name} (${item.currency} ${item.availableBalance})` }))}
								onChange={() => setTimeout(() => setFieldTouched('amount'))}
								errorPrompt
							/>
							<TextArea
								fluid
								name="details"
								errorPrompt
								label={t('sendmoneyTranslation.data.reference')}
							/>
							<Divider />
							{meta && <List relaxed>
								<List.Item>
									<List.Header>{t('sendmoney.data.beneficiary')}</List.Header>
									{meta.user.firstName} {meta.user.lastName}
								</List.Item>
							</List>}
							<Divider />
							<SubmitButton
								disabled={isSubmitting || !isEmpty(errors) || !dirty}
								fluid
								primary size="large" type="submit">
								{t('form.buttons.submit')}
							</SubmitButton>
						</Form>)}
				</Formik>}
			</Segment>
		</Modal.Content>
	</Modal>;
};

export default SendMoney;
