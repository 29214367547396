import React from 'react';
import { Button, Divider, Grid, Segment } from 'semantic-ui-react';
import './Crypto.css';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { addContact, editContact } from '@features/contacts/slice';
import { showErrorNotification } from '@features/swal/slice';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import isEmpty from 'is-empty';

interface Props {
	onBackClick: any,
	contact?: any
}

const AddAddress = ( { onBackClick, contact }: Props): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();

	const validationSchema = Yup.object({
		label: Yup.string().required(t('form.validator.required')),
		coin: Yup.string().required(t('form.validator.required')),
		address: Yup.string().required(t('form.validator.required')),
		network: Yup.string().required(t('form.validator.required')),
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { label, coin, address, network } = formData;
			if(!contact) {
				await dispatch(addContact({ name: label, bank: network, account: address, currency: coin, paymentType: 'CRYPTO' }));
			} else {
				await dispatch(editContact(contact.beneficiaryAccountId, { name: label, bank: network, account: address, currency: coin, paymentType: 'CRYPTO' }));
			}
			onBackClick();
		}
		catch (error) { showErrorNotification(error); }
		finally { setSubmitting(false); }
	};

	return (
		<div id='createContact'>
			<Grid stackable columns={1} container>
				<Grid.Row centered>
					<Grid.Column className='createContactCLMN' widescreen={9}>
						<Segment padded className='createContactSGMNT'>
							<Formik
								initialValues={{
									label: contact && contact.name || '',
									coin: contact && contact.ccy || '',
									address: contact && contact.account || '',
									network: contact && contact.bank || '',
								}}
								validationSchema={validationSchema}
								onSubmit={submit}
							>{({ errors, isSubmitting, dirty }) => (<Form
									size="large">
									<Input
										label={t('crypto.address.management.input.label')}
										fluid
										name="label"
									/>
									<Input
										label={t('crypto.address.management.input.coin')}
										fluid
										name="coin"
									/>
									<Input
										label={t('crypto.address.management.input.address')}
										fluid
										name="address"
									/>
									<Input
										label={t('crypto.address.management.input.network')}
										fluid
										name="network"
									/>
									<Divider section />
									<SubmitButton
										disabled={isSubmitting || !isEmpty(errors) || !dirty}
										primary fluid type="submit">
										{t('contacts.create.save')}
									</SubmitButton>
									<Divider section />
									<Button secondary onClick={onBackClick}>{t('contacts.create.cancel')}</Button>
								</Form>)}
							</Formik>
						</Segment>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</div>
	);
};

export default AddAddress;
