import React, { useState } from 'react';
import { Card } from '@/features/card/types';
import { Accordion, Button, Grid, Icon } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import CardDetails from '../CardDetails';
import { useTranslation } from 'react-i18next';
import './BusinessCards.css';
import EditAccountLabel from '../Shared/ScrollableTabs/Components/EditAccountLabel';
import { updateLabel } from '@/features/account/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { getCards } from '@/features/card/slice';
import { useDispatch, useSelector } from 'react-redux';
import TransactionStatements from '@/components/Banking/TransactionStatements';
import { RootState } from '@/rootReducer';

type Props = {
	card: Card
}

const BusinessCardDetails = ({ card }: Props): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [activeCard, setActiveCard] = useState<Card>(null);
	const [editAccountLabel, setEditAccountLabel] = useState<boolean>(false);

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const onClick = (activeCard: Card) => {
		setActiveCard(activeCard);
	};


	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};

	const onCloseEditAccountLabelModal = async (label?: string) => {
		setEditAccountLabel(false);
		if (label) {
			try {
				await updateLabel(card.accountId, label);
				dispatch(getCards());
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
	};

	return (<div>
		<EditAccountLabel
			onClose={onCloseEditAccountLabelModal}
			open={editAccountLabel}
			label={card.name}
		/>
		<Accordion>
			<Accordion.Title
				index={1}
				onClick={handleClick}
			>
				<div onClick={() => onClick(card)}>
					<Grid container columns={1}>
						<Grid.Row className="titleRow" columns={1}>
							<div className="tab-name">{card.name}
								<Button onClick={() => { setEditAccountLabel(true); }} floated='right' size="large" primary basic icon='edit' className="edit-account-label link-button" />
							</div>
							<Icon className='accordionOpenIcon' name='chevron down' />
						</Grid.Row>
						{card.assignedTo !== null && <Grid.Row className="titleRow" columns={1}>
							<div className="assigned-value">Assigned to: {card.assignedTo.firstName + ' ' + card.assignedTo.lastName}</div>
						</Grid.Row>}
						<Grid.Row className="titleRow" columns={1}>
							{!user.hideValues && <div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${card.currency} `} value={card.availableBalance} /></div>}
							{user.hideValues && <div className="tab-value">{card.currency} *****</div>}
						</Grid.Row>
						<Grid.Row className="titleRow" columns={1}>
							{!user.hideValues && <div className="tab-hold">{t('cards.balanceOnHold')}<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${card.currency} `} value={card.balanceOnHold} /></div>}
							{user.hideValues && <div className="tab-hold">{t('cards.balanceOnHold')}{card.currency} *****</div>}
						</Grid.Row>
					</Grid>
				</div>
			</Accordion.Title>
			<Accordion.Content active={activeIndex === 1}>
				{activeCard && <CardDetails card={activeCard} />}
				{activeCard && <TransactionStatements account={activeCard} />}
			</Accordion.Content>
		</Accordion>
	</div>);
};

export default BusinessCardDetails;
