import React from 'react';
import { Button, Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { disable2FA } from '@features/user/userSlice';
import { useHistory } from 'react-router-dom';

const GoogleAuth = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const manage = async () => {
		if (user.twoFactorAuthEnabled) {
			dispatch(disable2FA());
		} else {
			history.push('/wallet/2fa');
		}
	};

	return (
		<Segment padded>
			<Header size='medium'>{t('googleauth.data.header')}</Header>
			<p>{t('googleauth.data.description')}</p>
			{user && <Button primary basic onClick={manage}>{user.twoFactorAuthEnabled ? t('googleauth.activedata.disable') : t('googleauth.activedata.enable')}</Button>}
		</Segment>
	);
};

export default GoogleAuth;
