const SITE_KEY = '6LdAceUjAAAAANa2YRKHT9ETIQiIHtdUfQyHJ2M6';

export const loadRecaptureScript = (callback) => {
	const id = 'recaptcha-key';

	const url =
		'https://www.recaptcha.net/recaptcha/enterprise.js?render=' + SITE_KEY;
	const isScriptExist = document.getElementById(id);

	if (!isScriptExist) {
		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.src = url;
		script.id = id;
		script.onload = function () {
			if (callback) callback();
		};
		document.body.appendChild(script);
	}

	if (isScriptExist && callback) callback();
};

export const getRecaptureToken = async () => {
	const w = window as any;
	let token = '';
	if (w.grecaptcha?.enterprise) {
		token = await w.grecaptcha.enterprise.execute(
			SITE_KEY,
			{ action: 'sms' }
		);
	}
	
	return token;
};
