import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Container, Segment, Button, Header, Grid, Input, List, Divider } from 'semantic-ui-react';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import '@/components/Business/MyEmployees.css';
// import Loading from '@/pages/LoadingPage';
import ActiveEmployee from '@/components/Business/ActiveEmployee';
import PendingEmployee from '@/components/Business/PendingEmployee';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect, getActiveEmployees, getPendingEmployees, linkCard, setActiveEmployee, setPendingEmployee, subscribe, unsubscribe } from '@/features/employee/slice';
import { Employee } from '@/features/employee/types';
import AssignCard from './AssignCard';
import { useTranslation } from 'react-i18next';

const MyEmployees = (): React.ReactElement => {

	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const { path } = useRouteMatch();

	const [searchValue, setSearchValue] = useState('');
	const [assignCard, setAssignCard] = useState<boolean>(false);
	const [assignCardForEmployee, setAssignCardForEmployee] = useState<Employee>(null);


	const { activeEmployees, pendingEmployees } = useSelector(
		(state: RootState) => state.employee
	);

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);


	const fetchActiveEmployees = () => {
		try {
			dispatch(getActiveEmployees());
		} catch (e) {
			showErrorNotification(e);
		}
	};

	useEffect(() => {
		fetchActiveEmployees();
	}, [dispatch]);

	const fetchPendingEmployees = () => {
		try {
			dispatch(getPendingEmployees());
		} catch (e) {
			showErrorNotification(e);
		}
	};

	useEffect(() => {
		fetchPendingEmployees();
	}, [dispatch]);

	const create = () => {
		history.push('/wallet/create/employee?from=' + path);
	};

	const assign = (employee: Employee = null) => {
		setAssignCard(true);
		if (employee !== null) {
			setAssignCardForEmployee(employee);
		}
	};


	const handleSearchChange = e => {
		setSearchValue(e.target.value);
		if (searchValue.length >= 3) {
			return search();
		}
	};

	const clearInput = async () => {
		setSearchValue('');
		fetchPendingEmployees();
		fetchActiveEmployees();
	};

	const search = () => {
		if (searchValue === '') {
			return;
		}
		const pendingList: Employee[] = [];
		pendingEmployees.filter(item => {
			if (item.firstName.toLowerCase().includes(searchValue.toLowerCase()) || item.lastName.includes(searchValue.toLowerCase())) {
				pendingList.push(item);
			}
		});
		const activeList: Employee[] = [];
		activeEmployees.filter(item => {
			if (item.firstName.toLowerCase().includes(searchValue.toLowerCase()) || item.lastName.includes(searchValue.toLowerCase())) {
				activeList.push(item);
			}
		});
		dispatch(setPendingEmployee(pendingList));
		dispatch(setActiveEmployee(activeList));
	};

	const closeAssignCard = () => {
		setAssignCard(false);
		setAssignCardForEmployee(null);
	};

	const onCardLink = (values?) => {
		const link = async () => {
			try {
				await linkCard(values.employeeId, values.cardId);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		link();
		showSuccessNotification(t('swal.CARD_LINKED'));
		setAssignCard(false);
		setAssignCardForEmployee(null);
	};

	if (assignCard) { return <AssignCard onSend={onCardLink} onBackClick={closeAssignCard} employee={assignCardForEmployee} employees={activeEmployees}></AssignCard>; }


	return (
		<Container id='myEmployees'>
			<Header className='myEmployeeHeader'>{t('business.myEmployee.myEmployees')}</Header>
			<Segment>
				{/* Computer and tablet */}
				<Grid fluid columns="2" className="myEmployeeButtons">
					<Grid.Row only="computer tablet">
						<Grid.Column width="6" floated="left">
							<Button className='createContactBTN' primary onClick={create} content={t('business.myEmployee.createNewEmployee')} />
							<Button className='assignCardBTN' primary basic onClick={() => assign()} content={t('business.myEmployee.assignCard')} />
						</Grid.Column>
						<Grid.Column width="4" floated="right">
							<Input className="searchInput" size="large" fluid placeholder={t('business.myEmployee.searchPlaceholder')} value={searchValue} action={{ icon: 'times', onClick: () => clearInput() }} onChange={handleSearchChange} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row only="mobile">
						<Grid.Column width="16">
							<Button fluid className='createContactBTN' primary onClick={create} content={t('business.myEmployee.createNewEmployee')} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row only="mobile">
						<Grid.Column width="16">
							<Button fluid className='assignCardBTN' primary basic onClick={() => assign()} content={t('business.myEmployee.assignCard')} />
						</Grid.Column>
					</Grid.Row>
					<Grid.Row only="mobile">
						<Grid.Column width="16">
							<Input className="searchInput" size="large" fluid placeholder={t('business.myEmployee.searchPlaceholder')} value={searchValue} action={{ icon: 'times', onClick: () => clearInput() }} onChange={handleSearchChange} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
				<Divider hidden />
				<List size="large" relaxed="very" divided verticalAlign='middle'>
					<Header className="employeeListHeader">{t('business.myEmployee.pendingEmployees')}</Header>
					{pendingEmployees.length > 0 && pendingEmployees.map(employee => <List.Item key={employee.employeeId}><PendingEmployee employee={employee} clearInput={clearInput}/></List.Item>)}
					{!pendingEmployees.length && <List.Item className="noEmployees"> {t('business.myEmployee.noPendingEmployees')} </List.Item>}
					<Header className="employeeListHeader">{t('business.myEmployee.activeEmployees')}</Header>
					{activeEmployees.length > 0 && activeEmployees.map(employee => <List.Item key={employee.employeeId}><ActiveEmployee assingCardForEmployee={() => assign(employee)} employee={employee} /></List.Item>)}
					{!activeEmployees.length && <List.Item className="noEmployees"> {t('business.myEmployee.noActiveEmployees')} </List.Item>}
				</List>
			</Segment>
		</Container>
	);
};

export default MyEmployees;



