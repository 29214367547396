import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './Terms.css';

function TermsOLAMIT(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Terms and Conditions</h1></Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<div>
					<h1>
						INTRODUCTION
					</h1>
				</div>
				<div>
					<strong>
						Last Modified: August 19, 2022
					</strong>
				</div>
				<br />
				<div>
					<p>
						Olamit brand is owned by OLAMIT LTD (hereinafter “Olamit”) a company
						located at 71-75 SHELTON STREET, COVENT GARDEN, LONDON, UNITED KINGDOM,
						WC2H 9JQ and with the company number 13902902. whose registered office
						is at 71-75 SHELTON STREET, COVENT GARDEN, LONDON, UNITED KINGDOM, WC2H
						9JQ.
					</p>
				</div>
				<br />
				<div>
					<p>
						Olamit&nbsp;
						<a
							href="https://ib.olamit.com/"
							target="_blank"
							rel="noreferrer noopener"
						>
							https://ib.olamit.com
						</a>&nbsp;
						is operated by XWARD Pay Inc. (hereinafter “XWARD”), a Canadian
						Incorporated Company with incorporation number BC1332850, located at
						Suite 2500, Park Place 666, Burrard St., Vancouver, BC, V6C 2X8,
						Canada, and regulated by FINTRAC as a Money Service Business (MSB).
					</p>
				</div>
				<br />
				<div>
					<p>
						In consideration for permitting your access to our website and online
						services and other good and valuable consideration, you agree as
						follows:
					</p>
				</div>
				<br />
				<div>
					<p>
						These terms and conditions (the &quot;Terms&quot;) form a legally binding
						agreement which govern your access to and use of our website (the
						&quot;Website&quot; or “Platform”) and our corresponding Android and iOS
						applications (collectively the &quot;Services&quot;).
					</p>
				</div>
				<br />
				<div>
					<p>
						By using our Website and Services, you, the user (&quot;you&quot; or &quot;your&quot;),
						represent and warrant that (i) you are at least 18 years old; (ii) you
						are not a US citizen; (iii) you are not on any Canadian, European or US
						government list of prohibited, sanctioned or restricted persons; and
						(iv) you have read and understand these Terms and agree to be bound by
						them. Unless the above representations and warranties are true, you are
						not permitted to establish an account (an &quot;Account&quot;) with us or
						otherwise use our Services.
					</p>
				</div>
				<br />
				<div>
					<p>
						If you are using the Website or Services on behalf of, or in the employ
						of, an organization (corporation, trust, partnership, etc.), you are
						agreeing to these Terms for that organization and representing and
						warranting that you have the authority to bind that organization to
						these Terms. In such a case, &quot;you&quot; and &quot;your&quot; will also refer to that
						organisation and yourself individually. For greater clarity, both you
						as an individual and your organisation are legally bound by these Terms
						which form an agreement between you and us.
					</p>
				</div>
				<br />
				<div>
					<h1>
						PRIVACY‍
					</h1>
				</div>
				<br />
				<div>
					<p>
						We use your personal information in accordance with these Terms and our
						privacy policy, which is available online on our website as amended
						from time to time (the “Privacy Policy”). By using our Website and
						Services, you consent to such processing, and you represent that all
						information provided by you is accurate.‍‍‍
					</p>
				</div>
				<br />
				<div>
					<h1>
						AMENDMENTS
					</h1>
				</div>
				<br />
				<div>
					<p>
						As our Website and Services continue to change, we may, at any time,
						revise these Terms and our policies by updating this page or the page
						hosting the relevant policy. The date of the last version of these
						Terms is posted above. As you are bound by these Terms each time you
						visit our Website or use our Services, you are responsible for
						periodically reviewing the amendments to these Terms and you are deemed
						to have accepted and agreed to such amendments by accessing and using
						the Website and Services after such amendments have been posted. We may
						also undertake to send you notice of any changes to the Terms or
						policies via email and/or a notification on the Services.
					</p>
				</div>
				<br />
				<div>
					<p>
						If you do not agree with the amendments, you must immediately stop
						accessing the Website and Services and terminate your Account, subject
						to the provision of these Terms.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ABOUT OUR ONLINE SERVICES
					</h1>
				</div>
				<br />
				<div>
					<p>
						Our Services operate as an online platform for sending and receiving
						payments through e-wallets, using debit cards connected to users’
						accounts and buying, selling and exchanging eligible digital assets
						(collectively the “Digital Assets” or “Eligible Digital Assets” and
						each a “Digital Asset” or “Eligible Digital Asset”). We may add
						additional digital assets to our Services. We may also remove support
						for, or the ability to buy existing Digital Assets, at any time. You
						agree that these Terms shall apply to any additional digital assets
						added to our Services.
					</p>
				</div>
				<br />
				<div>
					<p>
						Payment services are provided by Clear Junction, an Electronic Money
						Institution authorized by the FCA with firm reference number 900684 and
						registered address at 4th Floor Imperial House, 15 Kingsway, London,
						United Kingdom, WC2B 6UN.
					</p>
				</div>
				<br />
				<div>
					<p>
						Prepaid debit cards are provided by Cospay Limited, an Electronic Money
						Institution authorized by the FCA with firm reference number 900088,
						registered address at One, Bartholomew Close, London, United Kingdom,
						EC1A 7BL and principal member of partner card schemes Mastercard.
					</p>
				</div>
				<br />
				<div>
					<p>
						Digital assets purchase, sale, exchange and custody are provided by
						XWARD.
					</p>
				</div>
				<br />
				<div>
					<h1>
						CUSTODY AND OWNERSHIP OF DIGITAL ASSETS
					</h1>
				</div>
				<br />
				<div>
					<h2>
						Appointment of Custodian
					</h2>
				</div>
				<br />
				<div>
					<p>
						When you purchase Digital Assets via our Services, they will be
						delivered and held under the custody of a third-party Digital Asset
						custodian (“Custodian(s)”).
					</p>
				</div>
				<br />
				<div>
					<p>
						Please note that when you use our custody service to store eligible
						digital assets you will not receive any fee, rewards or financial
						compensation of any kind on eligible digital assets so stored.
					</p>
				</div>
				<br />
				<div>
					<h2>
						Title &amp; Ownership
					</h2>
				</div>
				<br />
				<div>
					<p>
						The Digital Assets held in your Account are fully-paid assets
						beneficially owned by you. Custodian will record your Digital Assets in
						its books as separate and apart from anyone else’s Digital Assets and
						from Custodian own property. Custodian records will at all times
						provide for the separate identification of the Digital Assets owned by
						each client.
					</p>
				</div>
				<div>
					<p>
						<br />
						XWARD nor Custodian will transfer, sell, loan or otherwise
						rehypothecate Eligible Digital Assets held in a Custody Wallet unless
						specifically instructed by you, except as required by valid court
						order, competent regulatory agency, government agency or applicable
						law. As title owner of assets, you bear all risk of loss. XWARD or
						Custodian shall have no liability for any Digital Asset price
						fluctuations or any or all loss of Digital Assets. Notwithstanding the
						foregoing, we may suspend your access to the Services, including the
						Custody Service and your access to a Custody Wallet, in the event of
						market disruptions or periods of volatility. We will use reasonable
						care and commercially reasonable efforts in connection with the Custody
						Service to store and secure Eligible Digital Assets in a Custody
						Wallet. You understand that your use of the Custody Service does not
						create a fiduciary relationship between you and the Custodian. We have
						no duty to inquire into, supervise, or determine the suitability of any
						transaction you initiate involving Eligible Digital Assets in a Custody
						Wallet. Eligible Digital Assets in a Custody Wallet may be commingled
						with the Eligible Digital Assets of other Users, and XWARD nor
						Custodian is under no obligation to return the actual Eligible Digital
						Assets initially transferred by you to a Custody Wallet, but will
						return Eligible Digital Assets of the identical type reflected in your
						Account at the time you request such a return. We may provide
						information regarding the Eligible Digital Assets in a Custody Wallet
						to comply with any applicable law, regulation, rule, or request by law
						enforcement or government agencies.
					</p>
				</div>
				<br />
				<div>
					<h1>
						DEPOSITING DIGITAL ASSETS, TRANSACTIONS AND THIRD-PARTY CUSTODY
					</h1>
				</div>
				<br />
				<div>
					<p>
						You agree and acknowledge that you are the initiator and beneficiary of
						all transfers to your Account, to the extent that the transfer is
						within your control. You acknowledge that any incoming transfer not
						initiated or not requested to be initiated by you may not be released
						to your Account, until or unless you submit information on the person
						or entity who initiated the transfer. You also acknowledge that we
						reserves the right to conduct a manual review of your account and any
						incoming transfer(s), to ensure that the funds can be released to your
						account. This review period is usually completed within 48 to 72 hours,
						but it may take longer.
					</p>
				</div>
				<br />
				<div>
					<p>
						By using our Services, you consent to the use of and our integration
						with each Digital Asset wallet provider and each Custodian to act as a
						custodian of your Digital Assets. You further consent to the Digital
						Assets which you purchase or deposit in connection with our Services
						being delivered to, deposited with and held by a Custodian on your
						behalf.
					</p>
				</div>
				<br />
				<div>
					<p>
						We make no representations or warranties as to whether such deposits
						are insured by any Deposit Insurance Mechanism.
					</p>
				</div>
				<br />
				<div>
					<p>
						In order to facilitate quicker withdrawals of Digital Assets, you agree
						that we may use Digital Assets which we own, to carry out withdrawal
						instructions provided from within your Account on the Services. Upon a
						withdrawal transaction being requested and confirmed from within your
						Account on the Services, you agree that we shall have your irrevocable
						consent to instruct Custodians to cause an equal number of Digital
						Assets to be withdrawn from their custody, which you owned, to set off
						amounts we send you, or which you trade as part of such withdrawal
						transaction.
					</p>
				</div>
				<br />
				<div>
					<p>
						We do not, nor do we purport to have, ownership of Digital Assets which
						are delivered to each Custodian by us, on your behalf, in connection
						with the purchase or exchange of Digital Assets you make on our
						Services.
					</p>
				</div>
				<br />
				<div>
					<p>
						To the extent you purchase or deposit Digital Assets which are held by
						a Custodian in connection with our Services, you agree to keep your
						Digital Assets free from any liens, encumbrances, charges, or claims,
						unless disclosed and agreed to by us and the applicable Custodian, in
						writing. You represent and warrant that any Digital Assets you purchase
						or exchange on our Services are being purchased and exchanged by you
						directly, and not for or on behalf of any third-party. You are not
						authorized to use your Account on our Services for or on behalf of any
						other person or entity.
					</p>
				</div>
				<br />
				<div>
					<h1>
						WITHDRAWING DIGITAL ASSETS TO EXTERNAL DIGITAL WALLETS
					</h1>
				</div>
				<br />
				<div>
					<p>
						While the Digital Assets you purchase or deposit are held by a
						Custodian, you may elect to withdraw your Digital Assets to an external
						digital wallet address. Given the nature of digital assets and
						blockchain technology, we are unable to confirm who owns or controls
						external digital wallets address you specify for withdrawals. For your
						protection, you agree to only withdraw Digital Assets from your Account
						on the Services to external digital wallet addresses which you own and
						control, and not to any third-party. You acknowledge that we are unable
						to retrieve withdrawn Digital Assets, or reverse Digital Asset
						transactions.
					</p>
				</div>
				<br />
				<div>
					<p>
						As our Services may facilitate the withdrawal of your Digital Assets
						from a Custodian, you authorize us to send withdrawal instructions to
						each Custodian on your behalf. In doing so, we will act as your agent,
						with the nature and scope of such agency relationship being limited to
						sending Digital Asset withdrawal and transfer instructions to the
						Custodian in accordance with these Terms and any actions taken from
						within (or in connection with) your Account on our Services.
					</p>
				</div>
				<br />
				<div>
					<p>
						To facilitate the withdrawal of your Digital Assets held by a
						Custodian, when a withdrawal request is made within your Account, we
						send instructions to a Custodian, on your behalf, to send your Digital
						Assets to the wallet address you provided us with. In our sole
						discretion and in rare circumstances, including where your Account is
						suspended or terminated, we may also rely on withdrawal instructions
						from the email address registered for your Account on our services. For
						that and other reasons, it is your responsibility to ensure you
						maintain sole and exclusive control of any email address used to
						register your Account on the Services. It is your responsibility to
						ensure that any incoming or outgoing Digital Assets are sent to the
						correct digital wallet addresses and specify, where applicable, the
						correct memo or reference code; whether displayed by us in your Account
						on the Services, or whether the address and memo code are for an
						external wallet address.
					</p>
				</div>
				<br />
				<div>
					<p>
						Your failure to do so may result in the permanent loss of such digital
						assets, and you acknowledge that this is an inherent risk of using
						digital assets.
					</p>
				</div>
				<br />
				<div>
					<p>
						Without limiting any other section of these Terms which restricts or
						limits our liability, you agree that we shall not be held liable or
						responsible for any damages or losses, howsoever caused (including by
						negligence or otherwise), which you suffer, arising from or in any way
						related to Digital Assets being sent to the wrong wallet address, or
						the use of a Custodian. If you elect to withdraw any Digital Assets
						using our Services, you accept full responsibility and liability for
						ensuring the accuracy of the external digital wallet address and the
						custody and control of, and access to, the external digital wallet
						address and related private keys.
					</p>
				</div>
				<br />
				<div>
					<p>
						Processing times for withdrawing and making delivery of your Digital
						Assets via a Custodian may vary based upon the network times of the
						applicable Digital Asset and each Custodian’s processing times.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ESTABLISHING AN ACCOUNT
					</h1>
				</div>
				<br />
				<div>
					<p>
						To establish an Account you will be required to provide certain
						personal information as set out on the Services, in our Privacy Policy
						and these Terms, including your name, address, phone number, identity
						documents, identity numbers, and an email address.
					</p>
				</div>
				<br />
				<div>
					<p>
						You also agree that all incoming transfers to your Account will be
						initiated by you, or caused or requested to be initiated by you.
					</p>
				</div>
				<br />
				<div>
					<p>
						If you are establishing an Account in the name of an organization or
						business, such as a corporation, you may be required to provide a list
						of documents as per our request.
					</p>
				</div>
				<br />
				<div>
					<p>
						As part of our measures to confirm your identity (and the identity of
						each beneficial owner), we will use third party identification service
						providers such as Onfido. To learn more about Onfido’s privacy
						practices, please visit&nbsp;
						<a
							href="https://onfido.com/privacy/"
							target="_blank"
							rel="noreferrer noopener"
						>
							https://onfido.com/privacy/
						</a>
						.
					</p>
				</div>
				<br />
				<div>
					<p>
						We may send copies of your identity documents and personal information
						to such third-party providers for the purpose of confirming your
						identity and preventing fraud. In some instances, we may need to speak
						with you via phone to validate your Account information and your intent
						to register an Account with us. You agree that we may use the phone
						number registered with your Account for the above purposes. On such
						phone calls you must not reveal your Account passwords or passphrases
						for our or any other services.
					</p>
				</div>
				<br />
				<div>
					<p>
						Phone calls may be recorded for our own internal quality control and
						training purposes. To assist us with Account and information
						validations we may also use third-party service providers. Such service
						providers may have access to your personal information, including for
						example, your identity documents, photos you upload to the Service and
						contact information. Third party service providers may also call you,
						on our behalf, to assist us with your identity and Account
						verification.
					</p>
				</div>
				<div>
					<div>
					</div>
					<div>
						<p>
							Upon applying for an Account on behalf of a business or other
							organization, you represent and warrant that:
						</p>
					</div>
					<div>
					</div>
					<div>
						<ul>
							<li>
								<p>
									you are fully authorized to bind the business or other
									organization;
								</p>
							</li>
							<li>
								<p>
									the business or organization is duly authorized to
									establish the Account with us and enter these Terms;
								</p>
							</li>
							<li>
								<p>
									you will fully disclose the names and addresses of all
									beneficial owners of the business, organization and Account
									who have a beneficial ownership or control interest (or
									other similar beneficial interest) of 20% or more;
								</p>
							</li>
							<li>
								<p>
									The organization is not based in the U.S.
								</p>
							</li>
							<li>
								<p>
									none of the beneficial owners of the organization are an
									individual, entity, organization, government or country
									upon which the Government of Canada, the European Union or
									the United States of America has imposed sanctions,
									including but not limited to those listed under the
									legislation and related regulations referenced online at&nbsp;
									<a
										href="https://www.international.gc.ca/world-monde/international_relations-relations_internationales/sanctions/current-actuelles.aspx?lang=eng"
										target="_blank"
										rel="noreferrer noopener"
									>
										https://www.international.gc.ca
									</a>
									.
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div>
						<p>
							Upon establishing an Account, we grant you a non-transferable,
							non-exclusive license to access the Services in accordance with
							these Terms. However, we reserve the right to revoke that license
							and your access to our Website and Services without cause or
							justification at any time and without advanced notice.
						</p>
					</div>
				</div>
				<br />
				<div>
					<p>
						If you establish an Account with us, access to your Account is not
						transferable and is only intended for you, the individual who
						established the Account (whether for yourself, or for and on behalf of
						your organization). As a result, you are not permitted to change the
						name associated with your Account, nor are you permitted to purchase,
						deposit or store Digital Assets for or on behalf of anyone else. If you
						wish to change your address registered with us, we will ask you to
						provide information to verify your address change including such
						supporting documents as specified on the Website, or in our
						correspondence with you.
					</p>
				</div>
				<br />
				<div>
					<p>
						You are only permitted to hold one Account at a time on our Services.
						You agree not to establish more than one Account at any time and that
						we may remove Accounts we deem to be duplicates. In making such a
						determination, we may consider any personal information you provide to
						us, or which we have collected pursuant to our Privacy Policy. You
						agree to promptly provide us with such information and documents we may
						request from time-to-time, regarding your use of our Services. This may
						include the names of individuals you are sending or receiving Digital
						Assets to or from in connection with our Services, or as may otherwise
						be required for our compliance with applicable laws, rules and
						regulations.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ACCEPTABLE USE OF YOUR ACCOUNT
					</h1>
				</div>
				<br />
				<div>
					<p>
						You agree that:
					</p>
				</div>
				<br />
				<div>
					<div>
						<ul>
							<li>
								<p>
									You will not use the Website or Services in any way that
									intentionally or unintentionally violates any applicable
									local, state, provincial, national or international law,
									rule or regulation;
								</p>
							</li>
							<li>
								<p>
									Under no circumstances shall you buy, withdraw or transfer
									any Digital Assets for any illegal purpose, or in the
									furtherance of a crime;
								</p>
							</li>
							<li>
								<p>
									You will not send or receive Digital Assets to or from any
									individual, entity or country upon which the Government of
									Canada, the United States of America or the European Union
									has imposed sanctions, including but not limited to those
									listed under the legislation and related regulations
									referenced online at&nbsp;
									<a
										href="https://www.international.gc.ca/world-monde/international_relations-relations_internationales/sanctions/current-actuelles.aspx?lang=eng"
										target="_blank"
										rel="noreferrer noopener"
									>
										https://www.international.gc.ca
									</a>
									;
								</p>
							</li>
							<li>
								<p>
									You will not impersonate any person or entity or use false
									identities, documents or information on the Website,
									Services or in your interactions with us;
								</p>
							</li>
							<li>
								<p>
									You will not communicate with our staff or contractors in a
									way that is defamatory, harmful, hateful, harassing,
									bullying, threatening, racially or ethnically offensive or
									abusive;
								</p>
							</li>
							<li>
								<p>
									You will not translate, reverse engineer, decompile,
									disassemble, modify or create derivative works based on the
									Website or Services, in whole or in part;
								</p>
							</li>
							<li>
								<p>
									You will not circumvent, disable, violate or otherwise
									interfere with any security related feature of the Website
									or Services; and
								</p>
							</li>
							<li>
								<p>
									You will not use your Account for your own commercial
									purposes by sublicensing any rights granted by the Terms
									or, in any way, sharing the benefit of your Account with
									others.
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div>
						<p>
							If we reasonably believe you are using our Services for any illegal
							purpose, including but not limited to, in connection with
							facilitating transactions involving persons or entities who are
							under economic or other sanctions imposed by the Canadian or other
							governmental or international authority, we may terminate your
							Account and require that you liquidate your Digital Assets and
							withdraw any balance to the bank Account owned or controlled by
							you, or take such other reasonable measures to return your funds,
							such as, paying the funds into a court where you may claim them.
						</p>
					</div>
				</div>
				<br />
				<div>
					<h1>
						FUNDING AND WITHDRAWING FIAT FUNDS FROM YOUR ACCOUNT
					</h1>
				</div>
				<br />
				<div>
					<p>
						To buy Digital Assets, you will be required to fund your Account with
						Fiat currencies. All funds are held in a bank Account with an
						independent Financial Institution. Absent any fraud or wilful
						misconduct solely attributable to us, you agree that you bear all risk
						related to the banks with which your money is held, and the loss or
						theft thereof. We make no representations or warranties as to whether
						such deposits are insured by any Deposit Insurance Mechanism.
					</p>
				</div>
				<br />
				<div>
					<p>
						We offer various methods for funding your Account, which may change and
						be updated from time-to-time. Depending on the nature of your funding
						or withdrawal transaction, we may permit wire transfers and/or
						debit/credit card payment. We make no representations or warranties
						that deposit methods currently offered will continue to be available to
						you.
					</p>
				</div>
				<br />
				<div>
					<p>
						You agree that you are not permitted to fund your Account with funds or
						Digital Assets which you hold for or on behalf of any third-party. Nor
						are you permitted to fund your Account with Digital Assets or funds
						borrowed from a third-party. We may, at any time, ask you to (i)
						disclose the source of fiat funds being deposited in connection with
						the Services; and (ii) disclose the source and duly respond to any
						questions we pose concerning the source of any Digital Assets you wish
						to, or have deposited or withdrawn in connection with our Services. You
						agree that we may, in our sole discretion, deactivate your Account and
						no longer permit the use of our Services based on your responses (or
						lack thereof) and our policies, including for example, our Anti-Money
						Laundering Policy. We further reserve the right to not facilitate the
						sale or transfer of your Digital Assets via our Services without
						providing you any reason, justification or cause.
					</p>
				</div>
				<br />
				<div>
					<p>
						You are only permitted to use our Services, fund your Account and
						withdraw funds from your Account with a Bank account bearing the same
						legal name and address used to register your Account on the Services.
						You are not permitted to use a joint Bank account with our Services or
						in connection with any deposits or withdrawals. You agree that we may
						restrict funding methods and funding amounts for your Account.
					</p>
				</div>
				<br />
				<div>
					<h1>
						TAXES
					</h1>
				</div>
				<br />
				<div>
					<p>
						You acknowledge that buying and selling Digital Assets may give rise to
						taxable gains and/or taxable losses, which you are likely required to
						report for tax purposes. You are encouraged to obtain professional tax
						advice in connection with your tax filing obligations arising from the
						buying and selling of Digital Assets.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ACCEPTANCE OF RISK AND DISCLAIMERS
					</h1>
				</div>
				<br />
				<div>
					<p>
						Everything on our website and provided as part of our service offering
						is provided &quot;as is&quot; and “as available” without warranty of any kind
						including all implied warranties and conditions of merchantability and
						fitness for a particular purpose. we hereby disclaim all warranties and
						conditions of any kind, whether express, implied or statutory.
					</p>
				</div>
				<br />
				<div>
					<p>
						Before buying or selling digital assets you should obtain legal,
						financial and tax advice.
					</p>
				</div>
				<br />
				<div>
					<p>
						You agree that, while we strive to have error free and uninterrupted
						service, we do not guarantee the absence of interruptions nor shall you
						hold us liable for any damage such interruptions may cause. outages and
						downtime may occur for various reasons.
					</p>
				</div>
				<br />
				<div>
					<p>
						You acknowledge that buying and selling digital assets is risky. you
						should only purchase digital assets if you can afford to lose the
						entire amount of your purchase. you acknowledge there is a risk that
						the value of the digital assets decreases to zero.
					</p>
				</div>
				<br />
				<div>
					<p>
						We make no representations or warranties as to whether each custodian
						has insurance, or the types of insurance or coverage limits they may
						have in respect of your digital assets. Please contact the custodians
						directly with any questions.
					</p>
				</div>
				<br />
				<div>
					<p>
						You further acknowledge that there is a risk of malicious cyberattacks
						against our website and services, your account, your personal computing
						devices, smartphone and the third-party digital asset custodians which
						may result in the loss or theft of your funds, information and/or
						digital assets. If you do not wish to have your digital assets stored
						by the third-party custodian as referenced above, you should withdraw
						your digital assets to an external wallet which you control. you accept
						all risks associated with such withdrawals, including but not limited
						to the risks associated with losing your private keys and sending funds
						to an incorrect or invalid wallet address, or memo code, where they
						become forever inaccessible.
					</p>
				</div>
				<br />
				<div>
					<p>
						While these terms require that you only withdraw digital assets to
						external digital wallets you own and control, you accept all risks
						arising from transferring your digital assets to any third-party,
						including the risk that such third-party misappropriates your digital
						asset. You are responsible for conducting your own due diligence in
						respect of any third-parties you elect to send digital assets to. You
						further accept all risks associated with sending digital assets to
						fraudulent or otherwise illegal schemes.
					</p>
				</div>
				<br />
				<div>
					<p>
						Despite making various digital assets available for sale on our
						services, we make no representations or warranties in respect of such
						digital assets, or that they have, or will have any specific utility,
						functionality or features.
					</p>
				</div>
				<br />
				<div>
					<p>
						You acknowledge that we are not able to confirm the identity of the
						owners of any external digital wallet addresses for any digital asset.
						You accept all risks with any transfer, or attempted transfer, of
						digital assets to any third-party. As digital asset transactions on the
						blockchain cannot be reversed by us, it is your responsibility to
						ensure the accuracy of any wallet addresses you specify for
						withdrawals.
					</p>
				</div>
				<br />
				<div>
					<p>
						You acknowledge that the underlying blockchain technology of the
						digital assets may undergo a divergence, resulting in the formation of
						new crypto currencies (a “fork”). A fork may impact the value,
						functionality and other characteristics of the digital assets. We make
						no representation or warranty as to whether we will support a fork
						arising from any of the digital assets. You agree that we shall not be
						responsible or liable for claiming, issuing, storing or holding any
						crypto currencies resulting from a fork for or on your behalf.
					</p>
				</div>
				<br />
				<div>
					<p>
						You acknowledge and agree that we may elect not to support a fork
						arising from any of the digital assets and that in such circumstances,
						you may not be able to claim any new crypto currencies resulting from a
						fork. You should not store your digital assets with us if you wish to
						ensure ownership of any new digital assets arising from a fork.
					</p>
				</div>
				<br />
				<div>
					<p>
						You agree and acknowledge that we may suspend access to your account,
						or account functions, such as buying, selling or transferring digital
						assets or fiat funds for any reason, including for example, scheduled
						maintenance, your failure to comply with these terms, security or fraud
						concerns, for regulatory reasons or in the event of a fork.
					</p>
				</div>
				<br />
				<div>
					<p>
						You agree that you are responsible for, and shall indemnify us in
						relation to, any and all chargebacks charged to us by any bank,
						financial institution or other payment provider. You acknowledge that
						these terms permit us, without any liability to you, to liquidate any
						digital assets in your account to satisfy any payments owing to us, or
						any negative balance in your account.
					</p>
				</div>
				<br />
				<div>
					<p>
						We make no representations or warranties as to the ongoing availability
						of our third-party service providers to process deposits or withdrawals
						to and from your account. In the event third parties we rely on no
						longer offer or facilitate deposit or withdrawal services, you may not
						be able to buy or transfer digital assets or fund your account. In such
						circumstances, you will likely only be able to transfer your digital
						assets to another digital asset wallet address not associated with our
						services or a custodian.
					</p>
				</div>
				<br />
				<div>
					<p>
						We reserve the right to provide your personal information uploaded or
						collected by our website or services, or otherwise provided to us, to
						third parties if required by law, and to cooperate with law enforcement
						authorities in the investigation of any criminal or civil matter. Such
						personal information may include, but is not limited to, the
						information described in these terms and our privacy policy, including
						your name, bank account information and transaction history.
					</p>
				</div>
				<br />
				<div>
					<p>
						If we are required by law to make any disclosure of your personal
						information, we may (but are not obligated to) provide you with written
						notice (to the extent permitted by law) prior to such disclosure so
						that you may take appropriate action.
					</p>
				</div>
				<br />
				<div>
					<p>
						We also operate the website and services in conjunction with our
						independent contractors. Our independent contractors may have access to
						your personal information in providing services to us, or providing you
						with access to the website and services. We may use a variety of
						service providers, employees and servers in locations both inside and
						outside of Canada, which may make your personal information the subject
						of foreign laws and foreign legal proceedings.
					</p>
				</div>
				<br />
				<div>
					<p>
						To the fullest extent permitted by law, without limiting the generality
						of this section, or any other section limiting our liability, you agree
						that we shall not be responsible for the acts or omissions, including
						the negligent acts or omissions, of our independent contractors or
						third-party service providers.
					</p>
				</div>
				<br />
				<div>
					<h1>
						LIMITATION OF OUR LIABILITY
					</h1>
				</div>
				<br />
				<div>
					<p>
						You agree that, to the fullest extent permitted by law, in no event
						will we, our affiliates, officers, directors, shareholders, employees,
						contractors, agents or suppliers be liable, howsoever caused (including
						by negligence or otherwise), for any direct, indirect, special,
						incidental, punitive, exemplary or consequential damages you suffer,
						whether or not we have been warned of the possibility of such damages
						and whether those damages were foreseeable or not.
					</p>
				</div>
				<br />
				<div>
					<p>
						If you are dissatisfied with the website or services, or do not agree
						with any part of these terms, or have any other dispute or claim with
						or against us, our affiliates, officers, directors, shareholders,
						employees, contractors, agents, suppliers or licensors, then your sole
						and exclusive remedy is to withdraw your digital assets and discontinue
						accessing and using the services.
					</p>
				</div>
				<br />
				<div>
					<div>
						<p>
							In addition to and separate from your agreement to not hold the
							above entities and persons liable for any damages, in the event a
							court or arbitrator of competent jurisdiction declines to uphold
							said clause, you agree that:
						</p>
					</div>
					<div>
					</div>
					<div>
						<ul>
							<li>
								<p>
									In no circumstances shall the aggregate liability for any
									and all claims relating to or in any way arising from the
									use of our website or services, or in any way related to
									these terms, be more than the amounts paid by you to us as
									fees, if any and if implemented, or which we have earned as
									a result of your account.
								</p>
							</li>
							<li>
								<p>
									In no circumstances shall we be liable or responsible for
									any loss of profits or loss of expected revenue or gains.
								</p>
							</li>
							<li>
								<p>
									In no circumstances shall we be liable or responsible for
									any damages you suffer arising from the loss of an
									opportunity, including for example, the loss of an
									opportunity to buy, sell or profit from a digital asset.
								</p>
							</li>
							<li>
								<p>
									In no circumstances shall we be liable or responsible for
									any loss or damage whatsoever which does not arise directly
									as a result of our breach of this agreement (whether or not
									you are able to prove such loss or damage).
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div>
						<p>
							You agree and acknowledge that we would not enter into this
							agreement or grant access to the website or services without the
							above restrictions and limitations on our liability.
						</p>
					</div>
					<br />
					<div>
						<h1>
							INDEMNIFICATION AND HOLD HARMLESS
						</h1>
					</div>
					<br />
					<div>
						<p>
							You agree to indemnify us, our affiliates, employees, shareholders,
							directors, agents, representatives and contractors, and to defend
							and hold each of them harmless, from any and all claims and
							liabilities (including reasonable legal fees) which may arise from,
							(i) your violation of these terms, or any policy incorporated by
							reference; (ii) your violation of any third party right; (iii) any
							breach of a representation or warranty made by you to us, either in
							these terms, privacy policy or otherwise; (iv) any breach by you of
							applicable laws, rules or regulations; or (v) any claim for damages
							brought against us by the custodian or their affiliates, or any
							bank or financial institution, related to or arising from your
							account or your use of the services.
						</p>
					</div>
				</div>
				<br />
				<div>
					<p>
						Without limiting the above limitations on our liability in any way, you
						agree that we shall not be liable or responsible to you for the loss or
						theft of any digital assets caused or contributed to by a custodian,
						whether such loss or theft was caused, in whole or in part, by their
						negligence, fraudulent misconduct or otherwise.
					</p>
				</div>
				<br />
				<div>
					<h1>
						DISPUTES, GOVERNING LAW AND JURISDICTION
					</h1>
				</div>
				<br />
				<div>
					<p>
						These Terms, all documents incorporated by reference and your
						relationship with us shall be governed by, construed and enforced in
						accordance with the laws of the Province of British Columbia, Canada,
						and any Canadian federal laws applicable therein.
					</p>
				</div>
				<br />
				<div>
					<p>
						In respect of a claim by either you or us arising out of or in any way
						related to these Terms, all documents incorporated by reference and
						your relationship with us, you agree to irrevocably and unconditionally
						submit to the exclusive jurisdiction of the courts and tribunals of
						British Columbia, Canada (including the Federal courts and tribunals as
						applicable) for the settlement thereof.
					</p>
				</div>
				<br />
				<div>
					<h1>
						SEVERABILITY
					</h1>
				</div>
				<br />
				<div>
					<p>
						If any provision of these Terms is found to be unlawful, void, or for
						any reason unenforceable, then that provision shall be deemed severable
						from this agreement and shall not affect the validity and
						enforceability of any remaining provisions.
					</p>
				</div>
				<br />
				<div>
					<p>
						Where a provision in these Terms is found to be unlawful, void or for
						any reason unenforceable, a lawful or enforceable term which best
						reflects the intention of the provision, as originally drafted, shall
						substitute at the discretion of the court.
					</p>
				</div>
				<br />
				<div>
					<h2>
						No Interpretation Against Us
					</h2>
				</div>
				<br />
				<div>
					<p>
						If an ambiguity or question of intent arises with respect to any
						provision of these Terms, the Terms shall be construed as if drafted
						jointly by the parties and no presumption or burden of proof will arise
						favoring or disfavoring either party by virtue of authorship of any of
						the provisions of these Terms.
					</p>
				</div>
				<br />
				<div>
					<h2>
						Waiver of Class Proceedings and Trial by Jury
					</h2>
				</div>
				<br />
				<div>
					<p>
						To the extent permitted by law, you hereby waive your right to
						participate in any class action lawsuits against us, our contractors,
						employees, shareholders, successors, assigns and directors.
					</p>
				</div>
				<br />
				<div>
					<p>
						To the extent permitted by law, you further waive any right to a trial
						by jury, should such a right exist, in relation to any legal dispute
						connected to or in any way arising out of these Terms.
					</p>
				</div>
				<br />
				<div>
					<h1>
						RISKS YOU ASSUME
					</h1>
				</div>
				<br />
				<div>
					<p>
						There are many risks associated with Digital Currency, some of which
						are independent of the Services (and that are present in other Digital
						Currency business models), for which we will not be responsible and
						will not have any liability for, including the following Risks:
					</p>
				</div>
				<br />
				<div>
					<p>
						(i) Blockchain transactions are final, irreversible and irrecuperable.
						You must be careful not to transfer Digital Currency from your personal
						wallet to other external wallets, untrustworthy businesses or to people
						you do not personally know.
					</p>
				</div>
				<br />
				<div>
					<p>
						(ii) You are responsible for ensuring that any Digital Currency or
						money transfers that you conduct are with a trustworthy person or
						business. Any Digital Currency transfer that you perform is final,
						irreversible and irrecuperable. You are responsible for ensuring that
						any Digital Currency or money transfers that you conduct are with the
						correct person or business.
					</p>
				</div>
				<br />
				<div>
					<p>
						(iii) You are responsible for securing your device, email account,
						phone number, and other methods of communication that you use with our
						Services.
					</p>
				</div>
				<br />
				<div>
					<p>
						(iv) There are some Digital Currencies that have address formats that
						overlap (e.g. Bitcoin and certain Bitcoin forks). If you send a
						different Digital Currency to a wallet address not intended for that
						Digital Currency, then it will result in a permanent loss because the
						other Digital Currency is not supported. This rule also applies to
						different blockchain architecture including but not limited to, sending
						on the BEP blockchain or Layer 2 Network. Always take care when
						conducting Digital Currency transactions, and if you are in doubt, you
						should contact our customer support to receive technical support about
						blockchains and address formats.
					</p>
				</div>
				<br />
				<div>
					<p>
						(v) At peak times, or during a period of instability in a blockchain
						network, it may not be possible to move your Digital Currency to
						private keys that are personal to you as fast as it usually is.
					</p>
				</div>
				<br />
				<div>
					<p>
						(vi) We attempt to provide support for popular Digital Currencies (e.g.
						Bitcoin, Ethereum and USDC) but do not guarantee that it will continue
						to support any particular Digital Currency. In the event that we are
						forced to disable or remove a Digital Currency we will attempt to
						provide notice in advance.
					</p>
				</div>
				<br />
				<div>
					<p>
						(vii) It is possible that you may experience a technical error while
						using the Services. If you experience such an error you should report
						the anomaly to customer support rather than taking action. We endeavour
						to fix all errors on a timely basis but will not accept responsibility
						for your actions based on user interface errors.
					</p>
				</div>
				<br />
				<div>
					<p>
						(viii) Blockchain developers occasionally develop new blockchains that
						split off from the current version (a &quot;Fork&quot;) and it is likely that we
						will not support the Fork. To help mitigate this risk, you should move
						your Digital Currency to private keys that are personal to you, in
						advance of the Fork launching. Moving your Digital Currency to private
						keys that are personal to you will allow you to use and interact with
						any alternative Fork version of a Digital Currency that we do not
						support.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ACCOUNT FREEZES
					</h1>
				</div>
				<br />
				<div>
					<p>
						We are subject to stringent requirements, including anti-money
						laundering laws. As part of our requirements under applicable laws, it
						might be necessary for us to temporarily pause or permanently terminate
						activities involving your Account.
					</p>
				</div>
				<br />
				<div>
					<p>
						We may place a freeze on your Account (“Account Freeze”) if we are
						required to do so as part of our regulatory requirements (e.g. FINTRAC
						requirements), if we believe your Account or your use of the Services
						does not comply with this agreement, if we believe your Account or your
						use of the Services exposes us, yourself, another user or third party
						to excessive financial, security, legal, reputational or any other
						risk, or if we are required to do so as part of any other legal
						requirement. We may communicate with you to resolve any Account Freeze,
						which may include requesting documentation or further information (to
						the extent that we are permitted by law to do so). If we do not
						communicate with you and you realize your Account has been frozen, it
						is your responsibility to reach out to our support team.
					</p>
				</div>
				<br />
				<div>
					<p>
						In some circumstances, we may continue an Account Freeze for days or
						weeks, and ultimately may be required to terminate your Account and
						return your assets to you if we suspect that activities associated with
						your Account may be contrary to Canadian law, or damaging to Us or
						another person. We reserve the right to impose an Account Freeze or
						terminate your Account in our sole discretion and your sole remedy for
						such an Account Freeze or termination shall be the return of your
						assets, subject to any law that requires us to do otherwise.
					</p>
				</div>

			</Segment>
		</Container >
	);
}

export default TermsOLAMIT;
