import AssignCard from '@/components/Business/AssignCard';
import { connect, subscribe, unsubscribe } from '@/features/card/slice';
import { getActiveEmployees, linkCard } from '@/features/employee/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { UserType } from '@/features/user/types';
import { RootState } from '@/rootReducer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Button, Container, Divider, Input, List, Segment } from 'semantic-ui-react';
import AccountsPlaceholder from '../Shared/AccountsPlaceholder';
import BusinessCardDetails from './BusinessCardDetails';
import { Card } from '@features/card/types';
import { useTranslation } from 'react-i18next';

const BusinessCards = (): React.ReactElement => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { path } = useRouteMatch();
	const [type] = useState<string>('card');
	const [requiredTier, setRequiredTier] = useState<number>(1);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);
	const [accountsLoading, setAccountsLoading] = useState<boolean>(true);
	const [assignCard, setAssignCard] = useState<boolean>(false);
	const [cards, setCards] = useState<Card[]>();
	const [searchValue, setSearchValue] = useState('');
	const { t } = useTranslation('translations');

	const { loading, list, error } = useSelector(
		(state: RootState) => state.cards
	);

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { activeEmployees } = useSelector(
		(state: RootState) => state.employee
	);

	const fetchActiveEmployees = async () => {
		try {
			await dispatch(getActiveEmployees());
		} catch (e) {
			showErrorNotification(e);
		}
	};

	const create = () => {
		history.push(`/wallet/create/${type}?from=` + path);
	};

	const assign = () => {
		setAssignCard(true);
	};

	const closeAssignCard = () => {
		setAssignCard(false);
	};

	const onCardLink = (values?) => {
		const link = async () => {
			try {
				await linkCard(values.employeeId, values.cardId);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		link();
		showSuccessNotification(t('swal.CARD_LINKED'));
		setAssignCard(false);
	};

	const onSearch = e => {
		const value = e.target.value;
		const filtered = list.filter(card => card.name.toLowerCase().includes(value.toLowerCase()));
		setSearchValue(value);
		setCards(filtered);
	};

	const onClear = async () => {
		setSearchValue('');
		setCards(list);
	};

	useEffect(() => {
		fetchActiveEmployees();
	}, [dispatch]);

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);

	useEffect(() => {
		if (!user) return;
		const reqTier = user.type === UserType.INDIVIDUAL || user.type === UserType.EMPLOYEE ? 1 : 2;
		setRequiredTier(reqTier);
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		setKycTierVerified((kycTier >= requiredTier) || isKycTierRevoked);
		setAccountsLoading(!!user && loading);
	}, [user, requiredTier, loading]);

	useEffect(() => {
		setCards(list);
	}, [list]);

	if (accountsLoading || !user) return <AccountsPlaceholder
		loadingMessage='Loading cards'
		type={type} />;
	if (error) return <div>Error: {error}</div>;

	if (!kycTierVerified && user.onBehalfOf === null) {
		return <AccountsPlaceholder type={type} kyc={true} />;
	}

	if (assignCard) { return <AssignCard onSend={onCardLink} onBackClick={closeAssignCard} employees={activeEmployees} employee={null}></AssignCard>; }

	if ((!list || !list.length)) return <AccountsPlaceholder type={type} />;

	return (
		<Container id='businessCards'>
			<Segment padded>
				<div className='businessCardBTNs'>
					<Button onClick={create} className='newCardBTN' primary content={t('business.card.new')} />
					<Button onClick={() => assign()} primary basic content={t('business.card.assign')} />
					<Button primary basic content={t('business.card.bulk')} as={Link} to={'/wallet/transfers/bulk/top-up'}/>
					<Input  placeholder={t('business.card.search')} value={searchValue} onChange={onSearch} action={{ icon: 'times', onClick: () => onClear() }}/>
				</div>
				<Divider />
				<List size="large" relaxed="very" divided verticalAlign='middle'>
					{cards && cards.map(card => <List.Item key={card.cardId}><BusinessCardDetails card={card}/></List.Item>)}
				</List>
			</Segment>
		</Container>);
};

export default BusinessCards;
