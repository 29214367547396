import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import AddressManagement from '@/components/Crypto/AdressManagement';
import MyContacts from '@/components/Dashboard/MyContacts';

const Contacts = (): React.ReactElement => {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={ `${ path }/account` }>
				<MyContacts/>
			</Route>
			<Route path={ `${ path }/crypto` }>
				<AddressManagement/>
			</Route>
			<Redirect exact from={ path } to={`${path}/account`}/>
		</Switch>);
};

export default Contacts;
