import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import { TwoFAPayload } from './types';
import request from '../../services/request';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

const initialState = {
	open: false,
	message: null
};

let originalRequest = null;
let error = null;
let callbacks = [];

const twoFASlice = createSlice({
	name: 'swal',
	initialState,
	reducers: {
		open2FAModal(state) {
			state.open = true;
		},
		close2FAModal(state) {
			state.open = false;
			originalRequest = null;
			callbacks = [];
		},
		set2FAMessage(state, action:PayloadAction<string>) {
			state.message = action.payload;
		}
	}
});

export const { open2FAModal, close2FAModal, set2FAMessage } = twoFASlice.actions;


export const request2FA = (payload: any, resolve_cb: any, reject_cb: any, parent_error: any) => {
	originalRequest = payload;
	error = parent_error;

	if(callbacks.length > 0) {
		const previous_callbacks = callbacks.slice(-1);
		callbacks.push({
			reject_cb: previous_callbacks[0].reject_cb(reject_cb),
			resolve_cb: previous_callbacks[0].resolve_cb(resolve_cb)
		});
	} else {
		callbacks.push({
			reject_cb: reject_cb,
			resolve_cb: resolve_cb
		});
	}
};

const refreshRecaptchaToken = async (payloadData: any) => {
	if ('recaptchaToken' in payloadData) {
		const token = await getRecaptureToken();
		payloadData['recaptchaToken'] = token;
	}
	return payloadData;
};

export const next = (authenticatorCode: string): AppThunk => {
	return async dispatch => {
		const payload = originalRequest;
		const payloadData = JSON.parse(payload.data);
		payloadData.authenticatorCode = authenticatorCode;
		await refreshRecaptchaToken(payloadData);
		payload.data = JSON.stringify(payloadData);
		const cb = callbacks.slice(-1);
		const { resolve_cb, reject_cb } = cb[0];
		callbacks.pop();
		try {
			resolve_cb(await request(payload));
		} catch (e) {
			reject_cb(e);
		}
		dispatch(close2FAModal());
	};
};

export const reject2FA = (): AppThunk => {
	return async dispatch => {
		const cb = callbacks.slice(-1);
		const { reject_cb } = cb[0];
		reject_cb(error);
		dispatch(close2FAModal());
	};
};

export const twoFASelector = (state: { statusStore: TwoFAPayload }) =>
	state.statusStore;

export default twoFASlice.reducer;
