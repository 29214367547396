import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { parse, isDate, isValid } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { isValidPhoneNumber } from 'react-phone-number-input';

function parseDateString(value, originalValue) {
	if (isDate(originalValue)) {
		return originalValue;
	}
	if (isValid(parse(originalValue, 'yyyy-MM-dd', new Date()))) {
		return parse(originalValue, 'yyyy-MM-dd', new Date());
	}
	return parse(originalValue, 'dd/MM/yyyy', new Date());
}

const schema = Yup.object().shape({
	passwordLength: Yup.string().min(8, 'len'),
	passwordUpper: Yup.string().matches(
		/^(?=.*[a-z])(?=.*[A-Z])/,
		'upper'
	),
	passwordSpecial: Yup.string().matches(
		/^(?=.*[0-9])(?=.*[~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?/])/,
		'spec'
	)
});

export const usePasswordValidation = () => {
	const [errors, setErrors] = useState<Array<string>>([]);

	const validatePassword = async (value: string) => {
		const error = [];
		try {
			await schema.validate({ passwordLength: value });
		}
		catch (err) {
			error.push('len');
		}
		try {
			await schema.validate({ passwordUpper: value });
		}
		catch (err) {
			error.push('upper');
		}
		try {
			await schema.validate({ passwordSpecial: value });
		}
		catch (err) {
			error.push('spec');
		}
		setErrors(error);
	};

	return [errors, validatePassword] as const;
};

export const useValidator = (type) => {
	const { t } = useTranslation('translations');
	const [individual, setIndividual] = useState<Yup.AnySchema>(null);
	const [businnes, setBusinnes] = useState<Yup.AnySchema>(null);
	const [freelancer, setFreelancer] = useState<Yup.AnySchema>(null);
	const { invitationEnabled } = useSelector(
		(state: RootState) => state.status
	);
	const individualSchema = {
		firstName:
			Yup.string()
				.min(2, t('form.validator.firstnamemin'))
				.max(64, t('form.validator.firstnamemax'))
				.required(t('form.validator.required'))
				.matches(/^[A-Za-z\s]+$/, t('form.validator.latinOnly')), 
		lastName:
			Yup.string()
				.min(2, t('form.validator.lastnamemin'))
				.max(64, t('form.validator.lastnamemax'))
				.required(t('form.validator.required'))
				.matches(/^[A-Za-z\s]+$/, t('form.validator.latinOnly')),
		email:
			Yup.string()
				.email(t('form.validator.email'))
				.required(t('form.validator.required')),
		password:
			Yup.string()
				.required(t('form.validator.required')),
		passwordConfirm: //TODO matching
			Yup.string()
				.required(t('form.validator.required'))
				.oneOf([Yup.ref('password'), null], t('form.validator.confirmpassword')),
		country:
			Yup.string()
				.required(t('form.validator.required'))
				.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		phoneNumber:
			Yup.string()
				.matches(/^([+]|[1-9])[0-9]+$/, t('form.validator.phoneinvalid'))
				.min(4, t('form.validator.phonemin'))
				.test('phoneNumber',
					t('form.validator.phoneinvalid'),
					(value) => isValidPhoneNumber(`+${value}`))
				.required(t('form.validator.required')),
		birthDate:
			Yup.date()
				.transform(parseDateString)
				.max(new Date(), t('form.validator.birthdate'))
				.min(new Date('1900-01-01'), t('form.validator.birthdate'))
				.required(t('form.validator.required')),
	};

	const invitationCodeScheme = {
		invitationCode: Yup.string()
			.required(t('form.validator.required')),
	};

	const businnesSchema = {
		companyName: Yup.string()
			.min(2, t('form.validator.companymin'))
			.max(256, t('form.validator.companymax'))
			.required(t('form.validator.required')),
		companyRegNumber: Yup.string()
			.min(2, t('form.validator.companymin'))
			.max(256, t('form.validator.companymax'))
			.required(t('form.validator.required')),
		companyAddress: Yup.string()
			.min(2, t('form.validator.companymin'))
			.max(256, t('form.validator.companymax'))
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		companyCountry: Yup.string()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin'))
	};

	useEffect(() => {
		if (invitationEnabled) {
			setIndividual(Yup.object({ ...individualSchema, ...invitationCodeScheme }));
		} else {
			setIndividual(Yup.object(individualSchema));
		}
		setBusinnes(Yup.object({ ...individualSchema, ...businnesSchema }));
		setFreelancer(Yup.object(individualSchema));
	}, [type]);

	switch (type) {
		case 'individual':
			return individual;
		case 'businnes':
			return businnes;
		case 'freelancer':
			return freelancer;
		default: return Yup.object({});
	}

};
