import { useField } from 'formik';
import { Dropdown } from 'semantic-ui-react';
import React from 'react';
import './FormikDropdown.css';

interface DropdownProps {
	name: string,
	options: {
		key: string,
		text: string,
		value: string
	}[],
	onSearchChange?: (e: any, data: any) => void
	defaultValue?: string,
	placeholder?: string,
	loading?: boolean,
	fluid?: boolean,
	label?: string,
	labelId?: string
}

const FormikDropdown = ({ name, options, onSearchChange, defaultValue, loading, placeholder, fluid, label, labelId }: DropdownProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);
	const { setValue } = helpers;
	return (
		<>
			{label && <label id={labelId ? labelId : 'formik-label'}>{label}</label>}
			<Dropdown search selection fluid={fluid} onChange={ (e, v) => setValue(v.value) } options={ options }
				onSearchChange={ onSearchChange } placeholder={ placeholder ? placeholder : ''}
				defaultValue={ defaultValue } loading={ loading }/>
		</>

	);
};


export default FormikDropdown;
