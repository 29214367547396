import { format, parse } from 'date-fns';
const dateFormat = 'dd MMM yy hh:mm:ss';

export const convertDate = (originalDate: Date, pattern: string): string => {
    const date = new Date(originalDate);
    return format(date, pattern);
};

export const formatDate = (originalDate: string, originalFormat?: string): string => {
    if (originalFormat) {
        return format(parse(originalDate, originalFormat, new Date()), dateFormat);
    }
    const date = new Date(originalDate);
    return format(date, dateFormat);
};

export const formatDateWithPattern = (originalDate: string, dateFormat: string, originalFormat?: string): string => {
    if (originalFormat) {
        return format(parse(originalDate, originalFormat, new Date()), dateFormat);
    }
    const date = new Date(originalDate);
    return format(date, dateFormat);
};

export const formatDateWithPatternIgnoreTimezone = (originalDate: string, dateFormat: string): string => {
    
    const date = new Date(originalDate);
    const timezoneOffset = date.getTimezoneOffset() * 60000;
    return format(new Date(date.getTime() + timezoneOffset), dateFormat);
};


//Formats to DD/MM/YYYY, HH:MM:SS ex: 06/10/2020, 15:55:00
export const formatSimpleDateTime = (originalDate: string): string => {
    const dateOptions = { year: 'numeric', month: 'numeric', day: 'numeric' } as const;
    return new Date(originalDate).toLocaleTimeString('en-GB', dateOptions);
};
