import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Divider, Dropdown, Grid, Header, Loader, Modal } from 'semantic-ui-react';
import { showErrorNotification } from '@features/swal/slice';
import { editBulkTopUpEntry } from '@features/card/slice';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { Formik, useField } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@helpers/globalTypes';
import { BulkEntry } from '@features/card/types';

interface Props {
	detail: BulkEntry,
	bulkId: number,
	onClose: (boolean) => void,
	isOpen: boolean,
	reFetch: () => void,
	employees: any
}

interface DropdownProps {
	name: string,
	options: {
		key: string,
		text: string,
		value: string
	}[],
	defaultValue?: number
}

const FormikDropdown = ({ name, options, defaultValue }: DropdownProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { t } = useTranslation('translations');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);
	const { setValue } = helpers;
	return (
		<Dropdown fluid selection onChange={ (e, v) => setValue(v.value) } options={ options }
			defaultValue={defaultValue}
			placeholder={'Select card'}/>
	);
};

const EditBulkTopUpEntryModal = ({ detail, bulkId, onClose, isOpen, reFetch, employees }: Props): React.ReactElement => {
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation(['translations']);
	const [cardOptions, setCardOptions] = useState([]);
	const [defaultCard, setDefaultCard] = useState(null);
	const [employeeReference, setEmployeeReference] = useState(detail.employeeRef);
	const { status } = useSelector(
		(state: RootState) => state.user
	);
	const validationSchema = Yup.object({
		amount: Yup.string()
			.required(t('form.validator.required')),
		employeeRef: Yup.string()
			.required(t('form.validator.required')),
		cards: Yup.string()
			.required(t('form.validator.required')),
	});

	const getReferenceLabel = () => {
		return status.source === Agents.DTSCB ? 'Employee number' : 'Employee code';
	};

	useEffect(() => {
		const { cardSelection } = detail;
		const { selectedCardId, cardName } = detail;
		if(cardSelection.length > 0) {
			const cards = [];
			cardSelection.forEach(
				card => cards.push({
					key: card.id,
					value: card.id,
					text: card.cardName
				})
			);
			setCardOptions(cards);
		} else {
			const cards = [{
				key: selectedCardId,
				value: selectedCardId,
				text: cardName
			}];
			setCardOptions(cards);
			setDefaultCard(selectedCardId);
		}
	}, [detail]);

	useEffect(() => {
		const employee = employees.find(employee => ((employee.referenceCode === employeeReference) || (employee.employeeNumber === employeeReference)));
		if(employee) {
			const { linkedCards } = employee;
			const cardOptions = [];
			linkedCards.forEach( linkedCard => cardOptions.push({
				key: linkedCard.cardId,
				value: linkedCard.cardId,
				text: linkedCard.name
			}));
			setCardOptions(cardOptions);
		} else {
			setCardOptions([]);
		}
	}, [employeeReference]);

	const updateEntry = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { amount, employeeRef, cards } = formData;

		const payload = {
			amount: amount,
			employeeRef: employeeRef,
			selectedCardId: cards
		};

		try {
			setLoading(true);
			await editBulkTopUpEntry(detail.id, bulkId, payload);
			reFetch();
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
			setLoading(false);
			onClose(false);
		}
	};

	return (
		<Modal
			id='editBulkTopUpEntryModal'
			onClose={() => onClose(false)}
			open={isOpen}
			size='tiny'
		>
			<Modal.Content>
				<Dimmer active={loading} >
					<Loader inverted>Loading</Loader>
				</Dimmer>
				<Header>Edit</Header>
				<Formik
					initialValues={{
						amount: detail.amount ? detail.amount : '',
						employeeRef: detail.employeeRef ? detail.employeeRef : '',
						card: detail.selectedCardId ? detail.selectedCardId : '',
					}}
					validationSchema={validationSchema}
					onSubmit={updateEntry}
				>
					{({ setFieldValue, errors, isSubmitting }) => (
						<Form
							size="large">
							<Input
								fluid
								name="amount"
								label='Amount'
								type='number'
								errorPrompt
							/>
							<Input
								fluid
								disabled
								name="employeeName"
								label='Employee name'
								value={detail.employeeName}
								errorPrompt
							/>
							<Input
								fluid
								name="employeeRef"
								label={getReferenceLabel()}
								onChange={e => {
									setEmployeeReference(e.currentTarget.value);
									setFieldValue('employeeRef', e.currentTarget.value);
								}}
								errorPrompt
							/>
							<label>Card</label><br/>
							<FormikDropdown name={'cards'} options={cardOptions} defaultValue={defaultCard}/>
							<Divider section />
							<Grid columns={'equal'}>
								<Grid.Column>
									<SubmitButton
										fluid
										primary
										disabled={isSubmitting || !isEmpty(errors)}
										type="submit">
										Save
									</SubmitButton>
								</Grid.Column>
								<Grid.Column>
									<Button fluid onClick={() => onClose(false)} basic content='Cancel' />
								</Grid.Column>
							</Grid>
						</Form>)}
				</Formik>
			</Modal.Content>
		</Modal>
	);
};

export default EditBulkTopUpEntryModal;
