import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react';
import './VaultsList.css';
import { RootState } from '@/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { setVault } from '@features/tabs/slice';
import Header from './Header';
import { DepositStatus, Vault } from '@/features/earn/types';
import { Button, Grid, Table, Header as SHeader, Segment, Dropdown, Divider, List, Message, Icon } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import Loading from '@/components/Shared/Loading';
import DepositVaultModal from './DepositVaultModal';
import { showErrorNotification } from '@/features/swal/slice';
import { getVaults } from '@/features/earn/slice';
import { useTranslation } from 'react-i18next';
import { formatDate, formatDateWithPattern } from '@/helpers/date';

interface AccountsProps {
	vaults: Array<Vault>,
}

const VaultsList = ({ vaults }: AccountsProps): React.ReactElement => {
	const dispatch = useDispatch();
	const [width, setWidth] = useState<number>(null);
	const { currentVault } = useSelector(
		(state: RootState) => state.tabs
	);

	useLayoutEffect(() => {
		function updateSize() {
			setWidth(document.querySelector<HTMLElement>('#accounts-tabs').offsetWidth);
		}
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);

	useEffect(() => {
		setWidth(document.querySelector<HTMLElement>('#accounts-tabs').offsetWidth);
		if (!currentVault) {
			dispatch(setVault(vaults[0]));
			return;
		}

		const vault = vaults.find(vault => vault.proc === currentVault.proc);
		if (!vault && vaults.length > 0) {
			dispatch(setVault(vaults[0]));
		}
		if (!vault) return;
		dispatch(setVault(JSON.parse(JSON.stringify(vault))));
	}, [dispatch, vaults]);

	return <div id="accounts-tabs" style={{ width: '100%' }}>
		{width && <Header width={width} vaults={vaults} />}
		<div className="ui bottom attached segment active tab">
			<VaultDetails vault={currentVault} />
		</div>
	</div>;
};

interface VaultDetailsProps {
	vault: Vault,
}

const VaultDetails = ({ vault }: VaultDetailsProps) => {
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [deposits, setDeposits] = useState<any>(null);
	const [showDeposit, setShowDeposit] = useState<boolean>(false);
	const [dropdownRef] = useState<any>(React.createRef());
	const [, setMobileMenuOpen] = useState<boolean>(false);

	const { list } = useSelector(
		(state: RootState) => state.vaults
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getVaults());
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [dispatch]);

	useEffect(() => {
		if (!vault) return;
		setDeposits(vault.deposits);
	}, [vault]);

	const onCloseVaultModal = (update?: boolean) => {
		setShowDeposit(false);
		if (update) fetchData();
	};

	const statusIcon = (value: DepositStatus) => {
		let element;
		switch (value) {
			case DepositStatus.ACTIVE:
				element = <Icon className="approvalicon" name="clock outline" />;
				break;
			case DepositStatus.TERMINATED:
				element = <Icon className="rejectedicon" name="close" />;
				break;
			default:
				element = <Icon className="approvalicon" name="clock outline" />;
		}
		return element;
	};

	if (!vault) return <Loading />;

	return (
		<div>
			<DepositVaultModal onClose={onCloseVaultModal} vault={vault} open={showDeposit} />
			<Grid padded>


				<Grid.Row only="computer tablet">
					<Grid.Column width={11}>
						<Button onClick={() => setShowDeposit(true)} className="buttonmargin" primary content={t('vaults.depositButton')} icon='plus' labelPosition='left' />
					</Grid.Column>
				</Grid.Row>
				<Grid.Row only="mobile">
					<Grid.Column>
						<Segment className="myaccountsegment" >
							<Dropdown
								ref={dropdownRef}
								value={vault.proc}
								closeOnChange
								as={() => {
									return (<div style={{ width: '100%' }} id="mobile-account-dropdown" onClick={() => {
										setMobileMenuOpen(true);
									}}>
										<div>
											<div className="tab-name">{vault.name}</div>
											<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${vault.processProperties.currencySymbol ?? vault.processProperties.ccy} `} value={vault.totalBalance ?? '0.00'} /></div>
											<div className="tab-hold">
												<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={t('vaults.yearlyInterest')} value={parseFloat(vault.config.yearlyInterestRate) * 100} /></div>
										</div>
										<Dropdown fluid icon='chevron down' value={vault.proc}>
											<Dropdown.Menu fluid>
												{list.map(v => <Dropdown.Item fluid onClick={() => {
													setMobileMenuOpen(false);
													dispatch(setVault(v));
												}} key={v.proc}>
													<div style={{ width: '100%' }}>
														<div>
															<div className="tab-name">{v.name}</div>
															<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${v.processProperties.currencySymbol ?? vault.processProperties.ccy} `} value={v.totalBalance ?? '0.00'} /></div>
															<div className="tab-hold">
																<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={t('vaults.yearlyInterest')} value={parseFloat(v.config.yearlyInterestRate) * 100} /></div>
														</div>
													</div>
												</Dropdown.Item>)}
											</Dropdown.Menu>
										</Dropdown>
									</div>);
								}} >
							</Dropdown>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row only="mobile">
					<Button labelPosition="left" icon="plus circle" primary fluid content={t('vaults.depositButton')} onClick={() => setShowDeposit(true)} />
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={16}>
						<Message >
							<div><strong>{t('tokens.header.description')}:</strong></div>
							<br />
							<span className='display-linebreak' dangerouslySetInnerHTML={{ __html: vault.description }} />
						</Message>
					</Grid.Column>
				</Grid.Row>

				{deposits && deposits.length > 0 && <>
					<Grid.Row style={{ paddingBottom: '0.25rem' }}>
						<Grid.Column width={16}>
							<SHeader className="headerMargin">{t('vaults.yourDeposits')}</SHeader>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row only="computer tablet">
						<Grid.Column width={16}>
							<Table basic="very" padded="very" className='deposits'>
								<Table.Header>
									<Table.Row>
										<Table.HeaderCell></Table.HeaderCell>
										<Table.HeaderCell>{t('vaults.depositDate')}</Table.HeaderCell>
										<Table.HeaderCell>{t('vaults.interestRate')}</Table.HeaderCell>
										<Table.HeaderCell>{t('vaults.amount')}</Table.HeaderCell>
										<Table.HeaderCell>{t('vaults.repaymentDate')}</Table.HeaderCell>
										<Table.HeaderCell>{t('vaults.status')}</Table.HeaderCell>
									</Table.Row>
								</Table.Header>
								<Table.Body>
									{deposits.map(deposit => (
										<Table.Row key={deposit.accountId}>
											<Table.Cell>{t('vaults.depositLabel')}</Table.Cell>
											<Table.Cell>{formatDateWithPattern(deposit.createdDate, 'dd MMM yyyy')}</Table.Cell>
											<Table.Cell>{deposit.yearlyInterestRate * 100}%</Table.Cell>
											<Table.Cell><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${deposit.currencySymbol ?? deposit.currency} `} value={deposit.balance} /></Table.Cell>
											<Table.Cell>{formatDateWithPattern(deposit.repaymentDate, 'dd MMM yyyy')}</Table.Cell>
											<Table.Cell>{statusIcon(deposit.status)}{t('status.vaults.' + deposit.status)}</Table.Cell>
										</Table.Row>
									))}
								</Table.Body>
							</Table>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row style={{ paddingTop: '0.25rem' }} only="mobile">
						<Grid.Column >
							{deposits.map(deposit => (
								<List id="deposit-table" key={deposit.accountId}>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('vaults.depositDate')}:</List.Header>
											<List.Description>{formatDateWithPattern(deposit.createdDate, 'dd MMM yyyy')}</List.Description>
										</List.Content>
									</List.Item>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('vaults.interestRate')}:</List.Header>
											<List.Description>{deposit.yearlyInterestRate * 100}%</List.Description>
										</List.Content>
									</List.Item>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('vaults.amount')}:</List.Header>
											<List.Description>
												<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${deposit.currencySymbol ?? deposit.currency} `} value={deposit.balance} />
											</List.Description>
										</List.Content>
									</List.Item>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('vaults.repaymentDate')}:</List.Header>
											<List.Description>{formatDateWithPattern(deposit.repaymentDate, 'dd MMM yyyy')}</List.Description>
										</List.Content>
									</List.Item>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('vaults.status')}:</List.Header>
											<List.Description>{statusIcon(deposit.status)}{t('status.vaults.' + deposit.status)}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
								</List>
							))}
						</Grid.Column>
					</Grid.Row>
				</>}
			</Grid>
		</div>
	);
};

export default VaultsList;
