import { isVerifiedForPartner } from '@/features/identity/slice';
import { UserType } from '@/features/user/types';
import { CheckCircleIcon } from '@/icons';
import { RootState } from '@/rootReducer';
import { getNames } from 'i18n-iso-countries';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Segment } from 'semantic-ui-react';
import KYCForm from '../KYC/KYCForm';
import OpenBusinnes from '../Dashboard/MyAccount/Profile/OpenBusiness';
import { logout } from '@/features/user/userSlice';
import { showErrorNotification } from '@/features/swal/slice';


const Verification = () => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const [open, setOpen] = useState<boolean>(false);
	const [partnerStatus, setPartnerStatus] = useState<boolean>(false);
	const [requiredTier, setRequiredTier] = useState<number>(1);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);
	const [openBusinessModal, setOpenBusinessModal] = useState<boolean>(false);
	const [countries] = useState(getNames('en'));
	const dispatch = useDispatch();

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const getPartnerStatus = useCallback(() => {
		const get = async () => {
			const status = await isVerifiedForPartner();
			setPartnerStatus(status);
		};
		get();
	}, []);

	useEffect(() => {
		getPartnerStatus();
	}, [getPartnerStatus]);

	useEffect(() => {
		if (!user) return;
		//TODO: Need change required tier, when KYC will be implemented
		setRequiredTier(user.type === UserType.INDIVIDUAL ? 1 : 2);
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		setKycTierVerified((kycTier >= requiredTier) || isKycTierRevoked);
	}, [user, requiredTier]);


	const onKYCclick = (e) => {
		e.preventDefault();
		if (partnerStatus && user.type !== UserType.BUSINESS) {
			history.push('/wallet/transfer');
		} else {
			setOpen(true);
		}
	};

	const goToBusiness = async () => {
		try {
			await dispatch(logout());
			return history.push('/auth/login?mode=' + UserType.BUSINESS);
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	return (
		<Segment padded="very">
			<Grid>
				<Grid.Column textAlign="center">
					<Header>
						<CheckCircleIcon />
						{!kycTierVerified && <div>{t('kyc.header.verification')}</div>}
						{user && !user.hasBusiness && kycTierVerified && <div>{t('kyc.header.openBusiness')}</div>}
						{user && user.hasBusiness && kycTierVerified && <div>{t('kyc.header.switchToBusiness')}</div>}
					</Header>
					{!kycTierVerified &&
						<div>{t('kyc.label.verification')}
							<span>{t('kyc.link', { type: user && user.type === UserType.BUSINESS ? 'KYB' : 'KYC' })}</span>
						</div>}

					{user && !user.hasBusiness && kycTierVerified && <div>{t('kyc.label.openBusiness')}</div>}
					{user && user.hasBusiness && kycTierVerified && <div>{t('kyc.label.switchToBusiness')}</div>}
					<br />
					{!kycTierVerified && <Button primary onClick={onKYCclick}>{t('kyc.button.verify')}</Button>}
					{user && !user.hasBusiness && kycTierVerified && <Button primary onClick={() => setOpenBusinessModal(true)}>{t('profile.data.openBusiness')}</Button>}
					{user && user.hasBusiness && kycTierVerified && <Button primary onClick={goToBusiness}>{t('profile.data.switchBusiness')}</Button>}
				</Grid.Column>
			</Grid>
			{open && <KYCForm open={open} setOpen={setOpen} />}
			<OpenBusinnes countries={countries} open={openBusinessModal} onClose={() => { setOpenBusinessModal(false); }} />
		</Segment>
	);
};

export default Verification;
