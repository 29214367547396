import { AppThunk } from '@/store';
import request from '@services/request';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankAccount } from '@features/account/types';
import { SocketService } from '@/services/socketService';
import { Subscription } from 'rxjs';

const initialState = {
	loading: false,
	accounts: [],
	error: null,
};


const trustSlice = createSlice({
	name: 'trust',
	initialState,
	reducers: {
		setAccounts(state, action: PayloadAction<Array<BankAccount>>) {
			state.accounts = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		}
	}
});

export const { setAccounts, setLoading, setError } = trustSlice.actions;

export const getAccounts = (): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/accounts');
			const { data } = response;
			const trustAccounts = [];
			data.forEach(account => {
				if (account.providerType === 'TRUST') {
					trustAccounts.push(account);
				}
			});
			dispatch(setAccounts(trustAccounts));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

let socketService: SocketService;
let updateSubscriber: Subscription;

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('trust');
	}
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		updateSubscriber = socketService.listen('trust.account', {}).subscribe(() => {
			dispatch(getAccounts());
		});
	};
};

export const unsubscribe = (): void => {
	updateSubscriber.unsubscribe();
};

export default trustSlice.reducer;
