import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Icon, Item } from 'semantic-ui-react';
import { Ticket, Tickets, TicketType } from '../../../features/tickets/types';
import { convertDate } from '../../../helpers/date';

interface Props {
	message: Tickets,
	selectedTicket: Ticket,
	onClick: () => void
}

const MessageList = ({ message, selectedTicket, onClick }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const parseType = (type: TicketType) => {
		switch (type) {
		case TicketType.INCIDENT:
			return t('inbox.supportTicket');
		case TicketType.NOTIFICATION:
			return t('inbox.notification');
		case TicketType.TRANSFER_REQUEST:
			return t('inbox.paymentRequest');
		case TicketType.STOCK_ORDER:
			return t('inbox.stockOrder');
		default:
			return type;
		}
	};

	const getClassName = () => {
		if (!selectedTicket) return '';
		if (message.id === selectedTicket.id) {
			return 'selected';
		}
	};


	return (<div id="messages" className={getClassName()}>
		<Item.Group>
			<Item className="message">
				<Item.Content onClick={onClick} className="messageListContent">
					<Grid>
						<Grid.Column floated='left' width={14}>
							<Item.Description>{parseType(message.incidenttype)}</Item.Description>
							<Item.Description>
								{!message.isread && (
									<Icon className="unreadicon" size="small" name="circle"></Icon>
								)}
								{message.title}
							</Item.Description>
							<Item.Meta>{convertDate(message.createddate, 'dd MMM yyyy')}</Item.Meta>
						</Grid.Column>
						<Grid.Column floated='right' width={2}>
							<Icon className="chervonRightIcon" size="large" name="angle right"></Icon>
						</Grid.Column>
					</Grid>
				</Item.Content>
			</Item>
		</Item.Group>
		<Divider className={getClassName()} />
	</div>);
};



export default MessageList;
