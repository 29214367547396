import request from '@/services/request';
import { AppThunk } from '@/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { alpha3ToAlpha2 } from 'i18n-iso-countries';
import { CountryCodeStore } from './types';


const initialState: CountryCodeStore = {
	loading: false,
	list: [],
	error: null
};

const countrySlice = createSlice({
	name: 'allowedCountries',
	initialState,
	reducers: {
		setCountries(state, action: PayloadAction<Array<{ countryCode: string }>>) { 
			state.list = action.payload.map(p => alpha3ToAlpha2(p.countryCode))||[];
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {  
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => { 
			state.error = payload.toString();
		}
	}
});

export const { setCountries, setLoading, setError } = countrySlice.actions;

export const getAllowedCountries = (): AppThunk => {
	return async dispatch => {
		try { 
			const response = await request.get('/api/auth/countries'); 
			const { data } = response; 
			dispatch(setCountries(data));
			return data;
		} catch (e) {
			dispatch(setLoading(false)); 
			dispatch(setError(e.toString()));
		}
	};
};


export default countrySlice.reducer;
