import React from 'react';
import Login from './Auth/Login';
import Registration from './Auth/Registration';
import ForgotPassword from './Auth/ForgotPassword';
import ResetPassword from './Auth/ResetPassword';

import {
	Switch,
	Route,
	useRouteMatch
} from 'react-router-dom';

function Auth(): React.ReactElement {
	const { path } = useRouteMatch();
	return (
		<Switch>
			<Route path={`${path}/login`}>
				<Login />
			</Route>
			<Route path={`${path}/signup`}>
				<Registration />
			</Route>
			<Route path={`${path}/forgot-password`}>
				<ForgotPassword />
			</Route>
			<Route path={`${path}/reset-password/:code`}><ResetPassword /></Route>
			<Route component={Login} />
		</Switch>
	);
}

export default Auth;
