/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Subscription } from 'rxjs';
import request from '../../services/request';
import { SocketService } from '@services/socketService';
import { AppThunk } from '@/store';
import { PaginateParams, StockOrder, Ticket, TicketAttachment, Tickets, TicketsStore } from './types';
import { User, UserType } from '../user/types';

const initialState: TicketsStore = {
	loading: false,
	list: [],
	error: null,
	count: 0,
	totalPage: 0,
	pageSize: 10,
	unreadCount: 0,
	updateMessage: null,
	updateMessages: false,
	ticket: null
};


const slice = createSlice({
	name: 'tickets',
	initialState,
	reducers: {
		setTickets(state, action: PayloadAction<Array<Tickets>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		setCount: (state, { payload }: PayloadAction<number>) => {
			state.count = payload;
		},
		setTotalPage: (state, { payload }: PayloadAction<number>) => {
			state.totalPage = payload;
		},
		setPageSize: (state, { payload }: PayloadAction<number>) => {
			state.pageSize = payload;
		},
		setUnreadCount: (state, { payload }: PayloadAction<number>) => {
			state.unreadCount = payload;
		},
		setUpdateMessages: (state, { payload }: PayloadAction<boolean>) => {
			state.updateMessages = payload;
		},
		clearUpdateMessages: (state) => {
			state.updateMessages = false;
		},
		setUpdateMessage: (state, { payload }: PayloadAction<number>) => {
			state.updateMessage = payload;
		},
		clearUpdateMessage: (state) => {
			state.updateMessage = null;
		},
		setTicket: (state, { payload }: PayloadAction<Ticket>) => {
			state.ticket = payload;
		},
		clearTicket: (state) => {
			state.ticket = null;
		},
	}
});

export const { setTickets, setLoading, setError, setCount, setTotalPage, setPageSize, setUnreadCount, setUpdateMessages, clearUpdateMessages, setUpdateMessage, clearUpdateMessage, setTicket, clearTicket } = slice.actions;

export const getTickets = (payload: PaginateParams): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/tickets/list', {
				params: {
					filter: payload.filter,
					orderBy: payload.orderBy,
					sort: payload.sort.toUpperCase(),
					skip: payload.skip.toString(),
					take: initialState.pageSize.toString()
				}
			});
			const { data } = response;
			dispatch(setTickets(data.list));
			dispatch(setCount(data.count));
			dispatch(setPageSize(initialState.pageSize));
			dispatch(setTotalPage(Math.ceil(data.count / initialState.pageSize)));
			dispatch(clearUpdateMessages());
			dispatch(setLoading(false));
			return data;
		} catch (e) {
			console.log(e);
		}
	};
};

export const getTicket = (id: number): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get(`/api/tickets/${id}`);
			const { data } = response;
			dispatch(setTicket(data));
			dispatch(clearUpdateMessage());
			return data;
		} catch (e) {
			console.log(e);
		}
	};
};

export const deleteTicket = async (id: number) => {
	const response = await request.delete(`/api/tickets/${id}`);
	const { data } = response;
	return data;
};

export const getUnreadCount = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/tickets/notification/unread-count');
			const { data } = response;
			dispatch(setUnreadCount(data.count));
			return data;
		} catch (e) {
			console.log(e);
		}
	};
};

export const postTicket = async (payload) => {
	const response = await request.post('/api/tickets', payload);
	const { data } = response;
	return data;
};

export const replyTicket = async (payload) => {
	const response = await request.post('/api/tickets/replay', payload);
	const { data } = response;
	return data;
};

export const postPaymentRequest = async (payload) => {
	const response = await request.post('/api/tickets/payment-request', payload);
	const { data } = response;
	return data;
};

export const postStockOrder = async (payload: StockOrder) => {
	const response = await request.post('/api/tickets/stock-order', payload);
	const { data } = response;
	return data;
}; 

export const getAuthorInitials = (author: User): string => {
	if (author.type === UserType.BUSINESS) {
		return author.companyName.charAt(1).toUpperCase();
	} else {
		return author.firstName.charAt(0).toUpperCase() + author.lastName.charAt(0).toUpperCase();
	}
};

export const downloadAttachment = async (id: number, attachment: TicketAttachment) => {
	const response = await request.get(`/api/tickets/attachment/${id}/${attachment.key}`,
		{
			responseType: 'blob',
			params: {
				cacheBustTimestamp: Date.now(),
			},
		});
	const url = window.URL.createObjectURL(response.data);
	const link = document.createElement('a');
	link.download = attachment.name;
	link.href = url;
	link.className = 'hidden';
	document.body.appendChild(link);

	link.onclick = function () {
		requestAnimationFrame(function () {
			URL.revokeObjectURL(url);
			setTimeout(() => link.remove(), 300);
		});
	};

	link.click();
};

let socketService: SocketService;
let supportSubscriber: Subscription;
let supportMessageSubscriber: Subscription;


export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('support');
	}
};

export const disconnect = (): void => {
	socketService = null;
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		try {
			supportSubscriber = socketService.listen('support.messages', {}).subscribe((data) => {
				if (data === 'updated' || data === 'new') {
					dispatch(setUpdateMessages(true));
					dispatch(getUnreadCount());
				}
			});
			supportMessageSubscriber = socketService.listen('support.message', {}).subscribe((data) => {
				dispatch(setUpdateMessage(data));
				dispatch(getUnreadCount());
			});
		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const unsubscribe = (): void => {
	supportSubscriber.unsubscribe();
	supportMessageSubscriber.unsubscribe();
};

export default slice.reducer;
