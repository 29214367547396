import React from 'react';
import { Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const DownloadApp = () : React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<>
			<Grid.Row centered>
				<h2>{t('authenticator.download.header')}</h2>
			</Grid.Row>
			<Grid.Row>{t('authenticator.download.description')}</Grid.Row>
			<Grid.Row columns={2}>
				<Grid.Column verticalAlign='top' textAlign={'right'}>
					<a href='https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank" rel="noopener noreferrer">
						<img id='google-badge-img' alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
					</a>
				</Grid.Column>
				<Grid.Column verticalAlign='top' textAlign={'left'}>
					<a href="https://apps.apple.com/us/app/google-authenticator/id388497605?itsct=apps_box_badge&amp;itscg=30200" target="_blank" rel="noopener noreferrer">
						<img id='apple-badge-img' src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1284940800&h=7fc6b39acc8ae5a42ad4b87ff8c7f88d"  alt="Download on the App Store"/>
					</a>
				</Grid.Column>
			</Grid.Row>
		</>
	);
};

export default DownloadApp;
