import React from 'react';
import { Grid, Label } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface Props {
	backupKey: string
}

const BackupKey = ( { backupKey }: Props) : React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<>
			<Grid.Row centered><h2>{t('authenticator.backup.header')}</h2></Grid.Row>
			<Grid.Row id='backup-description'>{t('authenticator.backup.description')}</Grid.Row>
			<Grid.Row><Label size='large'>{backupKey}</Label></Grid.Row>
		</>
	);
};

export default BackupKey;
