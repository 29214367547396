import React from 'react';
import { Container, Grid, List, Segment, Table } from 'semantic-ui-react';
import './PrivacyPolicy.css';

function PrivacyPolicyDTSCB(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>DTS Circle Bank Privacy Policy</h1></Grid.Row></Grid>
			<Segment className="policyContent">

				<p>This Privacy Policy, which is provided pursuant to Articles 13 and 14 of Regulation EU n. 2016/679
					(General Data Protection Regulation – “<b>GDPR</b>”), describes the processing of your personal data
					carried out by DTS Circle Bank & Statutory Trust – commercially named DTS Circle Bank (“<b>DTSCB</b>” or
					“<b>we</b>”, “<b>us</b>”, “<b>our</b>”) when you use our Payment Service platform or browse / interact with our website
					www.dtscirclebank.com (“<b>Website</b>”).</p>

				<p>Protection and privacy of your personal data are our priority. Please read the following carefully
					to understand our views and practices regarding your personal data and how we will process it.</p>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Data controller</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<b>DTS Circle Bank</b><br/>
								Address: 111 Buckingham Palace Road – Office 3.39 – London UK<br/>
								E-mail: info@dtscirclebank.com
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>European representative of the data controller</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<b>DTSOCIALIZE Ltd</b><br/>
								Indirizzo: Kathleen Court Trip il Kappucini, Flat 1 - Zabbar – MALTA<br/>
								Indirizzo: e-mail office@dtsmail.tech
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Personal data processed</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<p><b>Website navigation data - Cookies</b></p>
								<p> While navigating and interacting with our Website, DTSCB and/or its partners collect
									navigation data (with your permission whenever it is mandatory by applicable
									law):
								</p>
								<List ordered>
									<List.Item>technical information including: IP address, information on the device,
										operating system, browser and software you use;
									</List.Item>
									<List.Item>
										information on the navigation on the Website including: URL of pages browsed and
										the activities carried out on these pages, (e.g. products viewed and eventually
										purchased), date and time of access, duration of access and clickstream.
									</List.Item>
								</List>
								<p>This information is collected to ensure the best functioning, management, and
									maintenance of the Website as well as the in order to ensure you a safe experience
									on the Website and to ascertain the liability in case of any breach or unlawful
									access.</p>
								<p>In addition, we analyse these information -also as aggregated data- in order to
									obtain statistics on the performance of the Website. Furthermore, with your
									permission with the acceptance of the specific cookie policy, we will process your
									navigation data in order to find products/services you prefer and to show you
									pertinent advertisements or interactive function of this Website. </p>
								<p> You are completely free to provide your navigation data. However, the refusal to
									provide for the information necessary information may make the access and the
									navigation on Website as well as connected activities impossible. We will store
									these data as long as they are necessary for the purpose for which they are
									collected. Navigation data are collected with the use of cookies. In order to
									understand how cookies work in particular or how to enable or disable them, please
									see our Cookie Policy.</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p><b>Connecting with DTSCB on social networks</b></p>
								<p>DTSCB provides experiences on social network platforms that enable online sharing
									and collaboration among users who have registered to use them. We may collect
									information you provide by interacting with us via social network, such as
									photographs, opinions and any personal data that you make available.</p>
								<p> In addition, when you visit a DTSCB’s page on a social network, whether by clicking
									on a social network link button or browsing directly through social network pages
									(including advertisements), the social network operator may share certain
									information about you with DTSCB. In particular:</p>
								<List ordered>
									<List.Item>
										Your actions, such as: viewing and interacting with our social page and any
										content posted on it (including shares, reactions, comments), clicks and private
										e-mail communications on our social page;
									</List.Item>
									<List.Item>
										Information about those actions, the people who carry out those actions and the
										browsers/apps used for them.
									</List.Item>
								</List>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p><b>Data provided by the user when requesting our assistance</b></p>
								<p>If you contact us for support, we will process any data you decide to share to
									enable us to respond to your request for assistance.</p>
								<p>In addition, if you contact us by phone, upon you giving consent, we will record the
									phone call and process any data referred therein for internal business quality
									purposes and to improve our customer care.</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p><b>Data provided by creating an account by using our Payment Service</b></p>
								<p>By filling the form on our Website to create your account and get a card and –
									subsequently, by using our service, you will provide us with the following
									data:</p>
								<p>Name and surname, place and date of birth, e-mail address, country, postal address,
									telephone number, details on your being (or not) a PEP, ID documents, social
									security number, financial, assets and earnings data, bank account details,
									transactional information, User ID.</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p><b>Data provided by third parties in the context of the due diligence we perform on you</b></p>
								<p>DTSCB may process data relating to criminal convictions, offences and related
									security or prevention measures applied to users which apply to become a Payment
									Service customer as it is necessary in order to comply with legal obligations to
									which DTSCB is subject, particularly in relation to anti-money laundering and
									preventing the financing of terrorism.</p>
								<p>Therefore, upon registration for our services, we start a due diligence process on
									you, which which may imply the provision of these type of data from publicly
									accessible sources of proven reliability and authority, as well as from providers of
									specialised databases.</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p><b>Data about your habits and preferences in the purchases you make and
									goods/services you are most interested in
								</b></p>
								<p>Through the analysis of your online transactions, as well as your interaction with
									social networks, upon your consent, we will analyse your habits and preferences in
									order to profile you as a consumer and provide you targeted advertising, as well as
									to produce analytical reports of aggregated data which we then use for marketing and
									statistical purposes and pass on to partners and third parties for their own
									marketing purposes, subject to your consent.</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p><b>Third parties’ websites  – general</b></p>
								<p>The Website may allow links to other websites or internet resources operated by
									third parties, also for advertising purposes. With regard to privacy protection
									instruments and the processing of personal data collected by such websites
									(including social network, if applicable), please refer to the relevant privacy
									policies. DTSCB cannot in any way be held responsible for the processing of personal
									data carried out by such websites or third party pages.</p>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								Purpose of data processing
							</Table.HeaderCell>
							<Table.HeaderCell>
								Legal basis for processing personal data
							</Table.HeaderCell>
							<Table.HeaderCell>
								Data retention period
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell><b>Check and ensure the proper functioning of the Website</b></Table.Cell>
							<Table.Cell>Legitimate interest</Table.Cell>
							<Table.Cell>For the period required to comply with a legal obligation or to defend a right
								of DTSCB before a judicial, administrative or other authority
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Preventing malicious and hacking attacks</b></Table.Cell>
							<Table.Cell>Legitimate interest</Table.Cell>
							<Table.Cell>For the period required to comply with a legal obligation or to defend a right
								of DTSCB before a judicial, administrative or other authority
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Carry out a due diligence on user’s financial reliabilty and not being
								involved in crimes against property, terrorism and other relevant crimes in the
								economic-financial-banking field
							</b></Table.Cell>
							<Table.Cell>Compliance with a legal obligation</Table.Cell>
							<Table.Cell>5 years after due diligence has been carried out</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Activate the user account and provide the user access to the service</b></Table.Cell>
							<Table.Cell>Performance of the service required by users</Table.Cell>
							<Table.Cell>5 years after activation of the user account and service has been required by user</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Issue and submit to user an invoice related to the fee paid for the Payment
								Service, if requested by the user</b></Table.Cell>
							<Table.Cell>Compliance with a legal obligation</Table.Cell>
							<Table.Cell>For the period required to comply with a legal obligation or to defend a right
								of DTSCB before a judicial, administrative or other authority
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Allow transactions required by user within the service</b></Table.Cell>
							<Table.Cell>Performance of the service required by users</Table.Cell>
							<Table.Cell>10 years after transaction took place</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Keep track of the transaction in the accounting records</b></Table.Cell>
							<Table.Cell>Performance of the service required by users as well compliance with the law</Table.Cell>
							<Table.Cell>For the period required to comply with a legal obligation or to defend a right
								of DTSCB before a judicial, administrative or other authority
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Handle the transaction requested by the user correctly, including submission
								of messages to user regarding the status of the transaction requested by the user
							</b></Table.Cell>
							<Table.Cell>Performance of the service required by users</Table.Cell>
							<Table.Cell>For 60 days or for the period required to comply with a legal obligation or to
								defend a right of DTSCB before a judicial, administrative or other authority
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Analyse your interests and preferences for marketing and statistical purposes (profiling)</b></Table.Cell>
							<Table.Cell>Specific consent which may be withdrawn at any time</Table.Cell>
							<Table.Cell rowSpan={3}>No more than 12 months for profiling and 24 months for marketing</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Send you promotional messages and/or commercial communications regarding
								products or services that are similar to those for which you are already a DTSCB
								customer
							</b></Table.Cell>
							<Table.Cell>Legitimate interest</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Send you promotional messages and/or commercial communications regarding
								products or services that are not similar to those for which you are already a DTSCB
								customer
							</b></Table.Cell>
							<Table.Cell>Specific consent which may be withdrawn at any time</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell><b>Transfer your personal data, even in aggregate form, to third parties who
								process them for their own purposes independently of DTSCB, including marketing purposes
							</b></Table.Cell>
							<Table.Cell>Specific consent which may be withdrawn at any time</Table.Cell>
							<Table.Cell>For 2 years from the data collection</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell colSpan={ 3 }>
								Once the above retention periods have expired your personal data will be destroyed,
								erased or made anonymous, subject to technical deletion and backup procedures. Likewise,
								this implement in any event if you decide to delete your account, subject to legal
								obligations that sometimes require us to retain the data.
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								Consent for person under 18 years of age
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<p>DTSCB Payment Services are not directed to individuals under the age of eighteen (18).</p>
								<p>Therefore, if a user fills forms on our Website or contacts DTSCB with regard to the
									Payment Service, we assume he/she is at least 18 years old. On the basis of this
									premise, any consent to data processing given by the user on our Website shall be
									deemed valid.</p>
								<p>In any case, any consent given on our Website by a person under sixteen (16) years
									of age shall in any event be deemed invalid.</p>
								<p>If you, as the legal representative or guardian of a minor, are giving consent to
									the processing of personal data on behalf of a child under eighteen (18) years of
									age then please be aware that minors need specific protection with regard to their
									personal data, as they may be less aware of the risks, consequences and safeguards
									concerned, and also of their rights in relation to the processing of personal data
									for the purposes of using these services. In such event, you assume any
									responsibility connected thereto.</p>
								<p>By accepting this privacy policy, on behalf of a minor, you are giving permission
									for their data to be used for the purposes described above and DTSCB will not be
									held responsible in any way.</p>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								Persons authorised to process your data
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<p>Personal data will only be processed by employees and collaborators of DTSCB and its
									European representative expressly authorised by DTSCB to process them.</p>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								Recipients of personal data
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<p>Your personal data may be disclosed to and processed by third parties acting as data
									controllers such as, by way of example:</p>
								<List bulleted>
									<List.Item>
										public authorities, supervisory authorities, control bodies, law enforcement
										agencies, and, in general, public or private parties (such as legal advisors,
										accountants, auditors) where necessary for the exercise or protection of our
										rights, as well as to comply with legal obligations;
									</List.Item>
									<List.Item>
										public or private third parties – as the case may be – in the context of
										extraordinary operations, including M&A operations, transfer of company assets
										and other transactions, for legitimate interests of DTSCB, to the extent
										permitted by applicable law;
									</List.Item>
									<List.Item>
										private third parties (including broker you indicate us, as well as DTSCB’s
										partners or affiliates) for the performance of the Payment Services and/or for
										purposes under this Privacy Policy;
									</List.Item>
									<List.Item>
										private third parties (including DTSCB’s partners or affiliates), for their own
										direct marketing purposes, subject to your prior consent thereto.
									</List.Item>
								</List>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p>The data may be processed, on behalf of the data controller, by third parties acting as
									data processors, who are given appropriate operating instructions, such as:</p>
								<List bulleted>
									<List.Item>
										Service providers (including IT developers and programmers, cloud providers, email providers, marketing companies),
									</List.Item>
									<List.Item>
										Advisors,
									</List.Item>
									<List.Item>
										Accountants,
									</List.Item>
									<List.Item>
										IT developers and programmers
									</List.Item>
								</List>
								<p>Such data processors have been selected among professionals who guarantee the
									implementation of appropriate technical and organizational measures, so that the
									processing is always carried out in compliance with applicable legislation and ensuring
									the protection of data subjects’ rights.</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p>The updated list containing the data processors is available by sending a written
									communication to the data controller or an e-mail to privacypolicy@dtscirclebank.com</p>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Transfer of personal data outside EU or EEA</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<p>DTSCB may store or transfer your personal data to countries outside the EU or
									European Economic Area. DTSCB ensure that all possible transfers outside the EU or
									EEA will be made in such a way as to guarantee the full and effective protection of
									your rights and freedoms. To this purpose, if no adequacy decision has been issued
									by the EU Commission with regard to the extra EU/EEA country to which the data is
									transferred, DTSCB will implement the proper safeguard measure in pursuance of
									Articles 46 ff. of GDPR performing due diligence checks with all importer of
									personal data to assess and verify that they have appropriate safeguards in place to
									protect the data.</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p>You can obtain evidence of the safeguards measures taken by DTS Circle Bank by
									sending an e-mail to privacypolicy@dtscirclebank.com</p>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>
								User’s rights
							</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<p>You can obtain access to your personal data and relevant copy, as well as their
									erasure, the rectification of inaccurate or incomplete data, or the limitation of
									the processing of your personal data according to Article 18 of GDPR;</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p>You also have the right to object to the processing of your personal data at any
									time in relation to your particular situation. Right to object to the processing of
									personal data for direct marketing purposes is absolute and can be exercised at any
									time (Article 21 of GDPR).</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p>If the processing of your personal data is based on consent, you have the right to
									withdraw your consent at any time (Article 7 of GDPR).</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p>If the conditions for the right to data portability under Article 20 of the GDPR are
									met, you have the right to receive in a structured format, commonly used and
									readable by automatic device the personal data you provided us and, where
									technically feasible, to the secure transmission of your personal data to another
									data controller.</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p>In order to protect your rights and your personal data, anytime, you may decide to
									file a complaint to the competent supervisory authority (in particular, in the EU
									State where you usually reside or work or in the State where any alledged violation
									of your data has been committed).</p>
							</Table.Cell>
						</Table.Row>
						<Table.Row>
							<Table.Cell>
								<p>You can exercise you rights by writing to the data controller by mail, at the above
									address, or by e-mail at the following e-mail address: compliance@dtscirclebank.com.
									It is understood that, where your request is made by electronic means, the
									information will be provided in a commonly used electronic format.</p>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>

				<Table>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Privacy Policy update</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						<Table.Row>
							<Table.Cell>
								<p>This Privacy Policy is constantly updated. For this reason, we indicate the date of
									the latest update at the head of this Privacy Policy. In case you have already
									provided your personal data to DTS Circle Bank, you will be informed about any
									substantial change with the appropriate means with a view to ensuring full
									transparency of the processing and full and adequate protection of your rights.</p>
								<p>However, we invite you to constantly check the Privacy Policy to be informed on its
									updates.</p>
							</Table.Cell>
						</Table.Row>
					</Table.Body>
				</Table>
			</Segment>
		</Container>
	);
}

export default PrivacyPolicyDTSCB;
