import React from 'react';
import {
	Button,
	Modal, Segment
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface Props {
	label: string;
	open: boolean;
	onClose: (value?: string) => void
}

const EditAccountLabel = ({ label, open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const initialValues = {
		label
	};

	const validationSchema = Yup.object({
		label: Yup.string().required(t('form.validator.required'))
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { label } = formData;
		onClose(label);
	};

	return <Modal
		size="mini"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Segment basic>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting, dirty }) => (
						<Form
							size="large">
							<Input
								label={t('cards.cardSettings.changeLabel')}
								fluid
								name="label"
								placeholder={t('cards.cardSettings.label')}
								errorPrompt
							/>
							<div className='editBTNS'>
								<SubmitButton
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									primary size="large" type="submit">
									{t('form.buttons.save')}
								</SubmitButton>
								<Button className='discardBTN' content='Discard' onClick={() => onClose()} />
							</div>
						</Form>)}
				</Formik>
			</Segment>
		</Modal.Content>
	</Modal>;
};

export default EditAccountLabel;
