import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Divider, Grid, Header, Icon, Menu, Segment, Checkbox as ConfirmBox, Message } from 'semantic-ui-react';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import './BankAccount.css';
import { TransferType } from '../SendMoney';
import BankAccountConfirmation from './BankAccountConfirmation';
import { findContact, getDataByIban } from '@features/contacts/slice';
import { showErrorNotification, showInfoNotification } from '@features/swal/slice';
import { runOperation } from '@features/operations/slice';
import { Contact } from '@/features/contacts/types';
import { postFile } from '@features/files/slice';
import { maxTwoDecimals } from '@/helpers/number';
import { AccountLimits } from '@/features/account/types';
import { Agents } from '@/helpers/globalTypes';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import TrustDomesticPayment from './TrustDomesticPayment';

enum SystemType {
	SEPA = 'SEPA_STEP2', SWIFT = 'SWIFT', UK_CHAPS = 'UK_CHAPS', US_WIRE = 'US_WIRE'
}

type Props = {
	onSend: (type: TransferType, value: any) => void,
	account: any,
	subProcesses: any,
	integration: string,
	accountLimits: AccountLimits
}

interface Payload {
	accountId: number,
	beneficiaryName: string,
	beneficiaryIdentityNo: string,
	beneficiaryIban?: string,
	beneficiaryBicSwift?: string,
	beneficiaryBankCountry?: string,
	beneficiaryBankName?: string,
	beneficiaryBankAddress?: string,
	beneficiarySortCode?: string,
	beneficiaryAccountNumber?: string
	amount: number,
	ccy: string,
	details: string,
	saveToContacts: boolean,
	attachments: any,
	beneficiaryAccountId?: number,
	isToBusiness: boolean,
	sortCode?: string,
	accountNumber?: string,
	beneficiaryAddressCountry?: string,
	beneficiaryAddressStreet?: string,
	beneficiaryAddressCity?: string,
	beneficiaryRegion?: string,
	beneficiaryPostalCode?: string,
	beneficiaryFurtherCreditAccountName?: string,
	beneficiaryFurtherCreditAccountNumber?: string,
}

interface Country {
	code: string,
	label: string,
}


/* eslint-disable  @typescript-eslint/no-unused-vars */
const BankAccount = ({ onSend, account, subProcesses, integration, accountLimits }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [addIdentity, setAddIdentity] = useState<boolean>(false);
	const [payload, setPayload] = useState<any>();
	const [process, setProcess] = useState<any>();
	const [type, setType] = useState<SystemType>(null);
	const [iban, setIban] = useState<string>('');
	const [name, setName] = useState<string>('');
	const [sortCode, setSortCode] = useState<string>('');
	const [accountNumber, setAccountNumber] = useState<string>('');
	const [reference, setReference] = useState<string>('');
	const [amount, setAmount] = useState<string>('');
	const [beneficiaryAccountId, setBeneficiaryAccountId] = useState<number>();
	const [beneficiaryIdentity, setBeneficiaryIdentity] = useState<string>(null);
	const [beneficiaryData, setBenificiaryData] = useState<any>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [saveToContacts, setSaveToContacts] = useState<boolean>(false);
	const [filteredContacts, setFilteredContacts] = useState<Array<Contact>>([]);
	const [isToBusiness, setIsToBusiness] = useState<boolean>(false);
	const [toIban, setToIban] = useState<boolean>(true);
	const [systems, setSystems] = useState([
	]);
	const [accountToCredit, setAccountToCredit] = useState<boolean>(false);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const [confirmed, setConfirmed] = useState<boolean>(integration === 'TRUST' && agent !== Agents.DTSUS ? false : true);

	const contriesList = getNames('en');
	const [countries] = useState<Array<Country>>(
		Object.keys(contriesList)
			.map($code => ({
				code: $code,
				label: contriesList[$code],
			})));
	const fileTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	];
	const [selectedFiles, setSelectedFiles] = useState([]);

	const isUsSwift = process?.proc === 'cj-pool-payment-swift' || process?.proc === 'cj-wallet-payment-swift'
		|| process?.processProperties.paymentType === 'SWIFT';

	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 1) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 1) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};
	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};

	const hiddenFileInput = React.useRef(null);

	useEffect(() => {
		if (!subProcesses) return;
		const filtered = Object.keys(subProcesses).filter(key => subProcesses[key].type === 'PAYMENT').map(key => subProcesses[key]);
		setSystems(filtered);
	}, [subProcesses]);

	useEffect(() => {
		setProcess(systems.find(s => s.processProperties.paymentType === type));
	}, [type]);

	const validationSchema = Yup.object({
		...(toIban ? {
			iban: Yup.string().required(t('form.validator.required'))
		} :
			{
				sortCode: Yup.string().required(t('form.validator.required')),
				accountNumber: Yup.string().required(t('form.validator.required'))
			}),
		...(type === SystemType.SWIFT && agent === Agents.DTSUS ? {
			country: Yup.string().required(t('form.validator.required')),
			address: Yup.string().required(t('form.validator.required')),
			city: Yup.string().required(t('form.validator.required')),
			region: Yup.string().required(t('form.validator.required')),
			postalCode: Yup.string().required(t('form.validator.required')),
		} : {}),
		amount: Yup.number()
			.required(t('form.validator.required'))
			.min(0.01, t('sendmoneyTranslation.data.wrongAmount'))
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				(value) => value <= account.availableBalance)
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('sendmoneyTranslation.data.limitReached', { ccy: accountLimits.ccy, availableBalanceNow: accountLimits.availableBalanceNow }), (value) => value <= accountLimits.availableBalanceNow),
		to: Yup.string().required(t('form.validator.required')),
		reference: Yup.string().required(t('form.validator.required')).max(140, t('sendmoneyTranslation.data.canNotExceedHundred')),
		identity: Yup.string().when('addIdentity', {
			is: (addIdentity) => addIdentity === true && (beneficiaryIdentity === null || beneficiaryIdentity === ''),
			then: Yup.string().required(t('form.validator.required')),
			otherwise: Yup.string()
		}).max(18, t('form.validator.maxChars', { chars: 18 }))
	});

	const isValidFiles = () => {
		if (integration === 'TRUST' && parseInt(amount) >= 10000) {
			return selectedFiles.length === 1;
		} else if (parseInt(amount) >= 5000) {
			return selectedFiles.length === 1;
		}
		else {
			return true;
		}
	};

	useEffect(() => {
		setSubmitting(submitting);
	}, [selectedFiles]);

	const initialValues = {
		from: account.name + ' (' + account.currency + ' ' + account.availableBalance + ')',
		amount: amount,
		to: name,
		iban: iban,
		reference: reference,
		sortCode: sortCode,
		accountNumber: accountNumber,
		bicswift: payload?.bicswift ?? '',
		country: '',
		address: '',
		city: '',
		region: '',
		postalCode: '',
		accountToCreditName: '',
		accountToCreditNumber: '',
		ibanDTSUS: ''
	};

	const handleSelectFromContacts = (contact) => {
		setIban(contact.account);
		setName(contact.name);
		setBeneficiaryAccountId(contact.beneficiaryAccountId);
		setIsToBusiness(contact.business);
		setSortCode(contact.sortCode);
		setAccountNumber(contact.accountNumber);
		contact.account ? setToIban(true) : setToIban(false);
		if (contact.business) setAddIdentity(true);
		if (contact.identification) {
			setBeneficiaryIdentity(contact.identification);
			setAddIdentity(true);
		}
		setFilteredContacts([]);
	};

	const handleIbanChange = (...args) => {
		setBeneficiaryAccountId(null);
		const iban = electronicFormatIBAN(args[1].value).replace(/\s/g, '');
		setIban(iban);
	};

	const handleNameChange = async (...args) => {
		setBeneficiaryAccountId(null);
		try {
			setName(args[1].value);
			const data = await findContact(args[1].value, type);
			setFilteredContacts(data);
		}
		catch (e) { console.log(e); }
	};

	const handleAmountChange = async (...args) => {
		setAmount(args[1].value);
	};

	const handleReferenceChange = async (...args) => {
		setReference(args[1].value);
	};

	const submit = async (formData, formikProps) => {
		const { setFieldError } = formikProps;
		if (!isValidFiles()) {
			showInfoNotification('sendmoneyTranslation.data.documentRequired');
			setSubmitting(false);
			return;
		}

		const { to, iban, amount, reference, identity, sortCode, accountNumber, country, city, address, postalCode, region, accountToCreditNumber, accountToCreditName, ibanDTSUS } = formData;

		if (toIban && !isUsSwift) {
			if (!isValidIBAN(iban)) {
				setBenificiaryData(null);
				setFieldError('iban', t('sendmoneyTranslation.data.invalidIban'));
				setSubmitting(false);
				return;
			} else {
				const beneficiaryData = await getDataByIban(iban.replace(/\s/g, ''));
				setBenificiaryData(beneficiaryData);
			}
		}

		const payload = {
			...{
				to, account, iban, amount, reference, identity, addToContacts: saveToContacts, sortCode, accountNumber, isToIban: toIban,
				country, city, address, postalCode, region, accountToCreditNumber, accountToCreditName, ibanDTSUS
			},
			...{ currency: account.currency },
			ibanFieldName: isUsSwift ? t('sendmoneyTranslation.data.accountNumber') : t('sendmoneyTranslation.data.iban'),
			bicswift: beneficiaryData ? beneficiaryData.bic : ''
		};
		setPayload(payload);
		formikProps.resetForm(initialValues);
	};

	const send = async () => {
		try {
			setSubmitting(true);
			const attachments: Map<string, string> = new Map<string, string>();
			if (selectedFiles.length === 1) {
				const formData = new FormData();
				formData.append('file', selectedFiles[0]);
				const fileRecord = await postFile(formData);
				const { key } = fileRecord;
				attachments.set(key, selectedFiles[0].name);
			}



			const dataBody: Payload = {
				accountId: account.accountId,
				beneficiaryName: name,
				beneficiaryIdentityNo: beneficiaryIdentity,
				...(toIban ?
					{
						beneficiaryIban: payload.iban,
						beneficiaryBicSwift: beneficiaryData?.bic,
						beneficiaryBankCountry: beneficiaryData?.country,
						beneficiaryBankName: beneficiaryData?.bank,
						beneficiaryBankAddress: beneficiaryData?.address
					} :
					{
						beneficiarySortCode: payload.sortCode,
						beneficiaryAccountNumber: payload.accountNumber
					}
				),
				amount: payload.amount,
				ccy: account.currency,
				details: payload.reference,
				saveToContacts: payload.addToContacts,
				attachments: Object.fromEntries(attachments),
				isToBusiness,
				...(type === SystemType.SWIFT && agent === Agents.DTSUS ? {
					beneficiaryPostalCode: payload.postalCode,
					beneficiaryAddressCountry: alpha2ToAlpha3(payload.country),
					beneficiaryAddressStreet: payload.address,
					beneficiaryAddressCity: payload.city,
					beneficiaryRegion: payload.region,
				} : {})
			};

			if (payload.ibanDTUS) {
				dataBody.beneficiaryIban = payload.ibanDTSUS;
			}

			if (accountToCredit) {
				dataBody.beneficiaryFurtherCreditAccountName = payload.accountToCreditName;
				dataBody.beneficiaryFurtherCreditAccountNumber = payload.accountToCreditNumber;
			}


			await runOperation(process.proc, dataBody);

			setSubmitting(false);
			onSend(TransferType.EXTERNAL, payload);
		} catch (e: any) {
			const validtionError = (e?.data?.errors[0]?.error?.message?.indexOf('Invalid') !== -1) || false;
			setSubmitting(false);
			showErrorNotification(e, validtionError ? 'errors.DATA_ISSUE' : 'errors.SOMETHING_WENT_WRONG_WITHOT_MESSAGE');
			setPayload(null);
		}
	};

	const getHeaderTranslation = (name): string => {
		if (name) {
			if (name.includes('SWIFT')) {
				return `SWIFT ${t('sendmoneyTranslation.data.paymentHeader')}`;
			}
			if (name.includes('SEPA')) {
				return integration === 'TRUST' ? agent === Agents.DTSUS ? 'SWIFT Payment' : 'New SEPA order' : `SEPA ${t('sendmoneyTranslation.data.paymentHeader')}`;
			}
			return name;
		} else {
			return '';
		}
	};

	const isConfirmed = () => {
		if (integration === 'TRUST' && !confirmed) {
			return false;
		} else {
			return true;
		}
	};


	if (!type) {
		return (
			<div id="sendmoneynew">
				<Grid>
					<Grid.Column width={16}>
						<Segment padded>
							<Card.Group centered>
								{systems.map(system => (
									<Card
										link
										onClick={() => { setType(system.processProperties.paymentType); }}
										style={{ maxWidth: 240, paddingBottom: 40 }}
										key={system.processProperties.paymentType}>
										<Card.Content>
											<Icon style={{ float: 'right' }} name="circle outline" />
											<Card.Header>{getHeaderTranslation(system.name)}</Card.Header>
											<Card.Description><span style={{ fontSize: '0.85em' }} dangerouslySetInnerHTML={{ __html: agent === Agents.DTSUS ? '' : system.description ?? '' }}></span></Card.Description>
										</Card.Content>
									</Card>))}
							</Card.Group>
						</Segment>
					</Grid.Column>
				</Grid>
			</div>
		);
	}


	if (payload) {
		return (
			<div id="sendmoneynew">
				<BankAccountConfirmation payload={payload} process={process} onConfirm={send} submitting={submitting} files={selectedFiles} />
			</div>
		);
	}

	// if (type && type === SystemType.US_WIRE) {
	// 	return (<TrustDomesticPayment subProcesses={subProcesses} onSend={onSend} account={account} integration={integration} accountLimits={accountLimits} />);
	// }

	return (
		<>
			{type && type === SystemType.US_WIRE ?
				<TrustDomesticPayment subProcesses={subProcesses} onSend={onSend} account={account} integration={integration} accountLimits={accountLimits} />
				:
				<div id="sendmoneynew">
					<Grid>
						<Grid.Column width={16}>
							<Segment padded>
								<Header className="cospayuserheader" as="h3">{integration === 'TRUST' ? agent === Agents.DTSUS ? t('sendmoneyTranslation.data.transfer') : t('sendmoneyTranslation.data.order') : t('sendmoneyTranslation.data.header')}</Header>
								{(integration === 'TRUST' && agent !== Agents.DTSUS) && <Message>
									{t('sendmoneyTranslation.trustInfo')}
								</Message>}
								<Divider hidden />
								<Formik
									initialValues={initialValues}
									validationSchema={validationSchema}
									onSubmit={submit}
								>
									{({ setFieldValue, errors, isSubmitting, validateForm }) => {
										return (
											<Form>
												<Input
													fluid
													id="account"
													name="from"
													label={t('sendmoneyTranslation.data.from')}
													disabled
												/>
												<Input
													className="transferamountinput"
													fluid
													onChange={handleAmountChange}
													name="amount"
													label={t('sendmoneyTranslation.data.transferamount')}
													placeholder={`0.00 ${account.currency}`}
													type='number'
													onWheel={(e) => e.target.blur()}
													errorPrompt
												/>
												<div className='field'>
													<label>{t('contacts.create.beneType')}</label>
													<Button.Group>
														<Button type='button' active={!isToBusiness} content={t('contacts.create.individual')} onClick={() => setIsToBusiness(false)} primary={!isToBusiness} />
														<Button.Or />
														<Button type='button' active={isToBusiness} content={t('contacts.create.business')} onClick={() => { setIsToBusiness(true); setAddIdentity(true); setFieldValue('addIdentity', true); }} primary={isToBusiness} />
													</Button.Group>
												</div>
												<Input
													fluid
													name="to"
													onChange={handleNameChange}
													label={isToBusiness ? t('contacts.create.companyName') : t('sendmoneyTranslation.data.beneficiary')}
													errorPrompt
												/>
												{filteredContacts.length ? (
													<div>
														<Divider hidden />
														<Header>Select from your contacts</Header>
														<Menu vertical fluid>
															{filteredContacts.map(contact => (
																<Menu.Item
																	key={contact.beneficiaryAccountId}
																	onClick={() => {
																		handleSelectFromContacts(contact);
																		contact.account && setFieldValue('iban', contact.account.replace(/\s/g, ''));
																		contact.name && setFieldValue('to', contact.name);
																		contact.business && setFieldValue('addIdentity', contact.business);
																		contact.sortCode && setFieldValue('sortCode', contact.sortCode);
																		contact.accountNumber && setFieldValue('accountNumber', contact.accountNumber);
																	}}
																>
																	{contact.name} ({contact.account})
																</Menu.Item>
															))}
														</Menu>
														<Header>Or fill with new data</Header>
													</div>
												) : ''}
												<Divider hidden />
												<Checkbox
													name='addIdentity'
													onChange={() => { setAddIdentity(!addIdentity); }}
													checked={addIdentity}
													label={t('sendmoneyTranslation.activedata.addidentity')}
													disabled={isToBusiness} />
												<Divider hidden />
												{(addIdentity) && <Input
													fluid
													name="identity"
													onChange={(...args) => {
														setBeneficiaryIdentity(args[1].value);
													}}
													value={beneficiaryIdentity}
													label={isToBusiness ? t('contacts.create.companyRegNumber') : t('sendmoneyTranslation.activedata.identity')}
													errorPrompt
												/>}
												{type === SystemType.UK_CHAPS &&
													<div className='field'>
														<label>{t('sendmoneyTranslation.data.paymentTo')}</label>
														<Button.Group>
															<Button type='button' active={toIban} content={t('sendmoneyTranslation.data.iban')} onClick={() => { setToIban(true); setTimeout(() => validateForm()); }} primary={toIban} />
															<Button.Or />
															<Button type='button' active={!toIban} content={t('sendmoneyTranslation.data.sortCode')} onClick={() => { setToIban(false); setTimeout(() => validateForm()); }} primary={!toIban} />
														</Button.Group>
													</div>}
												{toIban && <>
													<Input
														onChange={handleIbanChange}
														fluid
														name="iban"
														maxLength="40"
														label={isUsSwift ? t('sendmoneyTranslation.data.accountNumber') : t('sendmoneyTranslation.data.iban')}
														errorPrompt
													/>
													<Divider section />
													<Input
														fluid
														name="bicswift"
														value={beneficiaryData ? beneficiaryData.bic : ''}
														onChange={(...args) => { setBenificiaryData({ ...beneficiaryData, ...{ bic: args[1].value } }); }}
														label={t('sendmoneyTranslation.data.bicswift')}
														errorPrompt
														disabled={!isUsSwift}
													/>
													{!isUsSwift && <Input name="country" onChange={(...args) => {
														setBenificiaryData({ ...beneficiaryData, ...{ country: args[1].value } });
													}} value={beneficiaryData ? contriesList[beneficiaryData.country] : ''} fluid
														label={t('sendmoneyTranslation.data.country')} errorPrompt disabled />
													}
												</>}
												{!toIban &&
													<>
														<Input
															onChange={handleIbanChange}
															fluid
															name="sortCode"
															label={t('sendmoneyTranslation.data.sortCode')}
															errorPrompt
														/>
														<Input
															fluid
															name="accountNumber"
															label={t('sendmoneyTranslation.data.accountNumber')}
															errorPrompt
														/>
													</>}
												{type === SystemType.SWIFT && agent === Agents.DTSUS && <>
													<Divider section />

													<Select
														fluid
														name="country"
														label={t('form.fields.country')}
														placeholder={t('form.placeholder.country')}
														errorPrompt
														list="countries"
														options={countries.map(c => ({ key: c.code, value: c.code, text: c.label }))}
														clearable
														search
													/>

													<Input
														fluid
														name="address"
														label={t('form.fields.justAddress')}
														errorPrompt
													/>
													<Input
														fluid
														name="city"
														label={t('form.fields.town')}
														errorPrompt
													/>
													<Input
														fluid
														name="region"
														label={t('sendmoneyTranslation.data.region')}
														errorPrompt
													/>
													<Input
														fluid
														name="postalCode"
														label={t('form.fields.postalcode')}
														errorPrompt
													/>
													<Input
														fluid
														name="ibanDTSUS"
														maxLength="40"
														label={t('sendmoneyTranslation.data.ibanOptional')}
														errorPrompt
													/>
													<Divider section />
													<Checkbox
														name='addAccountToCredit'
														onChange={() => { setAccountToCredit(!accountToCredit); }}
														checked={accountToCredit}
														label={t('sendmoneyTranslation.data.accountToCreditCbx')}
													/>
													{accountToCredit && <>
														<Input
															fluid
															name="accountToCreditName"
															label={t('sendmoneyTranslation.data.accountToCreditName')}
															errorPrompt
														/>
														<Input
															fluid
															name="accountToCreditNumber"
															label={t('sendmoneyTranslation.data.accountToCreditNumber')}
															errorPrompt
														/>
													</>}
													<Divider section />
												</>}
												<TextArea
													name="reference"
													onChange={handleReferenceChange}
													value={reference}
													label={t('sendmoneyTranslation.data.reference')}
													errorPrompt
													maxLength="140"
												/>
												{type !== SystemType.SWIFT &&
													<Checkbox
														name="addToContacts"
														label={t('sendmoneyTranslation.data.addtocontacts')}
														onChange={() => { setSaveToContacts(!saveToContacts); }}
														checked={saveToContacts}
													/>
												}
												<Divider hidden />
												{integration === 'TRUST' ? <p><b>{t('sendmoneyTranslation.data.document', { ccy: account.currency, amount: '10 000' })}</b></p> : <p><b>{t('sendmoneyTranslation.data.document', { ccy: account.currency, amount: '5 000' })}</b></p>}

												<input type="file"
													multiple
													accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
													name="files"
													ref={hiddenFileInput}
													onChange={e => {
														uploadFiles(e.currentTarget.files);
														setFieldValue('files', selectedFiles);
													}}
													style={{ display: 'none' }}
												/>
												<Button id='uploadButton' basic type="button" onClick={handleUploadClick} content={t('sendmoneyTranslation.activedata.upload')} icon="upload" />
												<Divider hidden />
												<div id='security-info'>
													<Grid>
														<Grid.Column verticalAlign='middle'><Icon size='large' name='info circle' color='blue' /></Grid.Column>
														<Grid.Column width={15}>{t('sendmoneyTranslation.data.fileTypes')}</Grid.Column>
													</Grid>
												</div>
												{selectedFiles.length > 0 && (
													<Grid className="uploadedFiles" padded>
														<Divider hidden />
														{selectedFiles.map((file, index) =>
															<Grid.Row className="uploadedFile" key={file.name}>
																<Icon size="large" name="file outline" />
																<div className="fileName">{file.name}</div>
																<Grid.Column floated='right' className="deleteAttachment">
																	<Icon
																		onClick={() => {
																			deleteAttachment(index);
																			setFieldValue('files', selectedFiles);
																		}}
																		size="large"
																		name="times" />
																</Grid.Column>
															</Grid.Row>
														)}
													</Grid>
												)}
												<Divider section />
												{integration === 'TRUST' && agent !== Agents.DTSUS && <>
													<ConfirmBox
														checked={confirmed}
														onChange={() => { setConfirmed(!confirmed); }}
														label={t('trusts.confirmTransfer')}
														errorPrompt
													/>
													<Divider hidden />
												</>}
												<SubmitButton disabled={isSubmitting || !isEmpty(errors) || !isConfirmed()} primary fluid type="submit">{integration === 'TRUST' && agent !== Agents.DTSUS ? t('sendmoneyTranslation.activedata.trustButton') : t('sendmoneyTranslation.activedata.button')}</SubmitButton>
											</Form>);
									}}
								</Formik>
							</Segment>
						</Grid.Column>
					</Grid>
				</div>}
		</>);
};

export default BankAccount;
