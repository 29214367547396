import React from 'react';
import { Modal, Button, Header } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface Props {
	open: boolean;
	onClose: () => void;
}

const AnnouncementModal = ({ open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	return <Modal
		size="large"
		open={open}
		onClose={() => onClose()}
		closeOnDimmerClick={false}
		closeOnEscape={false}
	>
		<Modal.Content>
			<Modal.Description>
				<Header>Important Notice: Platform Migration in Progress</Header>
				<p style={{fontStyle: 'italic'}}>Dear valued users,</p>
				<p style={{fontStyle: 'italic'}}>We are excited to announce that we are in the process of migrating to a new and improved service provider to enhance your experience on our platform. However, this migration requires a temporary interruption of our services.</p>
				<p style={{fontStyle: 'italic'}}>To ensure a smooth transition and deliver a better product, we have temporarily stopped our services. We apologise for any inconvenience caused during this period. We expect to be back live in September, offering you an enhanced and seamless user experience.</p>
				<p style={{fontStyle: 'italic'}}>We understand the importance of your accounts and value your trust in our platform. As part of our commitment to customer satisfaction, we will be reimbursing the balance you have in your account. To initiate the reimbursement process, please share your account details to the following email: <a href="mailto:migration@dtsmoney.com">migration@dtsmoney.com</a></p>
				<p style={{fontStyle: 'italic'}}>Please make sure that the name/surname of the accountholder you will provide match the name/surname of your account in DTS Money Americas.</p>
				<p style={{fontStyle: 'italic'}}>During this transition period, we invite you to register at <a href="https://portal.dtsmoney.com/auth">https://portal.dtsmoney.com</a> where you will be able to manage your tokens without the need to go through the KYC process.</p>
				<p style={{fontStyle: 'italic'}}>We appreciate your patience and understanding during this migration period. Our team is working diligently to bring you an improved platform with enhanced features and functionality. We look forward to serving you again soon.</p>
				<p style={{fontStyle: 'italic'}}>Thank you for being a valued user.</p>
				<p style={{fontStyle: 'italic'}}>Best regards,
					<br />
					DTS Money Americas</p>
			</Modal.Description>
		</Modal.Content>
		<Modal.Actions>
			<Button
				content='Ok'
				labelPosition='right'
				icon='checkmark'
				onClick={() => onClose()}
				positive
			/>
		</Modal.Actions>
	</Modal>;
};

export default AnnouncementModal;
