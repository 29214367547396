import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container, Divider, Header, Message } from 'semantic-ui-react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './RegistrationIB.css';
import { Formik } from 'formik';
import { Form } from 'formik-semantic-ui-react';
import { logout, signup } from '@features/user/userSlice';
import { showException, showSuccess } from '@features/swal/slice';
import { SignupBody } from '@features/user/types';
import { format, isValid, parse } from 'date-fns';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import { usePasswordValidation, useValidator } from './hooks';
import PersonalInfo from './Parts/PersonalInfo';
import Compliance from './Parts/Compliance';
import RegisterButton from './Parts/RegisterButton';
import Links from './Parts/Links';
import { registerFreelancerCode } from '@features/employee/slice';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function parseDate(birthDate) {
	if (isValid(parse(birthDate, 'yyyy-MM-dd', new Date()))) {
		return format(parse(birthDate, 'yyyy-MM-dd', new Date()), 'MM-dd-yyyy');
	}
	return format(parse(birthDate, 'dd/MM/yyyy', new Date()), 'MM-dd-yyyy');
}

const Freelancer = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const query = useQuery();
	const [registrationError, setError] = useState<boolean>(false);
	const [politicalExposed, setPoliticalExposed] = useState<boolean>(false);
	const [usResident, setUsResident] = useState<boolean>(false);
	const [toc, setToc] = useState<boolean>(false);
	const [aml, setAml] = useState<boolean>(false);
	const [passwordErrors, validatePassword] = usePasswordValidation();
	const individualValidator = useValidator('freelancer');
	const countriesList = getNames('en');
	const [serviceAllowed, setServiceAllowed] = useState<boolean>(false);

	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		passwordConfirm: '',
		country: '',
		countryCode: '',
		phoneNumber: '',
		birthDate: ''
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setError(false);
		try {
			const { firstName, lastName, email, password, country, countryCode, phoneNumber, birthDate } = formData;
			const freelancerCode = query.get('code');
			if(!freelancerCode) {
				showException({}, [{
					key: 'url',
					title: 'Error',
					message: 'Check your registration url'
				}]);
			}
			const countyCode = alpha2ToAlpha3(country);
			const recaptchaToken = await getRecaptureToken();
			const payload: SignupBody = {
				accountType: 'employee',
				politicalExposed,
				usResident,
				email,
				country: countyCode,
				password,
				firstName,
				lastName,
				birthDate: parseDate(birthDate),
				mobileNumber: `+${countryCode}${phoneNumber}`,
				invitationCode: null,
				freelancerCode: freelancerCode,
				recaptchaToken
			};
			dispatch(logout());
			const response = await signup(payload);
			const { data } = response;
			await registerFreelancerCode(freelancerCode, data.id);
			showSuccess({ title: 'swal.USER_REGISTERED', text: 'swal.USER_REGISTERED_TEXT' });
			history.push('/auth');
		}
		catch (e) {
			const { data, status } = e;
			if (status === 417) {
				const { errors } = data;
				const duplicatePresent = errors.find(error => error.error === 'duplicate' && error.error_param === 'email');
				if(duplicatePresent) {
					showException({
						data: duplicatePresent,
						status: 417
					}, [{
						key: 'duplicate',
						title: 'Error',
						message: 'An account with your provided email has already been created'
					}]);
				}
			} else {
				showException(e, [{
					key: 'registration_disabled',
					title: 'swal.REGISTRATION_DISABLED_TITLE',
					message: 'swal.REGISTRATION_DISABLED_TEXT'
				}]);
			}
			setSubmitting(false);
		}
	};

	return (
		<Container>
			<Formik
				initialValues={initialValues}
				validationSchema={individualValidator}
				onSubmit={submit}
			>{({ errors, isSubmitting, dirty }) => (
					<Form error={registrationError}>
						<Header className="aboutyoutitle" as="h3">{t('registration.individualtitle')}</Header>
						<Divider hidden />
						<PersonalInfo contriesList={countriesList} validatePassword={validatePassword} passwordErrors={passwordErrors} dirty={dirty} freelancer={true} setServiceAllowed={setServiceAllowed} />
						<Header className="checkboxheader" size="medium">{t('registration.compliancelabel')}</Header>
						<Compliance toc={toc} setToc={setToc} aml={aml} setAml={setAml} usResident={usResident} setUsResident={setUsResident} politicalExposed={politicalExposed} setPoliticalExposed={setPoliticalExposed} />
						{registrationError && (<Message
							error
							header='Action Forbidden'
							content={registrationError} />)}
						<div>
							<RegisterButton
								isSubmitting={isSubmitting}
								toc={toc}
								aml={aml}
								usResident={false}
								politicalExposed={false}
								dirty={dirty}
								errors={errors}
								serviceAllowed={serviceAllowed}
							/>
						</div>
						<Divider hidden />
						<Links />
					</Form>
				)}
			</Formik>
		</Container >
	);
};

export default Freelancer;
