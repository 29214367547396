import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Container, Grid, Header, Icon, Menu } from 'semantic-ui-react';
import BuyCrypto from '@/components/Crypto/Trade/BuyCrypto';
import SellCrypto from '@/components/Crypto/Trade/SellCrypto';
import ConvertCrypto from '@/components/Crypto/Trade/ConvertCrypto';

interface Props {
	onBackClick: () => void
}

enum Action {
	BUY,SELL,CONVERT
}

const TradeCrypto = ({ onBackClick }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [action, setAction] = useState<Action>(Action.BUY);


	return (
		<div id='crypto-trade'>
			<Container>
				<Breadcrumb size='large'>
					<Breadcrumb.Section className='backchevronitem' link onClick={ onBackClick }><Icon
						name='chevron left' className='backIcon'/> { t('inbox.back') }</Breadcrumb.Section>
				</Breadcrumb>
				<Header as='h1'>Buy / Sell</Header>
				<Grid>
					<Grid.Column width={5}>
						<Menu className="vertical-menu" fluid vertical>
							<Menu.Item key={'key'}
								active={action === Action.BUY}
								onClick={() => setAction(Action.BUY) }
								icon="chevron right"
								name='Buy'
							/>
							<Menu.Item key={'key'}
								active={action === Action.SELL}
								onClick={() => setAction(Action.SELL) }
								icon="chevron right"
								name='Sell'
							/>
							<Menu.Item key={'key'}
								active={action === Action.CONVERT}
								onClick={() => setAction(Action.CONVERT) }
								icon="chevron right"
								name='Convert'
							/>
						</Menu>
					</Grid.Column>
					{action === Action.BUY && <BuyCrypto />}
					{action === Action.SELL && <SellCrypto/>}
					{action === Action.CONVERT && <ConvertCrypto/>}
				</Grid>
			</Container>
		</div>
	);
};

export default TradeCrypto;
