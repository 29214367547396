import React, { useState, useEffect } from 'react';
import {
	useLocation,
	useHistory
} from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { Grid, Menu } from 'semantic-ui-react';
import BankAccount from '@SendMoney/BankAccount';
import CospayUser from '@SendMoney/CospayUser';
import CospayAccount from '@SendMoney/CospayAccount';
import { useTranslation } from 'react-i18next';
import { getAccount, getAccountLimit } from '@features/account/slice';
import TrustAccount from '@SendMoney/TrustAccount';
import { AccountLimits } from '@/features/account/types';
import { Agents } from '@/helpers/globalTypes';
import CardTransfer from './SendMoney/CardTransfer/CardTransfer';
import TrustDomesticPayment from './SendMoney/TrustDomesticPayment';

const extractSubprocesses = (subProcesses) => {
	return Object.values(subProcesses).map(value => value['type']);
};

export enum TransferType {
	EXTERNAL, CONTACT, INTERNAL, REQUEST, TRUST, BULK, CARD, TRUST_DOMESTICS
}
interface Props {
	onSend: (type, value) => void
}

function SendMoney({ onSend }: Props): React.ReactElement {
	const { t } = useTranslation('translations');
	const location = useLocation();
	const history = useHistory();
	const searchParams = new URLSearchParams(location.search);
	const [accountId] = useState<string>(searchParams.get('account'));
	const [account, setAccount] = useState<any>(null);
	const [process, setProcess] = useState<any>(null);
	const [subProcesses, setSubProcesses] = useState<any>([]);
	const [transferType, setTransferType] = useState<TransferType>(TransferType.EXTERNAL);
	const [accountLimits, setAccountLimits] = useState<AccountLimits>(null);

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const { accounts } = useSelector(
		(state: RootState) => state.trust
	);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	useEffect(() => {
		if (isPaymentEnabled()) {
			setTransferType(TransferType.EXTERNAL);
			return;
		} else if (isTransferEnabled()) {
			setTransferType(TransferType.CONTACT);
			return;
		} else if (isOwnTransferEnabled()) {
			setTransferType(TransferType.INTERNAL);
			return;
		} else if (isTrustTransferEnabled()) {
			setTransferType(TransferType.TRUST);
		}
	}, [subProcesses]);

	const isPaymentEnabled = () => {
		return subProcesses.indexOf('PAYMENT') !== -1;
	};

	const isTransferEnabled = () => {
		return subProcesses.indexOf('TRANSFER') !== -1 && process.integration !== 'TRUST';
	};

	const isTrustTransferEnabled = () => {
		return subProcesses.indexOf('TRANSFER') !== -1 && process.integration === 'TRUST';
	};

	const isOwnTransferEnabled = () => {
		return subProcesses.indexOf('OWN_TRANSFER') !== -1 && list.length > 1;
	};

	const isTopupByCardEnabled = () => {
		return subProcesses.indexOf('DEPOSIT_FROM_CARD') !== -1 && list.length > 1;
	};

	useEffect(() => {
		if (!list.length && !accounts.length) {
			return history.push('/wallet');
		}
		const account = list.find(l => `${l.accountId}` === accountId);
		if (!account) {
			const trustAccount = accounts.find(l => `${l.accountId}` === accountId);
			setAccount(trustAccount);
		} else {
			setAccount(account);
		}
	}, [list, accountId, history]);

	useEffect(() => {
		const fetch = async () => {
			const process = await getAccount(parseInt(accountId));
			setProcess(process);
			setSubProcesses(extractSubprocesses(process.subProcesses));
		};
		fetch();
	}, [accountId]);

	useEffect(() => {
		if (!account) return;
		const getAccLimits = async () => {
			const limit = await getAccountLimit(account.accountId);
			setAccountLimits(limit);
		};
		getAccLimits();
	}, [account]);

	return (
		<div id="sendmoneytab">
			<Grid stackable columns="equal">
				<Grid.Column width={5}>
					<Menu fluid vertical className="vertical-menu">
						{isPaymentEnabled() &&
							<Menu.Item
								disabled={!isPaymentEnabled()}
								name={process.integration === 'TRUST' ? agent === Agents.DTSUS ? t('sendmoneyTranslation.data.transfer') : t('sendmoneyTranslation.data.order') : t('sendmoneyTranslation.data.payment')}
								active={transferType === TransferType.EXTERNAL}
								onClick={() => setTransferType(TransferType.EXTERNAL)}
								icon="chevron right" />
						}
						{isTransferEnabled() &&
							<Menu.Item
								disabled={!isTransferEnabled()}
								name={t('sendmoneyTranslation.data.cospayuserheader')}
								active={transferType === TransferType.CONTACT}
								onClick={() => setTransferType(TransferType.CONTACT)}
								icon="chevron right" />
						}
						{isOwnTransferEnabled() &&
							<Menu.Item
								disabled={!isOwnTransferEnabled()}
								name={t('sendmoneyTranslation.data.ownheader')}
								active={transferType === TransferType.INTERNAL}
								onClick={() => setTransferType(TransferType.INTERNAL)}
								icon="chevron right" />
						}
						{isTopupByCardEnabled() &&
							<Menu.Item
								disabled={!isTopupByCardEnabled()}
								name={t('sendmoneyTranslation.data.card')}
								active={transferType === TransferType.CARD}
								onClick={() => setTransferType(TransferType.CARD)}
								icon="chevron right" />
						}
						{isTrustTransferEnabled() &&
							<Menu.Item
								name={agent === Agents.DTSUS ? t('sendmoneyTranslation.data.trustHeaderDtsus') : t('sendmoneyTranslation.data.trustHeader')}
								active={transferType === TransferType.TRUST}
								onClick={() => setTransferType(TransferType.TRUST)}
								icon="chevron right" />
						}
					</Menu>
				</Grid.Column>
				{account && accountLimits && process && <Grid.Column width={8}>
					{transferType === TransferType.EXTERNAL && <BankAccount subProcesses={process.subProcesses} onSend={onSend} account={account} integration={process.integration} accountLimits={accountLimits} />}
					{transferType === TransferType.TRUST_DOMESTICS && <TrustDomesticPayment subProcesses={process.subProcesses} onSend={onSend} account={account} integration={process.integration} accountLimits={accountLimits} />}
					{transferType === TransferType.CONTACT && <CospayUser subProcesses={process.subProcesses} onSend={onSend} account={account} accountLimits={accountLimits} />}
					{transferType === TransferType.INTERNAL && <CospayAccount subProcesses={process.subProcesses} onSend={onSend} account={account} />}
					{transferType === TransferType.TRUST && <TrustAccount onSend={onSend} account={account} subProcesses={process.subProcesses} accountLimits={accountLimits} />}
					{transferType === TransferType.CARD && <CardTransfer account={account} subProcesses={process.subProcesses} />}
				</Grid.Column>}
			</Grid>
		</div>);
}

export default SendMoney;
