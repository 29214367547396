import React, { useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { Accordion, Grid, Icon, List, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { BankAccount } from '@features/account/types';
import './BankAccountDetails.css';
import { Agents } from '@helpers/globalTypes';
import { UserType } from '@features/user/types';

type BankAccountDetailsProps = {
    bankAccount: BankAccount
}

const BankDetails = ({ bankAccount }: BankAccountDetailsProps): React.ReactElement => {
    const { i18n, t } = useTranslation('translations');
    const [active, setActive] = useState<boolean>(false);

    const { status, user } = useSelector(
        (state: RootState) => state.user
    );

    const { agent } = useSelector(
        (state: RootState) => state.status
    );
    const [accountName, setAccountName] = useState<string>(bankAccount.name);

    useEffect(() => {
        setAccountName(bankAccount.name);
    }, [bankAccount, i18n.language]);

    const handleClick = () => {
        setActive(!active);
    };

    const isTrustInfoShown = () => {
        return status.source === 'DTSCB' && user.type === UserType.INDIVIDUAL && !user.onBehalfOf;
    };

    return (
        <Grid className='bacnkaccountDataGrid' container stackable>
            {bankAccount && bankAccount.status === 'ORDERED' ? <>
                <Grid.Row>
                    <Grid.Column>
                        <Grid className='info-notification' columns={2}>
                            <Grid.Row>
                                <Grid.Column style={{ width: 'fit-content', paddingRight: '0' }} only='computer tablet'>
                                    <Icon size='large' name='info circle' color='blue' />
                                </Grid.Column>
                                <Grid.Column width={16} only='mobile' style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }}>
                                    <Icon size='large' name='info circle' color='blue' />
                                </Grid.Column>
                                <Grid.Column width={15}>
                                    <strong>{t('banking.data.ordered.title')}</strong>
                                    <br />
                                    <p>{t('banking.data.ordered.text')}</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
            </> :
                <>
                    {bankAccount.providerType === 'POOLING' && <Grid.Row>
                        <Grid.Column>
                            <Grid className='info-notification' columns={2}>
                                <Grid.Row>
                                    <Grid.Column style={{ width: 'fit-content', paddingRight: '0' }} only='computer tablet'>
                                        <Icon size='large' name='info circle' color='blue' />
                                    </Grid.Column>
                                    <Grid.Column width={16} only='mobile' style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }}>
                                        <Icon size='large' name='info circle' color='blue' />
                                    </Grid.Column>
                                    <Grid.Column width={15}>
                                        <span>
                                            <strong>{t('pooling.howToUse', { accountName: accountName })}</strong>
                                        </span>
                                        <br />
                                        <span dangerouslySetInnerHTML={{ __html: t('pooling.text', { accountName: accountName }) }} />
                                        <ol style={{ margin: '0' }}>
                                            <li><span dangerouslySetInnerHTML={{ __html: t('pooling.first', { accountName: accountName }) }} /></li>
                                            {status.source !== Agents.CUSTODYFY  && <li><span dangerouslySetInnerHTML={{ __html: t('pooling.second', { accountName: accountName }) }} /></li>}
                                            {status.source === Agents.CUSTODYFY  && <li><span dangerouslySetInnerHTML={{ __html: t('pooling.second_cus', { accountName: accountName }) }} /></li>}
                                            {(status.source !== Agents.DTS && status.source !== Agents.CUSTODYFY) && <li>{t('pooling.third')}</li>}
                                            {(status.source !== Agents.DTS && status.source !== Agents.CUSTODYFY) && <li>{t('pooling.fourth')}</li>}
                                        </ol>
                                        <br />
                                        <span dangerouslySetInnerHTML={{ __html: t('pooling.important', { accountName: accountName }) }} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>}



                    <Grid.Row>
                        <Grid.Column widescreen={5}>
                            <List className="bankingList" relaxed="very" verticalAlign='middle'>
                                <List.Item>
                                    <List.Header>{t('banking.data.name')}</List.Header>
                                    <div className="accountdetailsLI">{bankAccount.recipient}</div>
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column widescreen={5}>
                            <List className="bankingList" relaxed="very" verticalAlign='middle'>
                                <List.Item>
                                    <List.Header>{t('banking.data.iban')}</List.Header>
                                    <div className="accountdetailsLI">
                                        {bankAccount.iban ||
                                            <Loader active inline size="mini" />
                                        }
                                    </div>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>
                    {bankAccount.providerType === 'POOLING' && <Grid.Row>
                        {status.source !== Agents.DTSCB && <Grid.Column widescreen={5}>
                            <List className="bankingList" relaxed="very" verticalAlign='middle'>
                                <List.Item>
                                    <List.Header>{t('banking.data.bank')}</List.Header>
                                    <div className="accountdetailsLI">{bankAccount.bankName ||
                                        <Loader active inline size="mini" />}</div>
                                </List.Item>
                            </List>
                        </Grid.Column>}
                        <Grid.Column widescreen={5}>
                            <List className="bankingList" relaxed="very" verticalAlign='middle'>
                                <List.Item>
                                    <List.Header>{t('banking.data.reference')}</List.Header>
                                    <div className="accountdetailsLI">{bankAccount?.parentWalletId??bankAccount.walletId}</div>
                                </List.Item>
                            </List>
                        </Grid.Column>
                        <Grid.Column widescreen={5}>
                            <List className="bankingList" relaxed="very" verticalAlign='middle'>
                                <List.Item>
                                    <List.Header>{t('banking.data.swift/bic')}</List.Header>
                                    <div className="accountdetailsLI">{bankAccount.swift ||
                                        <Loader active inline size="mini" />}</div>
                                </List.Item>
                            </List>
                        </Grid.Column>
                    </Grid.Row>}

                    {isTrustInfoShown() && <Grid.Row>
                        <Grid.Column>
                            <Grid className='info-notification'>
                                <Grid.Row>
                                    <Grid.Column><span><Icon size='large' name='info circle'
                                        color='blue' />{t('banking.data.dtscb.trust1')}<b>{t('banking.data.dtscb.trust2')}</b>{t('banking.data.dtscb.trust3')}
                                    </span></Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>}
                    {bankAccount.providerType !== 'POOLING' && <Grid.Row>
                        <Accordion fluid styled key='additional-details'>
                            <Accordion.Title active={active} index={0} onClick={handleClick}>
                                <Icon name='dropdown' />
                                {t('banking.data.showDetails')}
                            </Accordion.Title>
                            <Accordion.Content active={active}>
                                <Grid>
                                    <Grid.Row>
                                        {status.source !== Agents.DTSCB && <Grid.Column widescreen={5}>
                                            <List className="bankingList" relaxed="very" verticalAlign='middle'>
                                                <List.Item>
                                                    <List.Header>{t('banking.data.bank')}</List.Header>
                                                    <div className="accountdetailsLI">{bankAccount.bankName ||
                                                        <Loader active inline size="mini" />}</div>
                                                </List.Item>
                                            </List>
                                        </Grid.Column>}
                                        <Grid.Column widescreen={5}>
                                            <List className="bankingList" relaxed="very" verticalAlign='middle'>
                                                <List.Item>
                                                    <List.Header>{t('banking.data.reference')}</List.Header>
                                                    <div className="accountdetailsLI">{bankAccount.walletId}</div>
                                                </List.Item>
                                            </List>
                                        </Grid.Column>
                                        <Grid.Column widescreen={5}>
                                            <List className="bankingList" relaxed="very" verticalAlign='middle'>
                                                <List.Item>
                                                    <List.Header>{t('banking.data.swift/bic')}</List.Header>
                                                    <div className="accountdetailsLI">{bankAccount.swift ||
                                                        <Loader active inline size="mini" />}</div>
                                                </List.Item>
                                            </List>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Accordion.Content>
                        </Accordion>
                    </Grid.Row>
                    }</>}
        </Grid>
    );
};

export default BankDetails;
