import React, { useState, useEffect } from 'react';
import { Segment, Divider, Header, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import CospayAccountConfirmation from './CospayAccountConfirmation';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { runOperation } from '@features/operations/slice';
import { showErrorNotification } from '@features/swal/slice';
import { TransferType } from '../SendMoney';
import './CospayAccount.css';
import { maxTwoDecimals } from '@/helpers/number';

type SendProps = {
	onSend: (type: TransferType, value: any) => void,
	account: any,
	subProcesses: any,
}

const CospayAccount = ({ onSend, account, subProcesses }: SendProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [payload, setPayload] = useState<any>(null);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [process, setProcess] = useState<any>();

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	useEffect(() => {
		if (!subProcesses) return;
		setProcess(subProcesses[Object.keys(subProcesses).find(key => subProcesses[key]['type'] === 'OWN_TRANSFER')]);
	}, [subProcesses]);

	const validationSchema = Yup.object({
		amount: Yup.number().required(t('form.validator.required'))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'),
				(value) => value >= 0.01)
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.test('amount', t('sendmoneyTranslation.data.insufficient'), (value) => value <= account.availableBalance)
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		to: Yup.string().required(t('form.validator.required')),
	});

	const initialValues = {
		from: account.name + ' (' + account.currency + ' ' + account.availableBalance + ')',
		to: '',
		amount: ''
	};

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { to, amount } = formData;
		const payload = { ...{from: account, to: list.find(a => a.accountId === to), amount},  ...{ currency: account.currency } };
		setSubmitting(false);
		setPayload(payload);
	};

	const send = async () => {
		setSubmitting(true);
		try {
			await runOperation(process.proc, { accountFromId: payload.from.accountId, accountToId: payload.to.accountId, amount: payload.amount, ccy: payload.from.currency });
			setSubmitting(false);
			onSend(TransferType.INTERNAL, payload);
		} catch (e) {
			setSubmitting(false);
			await showErrorNotification(e);
			setPayload(null);
		}
	};

	if (payload) {
		return <div id="transferbetweenaccounts">
			<CospayAccountConfirmation payload={payload} process={process} onConfirm={send} submitting={submitting} availableAmount={account.availableBalance}  />
		</div>;
	}

	return (
		<div id="transferbetweenaccounts">
			<Grid>
				<Grid.Column width={16}>
					<Segment padded>
						<Header className="cospayuserheader" as="h3">{t('sendmoneyTranslation.data.ownheader')}</Header>
						<Divider hidden />
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting, dirty }) => (
								<Form>
									<Input
										fluid
										id="account"
										name="from"
										label={t('sendmoneyTranslation.data.from')}
										disabled 
									/>
									<Select
										fluid
										name="to"
										label={t('sendmoneyTranslation.data.to')}
										options={list.filter(i => i.accountId !== account.accountId && i.currency === account.currency).map(item => ({ value: item.accountId, key: item.accountId, text: `${item.name} (${item.currency} ${item.availableBalance})` }))}
										errorPrompt
									/>
									<Input
										fluid
										name="amount"
										label={t('sendmoneyTranslation.data.transferamount')}
										placeholder={`0.00 ${account.currency}`}
										errorPrompt
										type="number"  
										onWheel={(e) => e.target.blur()}
									/>
									<Divider section />
									<SubmitButton disabled={isSubmitting || !isEmpty(errors) || !dirty} primary fluid type="submit">{t('sendmoneyTranslation.activedata.button')}</SubmitButton>
								</Form>)}
						</Formik>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};
export default CospayAccount;
