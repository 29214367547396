import React, { useState } from 'react';
import { Button, Dimmer, Divider, Grid, Header, Loader, Modal } from 'semantic-ui-react';
import { showErrorNotification } from '@features/swal/slice';
import { deleteBulkTopUpEntry } from '@features/card/slice';

interface Props {
	detail: any,
	bulkId: number,
	onClose: (boolean) => void,
	isOpen: boolean,
	reFetch: () => void
}

const DeleteBulkTopUpEntryModal = ({ detail, bulkId, onClose, isOpen, reFetch }: Props): React.ReactElement => {

	const [loading, setLoading] = useState<boolean>(false);

	const deleteEntry = async () => {
		try {
			setLoading(true);
			await deleteBulkTopUpEntry(detail.id, bulkId);
			onClose(false);
			reFetch();
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			id='deleteBulkTopUpEntry'
			onClose={() => onClose(false)}
			open={isOpen}
			size='tiny'
		>
			<Modal.Content>
				<Dimmer active={loading} >
					<Loader inverted>Loading</Loader>
				</Dimmer>
				<Header>Delete entry</Header>
				<p className='terminateMSG'>The entry for employee <b>{detail.employeeName}</b> with code <b>{detail.employeeRef}</b> will be removed from bulk top up list.</p>
				<Divider section />
				<Grid columns={2}>
					<Grid.Column width={8}>
						<Button fluid onClick={deleteEntry} negative content='Confirm' />
					</Grid.Column>
					<Grid.Column width={8}>
						<Button fluid onClick={() => onClose(false)} basic content='Cancel' />
					</Grid.Column>
				</Grid>
			</Modal.Content>
		</Modal>
	);
};

export default DeleteBulkTopUpEntryModal;
