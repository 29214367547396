import React from 'react';
import {
	Modal,
	Header,
	Button,
	Divider
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import '../Profile.css';


interface Props {
	open: boolean;
	onClose: (value?: string) => void
}

const EditMobile = ({ open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const initialValues = {
		phone: ''
	};

	const validationSchema = Yup.object({
		phone: Yup.string()
			.matches(/^([+]|[1-9])[0-9]+$/, t('form.validator.phoneinvalid'))
			.min(4, t('form.validator.phonemin'))
			.test('phoneNumber',
				t('form.validator.phoneinvalid'),
				(value) => isValidPhoneNumber(`+${value}`))
			.required(t('form.validator.required')),

	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { phone } = formData;
		onClose(`+${phone}`);
	};

	return <Modal
		size="tiny"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Header textAlign='center' content='Edit phone number' />
			<Divider hidden />
			<Formik
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={submit}
			>
				{({ errors, isSubmitting, dirty }) => (
					<Form
						className='editMobileForm'
						size="large">
						<Input
							fluid
							name="phone"
							type="number"
							icon='plus'
							iconPosition='left'
							errorPrompt
							label={t('profile.data.phonenumber')}
							onWheel={(e) => e.target.blur()}
						/>

						<Divider />
						<div className='editBTNS'>
							<SubmitButton
								disabled={isSubmitting || !isEmpty(errors) || !dirty}
								primary size="large" type="submit">
								{t('form.buttons.save')}
							</SubmitButton>
							<Button className='discardBTN' content='Discard' onClick={() => onClose()} />
						</div>
					</Form>)}
			</Formik>

		</Modal.Content>
	</Modal>;
};

export default EditMobile;
