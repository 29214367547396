import React, { useCallback } from 'react';
import { Container, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TemplatePayload, TransactionType } from '@features/transactions/types';
import { getPdfData, resolveTemplateType } from '@features/transactions/slice';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import { Formik } from 'formik';
import request from '@services/request';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { formatDate } from '@helpers/date';
import isEmpty from 'is-empty';
import * as Yup from 'yup';
import './ShareTransaction.css';

interface Props {
	transaction: any,
	walletId?: string,
	accountType?: string
}

const ShareTransaction = ({ transaction, walletId, accountType }: Props): React.ReactElement => {
	const [open, setOpen] = React.useState(false);
	const { t } = useTranslation('translations');
	const initialValues = {
		email: ''
	};
	const validationSchema = Yup.object({
		email: Yup.string()
			.email(t('form.validator.email'))
			.required(t('form.validator.required'))
	});

	const fetchPdfData = useCallback(async (transaction) => {
		const transactionType: TransactionType = transaction.activity;
		const amount: number = transaction.amount;
		const template = resolveTemplateType(transactionType, amount);
		const payload = {
			templateIdentifier: template,
			transactionNumber: transactionType === TransactionType.FX_TRANSACTION ? transaction.docNumber : transaction.transactionNumber,
			walletId,
			accountType
		};
		return await getPdfData(payload, transaction.activity);
	}, [transaction]);

	const sendEmail = async (email: string, transaction: any) => {
		const data = await fetchPdfData(transaction);
		const base64 = btoa(
			new Uint8Array(data)
				.reduce((data, byte) => data + String.fromCharCode(byte), '')
		);
		const payload = {
			identifier: 'user.transaction.details.sent',
			payload: {
				...transaction,
				email: email,
				file: base64
			}
		};
		await request.post('/api/notifications/trigger',
			payload);
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { email } = formData;
			const formatted = {
				...transaction,
				date: formatDate(transaction.date, 'T')
			};
			await sendEmail(email, formatted);
			showSuccessNotification(t('sharetransaction.modal.sent'));
			setOpen(false);
		} catch (error) {
			setSubmitting(false);
			showErrorNotification(error);
		}
	};

	return (
		<Modal
			key={transaction.transactionNumber}
			size='tiny'
			id='shareTransactionModal'
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			closeIcon={<Icon className='inside' name='close' color='black' />}
			trigger={<div>{t('sharetransaction.modal.trigger')}</div>}
		>
			<Modal.Content>
				<Container fluid>
					<Segment basic>
						<Grid>
							<Grid.Row centered>

							</Grid.Row>
							<Grid.Row centered>
								<Header>{t('sharetransaction.modal.header')}</Header>
							</Grid.Row>
						</Grid>
						<Formik initialValues={initialValues} onSubmit={submit}
							validationSchema={validationSchema}>{
								({ errors, isSubmitting, dirty }) => (
									<Form>
										<Grid columns='equal'>
											<Grid.Column width={12}>
												<Input
													label={t('sharetransaction.modal.label')}
													fluid
													name='email'
													errorPrompt
												/></Grid.Column>
											<Grid.Column verticalAlign='bottom' textAlign='right'>
												<SubmitButton disabled={isSubmitting || !isEmpty(errors) || !dirty}
													type='submit' primary size='large'>
													{t('sharetransaction.modal.button')}
												</SubmitButton>
											</Grid.Column>
										</Grid>
									</Form>
								)
							}
						</Formik>
						<p id='shareTransactionDescription' className='color-grey'>{t('sharetransaction.modal.description')}</p>
					</Segment>
				</Container>
			</Modal.Content>
		</Modal>
	);
};

export default ShareTransaction;
