import React, { useCallback, useEffect, useState } from 'react';
import { Breadcrumb, Button, Container, Divider, Grid, Icon, Segment } from 'semantic-ui-react';
import { ShieldFilled, StatusActive, StatusInactive } from '@/icons';
import './MainScreen.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { generate2FACode } from '@features/user/userSlice';
import { openModal } from '@features/swal/slice';
import DownloadApp from '@/components/2FA/Parts/DownloadApp';
import ScanCode from '@/components/2FA/Parts/ScanCode';
import BackupKey from '@/components/2FA/Parts/BackupKey';
import Enable from '@/components/2FA/Parts/Enable';
import { useTranslation } from 'react-i18next';

const MainScreen = () : React.ReactElement => {
	const { t } = useTranslation('translations');
	const [active, setActive] = useState(1);
	const dispatch = useDispatch();
	const icon = (index) => {
		if (active === index) {
			return <StatusActive/>;
		} else {
			return <StatusInactive/>;
		}
	};
	const { user, twoFactorCode, backupKey } = useSelector(
		(state: RootState) => state.user
	);

	const fetch2FACode = useCallback(() => {
		if (user && user.twoFactorAuthEnabled) {
			return;
		}
		const getCode = async () => {
			try {
				await dispatch(generate2FACode());
			} catch (e) {
				dispatch(openModal({ header:  t('authenticator.error.header'), content: e.message }));
			}
		};

		open && getCode();
	}, []);

	useEffect(() => {
		fetch2FACode();
	}, [fetch2FACode]);

	return (
		<Container id='twoFA-enable'>
			<Segment>
				<Grid>
					<Grid.Row>
						{active > 1 &&
						<Breadcrumb size="large">
							<Breadcrumb.Section className="backchevronitem" link onClick={() => setActive(active - 1)}><Icon name="chevron left" />{t('authenticator.back')}</Breadcrumb.Section>
						</Breadcrumb>
						}
					</Grid.Row>
				</Grid>
				<Grid textAlign={'center'}>
					<Grid.Row><ShieldFilled/></Grid.Row>
					<Grid.Row><h1>{t('authenticator.header')}</h1></Grid.Row>
					<Grid.Row>{t('authenticator.description')}</Grid.Row>
					<Grid.Row columns={4}>
						<Grid.Column>
							<Grid.Row>
								{icon(1)}
							</Grid.Row>
							<Grid.Row>{t('authenticator.download.step')}</Grid.Row>
						</Grid.Column>
						<Grid.Column>
							<Grid.Row>
								{icon(2)}
							</Grid.Row>
							<Grid.Row>{t('authenticator.code.step')}</Grid.Row>
						</Grid.Column>
						<Grid.Column>
							<Grid.Row>
								{icon(3)}
							</Grid.Row>
							<Grid.Row>{t('authenticator.backup.step')}</Grid.Row>
						</Grid.Column>
						<Grid.Column>
							<Grid.Row>
								{icon(4)}
							</Grid.Row>
							<Grid.Row>{t('authenticator.enable.step')}</Grid.Row>
						</Grid.Column>
					</Grid.Row>
					{active === 1 && <DownloadApp/>}
					{active === 2 && <ScanCode twoFactorCode={twoFactorCode}/>}
					{active === 3 && <BackupKey backupKey={backupKey}/>}
					{active === 4 && <Enable/>}
					{active < 4 && <Divider/>}
					<Grid.Row>
						<Grid.Column width={3} mobile={6}>
							{active < 4 && <Button primary size='large' fluid onClick={() => setActive(active+1)}>{t('authenticator.next')}</Button>}
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</Container>
	);
};

export default MainScreen;
