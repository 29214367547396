import React, { useState, useEffect } from 'react';
import { Modal, Segment, Divider, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { TickerRequestMoney, TicketType } from '@features/tickets/types';
import { postPaymentRequest } from '@features/tickets/slice';
import { PaymentRequest } from '@features/tickets/types';
import { showErrorNotification } from '@features/swal/slice';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Input, Select, TextArea, SubmitButton } from 'formik-semantic-ui-react';
import { maxTwoDecimals } from '@/helpers/number';

function RequestMoneyForm({ initialValues, validationSchema, submit, contact }: { initialValues: any, validationSchema: any, submit: any, contact: TickerRequestMoney }): React.ReactElement {
	const { t } = useTranslation('translations');
	const [meta, setMeta] = useState<any>();
	const [_accounts, setAccounts] = useState<Array<any>>([]);

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const { accounts } = useSelector(
		(state: RootState) => state.trust
	);


	useEffect(() => {
		if (!contact) return;
		switch (contact.incidentType) {
			case TicketType.TRANSFER_REQUEST:
				try {
					setMeta(JSON.parse(contact.requestMeta));
				}
				catch (e) {
					setMeta(null);
				}
				break;
		}
	}, [contact]);

	useEffect(() => {
		if (!meta || !list) return;
		if (meta?.contactType === 'BANK') {
			setAccounts(list.filter(account => meta.account.currency === account.currency));
		} else if (meta?.contactType === 'TRUST') {
			setAccounts(accounts.filter(account => meta.account.currency === account.currency));
		} else {
			setAccounts(list.filter(account => meta.account.currency === account.currency));
		}
	}, [meta, list]);

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={submit}
			enableReinitialize={true}
		>
			{() => (
				<Form>
					<Select
						fluid
						name="to"
						label={t('sendmoneyTranslation.data.to')}
						options={_accounts.map(item => ({ value: item.accountId, key: item.accountId, text: `${item.name} (${item.currency} ${item.availableBalance})` }))}
						errorPrompt
					/>
					<Input
						fluid
						name="amount"
						label={t('sendmoneyTranslation.data.transferamount')}
						placeholder='0.00 EUR'
						type='number'
						errorPrompt
					/>
					<TextArea name="reference"
						errorPrompt
						label={t('sendmoneyTranslation.data.reference')}
						maxLength="140" />
					<Divider />
					{meta && <List relaxed>
						<List.Item>
							<List.Header>{t('sendmoneyTranslation.data.from')}</List.Header>
							{meta.user.firstName} {meta.user.lastName}
						</List.Item>
					</List>}
					<Divider section />
					<SubmitButton primary fluid type="submit">{t('sendmoneyTranslation.activedata.button')}</SubmitButton>
				</Form>)}
		</Formik>
	);
}
interface Props {
	contact: TickerRequestMoney;
	open: boolean;
	onClose: () => void;
}

function RequestMoney({ open, onClose, contact }: Props): React.ReactElement {
	const { t } = useTranslation('translations');
	const [initialValues] = useState<any>({ to: '', amount: '', reference: '' });

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const { user } = useSelector(
		(state: RootState) => state.user
	);
 
	const validationSchema = Yup.object({
		amount: Yup.number().required(t('form.validator.required'))
		.min(0.01, t('form.validator.minNumber', {field: t('sendmoneyTranslation.data.transferamount'), amount: '0.01'}))
		.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		to: Yup.string().required(t('form.validator.required')),
		reference: Yup.string().label( t('sendmoneyTranslation.data.referenceField')).required(t('form.validator.required'))
		.min(3, t('form.validator.minChars', {field: t('sendmoneyTranslation.data.referenceField'), chars: '3'})).max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { reference, amount, to } = formData;
		const account = list.find(account => account.accountId === to);

		try {
			const data = contact;
			const payment: PaymentRequest = {
				toUserId: `${data.userId}`,
				reference: reference,
				requestMeta: JSON.stringify({ user: { id: user.id, firstName: user.firstName, lastName: user.lastName }, amount, account: { accountId: account.accountId, currency: account.currency, currencySymbol: account.currencySymbol } })
			};
			await postPaymentRequest(payment);
			onClose();
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Modal
			size="mini"
			open={open}
			onClose={() => onClose()}>
			<Modal.Content>
				<Segment basic>
					<RequestMoneyForm contact={contact} initialValues={initialValues} validationSchema={validationSchema} submit={submit} />
				</Segment>
			</Modal.Content>
		</Modal>
	);
}

export default RequestMoney;
