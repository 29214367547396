import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { Operations } from './types';
import request from '../../services/request';
import _ from 'lodash';

const slice = createSlice({
	name: 'operations',
	initialState: { operations: [], loading: false },
	reducers: {
		setOperations(state, action: PayloadAction<Array<Operations>>) {
			state.operations = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		}
	}
});

export const { setOperations, setLoading } = slice.actions;

export const operationsSelector = (state: { operationsSelector: Array<Operations> }) =>
	state;

export const getOperations = (): AppThunk => {
	return async dispatch => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/operations');
			const { data } = response;
			dispatch(setOperations(data));
			return data;
		} catch (e) {
			// TODO catch error
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getOperationsByType = async (type: string) => {
	const response = await request.get('/api/operations/' + type);
	const { data } = response;
	return data;
};

export const runOperation = async (operation: string, payload: any) => {
	const response = await request.post('/api/operations/', { operation, data: payload });
	const { data } = response;
	return data;
};

/* returns supbrocess of ceratin type, usage exmaple :
const subProcess = tranformSubProcessByType(payload?.token?.subProcesses)['TRANSFER'];  */
export const tranformSubProcessByType = (subProcesses: any) => {
	return _.mapKeys(subProcesses, (value) => {
		return value.type;
	})||{};
};

export const ixopayComplete = async (activityId: string) => {
	const response = await request.patch(`/api/operations/ixopay/complete/${activityId}`);
	const { data } = response;
	return data;
};


export default slice.reducer;
