import React, { useCallback, useState } from 'react';
import { Button, Container, Grid, Header, Icon, Modal, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { postFile, postTransactionAttachments } from '@features/files/slice';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';

interface Props {
	transaction: any
}

const AddPaymentReceipt = ({ transaction }: Props): React.ReactElement => {
	const [open, setOpen] = useState(false);
	const [receipt, setReceipt] = useState(null);
	const { t } = useTranslation('translations');

	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			setReceipt(file);
		});
	}, []);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });
	const files = acceptedFiles.map(file => (
		<span key={file.name}>
			{file.name}
		</span>
	));

	const onSave = () => {
		const post = async () => {
			try {
				const attachments: Map<string, string> = new Map<string, string>();
				if (receipt) {
					const formData = new FormData();
					formData.append('file', receipt);
					const fileRecord = await postFile(formData);
					const { key } = fileRecord;
					attachments.set(key, receipt.path);
				}
				const payload = {
					attachments: Object.fromEntries(attachments)
				};
				await postTransactionAttachments(payload, transaction.transactionNumber);
				showSuccessNotification('Success!');
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setOpen(false);
			}
		};
		post();
	};


	return (
		<Modal
			key={transaction.transactionNumber}
			size='tiny'
			id='shareTransactionModal'
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			closeIcon={<Icon className='inside' name='close' color='black' />}
			trigger={<div>{t('transaction.dropdown.addPaymentReceipt')}</div>}
		>
			<Modal.Content>
				<Container fluid>
					<Segment basic>
						<Grid>
							<Grid.Row centered>
								<Header>{t('transaction.dropdown.addPaymentReceipt')}</Header>
							</Grid.Row>
							<Grid.Row>
								{t('transaction.dropdown.paymentReceiptHeader')}
							</Grid.Row>
						</Grid>
						<Grid>
							<Grid.Row>
								<div {...getRootProps()} id='file-container'>
									<input {...getInputProps()} id='file-drop' />
									<span><Icon name='upload' /> {t('employee.verification.form.document_back.upload')}</span>
								</div>
							</Grid.Row>
							{files.length > 0 &&
								<Grid.Row>
									<Icon size="large" name="file outline" />
									<div className="fileName">{files}</div>
								</Grid.Row>}
							<Grid.Row only='mobile' columns={2}>
								<Grid.Column>
									<Button disabled={files.length === 0} primary fluid size='large' onClick={onSave}>Save</Button>
								</Grid.Column>
								<Grid.Column>
									<Button basic size='large' fluid onClick={() => setOpen(false)}>{t('employee.verification.form.confirm.discard')}</Button>
								</Grid.Column>
							</Grid.Row>
							<Grid.Row only='computer tablet' columns={2}>
								<Grid.Column >
									<Button disabled={files.length === 0} primary fluid size='large' onClick={onSave}>Save</Button>
								</Grid.Column>
								<Grid.Column >
									<Button basic size='large' fluid onClick={() => setOpen(false)}>{t('employee.verification.form.confirm.discard')}</Button>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Segment>
				</Container>
			</Modal.Content>
		</Modal>
	);
};

export default AddPaymentReceipt;
