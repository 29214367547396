import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './Terms.css';

function TermsDTSUS(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Terms and Conditions</h1></Grid.Row>
			</Grid>
			<Segment className="policyContent">


				<div>
					<h1>
						INTRODUCTION
					</h1>
				</div>
				<div>
					<strong>
						Last Modified: September 19, 2022
					</strong>
				</div>
				<br />
				<div>
					<p>
						Mio Payments Inc., (hereinafter “Mio Pay”) a Canadian Company with
						incorporation number BC1333237, located at Suite 2500, Park Place 666,
						Burrard St., Vancouver, BC, V6C 2X8, Canada, and regulated by FINTRAC
						as a Money Service Business (MSB) under registration number M21336959.
					</p>
				</div>

				<div>
					<p>
						Mio Pay is the Platform owner. Mio Pay helps customers to reach
						Services and create legal relationship between Clients and Service
						Providers, namely Prime Trust LLC for digital assets and payments
						services and SPM s.r.l for membership programs.
					</p>
				</div>

				<div>
					<p>
						In consideration for permitting your access to our website and online
						services and other good and valuable consideration, you agree as
						follows:
					</p>
				</div>

				<div>
					<p>
						These terms and conditions (the &ldquo;Terms&rdquo;) form a legally binding
						agreement which govern your access to and use of our website (the
						&ldquo;Website&rdquo;, &ldquo;Site&rdquo; or &ldquo;Platform&rdquo;) and our corresponding Android and iOS
						applications (collectively the &ldquo;Services&rdquo;).
					</p>
				</div>

				<div>
					<p>
						By using our Website and Services, you, the user (&ldquo;you&rdquo; or &ldquo;your&rdquo;),
						represent and warrant that (i) you are at least 18 years old; (ii) you
						are not on any Canadian, European or US government list of prohibited,
						sanctioned or restricted persons; and (iii) you have read and
						understand these Terms and agree to be bound by them. Unless the above
						representations and warranties are true, you are not permitted to
						establish an account (an &ldquo;Account&rdquo;) with us or otherwise use our
						Services.
					</p>
				</div>

				<div>
					<p>
						If you are using the Website or Services on behalf of, or in the employ
						of, an organization (corporation, trust, partnership, etc.), you are
						agreeing to these Terms for that organization and representing and
						warranting that you have the authority to bind that organization to
						these Terms. In such a case, &ldquo;you&rdquo; and &ldquo;your&rdquo; will also refer to that
						organisation and yourself individually. For greater clarity, both you
						as an individual and your organisation are legally bound by these Terms
						which form an agreement between you and us.
					</p>
				</div>
				<br />
				<div>
					<h1>
						PRIVACY‍
					</h1>
				</div>
				<br />
				<div>
					<p>
						We use your personal information in accordance with these Terms and our
						privacy policy, which is available online on our website as amended
						from time to time (the “Privacy Policy”). By using our Website and
						Services, you consent to such processing and you represent that all
						information provided by you is accurate.‍‍‍
					</p>
				</div>
				<br />
				<div>
					<h1>
						AMENDMENTS
					</h1>
				</div>
				<br />

				<div>
					<p>
						As our Website and Services continue to change, we may, at any time,
						revise these Terms and our policies by updating this page or the page
						hosting the relevant policy. The date of the last version of these
						Terms is posted above. As you are bound by these Terms each time you
						visit our Website or use our Services, you are responsible for
						periodically reviewing the amendments to these Terms and you are deemed
						to have accepted and agreed to such amendments by accessing and using
						the Website and Services after such amendments have been posted. We may
						also undertake to send you notice of any changes to the Terms or
						policies via email and/or a notification on the Services.
					</p>
				</div>

				<div>
					<p>
						If you do not agree with the amendments, you must immediately stop
						accessing the Website and Services and terminate your Account, subject
						to the provision of these Terms.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ABOUT OUR SERVICES – SERVICE PROVIDERS
					</h1>
				</div>
				<br />

				<div>
					<p>
						Payment and Digital Assets services are powered and ultimately provided
						by Prime Trust, LLC, a Registered Money Transmitter Company located at
						330 S. RAMPART BLVD, LAS VEGAS, NV, 89145, USA.
					</p>
				</div>

				<div>
					<p>
						Rewards Program is provided by SPM s.r.l, a company located at Fontana
						dell&apos;Oste, 29/A, Colleferro (RM) 00034 Italy, with company number
						RM-1601530.
					</p>
				</div>
				<br />
				<div>
					<h1>
						CUSTODY AND OWNERSHIP OF ELIGIBLE DIGITAL ASSETS
					</h1>
				</div>
				<br />
				<div>
					<h2>
						Appointment of Custodian
					</h2>
				</div>
				<br />
				<div>
					<p>
						When you purchase Digital Assets via our Services, they will be
						delivered and held under the custody of a third-party Digital Asset
						custodian (“Custodian(s)”) to be held by the Custodian for your benefit
						in accordance with these Terms.
					</p>
				</div>

				<div>
					<p>
						Please note that when you use our custody service to store eligible
						digital assets you will not receive any fee, rewards or financial
						compensation of any kind on eligible digital assets so stored.
					</p>
				</div>
				<br />
				<div>
					<h2>
						Title &amp; Ownership
					</h2>
				</div>
				<br />
				<div>
					<p>
						The Digital Assets held in your Account are fully-paid assets
						beneficially owned by you. The Custodian will record your Digital
						Assets in its books as separate and apart from anyone else’s Digital
						Assets and from Custodian own property. The Custodian records will at
						all times provide for the separate identification of the Digital Assets
						owned by each client.
					</p>
				</div>

				<div>
					<p>
						The Custodian will not transfer, sell, loan or otherwise rehypothecate
						Eligible Digital Assets unless specifically instructed by you, except
						as required by valid court order, competent regulatory agency,
						government agency or applicable law. As title owner of assets, you bear
						all risk of loss. The Custodian shall have no liability for any Digital
						Asset price fluctuations or any or all loss of Digital Assets.
						Notwithstanding the foregoing, we may suspend your access to the
						Services, including the Custody Service and your access to a Custody
						Wallet, in the event of market disruptions or periods of volatility. We
						will use reasonable care and commercially reasonable efforts in
						connection with the Custody Service to store and secure Eligible
						Digital Assets. You understand that your use of the Custody Service
						does not create a fiduciary relationship between you and the Custodian.
						We have no duty to inquire into, supervise, or determine the
						suitability of any transaction you initiate involving Eligible Digital
						Assets in a Custody Wallet. Eligible Digital Assets held by the
						Custodian may be commingled with the Eligible Digital Assets of other
						Users, and Custodian is under no obligation to return the actual
						Eligible Digital Assets initially transferred by you to a Custody
						Wallet, but will return Eligible Digital Assets of the identical type
						reflected in your Account at the time you request such a return. We may
						provide information regarding the Eligible Digital Assets in a Custody
						Wallet to comply with any applicable law, regulation, rule, or request
						by law enforcement or government agencies.
					</p>
				</div>
				<br />
				<div>
					<h1>
						DEPOSITING DIGITAL ASSETS, TRANSACTIONS AND THIRD-PARTY CUSTODY
					</h1>
				</div>
				<br />
				<div>
					<p>
						You agree and acknowledge that you are the initiator and beneficiary of
						all transfers to your Account, to the extent that the transfer is
						within your control. You acknowledge that any incoming transfer not
						initiated or not requested to be initiated by you may not be released
						to your Account, until or unless you submit information on the person
						or entity who initiated the transfer. You also acknowledge that we
						reserve the right to conduct a manual review of your account and any
						incoming transfer(s), to ensure that the funds can be released to your
						account. This review period is usually completed within 48 to 72 hours,
						but it may take longer.
					</p>
				</div>

				<div>
					<p>
						By using our Services, you consent to the use of and our integration
						with each Digital Asset wallet provider and each Custodian to act as a
						custodian of your Digital Assets. You further consent to the Digital
						Assets which you purchase or deposit in connection with our Services
						being delivered to, deposited with and held by a Custodian on your
						behalf.
					</p>
				</div>

				<div>
					<p>
						We make no representations or warranties as to whether such deposits
						are insured by any Deposit Insurance Mechanism.
					</p>
				</div>

				<div>
					<p>
						We do not, nor do we purport to have, ownership of Digital Assets which
						are delivered to each Custodian by us, on your behalf, in connection
						with the purchase or exchange of Digital Assets you make on our
						Services.
					</p>
				</div>

				<div>
					<p>
						To the extent you purchase or deposit Digital Assets which are held by
						a Custodian in connection with our Services, you agree to keep your
						Digital Assets free from any liens, encumbrances, charges, or claims,
						unless disclosed and agreed to by us and the applicable Custodian, in
						writing. You represent and warrant that any Digital Assets you purchase
						or exchange on our Services are being purchased and exchanged by you
						directly, and not for or on behalf of any third-party. You are not
						authorized to use your Account on our Services for or on behalf of any
						other person or entity.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ESTABLISHING AN ACCOUNT
					</h1>
				</div>
				<br />
				<div>
					<p>
						To establish an Account you will be required to provide certain
						personal information as set out on the Services, in our Privacy Policy
						and these Terms, including your name, Tax payer identification number
						(TIN), address, phone number, identity documents, identity numbers, and
						an email address.
					</p>
				</div>

				<div>
					<p>
						You also agree that all incoming transfers to your Account will be
						initiated by you, or caused or requested to be initiated by you.
					</p>
				</div>

				<div>
					<p>
						If you are establishing an Account in the name of an organization or
						business, such as a corporation, you may be required to provide a list
						of documents as per our request.
					</p>
				</div>

				<div>
					<p>
						As part of our measures to confirm your identity (and the identity of
						each beneficial owner), we will use third party identification service
						providers such as Onfido. To learn more about Onfido’s privacy
						practices, please visit&nbsp;
						<a
							href="https://onfido.com/privacy/"
							target="_blank"
							rel="noreferrer noopener"
						>
							https://onfido.com/privacy
						</a>.
					</p>
				</div>

				<div>
					<p>
						We may send copies of your identity documents and personal information
						to such third-party providers for the purpose of confirming your
						identity and preventing fraud. In some instances, we may need to speak
						with you via phone to validate your Account information and your intent
						to register an Account with us. You agree that we may use the phone
						number registered with your Account for the above purposes. On such
						phone calls you must not reveal your Account passwords or passphrases
						for our or any other services.
					</p>
				</div>

				<div>
					<p>
						Phone calls may be recorded for our own internal quality control and
						training purposes. To assist us with Account and information
						validations we may also use third-party service providers. Such service
						providers may have access to your personal information, including for
						example, your identity documents, photos you upload to the Service and
						contact information. Third party service providers may also call you,
						on our behalf, to assist us with your identity and Account
						verification.
					</p>
				</div>

				<div>
					<p>
						Upon applying for an Account on behalf of a business or other
						organization, you represent and warrant that:
					</p>
				</div>
				<div>
					<div>
					</div>
					<div>
						<ul>
							<li>
								<p>
									you are fully authorized to bind the business or other
									organization;
								</p>
							</li>
							<li>
								<p>
									the business or organization is duly authorized to
									establish the Account with us and enter these Terms;
								</p>
							</li>
							<li>
								<p>
									you will fully disclose the names and addresses of all
									beneficial owners of the business, organization and Account
									who have a beneficial ownership or control interest (or
									other similar beneficial interest) of 20% or more;
								</p>
							</li>
							<li>
								<p>
									none of the beneficial owners of the organization are an
									individual, entity, organization, government or country
									upon which the Government of Canada, the European Union or
									the United States of America has imposed sanctions.
								</p>
							</li>
						</ul>
					</div>
				</div>

				<div>
					<p>
						Upon establishing an Account, we grant you a non-transferable,
						non-exclusive license to access the Services in accordance with these
						Terms. However, we reserve the right to revoke that license and your
						access to our Website and Services without cause or justification at
						any time and without advanced notice.
					</p>
				</div>

				<div>
					<p>
						If you establish an Account with us, access to your Account is not
						transferable and is only intended for you, the individual who
						established the Account (whether for yourself, or for and on behalf of
						your organization). As a result, you are not permitted to change the
						name associated with your Account, nor are you permitted to purchase,
						deposit or store Digital Assets for or on behalf of anyone else. If you
						wish to change your address registered with us, we will ask you to
						provide information to verify your address change including such
						supporting documents as specified on the Website, or in our
						correspondence with you.
					</p>
				</div>

				<div>
					<p>
						You are only permitted to hold one Account at a time on our Services.
						You agree not to establish more than one Account at any time and that
						we may remove Accounts we deem to be duplicates. In making such a
						determination, we may consider any personal information you provide to
						us, or which we have collected pursuant to our Privacy Policy. You
						agree to promptly provide us with such information and documents we may
						request from time-to-time, regarding your use of our Services. This may
						include the names of individuals you are sending or receiving Digital
						Assets to or from in connection with our Services, or as may otherwise
						be required for our compliance with applicable laws, rules and
						regulations.
					</p>
				</div>

				<br />
				<div>
					<h1>
						ACCEPTABLE USE OF YOUR ACCOUNT
					</h1>
				</div>
				<br />
				<div>
					<p>
						You agree that:
					</p>
				</div>

				<div>
					<div>
						<ul>
							<li>
								<p>
									You will not use the Website or Services in any way that
									intentionally or unintentionally violates any applicable
									local, state, provincial, national or international law,
									rule or regulation;
								</p>
							</li>
							<li>
								<p>
									Under no circumstances shall you buy, withdraw or transfer
									any Digital Assets for any illegal purpose, or in the
									furtherance of a crime;
								</p>
							</li>
							<li>
								<p>
									You will not send or receive Digital Assets to or from any
									individual, entity or country upon which the Government of
									Canada, the United States of America or the European Union
									has imposed sanctions;
								</p>
							</li>
							<li>
								<p>
									You will not impersonate any person or entity or use false
									identities, documents or information on the Website,
									Services or in your interactions with us;
								</p>
							</li>
							<li>
								<p>
									You will not communicate with our staff or contractors in a
									way that is defamatory, harmful, hateful, harassing,
									bullying, threatening, racially or ethnically offensive or
									abusive;
								</p>
							</li>
							<li>
								<p>
									You will not translate, reverse engineer, decompile,
									disassemble, modify or create derivative works based on the
									Website or Services, in whole or in part;
								</p>
							</li>
							<li>
								<p>
									You will not circumvent, disable, violate or otherwise
									interfere with any security related feature of the Website
									or Services; and
								</p>
							</li>
							<li>
								<p>
									You will not use your Account for your own commercial
									purposes by sublicensing any rights granted by the Terms
									or, in any way, sharing the benefit of your Account with
									others.
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div>
						<p>
							If we reasonably believe you are using our Services for any illegal
							purpose, including but not limited to, in connection with
							facilitating transactions involving persons or entities who are
							under economic or other sanctions imposed by the Canadian, US or
							other governmental or international authority, we may terminate
							your Account and require that you liquidate your Assets owned with
							us, or take such other reasonable measures to return your funds,
							such as, paying the funds into a court where you may claim them.
						</p>
					</div>
				</div>
				<br />
				<div>
					<h1>
						FUNDING AND WITHDRAWING FUNDS FROM YOUR ACCOUNT
					</h1>
				</div>
				<br />
				<div>
					<p>
						All funds are held in deposit accounts at Federal Deposit Insurance
						Corporation (“FDIC”) -insured, regulated depository institutions
						selected by Prime Trust, which accounts will be held for the benefit of
						Prime Trust Account Holders (“Deposit Accounts”) and maintain the
						Deposit Accounts as omnibus accounts1. Absent any fraud or wilful
						misconduct solely attributable to us, you agree that you bear all risk
						related to the banks with which your money is held, and the loss or
						theft thereof. We make no representations or warranties as to whether
						such deposits are insured by any Deposit Insurance Mechanism.
					</p>
				</div>

				<div>
					<p>
						We support wire transfers as funding method. We make no representations
						or warranties that deposit methods currently offered will continue to
						be available to you.
					</p>
				</div>

				<div>
					<p>
						You agree that you are not permitted to fund your Account with funds
						which you hold for or on behalf of any third-party. Nor are you
						permitted to fund your Account with funds borrowed from a third-party.
						We may, at any time, ask you to (i) disclose the source of funds being
						deposited in connection with the Services; and (ii) disclose the source
						and duly respond to any questions we pose concerning the source of any
						Digital Assets you wish to, or have deposited or withdrawn in
						connection with our Services. You agree that we may, in our sole
						discretion, deactivate your Account and no longer permit the use of our
						Services based on your responses (or lack thereof) and our policies,
						including for example, our Anti-Money Laundering Policy. We further
						reserve the right to not facilitate the sale or transfer of your
						Digital Assets via our Services without providing you any reason,
						justification or cause.
					</p>
				</div>

				<div>
					<p>
						You are only permitted to use our Services, fund your Account and
						withdraw funds from your Account with a Bank account bearing the same
						legal name and address used to register your Account on the Services.
						You are not permitted to use a joint Bank account with our Services or
						in connection with any deposits or withdrawals. You agree that we may
						restrict funding methods and funding amounts for your Account.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ACCEPTANCE OF RISK AND DISCLAIMERS
					</h1>
				</div>
				<br />
				<div>
					<p>
						Everything on our website and provided as part of our service offering
						is provided &ldquo;as is&rdquo; and “as available” without warranty of any kind
						including all implied warranties and conditions of merchantability and
						fitness for a particular purpose. we hereby disclaim all warranties and
						conditions of any kind, whether express, implied or statutory.
					</p>
				</div>

				<div>
					<p>
						You agree that, while we strive to have error free and uninterrupted
						service, we do not guarantee the absence of interruptions nor shall you
						hold us liable for any damage such interruptions may cause. outages and
						downtime may occur for various reasons.
					</p>
				</div>

				<div>
					<p>
						You further acknowledge that there is a risk of malicious cyberattacks
						against our website and services, your account, your personal computing
						devices, smartphone and the third-party digital asset custodians which
						may result in the loss or theft of your funds, information and/or
						digital assets.
					</p>
				</div>

				<div>
					<p>
						You agree and acknowledge that we may suspend access to your account,
						or account functions, for any reason, including for example, scheduled
						maintenance, your failure to comply with these terms, security or fraud
						concerns or for regulatory reasons.
					</p>
				</div>

				<div>
					<p>
						You agree that you are responsible for, and shall indemnify us in
						relation to, any and all chargebacks charged to us by any bank,
						financial institution or other payment provider. You acknowledge that
						these terms permit us, without any liability to you, to liquidate your
						assets in your account to satisfy any payments owing to us, or any
						negative balance in your account.
					</p>
				</div>

				<div>
					<p>
						We reserve the right to provide your personal information uploaded or
						collected by our website or services, or otherwise provided to us, to
						third parties if required by law, and to cooperate with law enforcement
						authorities in the investigation of any criminal or civil matter. Such
						personal information may include, but is not limited to, the
						information described in these terms and our privacy policy, including
						your name, bank account information and transaction history.
					</p>
				</div>

				<div>
					<p>
						If we are required by law to make any disclosure of your personal
						information, we may (but are not obligated to) provide you with written
						notice (to the extent permitted by law) prior to such disclosure so
						that you may take appropriate action.
					</p>
				</div>

				<div>
					<p>
						We also operate the website and services in conjunction with our
						independent contractors. Our independent contractors may have access to
						your personal information in providing services to us, or providing you
						with access to the website and services. We may use a variety of
						service providers, employees and servers in locations both inside and
						outside of Canada or the United States of America, which may make your
						personal information the subject of foreign laws and foreign legal
						proceedings.
					</p>
				</div>

				<div>
					<p>
						To the fullest extent permitted by law, without limiting the generality
						of this section, or any other section limiting our liability, you agree
						that we shall not be responsible for the acts or omissions, including
						the negligent acts or omissions, of our independent contractors or
						third-party service providers.
					</p>
				</div>
				<br />
				<div>
					<h1>
						LIMITATION OF OUR LIABILITY
					</h1>
				</div>
				<br />
				<div>
					<p>
						You agree that, to the fullest extent permitted by law, in no event
						will we, our affiliates, officers, directors, shareholders, employees,
						contractors, agents or suppliers be liable, howsoever caused (including
						by negligence or otherwise), for any direct, indirect, special,
						incidental, punitive, exemplary or consequential damages you suffer,
						whether or not we have been warned of the possibility of such damages
						and whether those damages were foreseeable or not.
					</p>
				</div>

				<div>
					<p>
						If you are dissatisfied with the website or services, or do not agree
						with any part of these terms, or have any other dispute or claim with
						or against us, our affiliates, officers, directors, shareholders,
						employees, contractors, agents, suppliers or licensors, then your sole
						and exclusive remedy is to stop using the services.
					</p>
				</div>
				<div>
					<div>
					</div>
					<div>
						<p>
							In addition to and separate from your agreement to not hold the
							above entities and persons liable for any damages, in the event a
							court or arbitrator of competent jurisdiction declines to uphold
							said clause, you agree that:
						</p>
					</div>
					<div>
					</div>
					<div>
						<ul>
							<li>
								<p>
									In no circumstances shall the aggregate liability for any
									and all claims relating to or in any way arising from the
									use of our website or services, or in any way related to
									these terms, be more than the amounts paid by you to us as
									fees, if any and if implemented, or which we have earned as
									a result of your account.
								</p>
							</li>
							<li>
								<p>
									In no circumstances shall we be liable or responsible for
									any loss of profits or loss of expected revenue or gains.
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div>
					<div>
						<ul>
							<li>
								<p>
									In no circumstances shall we be liable or responsible for
									any damages you suffer arising from the loss of an
									opportunity, including for example, the loss of an
									opportunity to buy, or sell any asset.
								</p>
							</li>
							<li>
								<p>
									In no circumstances shall we be liable or responsible for
									any loss or damage whatsoever which does not arise directly
									as a result of our breach of this agreement (whether or not
									you are able to prove such loss or damage).
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div>
						<p>
							You agree and acknowledge that we would not enter into this
							agreement or grant access to the website or services without the
							above restrictions and limitations on our liability.
						</p>
					</div>
					<div>
						<h1>
							INDEMNIFICATION AND HOLD HARMLESS
						</h1>
					</div>
				</div>
				<div>
					<p>
						You agree to indemnify us, our affiliates, employees, shareholders,
						directors, agents, representatives and contractors, and to defend and
						hold each of them harmless, from any and all claims and liabilities
						(including reasonable legal fees) which may arise from, (i) your
						violation of these terms, or any policy incorporated by reference; (ii)
						your violation of any third party right; (iii) any breach of a
						representation or warranty made by you to us, either in these terms,
						privacy policy or otherwise; (iv) any breach by you of applicable laws,
						rules or regulations; or (v) any claim for damages brought against us
						by the custodian or their affiliates, or any bank or financial
						institution, related to or arising from your account or your use of the
						services.
					</p>
				</div>

				<br />
				<div>
					<h1>
						DISPUTES, GOVERNING LAW AND JURISDICTION
					</h1>
				</div>
				<br />
				<div>
					<p>
						These Terms, all documents incorporated by reference and your
						relationship with us shall be governed by, construed and enforced
						exclusively in accordance with, the laws of the State of Nevada,
						without regard to its conflicts of laws provisions or rules.
					</p>
				</div>

				<div>
					<p>
						In respect of a claim by either you or us arising out of or in any way
						related to these Terms, all documents incorporated by reference and
						your relationship with us, you agree to irrevocably and unconditionally
						submit to the exclusive jurisdiction of the courts and tribunals of the
						State of Nevada for the settlement thereof.
					</p>
				</div>
				<br />
				<div>
					<h1>
						SEVERABILITY
					</h1>
				</div>
				<br />
				<div>
					<p>
						If any provision of these Terms is found to be unlawful, void, or for
						any reason unenforceable, then that provision shall be deemed severable
						from this agreement and shall not affect the validity and
						enforceability of any remaining provisions.
					</p>
				</div>

				<div>
					<p>
						Where a provision in these Terms is found to be unlawful, void or for
						any reason unenforceable, a lawful or enforceable term which best
						reflects the intention of the provision, as originally drafted, shall
						substitute at the discretion of the court.
					</p>
				</div>
				<br />
				<div>
					<h3>
						No Interpretation Against Us
					</h3>
				</div>
				<br />
				<div>
					<p>
						If an ambiguity or question of intent arises with respect to any
						provision of these Terms, the Terms shall be construed as if drafted
						jointly by the parties and no presumption or burden of proof will arise
						favoring or disfavouring either party by virtue of authorship of any of
						the provisions of these Terms.
					</p>
				</div>
				<br />
				<div>
					<h3>
						Waiver of Class Proceedings and Trial by Jury
					</h3>
				</div>
				<br />
				<div>
					<p>
						To the extent permitted by law, you hereby waive your right to
						participate in any class action lawsuits against us, our contractors,
						employees, shareholders, successors, assigns and directors.
					</p>
				</div>

				<div>
					<p>
						To the extent permitted by law, you further waive any right to a trial
						by jury, should such a right exist, in relation to any legal dispute
						connected to or in any way arising out of these Terms.
					</p>
				</div>
				<br />
				<div>
					<h1>
						ACCOUNT FREEZES
					</h1>
				</div>
				<br />
				<div>
					<p>
						We are subject to stringent requirements, including anti-money
						laundering laws. As part of our requirements under applicable laws, it
						might be necessary for us to temporarily pause or permanently terminate
						activities involving your Account.
					</p>
				</div>

				<div>
					<p>
						We may place a freeze on your Account (“Account Freeze”) if we are
						required to do so as part of our regulatory requirements, if we believe
						your Account or your use of the Services does not comply with this
						agreement, if we believe your Account or your use of the Services
						exposes us, yourself, another user or third party to excessive
						financial, security, legal, reputational or any other risk, or if we
						are required to do so as part of any other legal requirement. We may
						communicate with you to resolve any Account Freeze, which may include
						requesting documentation or further information (to the extent that we
						are permitted by law to do so). If we do not communicate with you and
						you realize your Account has been frozen, it is your responsibility to
						reach out to our support team.
					</p>
				</div>

				<div>
					<p>
						In some circumstances, we may continue an Account Freeze for days or
						weeks, and ultimately may be required to terminate your Account and
						return your assets to you if we suspect that activities associated with
						your Account may be contrary to Canadian law, or damaging to us or
						another person. We reserve the right to impose an Account Freeze or
						terminate your Account in our sole discretion and your sole remedy for
						such an Account Freeze or termination shall be the return of your
						assets, subject to any law that requires us to do otherwise.
					</p>
				</div>

			</Segment>


			<Grid>
				<Grid.Row centered><h1>Vaults - Terms and Conditions </h1></Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<div>
					<h1>
						General Provisions
					</h1>
				</div>
				<br />
				<div>
					<p>
						DTS Money Americas (“DTS”) is a platform operated by Mio Payments Inc.,
						(“Mio Pay”, “we” or “us”) a Canadian Company with incorporation number
						BC1333237, located at Suite 2500, Park Place 666, Burrard St.,
						Vancouver, BC, V6C 2X8, Canada, and regulated by FINTRAC as a Money
						Service Business under registration number M21336959.
					</p>
				</div>
				<div>
					<p>
						Vaults services (“Services” or “Products”), a feature within DTS Money
						Americas Platform, permits eligible users (“Vault Users”) to stake
						EURST, an ERC-20 euro stablecoin backed with FDIC-insured USD held in a
						trust managed by Prime Trust LLC for the benefits of EURST token
						holders (“EURST Holders”) and/or USDC, a digital stable coin pegged to
						the United States Dollars (“USDC Holders”), for the purpose to obtain
						staking rewards.
					</p>
				</div>
				<div>
					<p>
						These terms and conditions, and any others referenced or incorporated
						herein, constitute a legally binding contract between you, as a Vault
						User, and Mio Pay, that provides you access to the Services (the
						“Agreement”). By using the Services, you accept, in addition to all of
						the terms and conditions that are otherwise set out in the General
						Terms and Conditions, the additional terms set out below in this
						Agreement.
					</p>
				</div>
				<div>
					<p>
						Please carefully review these terms before using, or continuing to use
						the Vault Services. These terms include important information about
						your relationship with us. Your use of the services is expressly
						conditioned on your consent to, and compliance with, the terms. By
						accessing or using our services, you agree to be bound by the terms. If
						you do not agree to any of the provisions of these terms you should
						immediately stop using the Services. We encourage you to read the terms
						carefully and use them to make informed decisions.
					</p>
				</div>
				<div>
					<p>
						The treatment of digital assets may change depending on the
						jurisdiction in which you reside. Please read the following terms
						carefully so that you understand these changes and can make informed
						financial decisions.
					</p>
				</div>
				<div>
					<p>
						We reserve the right to implement, change, modify, or increase any fee,
						rates or other related cost in connection with your Vault Account or
						the use of any of the Services at any time. In the event a fee applies
						to you, we will notify you of the pricing of the fee prior to your
						providing authorization to complete the subject transaction or
						transfer. By accepting the Terms you hereby agree to pay all fees
						associated with or incurred by your use of the Vault Account or any of
						the Services.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Definitions
					</h1>
				</div>
				<br />
				<div>
					<p>
						<strong>“Vault Account”</strong> means a User’s designated user account on DTS, allowing
						a User to access and use the Services, view the amount of EURST and/or
						USDC staked, any rewards gained from staking of EURST and/or USDC
						and/or promotion of EURST, and manage the User’s personal information
						and profile.
					</p>
				</div>
				<div>
					<p>
						Your Vault Account is not a bank account, deposit account, savings
						accounts, checking account, or any other type of asset account and
						should not be characterized as a banking product or service. The use of
						terms such as “account,” “account balance,” “withdraw” and similar
						language in connection with the Services does not imply or establish,
						and shall not be taken to suggest, any form of banking relationship,
						and such language is used herein as terms of convenience only in
						referring to users’ relationship with DTS.
					</p>
				</div>
				<div>
					<p>
						<strong>“AML”</strong> stands for Anti-Money Laundering, which means a set of
						procedures, laws, and regulations that are intended to stop the
						practice of generating income through illegal actions.
					</p>
				</div>
				<div>
					<p>
						<strong>“Blockchain”</strong> means a system in which records of transactions made in
						Digital Assets are maintained across several computers that are linked
						in a peer-to-peer network.
					</p>
				</div>
				<div>
					<p>
						<strong>“Digital Asset”</strong> means a digital representation of value in which
						encryption techniques are used to regulate the generation of digital
						units and verify the transfer of assets, operating independently from a
						central bank.
					</p>
				</div>
				<div>
				</div>
				<div>
					<p>
						<strong>“Eligible Digital Assets”</strong> means USDC and EURST.
					</p>
				</div>
				<div>
				</div>
				<div>
					<p>
						<strong>“EURST”</strong> means an ERC-20 EUR stablecoin backed with FDIC-insured USD
						held in a trust managed by Prime Trust LLC for the benefits of EURST
						Holders.
					</p>
				</div>
				<div>
					<p>
						<strong>“Fiat,”</strong> when used in reference to money or currency, means the coin and
						paper money of a country that is designated as legal tender,
						circulates, and is customarily used and accepted as a medium of
						exchange in the country of issuance.
					</p>
				</div>
				<div>
					<p>
						<strong>“KYC”</strong> stands for Know Your Customer (or Client), which means the
						process of a business verifying the identity of its customers or
						clients and assessing potential risks of illegal intentions for the
						business relationship.
					</p>
				</div>
				<div>
					<p>
						<strong>“Stablecoin”</strong> means a Digital Asset that is Pegged to a Fiat currency.
					</p>
				</div>
				<div>
					<p>
						<strong>“Staking”</strong> means a process that involves committing your crypto assets
						to support a blockchain network and confirm transactions.
					</p>
				</div>
				<div>
					<p>
						<strong>“USDC”</strong> means a digital stable coin pegged to the United States Dollars.
					</p>
				</div>
				<div>
					<p>
						<strong>“Virtual Wallet”</strong> or <strong>“Virtual Wallet Address”</strong> means an on-Blockchain
						virtual address in which Digital Assets can be held and transferred.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Eligibility
					</h1>
				</div>
				<br />
				<div>
					<div>
						<p>
							To be eligible to use the Services, you must:
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									Be a resident of one of our supported countries for Vault
									services
								</p>
							</li>
							<li>
								<p>
									Be in compliance with the terms and conditions;
								</p>
							</li>
							<li>
								<p>
									Be at least 18 years old or the legal age of majority in
									the jurisdiction in which you reside;
								</p>
							</li>
							<li>
								<p>
									Not be subject to any sanctions imposed by any government,
									including without limitation those imposed by the United
									Nations, the European Union, any EU country, the UK
									Treasury, or the U.S. Office of Foreign Assets Control
									(“OFAC”); and
								</p>
							</li>
							<li>
								<p>
									Read, understand, and agree to our Privacy Policy and Terms
									and Conditions.
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div>
						<p>
							Even if you meet the eligibility criteria above, we reserve the
							right to refuse your request to use and access the Vaults Section,
							to suspend your access to the Vaults Section, and terminate your
							Account at any time in our sole discretion. Further, we reserve the
							right to change the eligibility criteria at any time. If we become
							aware that you are an ineligible Vault User or otherwise determine
							that you are in violation of any applicable terms and conditions,
							we may delete your Account immediately without notice and ban you
							from using any of our products and services. In addition to the
							other rights set forth herein and to those that may be available to
							us, you agree that you will indemnify and hold harmless Mio Pay and
							its Affiliates from any legal or monetary consequences that arise
							from any unauthorized use, including without limitation, all uses
							associated with an ineligible Vault User.
						</p>
					</div>
					<br />
					<div>
						<h1>
							Vaults
						</h1>
					</div>
					<br />
				</div>

				<div>
					<p>
						If the Services are available to you, upon your election, each eligible
						user can make a deposit in the Vaults in United States Dollars.
					</p>
				</div>
				<div>
					<p>
						Upon your deposit, you hereby allow and acknowledge that the service
						provider, under these terms, will exchange the equivalent amount of
						your deposit, minus any exchange fee applicable therein, into EURST
						and/or USDC, depending on the Vault that has been chosen, and stake the
						Eligible Digital Assets on your behalf for the period of time
						corresponding to the plan duration that you chose.
					</p>
				</div>
				<div>
					<p>
						Eligible Digital Assets in staking and any Rewards earned thereon are
						visible via your Account dashboard.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Fixed Plans
					</h1>
				</div>
				<br />
				<div>
					<p>
						Each Fixed Plan requires a minimum amount of Eligible Digital Assets to
						be staked, for a specific period of time, which are disclosed in DTS.
					</p>
				</div>
				<div>
					<p>
						Each Plan will be for a fixed duration ranging from six (6) to
						thirty-six (36) months. We reserve the right to amend the terms of any
						Fixed Plan if such changes are immaterial and do not impact the
						potential rewards to be earned or the plan duration.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Rewards
					</h1>
				</div>
				<br />

				<div>
					<div>
						<strong>
							1. EURST Vault
						</strong>
					</div>
					<div>
						<p>
							While staking EURST, subject to applicable law and for the duration
							of the period during which you elect to utilize the Services, you
							commit your crypto assets to support the blockchain network and the
							growth of EURST. Consequently, depending on the staking period, you
							are eligible to receive the following Staking Rewards calculated on
							the amount of EURST staked, and distributed monthly.
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									6 months staking period – 3% APY on the amount of EURST
									staked;
								</p>
							</li>
							<li>
								<p>
									12 months staking period – 3.5% APY on the amount of EURST
									staked;
								</p>
							</li>
							<li>
								<p>
									24 months staking period – 4% APY on the amount of EURST
									staked;
								</p>
							</li>
							<li>
								<p>
									36 months staking period – 4.5% APY on the amount of EURST
									staked;
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div>
						<p>
							In addition, EURST being a newly launched stablecoin, EURST
							promotor decided to incentivize the use and massive adoption of its
							stablecoin by offering to EURST holders who are staking EURST,
							additional rewards as part of a global promoting campaign. The
							amount and distribution frequency of those additional rewards for
							the promotion of EURST are left to the discretion of EURST’s
							promotor.
						</p>
					</div>
				</div>
				<div>
					<br />
					<div>
						<strong>
							2. USDC Vault
						</strong>
					</div>
					<div>
					</div>
					<div>
						<p>
							While staking USDC, subject to applicable law and for the duration
							of the period during which you elect to utilize the Services, you
							commit your crypto assets to support the blockchain network and the
							growth of USDC. Consequently, depending on the staking period, you
							are eligible to receive the following Staking Rewards calculated on
							the amount of USDC staked, and distributed monthly.
						</p>
					</div>
					<div>
					</div>
				</div>
				<div>
					<div>
						<ul>
							<li>
								<p>
									6 months staking period – 3% APY on the amount of USDC
									staked;
								</p>
							</li>
							<li>
								<p>
									12 months staking period – 3.5% APY on the amount of USDC
									staked;
								</p>
							</li>
							<li>
								<p>
									24 months staking period – 4% APY on the amount of USDC
									staked;
								</p>
							</li>
							<li>
								<p>
									36 months staking period – 4.5% APY on the amount of USDC
									staked;
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
				</div>
				<br />
				<div>
					<h1>
						Withdrawal
					</h1>
				</div>
				<br />
				<div>
					<p>
						You acknowledge that your Eligible Digital Assets purchased and staked
						on your behalf by the service provider will be subject to a lock-up
						period equivalent to the duration of the Fixed Plan in which your
						elected to stake your assets. Consequently, you may not terminate a
						Fixed Plan and withdraw your assets before its terms.
					</p>
				</div>
				<br />
				<div>
					<h1>
						No Automatic Renewal
					</h1>
				</div>
				<br />
				<div>
					<p>
						Upon termination of a Fixed Plan, the EURST/USDC or the equivalent
						amount of the Eligible Digital Assets in staking will automatically be
						deallocated and transferred to your corresponding Account and/or wallet
						as indicated by you.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Rights of Mio Pay
					</h1>
				</div>
				<br />
				<div>
					<p>
						We reserve the right to and at any time, without liability to you to:
					</p>
				</div>
				<div>
				</div>
				<div>
					<div>
						<ul>
							<li>
								<p>
									Refuse your request to open a Vault Account;
								</p>
							</li>
							<li>
								<p>
									Change the eligibility criteria for registration or use of
									the Services;
								</p>
							</li>
							<li>
								<p>
									Close, suspend, limit, restrict, or terminate your access
									to the Services, the Website, or other products and
									services provided by our Affiliates; and
								</p>
							</li>
							<li>
								<p>
									Change, update, remove, cancel, suspend, disable, or
									discontinue any features, component, Content or incentive
									of the Services.
								</p>
							</li>
						</ul>
					</div>
					<br />
					<div>
						<h1>
							Prohibited Activities
						</h1>
					</div>
					<br />
				</div>
				<div>
				</div>
				<div>
					<p>
						By accessing and using the Services, you agree that you will not do
						any of the following:
					</p>
				</div>
				<div>
					<ul>
						<li>
							<p>
								Engage in any activity that would violate or assist in the
								violation of any law, statute, regulation, or ordinance;
							</p>
						</li>
						<li>
							<p>
								Violate or breach any terms of this Agreement, any other
								agreement or set of applicable terms and conditions and our
								Privacy Policy;
							</p>
						</li>
						<li>
							<p>
								Provide us with any false, inaccurate, or misleading
								information or otherwise attempt to defraud us;
							</p>
						</li>
						<li>
							<p>
								Attempt to gain unauthorized access to our network, system,
								or website or App;
							</p>
						</li>
						<li>
							<p>
								Decompile, disassemble, or otherwise reverse engineer our
								Website, Services, or any other related product, program,
								or system or in any way infringe on our intellectual
								property rights including, but not limited to, copyrights,
								patents, trademarks, or trade secrets;
							</p>
						</li>
					</ul>
				</div>
				<br />
				<div>
					<h1>
						Suspension and Termination
					</h1>
				</div>
				<br />
				<div>
					<p>
						We reserve the right to suspend, restrict, or terminate your
						Account if:
					</p>
				</div>

				<div>
					<div>
						<ul>
							<li>
								<p>
									We are required to do so by a court order, government
									order, or similar demand from authorities;
								</p>
							</li>
							<li>
								<p>
									We reasonably suspect you of engaging in Prohibited
									Activities as described in this Agreement; or
								</p>
							</li>
							<li>
								<p>
									We reasonably suspect you of otherwise being in violation
									of this Agreement or other applicable terms and conditions
									for products and services offered by Mio Pay or its
									affiliates.
								</p>
							</li>
						</ul>
					</div>

				</div>
				<div>
					<p>
						Upon termination of your Account, you are entitled to recover your
						Assets unless we are prohibited from doing so by law or a court order
						or where we reasonably suspect that such funds were obtained through
						fraud or any unlawful means or in connection with any criminal
						activities.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Agreement
					</h1>
				</div>
				<br />
				<div>
					<p>
						This Agreement terminates immediately upon you closing your Account or
						our termination of your Account, but such termination shall not prevent
						any party from seeking remedies against any other party for any breach
						of this Agreement occurring prior to termination.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Contact
					</h1>
				</div>
				<br />
				<div>
					<p>
						If you have any questions regarding the Services or need assistance in
						connection with the suspension or termination of your Account, please
						contact us at
						<a
							href="mailto:contact@dtsamericas.com"
							target="_blank"
							rel="noreferrer noopener"
						>
							contact@dtsamericas.com
						</a>
					</p>
				</div>
				<br />
				<div>
					<h1>
						Miscellaneous Provisions
					</h1>
				</div>
				<br />
				<div>
					<h2>
						Indemnification
					</h2>
				</div>
				<br />
				<div>
					<p>
						You will defend, indemnify, and hold harmless Mio Pay, its affiliates,
						and each of their respective employees, officers, directors, and
						representatives from and against any claims, damages, losses,
						liabilities, costs, and expenses (including reasonable attorneys’ fees)
						arising out of or related to your breach of this Agreement, your
						violation of any statute, regulation, or ordinance, or the rights of
						any third party.
					</p>
				</div>
				<br />
				<div>
					<h2>
						Risk Disclaimers
					</h2>
				</div>
				<br />
				<div>
				</div>
				<div>
					<p>
						Before using any of our Services, you should ensure that you fully
						understand and can afford to undertake the risks involved. The risks
						listed below are intended to provide you with a general outline of the
						risks involved, but cannot capture all such risks.
					</p>
				</div>
				<div>
				</div>
				<div>
					<p>
						These Terms and your use of any of our Services do not create a
						fiduciary relationship between us and you. Your Account is not a
						checking or savings account, and it is not covered by any insurance
						against losses. We have no duty to inquire into, supervise, or
						determine the suitability of any transaction you initiate involving
						your Account.
					</p>
				</div>
				<div>
				</div>
				<div>
					<p>
						Digital Assets are not legal tender. Mio Pay is not a bank or
						depository institution, and your Account is not a deposit account. Your
						Account does not constitute an investment contract or a security, is
						not transferable and may not be traded, exchanged or sold to any third
						party under any circumstances.
					</p>
				</div>
				<div>
					<p>
						We do not provide any legal, tax or financial advice and you are
						strongly advised to obtain independent legal, tax or financial advice
						prior to making any financial decision, including buying, trading,
						holding, or using Digital Assets. There are significant risks
						associated with Digital Assets, and you are solely responsible to make
						sure you understand such risks and assess whether such risks are
						appropriate for you. We do not make any offers, recommendations, or
						invitations for you to deal in Digital Assets or use any Services, and
						does not take into account your personal circumstances, financial
						situation, needs or goals. Before making any financial decision, you
						should carefully assess your financial situation and capacity, and only
						use funds that you can afford to lose. Before entering into any
						transaction or using any of the Services you should ensure that you
						understand and have made an independent assessment of the suitability
						and appropriateness of a transaction into which you are entering and
						the nature and extent of your exposure to risk of loss in light of your
						own objectives, financial and operational resources, and other relevant
						circumstances. Past performance is no guarantee of future results.
					</p>
				</div>
				<div>
					<p>
						Legislative and regulatory changes or actions may adversely affect the
						use, transfer, exchange, and value of Digital Assets. Transactions in
						Digital Assets may be irreversible, and, accordingly, losses due to
						fraudulent or accidental transactions may not be recoverable.
					</p>
				</div>
				<div>
					<p>
						The value of Digital Assets may be derived from the continued
						willingness of market participants to exchange Digital Assets for Fiat
						currencies or other Digital Assets. If such willingness is abolished
						for any reason, this may result in the potential for a permanent and
						total loss of value of a particular Digital Asset.
					</p>
				</div>
				<div>
					<p>
						The volatility and unpredictability of the price of Digital Assets may
						result in significant loss over a short period of time. The nature of
						Digital Assets may lead to an increased risk of fraud or cyber-attack,
						including rollback attacks or Blockchain reorganizations. The nature of
						Digital Assets means that any technological difficulties experienced by
						us or third parties may limit, delay or prevent the access or use of
						Digital Assets and/or cause losses of Digital Assets. Although Mio Pay
						and its partners take precautionary measures to protect against cyber
						threats, circumstances may arise where losses or damages are incurred.
					</p>
				</div>
				<div>
					<p>
						In light of these risks, which are only some of the risks involved in
						using the Services, and do not constitute an exhaustive list of such
						risks, you should carefully consider whether using our Services is
						suitable for you in light of your financial condition.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Limitation of liability
					</h1>
				</div>
				<br />
				<div>
					<p>
						You agree that, to the fullest extent permitted by law, in no event
						will we, our affiliates, officers, directors, shareholders, employees,
						contractors, agents or suppliers be liable, howsoever caused (including
						by negligence or otherwise), for any direct, indirect, special,
						incidental, punitive, exemplary or consequential damages you suffer,
						whether or not we have been warned of the possibility of such damages
						and whether those damages were foreseeable or not.
					</p>
				</div>
				<div>
					<p>
						If you are dissatisfied with the website or services, or do not agree
						with any part of these terms, or have any other dispute or claim with
						or against us, our affiliates, officers, directors, shareholders,
						employees, contractors, agents, suppliers or licensors, then your sole
						and exclusive remedy is to withdraw your digital assets at the end of
						the fixed plan and discontinue accessing and using the services.
					</p>
				</div>
				<div>
					<p>
						We reserve the right to provide your personal information uploaded or
						collected by our website or services, or otherwise provided to us, to
						third parties if required by law, and to cooperate with law enforcement
						authorities in the investigation of any criminal or civil matter. such
						personal information may include, but is not limited to, the
						information described in these terms and our privacy policy, including
						your name, wallet address and transaction history.
					</p>
				</div>
				<div>
					<p>
						If we are required by law to make any disclosure of your personal
						information, we may (but are not obligated to) provide you with written
						notice (to the extent permitted by law) prior to such disclosure so
						that you may take appropriate action.
					</p>
				</div>
				<div>
					<p>
						We also operate the website and services in conjunction with our
						independent contractors. Our independent contractors may have access to
						your personal information in providing services to us, or providing you
						with access to the website and services. We may use a variety of
						service providers, employees and servers in locations both inside and
						outside of the U.S, which may make your personal information the
						subject of foreign laws and foreign legal proceedings.
					</p>
				</div>
				<div>
					<p>
						In addition to and separate from your agreement to not hold the above
						entities and persons liable for any damages, in the event a court or
						arbitrator of competent jurisdiction declines to uphold said clause,
						you agree that:
					</p>
				</div>
				<div>
					<div>
					</div>
					<div>
						<ul>
							<li>
								<p>
									in no circumstances shall the aggregate liability for any
									and all claims relating to or in any way arising from the
									use of our Services, or in any way related to these terms,
									be more than the amounts paid by you to us as fees, if any
									and if implemented, or which we have earned as a result of
									your account;
								</p>
							</li>
							<li>
								<p>
									in no circumstances shall we be liable or responsible for
									any loss of profits or loss of expected revenue or gains;
								</p>
							</li>
							<li>
								<p>
									in no circumstances shall we be liable or responsible for
									any damages you suffer arising from the loss of an
									opportunity, including for example, the loss of an
									opportunity to buy, sell or profit from a digital asset;
								</p>
							</li>
							<li>
								<p>
									in no circumstances shall we be liable or responsible for
									any loss or damage whatsoever which does not arise directly
									as a result of our breach of this agreement (whether or not
									you are able to prove such loss or damage).
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div>
				</div>
				<div>
					<p>
						You agree and acknowledge that we would not enter into this agreement
						or grant access to the Services without the above restrictions and
						limitations on our liability.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Indemnification and hold harmless
					</h1>
				</div>
				<br />
				<div>
					<p>
						You agree to indemnify us, our affiliates, employees, shareholders,
						directors, agents, representatives and contractors, and to defend and
						hold each of them harmless, from any and all claims and liabilities
						(including reasonable legal fees) which may arise from, (i) your
						violation of these terms, or any policy incorporated by reference; (ii)
						your violation of any third party right; (iii) any breach of a
						representation or warranty made by you to us, either in these terms,
						privacy policy or otherwise; or (iv) any breach by you of applicable
						laws, rules or regulations.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Tax Obligations
					</h1>
				</div>
				<br />
				<div>
					<p>
						Uncertainty in the treatment of taxes related to digital assets may
						expose you to future or unknown tax obligations. It is your sole
						responsibility to determine what taxes, if any, arise from your use of
						the Services. Further, you are solely responsible for reporting and
						paying any applicable taxes. Except to the extent that we are required
						by applicable law, we will not determine whether, or to what extent,
						you are liable for taxes, report them, or withhold them.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Entire Agreement
					</h1>
				</div>
				<br />
				<div>
					<p>
						This Agreement (together with the Privacy Policy) represents the entire
						agreement between you and us with respect to the Services and
						supersedes all prior representations, understandings, agreements, or
						communications between you and us regarding the Services.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Amendments
					</h1>
				</div>
				<br />
				<div>
					<p>
						We may amend or modify these Terms and Conditions from time to time by
						posting the updated Terms and Conditions on the Platform and such
						amendment or modification shall take effect immediately. Your continued
						use of the Services constitutes your acceptance of the updated
						Agreement; if you do not agree with the updated Agreement, your sole
						and exclusive remedy is to discontinue use of the Services.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Severability
					</h1>
				</div>
				<br />
				<div>
					<p>
						If any provision, in whole or in part, of this Agreement shall be
						determined to be invalid or unenforceable by a court of competent
						jurisdiction or a governmental body, such provision shall be changed
						and interpreted to accomplish its objectives to the greatest extent
						possible under applicable law and the validity and enforceability of
						any other provision of this Agreement shall not be affected.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Survival
					</h1>
				</div>
				<br />
				<div>
					<p>
						All provisions of this Agreement which by their nature extend beyond
						its termination, including, without limitation, provisions pertaining
						to suspension and termination, shall survive such termination.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Waiver
					</h1>
				</div>
				<br />
				<div>
					<p>
						The delay or failure of one party to require performance of any
						provision of this Agreement shall not constitute a waiver of that
						party’s right to require performance at any time thereafter. Further,
						the waiver of one party to seek recovery for the other party’s non-
						performance shall not constitute a waiver by the former party to seek
						recovery for any subsequent non-performance.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Assignment
					</h1>
				</div>
				<br />
				<div>
					<p>
						You may not assign, delegate, or transfer any rights or obligations
						under this Agreement without our prior written consent. Any such
						attempted assignment, delegation, or transfer shall be null and void.
						We reserve the right to assign our rights without restriction,
						including without limitation to affiliates or subsidiaries, or to any
						successor in interest of any business associated with our Platform.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Third Party Rights
					</h1>
				</div>
				<br />
				<div>
					<p>
						Other than the affiliates of Mio Pay, a person who is not a party to
						this Agreement shall have no right under this Agreement.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Governing Law
					</h1>
				</div>
				<br />
				<div>
					<p>
						These Terms, all documents incorporated by reference and your
						relationship with us shall be governed by, construed and enforced
						exclusively in accordance with, the laws of the State of Nevada,
						without regard to its conflicts of laws provisions or rules.
					</p>
				</div>
				<div>
					<p>
						In respect of a claim by either you or us arising out of or in any way
						related to these Terms, all documents incorporated by reference and
						your relationship with us, you agree to irrevocably and unconditionally
						submit to the exclusive jurisdiction of the courts and tribunals of the
						State of Nevada for the settlement thereof.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Supported Countries
					</h1>
				</div>
				<br />

				<div>
					<p>
						As of the date of these Terms, we support resident of the following
						countries:
					</p>
				</div>
				<div>
				</div>
				<div>
					<p>
						Argentina,
					</p>
				</div>
				<div>
					<p>
						Bolivia,
					</p>
				</div>
				<div>
					<p>
						Brazil,
					</p>
				</div>
				<div>
					<p>
						Chile,
					</p>
				</div>
				<div>
					<p>
						Colombia,
					</p>
				</div>
				<div>
					<p>
						Costa Rica,
					</p>
				</div>
				<div>
					<p>
						Ecuador,
					</p>
				</div>
				<div>
					<p>
						El Salvador,
					</p>
				</div>
				<div>
					<p>
						Guatemala,
					</p>
				</div>
				<div>
					<p>
						Honduras,
					</p>
				</div>
				<div>
					<p>
						Mexico,
					</p>
				</div>
				<div>
					<p>
						Paraguay,
					</p>
				</div>
				<div>
					<p>
						Peru,
					</p>
				</div>
				<div>
					<p>
						The Dominican Republic,
					</p>
				</div>
				<div>
					<p>
						United States of America,
					</p>
				</div>
				<div>
					<p>
						Uruguay.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Force Majeure
					</h1>
				</div>
				<br />
				<div>
					<p>
						We shall not be liable for any delay, error, interruption, or failure
						to perform any obligation under this Agreement where the delay or
						failure is directly or indirectly resulting from any cause beyond our
						control including, but not limited to i) acts of God, nature, court, or
						government; ii) failure or interruption in private or public
						telecommunications networks, communication channels, or information
						systems; iii) acts or omissions of a party for whom we are not
						responsible; iv) delay, failure, interruption in, or unavailability of
						third-party services and sites; v) strikes, lockouts, labor disputes,
						war, terrorist acts, or riots; and vi) viruses, malware, other
						malicious computer code, or the hacking of any part of our Website or
						the Services.
					</p>
				</div>
				<div>
					<p>
						You understand and agree that your use of the Services is at your own
						risk. This section is not exhaustive and does not disclose all the
						risks associated with the use of the Services. You should carefully
						consider whether such use is suitable for you in light of your
						circumstances and financial resources.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Disclosures
					</h1>
				</div>
				<br />
				<div>
					<p>
						The regulatory status of digital assets is currently unsettled, varies
						among jurisdictions, and is subject to significant uncertainty. It is
						possible that in the future, certain laws, regulations, policies, or
						rules relating to digital assets, blockchain technology, or blockchain
						applications may be implemented which would directly or indirectly
						affect or restrict the Services.
					</p>
				</div>
				<div>
				</div>
				<div>
					<p>
						You are responsible for determining whether the use of the Services is
						legal in your jurisdiction and you shall not use them should such use
						be illegal in your jurisdiction. If you are uncertain, please seek
						independent legal advice.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Cybersecurity
					</h1>
				</div>
				<br />
				<div>
					<p>
						Digital assets may be subject to expropriation, theft and/or fraud;
						hackers and other malicious groups or organizations may attempt to
						interfere with our network and/or system in various ways including
						malware attacks, denial of service attacks, consensus-based attacks,
						Sybil attacks, smurfing, and spoofing which may result in the loss of
						your digital assets or the loss of your ability to access or control
						the same. In such an event, we do not guarantee any remedy, refund, or
						compensation.
					</p>
				</div>
				<br />
				<div>
					<h1>
						Change of terms
					</h1>
				</div>
				<br />
				<div>
					<p>
						Please be aware that the terms and conditions governing the Services
						can change over time. We reserve the right to discontinue or make
						changes to any of the Services. We may change these Terms, and we may
						add to or delete from these Terms, and the updated version will
						supersede all prior versions. We will provide notice of changes,
						additions, and deletions, as required by law.
					</p>
				</div>

			</Segment>
		</Container >
	);
}

export default TermsDTSUS;
