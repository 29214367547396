import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './Terms.css';

function Terms(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered>
					<h1>Website Terms and Conditions</h1>
				</Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<p>
					These terms and conditions (“Agreement”) govern the use of
					the Payment Services defined in Clause 1, which are supplied
					by <strong>Cospay Limited</strong>, registered address: One,
					Bartholomew Close, London, United Kingdom, EC1A 7BL whose
					details are in Clause 2 (“Cospay”,“we”, “us”, “our”) to any
					person whose application we approve (“Customer”, “you”,
					“your”).
				</p>
				<p>
					Words that begin with a capital letter have the meaning
					given either where they first appear in this Agreement or in
					Clause 1.
				</p>
				<p>This Agreement includes the terms of our Privacy Policy.</p>
				<p>
					By applying for your Account and Card with Cospay, you
					hereby agree that you have read, understood and will at all
					times comply with the terms of this Agreement (a copy of
					which you may download and store at any time). This
					Agreement shall commence upon receipt of your application
					and shall continue thereafter unless cancelled under Clause
					10 or terminated under Clause 12 of this Agreement.
				</p>
				<p>
					We reserve the right to change this Agreement by giving 2
					months’ notice to you in accordance with Clause 18. If we do
					this, you may terminate this Agreement immediately and
					without charge before the proposed changes take effect,
					otherwise you shall be deemed to have accepted such changes
					when the 2 months’ notice expires. However, you agree that
					changes to the Applicable Exchange Rate may be applied
					immediately and at the rate quoted via the Payment Service
					at the time of the relevant Transaction.
				</p>
				<p>
					Please also read the conditions of redemption, including any
					fees relating to redemption, in Clause 11 before applying
					for your Account and Card.
				</p>
				<p>
					We will communicate with you in the language in which this
					Agreement was agreed with you on registration for your
					Account.
				</p>
				<p>
					Key information relating to your Transactions will be
					provided to you at the email address you register with us
					and/or in your Account. You may access, download and print
					this information at any time by logging in to your Account.
				</p>
				<p>
					In addition, you agree that we may provide notices or other
					information to you from time to time by posting it in your
					Account, emailing it to your registered email address,
					mailing it to your registered physical address, calling you
					by phone or sending you mobile messages. Notices to you by
					email or mobile messages shall be deemed given 24 hours
					after the email is sent, unless the sending party is
					notified that the email address is invalid. Notices sent by
					registered mail shall be deemed to have been received three
					days after the date of mailing. You may contact us as
					specified in Clause 2.
				</p>
				<p>
					You may request a copy of any legally required disclosures
					(including this Agreement) from us via the contact details
					in Clause 2, and we will provide this to you in a form which
					enables you to store the information in a way that is
					accessible for future reference for a period of time
					adequate for the purposes of the information and which
					allows the unchanged reproduction of the information stored
					via, for example, our Website, your Account or by email.
				</p>
				<h1>1. Definitions &amp; Interpretation</h1>
				<p>
					In this document, if we use words that start with a capital
					letter, that means the word has been defined in this
					‘Definition &amp; Interpretation’ section.
				</p>
				<p>
					&quot;<strong>Account</strong>&quot; or &quot;
					<strong>E-Wallet</strong>&quot;a non-deposit non-interest
					bearing pre-paid electronic account associated at times to a
					Card which is maintained for the sole purpose of enabling
					Transactions;
				</p>
				<p>
					&quot;<strong>Account Closure Fee</strong>&quot; the fee
					payable for the Account closure, pursuant to clause 11, as
					specified in the Fees &amp; Limits Schedule, available by
					logging into your Account through&nbsp;
					<a href="https://ww.cospay.com">https://ww.cospay.com</a> (
					<strong>Website</strong>);
				</p>
				<p>
					&quot;<strong>Account Information Service</strong>&quot;
					means an online service to provide consolidated information
					on one or more payment accounts held by the payment service
					user with another payment service provider or with more than
					one payment service provider;
				</p>
				<p>
					&quot;<strong>Account Information Service Provider</strong>
					&quot; or &quot;AISP&quot; the supplier of an Account
					Information Service;
				</p>
				<p>
					&quot;<strong>Additional Cardholder</strong>&quot; where
					applicable, a person who holds a Secondary Card;
				</p>
				<p>
					&quot;<strong>Applicable Exchange Rate</strong>&quot; the
					exchange rate available on our Website;
				</p>
				<p>
					&quot;<strong>ATM</strong>&quot; An automated teller machine
					is an electronic telecommunications device that enables
					Customers to perform financial transactions, particularly
					cash withdrawal, without the need for a human cashier, clerk
					or bank teller;
				</p>
				<p>
					&quot;<strong>Authorised Person</strong>&quot; means any
					person to whom you authorise us to access your Account;
				</p>
				<p>
					&quot;<strong>Available Balance</strong>&quot; the amount of
					E-money issued by us to you but not yet spent or redeemed;
				</p>
				<p>
					&quot;<strong>Business Day</strong>&quot; Monday to Friday,
					09:00hrs to 17:00hrs GMT, excluding bank and public holidays
					in the United Kingdom;
				</p>
				<p>
					&quot;<strong>Card</strong>&quot; a prepaid Mastercard debit
					card issued by us and linked to your Account, which can be
					used to spend your Available Balance wherever Mastercard
					cards are accepted;
				</p>
				<p>
					&quot;<strong>Card Replacement Fee</strong>&quot; the fee
					payable for Card replacement, pursuant to clauses 11.2;
					13.1; 13.3,as specified in the Fees &amp; Limits Schedule,
					available by logging into your Account through the Website;
				</p>
				<p>
					&quot;<strong>Card Scheme</strong>&quot; the operator of the
					payment scheme under which we issue each Card;
				</p>
				<p>
					&quot;<strong>Customer Due Diligence</strong>&quot; the
					process we are required to go through to verify the identity
					of our Customers;
				</p>
				<p>
					&quot;<strong>Customer Funds Account</strong>&quot; the
					segregated bank account where we hold relevant funds
					corresponding to your Available Balance in accordance with
					the safeguarding provisions of the Electronic Money
					Regulations 2011;
				</p>
				<p>
					&quot;<strong>E-money</strong>&quot; monetary value issued
					by us to your Account on receipt of funds on your behalf in
					our Customer Funds Account, equal to the amount of funds
					received;
				</p>
				<p>
					&quot;<strong>European Economic Area</strong>&quot; or
					&quot;EEA&quot; means the European Union (&quot;EU&quot;)
					member states, as well as Iceland, Liechtenstein and Norway;
				</p>
				<p>
					&quot;<strong>Fees</strong>&quot; the fees payable by you
					for the Payment Services as specified in the Fees &amp;
					Limits Schedule, available by logging into your Account
					through our Website;
				</p>
				<p>
					&quot;<strong>IBAN</strong>&quot; means an International
					Bank Account Number used to identify bank accounts for the
					purposes of international payments;
				</p>
				<p>
					&quot;<strong>Late Redemption Fee</strong>&quot; has the
					meaning given in the Fees &amp; Limits Schedule available by
					logging into your Account;
				</p>
				<p>
					&quot;<strong>Late Fee</strong>&quot; means an extra charge
					assessed on you if you have a negative balance on your
					Mastercard account;
				</p>
				<p>
					&quot;<strong>Merchant</strong>&quot; a retailer who accepts
					Payment for the sale of goods or services to you;
				</p>
				<p>
					&quot;<strong>Payment</strong>&quot; means a payment for
					goods or services using a Card;
				</p>
				<p>
					&quot;<strong>Payment Initiation Service</strong>&quot;
					means an online service to initiate a payment order at the
					request of the payment service user with respect to a
					payment account held at another payment service provider;
				</p>
				<p>
					&quot;<strong>Payment Initiation Service Provider</strong>
					&quot; or (&quot;PISP&quot;) is a payment service provider
					who supplies a Payment Initiation Service;
				</p>
				<p>
					&quot;<strong>Payment Services</strong>&quot; means the
					services supplied by us to you under this Agreement,
					including issuing Cards, Accounts and IBANs; and executing
					Transactions;
				</p>
				<p>
					&quot;<strong>IBAN</strong>&quot; means a virtual IBAN
					issued by our bank service provider that we allocate to your
					Card or your Account which can be used by you or others for
					the purpose of making a SEPA Transfer of funds that will
					result in a credit of the relevant funds to your Account;
				</p>
				<p>
					&quot;<strong>Secondary Card</strong>&quot; where
					applicable, any extra Card which is issued to a Customer or
					Authorised Person;
				</p>
				<p>
					&quot;<strong>SEPA Transfer</strong>&quot; A facility
					available for UK registered Accounts only whereby you
					instruct us to send Euros to a bank account elsewhere in the
					Single Euro Payments Area (&quot;SEPA&quot;), quoting the
					IBAN of the intended recipient of the funds;
				</p>
				<p>
					&quot;<strong>Simplified Due Diligence</strong>&quot; means
					a lighter form of Customer Due Diligence, resulting in
					certain lower Card and Account limits;
				</p>
				<p>
					&quot;<strong>Transaction</strong>&quot; a, Payment, a
					Transfer or a SEPA Transfer, ATM withdrawal;
				</p>
				<p>
					&quot;<strong>Transfer</strong>&quot; a transfer of E-money
					from one Account to another Account.
				</p>
				<h1>2.Contact and Regulatory Information</h1>
				<p>
					2.1. Your Card or Account can be managed online at
					https://www.cospay.com/ or via email to&nbsp;
					<a href="mailto:cardservices@cospay.com">
						cardservices@cospay.com
					</a>
					. To report your Cards lost or stolen please call +44 7480
					777400 or cardservices@cospay.com.
				</p>
				<p>
					2.2. The issuer for Your Prepaid Mastercard Card and
					provider of Card Services is Cospay Limited.
				</p>
				<p>
					2.3. Cospay Limited is included on the Financial Conduct
					Authority Register of Electronic Money Institutions who have
					been authorised by the Financial Conduct Authority of the
					United Kingdom under the firm reference number 900088.
				</p>
				<p>
					2.4. Cospay Limited is licensed as a principal member with
					the Mastercard Scheme. Mastercard is a registered trademark
					of Mastercard International Incorporated.
				</p>
				<p>
					2.5. Cospay Limited reserves the right to use the marketing name &quot;CARDAUTHMSG&quot; for branches and notifying customers via SMS and SMS. This means that customers receive messages related to the activities of payment cards, pharmacy operations, and fully identify the marketing name, alias, alias with the full name of the company COSPAY LIMITED.
				</p>
				<h1>3.Type of Service, Eligibility and Account</h1>
				<p>
					3.1. Your Card is not a credit card and is not issued by a
					bank. Regardless of the type of Card(s) you have, you will
					have only one Account where your Available Balance is
					located.
				</p>
				<p>
					3.2 Your Payment Services may not be activated unless we
					have been provided with the required information so that we
					may identify you and can comply with all applicable
					Simplified Customer Due Diligence requirements. We shall
					keep records of such information and documents in accordance
					with all applicable legal and regulatory requirements.
				</p>
				<p>
					3.3. Reference to a currency (e.g. Euros € ) shall mean that
					amount or the local currency equivalent in which your Card
					is denominated.
				</p>
				<p>
					3.4. Any Transaction on your Card in a currency other than
					the currency in which your Card is denominated, will require
					a currency conversion using an Applicable Exchange Rate.
				</p>
				<p>
					3.5 The Available Balance on your Card and/or Account will
					not earn any interest.
				</p>
				<p>
					3.6. The Payment Services are prepaid payment services and
					not a credit or bank product, you must therefore ensure that
					you have a sufficient Available Balance from time to time to
					pay for your Transactions and applicable Fees. If for any
					reason a Transaction is processed, and the Transaction
					amount exceeds the Available Balance, you must repay us the
					amount of such excess immediately and we shall be entitled
					to stop any existing or subsequent Transactions from
					proceeding.
				</p>
				<p>
					3.7. This Agreement does not give you any rights against the
					Card Schemes, its affiliates or any third party.
				</p>
				<p>
					3.8. Only persons over 18 years of age are entitled to
					register for the Payment Services.
				</p>
				<p>
					3.9. Each time you seek access the Account we will be asking
					for your Access Codes (as defined in Clause 8. As long as
					the correct Access Codes are entered, we will assume that
					you are the person giving instructions and making
					Transactions and you will be liable for them, except to the
					extent provided for in Clause 8. We can refuse to act on any
					instruction that we believe:
				</p>
				<ol type="i">
					<li>was unclear;</li>
					<li>was not given by you; or</li>
					<li>might cause us to breach a legal or other duty; or</li>
					<li>
						if we believe the Payment Service is being used for an
						illegal purpose.
					</li>
				</ol>
				<p>
					3.10.We will do all that we reasonably can to prevent
					unauthorised access to the Account.
				</p>
				<h1>4.Service Limits &amp; SEPA Transfer Payment</h1>
				<p>
					4.1.Transactions may be restricted by Card or Account type,
					individual usage patterns and payment risk profiles. Cards
					are issued in accordance with regulatory limits and
					conditions. Limits relating to the use of Cards can be found
					in the Fees &amp; Limits Schedule available by logging into
					your Account and on our Website at Terms and Conditions. For
					anti-money laundering and anti-fraud reasons we reserve our
					rights to change particular payment restrictions (including
					from those published or included herein) without notice and
					to the extent required to meet our regulatory obligations.
				</p>
				<p>
					4.2.Simplified Due Diligence may be restricted to domestic
					ATM access, along with reduced annual maximum load limits
					and capped annual withdrawal limits. These limits will be
					subject to Scheme and regulatory requirements.
				</p>
				<p>
					4.3.You can make a Transfer to another Account by signing in
					to your Account and following the relevant instructions.
				</p>
				<p>
					4.4.When sending funds to your Account with us, we recommend
					that you or other senders make a SEPA Transfer using your
					IBAN.
				</p>
				<p>
					4.5.We cannot be held liable for the payment process or fees
					associated with bank(s) and or intermediary bank(s) to
					process payments from you to us. Any fee(s) charged by third
					parties, not limited to receiving, processing or crediting a
					payment for you will be deducted by us before crediting the
					remaining balance to you.
				</p>
				<p>
					4.6.You are responsible for checking and confirming payment
					details and fees before making a payment to us or to your
					Account. We shall not be liable for any loss of any nature
					that you incur as a result of your use of incorrect payment
					details.
				</p>
				<p>
					4.7.Payments received will be credited to your Account at
					least once a day and before the end of the Business Day.
					Amounts received after the cut off period will be processed
					the next Business Day.
				</p>
				<p>
					4.8.You may be asked to provide us with evidence of source
					of funds in order for us to meet our regulatory
					requirements, in which case you agree to provide that
					evidence promptly. You represent and warrant to us that the
					evidence you provide to us is up to date, complete and
					accurate.
				</p>
				<p>
					4.9.Where enabled, you may be eligible to instruct companies
					to create regular SEPA Transfers from your UK issued and
					registered Account. You will be responsible for ensuring
					that the correct details are provided in order for the SEPA
					Transfer to be created for you. You must ensure at all times
					that you have a sufficient balance on your Account to allow
					for the funds to be debited from your Account. You are
					responsible for checking the terms and conditions that have
					been provided to you by the SEPA Transfer originator.
				</p>
				<p>
					1.10.You may incur a charge for unpaid SEPA Transfers if
					there are not enough funds in your Account to pay an
					incoming SEPA Transfer request.
				</p>
				<h1>5.Use of the Payment Services</h1>
				<p>
					5.1.You may access your Account information by logging into
					your Account through our Website. From here you will be able
					to view details on your Transactions, including dates,
					currencies, charges or exchange rates applied. This
					information is accessible at any time and can be stored and
					reproduced as necessary.
				</p>
				<p>
					5.2.You can use the Payment Services up to the amount of the
					Available Balance for Transactions.
				</p>
				<p>
					5.3.If the Available Balance is insufficient to pay for a
					Payment, some Merchants will not permit you to combine use
					of a Card or Account with other payment methods.
				</p>
				<p>
					5.4.The value of each Transaction and the amount of any Fees
					payable by you under this Agreement will be deducted from
					the Available Balance.
				</p>
				<p>
					5.5.Once a Transaction is authorised, the relevant payment
					order may not be withdrawn (or revoked) by you after the
					time it is received by us. A Transaction will be deemed to
					have been received by us at the time you authorise the
					Transaction as follows:
				</p>
				<ol type="i">
					<li>
						for Payments and ATM Transactions, at the time we
						receive the payment order for the Transaction from the
						merchant acquirer or ATM operator, and
					</li>
					<li>
						A payment order for a Transfer or SEPA Transfer is
						provided to and received by us at the time it is issued
						by you via the Account.
					</li>
				</ol>
				<p>
					5.6.Where a revocation of an authorised payment is agreed
					between us and you, we may charge a Fee for revocation.
				</p>
				<p>
					5.7.We will ensure that the amount of a SEPA Transfer is
					credited to the payment service provider of the payee by the
					end of the Business Day following the time of the receipt of
					your payment order. If the payment service provider of the
					Merchant is located outside the EEA, we will effect payment
					as soon as possible and in any event as required by
					applicable law.
				</p>
				<p>
					5.8.In order to protect you and us from fraud, Merchants may
					seek electronic authorisation before processing any Payment.
					If a Merchant is unable to get an electronic authorisation,
					they may not be able to authorise your Payment.
				</p>
				<p>
					5.9.We may refuse to authorise any use of the Payment
					Services which could breach these terms and conditions or if
					we have reasonable grounds for suspecting that you or a
					third party have committed or are planning to commit fraud
					or any other illegal or un-permitted use of the Payment
					Services.
				</p>
				<p>
					5.10.Your ability to use or access the Payment Services may
					occasionally be interrupted, for example if we need to carry
					out maintenance on our Systems. Please contact Customer
					Services via our Website to notify us of any problems you
					are experiencing using your Card or Account and we will
					endeavour to resolve any problem.
				</p>
				<p>
					5.11.Where applicable, you may apply to us for up to 3
					Secondary Cards, for use by Additional Cardholders on your
					Account. Additional Cardholders, for whom you are legally
					responsible, must be 18 years of age or older. It is your
					responsibility to authorize the Transactions incurred by
					each Additional Cardholder on the relevant Secondary Card
					and to ensure that the Additional Cardholder keeps to the
					provision of this Agreement. You are responsible for their
					use of the Secondary Card and for paying any amounts they
					add to your Account even if the Additional Cardholder does
					not keep to the provisions of this Agreement. We accept no
					responsibility or liability of any kind whatsoever for use
					of any Secondary Card by any Additional Cardholder for
					Transactions not authorised by you. You will be liable for
					any loss that directly results from use of any Secondary
					Card by any Additional Cardholder. If you successfully
					register and request one, we will send you a Secondary Card
					in the name of the Additional Cardholder with a copy of this
					Agreement. Upon receipt of the Secondary Card, you may give
					the Secondary Card to the Additional Cardholder for their
					use, subject to:
				</p>
				<ol type="i">
					<li>
						you providing them with the copy of this Agreement (by
						using the Secondary Card the Additional Cardholder
						consents to the terms of this Agreement, which will then
						bind you and the Additional Cardholder in relation to
						the use of the Secondary Card);
					</li>
					<li>
						the Secondary Card must only be used by that person;
					</li>
					<li>
						you continuing to hold the Account and the Card with
						which the Secondary Card is associated;
					</li>
					<li>
						you informing the Additional Cardholder that you have
						retained the Primary Card and that you are still able to
						use the Account;
					</li>
					<li>
						us obtaining such further information and documentation
						in order to enable us to comply with all applicable
						Customer Due Diligence anti-money laundering
						requirements in relation to the Additional Cardholder.
					</li>
				</ol>
				<p>
					5.12. You will remain responsible for the use of the Payment
					Services, and for any Fees and charges incurred by the
					Additional Cardholder(s), and you will continue to be
					regarded as the holder of any funds already or subsequently
					loaded on the Account. The use of a Card in relation to
					which an Additional Cardholder has been registered will be
					regarded as confirmation that you have provided the
					Additional Cardholder with this Agreement.
				</p>
				<p>
					5.13. You or any Additional Cardholder may ask us to remove
					that Additional Cardholder, and in that case you must cut
					the relevant Secondary Card in half.
				</p>
				<p>
					5.14. You agree that we may give information about your
					Account to each Additional Cardholders and restrict what
					Additional Cardholders can do in relation to your Account.
					5.15. Except as required by law, we shall not be
					responsible, and you will be solely responsible, for
					compiling and retaining your own copy of the data in your
					Account and your activities in connection with this
					Agreement. We shall collect, store, manage the data of
					Customer, the Transaction data, documentation or other
					information in connection with any Transactions or the
					Account under the conditions provided for in the agreement
					during the term of the agreement and 5 (five) years after
					the termination of the Agreement.
				</p>
				<p>
					5.16. You agree to only use the Payment Services for lawful
					purposes and to adhere at all times to all laws, rules and
					regulations applicable to the use of the Payment Services,
					including the terms of this Agreement.
				</p>
				<p>
					5.17. You may not use the Payment Services to receive or
					transfer any funds on behalf of any other natural person or
					legal entity.
				</p>
				<p>
					5.18. You shall always have a zero or positive balance in
					your Mastercard account. If your Mastercard account goes
					into a negative balance, you shall repay the negative
					balance immediately without any notice from us. We may send
					you reminders or take such other reasonable actions to
					recover the negative balance from you, for example, we may
					use a debt collection service or take further legal actions.
					We will charge you the Late Fee, as well as for any costs we
					may incur as a result of these additional collection
					efforts.
				</p>
				<h1>6.Access by Third Party Providers</h1>
				<p>
					6.1.You may consent to regulated third party providers
					(PISPs or AISPs) accessing your Account online to make
					payments or obtain information about balances or
					Transactions on your Card and/or Account.
				</p>
				<p>
					6.2.The PISPs and/or AISPs must be appropriately registered
					and authorised in accordance with PSD2 Directive (Directive
					(EU) 2015/2366). You should check with the regulatory
					authority of the relevant country before giving consent to
					the relevant PISP/AISP.
				</p>
				<p>
					6.3.Any consent you give to a third-party provider is an
					agreement between you and it, we will have no liability for
					any loss whatsoever, as a result of any such agreement.
				</p>
				<p>
					6.4.To the extent permitted by law or regulation and subject
					to any right to refund you may have under this Agreement,
					between you and us, we are not responsible for any actions
					that the relevant third party takes in relation to
					suspending or terminating your use of their service or for
					any resulting losses. We are also not responsible for, or a
					party to, any agreement that you enter into with any
					relevant third party. You should make sure that you read and
					comply with such agreement or other applicable policies and
					note that this Agreement will continue to apply between us
					regardless, unless terminated in accordance with the terms
					of this Agreement.
				</p>
				<p>
					6.5.Where appropriate, we may deny access to your Account,
					to any third party where we consider such access to be a
					risk of money laundering or terrorism financing, fraud or
					other criminal activity. Should we need to take these
					actions and where possible, we will give reasons for doing
					so unless restricted by law or for internal security
					reasons.
				</p>
				<h1>7.Condition of Use at Certain Merchants</h1>
				<p>
					7.1.In some circumstances we or Merchants may require you to
					have an Available Balance in excess of the Payment amount.
					For example, at restaurants you may be required to have 15%
					more on your Card than the value of the bill to allow for
					any gratuity or service charge added by the restaurant or
					you.
				</p>
				<p>
					7.2.In some circumstances Merchants may require verification
					that your Available Balance will cover the Payment amount
					and initiate a hold on your Available Balance in that
					amount, examples include rental cars. In the event a
					Merchant places a pre-authorisation on your Account, you
					will not have access to these funds until the Payment is
					completed or released by the Merchant which may take up to
					30 days.
				</p>
				<p>
					7.3.If you use your Card at an automated fuelling station,
					subject to Merchant acceptance, your Card may need to be
					pre-authorised for a pre-determined amount in the relevant
					currency. If you do not use the whole pre-authorisation or
					do not have Available Balance to obtain a pre-authorisation,
					it is possible that the pre-authorised amount will be held
					for up to 30 days before becoming available to you again.
				</p>
				<p>
					7.4.Some Merchants may not accept payment using our Payment
					Services. It is your responsibility to check the policy with
					each Merchant. We accept no liability if a Merchant refuses
					to accept payment using our Payment Services.
				</p>
				<p>
					7.5.In relation to any dispute between the you and a
					Merchant, provided you are able to satisfy us that you have
					already made all efforts to resolve the dispute with the
					relevant Merchant, we will attempt to assist you so far as
					is reasonably practicable. We may charge you a chargeback
					processing fee as referenced in the Fees &amp; Limits
					Schedule available by logging into your Account, for any
					such assistance we may give you with any dispute. If there
					is an un-resolvable dispute with a Merchant in circumstances
					where the Card has been used for a Payment, you will be
					liable for the Payment and will have to resolve this
					directly with the relevant Merchant.
				</p>
				<strong>8.Managing &amp; Protecting Your Account</strong>
				<p>
					8.1.You are responsible for the safekeeping of your username
					and password for your Account (“Access Codes”) and the
					personal identification number for your Card (“PIN”).
				</p>
				<p>
					8.2.Do not share your PIN with anyone. You must keep your
					PIN safe, and separate from your Card or any record of your
					Card number and not disclose it to anyone else. This
					includes:
				</p>
				<ol type="i">
					<li>
						memorising your PIN as soon as you receive it, and
						destroying the post mail or other authorised
						communication used to transmit it to you;
					</li>
					<li>
						never writing your PIN on your Card or on anything you
						usually keep with your Card;
					</li>
					<li>
						keeping your PIN secret at all times, including by not
						using your PIN if anyone else is watching;
					</li>
					<li>not disclosing your PIN to any person.</li>
				</ol>
				<p>
					8.3.The user of the Card(s) must sign the signature strip on
					any Personalised Card immediately when received.
				</p>
				<p>
					8.4.Do not share your Access Codes with anyone except an
					Authorised Person. If you disclose the Access Codes to any
					Authorised Person, you are responsible and liable for their
					access, use or misuse of the Account, their breach of the
					terms of this Agreement or disclosure of the Access Codes.
				</p>
				<p>
					8.5.The Payment Services may only be used by you and each
					Additional Cardholder or Authorised Person.
				</p>
				<p>
					8.6.You must not give the Card to any other person or allow
					any other person to use the Payment Services except
					Authorised Persons or Additional Cardholders. You must keep
					the Card in a safe place.
				</p>
				<p>
					8.7.Failure to comply with Clause 8 may affect your ability
					to claim any losses under Clause 14 including where you have
					acted fraudulently, intentionally, with undue delay or with
					gross negligence. In all other circumstances your maximum
					liability shall be as set out below at Clause 15.
				</p>
				<p>
					8.8.If you believe that someone else knows your Account or
					Card security details, you should contact us immediately in
					accordance with Clause 13.
				</p>
				<p>
					8.9.Once your Card has expired (see Clause 11), or if it is
					found after you have reported it as lost or stolen, you must
					destroy your Card by cutting it in two, through the magnetic
					strip.
				</p>
				<h1>9.Identity Verification</h1>
				<p>
					9.1.If you enter into Transactions over the internet, some
					websites require you to enter your name and address. In such
					cases you should supply the most recent address which has
					been registered with us by you as the Account address. The
					Account address is also the address to which we will send
					any correspondence.
				</p>
				<p>
					9.2.You must notify us within 7 days of any change in the
					Account address or your other contact details. You can
					notify us by contacting Customer Services who may require
					you to confirm such notification in writing. You will be
					liable for any loss that results from any failure to notify
					us of such a change as a result of undue delay, your
					negligence, recklessness, wilful default or fraud. We will
					need to verify your new Account address and shall request
					the relevant proofs from you.
				</p>
				<p>
					9.3.We reserve the right at any time to satisfy ourselves as
					to your identity and home address (for example, by
					requesting relevant original documents) including for the
					purposes of preventing fraud and/or money laundering. In
					addition, at the time of your application or at any time in
					the future, in connection with your Account, you authorise
					us to perform electronic identity verification checks
					directly or using relevant third parties.
				</p>
				<h1>10.Right to Cancel (“Cooling-Off”)</h1>
				<p>
					10.1You have a right to withdraw from this Agreement under
					the following conditions:
				</p>
				<p>
					10.1.where you purchased the Payment Services then you have
					a “Cooling Off” period of 14 days beginning on the date of
					the successful registration of your Account, to withdraw
					from this Agreement and cancel the Payment Services, without
					any penalty but subject to deduction of any reasonable costs
					incurred by us in the performance of any part of the
					provision of services before you cancel. You must contact us
					within this 14-day period and inform us that you wish to
					withdraw from this Agreement and you must not use the
					Payment Services. We will then cancel the Payment Services
					and reimburse the amount of Available Balance on the Account
					to you. However, we reserve the right to hold Available
					Balance for up to 30 business days from receipt of your
					instructions before returning the balance, to ensure that
					details of all Transactions have been received.
				</p>
				<p>
					10.2.After the Cooling Off period you may only terminate the
					Payment Services as described in Clause 12.
				</p>
				<strong>11.Expiry &amp; Redemption</strong>
				<p>
					11.1.Your Card has an expiry date printed on it (the “Expiry
					Date”). The Card (and any Secondary Card) and any Cospay
					IBAN linked to the Card will no longer be usable following
					the Expiry Date, and you must not use it after that time,
					but you will still be able to receive and send funds to and
					from the Account associated with the Card.
				</p>
				<p>
					11.2.If a Card expires before your Available Balance is
					exhausted, you can contact Customer Services to request a
					replacement Card, provided you do so 14 days before the
					Expiry Date printed on your Card and subject to payment of a
					Fee (where specified). We reserve the right to issue you
					with a replacement for an expired Card and apply Card
					Replacement Fee even if you have not requested one.
				</p>
				<p>
					11.3.If your IBAN is linked to your Account, rather than
					linked to your Card, then it will not expire when the Card
					expires, but will be available to use as long as the Account
					is available for your use.
				</p>
				<p>
					11.4.Your funds are available for redemption by contacting
					us at any time before the end of the 6 years after the date
					on which this Agreement ends under Clause 12, after which
					time your Available Balance will no longer be redeemable to
					you and we are entitled to retain the corresponding funds.
					Where an Available Balance remains for more than one year
					after the date on which this Agreement ends under Clause 12,
					we are not required to safeguard the corresponding funds in
					our Customer Funds Account, but you can still request
					redemption for up to 6 years. We may deduct from the
					proceeds of redemption of any such Available Balance the
					amount of any Late Redemption Fee.
				</p>
				<p>
					11.5.Provided that your request for redemption is made less
					than 12 months following the date on which this Agreement
					ends under Clause 12, redemption will not incur any Late
					Redemption Fee. If you make a request for redemption more
					than 12 months after the date on which this Agreement ends
					under Clause 12 an Account Closure Fee may be charged (where
					specified).
				</p>
				<p>
					11.6.We shall have the absolute right to set-off, transfer,
					or apply sums held in the Account(s) or Cards in or towards
					satisfaction of all or any liabilities and Fees owed to us
					that have not been paid or satisfied when due.
				</p>
				<p>
					11.7.We shall have the absolute right to close your Account
					and submit a chargeback claim for the relevant Transactions
					if your Account is in negative standing for more than 60
					days. If our chargeback is successful, funds paid to your
					Account may only be used to credit your Card or Account, and
					your Account will remain closed.
				</p>
				<p>
					11.7.1.We shall have the absolute right to close any
					Unverified Account that has not received any funds within 30
					days since Account registration.
				</p>
				<p>
					11.8.If your Account is inactive (including without
					limitation no access to the account or payment Transactions)
					for at least 2 consecutive years and has an Available
					Balance, we may (but we are not obliged to) notify you by
					sending an e-mail to your registered e-mail address and give
					you the option of keeping your Account open and maintaining
					or redeeming the Available Balance. If you do not respond to
					our notice within thirty (30) days, we will automatically
					close your Account and initiate a Transfer of your Available
					Balance to the last payment account notified by you to us
					(your “Nominated Bank Account”) or by cheque to your last
					known address.
				</p>
				<h1>
					12.Termination or Suspension of Your Account and/or
					Processing of Transactions
				</h1>
				<p>
					12.1.We may terminate this Agreement and your use of the
					Payment Services with prior notice of at least 2 months.
				</p>
				<p>
					12.2.Your use of your Card and any IBAN linked to the Card
					ends on the Expiry Date in accordance with Clause 11.2.
				</p>
				<p>
					12.3.This Agreement and your use of the Payment Services
					will also end when your ability to initiate all Transactions
					ceases.
				</p>
				<p>
					12.4.We may terminate or suspend, for such period as may
					reasonably be required, your use of the Payment Services in
					whole or in part at any time or the processing of any
					Transaction(s) if:
				</p>
				<ol type="i">
					<li>
						there is any fault or failure in the relevant data
						processing system(s);
					</li>
					<li>
						we reasonably believe that you have used or are likely
						to use the Payment Services, or allow them to be used,
						in breach of this Agreement or to commit an offence;
					</li>
					<li>
						any Available Balance may be at risk of fraud or misuse;
					</li>
					<li>
						if we suspect that you have provided false or misleading
						information; v. we are required to do so by law, the
						police, a court or any relevant governmental or
						regulatory authority;
					</li>
					<li>
						required to fulfil our legal obligations in relation to
						the fight against money laundering and financing of
						terrorism;
					</li>
					<li>
						there is suspicion of unauthorised or fraudulent access
						to or use of your Account or that any of its security
						features have been compromised, including the
						unauthorised or fraudulent initiation of a Transaction;
					</li>
					<li>
						we have reasonable grounds to believe you are carrying
						out a prohibited or illegal activity;
					</li>
					<li>
						we are unable to verify your identity or any other
						information pertaining to you, your Account or a
						Transaction.
					</li>
				</ol>
				<p>
					12.5.If any Transactions are found to have been made using
					your Card or IBAN after expiry or any action has been taken
					by us under Clause 12.4, you must immediately repay such
					amounts to us.
				</p>
				<p>
					12.6.Where it is practicable and lawful for us to do so or
					would not compromise reasonably justified security reasons,
					we will notify you via email of the suspension or
					restriction and the reasons for it before such measures take
					place or immediately thereafter.
				</p>
				<p>
					12.7.We will reinstate your Account or execute the relevant
					Transaction(s) as soon as practicable after the reasons
					pursuant to Clause 12.4 no longer apply or exist.
				</p>
				<p>
					12.8.If you wish to terminate the Payment Services at any
					time, you must request termination and the return of your
					Available Balance by email to our address in Clause 2 from
					the email address registered in your Account. Our Customer
					Services department will then suspend all further use of
					your Payment Services.
				</p>
				<p>
					12.9.Once we have received all the necessary information
					from you (including any Customer Due Diligence) and all
					Transactions and applicable Fees and charges have been
					processed, we will refund to the you any Available Balance
					less any Fees and charges payable to us, provided that:
				</p>
				<ol type="i">
					<li>
						you have not acted fraudulently or with gross negligence
						or in such a way as to give rise to reasonable suspicion
						of fraud or gross negligence; and
					</li>
					<li>
						we are not required to withhold your Available Balance
						by law or regulation, or at the request of the police, a
						court or any regulatory authority
					</li>
				</ol>
				<p>
					12.10.Once the Payment Services have been terminated, it
					will be your responsibility to destroy the Card(s) that were
					provided to you.
				</p>
				<p>
					12.11.If, following reimbursement of your Available Balance,
					any further Transactions are found to have been made or
					charges or Fees incurred using the Card(s) or we receive a
					reversal of any prior funding Transaction, we will notify
					you of the amount and you must immediately repay to us such
					amount on demand as a debt.
				</p>
				<h1>
					13.Loss or Theft of your Card or Misappropriation of Your
					Account
				</h1>
				<p>
					13.1.If your Card is lost or stolen or if you think someone
					is using your Card, PIN and/or Access Codes without your
					permission or if your Card is damaged or malfunctions:
				</p>
				<ol type="i">
					<li>
						you must contact us as soon as possible and you must
						provide us with your Account or Card number and either
						your Username and Password or some other identifying
						details acceptable to us so that we can be sure we are
						speaking to you; and
					</li>
					<li>
						Provided we have obtained your consent to close the
						Account, we will then provide you with a replacement
						Card with a corresponding new Account loaded with an
						amount equivalent to your last Available Balance.
					</li>
				</ol>
				<p>
					13.2.You will be liable up to a maximum of the first £50 of
					losses arising from any unauthorised Transactions that take
					place prior to your notifying us of the loss or theft. If
					our investigations show that any disputed transaction was
					Authorised by you, or you have acted fraudulently or with
					gross negligence (for example by failing to keep your
					Prepaid Card or PIN secure), we may reverse any refund made
					and you may be liable for any loss we suffer because of the
					use of the Prepaid Card. You will not be held liable for any
					losses once you have notified us of loss or theft within 3
					months of the date of the transaction or unless we
					reasonably determine that you have acted in accordance with
					clause 14.1 – in which case you shall be liable for all
					losses.
				</p>
				<p>
					13.3.Replacement Cards will be posted to the most recent
					Account address registered by you. Failure to provide the
					correct address will result in a Card Replacement Fee.
				</p>
				<p>
					13.4.If you subsequently find or retrieve a Card that you
					have reported lost or stolen, you must immediately destroy
					the found Card by cutting it in half through the magnetic
					stripe and chip.
				</p>
				<p>
					13.5.You agree to help us, our agents, regulatory
					authorities and the police if your Card is lost, stolen or
					if we suspect that the Payment Services are being misused.
				</p>
				<h1>
					14.Liability for Unauthorised or Incorrectly Executed
					Transactions
				</h1>
				<p>
					14.1.Subject to Clauses 14.2, 14.3 and 14.6 we will
					reimburse you in full for all unauthorised Transactions sent
					from your Account immediately and in any event no later than
					the end of the following Business Day after noting or being
					notified of the Transaction (except where we have reasonable
					grounds for suspecting fraud), provided that you have
					informed us of the unauthorised Transaction without undue
					delay after becoming aware of the Transaction and in any
					event, no later than 3 months after the Transaction was
					executed. Where applicable, we shall restore your Account to
					the state in which it would have been had the unauthorised
					Transaction not taken place, so that that the credit value
					date shall be no later than the date the amount had been
					debited.
				</p>
				<p>
					14.2.You may be liable for losses relating to any
					unauthorized Transactions up to a maximum of EUR 40
					resulting from the use of a lost or stolen Card or the
					misappropriate of your Account, unless the loss, theft or
					misappropriation was not detectable to you prior to payment
					(except where you acted fraudulently) or was caused by acts
					or lack of action of our employee, agent, branch or service
					provider.
				</p>
				<p>
					14.3.You are liable for any losses incurred by an
					unauthorised Transaction if you have acted or we suspect
					that you acted fraudulently, dishonestly, negligently or
					recklessly, to use your Account in accordance with the terms
					of this Agreement or to keep your Access Codes confidential
					and secure in accordance with Clause 8.
				</p>
				<p>
					14.4.You shall not be liable for losses incurred by an
					unauthorised Transaction which takes place after you have
					notified us of a compromise of your Access Codes according
					to Clause 8, unless you have acted or we suspect you have
					acted fraudulently, or where we have failed to provide you
					with the means to notify us in the agreed manner without
					delay on you becoming aware of the loss, theft,
					misappropriation or unauthorised use of your Card or
					Account.
				</p>
				<p>
					14.5.We shall not liable for a refund or losses incurred by
					an incorrectly or non-executed payment Transaction if the
					details of the payee’s account provided by you were
					incorrect or we can prove that the full amount of the
					Transaction was duly received by the payment service
					provider of the payee.
				</p>
				<p>
					14.6.We shall not be liable for any unauthorised or
					incorrectly executed Transactions in case the Transaction
					was affected by abnormal and unforeseeable circumstances
					beyond our reasonable control or where we acted in
					accordance with a legal or regulatory obligation.
				</p>
				<p>
					14.7.Where we are liable for the incorrect execution of a
					Transfer or SEPA Transfer that you receive under this
					Agreement, we shall immediately place the amount of the
					Transaction at your disposal in accordance and credit the
					corresponding amount to your Account no later than the date
					on which the amount would have been value dated, had the
					Transaction been correctly executed.
				</p>
				<p>
					14.8.Where we are liable for the incorrect execution of a
					Payment, Transfer or SEPA Transfer by you as payer, we
					shall, without undue delay, refund to you the amount of the
					non-executed or defective Transaction, and, where
					applicable, restore the debited Account to the state in
					which it would have been had the defective Transaction not
					taken place.
				</p>
				<p>
					14.9.In the case of a non-executed or defectively executed
					Payment, Transfer or SEPA Transfer by you as payer, we
					shall, regardless of whether we are liable, on request, make
					immediate efforts to trace the Transaction and notify you of
					the outcome, free of charge.
				</p>
				<p>
					14.10.A Payment initiated by or through a payee (e.g. a
					Merchant) shall be considered to be unauthorised if you have
					not given your consent for the Payment to be made. If you
					believe that a Payment has been made without your consent
					you should contact us in accordance with Clause 2.
				</p>
				<p>
					14.11.A claim for a refund of an authorised Payment
					initiated by or through a payee (e.g. a Merchant) where the
					authorisation did not specify an exact amount of payment
					Transaction (and the amount of the Payment exceeded the
					amount that you reasonably could have expected taking into
					account your previous spending pattern, this Agreement and
					the circumstances of the case), must be made within 8 weeks
					from the date on which the funds were deducted from your
					Available Balance. Within 10 Business Days of receiving your
					claim for a refund or within 10 Business Days of receiving
					further information from you, we will either refund the full
					amount of the Payment as at the date on which the amount of
					the Payment was debited or provide you with justification
					for refusing the refund.
				</p>
				<p>
					14.12.The right to a refund under this Clause 14 does not
					apply where you have given consent directly to us for the
					Payment to be made and, if applicable, information on the
					Payment was provided or made available to you by us or the
					payee in an agreed manner for at least four weeks before the
					due date.
				</p>
				<p>
					14.13.If you are not satisfied with the justification
					provided for refusing the refund or with the outcome of your
					claim for a refund, you may submit a complaint to us or
					contact the complaints authority as described in Clause 16.
				</p>
				<p>
					14.14.If at any time we have incorrectly deducted money from
					your Available Balance, we shall refund the amount to you.
					If we subsequently establish that the refunded amount had
					been correctly deducted, we may deduct it from your
					Available Balance and may charge you a Fee. If you do not
					have sufficient Available Balance, you must repay us the
					amount immediately on demand.
				</p>
				<p>
					14.15.Where any request, Transaction, disputed Transaction,
					arbitration or reversed Transaction involves third party
					costs, you remain liable for these and they will be deducted
					from your Account or otherwise charged to you.
				</p>
				<h1>15.General Liability</h1>
				<p>
					15.1.Without prejudice to Clause 14 and subject to Clause
					15.4;
				</p>
				<ol type="i">
					<li>
						neither party shall be liable to the other for indirect
						or consequential loss or damage (including without
						limitation loss of business, profits or revenues),
						incurred in connection with this Agreement, whether
						arising in contract, tort (including negligence), breach
						of statutory duty or otherwise;
					</li>
					<li>we shall not be liable:</li>
				</ol>
				<ol type="a">
					<li>
						if you are unable to use the Card or Payment Services
						for any valid reason stated in this Agreement;
					</li>
					<li>
						for any fault or failure beyond our reasonable control
						relating to the use of the Payment Services, including
						but not limited to, a lack of Available Balance or
						fault in or failure of data processing systems;
					</li>
					<li>
						for any loss, fault or failure relating to the use of a
						third-party provider as stated in Clause 6.3, 6.6 and
						6.7 of this Agreement,
					</li>
					<li>
						if a Merchant refuses to accept a Payment or fails to
						cancel an authorisation or pre-authorisation;
					</li>
					<li>
						for the goods or services that are purchased with your
						Card;
					</li>
					<li>
						for any dispute you might have with a Merchant or other
						user of the Payment Service where you acted with:
					</li>
					<ol type="I">
						<li>undue delay or dishonestly;</li>
						<li>fraudulently; or</li>
						<li>
							Negligently (including where losses arise due to
							your failure to keep us notified of your correct
							personal details)
						</li>
					</ol>
				</ol>
				<p>
					15.2.You warrant and represent that you will not use the
					Payment Services in an illegal manner and you agree to
					indemnify us against any loss, damage, claim, fines or
					proceeding brought about by such illegal use of the Payment
					Services by you, your Authorised Person(s) and Additional
					Cardholder(s).
				</p>
				<p>
					15.3.You are solely responsible for your interactions with
					Merchants or other users of the Payment Service. We reserve
					the right, but have no obligation, to monitor or mediate
					such disputes.
				</p>
				<p>
					15.4.To the fullest extent permitted by relevant law, and
					subject to Clause 14 and Clause 15.5, our total liability
					under or arising from this Agreement shall be limited as
					follows:
				</p>
				<ol type="a">
					<li>
						where your Card is faulty due to our default, our
						liability shall be limited to replacement of the Card
						or, at our choice, repayment to you of the Available
						Balance; an
					</li>
					<li>
						In all other circumstances of our default, our liability
						will be limited to repayment of the amount of your
						Available Balance (such amount to be calculated by
						Cospay upon receipt of your claim).
					</li>
				</ol>
				<p>
					15.5.Nothing in this Agreement shall exclude or limit either
					party’s liability in respect of death or personal injury
					arising from that party’s negligence or fraudulent
					misrepresentation.
				</p>
				<p>
					15.6.No party shall be liable for, or be considered in
					breach of this Agreement on account of, any delay or failure
					to perform as required by this Agreement as a result of any
					causes or conditions which are beyond such Party’s
					reasonable control.
				</p>
				<h1>16.Dispute Resolution</h1>
				<p>
					16.1.We are committed to providing an excellent customer
					experience for all our Customers. If we do not meet your
					expectations in any way, we want to have the opportunity to
					put things right.
				</p>
				<p>
					16.2.In the first instance, your initial communication will
					be with our Customer Services Team who can be contacted by
					Email to info@cospay.com. Our Customer Services Team will
					listen to your needs and will do their best to solve your
					issue promptly and fairly. We value the opportunity to
					review the way we do business and help us meet our
					customers’ expectations.
				</p>
				<p>
					16.3.If having received a response from our Customer
					Services Team you are unhappy with the outcome, please
					contact Complaints Team of Cospay complaints@cospay.com.
				</p>
				<p>
					16.4.Once received, the Complaints Team will conduct an
					investigation and you will receive a response of its
					findings within 15 days of receipt of the complaint. In
					exceptional circumstances where we are unable to reply
					within the first 15 days, we will reply providing a reason
					for the delay and deadline for response, not more than 35
					days after first receipt of complaint.
				</p>
				<p>
					16.5.If the Complaints Team is unable to resolve your
					complaint and you wish to escalate your complaint further,
					please direct to the Financial Ombudsman Service. Details of
					the service offered by the Financial Ombudsmen Office at
					https://www.financial-ombudsman.org.uk/
				</p>
				<p>
					16.6.You must provide us with all receipts and information
					that are relevant to your claim.
				</p>
				<h1>17.Your Personal Data</h1>
				<p>
					17.1. We will only use your personal information as set out
					in our Privacy Policy -
					https://ib.cospay.com/legal/privacypolicy.
				</p>
				<h1>18.Changes to the Terms and Conditions</h1>
				<p>
					18.1.We may update or amend these terms and conditions
					(including our Fees &amp; Limits Schedule). Notice of any
					changes will be given on our Website, or by e-mail
					notification, or by SMS at least 2 months in advance. By
					continuing to use the Payment Services after the expiry of
					the 2-month notice period you acknowledge that you indicate
					your acceptance to be bound by the updated or amended terms
					and conditions. If you do not wish to be bound by them, you
					should stop using the Payment Services and terminate this
					Agreement in accordance with Clause 10 before the changes
					take effect.
				</p>
				<h1>19.Miscellaneous</h1>
				<p>
					19.1.We may assign or transfer our rights, interest or
					obligations under this Agreement to any third party
					(including by way of merger, consolidation or the
					acquisition of all or substantially all of our business and
					assets relating to the Agreement) and we will notify you of
					such assignment. This will not adversely affect your rights
					or obligations under this Agreement. You may not assign,
					transfer or charge any of your rights, interests or
					obligations under this Agreement without first obtaining our
					written consent.
				</p>
				<p>
					19.2.Nothing in this Agreement is intended to confer a
					benefit on any person who is not a party to it, and no such
					person has any right under the Contracts (Rights of Third
					Parties) Act 1999 to enforce any terms of this Agreement.
				</p>
				<p>
					19.3.Any waiver or concession we may allow you, will not
					affect our strict rights and your obligations under this
					Agreement.
				</p>
				<p>
					19.4.This Agreement and the documents referred to in it,
					constitute the entire agreement and understanding of the
					parties and supersede any previous agreement between the
					parties relating to the subject matter of this Agreement.
				</p>
				<h1>20.Funds Protection</h1>
				<p>
					All relevant funds corresponding to your Available Balance
					are segregated from our funds and held in the Customer Funds
					Account in accordance with the safeguarding requirements of
					the Electronic Money Regulations 2011 by law. In the event
					that we became insolvent those funds are protected against
					claims made by any of our creditors.
				</p>
				<h1>21.Regulation &amp; Law</h1>
				<p>
					21.1.The Payment Services, Card and Account are payment
					services and not deposit, credit or banking products and are
					not covered by the Financial Services Compensation Scheme.
				</p>
				<p>
					21.2.The terms of this Agreement are governed by English law
					and you can bring legal proceedings in respect of the
					products in the English courts. If you live in Scotland you
					can bring legal proceedings in respect of the products in
					either the Scottish or the English courts. If you live in
					Northern Ireland you can bring legal proceedings in respect
					of the products in either the Northern Irish or the English
					courts.
				</p>
			</Segment>
		</Container>
	);
}

export default Terms;
