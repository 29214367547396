import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import request from '@services/request';
import { Token, TokenOrderPayload, TokenStore, TokenTransaction, RequestTransactionsPayload, TokenPendingTransaction, TokenTransferPayload, TokenWithdrawPayload } from '@features/tokens/types';
import { getErrorWithParams, showErrorNotification } from '../swal/slice';
import { SocketService } from '@/services/socketService';
import { Subscription } from 'rxjs/internal/Subscription';


const initialState: TokenStore = {
	tokens: [],
	loading: false,
	estimatedBalance: 0,
	error: null,
	transactionList: [],
	updateTransactions: false,
	pendingTransactionList: [],
	pendingTransactionCount: 0,
};

const tokensSlice = createSlice({
	name: 'tokens',
	initialState,
	reducers: {

		setTokens(state, action: PayloadAction<Token[]>) {
			state.tokens = action.payload;
		},
		setTokenLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setEstimatedBalance: (state, { payload }: PayloadAction<number>) => {
			state.estimatedBalance = payload;
		},
		setError: (state, { payload }: PayloadAction<any>) => {
			state.error = payload;
		},
		setTransactions(state, action: PayloadAction<Array<TokenTransaction>>) {
			state.transactionList = action.payload;
		},
		setPendingTransactions(state, action: PayloadAction<Array<TokenPendingTransaction>>) {
			state.pendingTransactionList = action.payload;
		},
		setPendingCount: (state, { payload }: PayloadAction<number>) => {
			state.pendingTransactionCount = payload;
		},
		setUpdateTransactions: (state, { payload }: PayloadAction<boolean>) => {
			state.updateTransactions = payload;
		}
	}
});

export const { setTokens,
	setTokenLoading,
	setEstimatedBalance,
	setError,
	setTransactions,
	setPendingTransactions,
	setPendingCount,
	setUpdateTransactions } = tokensSlice.actions;


export const getTokens = (): AppThunk => {
	return async (dispatch) => {
		try {
			setError(null);
			dispatch(setTokenLoading(true));
			const response = await request.get('/api/tokens');
			const { data } = response;
			dispatch(setTokens(data.tokens));
			dispatch(setEstimatedBalance(data.estimatedBalance));
			return data;
		} catch (e) {
			setError('Token loading error');
		} finally {
			dispatch(setTokenLoading(false));
		}
	};
};

export const postTokenOrder = async (payload: TokenOrderPayload) => {
	const response = await request.post('/api/tokens/order', payload);
	const { data } = response;
	return data;
};

export const postTokenTransfer = async (payload: TokenTransferPayload) => {
	const response = await request.post('/api/tokens/transfer', { ...payload });
	const { data } = response;
	return data;
};

export const postTokenWithdraw = async (payload: TokenWithdrawPayload) => {
	const response = await request.post('/api/tokens/withdraw', { ...payload });
	const { data } = response;
	return data;
};

export const getPendingTransactions = (): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.get('/api/tokens/transactions/pending');
			const { data } = response;
			dispatch(setPendingTransactions(data));
			return data;
		} catch (e) {
			dispatch(setError('Pending Transacition loading error'));
		}
	};
};

export const getUserTransactions = (payload: RequestTransactionsPayload): AppThunk => {
	return async dispatch => {
		try {
			const response = await request.post('/api/tokens/transactions', payload);
			const { data } = response;
			dispatch(setTransactions(data));
			return data;
		} catch (e) {
			dispatch(setError('Token Transacition loading error'));
		}
	};
};

export const lookupTokenWallet = async (walletId: string) => {
	const { data } = await request.get(`/api/tokens/lookup?walletId=${walletId}`
	);
	return data;
};

export const lookupCryptoAddress = async (address: string, network: string) => {
	const { data } = await request.get(`/api/tokens/wallet-address/lookup?beneficiaryCryptoAddress=${address}&beneficiaryCryptoNetwork=${network}`
	);
	return data;
};

export const isValidTokenWallet = async (walletId: string) => {
	try {
		await lookupTokenWallet(walletId);
		return true;
	} catch (e) {
		const err = getErrorWithParams(e);
		if (err && err.error === 'notFound' && err.error_param === 'walletId') {
			return false;
		} else {
			await showErrorNotification(e);
		}
	}
};

export const isValidCyptoAddress = async (address: string, network: string) => {
	try {
		await lookupCryptoAddress(address, network);
		return true;
	} catch (e) {
		const err = getErrorWithParams(e);
		if (err && err.error === 'invalid' && err.error_param === 'beneficiaryCryptoAddress') {
			return false;
		} else {
			await showErrorNotification(e);
		}
	}
};

export const getTokenFee = (token: Token, price: number) => {

	const subProcess = token?.subProcesses[Object.keys(token?.subProcesses).find(key => token?.subProcesses[key]['type'] === 'OWN_TRANSFER')];
	const fee = subProcess?.fees?.find(f => f.type === 'VOLUME');
	const totalFee = Math.max(fee?.minFee, price * (fee?.feePercent / 100)) || 0;

	return totalFee;
};

let socketService: SocketService;
let cryptoSubscriber: Subscription;


export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('tokens');
	}
};

export const disconnect = (): void => {
	socketService = null;
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		try {
			cryptoSubscriber = socketService.listen('tokens.account', {}).subscribe((data) => {
				if (data === 'updated') {
					dispatch(getTokens());
					dispatch(getPendingTransactions());
				}
			});
		} catch (e) {
			console.log(e);
			dispatch(setError(e));
		}
	};
};

export const unsubscribe = (): void => {
	cryptoSubscriber.unsubscribe();
};


export default tokensSlice.reducer;
