import React from 'react';
import { Segment, Header, Grid, Container, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SvgRejectedIcon from '@/icons/RejectedIcon';


const CardTransferCancel = (): React.ReactElement => {
	const { t } = useTranslation('translations');

	return (
		<Grid>
			<Grid.Row centered>
				<Container text textAlign="center">
					<Segment padded="very" className="submitSegment">
						<SvgRejectedIcon id="SvgRejectedIcon" />
						<Header as="h2">{t('paymentsubmitted.topupCancelled')}</Header>
						<Button as={Link} to="/wallet" basic className='retrunDashboard'>{t('paymentsubmitted.button')}</Button>
					</Segment>
				</Container>
			</Grid.Row>
		</Grid>
	);
};
export default CardTransferCancel;
