export enum Operations {
	CREATE_ACCOUNT = 'CREATE_ACCOUNT',
	CREATE_CARD = 'CREATE_CARD',
	CREATE_STOCK = 'CREATE_STOCK',
	CREATE_TRUST = 'CREATE_TRUST',
	CREATE_CRYPTO = 'CREATE_CRYPTO_ACCOUNT',
	CREATE_TOKENS = 'CREATE_TOKENS',
	CREATE_VAULT = 'CREATE_VAULT'
}

export enum AccountProviderType {
	POOLING = 'POOLING',
	POOLING_IBAN = 'POOLING_IBAN',
	IBAN = 'IBAN',
	CARD = 'CARD',
	CRYPTO = 'CRYPTO'
}
