/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Divider, Grid, Icon, List, } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Input, Select } from 'formik-semantic-ui-react';
import {  alpha3ToAlpha2, GetNameOptions, LocalizedCountryNames } from 'i18n-iso-countries';
import localCallingCodes from './callingCodes.json';
import areaCodes from './areaCodeMapping.json';
import { useField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getAllowedCountries } from '@/features/countries/slice';

const callingCodes = [];
for (const code of localCallingCodes) {
	if (callingCodes.includes(code) !== true) {
		callingCodes.push(code);
	}
}

type PersonalProps = {
	validatePassword: (value: string) => void,
	setServiceAllowed: (value: boolean) => void,
	passwordErrors: Array<string>,
	dirty: boolean,
	contriesList: LocalizedCountryNames<GetNameOptions>,
	freelancer?: boolean
}

interface Country {
	code: string,
	label: string
}

const Individual = ({ validatePassword, setServiceAllowed, passwordErrors, dirty, contriesList, freelancer }: PersonalProps): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	//const [countryCodes] = useState<Array<string>>(callingCodes);
	const [countryOptions] = useState<Array<Country>>(
		Object.keys(contriesList)
			.map($code => ({
				code: $code,
				label: contriesList[$code],
			})));
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
	const [country, setCountry] = useState(null);
	const [phone, setPhone] = useState<string>('');
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField('countryCode');
	const { setValue } = helpers;
	const { invitationEnabled } = useSelector(
		(state: RootState) => state.status
	);

	useEffect(() => {
		dispatch(getAllowedCountries());
	}, []);

	const allowedCountries = useSelector(
		(state: RootState) => state.allowedCountries
	);

	const serviceIsAllowdInCountry = allowedCountries.list.includes(country);

	useEffect(() => {
		if (country) {
			const codeMapping = areaCodes.filter(entry => entry.country === contriesList[country]);
			if (codeMapping.length === 1 && phone === '') {
				setPhone(codeMapping[0].code);
			}
		}
		setServiceAllowed(serviceIsAllowdInCountry);
	}, [country, serviceIsAllowdInCountry]);

	return (
		<div>
			<Grid className="name" columns={2} stackable>
				<Grid.Column className="firstname">
					<Input
						label={t('form.fields.firstname')}
						name="firstName"
						errorPrompt />
				</Grid.Column>
				<Grid.Column className="lastname">
					<Input
						label={t('form.fields.lastname')}
						name="lastName"
						errorPrompt />
				</Grid.Column>
			</Grid>
			<Input
				fluid
				name="email"
				label={t('form.fields.email')}
				placeholder={t('form.placeholder.email')}
				errorPrompt
				maxLength={255}
			/>
			<Divider section />
			<div>
				<Input
					icon={<Icon name={showPassword ? 'eye slash' : 'eye'} link onClick={() => { setShowPassword(!showPassword); }} />}
					name="password"
					label={t('form.fields.password')}
					placeholder={t('form.placeholder.password')}
					type={showPassword ? 'text' : 'password'}
					errorPrompt
					validate={validatePassword}
				/>
				<Input
					icon={<Icon name={showConfirmPassword ? 'eye slash' : 'eye'} link onClick={() => { setShowConfirmPassword(!showConfirmPassword); }} />}
					name="passwordConfirm"
					label={t('form.fields.confirmpassword')}
					placeholder={t('form.placeholder.confirmpassword')}
					type={showConfirmPassword ? 'text' : 'password'}
					errorPrompt >
				</Input>
			</div>
			<List className="validation-items">
				<List.Header as="h5" className="validationsheader">{t('registration.passwordvalidators.title')}</List.Header>
				<List.Item className={`${(dirty && passwordErrors.indexOf('len') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.len')}</List.Item>
				<List.Item className={`${(dirty && passwordErrors.indexOf('spec') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.spec')}</List.Item>
				<List.Item className={`${(dirty && passwordErrors.indexOf('upper') === -1 ? 'passed' : '')}`}><Icon name="check circle outline" /> {t('registration.passwordvalidators.upper')}</List.Item>
			</List>
			<Divider className="validationsdivider" section />

			<Select
				fluid
				name="country"
				label={t('form.fields.country')}
				placeholder={t('form.placeholder.country')}
				onChange={(e, v) => setCountry(v.value)}
				errorPrompt
				search
				clearable
				selectOnNavigation={false}
				options={countryOptions.map(c => ({ key: c.code, value: c.code, text: c.label }))}
			/>

			<Input
				fluid
				name="phoneNumber"
				type="number"
				value={phone}
				icon='plus'
				iconPosition='left'
				onChange={(e, v) => setPhone(v.value.replace(/\D/g, ''))}
				errorPrompt
				label={t('form.fields.phone')} 
				onWheel={(e) => e.target.blur()}
				/>

            <Input
                className="birthdateinput"
                name="birthDate"
                label={ t('form.fields.birthdate') }
                type="date"
                placeholder={ t('form.placeholder.date') }
                max="2999-12-31"
                errorPrompt/>
            {
                serviceIsAllowdInCountry ?
                    <p className="color-green">
                        { t('registration.available') }
                    </p> :
                    <p className="color-red">
                        { t('registration.restriction') }
                    </p>
            }
			{(invitationEnabled && !freelancer) && <Input
				label={t('form.fields.invitationCode')}
				name="invitationCode"
				errorPrompt />}
		</div >);
};

export default Individual;
