import React, { useState } from 'react';
import {
	Button,
	Divider,
	Grid,
	Header,
	List,
	Modal
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Contact } from '@/features/contacts/types';
import { ContactTypes } from './CreateContact';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';


interface Props {
	open: boolean;
	onClose: () => void;
	contact: Contact;
}

const ContactDetailsModal = ({ open, onClose, contact }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [countries] = useState(getNames('en'));

	if (!contact) return null;
	return (<Modal
		size="small"
		open={open}
		onClose={() => onClose}>
		<Modal.Content>
			<Grid container >
				<Grid.Column>
					<Header textAlign='center'>{contact.paymentType === ContactTypes.SEPA ? t('contacts.details.sepaHeader') : t('contacts.details.internalHeader')}</Header>
					<Divider hidden />

					<List relaxed>
						<List.Item>
							<List.Header>{t('contacts.create.beneType')}</List.Header>
							<span>{contact.business ? t('contacts.create.business') : t('contacts.create.individual')}</span>
						</List.Item>
						<List.Item>
							<List.Header>{contact.business ? t('contacts.create.companyName') : t('contacts.create.name')}</List.Header>
							<span>{contact.name}</span>
						</List.Item>

						{contact.paymentType === ContactTypes.LOCAL &&
							<List.Item>
								<List.Header>{t('contacts.create.id')}</List.Header>
								<span>{contact.walletId}</span>
							</List.Item>}
						{contact.paymentType === ContactTypes.UK_CHAPS && <>
							<List.Item>
								<List.Header>{t('sendmoneyTranslation.data.sortCode')} </List.Header>
								<span>{contact.sortCode}</span>
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoneyTranslation.data.accountNumber')} </List.Header>
								<span>{contact.accountNumber}</span>
							</List.Item>
						</>
						}
						{contact.paymentType === ContactTypes.SEPA && <>
							<List.Item>
								<List.Header>{t('contacts.create.iban')}</List.Header>
								<span>{contact.account}</span>
							</List.Item>

							<List.Item>
								<List.Header>{t('contacts.create.bank')}</List.Header>
								<span>{contact.bank}</span>
							</List.Item>

							<List.Item>
								<List.Header>{t('contacts.create.country')}</List.Header>
								<span>{countries[alpha3ToAlpha2(contact.country)]}</span>
							</List.Item>

							<List.Item>
								<List.Header>{t('contacts.create.address')}</List.Header>
								<span>{contact.address}</span>
							</List.Item>

							<List.Item>
								<List.Header>{t('contacts.create.bicSwift')}</List.Header>
								<span>{contact.bic}</span>
							</List.Item>

							<List.Item>
								<List.Header>{contact.business ? t('contacts.create.companyRegNumber') : t('sendmoneyTranslation.activedata.identity')}</List.Header>
								<span>{contact.identification ?? '---'}</span>
							</List.Item>
						</>}
					</List>

					<Divider hidden />
					<Button onClick={() => onClose()} fluid primary content='Close' icon='close' />
				</Grid.Column>
			</Grid>
		</Modal.Content>
	</Modal>);
};

export default ContactDetailsModal;
