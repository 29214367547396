import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/rootReducer';
import isEmpty from 'is-empty';
import {
	Message,
	Grid,
	Segment,
	Divider,
	Container,
	Image,
	Transition,
	Ref
} from 'semantic-ui-react';
import { getDeviceInfo } from '@helpers/browser';
import logo from '@assets/registrationLogo.svg';
import './Login.css';
import { login, logout, resendEmail } from '@features/user/userSlice';
import { UserType } from '@features/user/types';
import { showInfo, showErrorNotification } from '@features/swal/slice';
import { DeviceInfo } from '@features/user/types';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ResendEmail from '@/components/Login/ResendEmail';
import qs from 'qs';
import Footer from '@/components/Footer/Footer';
import { Agents } from '@/helpers/globalTypes';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

const Login = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const history = useHistory();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const testRef = useRef();
	const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(null);
	const [loginError, setError] = useState<boolean>(false);
	const [emailError, setEmailError] = useState<boolean>(false);
	const [email, setEmail] = useState<string>('');
	const [emailSending, setEmailSending] = useState<boolean>(false);
	const [type, setType] = useState<UserType>(UserType.INDIVIDUAL);
	const [inactive, setInactive] = useState<boolean>(false);

	const { regionRestricted, registrationDisabledReason, registrationEnabled, agent } = useSelector(
		(state: RootState) => state.status
	);

	const initialValues = {
		email: '',
		password: ''
	};

	const validationSchema = Yup.object({
		email: Yup.string().email(t('form.validator.email')).required(t('form.validator.required')),
		password: Yup.string().required(t('form.validator.required')),
	});

	useEffect(() => {
		async function getBrowserInfo() {
			setDeviceInfo(await getDeviceInfo());
		}
		getBrowserInfo();
	}, []);


	useEffect(() => {
		const query = qs.parse(search, { ignoreQueryPrefix: true });
		if (query.mode === 'BUSINESS') {
			setType(UserType.BUSINESS);
		} else if (query.mode === 'INACTIVE') {
			setInactive(true);
		}
	}, [search]);

	const submit = async (formData, formikProps) => {
		const recaptchaToken = await getRecaptureToken();
		const { setSubmitting } = formikProps;

		setError(false);
		try {
			await dispatch(logout());
			const { email, password } = formData;
			setEmail(email);
			await dispatch(login(type, { email, password, deviceInfo, recaptchaToken }));
			history.push('/wallet');
		}
		catch ({ data }) {
			setSubmitting(false);
			if (data.errors.length > 0 && data.errors[0].error === 'email_not_confirmed') {
				setEmailError(true);
			} else if ((data.errors.length > 0) && data.errors[0].error === 'required' && data.errors[0].error_param === 'authenticatorCode') {
				setError(false);
			} else if ((data.errors.length > 0) && data.errors[0].error === 'invalid' && data.errors[0].error_param === 'authenticatorCode') {
				setError(false);
			} else {
				setError(true);
			}
		}
	}; 

	const resend = async () => {
		setEmailSending(true);
		try {
			const recaptchaToken = await getRecaptureToken();
			await dispatch(resendEmail(email, recaptchaToken));
		} catch (e) {
			showErrorNotification(e);
		} finally {
			setEmailSending(false);
			setEmailError(false);
		}
	};

	const onRegistrationClick = (e) => {
		if(['DTSCB', 'COSPAY'].includes(agent)) {
			e.preventDefault();
			window.location.replace('https://www.dtsmoney.com');
		}
		if (!registrationEnabled) {
			e.preventDefault();
			showInfo({ title: 'swal.REGISTRATION_DISABLED_TITLE', text: 'swal.REGISTRATION_DISABLED_TEXT', textParams: { param_value: registrationDisabledReason, timer: null } });
		}
		if (regionRestricted) {
			e.preventDefault();
			showInfo({ title: 'swal.REGION_RESTRICTED_TITLE', text: 'swal.REGION_RESTRICTED_TEXT' });
		}
	};

	return (
		<Container fluid className="dark content-wrapper">
			<ResendEmail dimmer={emailSending} open={emailError} onClose={() => { setEmailError(false); }} resend={resend} />
			<Grid id="login" verticalAlign="middle" centered stackable divided="vertically">
				<Grid.Column>
					<Segment>
						<Segment basic style={{ marginBottom: 0 }}>
							<Image src={logo} className={agent === Agents.WZ ? 'logo halfLogo' : 'logo'} />
						</Segment>
						<Segment basic style={{ marginTop: 0 }}>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={submit}
							>
								{({ errors, isSubmitting, dirty }) => {
									return (
										<Ref innerRef={testRef}>
											<Form
												size="large"
												error={loginError}>
												<Transition visible={loginError} animation='scale' duration={500}>
													<Message
														error
														content={t('errors.EMAIL_OR_PASSWORD_INCORRECT')} />
												</Transition>
												{!loginError && <Transition visible={inactive} animation='scale' duration={500}>
													<Message
														error
														content={t('session.loginErr')} />
												</Transition>}
												<Input
													fluid
													name="email"
													icon="envelope"
													iconPosition="left"
													placeholder={t('form.placeholder.email')}
													errorPrompt
												/>
												<Input
													fluid
													name="password"
													icon="lock"
													iconPosition="left"
													placeholder={t('form.placeholder.password')}
													type="password"
													errorPrompt
												/>
												<Divider fitted hidden />
												{/* 												{businessEnabled && !allowMultipleBusinessRegistration && <Checkbox label={t('login.business')} toggle checked={type === UserType.BUSINESS}
													onChange={() => type === UserType.INDIVIDUAL ? setType(UserType.BUSINESS) : setType(UserType.INDIVIDUAL)} />} */}
												<Divider hidden /><SubmitButton
													disabled={isSubmitting || !isEmpty(errors) || !dirty}
													primary size="large" type="submit">
													{t('login.submit')}
												</SubmitButton>
											</Form>
										</Ref>);
								}}
							</Formik>
						</Segment>
						<Segment basic>
							<Grid columns={2}>
								<Grid.Column>
									<Link to="/auth/forgot-password">{t('login.resetPassword')}</Link>
								</Grid.Column>
								<Grid.Column textAlign="right">
									<Link to="/auth/signup" onClick={onRegistrationClick}>{t('login.register')}</Link>
								</Grid.Column>
							</Grid>
						</Segment>
					</Segment>
				</Grid.Column>
			</Grid>
			<Footer authorized={false} />
		</Container >
	);
};

export default Login;
