import React, { useState } from 'react';
import { Divider, Grid, Header, List, Segment } from 'semantic-ui-react';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Token, TokenAction } from '@/features/tokens/types';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import {  getTokens, postTokenOrder } from '@/features/tokens/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { trimDecimals, roundTwo } from '@/helpers/round';
import useTokens from '@/helpers/customHook/useTokens';
import useAccounts from '@/helpers/customHook/useAccounts';
import useFee from '@/helpers/customHook/useFee';
import { containsDecimals } from '@/helpers/number';

interface Props {
	initToken: Token,
	onComplete?: () => void
}


const SellTokenForm = ({ initToken, onComplete }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();

	const [tokenQuantity, setTokenQuantity] = useState<string>('');
	const [currentToken, setCurrentToken] = useState<Token>(initToken);
	const [toAccountId, setToAccountId] = useState<number>();
	const currency = currentToken.currency;

	const { tokenOptions, tokens } = useTokens();
	const { accountOptions } = useAccounts(currency);


	const subProcess = currentToken?.subProcesses[Object.keys(currentToken?.subProcesses).find(key => currentToken?.subProcesses[key]['type'] === 'OWN_TRANSFER')];

	const initialValues = {
		token: initToken.id + '-' + initToken.accountId,
		amount: '',
		toAccountId: ''
	};

	const currentHoldings = trimDecimals(currentToken.availableHoldings, currentToken.precision) ?? 0;
	const rawCurrentHoldings = parseFloat(currentToken?.availableHoldings ?? '0');

	const validationSchema = Yup.object({
		token: Yup.string().required(t('form.validator.required')),
		amount: Yup.number().label('Amount').required(t('form.validator.required'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.min(0.00001)
			.test('amount', t('tokens.header.maxTokens', { amount: rawCurrentHoldings }), (value) => value <= rawCurrentHoldings)
			.test('amount', t('tokens.wholeNumber'), (value) => (currentToken.precision === 0 && containsDecimals(value)? false : true)),
		toAccountId: Yup.string().required(t('form.validator.required'))
	});
 

	const quantity = parseFloat(tokenQuantity) ?? 0; 
	const totalPrice = quantity * roundTwo(parseFloat(currentToken.sellPrice));
	const { fee } = useFee(toAccountId, subProcess?.proc, totalPrice);

	const totalMoney = (totalPrice - fee);


	const confirmToken = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { amount, toAccountId } = formData;
		setSubmitting(true);
		const dataPayload = {
			accountFromId: currentToken.accountId,
			accountToId: toAccountId
		};

		const order = {
			operation: subProcess.proc,
			tokenProc: currentToken.proc,
			id: currentToken.id,
			direction: TokenAction.SELL,
			amount: currentHoldings === amount ? currentToken.availableHoldings : amount,
			data: dataPayload
		};
		try {
			await postTokenOrder(order);
			showSuccessNotification(t('tokens.tokenRequestCreated'));
			dispatch(getTokens());
		} catch (err) {
			showErrorNotification(err);
		} finally {
			setSubmitting(false);
			onComplete && onComplete();
		}
	};

	const handleTokenChange = (event, data) => {
		const newToken = tokens.find(p => p.id + '-' + p.accountId === data.value);
		setCurrentToken(newToken);
	};

	const handleAccountChange = (event, data) => {
		setToAccountId(data.value);
	};

	return (
		<Grid.Column width={11}>
			<Segment className='token-trade-window'>
				<Header className='stage'>{t('tokens.tokenActions.SELL')}</Header>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={confirmToken}
				>
					{({ errors, isSubmitting, setFieldTouched }) => {
						return (
							<Form autoComplete="off">
								<div className="tokenTopup ">
									<Input name="amount"
										className="big-amount-input big-color-font highlight-item "
										transparent
										type="number"
										placeholder="0.00"
										onWheel={(e) => e.target.blur()}
										errorPrompt
										onChange={e => { setTokenQuantity(e.currentTarget.value); }}
									/>
									<Header className="currencyLabel big-color-font highlight-item " as="h2">{currentToken.symbol}</Header>
								</div>
								<List.Item relaxed>
									<Select
										id='token'
										name='token'
										label={t('tokens.tokenActions.SELL')}
										onChange={(event, data) => { handleTokenChange(event, data); setTimeout(() => setFieldTouched('amount', true)); }}
										errorPrompt
										options={tokenOptions} />
								</List.Item>
								<List.Item relaxed>
									<Select
										fluid
										name='toAccountId'
										label={t('tokens.header.toAccount')}
										onChange={(event, data) => handleAccountChange(event, data)}
										errorPrompt
										options={accountOptions} />
								</List.Item>
								<List relaxed >
									<List.Header>{t('tokens.header.sellPrice')}</List.Header>
									<List.Item>
										<NumberFormat displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`≈ ${currentToken.currency} `} value={currentToken.sellPrice} />
									</List.Item>
								</List>
								<List relaxed >
									<List.Header>{t('tokens.header.fee')}</List.Header>
									<List.Item>
										<NumberFormat displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`≈ ${currentToken.currency} `} value={fee} />
									</List.Item>
								</List>
								<List relaxed>
									<List.Header>{t('tokens.header.youreceive')}</List.Header>
									<List.Item>
										<NumberFormat displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`≈ ${currentToken.currency} `} value={totalMoney} />

									</List.Item>
									{totalMoney < 0 &&
										<div className="color-red token-error" >
											{t('sendmoneyTranslation.data.moreThanZero')}
										</div>
									}
								</List>
								<Divider className='tokens-trade-divider' />
								<SubmitButton
									className='tokens-trade-submit'
									disabled={!isEmpty(errors) || isSubmitting || totalMoney < 0}
									primary fluid size="large" type="submit">
									{t('tokens.btn.submit')}
								</SubmitButton>
							</Form>
						);
					}}
				</Formik>
			</Segment>
		</Grid.Column>
	);
};


export default SellTokenForm;
