import React from 'react';
import { Segment, Divider, Button, Header, List, Grid, Icon } from 'semantic-ui-react';
import './SendMoneyConfirmation.css';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import useFee from '@/helpers/customHook/useFee';

type ConfirmationProps = {
	onConfirm: () => void,
	payload: any,
	process: any,
	submitting: boolean,
	files?: any,
	isDomesticTrustPayment?: boolean,
}

const CospayUser = ({ payload, process, onConfirm, submitting, files, isDomesticTrustPayment }: ConfirmationProps): React.ReactElement => {
	const { t } = useTranslation('translations');

	const { fee } = useFee(payload.account.accountId, process.proc, payload.amount);

	const youPay = parseFloat(payload.amount) + fee;
	const accountAmount = Math.round((parseFloat(payload.account.availableBalance) + Number.EPSILON) * 100) / 100;
	const insufficientFunds = accountAmount < youPay;

	return (
		<div id="">
			<Grid>
				<Grid.Column width={12}>
					<Segment padded>
						<Header as="h3">{t('sendmoney.data.confirmheader')}</Header>
						<Divider hidden />
						<List relaxed="very">
							<List.Item>
								<List.Header>{t('sendmoney.data.transferamount')}</List.Header>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={payload.amount} />
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.currency')}</List.Header>
								{payload.currency}
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.transferfee')}</List.Header>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={fee}
									prefix={`${payload.currency} `} />
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.youpay')}</List.Header>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={parseFloat(payload.amount) + fee}
									prefix={`${payload.currency} `} />
							</List.Item>
							{insufficientFunds && <div id='insufficientFunds'> {t('sendmoneyTranslation.data.insufficient')}</div>}
							<Divider />
							<List.Item>
								<List.Header>Name</List.Header>
								{payload.to}
							</List.Item>
							{payload.isToIban ?
								<>
									<List.Item>
										<List.Header>{payload?.ibanFieldName ?? 'IBAN'}</List.Header>
										{payload?.iban}
									</List.Item>
									{payload?.bicSwift && <List.Item>
										<List.Header>{t('sendmoneyTranslation.data.bicswift')}</List.Header>
										{payload?.bicSwift}
									</List.Item>
									}
								</> :
								<>
									<List.Item>
										<List.Header>{isDomesticTrustPayment ? 'Routing number' : t('sendmoneyTranslation.data.sortCode')}</List.Header>
										{isDomesticTrustPayment ? payload?.routingNumber : payload?.sortCode}
									</List.Item>
									<List.Item>
										<List.Header>{t('sendmoneyTranslation.data.accountNumber')}</List.Header>
										{payload?.accountNumber}
									</List.Item>
								</>
							}
							{payload?.reference &&
								<List.Item>
									<List.Header>{t('sendmoney.data.reference')}</List.Header>
									{payload.reference}
								</List.Item>}
							{files?.length > 0 && <List.Item>
								<List.Header>{t('sendmoney.data.document')}</List.Header>
								{files.length > 0 && (
									<div id="uploadedFiles">
										{files.map((file) =>
											<div className="uploadedFile" key={file.name}>
												<Icon size="large" name="file outline" />
												<span className="fileName">{file.name}</span>
											</div>
										)}
									</div>
								)}
							</List.Item>}
						</List>
						<Divider section />
						<Button loading={submitting} onClick={onConfirm} primary fluid disabled={insufficientFunds}>{t('sendmoney.activedata.confirmbutton')}</Button>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};

export default CospayUser;
