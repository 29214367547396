import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';
import status from '@features/status/statusSlice';
import user from '@features/user/userSlice';
import kyc from '@features/kyc/slice';
import accounts from '@features/account/slice';
import cards from '@features/card/slice';
import swal from '@features/swal/slice';
import twoFA from '@features/2fa/slice';
import credentials from '@features/user/credentialsSice';
import transaction from '@features/transaction/transactionSlice';
import tabs from '@features/tabs/slice';
import operations from '@features/operations/slice';
import faq from '@features/faq/slice';
import tickets from '@features/tickets/slice';
import contacts from '@features/contacts/slice';
import stocks from '@features/stocks/slice';
import employee from '@features/employee/slice';
import trust from '@features/trust/slice';
import crypto from '@features/crypto/slice';
import settings from '@features/settings/slice';
import allowedCountries from '@features/countries/slice';
import tokens from '@features/tokens/slice';
import vaults from '@features/earn/slice';
import kyb from '@features/kyb/slice';
import kycCalendar from '@features/scheduleMeeting/slice';
import maintenance from '@/features/maintenance/maintenanceSlice';

const appReducer = combineReducers({ contacts, user, accounts, cards, status, swal, credentials, transaction, kyc, twoFA, tabs, operations, faq, tickets, stocks, employee, trust, crypto, settings, allowedCountries, tokens, vaults, kyb, kycCalendar, maintenance });


const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
	if (action.type === 'RESET_APP') {
		const { status, settings, allowedCountries } = state;
		state = {} as RootState;
		state = {
			...state,
			settings,
			status,
			allowedCountries
		};
	}
	if(action.type === 'SWITCH_REPRESENTATION') {
		state = {
			...state,
			accounts: undefined,
			cards: undefined
		};
	}
	return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>

export default rootReducer;
