import React, { useState } from 'react';
import { Segment, Header, Button, Divider, List, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { usePasswordValidation } from '../../Registration/hooks';
import { changePassword } from '@features/security/slice';
import { showSuccessNotification, showException } from '@features/swal/slice';
import '../Security.css';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

const Password = (): React.ReactElement => {
	const [edit, setEdit] = useState<boolean>(false);
	const { t } = useTranslation('translations');
	const [passwordErrors, validatePassword] = usePasswordValidation();
	const individualValidator = Yup.object({
		passwordOld: Yup.string()
			.required(t('form.validator.required')),
		password:
			Yup.string()
				.required(t('form.validator.required')),
		passwordConfirm: //TODO matching
			Yup.string()
				.required(t('form.validator.required'))
				.oneOf([Yup.ref('password'), null], t('form.validator.confirmpassword'))
	});

	const initialValues = {
		password: '',
		passwordConfirm: '',
		passwordOld: ''
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { password, passwordOld } = formData;
		try {
			const recaptchaToken = await getRecaptureToken();
			await changePassword({ newPassword: password, oldPassword: passwordOld, recaptchaToken });
			showSuccessNotification(t('swal.PASSWORD_UPDATED'));
			setEdit(false);
		} catch (e) {
			await showException(e, [
				{
					key: 'unchanged',
					title: 'swal.ERROR',
					message: 'swal.NEW_PASSWORD_UNCHANGED'
				},
				{
					key: 'invalid',
					title: 'swal.ERROR',
					message: 'swal.INVALID_OLD_PASSWORD'
				}
			]);
		} finally { setSubmitting(false); }
	};

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

	return (
		<div id="securitypassword">
			<Segment padded>
				<Header size='medium'>{t('password.data.header')}</Header>
				{!edit && <Button onClick={() => { setEdit(true); }} primary basic>{t('password.activedata.changebutton')}</Button>}
				{edit &&
					(<Formik
						initialValues={initialValues}
						validationSchema={individualValidator}
						onSubmit={submit}
					>{({ errors, isSubmitting, dirty }) => (
						<Form>
							<br />
							<Input icon={<Icon name={showPassword ? 'eye slash' : 'eye'} link onClick={() => {
								setShowPassword(!showPassword);
							}} />} name="passwordOld" label={t('form.validator.currentpassword')}
								type={showPassword ? 'text' : 'password'} errorPrompt />
							<Divider />
							<Input icon={<Icon name={showNewPassword ? 'eye slash' : 'eye'} link onClick={() => {
								setShowNewPassword(!showNewPassword);
							}} />} name="password" label={t('form.fields.newpasswordlabel')}
								type={showNewPassword ? 'text' : 'password'} errorPrompt
								validate={validatePassword}>
							</Input>
							<Input
								icon={<Icon name={showConfirmPassword ? 'eye slash' : 'eye'} link onClick={() => {
									setShowConfirmPassword(!showConfirmPassword);
								}} />} name="passwordConfirm" label={t('form.fields.confirmPasswordLabel')}
								type={showConfirmPassword ? 'text' : 'password'} errorPrompt>
							</Input>
							<List className="validation-items">
								<List.Header as="h5"
									className="validationsheader">{t('password.passwordvalidators.title')}</List.Header>
								<List.Item
									className={`${(dirty && passwordErrors.indexOf('len') === -1 ? 'passed' : '')}`}><Icon
										name="check circle outline" /> {t('password.passwordvalidators.len')}</List.Item>
								<List.Item
									className={`${(dirty && passwordErrors.indexOf('spec') === -1 ? 'passed' : '')}`}><Icon
										name="check circle outline" /> {t('password.passwordvalidators.spec')}</List.Item>
								<List.Item
									className={`${(dirty && passwordErrors.indexOf('upper') === -1 ? 'passed' : '')}`}><Icon
										name="check circle outline" /> {t('password.passwordvalidators.upper')}</List.Item>
							</List>
							<SubmitButton type='submit'
								disabled={isSubmitting || !isEmpty(errors) || !dirty || !isEmpty(passwordErrors)}
								primary>{t('password.activedata.changepwd')}</SubmitButton>
						</Form>
					)}
					</Formik>)
				}
			</Segment>
		</div>
	);
};

export default Password;
