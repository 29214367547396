import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dropdown, Grid, Icon, List, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { BankAccount } from '@features/account/types';
import { cancelToken, getAccount } from '@features/account/slice';
import { setAccount } from '@features/tabs/slice';
import './BankAccountDetails.css';
import AccountError from './Shared/AccountError';
import NumberFormat from 'react-number-format';
import RequestMoneyIcon from '@icons/RequestMoneyIcon';
import { SendMoneyIcon } from '@icons/index';
import { Agents } from '@/helpers/globalTypes';
import BankDetails from '@/components/Banking/BankDetails';
import { UserType } from '@/features/user/types';

const extractSubprocesses = (subProcesses) => {
	return Object.values(subProcesses).map(value => value['type']);
};

type BankAccountDetailsProps = {
	bankAccount: BankAccount
}

const BankAccountDetails = ({ bankAccount }: BankAccountDetailsProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);

	const { status, user } = useSelector(
		(state: RootState) => state.user
	);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const [subProcesses, setSubProcesses] = useState<any>([]);
	const [showBankDetails, setShowBankDetails] = useState<boolean>(true);
	const [, setSource] = useState<any>(null);
	const [error, setError] = useState<string>(null);
	const [dropdownRef] = useState<any>(React.createRef());
	const [, setMobileMenuOpen] = useState<boolean>(false);
	const [hideCreateAccount, setHideCreateAccount] = useState<boolean>(false);

	const walletId=bankAccount?.parentWalletId??bankAccount.walletId;

	const getDetails = useCallback(() => {
		setError(null);
		cancelToken();
		const get = async () => {
			setSubProcesses([]);
			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			setSource(source);
			try {
				const detail = await getAccount(bankAccount.accountId, source);
				setSubProcesses(extractSubprocesses(detail.subProcesses));
				setError(null);
			}
			catch (e) {
				if (e && e.data) {
					setError(e.data.message);
				}
			}
		};
		get();
	}, [bankAccount.accountId]);

	useEffect(() => {
		getDetails();
	}, [getDetails]);

	useEffect(() => {
		if (!(user.type === UserType.BUSINESS || user.onBehalfOf !== null)) {
			if (!user.accountSettings.unlimited && (list.length >= user.accountSettings.maxCount)) {
				setHideCreateAccount(true);
			}
		}

	}, []);

	useEffect(() => {
		if (status.source === Agents.WALLEXCARD && bankAccount.providerType === 'POOLING') {
			setShowBankDetails(false);
		} else {
			setShowBankDetails(true);
		}
	}, [bankAccount.accountId]);

	const isSendMoneyEnabled = () => {
		if (subProcesses.indexOf('PAYMENT') !== -1) return true;
		return subProcesses.indexOf('TRANSFER') !== -1;
	};

	const isExchangeEnabled = () => {
		return subProcesses.indexOf('FX_ORDER') !== -1;
	};


	if (error) return <AccountError message={error} />;

	return (
		<div id="bankaccountdetails">
			<Grid padded>
				<Grid.Row className="mobileaccountdetailsRow" only="mobile">
					<Grid.Column>
						<Segment className="myaccountsegment" >
							<Dropdown
								ref={dropdownRef}
								value={bankAccount.accountId}
								closeOnChange
								as={() => {
									return (<div style={{ width: '100%' }} id="mobile-account-dropdown" onClick={() => {
										setMobileMenuOpen(true);
									}}>
										<div>
											<div className="tab-name">{bankAccount.name}</div>
											<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${bankAccount.currency} `} value={bankAccount.availableBalance} /></div>
											<div className="tab-hold">{t('cards.balanceOnHold')}<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${bankAccount.currency} `} value={bankAccount.balanceOnHold} /></div>
										</div>
										<Dropdown fluid icon='chevron down'>
											<Dropdown.Menu>
												{list.map(account => <Dropdown.Item onClick={() => {
													setMobileMenuOpen(false);
													dispatch(setAccount(account));
												}} key={account.accountId}>
													<div style={{ width: '100%' }}>
														<div>
															<div className="tab-name">{account.name}</div>
															<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${account.currency} `} value={account.availableBalance} /></div>
															<div className="tab-hold">{t('cards.balanceOnHold')}<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${account.currency} `} value={account.balanceOnHold} /></div>
														</div>
													</div>
												</Dropdown.Item>)}
											</Dropdown.Menu>
										</Dropdown>
									</div>);
								}} >
							</Dropdown>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				{/* Mobile only buttons */}
				<Grid.Row only="mobile">
					{(bankAccount.providerType !== 'POOLING' || (agent !== Agents.DTS && agent !== Agents.CUSTODYFY)) && <Grid.Column className="recievemoneyClmn" width="8">
						<Button className="recievebuttonmobile" fluid basic as={Link} to={`/wallet/transfers/request?account=${bankAccount.accountId}`} >
							<RequestMoneyIcon />
							<br />
							<Button.Content>{t('banking.data.requestmoneybutton')}</Button.Content>
						</Button>
					</Grid.Column>}
					<Grid.Column className="sendmoneyClmn" width="8">
						{isSendMoneyEnabled() && <>
							<Button className="sendbuttonmobile" fluid basic as={Link} to={`/wallet/transfers/send?account=${bankAccount.accountId}`}>
								<SendMoneyIcon />
								<br />
								<Button.Content>{t('banking.data.sendmoneybutton')}</Button.Content>
							</Button>
						</>}
					</Grid.Column>
					<Grid.Column  className='exchangeClmn' width="8">
						{isExchangeEnabled() && <>
							<Button className="sendbuttonmobile" fluid basic as={Link} to={`/wallet/transfers/exchange?account=${bankAccount.accountId}`}>
								<SendMoneyIcon />
								<br />
								<Button.Content>{t('banking.data.exchangebutton')}</Button.Content>
							</Button>
						</>}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="walletidBTNRow" only="mobile">
					<Grid.Column>
						<CopyToClipboard id="wallet-clipboard-button" text={walletId}>
							<Button basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}}>{t('banking.data.walletid')} {walletId} <Icon name='clone outline' /></Button>
						</CopyToClipboard>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="walletidBTNRow" only="mobile">
					{!hideCreateAccount && <Grid.Column>
						<Button labelPosition="left" icon="plus circle" primary fluid content={t('create.createNewAccount')} onClick={() => history.push('/wallet/create/account')} />
					</Grid.Column>}
				</Grid.Row>
				{/* Tablet and computer only buttons */}
				<Grid.Row only="computer tablet">
					<Grid.Column width={11}>
						{(bankAccount.providerType !== 'POOLING' || (agent !== Agents.DTS && agent !== Agents.CUSTODYFY)) && <Button as={Link} to={`/wallet/transfers/request?account=${bankAccount.accountId}`} className="buttonmargin" secondary content={t('banking.data.requestmoneybutton')} icon='plus' labelPosition='left' />}
						{isSendMoneyEnabled() && <Button as={Link} to={`/wallet/transfers/send?account=${bankAccount.accountId}`} disabled={!isSendMoneyEnabled()} className="buttonmargin" primary content={t('banking.data.sendmoneybutton')} icon='right arrow' labelPosition='left' />}
						{isExchangeEnabled() && <Button as={Link} to={`/wallet/transfers/exchange?account=${bankAccount.accountId}`} disabled={!isExchangeEnabled()} className="buttonmargin" primary content={t('banking.data.exchangebutton')} icon='right arrow' labelPosition='left' />}
					</Grid.Column>
					<Grid.Column width={4} floated="right">
						<List relaxed="very" verticalAlign='middle'>
							<List.Item className='walletIDItem'>
								<List.Header>{t('banking.data.walletid')}</List.Header>
								<List.Content>
									{walletId}
									<CopyToClipboard id="wallet-clipboard-button" text={walletId}>
										<Button basic primary onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}}><Icon name='clone outline' /></Button>
									</CopyToClipboard>
								</List.Content>
							</List.Item>
						</List>
					</Grid.Column>
				</Grid.Row>
				{showBankDetails && <BankDetails bankAccount={bankAccount} />}
			</Grid>
		</div >
	);
};

export default BankAccountDetails;
