import React from 'react';
import { Link } from 'react-router-dom';
import './PaymentSubmitted.css';
import { Container, Segment, Button, Header, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { SucessStatus } from '../../../../services/icons';
import { TransferType } from '../SendMoney';

interface Props {
	payload: any
}

const PaymentSubmitted = ({ payload }: Props) => {
	const { t } = useTranslation('translations');

	return (
		<Grid>
			<Grid.Row centered>
				<Container text textAlign="center">
					<Segment padded="very" className="submitSegment">
						<SucessStatus id="successCheckIcon" />
						{payload.type !== TransferType.REQUEST && <div><Header as="h2">{t('paymentsubmitted.data.header')}</Header>
							<p>
								{t('paymentsubmitted.data.sentamount', { amount: payload.amount, account: payload.account })}
							</p>
						</div>}
						{payload.type === TransferType.REQUEST && <div><Header as="h2">{t('paymentsubmitted.data.header_request')}</Header>
							<p>
								{t('paymentsubmitted.data.requestamount', { amount: payload.amount, account: payload.account })}
							</p>
						</div>}
						{payload.date && <p className="estimatedarrival">
							{t('paymentsubmitted.data.arrival', { date: '---' })}
						</p>}
						<Button as={Link} to="/wallet" basic className='retrunDashboard'>{t('paymentsubmitted.button')}</Button>
					</Segment>
				</Container>
			</Grid.Row>
		</Grid>
	);
};
export default PaymentSubmitted;
