import React from 'react';
import { Divider, Grid, Header, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import isEmpty from 'is-empty';

interface Props {
	isOpen: boolean,
	onClose: (value) => void,
	onSubmit: (formData) => void
}

const AccountNameForm = ({ isOpen, onClose, onSubmit }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const initialValues = { name: '' };
	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required'))
	});

	return (
		<Modal open={isOpen} onClose={() => onClose(false)}>
			<Modal.Content >
				<Grid padded centered columns="1">
					<Grid.Row>
						<Grid.Column width="16">
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="10" textAlign="center">
							<Modal.Content>
								<Modal.Description>
									<Header>Enter account name</Header>
								</Modal.Description>
							</Modal.Content>
						</Grid.Column>
					</Grid.Row>
					<Grid.Row>
						<Grid.Column width="16">
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSubmit}
							>
								{({ errors, isSubmitting, dirty }) => (
									<Form
										className="accountNameForm"
										size="large"
										style={{ margin: '0 auto 2rem' }}
									>
										<Input
											autoComplete="off"
											fluid
											autoFocus
											name="name"
											errorPrompt
										/>
										<Divider clearing />
										<SubmitButton
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
											primary size="large" type="submit">
											{t('form.buttons.submit')}
										</SubmitButton>
									</Form>)}
							</Formik>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Modal.Content >
		</Modal>
	);
};

export default AccountNameForm;
