import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, List, Segment } from 'semantic-ui-react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import i18n from '@/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguages } from '@features/settings/slice';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';

interface Props {
	authorized: boolean
}

const Footer = ({ authorized }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [selectedLang, setSelectedLang] = useState<any>(process.env.REACT_APP_DEFAULT_LANGUAGE ? process.env.REACT_APP_DEFAULT_LANGUAGE : 'en');
	const currentYear = new Date().getFullYear().toString();
	const dispatch = useDispatch();
	const [codes, setCodes] = useState<string[]>([]);
	const { languageCodes } = useSelector(
		(state: RootState) => state.settings
	);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const linkProps = authorized ? {
		exact: true,
		as: NavLink
	} : {
		as: NavLink
	};

	const getLanguageCodes = useCallback(() => {
		const get = async () => {
			dispatch(getLanguages());
		};
		get();
	}, [dispatch]);

	useEffect(() => {
		getLanguageCodes();
	},[getLanguageCodes]);

	useEffect(() => {
		setCodes(languageCodes);
	}, [languageCodes]);

	const baseLink = authorized ? '/wallet' : '/legal';

	const getFlagName = (code: string): string =>  {
		if(code === 'en') {
			return 'uk';
		} else {
			return code;
		}
	};

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setSelectedLang(lng);
	};

	return (
		<div id="footer">
			<Container>
				<Segment padded basic className="footer" >
					<Grid stackable columns='equal'>
						<Grid.Row>
							{agent !== Agents.WZ && agent !== Agents.AZUREPAY && <Grid.Column className="privacyfooter">
								<List bulleted>
									<List.Item to={`${baseLink}/privacypolicy`} {...linkProps} >{t('footer.privacy')}</List.Item>
								</List>
							</Grid.Column>}
							{agent !== Agents.WZ && agent !== Agents.AZUREPAY  && <Grid.Column className="termsfooter">
								<List bulleted>
									<List.Item to={`${baseLink}/terms`} {...linkProps}>{t('footer.terms')}</List.Item>
								</List>
							</Grid.Column>}
							{agent !== Agents.DTS && agent !== Agents.WZ && agent !== Agents.CUSTODYFY && agent !== Agents.DTSUS && agent !== Agents.YESSY && agent !== Agents.OLAMIT && agent !== Agents.AZUREPAY && agent !== Agents.COSPAY && <Grid.Column className="amlfooter">
								<List bulleted>
									<List.Item to={`${baseLink}/amlpolicy`} {...linkProps}>{t('footer.aml')}</List.Item>
								</List>
							</Grid.Column>}
							{/* {agent === Agents.DTS && <Grid.Column className="amlfooter">
								<List bulleted>
									<List.Item to={`${baseLink}/cookiesPolicy`} {...linkProps}>{t('footer.cookies')}</List.Item>
								</List>
							</Grid.Column>} */}
							{(agent === Agents.DTS || agent === Agents.CUSTODYFY || agent === Agents.DTSUS || agent === Agents.YESSY || agent === Agents.OLAMIT) && <Grid.Column className="amlfooter">
								<List bulleted>
									<List.Item to={`${baseLink}/termsofuse`} {...linkProps}>{t('footer.termsOfUse')}</List.Item>
								</List>
							</Grid.Column>}
							{authorized &&
								<Grid.Column className="supportfooter">
									<List bulleted>
										<List.Item to={`${baseLink}/support`} as={NavLink}>{t('footer.support')}</List.Item>
									</List>
								</Grid.Column>
							}
							<Grid.Column className="switcher">
								<List horizontal divided>
									{codes.map( code =>
										<List.Item key={code} onClick={() => changeLanguage(code)} className={selectedLang === code ? 'active' : ''}><i className={`${getFlagName(code)} flag`}/></List.Item>)}
								</List>
							</Grid.Column>

							<Grid.Column floated="right" width={4}>
								<List.Item className="footercopyright">{t('footer.copyright', { year: currentYear })}</List.Item>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Segment>
			</Container>
		</div>
	);
};
export default Footer;
