import React from 'react';
import {
	Button, Divider,
	Modal, Segment
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { approveEmployee } from '@features/employee/slice';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';

interface Props {
	employee: any;
	open: boolean;
	onClose: (value?: string) => void,
	refetch: any
}

const EmployeeCodeModal = ({ employee, open, onClose, refetch }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const initialValues = {
		label: ''
	};

	const validationSchema = Yup.object({
		employeeNumber: Yup.string().required(t('form.validator.required'))
	});

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { employeeNumber } = formData;
		await approvePendingEmployee({
			employeeNumber: employeeNumber,
			...employee
		});
		onClose();
	};

	const approvePendingEmployee = async (employee) => {
		try {
			await approveEmployee(employee);
			showSuccessNotification(t('swal.EMPLOYEE_ADDED'));
			await refetch;
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	return <Modal
		size="mini"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Segment basic>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting, dirty }) => (
						<Form
							size="large">
							<Input
								label='Employee number'
								fluid
								name="employeeNumber"
								errorPrompt
							/>
							<span>{t('business.addEmployeeCode.description')}</span>
							<Divider/>
							<div className='editBTNS'>
								<SubmitButton
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									primary size="large" type="submit">
									{t('form.buttons.add')}
								</SubmitButton>
								<Button className='discardBTN' content='Cancel' onClick={() => onClose()} />
							</div>
						</Form>)}
				</Formik>
			</Segment>
		</Modal.Content>
	</Modal>;
};

export default EmployeeCodeModal;
