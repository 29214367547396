import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { CheckCircleIcon } from '../../../services/icons';
import { Button, Dimmer, Grid, Header, Icon, List, Loader, Segment } from 'semantic-ui-react';
import KYCForm from '../../KYC/KYCForm';
import { getOperationsByType, runOperation } from '@features/operations/slice';
import { Operations } from '@features/operations/types';
import { showErrorNotification } from '@features/swal/slice';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAccount, setLoading } from '@features/stocks/slice';
import { getAccounts } from '@features/trust/slice';
import AccountNameForm from '@/components/Banking/Shared/AccountNameForm';
import { isVerifiedForPartner } from '@features/identity/slice';
import { RootState } from '@/rootReducer';
import { UserType } from '@/features/user/types';
import { setCryptoAccount } from '@features/crypto/slice';
import { useDropzone } from 'react-dropzone';
import { postUserPoa } from '@/features/files/slice';
import { isEmpty } from 'lodash';
import { Agents } from '@/helpers/globalTypes';

interface Props {
	type: string,
	loadingMessage?: string,
	kyc?: boolean
}

const AccountsPlaceholder = ({ type, kyc, loadingMessage }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const { path } = useRouteMatch();
	const [open, setOpen] = useState<boolean>(false);
	const [openName, setOpenName] = useState<boolean>(false);
	const [partnerStatus, setPartnerStatus] = useState<boolean>(false);
	const [rejectionReason, setRejectionReason] = useState<Array<string>>([]);
	const [kycRejected, setKycRejected] = useState<boolean>(false);

	const { user, status } = useSelector(
		(state: RootState) => state.user
	);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const createAccount = () => {
		history.push(`/wallet/create/${type}?from=` + path);
	};

	useEffect(() => {
		if (!user) return;

		if (user.kyc.length > 0 && user.kyc[0].status === 'REJECTED') {
			setKycRejected(true);
		} else {
			setKycRejected(false);
		}
	}, [user]);

	useEffect(() => {
		if (kycRejected) {
			const reason = user.kyc[0].rejectionReason ?? null;
			const reasonArray = reason === null ? [] : reason.split(';;;');
			setRejectionReason(reasonArray);
		}
	}, [kycRejected]);

	const createStock = () => {
		dispatch(setLoading(true));
		const get = async () => {
			try {
				const response = await getOperationsByType(Operations.CREATE_STOCK);
				const procedure = response[0];
				const payload = {
					name: 'Stock account',
					ccy: 'EUR'
				};
				const account = await runOperation(procedure.proc, payload);
				dispatch(setAccount(account));
			}
			catch (e) {
				showErrorNotification(e);
			} finally {
				dispatch(setLoading(false));
			}
		};
		get();
	};

	const createCrypto = () => {
		dispatch(setLoading(true));
		const get = async () => {
			try {
				const response = await getOperationsByType(Operations.CREATE_CRYPTO);
				const procedure = response[0];
				const payload = {
					name: 'Crypto account'
				};
				const account = await runOperation(procedure.proc, payload);
				dispatch(setCryptoAccount(account));
			}
			catch (e) {
				showErrorNotification(e);
			} finally {
				dispatch(setLoading(false));
			}
		};
		get();
	};

	const getPartnerStatus = useCallback(() => {
		const get = async () => {
			const status = await isVerifiedForPartner();
			setPartnerStatus(status);
		};
		get();
	}, []);

	useEffect(() => {
		getPartnerStatus();
	}, [getPartnerStatus]);

	const createTrust = () => {
		dispatch(setLoading(true));
		const get = async () => {
			try {
				const response = await getOperationsByType(Operations.CREATE_TRUST);
				const procedure = response[0];
				const payload = {
					name: 'Custody account',
					ccy: 'EUR'
				};
				const account = await runOperation(procedure.proc, payload);
				dispatch(setCryptoAccount(account));
			}
			catch (e) {
				showErrorNotification(e);
			} finally {
				dispatch(setLoading(false));
			}
		};
		get();
	};

	const submit = (formData) => {
		const { name } = formData;
		dispatch(setLoading(true));
		const get = async () => {
			try {
				const response = await getOperationsByType(Operations.CREATE_TRUST);
				const procedure = response[0];
				const payload = {
					name: name,
					ccy: 'EUR'
				};
				await runOperation(procedure.proc, payload);
			}
			catch (e) {
				showErrorNotification(e);
			} finally {
				dispatch(setLoading(false));
				dispatch(getAccounts());
				setOpenName(false);
			}
		};
		get();
	};

	const create = (type: string) => {
		if (type === 'stock') {
			createStock();
		} else if (type === 'trust') {
			createTrust();
		} else if (type === 'crypto') {
			createCrypto();
		}
		else if (type === 'token') {
			null;
		} else {
			createAccount();
		}
	};

	const onKYCclick = (e) => {
		e.preventDefault();
		if (partnerStatus && user.type !== UserType.BUSINESS) {
			history.push('/wallet/transfer');
		} else {
			setOpen(true);
		}
	};

	const isCreateButtonShown = () => {
		if(user.poa.required && user.poa.status !== 'APPROVED') {
			return false;
		}
		
		if(type === 'account' && status.autoAccountCreation) {
			return false;
		} else if(type === 'trust' && status.custodyAccountSettings.autoCreate) {
			return false;
		} else {
			return true;
		}
	};

	return (
		isEmpty(user?.poa) ?
			<Dimmer active={isEmpty(user?.poa)} inverted>
				<Loader>{'Loading'}</Loader>
			</Dimmer>
			:
			<Segment padded="very">
				<Dimmer active={!!loadingMessage} inverted>
					<Loader>{loadingMessage}</Loader>
				</Dimmer>
				<Grid>
					<Grid.Column textAlign="center">
						{!kyc && ((user.poa.status === 'REJECTED' || user.poa.status === null) && user.poa.required) ?
							<>
								<Header>
									<Icon className="rejectedicon" name="close" style={{ display: 'flex', marginLeft: 'auto', marginRight: 'auto' }} />
									<div>{t('poa.placeholder.header')}</div>
								</Header>
								{user.poa.rejectionReason && <div><strong>{t('poa.placeholder.rejectionReason')}</strong> {user.poa.rejectionReason}</div>}
							</>

							: <Header>
								<CheckCircleIcon />
								<div>{(type === 'trust' && agent === Agents.DTSUS) ? t('kyc.header.trustDtsus') : t(`kyc.header.${type}`)}</div>
							</Header>}
						{type === 'token' && <div>{t('tokens.terms')}</div>}
						{kyc &&
							<div>{(type === 'trust' && agent === Agents.DTSUS) ? `${t('kyc.label.trustDtsus')} ` : `${t(`kyc.label.${type}`)} `}
								<span>{t('kyc.link', { type: user.type === UserType.BUSINESS ? 'KYB' : 'KYC' })}</span>
							</div>}
						<br />
						{kycRejected && <>
							<div className='kycRejected' style={{ color: 'red' }}>You identity verification is rejected</div>
							{rejectionReason.length > 0 &&
								<List bulleted style={{ marginTop: '0' }}>
									{rejectionReason.map(reason => (
										<List.Item key={reason}>{reason}</List.Item>
									))}
								</List>
							}
							<br />
						</>}
						{kyc && <Button primary onClick={onKYCclick}>{t('kyc.button.verify')}</Button>}
						{!kyc && (user.poa.status === 'PENDING' && user.poa.required) && <div>{t('poa.placeholder.pending')}</div>}
						{!kyc && ((user.poa.status === 'REJECTED' || user.poa.status === null) && user.poa.required) && <SubmitPoa />}
						{!kyc && (user.accountSettings.maxCount === 1 && status.autoAccountCreation && type === 'account' && (user.poa.status === 'APPROVED' || !user.poa.required)) && <div>{t('kyc.label.autoAccountCreation')}</div>}
						{!kyc && (status.custodyAccountSettings.autoCreate && type === 'trust' && (user.poa.status === 'APPROVED' || !user.poa.required)) && <div>{t('kyc.label.autoAccountCreation')}</div>}
						{!kyc && isCreateButtonShown() && <Button
							onClick={() => create(type)}
							className="createVirtCardBtn"
							primary>
							<Icon name="plus circle" />
							{t(`kyc.button.${type}`)}</Button>}
					</Grid.Column>
				</Grid>
				<AccountNameForm isOpen={openName} onClose={setOpenName} onSubmit={submit} />
				{open && <KYCForm open={open} setOpen={setOpen} />} 
			</Segment>
	);
};


const SubmitPoa = () => {

	const { t } = useTranslation('translations');
	const [residenceProof, setResidenceProof] = useState(null);

	const onDrop = useCallback((acceptedFiles) => {
		setResidenceProof(acceptedFiles[0]);
	}, []);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/png,image/jpg,image/jpeg,application/pdf', multiple: false, maxSize: 10485760,
	onDropRejected(fileRejections) {
		fileRejections.map(file => {
			file.errors.map(err => {
				if (err.code === 'file-too-large') {
					showErrorNotification(err, 'File size too large');
				}
			});
		});
	}, });


	const removeAll = useCallback(() => {
		setResidenceProof(null);
		acceptedFiles.splice(0);
	}, []);

	const submit = async () => {
		const poaFile = new FormData();
		poaFile.append('file', residenceProof);
		await postUserPoa(poaFile);
	};

	return (
		<>
			<Grid className='uploadPoa' style={{ marginLeft: '0', marginRight: '0', marginBottom: '1em', marginTop: '1em' }}>
				<Grid.Row><strong>{t('poa.form.label')}</strong></Grid.Row>
				<Grid.Row>
					<span style={{ textAlign: 'start' }}>
						{t('poa.form.help')}
					</span></Grid.Row>
				<Grid.Row>
					<div {...getRootProps()} id='file-container'>
						<input {...getInputProps()} id='file-drop' />
						<span><Icon name='upload' />  {t('poa.form.dropzone')}</span>
					</div>
				</Grid.Row>
				{residenceProof &&
					<Grid.Row>
						<Icon size="large" name="file outline" />
						<div className="fileName">{residenceProof.name}</div>
						<Icon className="fileName" size="large" name='trash' link onClick={() => removeAll()} />
					</Grid.Row>}
				<Grid.Row style={{ justifyContent: 'center' }}>
					<Grid.Column width={3} mobile={6} >
						<Button disabled={!residenceProof} primary size='large' fluid onClick={submit}> {t('poa.form.button')}</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

export default AccountsPlaceholder;
