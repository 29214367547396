import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Button, Divider, Grid, Header, Icon, Modal, Segment, Checkbox as ConfirmBox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import CospayUserConfirmation from './CospayUserConfirmation';
import { TransferType } from '../SendMoney';
import { runOperation } from '@features/operations/slice';
import { getErrorWithParams, showErrorNotification, showInfoNotification } from '@features/swal/slice';
import { lookupContact } from '@features/contacts/slice';
import CreateContact from '@/components/Dashboard/Contacts/CreateEdit';
import './CospayUser.css';
import { postFile } from '@features/files/slice';
import { maxTwoDecimals } from '@/helpers/number';
import { AccountLimits } from '@/features/account/types';
import { ContactTypes } from '@/components/Dashboard/Contacts/CreateContact';
import { Agents } from '@/helpers/globalTypes';

type Props = {
	onSend: (type: TransferType, value: any) => void,
	account: any,
	subProcesses: any,
	accountLimits: AccountLimits
}

const TrustAccount = ({ onSend, account, subProcesses, accountLimits }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const hiddenFileInput = React.useRef(null);
	const [payload, setPayload] = useState<any>();
	const [process, setProcess] = useState<any>();
	const [addToContacts, setAddToContacts] = useState<boolean>(false);
	const [paymentSubmitting, setPaymentSubmitting] = useState<boolean>(false);
	const fileTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	];
	
	const { agent } = useSelector(
		(state: RootState) => state.status
		);
		
	const [confirmed, setConfirmed] = useState<boolean>(agent === Agents.DTSUS ? true : false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 1) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 1) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};
	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};
	const isValidFiles = (amount: string): boolean => {
		if (parseInt(amount) >= 5000) {
			return selectedFiles.length === 1;
		} else {
			return true;
		}
	};
	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};
	useEffect(() => {
		if (!subProcesses) return;
		setProcess(subProcesses[Object.keys(subProcesses).find(key => subProcesses[key]['type'] === 'TRANSFER')]);
	}, [subProcesses]);

	const { contacts, error } = useSelector(
		(state: RootState) => state.contacts
	);

	const validationSchema = Yup.object().shape({
		amount: Yup.number().required(t('form.validator.required'))
			.min(0.1, t('sendmoneyTranslation.data.wrongAmount'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				(value) => value <= account.availableBalance)
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('sendmoneyTranslation.data.limitReached', { ccy: accountLimits.ccy, availableBalanceNow: accountLimits.availableBalanceNow }), (value) => value <= accountLimits.availableBalanceNow),
		from: Yup.string().required(t('form.validator.required')),
		walletId: Yup.string().required(t('form.validator.required')),
	});

	const initialValues = {
		walletId: '',
		from: account.name + ' (' + account.currency + ' ' + account.availableBalance + ')',
		amount: '',
	};

	const submit = async (formData, formikProps) => { 
		const { amount } = formData;
		const { setSubmitting, setFieldError } = formikProps;
		if (!isValidFiles(amount)) {
			showInfoNotification('sendmoneyTranslation.data.documentRequired');
			setSubmitting(false);
			return;
		}
		
		const { walletId } = formData;
		try {
			const user = await lookupContact(walletId, account.currency); 
			if (account.type !== user.accountType || account.currency != user.currency)  {
				setFieldError('walletId', t('sendmoneyTranslation.data.wrongProcess'));
				return;
			}

			setPayload({ ...formData, ...{ currency: account.currency }, ...{ user }, fromAccountId: account.accountId });
		}
		catch (e) {
			const err = getErrorWithParams(e);
			if (e && err.error === 'notFound' && err.error_param === 'walletId') {
				setFieldError('walletId', t('sendmoneyTranslation.data.userNotFound'));
			} else if (err && err.error === 'your' && err.error_param === 'walletId') {
				setFieldError('walletId', t('sendmoneyTranslation.data.belongsToYou'));
			} else {
				await showErrorNotification(e);
			}
		}
		finally {
			setSubmitting(false);
		}
	};

	const send = async () => {
		setPaymentSubmitting(true);
		try {
			const attachments: Map<string, string> = new Map<string, string>();
			if (selectedFiles.length === 1) {
				const formData = new FormData();
				formData.append('file', selectedFiles[0]);
				const fileRecord = await postFile(formData);
				const { key } = fileRecord;
				attachments.set(key, selectedFiles[0].name);
			}
			await runOperation(process.proc, { accountFromId: `${account.accountId}`, accountToId: `${payload.user.accountId}`, amount: parseFloat(payload.amount), attachments: Object.fromEntries(attachments) });
			setPaymentSubmitting(false);
			onSend(TransferType.CONTACT, { ...payload, ...{ currency: account.currency } });
		} catch (e) {
			setPaymentSubmitting(false);
			showErrorNotification(e);
			setPayload(null);
		}
	};

	useEffect(() => { error && showErrorNotification(error); }, [error]);

	if (payload) {
		return (
			<div id="sendmoneyuser">
				<Modal open={addToContacts}>
					<Modal.Content>
						<Segment basic>
							<CreateContact
								fullWidth
								onSave={() => { setAddToContacts(false); }}
								user={{ walletId: payload.walletId, name: `${payload.user.firstName} ${payload.user.lastName}` }}
							/>
						</Segment>
					</Modal.Content>
				</Modal>
				<CospayUserConfirmation submitting={paymentSubmitting} payload={payload} process={process} onConfirm={send} files={selectedFiles} availableAmount={account.availableBalance} />
			</div>
		);
	}


	return (
		<div id="sendmoneyuser">
			<Grid>
				<Grid.Column width={16}>
					<Segment padded>
						<Header className="cospayuserheader" as="h3">{agent === Agents.DTSUS ?  t('sendmoneyTranslation.data.trustHeaderDtsus') : t('sendmoneyTranslation.data.trustHeader')}</Header>
						<Divider hidden />
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ setFieldValue }) => (
								<Form>
									<Input
										fluid
										id="account"
										name="from"
										label={t('sendmoneyTranslation.data.from')}
										disabled
									/>
									<Input
										fluid
										name="amount"
										label={t('sendmoneyTranslation.data.transferamount')}
										placeholder={`0.00 ${account.currency}`}
										type='number'
										errorPrompt
									/>
									<Select
										className="userdropdown"
										placeholder="Select user"
										label={t('sendmoneyTranslation.data.cospayuser')}
										fluid
										name="walletId"
										options={contacts.filter(p => p.paymentType === ContactTypes.LOCAL && p.type === 'TRUST').map((c, index) => ({ key: c.walletId + index, text: c.name, value: c.walletId }))}
										//errorPrompt
									/>
									<Input
										fluid
										name="walletId"
										label={t('sendmoneyTranslation.data.walletid')}
										errorPrompt
									/>
									<Checkbox
										name='addToContacts'
										checked={addToContacts}
										onChange={() => { setAddToContacts(!addToContacts); }}
										label={t('sendmoneyTranslation.data.addtocontacts')}
									/>
									<p><b>{t('sendmoneyTranslation.data.document')}</b></p>

									<input type="file"
										multiple
										accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
										name="files"
										ref={hiddenFileInput}
										onChange={e => {
											uploadFiles(e.currentTarget.files);
											setFieldValue('files', selectedFiles);
										}}
										style={{ display: 'none' }}
									/>
									<Button id='uploadButton' basic type="button" onClick={handleUploadClick} content={t('sendmoneyTranslation.activedata.upload')} icon="upload" />

									{selectedFiles.length > 0 && (
										<Grid className="uploadedFiles" padded>
											<Divider hidden />
											{selectedFiles.map((file, index) =>
												<Grid.Row className="uploadedFile" key={file.name}>
													<Icon size="large" name="file outline" />
													<div className="fileName">{file.name}</div>
													<Grid.Column floated='right' className="deleteAttachment">
														<Icon
															onClick={() => {
																deleteAttachment(index);
																setFieldValue('files', selectedFiles);
															}}
															size="large"
															name="times" />
													</Grid.Column>
												</Grid.Row>
											)}
										</Grid>
									)}
									<Divider section />
									{agent !== Agents.DTSUS && <ConfirmBox
										checked={confirmed}
										onChange={() => { setConfirmed(!confirmed); }}
										label={t('trusts.confirmTransfer')}
										errorPrompt
									/>}
									<Divider hidden />
									<SubmitButton primary fluid type="submit" disabled={!confirmed}>{t('sendmoneyTranslation.activedata.button')}</SubmitButton>
								</Form>)}
						</Formik>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};
export default TrustAccount;
