import React, { useCallback, useEffect, useState } from 'react';
import { Container, Grid, Message, Select, Tab } from 'semantic-ui-react';
import Services from './Home/Services';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { NavLink, Route, useHistory, useRouteMatch } from 'react-router-dom';
import Accounts from '@/components/Banking/Accounts';
import Cards from '@/components/Banking/Cards';
import InboxTab from '@/components/Dashboard/Inbox/InboxTab';
import Stocks from '@/components/Stocks/Stocks';
import { getUnreadCount } from '@features/tickets/slice';
import { showErrorNotification } from '@features/swal/slice';
import { connect, subscribe, unsubscribe } from '@features/account/slice';
import { connect as connectTrust, subscribe as subscribeTrust, unsubscribe as unsubscribeTrust } from '@features/trust/slice';
import { useTranslation } from 'react-i18next';
import i18n from '@/i18n';
import BusinessCards from '../Banking/Card/BusinessCards';
import { UserType } from '@/features/user/types';
import { getMyAccountantCompanies, setOnBehalfOf, unsetOnBehalfOf } from '@/features/employee/slice';
import './Home.css';
import Trust from '@/components/Banking/Trust';
import Crypto from '@/components/Crypto/Crypto';
import LoadingPage from '@/pages/LoadingPage';
import { Agents } from '@/helpers/globalTypes';
import Verification from '../Verification/Verification';
import Tokens from '../Tokens/Tokens';
import Earn from '../Earn/Earn';
import KYCForm from '../KYC/KYCForm';
import AnnouncementModal from '@/pages/Auth/AnnouncementModal';
import CospayAnnouncement from '@/pages/Auth/CospayAnnouncement';
import MaintenanceCard from '../Shared/MaintenanceCard';
import { getMaintenance } from '@/features/maintenance/maintenanceSlice';
import DTSCBAnnouncement from '@/pages/Auth/DTSCBAnnouncement';

const Home = (): React.ReactElement => {
	const { path } = useRouteMatch();
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const { unreadCount } = useSelector(
		(state: RootState) => state.tickets
	);
	const SWITCH_REPRESENTATION_ACTION = {
		type: 'SWITCH_REPRESENTATION'
	};
	const { operations, loading } = useSelector(
		(state: RootState) => state.operations
	);
	const { user } = useSelector(
		(state: RootState) => state.user
	);
	const { agent } = useSelector(
		(state: RootState) => state.status
	);
	const [onBehalfOfOptions, setOnBehalfOfOptions] = useState([]);
	const [onBehalfOfValue, setOnBehalfOfValue] = useState(null);
	const [panes, setPanes] = useState(null);
	const [open, setOpen] = useState<boolean>(false);
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [announcement, setAnnouncement] = useState<boolean>(agent === Agents.DTSUS ? true : false);

	const { isPartiallyDown, isPlanned } = useSelector((state: RootState) => state.maintenance);

	const showMaintenanceCard = isPartiallyDown || isPlanned;


	const bankingPanel = {
		menuItem: {
			as: NavLink,
			content: t('pages.banking'),
			to: `${path}/accounts`,
			exact: true,
			key: 'banking'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/accounts`} >
				<Tab.Pane as="div">
					<Accounts />
				</Tab.Pane>
			</Route>
		)
	};
	const cardPanel = {
		menuItem: {
			as: NavLink,
			content: t('pages.cards'),
			to: `${path}/cards`,
			exact: true,
			key: 'cards',
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/cards`} exact>
				<Tab.Pane as="div">
					<Cards />
				</Tab.Pane>
			</Route>
		)
	};
	const businessCardPanel = {
		menuItem: {
			as: NavLink,
			content: t('pages.cards'),
			to: `${path}/cards`,
			exact: true,
			key: 'cards',
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/cards`} exact>
				<Tab.Pane as="div">
					<BusinessCards />
				</Tab.Pane>
			</Route>
		)
	};
	const stockPanel = {
		menuItem: {
			as: NavLink,
			content: t('pages.stocks'),
			to: `${path}/stocks`,
			exact: true,
			key: 'stocks'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/stocks`} exact>
				<Tab.Pane as="div">
					<Stocks />
				</Tab.Pane>
			</Route>
		)
	};

	const tokenPanel = {
		menuItem: {
			as: NavLink,
			content: t('pages.tokens'),
			to: `${path}/tokens`,
			exact: true,
			key: 'tokens'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/tokens`} exact>
				<Tab.Pane as="div">
					<Tokens />
				</Tab.Pane>
			</Route>
		)
	};

	const earnPanel = {
		menuItem: {
			as: NavLink,
			content: t('pages.earn'),
			to: `${path}/earn`,
			exact: true,
			key: 'earn'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/earn`} exact>
				<Tab.Pane as="div">
					<Earn />
				</Tab.Pane>
			</Route>
		)
	};

	const trustPanel = {
		menuItem: {
			as: NavLink,
			content: agent === Agents.DTSUS ? t('pages.banking') : t('pages.trust'),
			to: `${path}/trust`,
			exact: true,
			key: 'trust'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/trust`} exact>
				<Tab.Pane as="div">
					<Trust />
				</Tab.Pane>
			</Route>
		)
	};
	const cryptoPanel = {
		menuItem: {
			as: NavLink,
			content: 'Crypto',
			to: `${path}/crypto`,
			exact: true,
			key: 'crypto'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/crypto`} exact>
				<Tab.Pane as="div">
					<Crypto />
				</Tab.Pane>
			</Route>
		)
	};
	const verificationPanel = {
		menuItem: {
			as: NavLink,
			content: 'Verification',
			to: `${path}/verification`,
			exact: true,
			key: 'verification'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/verification`} exact>
				<Tab.Pane as="div">
					<Verification />
				</Tab.Pane>
			</Route>
		)
	};

	const inboxPanel = {
		menuItem: {
			as: NavLink,
			content: <div>{t('pages.inbox')}{unreadCount > 0 && (<div className="notificationCount">{unreadCount}</div>)}</div>,
			to: `${path}/inbox`,
			exact: true,
			key: 'inbox',
			//badge: unreadCount,
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/inbox`} exact>
				<Tab.Pane as="div">
					<InboxTab />
				</Tab.Pane>
			</Route>
		)
	};

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getUnreadCount());
			} catch (e) {
				await showErrorNotification(e);
			}
		};
		get();
	}, [dispatch]);

	const format = str => str?.replace(/(^\w|\s\w)/g, m => m.toUpperCase());

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (operations.find(operation => operation === 'CREATE_ACCOUNT')) {
			connect();
			dispatch(subscribe());
			return () => {
				unsubscribe();
			};
		} else if (operations.find(operation => operation === 'CREATE_TRUST')) {
			connectTrust();
			dispatch(subscribeTrust());
			return () => {
				unsubscribeTrust();
			};
		}
	}, [operations, dispatch]);

	useEffect(() => {
		if (!user || user.kyc.length === 0) return;

		if (user.kyc[0].expiredInMonth) {
			setShowNotification(true);
		}

	}, [user]);

	useEffect(() => {
		if (!user || loading) return;

		const activePanes = [];
		if (operations.length !== 0) {
			if (user.type === UserType.EMPLOYEE) {
				activePanes.push(cardPanel);
			} else {
				if (operations.find(operation => operation === 'CREATE_ACCOUNT')) {
					activePanes.push(bankingPanel);
				}
				if (operations.find(operation => operation === 'CREATE_CARD')) {
					user && user.type === UserType.INDIVIDUAL && user.onBehalfOf === null ? activePanes.push(cardPanel) : activePanes.push(businessCardPanel);
				}
				// if (operations.find(operation => operation === 'CREATE_STOCK')) {
				// 	activePanes.push(stockPanel);
				// }
				if (operations.find(operation => operation === 'CREATE_TRUST')) {
					activePanes.push(trustPanel);
				}
				// if (operations.find(operation => operation === 'CREATE_CRYPTO_ACCOUNT')) {
				// 	activePanes.push(cryptoPanel);
				// }
				if (operations.find(operation => operation === 'CREATE_TOKEN_ACCOUNT')) {
					activePanes.push(tokenPanel);
				}
				if (operations.find(operation => operation === 'CREATE_VAULT')) {
					activePanes.push(earnPanel);
				}
			}
		} else if (agent === Agents.AZURE && user.type === UserType.INDIVIDUAL) {
			activePanes.push(verificationPanel);
		}
		activePanes.push(inboxPanel);
		setPanes(activePanes);
	}, [operations, user, i18n.language, unreadCount, loading]);

	useEffect(() => {
		const fetch = async () => {
			const list = await getMyAccountantCompanies();
			const options = [];
			if (list.length) {
				options.push({ key: 'PRIVATE', value: 'PRIVATE', text: t('business.behalfOf.privateProfile') });
				list.forEach(item => {
					options.push({ value: item.businessUserId, key: item.businessUserId, text: item.company });
				});
			}
			setOnBehalfOfOptions(options);
		};
		fetch();
	}, []);

	useEffect(() => {
		if (user) setOnBehalfOfValue(user.onBehalfOf === null ? 'PRIVATE' : user.onBehalfOf);
	}, [user]);

	const handleChange = async (val) => {
		if (user.onBehalfOf === null && val === 'PRIVATE') return;
		if (val === user.onBehalfOf) return;
		if (val === 'PRIVATE') {
			await unsetOnBehalfOf();
		} else {
			await setOnBehalfOf(val);
		}
		setOnBehalfOfValue(val);
		dispatch(SWITCH_REPRESENTATION_ACTION);
		history.push('/wallet');
	};

	useEffect(() => {
		dispatch(getMaintenance());
	}, [dispatch]);


	if (!panes) {
		return <LoadingPage />;
	}



	return (
		<>
			<Container>
				<AnnouncementModal open={announcement} onClose={() => setAnnouncement(false)} />
				{user?.kycTier > 0 && agent === Agents.COSPAY && <CospayAnnouncement agent={agent} />}
				{agent === Agents.DTSCB && <DTSCBAnnouncement />}
				{open && <KYCForm open={open} setOpen={setOpen} />}
				{showNotification &&
					<Message info>
						<Message.Header>{t('kyc.expireSoon.header')}</Message.Header>
						<p><a onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>{t('kyc.expireSoon.text')}</a></p>
					</Message>}
				{showMaintenanceCard &&
					<Grid columns={1}>
						<MaintenanceCard />
					</Grid>
				}
				<Grid columns={2} className="welcome">
					<Grid.Row className="first" only="computer tablet">
						<Grid.Column>
							{user && <div className="welcomeText">{t('business.behalfOf.welcome')}, {format(user.type === UserType.BUSINESS ? user.companyName : user.firstName)}</div>}
						</Grid.Column>
						<Grid.Column>
							{onBehalfOfOptions.length !== 0 &&
								<Select
									className="onBehalfOf"
									name="onBehalfOf"
									placeholder={t('business.behalfOf.onBehalfOfPlaceholder')}
									label={t('business.behalfOf.onBehalfOf')}
									options={onBehalfOfOptions}
									value={onBehalfOfValue}
									onChange={(element, { value }) => handleChange(value)}
								/>}
						</Grid.Column>
					</Grid.Row>
					<Grid.Row className="second" only="computer tablet">
						<Grid.Column>
							{user && user.onBehalfOf !== null && <div>{t('business.behalfOf.youWorkingOBO', { company: user.onBehalfOfCompany })}</div>}
						</Grid.Column>
						<Grid.Column>{user && user.plan.upgradeAllowed && user.type === UserType.INDIVIDUAL && <div className='onBehalfOf'>{user.plan.currentUserLevel.name} {t('plans.planL')} - <a className="upgradeLink" onClick={() => history.push('/wallet/upgrade-plan')} >{t('plans.upgrade')}</a></div>}</Grid.Column>
					</Grid.Row>
				</Grid>

				<Grid centered className="welcome">
					<Grid.Row className="first" only="mobile">
						{user && <div className="welcomeText">{t('business.behalfOf.welcome')}, {format(user.type === UserType.BUSINESS ? user.companyName : user.firstName)}</div>}
					</Grid.Row>
					<Grid.Row only="mobile" className="second">
						<Grid.Column>{user && user.plan.upgradeAllowed && user.type === UserType.INDIVIDUAL && <div className='onBehalfOf'>{user.plan.currentUserLevel.name} {t('plans.planL')} - <a className="upgradeLink" onClick={() => history.push('/wallet/upgrade-plan')} >{t('plans.upgrade')}</a></div>}</Grid.Column>
					</Grid.Row>
					<Grid.Row only="mobile" className="second">
						{onBehalfOfOptions.length !== 0 &&
							<Select
								className="onBehalfOf"
								name="onBehalfOf"
								placeholder={t('business.behalfOf.onBehalfOfPlaceholder')}
								label={t('business.behalfOf.onBehalfOf')}
								options={onBehalfOfOptions}
								value={onBehalfOfValue}
								onChange={(element, { value }) => handleChange(value)}
							/>}
					</Grid.Row>
				</Grid>
				{panes && <Services panes={panes} />}
			</Container>
		</>
	);
};


export default Home;
