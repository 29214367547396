import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, Icon } from 'semantic-ui-react';

interface Props {
	acceptedFiles?: string,
	selectedFiles: any[],
	hideMaxSize?: boolean,
	reverse?: boolean,
	instruction?: string | JSX.Element,
	uploadFiles: (fles: any) => void,
	deleteAttachment: (index: number) => void
}
export const inputAcceptedFiles = 'image/jpg, image/png, image/jpeg, .pdf, .doc, .docx';

const FileUpload = (props: Props) => {

	const { t } = useTranslation('translations');

	const hiddenFileInput = React.useRef(null);
	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	return (<>
		<p>{props.instruction ?? t('sendmoneyTranslation.data.document')}</p>
		<input type="file"
			multiple
			accept={props.acceptedFiles ?? inputAcceptedFiles}
			name="files"
			ref={hiddenFileInput}
			onChange={e => {
				props.uploadFiles(e.currentTarget.files);
				e.currentTarget.value = '';
				//setFieldValue('files', selectedFiles);
			}}
			style={{ display: 'none' }}
		/>
		<Button id='uploadButton' basic type="button" onClick={handleUploadClick} content={t('sendmoneyTranslation.activedata.upload')} icon="upload" />
		<Divider hidden />
		<div id='security-info'>
			<Grid>
				<Grid.Column verticalAlign='middle'><Icon size='large' name='info circle' color='blue' /></Grid.Column>
				<Grid.Column width={15}>{t('sendmoneyTranslation.data.fileTypes')}</Grid.Column>
			</Grid>
		</div>
		{props.selectedFiles.length > 0 && (
			<Grid className="uploadedFiles" padded>
				<Divider hidden />
				{props.selectedFiles.map((file, index) =>
					<Grid.Row className="uploadedFile" key={file.name}>
						<Icon size="large" name="file outline" />
						<div className="fileName">{file.name}</div>
						<Grid.Column floated='right' className="deleteAttachment">
							<Icon
								onClick={() => {
									props.deleteAttachment(index);
									//setFieldValue('files', selectedFiles);
								}}
								size="large"
								name="times" />
						</Grid.Column>
					</Grid.Row>
				)}
			</Grid>
		)}
		<Divider section />
	</>);
};

export default FileUpload;
