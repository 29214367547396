import React from 'react';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';

const Loading = (): React.ReactElement => {
	return (
		<Grid.Row>
			<Grid.Column>
				<Segment disabled className="cardLoading">
					<Dimmer active inverted>
						<Loader />
					</Dimmer>
				</Segment>
			</Grid.Column>
		</Grid.Row>
	);
};

export default Loading;
