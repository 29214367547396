import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './TermsOfUse.css';

function TermsOfUseITCUSTODYFY(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>CONDIZIONI DI UTILIZZO DI CUSTODYFY</h1></Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<h1>1. I nostri Termini e Condizioni&nbsp;</h1>
				<ul>
					<li>
						<p>I presenti termini e condizioni d&apos;uso (&quot;Condizioni&quot;) spiegano come l&apos;utente pu&ograve; utilizzare questo sito web e qualsiasi suo contenuto (&quot;Sito&quot;). Le presenti Condizioni si applicano tra Custodyfy Kommanditbolag, societ&agrave; in accomandita per azioni costituita e registrata in Svezia come fiduciaria professionale ai sensi della legge 2017:630 sulla prevenzione del riciclaggio di denaro e del finanziamento del terrorismo con il Consiglio amministrativo della Contea di Stoccolma come autorit&agrave; di vigilanza ai fini dell&apos;Antiriciclaggio e Contrasto al Finanziamento del Terrorismo (AML/CFT) (di seguito - &quot;Custodydy&quot;, &quot;noi&quot;, &quot;ci&quot; o &quot;nostro&quot;), e Lei, la persona che accede o utilizza il Sito (&quot;Lei&quot; o &quot;Suo&quot;).</p>
					</li>
					<li>
						<p>L&apos;utente &egrave; tenuto a leggere attentamente le presenti Condizioni prima di utilizzare il Sito. Utilizzando il Sito o indicando in altro modo il proprio consenso, l&apos;utente accetta di essere vincolato dalle presenti Condizioni. Se l&apos;utente non &egrave; d&apos;accordo con una qualsiasi delle presenti Condizioni, deve interrompere immediatamente l&apos;utilizzo del Sito.</p>
					</li>
					<li>
						<p>Se si sottoscrive un abbonamento ai Servizi Vault, si applicheranno termini e condizioni separati.</p>
					</li>
				</ul>
				<h1>2. Informazioni su di noi</h1>
				<ul>
					<li>
						<p>Custodyfy &egrave; una Statutory Trust del Delaware con sede legale al 3411 Silverside Road Tatnall Building #104, Wilmington, Delaware, rappresentata dallo Sponsor della Trust, Custodyfy Kommanditbolag, una societ&agrave; svedese a responsabilit&agrave; limitata con sede legale al Central Master Samuelsgatan 60, 111 21 Stoccolma, Svezia e registrata come Professional Trustee ai sensi della legge 2017:630 sulla prevenzione del riciclaggio di denaro e del finanziamento del terrorismo.</p>
					</li>
					<li>
						<p>In caso di domande sul Sito, si prega di contattarci tramite:</p>
						<ul>
							<li>
								<p>invio di un&apos;e-mail a&nbsp;contact@custodyfy.com&nbsp;</p>
							</li>
						</ul>
					</li>
				</ul>
				<h1>3. Utilizzo del sito</h1>
				<ul>
					<li>
						<p>Il Sito &egrave; destinato esclusivamente a un uso personale e non commerciale.</p>
					</li>
					<li>
						<p>L&apos;utente accetta di essere l&apos;unico responsabile di tutti i costi e le spese che potrebbe sostenere in relazione all&apos;utilizzo del Sito.</p>
					</li>
					<li>
						<p>Non promettiamo che il Sito sia appropriato o disponibile per l&apos;uso in luoghi al di fuori dello Spazio Economico Europeo (SEE). Se scegliete di accedere al Sito da localit&agrave; al di fuori del SEE, riconoscete di farlo di vostra iniziativa e di essere responsabili del rispetto delle leggi locali in vigore.</p>
					</li>
					<li>
						<p>Cerchiamo di rendere il Sito il pi&ugrave; accessibile possibile. In caso di difficolt&agrave; nell&apos;utilizzo del Sito, vi invitiamo a contattarci utilizzando i recapiti riportati all&apos;inizio di questa pagina.</p>
					</li>
					<li>
						<p>Come condizione per l&apos;utilizzo del Sito, l&apos;utente accetta di non:</p>
						<ul>
							<li>
								<p>utilizzare in modo improprio o attaccare il nostro Sito introducendo consapevolmente virus, trojan, worm, bombe logiche o qualsiasi altro materiale dannoso o tecnologicamente dannoso (ad esempio tramite un attacco denial-of-service), oppure</p>
							</li>
							<li>
								<p>tentare di ottenere un accesso non autorizzato al nostro Sito, al server su cui &egrave; memorizzato il nostro Sito o a qualsiasi server, computer o database collegato al nostro Sito.</p>
							</li>
						</ul>
					</li>
					<li>
						<p>Possiamo impedire o sospendere l&apos;accesso al Sito se l&apos;utente non rispetta le presenti Condizioni o qualsiasi altra legge applicabile.</p>
					</li>
				</ul>
				<h1>4. La vostra privacy e le vostre informazioni personali</h1>
				<ul>
					<li>
						<p>La vostra privacy e le vostre informazioni personali sono importanti per noi. Tutte le informazioni personali fornite dall&apos;utente saranno trattate in linea con la nostra Informativa sulla privacy disponibile sul sito web di Custodyfy,&nbsp;che spiega quali informazioni personali raccogliamo dall&apos;utente, come e perch&eacute; raccogliamo, archiviamo, utilizziamo e condividiamo tali informazioni, i diritti dell&apos;utente in relazione alle sue informazioni personali e le modalit&agrave; di contatto con noi e con le autorit&agrave; di vigilanza in caso di domande o reclami sull&apos;utilizzo delle sue informazioni personali.</p>
					</li>
				</ul>
				<h1>5. Propriet&agrave;, uso e diritti di propriet&agrave; intellettuale</h1>
				<ul>
					<li>
						<p>I diritti di propriet&agrave; intellettuale sul Sito e su qualsiasi testo, immagine, video, audio o altro contenuto multimediale, software o altre informazioni o materiali inviati o accessibili dal Sito (Contenuti) sono di nostra propriet&agrave; e dei nostri licenziatari.</p>
					</li>
					<li>
						<p>Noi e i nostri licenziatari ci riserviamo tutti i nostri diritti di propriet&agrave; intellettuale (compresi, ma non solo, tutti i diritti d&apos;autore, i marchi, i nomi di dominio, i diritti di design, i diritti di database, i brevetti e tutti gli altri diritti di propriet&agrave; intellettuale di qualsiasi tipo), registrati o non registrati in qualsiasi parte del mondo. Ci&ograve; significa, ad esempio, che ne restiamo proprietari e siamo liberi di utilizzarli come riteniamo opportuno.</p>
					</li>
					<li>
						<p>Nessuna disposizione delle presenti Condizioni concede all&apos;utente alcun diritto legale sul Sito o sul Contenuto se non quello necessario per accedervi. L&apos;utente accetta di non modificare, tentare di eludere o cancellare qualsiasi avviso contenuto nel Sito o nel Contenuto (compresi gli avvisi di propriet&agrave; intellettuale) e, in particolare, in qualsiasi diritto digitale o altra tecnologia di sicurezza incorporata o contenuta nel Sito o nel Contenuto.</p>
					</li>
				</ul>
				<h1>6. Invio di informazioni al sito</h1>
				<ul>
					<li>
						<p>Pur cercando di garantire la sicurezza del Sito, non controlliamo attivamente n&eacute; verifichiamo se le informazioni che ci vengono fornite attraverso il Sito sono riservate, sensibili dal punto di vista commerciale o di valore.</p>
					</li>
					<li>
						<p>A parte le informazioni personali che saranno trattate in conformit&agrave; con la nostra Politica sulla Privacy, non garantiamo che le informazioni forniteci attraverso il Sito siano mantenute riservate e possiamo utilizzarle in modo libero e gratuito come ragionevolmente riteniamo opportuno.</p>
					</li>
				</ul>
				<h1>7. Accuratezza delle informazioni e disponibilit&agrave; del sito</h1>
				<ul>
					<li>
						<p>Cerchiamo di assicurarci che il Sito sia accurato, aggiornato e privo di bug, ma non possiamo promettere che lo sar&agrave;. Inoltre, non possiamo promettere che il Sito sia adatto o idoneo a qualsiasi scopo. Qualsiasi affidamento sulle informazioni contenute nel Sito &egrave; a vostro rischio e pericolo.</p>
					</li>
					<li>
						<p>Possiamo sospendere o interrompere l&apos;accesso o il funzionamento del Sito in qualsiasi momento, come riteniamo opportuno.</p>
					</li>
					<li>
						<p>Tutti i Contenuti sono forniti solo a scopo informativo generale e per informare l&apos;utente su di noi e sui nostri prodotti, nonch&eacute; su notizie, caratteristiche, servizi e altri siti web che potrebbero essere di interesse, ma non sono stati adattati alle sue esigenze o circostanze specifiche. Non costituisce una consulenza tecnica, finanziaria o legale o di qualsiasi altro tipo e non vi si deve fare affidamento per nessun motivo. L&apos;utente deve sempre utilizzare il proprio giudizio indipendente quando utilizza il nostro Sito e il Contenuto dello stesso.</p>
					</li>
					<li>
						<p>Sebbene cerchiamo di assicurarci che il Sito sia disponibile per l&apos;uso da parte dell&apos;utente, non promettiamo che il Sito sia sempre disponibile o che l&apos;uso del Sito sia ininterrotto.</p>
					</li>
				</ul>
				<h1>8. Collegamenti ipertestuali e siti di terzi</h1>
				<ul>
					<li>
						<p>Il Sito pu&ograve; contenere collegamenti ipertestuali o riferimenti a pubblicit&agrave; e siti web di terzi diversi dal Sito. Tali collegamenti ipertestuali o riferimenti sono forniti solo per comodit&agrave; dell&apos;utente. Non abbiamo alcun controllo sulla pubblicit&agrave; o sui siti web di terzi e non ci assumiamo alcuna responsabilit&agrave; legale per i contenuti, il materiale o le informazioni in essi contenuti. La visualizzazione di un collegamento ipertestuale e di un riferimento a una pubblicit&agrave; o a un sito web di terzi non implica l&apos;approvazione del sito web, dei prodotti o dei servizi di tali terzi. L&apos;utilizzo di un sito di terzi pu&ograve; essere regolato dai termini e dalle condizioni di tale sito ed &egrave; a rischio dell&apos;utente.</p>
					</li>
				</ul>
				<h1>9. La nostra responsabilit&agrave; nei vostri confronti</h1>
				<ul>
					<li>
						<p>Se violiamo le presenti Condizioni o siamo negligenti, siamo responsabili nei confronti dell&apos;utente per le perdite o i danni prevedibili che l&apos;utente subisce di conseguenza. Per &quot;prevedibili&quot; intendiamo che, al momento della stipula delle presenti Condizioni, era chiaro che tali perdite o danni si sarebbero verificati o che entrambi sapevamo che si sarebbero potuti ragionevolmente verificare, come conseguenza di qualcosa che abbiamo fatto (o omesso di fare).</p>
					</li>
					<li>
						<p>Non siamo responsabili nei vostri confronti per perdite o danni che non erano prevedibili, per perdite o danni non causati da nostre violazioni o negligenze, o per perdite o danni commerciali.</p>
					</li>
					<li>
						<p>Nulla in queste Condizioni esclude o limita la nostra responsabilit&agrave; per qualsiasi morte o lesione personale causata dalla nostra negligenza, responsabilit&agrave; per frode o dichiarazione fraudolenta, o qualsiasi altra responsabilit&agrave; che la legge non ci consente di escludere o limitare.</p>
					</li>
				</ul>
				<h1>10. Eventi al di fuori del nostro controllo</h1>
				<ul>
					<li>
						<p>Non siamo responsabili nei confronti dell&apos;utente se non riusciamo a rispettare le presenti Condizioni a causa di circostanze al di fuori del nostro ragionevole controllo.</p>
					</li>
				</ul>
				<h1>11. Nessun diritto di terzi</h1>
				<ul>
					<li>
						<p>Nessun altro, oltre a noi o all&apos;utente, ha il diritto di far valere una qualsiasi delle presenti Condizioni.</p>
					</li>
				</ul>
				<h1>12. Modifica dei presenti termini e condizioni</h1>
				<ul>
					<li>
						<p>Nessuna modifica alle presenti Condizioni &egrave; valida o ha effetto se non &egrave; stata concordata da noi per iscritto o se non &egrave; stata effettuata in conformit&agrave; con la presente clausola.</p>
					</li>
					<li>
						<p>Ci riserviamo il diritto di variare i presenti Condizioni in qualsiasi momento. Le nostre Condizioni aggiornate saranno visualizzate sul Sito e continuando a utilizzare e ad accedere al Sito in seguito a tali modifiche, lei accetta di essere vincolato da qualsiasi variazione da noi apportata. &Egrave; sua responsabilit&agrave; controllare periodicamente le presenti Condizioni per verificare tali modifiche.</p>
					</li>
				</ul>
				<h1>13. Reclami</h1>
				<ul>
					<li>
						<p>Cercheremo di risolvere qualsiasi controversia con voi in modo rapido ed efficiente. Se non siete soddisfatti di noi, vi preghiamo di contattarci il prima possibile utilizzando i dettagli di contatto indicati all&apos;inizio di questa pagina.</p>
					</li>
					<li>
						<p>Alle presenti Condizioni si applicano le leggi dello Stato del Delaware, anche se l&apos;utente residente altrove manterr&agrave; il beneficio di tutte le protezioni obbligatorie previste dalle leggi di quel paese.</p>
					</li>
					<li>
						<p>Qualsiasi controversia sar&agrave; soggetta alla giurisdizione esclusiva dei tribunali dello Stato del Delaware.&nbsp;</p>
					</li>
				</ul>

			</Segment>
		</Container>
	);
}

export default TermsOfUseITCUSTODYFY;
