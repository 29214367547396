import React, { useState } from 'react';
import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import { disableAccount } from '../../../features/security/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '@/features/user/userSlice';
import { useHistory } from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { formatDate } from '@/helpers/date';

const AccountActivity = () => {
	const { t } = useTranslation('translations');
	const [edit, setEdit] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const { user } = useSelector(
		(state: RootState) => state.user
	);
	
	const individualValidator = Yup.object({
		password: Yup.string()
			.required(t('form.validator.required'))
	});

	const initialValues = {
		password: '',
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { password } = formData;
		try {
			await disableAccount(password);
			history.push('/auth');
			await dispatch(logout());
			showSuccessNotification(t('swal.ACCOUNT_DISABLED'));
		} catch (e) {
			showErrorNotification(e);
		} finally { setSubmitting(false); }
	};

	return (
		<Segment padded>
			<Header>{t('accountactivity.header')}</Header>
			<p>{t('accountactivity.loginp')} {formatDate(user.previousLoginDate)}</p>
			<p>{t('accountactivity.suscpiciousp')}</p>
			{!edit && <Button onClick={() => { setEdit(true); }} primary basic>{t('accountactivity.button')}</Button>}
			{edit &&
				(<Formik
					initialValues={initialValues}
					validationSchema={individualValidator}
					onSubmit={submit}
				>{({ errors, isSubmitting, dirty }) => (<Form>
						<br />
						<Input
							icon={<Icon name={showPassword ? 'eye slash' : 'eye'} link onClick={() => { setShowPassword(!showPassword); }} />}
							name="password"
							label={t('form.validator.currentpassword')}
							type={showPassword ? 'text' : 'password'}
							errorPrompt
						/>
						<SubmitButton type='submit' disabled={isSubmitting || !isEmpty(errors) || !dirty} primary>{t('accountactivity.button')}</SubmitButton>
					</Form>)}
				</Formik>)
			}
		</Segment>
	);
};
export default AccountActivity;
