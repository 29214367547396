import React from 'react';
import { RootState } from '@/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { setVault } from '@features/tabs/slice';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { Vault } from '@/features/earn/types';

interface HeaderTabProps {
	vault: Vault
}

const HeaderTab = ({ vault }: HeaderTabProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const onClick = (e) => {
		e.preventDefault();
		dispatch(setVault(vault));
	};
	const { currentVault } = useSelector(
		(state: RootState) => state.tabs
	);

	return (<div>
		<div className={`item${currentVault.proc === vault.proc ? ' active' : ''}`} onClick={onClick}>
			<div>
				<div className="tab-name">{vault.name}</div>
					<>
						<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${vault.processProperties.currencySymbol??vault.processProperties.ccy} `} value={vault.totalBalance??'0.00'} /></div>
						<div className="tab-hold">
							<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} suffix={t('vaults.yearlyInterest')} value={parseFloat(vault.config.yearlyInterestRate) * 100} /></div>
					</>
			</div>
		</div>
	</div>
	);
};

export default HeaderTab;
