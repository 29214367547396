import React, { useState } from 'react';
import { Segment, Divider, Button, Header, List, Grid, Message, } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import { postFile } from '@/features/files/slice';
import { TokenTransferPayload } from '@/features/tokens/types';
import { getTokens, postTokenTransfer } from '@/features/tokens/slice';
import { getErrorWithParams, showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { useDispatch } from 'react-redux';
import { tranformSubProcessByType } from '@/features/operations/slice';
import { SendToUserPayload } from './SendToUserForm';

type ConfirmationProps = {
	onConfirm: () => void,
	payload: SendToUserPayload
}

const SendToUserConfirmation = ({ payload, onConfirm }: ConfirmationProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [walletIdError, setWalletIdError] = useState<string>();
	const [submitting, setSubmitting] = useState<boolean>(false);
	const subProcess = tranformSubProcessByType(payload?.token?.subProcesses)['TRANSFER'];

	const send = async () => {
		setSubmitting(true);
		try {
			const attachments: Map<string, string> = new Map<string, string>();
			if (payload.selectedFile) {
				const formData = new FormData();
				formData.append('file', payload.selectedFile);
				const fileRecord = await postFile(formData);
				const { key } = fileRecord;
				attachments.set(key, payload.selectedFile.name);
			}

			const data: TokenTransferPayload = {
				accountFromId: payload.token.accountId,
				amount: payload.amount,
				tokenId: payload.token.id,
				operation: subProcess.proc,
				walletId: payload.walletId,
				reference: payload.reference,
				attachments: Object.fromEntries(attachments)
			};

			await postTokenTransfer(data);

			showSuccessNotification(t('tokens.tokenRequestCreated'));
			dispatch(getTokens());
			onConfirm();
		} catch (e) {
			const err = getErrorWithParams(e);
			if (err && (err.error === 'notFound' || err.error === 'invalid') && err.error_param === 'walletId') {
				setWalletIdError(t('tokens.header.userNotFound'));
			} else {
				await showErrorNotification(e);
			}
			return;
		}
		finally { setSubmitting(false); }
	};

	return (
		<Grid.Column width={11}>
			<Segment className='token-trade-window'>
				<Header as="h3">{t('tokens.header.confirmation')}</Header>
				<Divider hidden />
				<List relaxed>
					<List.Item>
						<List.Header>{t('tokens.header.transferAmount')}</List.Header>
						<NumberFormat
							displayType={'text'}
							decimalScale={payload.token.precision}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							value={payload.amount} />
					</List.Item>
					<List.Item>
						<List.Header>{t('tokens.header.token')}</List.Header>
						{payload.token.symbol}
					</List.Item>
					<Divider />
					<List.Item>
						<List.Header>{t('tokens.header.cryptowalletid')}</List.Header>
						{payload.walletId}
						{walletIdError && <Message negative>{walletIdError}</Message>}
					</List.Item>
					{payload.selectedFile?.name && <List.Item>
						<List.Header>{t('sendmoney.data.document')}</List.Header>
						{payload.selectedFile?.name}
					</List.Item>
					}
				</List>
				<Divider section />
				<Button className='tokens-trade-submit'
					onClick={send}
					disabled={!subProcess.proc || submitting}
					primary fluid size="large" >{t('form.buttons.confirm')}</Button>
			</Segment>
		</Grid.Column>
	);
};

export default SendToUserConfirmation;
