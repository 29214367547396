import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './rootReducer';
import * as countries from 'i18n-iso-countries';
import Dashboard from './pages/Dashboard';
import Auth from './pages/Auth';
import NotFound from './pages/NotFound';
import LoadingPage from './pages/LoadingPage';
import Confirmation from './pages/Confirmation';
import Swal from './components/Swal';
import TwoFA from './components/TwoFA';
import Interceptors from './components/Interceptors';
import ProtectedRoute from './ProtectedRoute';
import { getStatus } from '@features/status/statusSlice';
import { useTranslation } from 'react-i18next';
import './App.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { BrowserRouter as Router, Redirect, Route, Switch, } from 'react-router-dom';
import Legal from '@/components/Legal/Legal';
import PageTop from '@/components/Shared/PageTop';
import { loadRecaptureScript } from './helpers/recaptureUtils';
import { getMaintenance } from './features/maintenance/maintenanceSlice';
import MaintenanceMode from './components/Shared/MaintenanceMode';

const App = (): React.ReactElement => {
	const { ready } = useTranslation('translations', { useSuspense: false });
	const dispatch = useDispatch();
	const { loading } = useSelector(
		(state: RootState) => state.status
	);

	const { isFullyDown } = useSelector((state: RootState) => state.maintenance);

	useEffect(() => {
		dispatch(getStatus());
		// TODO switch between locales
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
	}, [dispatch]);

	useEffect(() => {
		loadRecaptureScript(() => {
			//console.log('Script loaded!');
		});
	}, []);

	useEffect(() => {
		dispatch(getMaintenance());
	}, [dispatch]);

	if (loading || !ready) return <LoadingPage />;

	return (
		<Router>
			<Interceptors />
			<Swal />
			<TwoFA />
			<PageTop>
				{isFullyDown ?
					<MaintenanceMode /> :
					<Switch>
						<Redirect from='/reset-password/:code' to='/auth/reset-password/:code' />
						<Route path="/auth">
							<Auth />
						</Route>
						<Route path='/maintenance'>
							<MaintenanceMode />
						</Route>
						<Route path='/legal'>
							<Legal />
						</Route>
						<ProtectedRoute path='/wallet' component={Dashboard} />
						<Route path='/confirm' component={Confirmation} />
						<Redirect exact from='/' to='/wallet' />
						<Route component={NotFound} />
					</Switch>
				}
			</PageTop>
		</Router >
	);
};

export default App;
