import { Agents } from '@/helpers/globalTypes';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Segment, Button, Header, Grid, Input, Divider, Icon, Breadcrumb, Dimmer, Loader } from 'semantic-ui-react';
import { getFaqs } from '../../features/faq/slice';
import { Faq, FaqList } from '../../features/faq/types';
import { showErrorNotification, showSuccessNotification } from '../../features/swal/slice';
import { postTicket } from '../../features/tickets/slice';
import LoadingPage from '../../pages/LoadingPage';
import { RootState } from '../../rootReducer';
import SupportTicket from '../Dashboard/Inbox/SupportTicket';
import './FAQ.css';
import FAQItem from './FAQItem';
import FAQList from './FAQList';



const FAQ = (): React.ReactElement => {

	const dispatch = useDispatch();
	const { list } = useSelector(
		(state: RootState) => state.faq
	);
	const { status, user } = useSelector(
		(state: RootState) => state.user
	);

	const [state, setState] = useState({ faq: null, type: 'header' });
	const [searchValue, setSearchValue] = useState('');
	const [showRequest, setShowRequest] = useState(false);
	const [faqLoading, setFaqLoading] = useState(true);
	const { i18n, t } = useTranslation('translations');


	useEffect(() => {
		const fetch = async () => {
			setFaqLoading(true);
			try {
				if (!user) return;
				await dispatch(getFaqs(i18n.language, user.country));
			} catch (e) {
				showErrorNotification(e);
			} finally {
				setFaqLoading(false);
			}
		};
		fetch();
	}, [i18n.language, user, dispatch]);

	const handleSearchChange = e => {
		setSearchValue(e.target.value);
		if (searchValue.length >= 2) {
			return search();
		}
	};

	const clearInput = () => {
		setSearchValue('');
		setState({ faq: null, type: 'header' });
	};

	useEffect(() => {
		if (searchValue.length >= 2) {
			return search();
		}
		if (!searchValue.length) {
			return setState({ faq: null, type: 'header' });
		}
	}, [searchValue]);

	const search = () => {
		if (searchValue === '') {
			return;
		}
		const faq: Faq[] = [];
		let initList = list;
		if (state.faq !== null) {
			initList = state.faq;
		}
		initList.filter(el => {
			const faqList: FaqList[] = [];
			el.faqs.filter(item => {
				if (item.question.toLowerCase().includes(searchValue.toLowerCase())) {
					faqList.push(item);
				}
			});
			if (faqList.length > 0) {
				faq.push({ name: el.name, faqs: faqList });
			}
		});
		if (faq.length > 0) {
			setState({ faq: faq, type: 'faq-list' });
		} else {
			setState({ faq: initList, type: 'not-found' });
		}
	};

	const submitRequest = () => {
		if(status.source === Agents.DTSCB || status.source === Agents.DTS || status.source === Agents.DTSUS ) {
			window.open('https://dtcirclesupport.com', '_blank');
		} else {
			setShowRequest(true);
		}
	};

	const backToSupportPage = () => {
		setShowRequest(false);
	};

	const onTicketSend = (value?) => {
		const get = async () => {
			try {
				if (value.description === undefined || value.subject === undefined || value.subtype === undefined) {
					return;
				}
				const formData = new FormData();
				formData.append('description', value.description);
				formData.append('subject', value.subject);
				formData.append('subType', value.subtype);


				if (value.files) {
					value.files.map(file => {
						formData.append('files', file);
					});
				}
				await postTicket(formData);
				showSuccessNotification(t('swal.SUPPORT_TICKET_SENT'));
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
		setShowRequest(false);
	};

	if (showRequest) { return <SupportTicket onBackClick={() => backToSupportPage()} onSend={onTicketSend} />; }

	if (!user) { return <LoadingPage />; }
	return (
		<div id="Faq">
			<Container className="faqcontainer" fluid>
				<Segment size="big" className="faqsegment" padded="very" >
					<Grid container centered >
						<Grid.Column computer="8" mobile="16">
							<Header className="faqheader" as="h1" content={t('faq.title')} />
							<Input className="searchInput" size="mini" fluid placeholder={t('faq.searchPlaceholder')} value={searchValue} action={{ icon: 'times', onClick: () => clearInput() }} onChange={handleSearchChange} />
						</Grid.Column>
					</Grid>
				</Segment>
				<Container>
					{(state.type === 'faq-list' || state.type === 'not-found') && (
						<Breadcrumb size="large">
							<Breadcrumb.Section className="backchevronitem" link onClick={() => setState({ faq: null, type: 'header' })}><Icon name="chevron left" className="backIcon" /> {t('faq.back')}</Breadcrumb.Section>
						</Breadcrumb>
					)}
				</Container>
				<Divider hidden clearing />
				<Container>
					<Dimmer active={faqLoading} inverted>
						<Loader />
					</Dimmer>
					{state.type === 'header' && (<Segment className="faqitemssegment" padded basic>
						<Header className="faqitemsheader" as="h1" content={t('faq.faq')} />
						<Grid stackable columns="2">
							{list.map(item => (
								<Grid.Column key={item.name}>
									<FAQItem faq={item} onClick={() => setState({ faq: [item], type: 'faq-list' })} />
								</Grid.Column>))}
						</Grid>
					</Segment>)}
					<Grid columns="1">
						{state.type === 'faq-list' && (
							<Grid.Row>
								<FAQList faq={state.faq} />
							</Grid.Row>)}
						{state.type === 'not-found' && (
							<Grid.Row centered>
								<h2>{t('faq.notFoundText')}</h2>
							</Grid.Row>)}
						<Divider hidden />
						<Grid.Row>
							<Grid.Column width="16" textAlign="center">
								<Header className="faqbtnheader" content={t('faq.haveMoreQuestions')} />
								<Button className="faqbutton" onClick={submitRequest} content={t('faq.submitRequest')} icon="plus circle" primary />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
				<Divider clearing hidden />
			</Container>
		</div>);
};

export default FAQ;
