import React, { useState } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { setAccount } from '@features/tabs/slice';
import { BankAccount } from '@features/account/types';
import NumberFormat from 'react-number-format';
import { Button } from 'semantic-ui-react';
import EditAccountLabel from './EditAccountLabel';
import { showErrorNotification } from '@/features/swal/slice';
import { getAccounts, updateLabel } from '@/features/account/slice';
import { getCards } from '@/features/card/slice';
import { useTranslation } from 'react-i18next';

interface HeaderTabProps {
	account: BankAccount
}

const HeaderTab = ({ account }: HeaderTabProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const onClick = (e) => {
		e.preventDefault();
		dispatch(setAccount(account));
	};
	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const [editAccountLabel, setEditAccountLabel] = useState<boolean>(false);

	const onCloseEditAccountLabelModal = async (label?: string) => {
		setEditAccountLabel(false);
		if (label) {
			try {
				await updateLabel(currentAccount.accountId, label);

				if (currentAccount.providerType !== 'CARD') {
					dispatch(getAccounts());
				} else {
					dispatch(getCards());
				}
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
	};

	return (<div>
		<EditAccountLabel
			onClose={onCloseEditAccountLabelModal}
			open={editAccountLabel}
			label={currentAccount.name}
		/>
		<div className={`item${currentAccount.accountId === account.accountId ? ' active' : ''}`} onClick={onClick}>
			<div>
				<div className="tab-name">{account.name}
					{currentAccount.accountId === account.accountId && account.providerType !== 'POOLING' && account.providerType !== 'TRUST' && <Button onClick={() => { setEditAccountLabel(true); }} floated='right' size="large" primary basic icon='edit' className="edit-account-label link-button" />}
				</div>
				{!user.hideValues &&
					<>
						<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${account.currency} `} value={account.availableBalance} /></div>
						<div className="tab-hold">{t('cards.balanceOnHold')}
							<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${account.currency} `} value={Math.abs(account.balanceOnHold)} /></div>
					</>}

				{user.hideValues && <>
					<div className="tab-value">{account.currency} *****</div>
					<div className="tab-hold">{t('cards.balanceOnHold')} {account.currency} *****</div>
				</>}
			</div>
		</div>
	</div>
	);
};

export default HeaderTab;
