import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import request from '@services/request';

const initialState = {
	languageCodes: [],
	loading: false
};

const settingsSlice = createSlice({
	name: 'settings',
	initialState,
	reducers: {
		setLanguageCodes(state, action: PayloadAction<string[]>) {
			state.languageCodes = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
	}
});

export const { setLanguageCodes, setLoading } = settingsSlice.actions;

export const getLanguages = (): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/settings/languages');
			const { data } = response;
			dispatch(setLanguageCodes(data));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export default settingsSlice.reducer;
