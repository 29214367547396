import { completeForm, getForm, postFile } from '@/features/kyb/slice';
import { Field, Group, Section } from '@/features/kyb/types';
import { showErrorNotification } from '@/features/swal/slice';
import { RootState } from '@/rootReducer';
import { Formik } from 'formik';
import { Checkbox, Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dimmer, Divider, Grid, Header, Icon, Loader, Step } from 'semantic-ui-react';
import * as Yup from 'yup';
import './AdvisionaryKybForm.css';
import { useDropzone } from 'react-dropzone';
import ComponentWithConfirmation from '../Shared/ComponentWithConfirmation';

interface AdvisionaryKybFormProps {
    onClose: () => void
}

interface Country {
    code: string,
    label: string,
}

const AdvisionaryKybForm = ({ onClose }: AdvisionaryKybFormProps) => {
    const { i18n, t } = useTranslation('translations');
    const [initialValues, setInitialValues] = useState<any>({});
    const [validationSchema, setValidationSchema] = useState({});
    const [currentValidationSchema, setCurrentValidationSchema] = useState({});
    const [activeStep, setActiveStep] = useState<number>(0);
    const [sortedSections, setSortedSections] = useState<Section[]>(null);
    const [uploadedFiles, setUploadedFiles] = useState<Array<{ name: string, files: Array<any> }>>([]);


    const dispatch = useDispatch();
    const { form, loading } = useSelector(
        (state: RootState) => state.kyb
    );

    useEffect(() => {
        const fetch = async () => {

            try {
                await dispatch(getForm('kybForm', i18n.language));
            } catch (e) {
                showErrorNotification(e);
            }
        };
        fetch();
    }, [i18n.language, dispatch]);

    useEffect(() => {
        if (!form) return;
        initForm();
        setSortedSections(getSortedSections(form.sections));
    }, [form]);

    useEffect(() => {
        if (!form || !validationSchema) return;
        setCurrentValidationSchema(Yup.object().shape(validationSchema[activeStep]));
    }, [form, activeStep, validationSchema]);

    const initForm = () => {
        const _initialValues = {};
        const _validationSchema = [];

        const _sections = [...form.sections];
        _sections.sort((a, b) => a.order - b.order);

        _sections.map(section => {
            const _groups = [...section.groups];
            _groups.sort((a, b) => a.order - b.order);
            const formId = section.formId;
            const _vs = {};
            _groups.map(group => {
                const _fields = [...group.fields];
                _fields.sort((a, b) => a.order - b.order);
                const sectionId = group.sectionId;
                _fields.map(field => {
                    const groupId = field.groupId;
                    if (field.type !== 'upload') {
                        _initialValues[`${formId}_${sectionId}_${groupId}_${field.fieldId}_${field.name}`] = '';
                        if (field.format === 'string') {
                            _vs[`${formId}_${sectionId}_${groupId}_${field.fieldId}_${field.name}`] = Yup.string();
                        } else if (field.format === 'number') {
                            _vs[`${formId}_${sectionId}_${groupId}_${field.fieldId}_${field.name}`] = Yup.number();
                        }

                        if (field.required) {
                            _vs[`${formId}_${sectionId}_${groupId}_${field.fieldId}_${field.name}`] =
                                _vs[`${formId}_${sectionId}_${groupId}_${field.fieldId}_${field.name}`].required(t('form.validator.required'));
                        }
                    }
                });
            });

            _validationSchema.push(_vs);
        });

        setInitialValues(_initialValues);
        setValidationSchema(_validationSchema);

    };

    const getSortedSections = (sections: Section[]) => {
        const arrayForSort = [...sections];
        return arrayForSort.sort((a, b) => a.order - b.order);
    };

    const submit = async (formData, formikProps) => {
        const { setSubmitting, setTouched } = formikProps;

        if (sortedSections.length - 1 !== activeStep) {
            setActiveStep(activeStep + 1);
            setTouched({});
        } else {

            try {
                const files = {};
                const promises = uploadedFiles.map(async value => {


                    const promises = value.files.map(async file => {
                        const payload = new FormData();
                        payload.append('file', file);

                        const response = await postFile(payload);
                        return response.key;
                    });

                    const keys = await Promise.all(promises);
                    keys.map(el => {
                        files[value.name] = el;
                    });


                });

                await Promise.all(promises);

                await completeForm(formData, files);
            } catch (err) {
                console.log(err);
                showErrorNotification(err);
            }
            setSubmitting(false);
        }
    };


    const onUpload = (name: string, files?: Array<any>) => {
        let _files = [...uploadedFiles];
        if (files) {
            _files = _files.filter(el => el.name !== name);
            _files.push({ name, files });
            setUploadedFiles(_files);
        } else {
            if (_files.length > 0) {
                _files = _files.filter(el => el.name !== name);
            }

            if (_files.length > 0) {
                setUploadedFiles(_files);
            } else {
                setUploadedFiles([]);
            }
        }
    };

    useEffect(() => {
        document.getElementById('kycModal').scrollIntoView();
    }, [activeStep]);

    return (
        <>
            <Dimmer active={loading} inverted>
                <Loader />
            </Dimmer>

            {form && sortedSections && <div style={{ margin: '1em' }}>
                <Step.Group fluid size='mini' >
                    {sortedSections.map((section, i) => (
                        <Step completed={i < activeStep ? true : false} key={section.name} active={activeStep === i} onClick={() => setActiveStep(i)} disabled={activeStep < i} >
                            <Step.Content >
                                <Step.Title>{i + 1}</Step.Title>
                                {/* <Step.Description>{section.translation[0].description}</Step.Description> */}
                            </Step.Content>
                        </Step>
                    ))}
                </Step.Group>

            </div>}
            {form && sortedSections && <Formik
                initialValues={initialValues}
                validationSchema={currentValidationSchema}
                onSubmit={submit}
                enableReinitialize
            >
                {({ errors, isSubmitting, values }) => (
                    <Form
                        size="large"
                        error={false}
                        style={{ margin: '1em' }}
                        id="kybForm"
                    >
                        <Header as="h2">{sortedSections[activeStep].translation[0].label}</Header>
                        {sortedSections[activeStep].translation[0].description && <div dangerouslySetInnerHTML={{ __html: sortedSections[activeStep].translation[0].description }} />}
                        <SectionJsx section={sortedSections[activeStep]} values={values} onUpload={onUpload} uploadedFiles={uploadedFiles} />

                        <div className='formBTNS'>
                            <SubmitButton
                                className="submitButton"
                                disabled={isSubmitting}
                                primary size="large" type="submit">
                                {sortedSections.length - 1 === activeStep ? 'Submit' : 'Next'}
                            </SubmitButton>
                            {activeStep !== 0 && <Button size="large" type='button' onClick={() => setActiveStep(activeStep - 1)}>Back</Button>}

                            <ComponentWithConfirmation onConfirm={onClose} confirmContent={'Are you sure you want to exit? Please be informed that you will lose all your progress.'}>
                                <Button size="large">{t('form.buttons.cancel')}</Button>
                            </ComponentWithConfirmation>
                        </div>

                    </Form>)}
            </Formik>}
        </>

    );
};



interface SectionProps {
    section: any;
    values: any;
    onUpload: (name: string, files?: Array<any>) => void;
    uploadedFiles: Array<{ name: string, files: Array<any> }>;
}

const SectionJsx = ({ section, values, onUpload, uploadedFiles }: SectionProps) => {
    const contriesList = getNames('en');
    const [countries] = useState<Array<Country>>(
        Object.keys(contriesList)
            .map($code => ({
                code: alpha2ToAlpha3($code),
                label: contriesList[$code],
            })));



    const getFormElement = (field: Field, group: Group, section: Section) => {
        let element = null;
        switch (field.type) {
            case 'input':
                element = <Input
                    fluid
                    name={`${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`}
                    label={field.translation[0].label}
                    errorPrompt
                />;
                break;
            case 'textArea':
                element = <TextArea
                    fluid
                    name={`${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`}
                    label={field.translation[0].label}
                    errorPrompt
                />;
                break;
            case 'date':
                element = <Input
                    fluid
                    name={`${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`}
                    label={field.translation[0].label}
                    type="date"
                    max="2999-12-31"
                    errorPrompt
                />;
                break;
            case 'countrySelect':
                element = <Select
                    fluid
                    name={`${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`}
                    label={field.translation[0].label}
                    errorPrompt
                    search
                    clearable
                    options={countries.map(c => ({ key: c.code, value: c.code, text: c.label }))}
                />;
                break;
            case 'selectWithOther':
                // eslint-disable-next-line no-case-declarations
                const name = `${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`;
                element = <>
                    <Select
                        fluid
                        name={name}
                        label={field.translation[0].label}
                        errorPrompt
                        search
                        clearable
                        options={field.selectOptions}
                    />
                    {values[name] === 'Other' && <Input
                        fluid
                        name={`${name}_other`}
                        label='Other:'
                        errorPrompt
                    />}
                </>;
                break;
            case 'selectWithYes':
                // eslint-disable-next-line no-case-declarations
                const fieldName = `${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`;
                element = <>
                    <Select
                        fluid
                        name={fieldName}
                        label={field.translation[0].label}
                        errorPrompt
                        search
                        clearable
                        options={field.selectOptions}
                    />
                    {values[fieldName] === 'Yes' && <Input
                        fluid
                        name={`${fieldName}_yes`}
                        label='If yes:'
                        errorPrompt
                    />}
                </>;
                break;
            case 'select':
                element = <Select
                    fluid
                    name={`${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`}
                    label={field.translation[0].label}
                    errorPrompt
                    search
                    clearable
                    options={field.selectOptions}
                />;
                break;

            case 'upload':
                element = <UploadFile
                    field={field}
                    onUpload={onUpload}
                    name={`${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`}
                    uploadedFiles={uploadedFiles}
                />;
                break;
            case 'checkbox':
                element = <Checkbox
                    fluid
                    name={`${section.formId}_${group.sectionId}_${field.groupId}_${field.fieldId}_${field.name}`}
                    label={field.translation[0].label}
                    errorPrompt
                />;
                break;
        }


        return element;
    };

    const getSortedGroups = (groups: Group[]) => {
        const arrayForSort = [...groups];
        return arrayForSort.sort((a, b) => a.order - b.order);
    };

    const getSortedFields = (fields: Field[]) => {
        const arrayForSort = [...fields];
        return arrayForSort.sort((a, b) => a.order - b.order);
    };

    return (
        <>
            {getSortedGroups(section.groups).map(group => (
                <div key={group.name}>
                    <Divider hidden />
                    {!group.hideLabel && <Header as="h3">{group.translation[0].label}</Header>}
                    {getSortedFields(group.fields).map((field, i) => (
                        <div key={field.name + i}>
                            {getFormElement(field, group, section)}
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

interface UploadFileProps {
    field: Field;
    onUpload: (name: string, files?: Array<any>) => void;
    name: string;
    uploadedFiles: Array<{ name: string, files: Array<any> }>;
}


const UploadFile = ({ field, onUpload, name, uploadedFiles }: UploadFileProps) => {
    const [_acceptedFiles, setAcceptedFiles] = useState<any>([]);

    const onDrop = useCallback((acceptedFiles) => {
        onUpload(name, acceptedFiles);
        setAcceptedFiles(acceptedFiles);
    }, [uploadedFiles]);

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop, accept: 'image/png,image/jpg,image/jpeg,application/pdf', multiple: false, maxSize: 10485760,
        onDropRejected(fileRejections) {
            fileRejections.map(file => {
                file.errors.map(err => {
                    if (err.code === 'file-too-large') {
                        showErrorNotification(err, 'File size too large');
                    }
                });
            });
        },
    });

    useEffect(() => {
        if (uploadedFiles.length === 0 || acceptedFiles.length > 0) return;
        const af = uploadedFiles.filter(el => el.name === name);
        if (af.length > 0) {
            setAcceptedFiles(af[0].files);
        }
    }, [uploadedFiles]);

    const removeAll = useCallback(() => {
        onUpload(name);
        acceptedFiles.splice(0, acceptedFiles.length);
        setAcceptedFiles([]);
    }, [onUpload]);

    return (
        <Grid className='uploadFile field' style={{ marginLeft: '0', marginRight: '0', marginBottom: '1em', marginTop: '1em' }}>
            <label style={{ paddingLeft: '0' }}>{field.translation[0].label}</label>
            <Grid.Row style={{ paddingTop: '0' }}>
                <div {...getRootProps()} id='file-container'>
                    <input {...getInputProps()} id='file-drop' />
                    <span><Icon name='upload' />Upload file</span>
                </div>
            </Grid.Row>
            {_acceptedFiles.length > 0 &&
                <Grid.Row>
                    <Icon size="large" name="file outline" />
                    {_acceptedFiles.map((file, i) => (<div key={i} className="fileName">{file.name}</div>))}
                    < Icon className="fileName" size="large" name='trash' link onClick={() => removeAll()} />
                </Grid.Row>}
        </Grid>
    );
};

export default AdvisionaryKybForm;
