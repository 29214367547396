import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, Menu } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Operations } from '@features/operations/types';
import { getOperationsByType } from '@features/operations/slice';
import { CardType } from '@features/card/types';
import NewCard from '../../Banking/CreateCard/Index';
import './CreateCard.css';
import { useTranslation } from 'react-i18next';
import { UserType } from '@/features/user/types';

interface Props {
	from: string
}

function Cards({ from }: Props): React.ReactElement {
	const [cardType, setCardType] = useState<CardType>(CardType.VIRTUAL);
	const [menuDisabled, setMenuDisabled] = useState<boolean>(false);
	const history = useHistory();
	const [, setLoading] = useState<boolean>(true);
	const [, setError] = useState<any>(null);
	const [options, setOptions] = useState<any>([]);
	const [filteredOptions, setFilteredOptions] = useState<any>([]);
	const [, setOperation] = useState<any>(null);
	const [, setResult] = useState<any>(null);
	const { t } = useTranslation('translations');
	const { list: cardList } = useSelector((state: RootState) => state.cards);
	
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const disableVirtualCard = user.type === UserType.BUSINESS || user.onBehalfOf !== null ? false : (cardList.filter(p => p.virtual).length) >= 2;
	const disablePhysicalCard =  user.type === UserType.BUSINESS || user.onBehalfOf !== null ? false : cardList.filter(p => p.virtual == false).length >= 1;

	const getCardType = (operation) => {
		return operation.processProperties.virtual ? CardType.VIRTUAL : CardType.PHYSICAL;
	};

	const { operations } = useSelector(
		(state: RootState) => state.operations
	);

	const selectType = (type: CardType) => {
		setCardType(type);
		setOperation(null);
		setResult(null);
	};

	useEffect(() => {
		if (operations.indexOf(Operations.CREATE_CARD) === -1) {
			history.push(from);
		}
	}, [operations, from, history]);

	useEffect(() => {
		const get = async () => {
			if (operations.indexOf(Operations.CREATE_CARD) !== -1) {
				try {
					const response = await getOperationsByType(Operations.CREATE_CARD);
					setOptions(response);
					const virtualPresent = response.filter((item) => CardType.VIRTUAL === getCardType(item)).length > 0;
					if (virtualPresent && !disableVirtualCard) {
						setCardType(CardType.VIRTUAL);
					} else {
						if (!disablePhysicalCard) {
							setCardType(CardType.PHYSICAL);
						}
					}
				} catch (e) {
					setError(e);
				} finally {
					setLoading(false);
				}
			}
		};
		get();
	}, [operations]);

	useEffect(() => {
		setFilteredOptions(options.filter((option) => cardType === getCardType(option)));
	}, [cardType, options]);

	const enabled = (type: CardType) => {
		return options.filter((option) => type === getCardType(option)).length > 0;
	};

	return (
		<div id="createaccount">
			<Grid stackable columns="equal">
				<Grid.Column width={5}>
					<Menu fluid vertical className="vertical-menu">
						{enabled(CardType.VIRTUAL) && !disableVirtualCard &&
							<Menu.Item
								disabled={menuDisabled}
								name={t('cards.newCard.virtual')}
								active={cardType === CardType.VIRTUAL}
								onClick={() => { selectType(CardType.VIRTUAL); }}
								icon="chevron right" />
						}
						{enabled(CardType.PHYSICAL) && !disablePhysicalCard &&
							<Menu.Item
								disabled={menuDisabled}
								name={t('cards.newCard.physical')}
								active={cardType === CardType.PHYSICAL}
								onClick={() => { selectType(CardType.PHYSICAL); }}
								icon="chevron right" />
						}
					</Menu>
				</Grid.Column>
				<Grid.Column width={11}>
					<NewCard type={cardType} options={filteredOptions} onStart={(disable) => { setMenuDisabled(disable); }} />
				</Grid.Column>
			</Grid>
		</div>
	);
}

export default Cards;
