import React from 'react';
import { Button, Icon, Grid, Header, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { CopyToClipboard } from 'react-copy-to-clipboard';


interface Props {
	estimatedBalance: number
}

const TokenWalletInfo = ({ estimatedBalance }: Props): React.ReactElement => {

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { tokens } = useSelector((state: RootState) => state.tokens);
	const { t } = useTranslation('translations');


	return (
		<Grid container>
			<Grid.Row columns={2}>
				<Grid.Column verticalAlign='bottom'>
					<Grid.Row><Header>{t('tokens.header.balance')}</Header></Grid.Row>
					<Grid.Row><div className='balance-value'>
						<NumberFormat displayType={'text'}
							decimalScale={2}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={`${tokens[0]?.currency ?? ''} `} value={estimatedBalance.toFixed(2)} /></div>
					</Grid.Row>
				</Grid.Column>
				<Grid.Column >
					<List relaxed="very" verticalAlign='middle' floated="right">
						<List.Item className='walletIDItem'>
							<List.Header>{t('tokens.header.cryptowalletid')+':'}</List.Header>
							<List.Content>
								{user.clientId}
								<CopyToClipboard id="wallet-clipboard-button" text={user.clientId}>
									<Button basic primary onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
									}}><Icon name='clone outline' /></Button>
								</CopyToClipboard>
							</List.Content>
						</List.Item>
					</List>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default TokenWalletInfo;
