import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Segment, Button, Header, Grid, Divider } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { SucessStatus } from '../../../services/icons';

interface Props {
	onRestart: () => void
}

const CardCreated = ({ onRestart }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	return (
		<Segment padded="very" className="submitSegment">
			<Grid>
				<Grid.Row centered>
					<Container text textAlign="center">
						<SucessStatus id="successCheckIcon" />
						<Header as="h2">{t('cards.created')}</Header>
						<Divider hidden />
						<Button primary as={Link} to="/wallet/home/cards" basic>{t('paymentsubmitted.button')}</Button>
						<Divider hidden />
						<Button onClick={onRestart} to="/wallet" basic>{t('cards.create')}</Button>
					</Container>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default CardCreated;
