import React from 'react';
import { Container, Segment, Loader, Grid } from 'semantic-ui-react';

const Load = () => {
	return (
		<Segment padded="very" className="submitSegment">
			<Grid>
				<Grid.Row centered>
					<Container text textAlign="center">
						<Loader inline size="large" />
					</Container>
				</Grid.Row>
			</Grid>
		</Segment>
	);
};

export default Load;
