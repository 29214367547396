export interface Tickets {
    id: number,
    title: string,
    status: string,
    createddate: Date,
    modifieddate: Date,
    incidenttype: TicketType,
    isread: boolean
}

export interface Ticket {
    id: number,
    userId: number,
    title: string,
    status: string,
    createdDate: Date,
    incidentType: TicketType,
    isRead: boolean,
    ticketMessages: TicketMessage[]
}

export interface TickerRequestMoney extends Ticket {
    incidentType: TicketType,
    requestMeta?: string
}

export interface TicketMessage {
    message: string,
    createdDate: string,
    author: string,
    authorYou: boolean,
    ticketAttachments: TicketAttachment[]
}

export interface TicketAttachment {
    name: string,
    size: string,
    mimeType: string,
    key: string
}

export enum TicketType {
    TRANSFER_REQUEST = 'TRANSFER_REQUEST',
    NOTIFICATION = 'NOTIFICATION',
    INCIDENT = 'INCIDENT',
    STOCK_ORDER = 'STOCK_ORDER' 
}


export interface PaginateParams {
    orderBy: string,
    sort: 'DESC' | 'ASC',
    skip: number,
    filter: string
}

export interface PaymentRequest {
    toUserId: string,
    reference: string,
    requestMeta: string
}

export interface StockOrder {
	subject: string,
	description: string,
}

export interface TicketRequest {
    subject: string,
    description: string,
    subType: string,
    files?: any
}

export interface ReplyRequest {
    ticketId: string,
    message: string,
    files?: any
}

export interface TicketsStore {
    loading: boolean,
    list: Array<Tickets>,
    ticket: Ticket,
    error: string,
    count: number,
    totalPage: number,
    pageSize: number,
    unreadCount: number,
    updateMessages: boolean,
    updateMessage: number
}
