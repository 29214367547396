import { RootState } from '@/rootReducer'; 
import {   useSelector } from 'react-redux';
import { Agents } from '../globalTypes';

const useAccounts = (currency?: string) => { 
	
	const { loading: accountsLoading, list, error: accountListError } = useSelector((state: RootState) => state.accounts);
	const { accounts } = useSelector((state: RootState) => state.trust);
	const { agent } = useSelector((state: RootState) => state.status); 

	let accountCurrencyList = currency ? list.filter(p => p.currency === currency) : list;

	if(agent === Agents.DTSUS) {
		accountCurrencyList = currency ? accounts.filter(p => p.currency === currency) : accounts;
	} 

	const accountList = agent !== Agents.DTSUS ?
		accountCurrencyList.filter(p => p.providerType === 'POOLING') :
		accountCurrencyList;

	const accountOptions = accountList.map(item => ({ value: item.accountId, key: item.accountId, text: `${item.name} (${item.currency} ${item.availableBalance})` }));

	return { accountsLoading, accountList, accountListError, accountOptions };
};

export default useAccounts;
