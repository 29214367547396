import React from 'react';
import { useTranslation } from 'react-i18next';
import { getMaintenance } from '@/features/maintenance/maintenanceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { MaintenanceStatus } from '@/features/maintenance/maintenanceType';
import { format } from 'date-fns';
import { Button, Header, Segment } from 'semantic-ui-react';


const MaintenanceCard = () => {
    const { t } = useTranslation('translations');
    const dispatch = useDispatch();
    const { list, isPlanned, isPartiallyDown } = useSelector((state: RootState) => state.maintenance);

    const active = list.filter(p => p.status === MaintenanceStatus.ACTIVE) || [];
    const planned = list.filter(p => p.status === MaintenanceStatus.UPCOMMING) || [];

    const handleReload = () => {
        dispatch(getMaintenance());
    };

    return (
        <Segment padded="very" textAlign='center'>
            {isPartiallyDown && <>
                <Header> {t('dashboard.maintenance.appMaintenance')}</Header>
                <div style={{ marginBottom: '20px', marginTop: '13px' }}>
                    {active.map(item =>
                        <div key={item.id} style={{ fontWeight: 400 }}>
                            {item.description}
                        </div>
                    )}
                </div>
            </>
            }
            {isPlanned && <>
                <Header > {t('dashboard.maintenance.planned')}</Header>
                <div style={{ marginBottom: '20px', marginTop: '13px' }}>
                    {planned.map(item =>
                        <div key={item.id} style={{ fontWeight: 400 }}>
                            {item.description}
                            from {format(new Date(item.startDate), 'dd MMM yyyy HH:mm')}
                            to {format(new Date(item.endDate), 'dd MMM yyyy HH:mm')}
                        </div>
                    )}
                </div>
            </>}
            <Button primary onClick={handleReload}>
                {t('form.buttons.reloadPage')}
            </Button>
        </Segment>
    );
};

export default MaintenanceCard;
