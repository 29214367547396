import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '@/rootReducer';
import { showInfo } from '@features/swal/slice';
import Navbar from '../../components/Navbar/Navbar';
import Business from '../../components/Registration/RegistrationBusiness';
import Individual from '../../components/Registration/RegistrationIndividual';
import {
	Grid,
	Header,
	Segment,
	Tab
} from 'semantic-ui-react';
import {
	useHistory,
	Route,
	useRouteMatch,
	NavLink, useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './Registration.css';
import Footer from '@/components/Footer/Footer';
import Freelancer from '@/components/Registration/RegistrationFreelancer';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Registration = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const dispatch = useDispatch();
	const { path } = useRouteMatch();
	const location = useLocation();
	const [panes, setPanes] = useState(null);
	const { regionRestricted, registrationDisabledReason, registrationEnabled, businessEnabled, businessRegistrationEnabled } = useSelector(
		(state: RootState) => state.status
	);
	const query = useQuery();

	useEffect(() => {
		if (!registrationEnabled) {
			showInfo({ title: 'swal.REGISTRATION_DISABLED_TITLE', text: 'swal.REGISTRATION_DISABLED_TEXT', textParams: { param_value: registrationDisabledReason, timer: null } });
			return history.push('/auth');
		}
		if (regionRestricted) {
			showInfo({ title: 'swal.REGION_RESTRICTED_TITLE', text: 'swal.REGION_RESTRICTED_TEXT' });
			return history.push('/auth');
		}
	}, [dispatch, history, regionRestricted, registrationDisabledReason, registrationEnabled]);


	const individualPane = {
		menuItem: {
			as: NavLink,
			content: t('registration.individual'),
			to: `${path}`,
			exact: true,
			key: 'individual'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}`} exact>
				<Tab.Pane>
					<Individual />
				</Tab.Pane>
			</Route>
		)
	};

	const businessPane = {
		menuItem: {
			as: NavLink,
			content: t('registration.business'),
			to: `${path}/business`,
			exact: true,
			key: 'business'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/business`} exact>
				<Tab.Pane>
					<Business />
				</Tab.Pane>
			</Route>
		)
	};

	const employeePane = {
		menuItem: {
			as: NavLink,
			content: t('registration.freelancer'),
			to: `${path}/freelancer`,
			exact: true,
			key: 'freelancer'
		},
		// eslint-disable-next-line react/display-name
		render: () => (
			<Route path={`${path}/freelancer`} exact>
				<Tab.Pane>
					<Freelancer/>
				</Tab.Pane>
			</Route>
		)
	};

	useEffect(() => {
		const activePanes = [];
		activePanes.push(individualPane);
		if (businessEnabled && businessRegistrationEnabled) {
			activePanes.push(businessPane);
		}
		const freelancerCode = query.get('code');
		if (freelancerCode) {
			activePanes.push(employeePane);
		}
		setPanes(activePanes);
	}, []);

	return (
		<div id="registration">
			<Navbar menu={false} />
			<Grid verticalAlign="middle" centered stackable divided="vertically">
				<Grid.Column id="registration-column">
					<Segment>
						<Segment basic>
							<Header className="headerStyle" as="h1">{t('registration.title')}
							</Header>
						</Segment>
						<Segment basic>
							<Tab defaultActiveIndex={location.pathname.includes('freelancer') ? 2 : 0} menu={{ secondary: true, pointing: true }} panes={panes} />
						</Segment>
					</Segment>
				</Grid.Column>
			</Grid>
			<Footer authorized={false} />
		</div>);
};

export default Registration;
