export interface Status {
	loggedIn: boolean,
	permissions: Array<string>,
	restricted: Array<string>,
	roles: Array<string>,
	source: string,
	sumsubUrl?: string,
	iSumsubFlow?: string
	bSumsubFlow?: string
	twoFARequired?: boolean,
	kycProvider?: 'sumsub' | 'onfido' | 'advisionary',
	kybProvider?: 'sumsub' | 'onfido' | 'advisionary',
	autoAccountCreation?: boolean,
	confirmPhoneRequired?: boolean,
	custodyAccountSettings?: {
		autoCreate?: boolean,
		name?: string,
		ccy?: string
	},
	amlVideo?: {
		amlVideoAvailable: boolean,
		amlVideoUrl: string
	} 
}

export interface MaintenanceMode {
	mode: boolean, reason: string
}

export enum KycStatus {
	CREATED = 'CREATED',
	PENDING = 'PENDING',
	VERIFIED = 'VERIFIED',
	IN_PROGRESS = 'IN_PROGRESS',
	REJECTED = 'REJECTED',
	PAID = 'PAID'
}

export class KycTier {
	tier: number;
	status: KycStatus;
	createdDate: Date;
	updatedDate: Date;
	rejectionReason: string;
	final: boolean;
	checkCount: number;
	expiredInMonth: boolean;
}

export class BankAccount {
	id?: number;
	refNumber?: string;
	status?: string;
	accountNumber?: string;
	iban?: string;
	bankName?: string;
	bankAddress?: string;
	sortCode?: string;
	swiftBicCode?: string;
	createdDate?: Date;
	updatedDate?: Date;
}

export enum UserType {
	INDIVIDUAL = 'INDIVIDUAL',
	BUSINESS = 'BUSINESS',
	EMPLOYEE = 'EMPLOYEE'
}

export interface User {
	id: number;
	email: string;
	kycVerificationStatus?: number;
	emailConfirmed?: boolean;
	emailConfirmationResent?: boolean;
	twoFactorAuthEnabled?: boolean;
	kycTier?: number;
	previousKycTier?: number;
	recoveryEmail?: string;
	recoveryEmailConfirmed?: boolean;
	recoveryNumber?: string;
	recoveryNumberConfirmed?: boolean;
	hideValues?: boolean;
	whitelistEnabled?: boolean;
	detained?: boolean;
	detainedReason?: string;
	disabled?: boolean;
	ipSettings?: string;
	createdDate?: string;
	previousLoginDate?: string;
	// User profile data
	avatar?: any;
	username?: string;
	firstName?: string;
	lastName?: string;
	birthDate?: string;
	address?: string;
	address2?: string;
	city?: string;
	country?: string;
	state?: string;
	postalCode?: string;
	mobileNumber?: string;
	kyc?: KycTier[];
	hasBusiness?: boolean;
	clientId?: string;
	type?: UserType;
	bankAccount?: BankAccount;
	companyName?: string;
	companyRegNumber?: string;
	companyAddress?: string;
	companyCountry?: string;
	userLevelId?: number;
	accountSettings?: AccountSettings;
	onBehalfOf: string;
	onBehalfOfCompany: string;
	poa?: PoaStatus,
	plan?: Plan,
	confirmedMobileNumber?: string,
	switchOptions: SwitchOption[],
	taxFormRequired?: {
		taxFormRequired: boolean,
		taxFormSigned: boolean
	} ,
	addressCountry?: string
}

export interface Plan {
	upgradeAllowed: boolean,
	currentUserLevel: {
		name: string,
		id: number
	},
	availablePlans: Array<AvailablePlan>
}

export interface AvailablePlan {
	name: string,
	ccy: string,
	defaultLevel: boolean,
	description: string,
	currencySymbol: string,
	price: number,
	priority: number,
	userLevelId: number,
}

export interface PoaStatus {
	required: boolean,
	status: string,
	rejectionReason: string
}

export interface AccountSettings {
	maxCount: number,
	unlimited: boolean
}

export interface UserError {
	error: string,
	error_param: string
}

export interface UserStore {
	user: User,
	status: Status,
	error: UserError,
	maintenanceMode: MaintenanceMode,
	twoFactorCode: string,
	backupKey: string,
	kycProvider?: 'sumsub' | 'onfido',
	kybProvider?: 'sumsub' | 'onfido',
	clientId?:string
}

export interface DeviceInfo {
	browser: string,
	platform: string,
	hash: string
}

export interface LoginBody {
	email: string,
	password: string,
	deviceInfo: DeviceInfo,
	recaptchaToken: string,
}

export interface ForgotBody {
	email: string,
	recaptchaToken: string,
}

export interface ResetBody {
	code: string,
	password: string,
	recaptchaToken: string,
}

export interface SignupBody {
	accountType: string,
	politicalExposed: boolean,
	usResident: boolean,
	email: string,
	country: string,
	password: string,
	firstName: string,
	lastName: string,
	birthDate: string,
	mobileNumber: string,
	invitationCode: string,
	businessAccount?: boolean,
	companyName?: string,
	companyAddress?: string,
	companyCountry?: string,
	companyRegNumber?: string,
	freelancerCode?: string,
	recaptchaToken: string
}

export interface Credentials {
	email: string,
	token: string
}

export interface SwitchBody {
	clientId: string, 
	deviceInfo: DeviceInfo
}


export interface SwitchOption {
	clientId: string,
	name: string
}

 
export interface CountryPhoneCodesType {
	countryCode: string, 
	countryCodeAlpha2: string, 
	countryName: string,
	phoneCode: string
}
