import React from 'react';
import { useTranslation } from 'react-i18next';
import { Segment, Grid, Message, Icon } from 'semantic-ui-react';
import CardImage from '../Shared/CardImage';
import cardImage from '@assets/card.png';

interface Props {
	image?: any
}

const Pending = ({ image }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<Grid.Row>
			<Grid stackable columns={2}>
				<Grid.Column only="computer tablet" className="cardOptionCLMN" computer={5} mobile={16}>
					<Segment disabled className="cardOptionSegment">
						<CardImage image={image ? image : cardImage}/>
					</Segment>
				</Grid.Column>
				<Grid.Column mobile={16} computer={11} className="horisontal-group">
					<Message primary attached='top'>
						<Icon name='info' />
						{t('cards.cardPending.message')}
					</Message>
				</Grid.Column>
			</Grid>
		</Grid.Row>
	);
};

export default Pending;
