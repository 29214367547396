
import React, { useState } from 'react';
import { Segment, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Token } from '@/features/tokens/types';
import TokenInformation from './TokenInformation';
import TokenTrade from './Trade/TokenTrade';
import TokenSend from './Send/TokenSend';

import './TokenInformation.css';
import TokenWalletInfo from './TokenWalletInfo';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

enum TokenActivity {
	SEND = 'SEND', TRADE = 'TRADE'
}

interface Props {
	tokenAction: boolean,
	setTokenAction: React.Dispatch<React.SetStateAction<boolean>>
}

const TokenList = ({ tokenAction, setTokenAction }: Props): React.ReactElement => {

	const { t } = useTranslation('translations');
	const { tokens,
		estimatedBalance
	} = useSelector((state: RootState) => state.tokens);

	const [token, setToken] = useState<Token>();
	const [action, setAction] = useState<TokenActivity>();

	const submitRequest = (token, action) => {
		if (token) {
			setAction(action);
			setToken(token);
			setTokenAction(true);
		}
	};

	const backToTokensFromTokenTrade = () => {
		setTokenAction(false);
	};

	if (tokenAction) {
		if (action === TokenActivity.TRADE) {
			return <TokenTrade
				onBackClick={() => backToTokensFromTokenTrade()}
				token={token}
				tokens={tokens}
			/>;
		}
		else if (action === TokenActivity.SEND) {
			return <TokenSend
				onBackClick={() => backToTokensFromTokenTrade()}
				token={token}
			/>;
		}
		else return <div />;
	} 

	return (
		<Segment className='token-segment-wrap'>
			<TokenWalletInfo estimatedBalance={estimatedBalance} />
			<Table attached='top' basic className='token-table-headers'>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={4}>{t('tokens.header.name')}</Table.HeaderCell>
						<Table.HeaderCell> {t('tokens.header.price')}</Table.HeaderCell>
						<Table.HeaderCell> {t('tokens.header.sellPrice')}</Table.HeaderCell>
						<Table.HeaderCell>{t('tokens.header.availableHoldings')}</Table.HeaderCell>
						<Table.HeaderCell>{t('tokens.header.holdingsOnHold')}</Table.HeaderCell>
						<Table.HeaderCell colSpan={4} width={1} textAlign='center'>{t('tokens.header.actions')}</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				{tokens?.map((token, idx) => {
					return (
						<TokenInformation key={idx} token={token}
							onTradeToken={() => submitRequest(token, TokenActivity.TRADE)}
							onSendToken={() => submitRequest(token, TokenActivity.SEND)} />
					);
				})}
			</Table>
		</Segment>
	);
};

export default TokenList;
