import React, { useState } from 'react';
import { Grid, Menu } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import '@/components/Dashboard/MyContacts.css';
import NewInternalContact from './NewInternalContact';
import NewSepaContact from './NewSepaContact';
import NewChapsContact from './NewChapsContact';

export enum ContactTypes {
	LOCAL = 'LOCAL',
	SEPA = 'SEPA_STEP2',
	UK_CHAPS = 'UK_CHAPS'
}

const CreateContact = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const [contactType, setContactType] = useState<string>(ContactTypes.LOCAL);

	return (
		<div id="createaccount">
			<Grid stackable columns="equal">
				<Grid.Column width={5}>
					<Menu fluid vertical className="vertical-menu">
						<Menu.Item
							name={t('contacts.create.internal')}
							active={contactType === ContactTypes.LOCAL}
							onClick={() => { setContactType(ContactTypes.LOCAL); }}
							icon="chevron right" />
						<Menu.Item
							name={t('contacts.create.sepa')}
							active={contactType === ContactTypes.SEPA}
							onClick={() => { setContactType(ContactTypes.SEPA); }}
							icon="chevron right" />
						<Menu.Item
							name={t('contacts.create.chaps')}
							active={contactType === ContactTypes.UK_CHAPS}
							onClick={() => { setContactType(ContactTypes.UK_CHAPS); }}
							icon="chevron right" />
					</Menu>
				</Grid.Column>
				<Grid.Column width={11}>
					{contactType === ContactTypes.LOCAL && <NewInternalContact />}
					{contactType === ContactTypes.SEPA && <NewSepaContact />}
					{contactType === ContactTypes.UK_CHAPS && <NewChapsContact />}
				</Grid.Column>
			</Grid>
		</div>
	);

};
export default CreateContact;
