import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { BankAccount } from '@features/account/types';
import { setAccount } from '@features/tabs/slice';
import HeaderTab from './HeaderTab';
import HeaderButton from './HeaderButton';
import { UserType } from '@/features/user/types';
import useOnScreen from '@/helpers/customHook/useOnScreen';

enum Direction {
	Right = 1,
	Left = -1,
	Center = 0
}

const tabWidth = 272;

interface HeaderProps {
	width: number;
	accounts: Array<BankAccount>,
	type: string
}

const AccountsListHeader = ({ width, accounts, type }: HeaderProps): ReactElement => {
	const dispatch = useDispatch();
	const [areChevronsRequired, setAreChevronsRequired] = useState<boolean>(false);
	const [scrollerWidth, setScrollerWidth] = useState<number>(0);
	const [currentScroll, setCurrentScroll] = useState<number>(0);
	const [tabs, setTabs] = useState<any>([]);
	const ref = useRef<HTMLDivElement>(null);

	const rightRef = useRef(null);
	const rightVisiable = useOnScreen(rightRef);

	const leftRef = useRef(null);
	const leftVisiable = useOnScreen(leftRef);

	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const slide = (direction: Direction) => {
		function getTabIndex(element: any) {
			const index = accounts.findIndex((a) => a.accountId === element.accountId);
			return index;
		}

		function getElementPosition(index: number) {
			if ((index * tabWidth) > currentScroll + width) {
				return Direction.Right;
			} else if (((index - 1) * tabWidth) < currentScroll) {
				return Direction.Left;
			}
			return Direction.Center;
		}
		const index = getTabIndex(currentAccount);
		const buttonElemen = getElementPosition(accounts.length + 1);

		if (direction !== Direction.Center) {
			const scroll = direction * (tabWidth - (index === 0 ? 100 : 0));
			setCurrentScroll(currentScroll + scroll);
			ref.current.scrollBy({
				left: scroll,
				behavior: 'smooth'
			});
		}
		if ((index + direction) < accounts.length - 1 && (index + direction) >= 0) {
			dispatch(setAccount(accounts[index + direction]));
		}
		if (index === accounts.length - 1 && (direction === Direction.Right && buttonElemen !== Direction.Center)) {
			const scroll = direction * (tabWidth);
			setCurrentScroll(currentScroll + scroll);
			ref.current.scrollBy({
				left: scroll,
				behavior: 'smooth'
			});
		}
	};

	useEffect(() => {
		if (type == 'account' && !user.accountSettings.unlimited) {
			if (user.type === UserType.BUSINESS || user.onBehalfOf !== null) {
				setTabs((accounts as any).concat(['button']));
			}
			else if (accounts.length < user.accountSettings.maxCount) {
				setTabs((accounts as any).concat(['button']));
			} else {
				setTabs((accounts as any));
			}
		} else if (type === 'trust') {
			setTabs(accounts as any);
		} else if (type === 'card') {
			//max 3 cards
			if (accounts.length < 3) {
				setTabs((accounts as any).concat(['button']));
			}
			else { setTabs(accounts as any); }
		} else {
			setTabs((accounts as any).concat(['button']));
		}
	}, [accounts]);

	useEffect(() => {
		setScrollerWidth(width + tabWidth);
	}, [width]);

	useEffect(() => {
		setAreChevronsRequired(width < tabs.length * tabWidth);
	}, [width, tabs]);

	useEffect(() => {
		function getTabIndex(element: any) {
			const index = accounts.findIndex((a) => a.accountId === element.accountId) + 1;
			return index;
		}

		function getElementPosition(index: number) {
			if ((index * tabWidth) > currentScroll + width) {
				return Direction.Right;
			} else if (((index - 1) * tabWidth) < currentScroll) {
				return Direction.Left;
			}
			return Direction.Center;
		}

		const position = getElementPosition(getTabIndex(currentAccount));

		if (position !== Direction.Center) {
			const scroll = tabWidth * position * 1.5;
			setCurrentScroll(currentScroll + scroll);
			ref.current.scrollBy({
				left: scroll,
				behavior: 'smooth'
			});
		}

	}, [currentAccount, width, scrollerWidth, accounts]);


	return (
		<div id="scrollable-tabs">
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				{areChevronsRequired && !!currentScroll && leftVisiable === false && (<div className="tabs-arrow left-arrow"><Button secondary onClick={() => { slide(Direction.Left); }} circular icon='arrow left' /></div>)}
				<div ref={ref}
					className="tabs ui attached tabular menu"
					style={{ width: scrollerWidth }}>
					<div ref={leftRef} />
					{tabs.map(tab => {
						if (tab === 'button') {
							if (user && user.type === 'EMPLOYEE') {
								return <></>;
							}
							return <HeaderButton type={type} key={'button'} />;
						}
						const account = tab as BankAccount;
						return <HeaderTab account={account} key={account.accountId} />;
					})}
					<div ref={rightRef} />
				</div>
				{areChevronsRequired && rightVisiable === false && (<div className="tabs-arrow right-arrow"><Button secondary onClick={() => { slide(Direction.Right); }} circular icon='arrow right' /></div>)}
			</div>
		</div>
	);
};

export default AccountsListHeader;
