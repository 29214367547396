import React, { useEffect, useState } from 'react';
import './Crypto.css';
import { Button, Confirm, Container, Dimmer, Dropdown, Grid, Input, Loader, Segment, Table } from 'semantic-ui-react';
import AddAddress from '@/components/Crypto/AddAddress';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { deleteContact, getContacts } from '@features/contacts/slice';
import { useTranslation } from 'react-i18next';

const AddressManagement = (): React.ReactElement => {
	const dispatch = useDispatch();
	const { t } = useTranslation(['translations']);
	const [managementOpen, setManagementOpen] = useState<boolean>(false);
	const [addresses, setAddresses] = useState([]);
	const [running, setRunning] = useState<boolean>(false);
	const [confirmDelete, setConfirmDelete] = useState<boolean | number>(false);
	const [address, setAddress]  = useState(null);
	const [search, setSearch] = useState<string>(null);
	const { loading, contacts } = useSelector(
		(state: RootState) => state.contacts
	);

	const onAddressManagement = async () => {
		if(managementOpen) {
			await dispatch(getContacts());
		}
		setManagementOpen(!managementOpen);
	};

	const remove = async (id: number) => {
		setConfirmDelete(false);
		setRunning(true);
		try {
			await dispatch(deleteContact(id));
			await dispatch(getContacts());
		}
		catch (e) {
			console.log(e);
		} finally {
			setRunning(false);
		}
	};

	const onEdit = (address) => {
		setAddress(address);
		setManagementOpen(true);
	};

	const onDelete = (id) => {
		setConfirmDelete(id);
	};

	const onSearch = e => {
		setSearch(e.target.value);
	};

	useEffect(() => { dispatch(getContacts()); }, []);

	useEffect(()=> {
		if(loading) return;
		setAddresses(contacts.filter(contact => contact.paymentType === 'CRYPTO'));
	}, [contacts]);

	useEffect(() => {
		if(!search || search === '') return;
		const filtered = contacts.filter( (address) => {

			const { name, ccy, account, bank } = address;

			if(name) {
				if(name.toUpperCase().startsWith(search.toUpperCase())) {
					return true;
				}
			}
			if(ccy) {
				if(ccy.toUpperCase().startsWith(search.toUpperCase())) {
					return true;
				}
			}
			if(account) {
				if(account.toUpperCase().startsWith(search.toUpperCase())) {
					return true;
				}
			}
			if(bank) {
				if(bank.toUpperCase().startsWith(search.toUpperCase())) {
					return true;
				}
			}
			return false;
		});
		setAddresses(filtered);
	}, [search]);

	if(managementOpen) return <AddAddress onBackClick={onAddressManagement} contact={address}/>;

	return (
		<>
			<div>
				<Container>
					<Confirm
						open={!!confirmDelete}
						onCancel={() => setConfirmDelete(false)}
						onConfirm={() => remove(confirmDelete as number)}
					>
						<Dimmer inverted active={running} ><Loader/></Dimmer>
					</Confirm>
					<Segment>
						<Grid columns='equal'>
							<Grid.Row>
								<Grid.Column><Button primary onClick={onAddressManagement}>{t('crypto.address.management.buttons.add')}</Button></Grid.Column>
								<Grid.Column floated='right' textAlign='right'>
									<Input
										placeholder={t('crypto.address.management.search')}
										icon={{ name: 'search', link: true}}
										value={search}
										onChange={onSearch}
									/>
								</Grid.Column>
							</Grid.Row>
						</Grid>
						<Table>
							<Table.Header>
								<Table.HeaderCell>{t('crypto.address.management.table.label')}</Table.HeaderCell>
								<Table.HeaderCell>{t('crypto.address.management.table.coin')}</Table.HeaderCell>
								<Table.HeaderCell>{t('crypto.address.management.table.address')}</Table.HeaderCell>
								<Table.HeaderCell>{t('crypto.address.management.table.network')}</Table.HeaderCell>
								<Table.HeaderCell></Table.HeaderCell>
							</Table.Header>
							<Table.Body>
								{addresses.map((address) => {
									return(
										<Table.Row key={address.beneficiaryAccountId}>
											<Table.Cell>{address.name}</Table.Cell>
											<Table.Cell>{address.ccy}</Table.Cell>
											<Table.Cell>{address.account}</Table.Cell>
											<Table.Cell>{address.bank}</Table.Cell>
											<Table.Cell>
												<Dropdown className='listDropdown' icon="ellipsis horizontal">
													<Dropdown.Menu direction="left" >
														<Dropdown.Item onClick={() => onEdit(address)} text={'Edit'}/>
														<Dropdown.Item onClick={() => onDelete(address.beneficiaryAccountId)} text={'Delete'}/>
													</Dropdown.Menu>
												</Dropdown>
											</Table.Cell>
										</Table.Row>
									);
								})}
							</Table.Body>
						</Table>
					</Segment>
				</Container>
			</div>
		</>
	);
};

export default AddressManagement;
