import React, { useState } from 'react';
import { Segment, Grid, Divider, Label, Button } from 'semantic-ui-react';
import { Formik } from 'formik';
import { Form, Radio, Input, Checkbox, SubmitButton } from 'formik-semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Yup from 'yup';
import '@/components/Business/MyEmployees.css';
import { useHistory } from 'react-router-dom';
import { generateEmployeeCode } from '@/features/employee/slice';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';

interface Props {
	from?: string,
}

enum Role {
	ACCOUNTANT = 'ACCOUNTANT',
	EMPLOYEE = 'EMPLOYEE',
	FREELANCER = 'FREELANCER'
}

const CreateEmployee = ({ from }: Props): React.ReactElement => {
	const [role, setRole] = useState<string>(Role.EMPLOYEE);
	const [step1, setStep1] = useState<boolean>(true);
	const [generatedCode, setGeneratedCode] = useState<string>(null);
	const history = useHistory();
	const { t } = useTranslation('translations');

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const close = () => {
		history.push(from);
	};

	const initialValues = {
		email: '',
		sendCode: false,
		role: role
	};

	const validationSchema = Yup.object({
		sendCode: Yup.boolean(),
		email: Yup.string().when('role', {
			is: 'FREELANCER',
			then: Yup.string().email(t('form.validator.email')).required(t('form.validator.required')),
		}).when('sendCode', {
			is: true,
			then: Yup.string().email(t('form.validator.email')).required(t('form.validator.required'))
		})
	});

	const confirm = async (formData) => {
		const { role, sendCode, email } = formData;
		const { onBehalfOfCompany } = user;

		const response = await getCode(role, onBehalfOfCompany ? onBehalfOfCompany : user.companyName, email, sendCode);

		if(role !== Role.FREELANCER) {
			setGeneratedCode(response.referenceCode);
			setStep1(false);
		} else {
			setStep1(false);
		}
	};

	const getCode = async (role, companyName, email?, sendCode?) => {
		if(sendCode || role === Role.FREELANCER) {
			return await generateEmployeeCode(role, companyName, email);
		} else {
			return  await generateEmployeeCode(role, companyName);
		}
	};

	return (
		<Grid id='createEmployee' container columns={1}>
			<Grid.Column width={8}>
				<Segment padded>
					{step1 && <Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={confirm}
					>{({ isSubmitting, errors }) => (
							<Form>
								<div className='employeeRadioDiv'>
									<Radio
										name='role'
										className='employeeRadio'
										label={t('business.createNewEmployee.employee')}
										value={Role.EMPLOYEE}
										checked={role === Role.EMPLOYEE}
										onChange={() => setRole(Role.EMPLOYEE)} />
									<Radio
										name='role'
										className='employeeRadio'
										label={t('business.createNewEmployee.accountant')}
										value={Role.ACCOUNTANT}
										checked={role === Role.ACCOUNTANT}
										onChange={() => setRole(Role.ACCOUNTANT)} />
									<Radio
										name='role'
										className='employeeRadio'
										label={t('business.createNewEmployee.freelancer')}
										value={Role.FREELANCER}
										checked={role === Role.FREELANCER}
										onChange={() => setRole(Role.FREELANCER)} />
								</div>

								{(role === Role.ACCOUNTANT || role === Role.EMPLOYEE) &&
								<>
									<Input
										fluid
										label={t('business.createNewEmployee.email')}
										name='email'
										maxLength={255}
										errorPrompt/>
									<div>
										<Checkbox name='sendCode' className='employeeCodeCheck' label={t('business.createNewEmployee.send')} />
									</div>
									<Divider section />
									<SubmitButton
										fluid
										className='createEmployeeBTN'
										disabled={isSubmitting || !isEmpty(errors)}
										primary size="large" type="submit">
										{t('business.createNewEmployee.generateCode')}
									</SubmitButton>
								</>
								}
								{(role === Role.FREELANCER) &&
								<>
									<Input
										fluid
										label={t('business.createNewEmployee.emailMandatory')}
										name='email'
										errorPrompt/>
									<Divider section />
									<SubmitButton
										fluid
										className='createEmployeeBTN'
										disabled={isSubmitting || !isEmpty(errors)}
										primary size="large" type="submit">
										{t('business.createNewEmployee.invite')}
									</SubmitButton>
								</>
								}


							</Form>)}
					</Formik>}

					{(!step1 && (role === Role.EMPLOYEE || role === Role.ACCOUNTANT)) && <div>
						<p>{t('business.createNewEmployee.codeText')}</p>
						<Divider hidden />
						<Grid.Row textAlign='center'><Label size='large'>{generatedCode} <CopyToClipboard id="clipboard-button" text={generatedCode}>
							<Button basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}} icon="clone outline" />
						</CopyToClipboard></Label></Grid.Row>
						<Divider hidden />
						<Button fluid primary size="large" onClick={close}>{t('form.buttons.close')}</Button>
					</div>
					}

					{(!step1 && (role === Role.FREELANCER)) && <div>
						<p>{t('business.createNewEmployee.confirmFreelancer')}</p>
						<Divider hidden />
						<Button fluid primary size="large" onClick={close}>{t('form.buttons.close')}</Button>
					</div>
					}
				</Segment>
			</Grid.Column>
		</Grid >
	);
};

export default CreateEmployee;


