import React, { useState, useEffect } from 'react';
import { Segment, Divider, Button, Header, List, Grid, Icon, Message } from 'semantic-ui-react';
import './SendMoneyConfirmation.css';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import useFee from '@/helpers/customHook/useFee';

type ConfirmationProps = {
	onConfirm: () => void,
	payload: any,
	process: any,
	submitting: boolean,
	files: any,
	availableAmount: number
}

const CospayUser = ({ payload, process, onConfirm, submitting, files, availableAmount }: ConfirmationProps): React.ReactElement => {
	const { t } = useTranslation('translations');

	const { fee } = useFee(payload.fromAccountId, process.proc, payload.amount);
	const insufficientFunds = availableAmount < (payload.amount + fee);
	
	return (
		<div id="transferBetweenAccountsConfirmation">
			<Grid>
				<Grid.Column width={16}>
					<Segment padded>
						<Header as="h3">{t('sendmoney.data.confirmheader')}</Header>
						<Divider hidden />
						<List relaxed>
							<List.Item>
								<List.Header>{t('sendmoney.data.transferamount')}</List.Header>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={payload.amount} />
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.currency')}</List.Header>
								{payload.currency}
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.transferfee')}</List.Header>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									value={fee}
									prefix={`${payload.currency} `} />
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.youpay')}</List.Header>
								<NumberFormat
									displayType={'text'}
									decimalScale={2}
									fixedDecimalScale={true}
									thousandsGroupStyle='thousand'
									thousandSeparator={true}
									prefix={`${payload.currency} `}
									value={parseFloat(payload.amount) + fee}
								/>
							</List.Item>
							{insufficientFunds && <div id='insufficientFunds'> {t('sendmoneyTranslation.data.insufficient')}</div>}
							<Divider />
							<List.Item>
								<List.Header>{t('sendmoney.data.cospayuser')}</List.Header>
								{payload.user.companyName !== null ? payload.user.companyName : `${payload.user.firstName} ${payload.user.lastName}`}
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.walletid')}</List.Header>
								{payload.walletId}
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.document')}</List.Header>
								{files.length > 0 && (
									<div id="uploadedFiles">
										{files.map((file) =>
											<div className="uploadedFile" key={file.name}>
												<Icon size="large" name="file outline" />
												<span className="fileName">{file.name}</span>
											</div>
										)}
									</div>
								)}
							</List.Item>
						</List>
						<Divider section />
						<Button loading={submitting} onClick={onConfirm} primary fluid disabled={insufficientFunds}>{t('sendmoney.activedata.confirmbutton')}</Button>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};

export default CospayUser;
