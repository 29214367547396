import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './Terms.css';

function TermsYESSY(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered>
					<h1>Terms and Conditions</h1>
				</Grid.Row>
			</Grid>
			<Segment className="policyContent">
				<div>

					<h1>INTRODUCTION</h1>
					<h2>Last Modified: January 14, 2023 </h2>
					<p>
						Yessy Pay is a brand owned by GET IN TOUCH s.r.l, (hereinafter &quot;GIT&quot;) a
						company located at Via Alpe Adria 6 33010 Tavagnacco (UD) Italy and
						registered under the &quot;Organismo Agenti e Mediatori&quot; as a Virtual Currency
						Operator under registration number 165635.
					</p>
					<p>
						<a href="https://portal.yessypay.com/auth">https://portal.yessypay.com/auth</a>
						- is operated by (i) XWARD Pay Inc. (hereinafter &quot;XWARD&quot;), a Canadian
						Incorporated Company with incorporation number BC1332850, located at Suite
						2500, Park Place 666, Burrard St., Vancouver, BC, V6C 2X8, Canada,
						regulated by FINTRAC as a Money Service Business (MSB), (ii) its partners
						from time to time and (iii) GIT for digital assets services.
					</p>
					<p>
						In consideration for permitting your access to our website and online
						services and other good and valuable consideration, you agree as follows:
					</p>
					<p>
						These terms and conditions (the &quot;Terms&quot;) form a legally binding agreement
						which govern your access to and use of our website (the &quot;Website&quot; or
						&quot;Platform&quot;) and our corresponding Android and iOS applications
						(collectively the &quot;Services&quot;).
					</p>
					<p>
						By using our Website and Services, you, the user (&quot;you&quot; or &quot;your&quot;),
						represent and warrant that (i) you are at least 18 years old; (ii) you are
						not a US citizen; (iii) you are not on any Canadian, European or US
						government list of prohibited, sanctioned or restricted persons; and (iv)
						you have read and understand these Terms and agree to be bound by them.
						Unless the above representations and warranties are true, you are not
						permitted to establish an account (an &quot;Account&quot;) with us or otherwise use
						our Services.
					</p>
					<p>
						If you are using the Website or Services on behalf of, or in the employ of,
						an organization (corporation, trust, partnership, etc.), you are agreeing
						to these Terms for that organization and representing and warranting that
						you have the authority to bind that organization to these Terms. In such a
						case, &quot;you&quot; and &quot;your&quot; will also refer to that organization and yourself
						individually. For greater clarity, both you as an individual and your
						organisation are legally bound by these Terms which form an agreement
						between you and us.
					</p>
					<h1>PRIVACY</h1>
					<p>
						We use your personal information in accordance with these Terms and our
						privacy policy, which is available online on our website as amended from
						time to time (the &quot;Privacy Policy&quot;). By using our Website and Services, you
						consent to such processing, and you represent that all information provided
						by you is accurate.
					</p>
					<h1>AMENDMENTS</h1>
					<p>
						As our Website and Services continue to change, we may, at any time, revise
						these Terms and our policies by updating this page or the page hosting the
						relevant policy. The date of the last version of these Terms is posted
						above. As you are bound by these Terms each time you visit our Website or
						use our Services, you are responsible for periodically reviewing the
						amendments to these Terms and you are deemed to have accepted and agreed to
						such amendments by accessing and using the Website and Services after such
						amendments have been posted. We may also undertake to send you notice of
						any changes to the Terms or policies via email and/or a notification on the
						Services.
					</p>
					<p>
						If you do not agree with the amendments, you must immediately stop
						accessing the Website and Services and terminate your Account, subject to
						the provision of these Terms.
					</p>
					<h1>ABOUT OUR ONLINE SERVICES</h1>
					<p>
						Our Services operate as an online platform for sending and receiving
						payments through e-wallets, using debit cards connected to users’ accounts
						and buying, selling, exchanging and storing eligible digital assets
						(collectively the &quot;Digital Assets&quot; or &quot;Eligible Digital Assets&quot; and each a
						&quot;Digital Asset&quot; or &quot;Eligible Digital Asset&quot;). We may add additional digital
						assets to our Services. We may also remove support for, or the ability to
						buy existing Digital Assets, at any time. You agree that these Terms shall
						apply to any additional digital assets added to our Services.
					</p>
					<p>
						Payment services are provided by Clear Junction, an Electronic Money
						Institution authorized by the FCA with firm reference number 900684 and
						registered address at 4th Floor Imperial House, 15 Kingsway, London, United
						Kingdom, WC2B 6UN.
					</p>
					<p>
						Debit cards are provided by Cospay Limited, an Electronic Money Institution
						authorized by the FCA with firm reference number 900088, registered address
						at One, Bartholomew Close, London, United Kingdom, EC1A 7BL and principal
						member of partner card schemes Mastercard.
					</p>
					<p>
						Digital Assets services are provided by GIT and its partners from time to
						time.
					</p>
					<h1>CUSTODY AND OWNERSHIP OF DIGITAL ASSETS</h1>
					<h2>Appointment of Custodian</h2>
					<p>
						When you purchase Digital Assets via our Services, they will be delivered
						and held under the custody of a third-party Digital Asset custodian
						(&quot;Custodian(s)&quot;). As of the last date of these Terms, you agree to hereby
						appoint GIT to act as the custodian of the Digital Assets in your Account,
						to be held by GIT for your benefit in accordance with these Terms.
					</p>
					<p>
						Please note that when you use our custody service to store eligible digital
						assets you will not receive any fee, rewards or financial compensation of
						any kind on eligible digital assets so stored.
					</p>
					<h2>Title &amp; Ownership</h2>
					<p>
						The Digital Assets held in your Account are fully-paid assets beneficially
						owned by you. GIT and its partners will record your Digital Assets in its
						books as separate and apart from anyone else’s Digital Assets and from GIT
						and its partners’ own property. GIT and its partners’ records will at all
						times provide for the separate identification of the Digital Assets owned
						by each client.
					</p>
					<p>
						GIT and its partners will not transfer, sell, loan or otherwise
						rehypothecate Eligible Digital Assets held in a Custody Wallet unless
						specifically instructed by you, except as required by valid court order,
						competent regulatory agency, government agency or applicable law. As title
						owner of assets, you bear all risk of loss. GIT and its partners shall have
						no liability for any Digital Asset price fluctuations or any or all loss of
						Digital Assets. Notwithstanding the foregoing, we may suspend your access
						to the Services, including the Custody Service and your access to a Custody
						Wallet, in the event of market disruptions or periods of volatility. We
						will use reasonable care and commercially reasonable efforts in connection
						with the Custody Service to store and secure Eligible Digital Assets in a
						Custody Wallet. You understand that your use of the Custody Service does
						not create a fiduciary relationship between you and the Custodian. We have
						no duty to inquire into, supervise, or determine the suitability of any
						transaction you initiate involving Eligible Digital Assets in a Custody
						Wallet. Eligible Digital Assets in a Custody Wallet may be commingled with
						the Eligible Digital Assets of other Users, and GIT is under no obligation
						to return the actual Eligible Digital Assets initially transferred by you
						to a Custody Wallet, but will return Eligible Digital Assets of the
						identical type reflected in your Account at the time you request such a
						return. We may provide information regarding the Eligible Digital Assets in
						a Custody Wallet to comply with any applicable law, regulation, rule, or
						request by law enforcement or government agencies.
					</p>
					<h1>DEPOSITING DIGITAL ASSETS, TRANSACTIONS AND THIRD-PARTY CUSTODY</h1>
					<p>
						You agree and acknowledge that you are the initiator and beneficiary of all
						transfers to your Account, to the extent that the transfer is within your
						control. You acknowledge that any incoming transfer not initiated or not
						requested to be initiated by you may not be released to your Account, until
						or unless you submit information on the person or entity who initiated the
						transfer. You also acknowledge that we reserve the right to conduct a
						manual review of your account and any incoming transfer(s), to ensure that
						the funds can be released to your account. This review period is usually
						completed within 48 to 72 hours, but it may take longer.
					</p>
					<p>
						By using our Services, you consent to the use of and our integration with
						each Digital Asset wallet provider and each Custodian to act as a custodian
						of your Digital Assets. You further consent to the Digital Assets which you
						purchase or deposit in connection with our Services being delivered to,
						deposited with and held by a Custodian on your behalf.
					</p>
					<p>
						We make no representations or warranties as to whether such deposits are
						insured by any Deposit Insurance Mechanism.
					</p>
					<p>
						In order to facilitate quicker withdrawals of Digital Assets, you agree
						that we may use Digital Assets which we own, to carry out withdrawal
						instructions provided from within your Account on the Services. Upon a
						withdrawal transaction being requested and confirmed from within your
						Account on the Services, you agree that we shall have your irrevocable
						consent to instruct Custodians to cause an equal number of Digital Assets
						to be withdrawn from their custody, which you owned, to set off amounts we
						send you, or which you trade as part of such withdrawal transaction.
					</p>
					<p>
						We do not, nor do we purport to have, ownership of Digital Assets which are
						delivered to each Custodian by us, on your behalf, in connection with the
						purchase or exchange of Digital Assets you make on our Services.
					</p>
					<p>
						To the extent you purchase or deposit Digital Assets which are held by a
						Custodian in connection with our Services, you agree to keep your Digital
						Assets free from any liens, encumbrances, charges, or claims, unless
						disclosed and agreed to by us and the applicable Custodian, in writing. You
						represent and warrant that any Digital Assets you purchase or exchange on
						our Services are being purchased and exchanged by you directly, and not for
						or on behalf of any third-party. You are not authorized to use your Account
						on our Services for or on behalf of any other person or entity.
					</p>
					<h1>WITHDRAWING DIGITAL ASSETS TO EXTERNAL DIGITAL WALLETS</h1>
					<p>
						While the Digital Assets you purchase or deposit are held by a Custodian,
						you may elect to withdraw your Digital Assets to an external digital wallet
						address. Given the nature of digital assets and blockchain technology, we
						are unable to confirm who owns or controls external digital wallets address
						you specify for withdrawals. For your protection, you agree to only
						withdraw Digital Assets from your Account on the Services to external
						digital wallet addresses which you own and control, and not to any
						third-party. You acknowledge that we are unable to retrieve withdrawn
						Digital Assets, or reverse Digital Asset transactions.
					</p>
					<p>
						As our Services may facilitate the withdrawal of your Digital Assets from a
						Custodian, you authorize us to send withdrawal instructions to each
						Custodian on your behalf. In doing so, we will act as your agent, with the
						nature and scope of such agency relationship being limited to sending
						Digital Asset withdrawal and transfer instructions to the Custodian in
						accordance with these Terms and any actions taken from within (or in
						connection with) your Account on our Services.
					</p>
					<p>
						To facilitate the withdrawal of your Digital Assets held by a Custodian,
						when a withdrawal request is made within your Account, we send instructions
						to a Custodian, on your behalf, to send your Digital Assets to the wallet
						address you provided us with. In our sole discretion and in rare
						circumstances, including where your Account is suspended or terminated, we
						may also rely on withdrawal instructions from the email address registered
						for your Account on our services. For that and other reasons, it is your
						responsibility to ensure you maintain sole and exclusive control of any
						email address used to register your Account on the Services. It is your
						responsibility to ensure that any incoming or outgoing Digital Assets are
						sent to the correct digital wallet addresses and specify, where applicable,
						the correct memo or reference code; whether displayed by us in your Account
						on the Services, or whether the address and memo code are for an external
						wallet address.
					</p>
					<p>
						Your failure to do so may result in the permanent loss of such digital
						assets, and you acknowledge that this is an inherent risk of using digital
						assets.
					</p>
					<p>
						Without limiting any other section of these Terms which restricts or limits
						our liability, you agree that we shall not be held liable or responsible
						for any damages or losses, howsoever caused (including by negligence or
						otherwise), which you suffer, arising from or in any way related to Digital
						Assets being sent to the wrong wallet address, or the use of a Custodian.
						If you elect to withdraw any Digital Assets using our Services, you accept
						full responsibility and liability for ensuring the accuracy of the external
						digital wallet address and the custody and control of, and access to, the
						external digital wallet address and related private keys.
					</p>
					<p>
						Processing times for withdrawing and making delivery of your Digital Assets
						via a Custodian may vary based upon the network times of the applicable
						Digital Asset and each Custodian’s processing times.
					</p>
					<h1>ESTABLISHING AN ACCOUNT</h1>
					<p>
						To establish an Account you will be required to provide certain personal
						information as set out on the Services, in our Privacy Policy and these
						Terms, including your name, address, phone number, identity documents,
						identity numbers, and an email address.
					</p>
					<p>
						You also agree that all incoming transfers to your Account will be
						initiated by you, or caused or requested to be initiated by you.
					</p>
					<p>
						If you are establishing an Account in the name of an organization or
						business, such as a corporation, you may be required to provide a list of
						documents as per our request.
					</p>
					<p>
						As part of our measures to confirm your identity (and the identity of each
						beneficial owner), we will use third party identification service providers
						such as Onfido. To learn more about Onfido’s privacy practices, please
						visit <a href="https://onfido.com/privacy/">https://onfido.com/privacy/</a>.
					</p>
					<p>
						We may send copies of your identity documents and personal information to
						such third-party providers for the purpose of confirming your identity and
						preventing fraud. In some instances, we may need to speak with you via
						phone to validate your Account information and your intent to register an
						Account with us. You agree that we may use the phone number registered with
						your Account for the above purposes. On such phone calls you must not
						reveal your Account passwords or passphrases for our or any other services.
					</p>
					<p>
						Phone calls may be recorded for our own internal quality control and
						training purposes. To assist us with Account and information validations we
						may also use third-party service providers. Such service providers may have
						access to your personal information, including for example, your identity
						documents, photos you upload to the Service and contact information. Third
						party service providers may also call you, on our behalf, to assist us with
						your identity and Account verification.
					</p>
					<p>
						Upon applying for an Account on behalf of a business or other organization,
						you represent and warrant that:
					</p>
					<ul>
						<li>
							you are fully authorized to bind the business or other organization;
						</li>
						<li>
							the business or organization is duly authorized to establish the
							Account with us and enter these Terms;
						</li>
						<li>
							you will fully disclose the names and addresses of all beneficial
							owners of the business, organization and Account who have a beneficial
							ownership or control interest (or other similar beneficial interest) of
							20% or more;
						</li>
						<li>
							The organization is not based in the U.S.
						</li>
						<li>
							none of the beneficial owners of the organization are an individual,
							entity, organization, government or country upon which the Government
							of Canada, the European Union or the United States of America has
							imposed sanctions, including but not limited to those listed under the
							legislation and related regulations referenced online at
							<a href="https://www.international.gc.ca/world-monde/international_relations-relations_internationales/sanctions/current-actuelles.aspx?lang=eng">https://www.international.gc.ca</a>.
						</li>
					</ul>
					<p>
						Upon establishing an Account, we grant you a non-transferable,
						non-exclusive license to access the Services in accordance with these
						Terms. However, we reserve the right to revoke that license and your access
						to our Website and Services without cause or justification at any time and
						without advanced notice.
					</p>
					<p>
						If you establish an Account with us, access to your Account is not
						transferable and is only intended for you, the individual who established
						the Account (whether for yourself, or for and on behalf of your
						organization). As a result, you are not permitted to change the name
						associated with your Account, nor are you permitted to purchase, deposit or
						store Digital Assets for or on behalf of anyone else. If you wish to change
						your address registered with us, we will ask you to provide information to
						verify your address change including such supporting documents as specified
						on the Website, or in our correspondence with you.
					</p>
					<p>
						You are only permitted to hold one Account at a time on our Services. You
						agree not to establish more than one Account at any time and that we may
						remove Accounts we deem to be duplicates. In making such a determination,
						we may consider any personal information you provide to us, or which we
						have collected pursuant to our Privacy Policy. You agree to promptly
						provide us with such information and documents we may request from
						time-to-time, regarding your use of our Services. This may include the
						names of individuals you are sending or receiving Digital Assets to or from
						in connection with our Services, or as may otherwise be required for our
						compliance with applicable laws, rules and regulations.
					</p>
					<h1>ACCEPTABLE USE OF YOUR ACCOUNT</h1>
					<p>
						You agree that:
					</p>
					<ul>
						<li>
							You will not use the Website or Services in any way that intentionally
							or unintentionally violates any applicable local, state, provincial,
							national or international law, rule or regulation;
						</li>
						<li>
							Under no circumstances shall you buy, withdraw or transfer any Digital
							Assets for any illegal purpose, or in the furtherance of a crime;
						</li>
						<li>
							You will not send or receive Digital Assets to or from any individual,
							entity or country upon which the Government of Canada, the United
							States of America or the European Union has imposed sanctions,
							including but not limited to those listed under the legislation and
							related regulations referenced online at
							<a href="https://www.international.gc.ca/world-monde/international_relations-relations_internationales/sanctions/current-actuelles.aspx?lang=eng">https://www.international.gc.ca</a>;
						</li>
						<li>
							You will not impersonate any person or entity or use false identities,
							documents or information on the Website, Services or in your
							interactions with us;
						</li>
						<li>
							You will not communicate with our staff or contractors in a way that is
							defamatory, harmful, hateful, harassing, bullying, threatening,
							racially or ethnically offensive or abusive;
						</li>
						<li>
							You will not translate, reverse engineer, decompile, disassemble,
							modify or create derivative works based on the Website or Services, in
							whole or in part;
						</li>
						<li>
							You will not circumvent, disable, violate or otherwise interfere with
							any security related feature of the Website or Services; and
						</li>
						<li>
							You will not use your Account for your own commercial purposes by
							sublicensing any rights granted by the Terms or, in any way, sharing
							the benefit of your Account with others.
						</li>
					</ul>
					<p>
						If we reasonably believe you are using our Services for any illegal
						purpose, including but not limited to, in connection with facilitating
						transactions involving persons or entities who are under economic or other
						sanctions imposed by the Canadian or other governmental or international
						authority, we may terminate your Account, charge a termination fee, and
						require that you liquidate your Digital Assets and withdraw any balance to
						the bank Account owned or controlled by you, or take such other reasonable
						measures to return your funds, such as, paying the funds into a court where
						you may claim them.
					</p>
					<h1>FUNDING AND WITHDRAWING FIAT FUNDS FROM YOUR ACCOUNT</h1>
					<p>
						To buy Digital Assets, you will be required to fund your Account with Fiat
						currencies. All funds are held in a bank Account with an independent
						Financial Institution. Absent any fraud or willful misconduct solely
						attributable to us, you agree that you bear all risk related to the banks
						with which your money is held, and the loss or theft thereof. We make no
						representations or warranties as to whether such deposits are insured by
						any Deposit Insurance Mechanism.
					</p>
					<p>
						We may offer various methods for funding your Account, which may change and
						be updated from time-to-time. Currently, we offer funding by wire
						transfers. We make no representations or warranties that deposit methods
						currently offered will continue to be available to you.
					</p>
					<p>
						You agree that you are not permitted to fund your Account with funds or
						Digital Assets which you hold for or on behalf of any third-party. Nor are
						you permitted to fund your Account with Digital Assets or funds borrowed
						from a third-party. We may, at any time, ask you to (i) disclose the source
						of fiat funds being deposited in connection with the Services; and (ii)
						disclose the source and duly respond to any questions we pose concerning
						the source of any Digital Assets you wish to, or have deposited or
						withdrawn in connection with our Services. You agree that we may, in our
						sole discretion, deactivate your Account and no longer permit the use of
						our Services based on your responses (or lack thereof) and our policies,
						including for example, our Anti-Money Laundering Policy. We further reserve
						the right to not facilitate the sale or transfer of your Digital Assets via
						our Services without providing you any reason, justification or cause.
					</p>
					<p>
						You are only permitted to use our Services, fund your Account and withdraw
						funds from your Account with a Bank account bearing the same legal name and
						address used to register your Account on the Services. You are not
						permitted to use a joint Bank account with our Services or in connection
						with any deposits or withdrawals. You agree that we may restrict funding
						methods and funding amounts for your Account.
					</p>
					<h1>TAXES</h1>
					<p>
						You acknowledge that buying and selling Digital Assets may give rise to
						taxable gains and/or taxable losses, which you are likely required to
						report for tax purposes. You are encouraged to obtain professional tax
						advice in connection with your tax filing obligations arising from the
						buying and selling of Digital Assets.
					</p>
					<h1>ACCEPTANCE OF RISK AND DISCLAIMERS</h1>
					<p>
						Everything on our website and provided as part of our service offering is
						provided &quot;as is&quot; and &quot;as available&quot; without warranty of any kind including
						all implied warranties and conditions of merchantability and fitness for a
						particular purpose. we hereby disclaim all warranties and conditions of any
						kind, whether express, implied or statutory.
					</p>
					<p>
						Before buying or selling digital assets you should obtain legal, financial
						and tax advice.
					</p>
					<p>
						You agree that, while we strive to have error free and uninterrupted
						service, we do not guarantee the absence of interruptions nor shall you
						hold us liable for any damage such interruptions may cause. outages and
						downtime may occur for various reasons.
					</p>
					<p>
						You acknowledge that buying and selling digital assets is risky. you should
						only purchase digital assets if you can afford to lose the entire amount of
						your purchase. you acknowledge there is a risk that the value of the
						digital assets decreases to zero.
					</p>
					<p>
						We make no representations or warranties as to whether each custodian has
						insurance, or the types of insurance or coverage limits they may have in
						respect of your digital assets. Please contact the custodians directly with
						any questions.
					</p>
					<p>
						You further acknowledge that there is a risk of malicious cyberattacks
						against our website and services, your account, your personal computing
						devices, smartphone and the third-party digital asset custodians which may
						result in the loss or theft of your funds, information and/or digital
						assets. If you do not wish to have your digital assets stored by the
						third-party custodian as referenced above, you should withdraw your digital
						assets to an external wallet which you control. you accept all risks
						associated with such withdrawals, including but not limited to the risks
						associated with losing your private keys and sending funds to an incorrect
						or invalid wallet address, or memo code, where they become forever
						inaccessible.
					</p>
					<p>
						While these terms require that you only withdraw digital assets to external
						digital wallets you own and control, you accept all risks arising from
						transferring your digital assets to any third-party, including the risk
						that such third-party misappropriates your digital asset. You are
						responsible for conducting your own due diligence in respect of any
						third-parties you elect to send digital assets to. You further accept all
						risks associated with sending digital assets to fraudulent or otherwise
						illegal schemes.
					</p>
					<p>
						Despite making various digital assets available for sale on our services,
						we make no representations or warranties in respect of such digital assets,
						or that they have, or will have any specific utility, functionality or
						features.
					</p>
					<p>
						You acknowledge that we are not able to confirm the identity of the owners
						of any external digital wallet addresses for any digital asset. You accept
						all risks with any transfer, or attempted transfer, of digital assets to
						any third-party. As digital asset transactions on the blockchain cannot be
						reversed by us, it is your responsibility to ensure the accuracy of any
						wallet addresses you specify for withdrawals.
					</p>
					<p>
						You acknowledge that the underlying blockchain technology of the digital
						assets may undergo a divergence, resulting in the formation of new crypto
						currencies (a &quot;fork&quot;). A fork may impact the value, functionality and other
						characteristics of the digital assets. We make no representation or
						warranty as to whether we will support a fork arising from any of the
						digital assets. You agree that we shall not be responsible or liable for
						claiming, issuing, storing or holding any crypto currencies resulting from
						a fork for or on your behalf.
					</p>
					<p>
						You acknowledge and agree that we may elect not to support a fork arising
						from any of the digital assets and that in such circumstances, you may not
						be able to claim any new crypto currencies resulting from a fork. You
						should not store your digital assets with us if you wish to ensure
						ownership of any new digital assets arising from a fork.
					</p>
					<p>
						You agree and acknowledge that we may suspend access to your account, or
						account functions, such as buying, selling or transferring digital assets
						or fiat funds for any reason, including for example, scheduled maintenance,
						your failure to comply with these terms, security or fraud concerns, for
						regulatory reasons or in the event of a fork.
					</p>
					<p>
						You agree that you are responsible for, and shall indemnify us in relation
						to, any and all chargebacks charged to us by any bank, financial
						institution or other payment provider. You acknowledge that these terms
						permit us, without any liability to you, to liquidate any digital assets in
						your account to satisfy any payments owing to us, or any negative balance
						in your account.
					</p>
					<p>
						We make no representations or warranties as to the ongoing availability of
						our third-party service providers to process deposits or withdrawals to and
						from your account. In the event third parties we rely on no longer offer or
						facilitate deposit or withdrawal services, you may not be able to buy or
						transfer digital assets or fund your account. In such circumstances, you
						will likely only be able to transfer your digital assets to another digital
						asset wallet address not associated with our services or a custodian.
					</p>
					<p>
						We reserve the right to provide your personal information uploaded or
						collected by our website or services, or otherwise provided to us, to third
						parties if required by law, and to cooperate with law enforcement
						authorities in the investigation of any criminal or civil matter. Such
						personal information may include, but is not limited to, the information
						described in these terms and our privacy policy, including your name, bank
						account information and transaction history.
					</p>
					<p>
						If we are required by law to make any disclosure of your personal
						information, we may (but are not obligated to) provide you with written
						notice (to the extent permitted by law) prior to such disclosure so that
						you may take appropriate action.
					</p>
					<p>
						We also operate the website and services in conjunction with our
						independent contractors. Our independent contractors may have access to
						your personal information in providing services to us, or providing you
						with access to the website and services. We may use a variety of service
						providers, employees and servers in locations both inside and outside of
						Canada, which may make your personal information the subject of foreign
						laws and foreign legal proceedings.
					</p>
					<p>
						To the fullest extent permitted by law, without limiting the generality of
						this section, or any other section limiting our liability, you agree that
						we shall not be responsible for the acts or omissions, including the
						negligent acts or omissions, of our independent contractors or third-party
						service providers.
					</p>
					<h1>LIMITATION OF OUR LIABILITY</h1>
					<p>
						You agree that, to the fullest extent permitted by law, in no event will
						we, our affiliates, officers, directors, shareholders, employees,
						contractors, agents or suppliers be liable, howsoever caused (including by
						negligence or otherwise), for any direct, indirect, special, incidental,
						punitive, exemplary or consequential damages you suffer, whether or not we
						have been warned of the possibility of such damages and whether those
						damages were foreseeable or not.
					</p>
					<p>
						If you are dissatisfied with the website or services, or do not agree with
						any part of these terms, or have any other dispute or claim with or against
						us, our affiliates, officers, directors, shareholders, employees,
						contractors, agents, suppliers or licensors, then your sole and exclusive
						remedy is to withdraw your digital assets and discontinue accessing and
						using the services.
					</p>
					<p>
						In addition to and separate from your agreement to not hold the above
						entities and persons liable for any damages, in the event a court or
						arbitrator of competent jurisdiction declines to uphold said clause, you
						agree that:
					</p>
					<ul>
						<li>
							In no circumstances shall the aggregate liability for any and all
							claims relating to or in any way arising from the use of our website or
							services, or in any way related to these terms, be more than the
							amounts paid by you to us as fees, if any and if implemented, or which
							we have earned as a result of your account.
						</li>
						<li>
							In no circumstances shall we be liable or responsible for any loss of
							profits or loss of expected revenue or gains.
						</li>
						<li>
							In no circumstances shall we be liable or responsible for any damages
							you suffer arising from the loss of an opportunity, including for
							example, the loss of an opportunity to buy, sell or profit from a
							digital asset.
						</li>
						<li>
							In no circumstances shall we be liable or responsible for any loss or
							damage whatsoever which does not arise directly as a result of our
							breach of this agreement (whether or not you are able to prove such
							loss or damage).
						</li>
					</ul>
					<p>
						You agree and acknowledge that we would not enter into this agreement or
						grant access to the website or services without the above restrictions and
						limitations on our liability.
					</p>
					<h1>INDEMNIFICATION AND HOLD HARMLESS</h1>
					<p>
						You agree to indemnify us, our affiliates, employees, shareholders,
						directors, agents, representatives and contractors, and to defend and hold
						each of them harmless, from any and all claims and liabilities (including
						reasonable legal fees) which may arise from, (i) your violation of these
						terms, or any policy incorporated by reference; (ii) your violation of any
						third party right; (iii) any breach of a representation or warranty made by
						you to us, either in these terms, privacy policy or otherwise; (iv) any
						breach by you of applicable laws, rules or regulations; or (v) any claim
						for damages brought against us by the custodian or their affiliates, or any
						bank or financial institution, related to or arising from your account or
						your use of the services.
					</p>
					<p>
						Without limiting the above limitations on our liability in any way, you
						agree that we shall not be liable or responsible to you for the loss or
						theft of any digital assets caused or contributed to by a custodian,
						whether such loss or theft was caused, in whole or in part, by their
						negligence, fraudulent misconduct or otherwise.
					</p>
					<h1>NON-SOLICITATION</h1>
					<p>
						If you are domiciled outside Canada (for digital assets services) or the
						United Kingdom (for services related to electronic money), you confirm that
						you are visiting this website on your own initiative, without any
						solicitation from XWARD. You also confirm that it is your full and sole
						responsibility to find out whether the legislation applicable to you, such
						as the legislation of your domicile, prohibits you from using the services
						offered on this website.
					</p>
					<h1>DISPUTES, GOVERNING LAW AND JURISDICTION</h1>
					<p>
						These Terms, all documents incorporated by reference and your relationship
						with us shall be governed by, construed and enforced in accordance with the
						laws of the Province of British Columbia, Canada, and any Canadian federal
						laws applicable therein.
					</p>
					<p>
						In respect of a claim by either you or us arising out of or in any way
						related to these Terms, all documents incorporated by reference and your
						relationship with us, you agree to irrevocably and unconditionally submit
						to the exclusive jurisdiction of the courts and tribunals of British
						Columbia, Canada (including the Federal courts and tribunals as applicable)
						for the settlement thereof.
					</p>
					<h1>SEVERABILITY</h1>
					<p>
						If any provision of these Terms is found to be unlawful, void, or for any
						reason unenforceable, then that provision shall be deemed severable from
						this agreement and shall not affect the validity and enforceability of any
						remaining provisions.
					</p>
					<p>
						Where a provision in these Terms is found to be unlawful, void or for any
						reason unenforceable, a lawful or enforceable term which best reflects the
						intention of the provision, as originally drafted, shall substitute at the
						discretion of the court.
					</p>
					<h2>No Interpretation Against Us</h2>
					<p>
						If an ambiguity or question of intent arises with respect to any provision
						of these Terms, the Terms shall be construed as if drafted jointly by the
						parties and no presumption or burden of proof will arise favoring or
						disfavoring either party by virtue of authorship of any of the provisions
						of these Terms.
					</p>
					<h2>Waiver of Class Proceedings and Trial by Jury</h2>
					<p>
						To the extent permitted by law, you hereby waive your right to participate
						in any class action lawsuits against us, our contractors, employees,
						shareholders, successors, assigns and directors.
					</p>
					<p>
						To the extent permitted by law, you further waive any right to a trial by
						jury, should such a right exist, in relation to any legal dispute connected
						to or in any way arising out of these Terms.
					</p>
					<h1>RISKS YOU ASSUME</h1>
					<p>
						There are many risks associated with Digital Currency, some of which are
						independent of the Services (and that are present in other Digital Currency
						business models), for which we will not be responsible and will not have
						any liability for, including the following Risks:
					</p>
					<ol type="i">
						<li>
							Blockchain transactions are final, irreversible and irrecuperable. You
							must be careful not to transfer Digital Currency from your personal wallet
							to other external wallets, untrustworthy businesses or to people you do not
							personally know.
						</li>

						<li>
							You are responsible for ensuring that any Digital Currency or money
							transfers that you conduct are with a trustworthy person or business. Any
							Digital Currency transfer that you perform is final, irreversible and
							irrecuperable. You are responsible for ensuring that any Digital Currency
							or money transfers that you conduct are with the correct person or
							business.
						</li>
						<li>
							You are responsible for securing your device, email account, phone
							number, and other methods of communication that you use with our Services.
						</li>
						<li>
							There are some Digital Currencies that have address formats that
							overlap (e.g. Bitcoin and certain Bitcoin forks). If you send a different
							Digital Currency to a wallet address not intended for that Digital
							Currency, then it will result in a permanent loss because the other Digital
							Currency is not supported. This rule also applies to different blockchain
							architecture including but not limited to, sending on the BEP blockchain or
							Layer 2 Network. Always take care when conducting Digital Currency
							transactions, and if you are in doubt, you should contact our customer
							support to receive technical support about blockchains and address formats.
						</li>
						<li>
							At peak times, or during a period of instability in a blockchain
							network, it may not be possible to move your Digital Currency to private
							keys that are personal to you as fast as it usually is.
						</li>
						<li>
							We attempt to provide support for popular Digital Currencies (e.g.
							Bitcoin, Ethereum and USDC) but do not guarantee that it will continue to
							support any particular Digital Currency. In the event that we are forced to
							disable or remove a Digital Currency we will attempt to provide notice in
							advance.
						</li>
						<li>
							It is possible that you may experience a technical error while using
							the Services. If you experience such an error you should report the anomaly
							to customer support rather than taking action. We endeavour to fix all
							errors on a timely basis but will not accept responsibility for your
							actions based on user interface errors.
						</li>
						<li>
							Blockchain developers occasionally develop new blockchains that
							split off from the current version (a &quot;Fork&quot;) and it is likely that we will
							not support the Fork. To help mitigate this risk, you should move your
							Digital Currency to private keys that are personal to you, in advance of
							the Fork launching. Moving your Digital Currency to private keys that are
							personal to you will allow you to use and interact with any alternative
							Fork version of a Digital Currency that we do not support.
						</li>
					</ol><h1>ACCOUNT FREEZES</h1><p>
						We are subject to stringent requirements, including anti-money laundering
						laws. As part of our requirements under applicable laws, it might be
						necessary for us to temporarily pause or permanently terminate activities
						involving your Account.
					</p><p>
						We may place a freeze on your Account (&quot;Account Freeze&quot;) if we are required
						to do so as part of our regulatory requirements (e.g. FINTRAC
						requirements), if we believe your Account or your use of the Services does
						not comply with this agreement, if we believe your Account or your use of
						the Services exposes us, yourself, another user or third party to excessive
						financial, security, legal, reputational or any other risk, or if we are
						required to do so as part of any other legal requirement. We may
						communicate with you to resolve any Account Freeze, which may include
						requesting documentation or further information (to the extent that we are
						permitted by law to do so). If we do not communicate with you and you
						realize your Account has been frozen, it is your responsibility to reach
						out to our support team.
					</p><p>
						In some circumstances, we may continue an Account Freeze for days or weeks,
						and ultimately may be required to terminate your Account and return your
						assets to you if we suspect that activities associated with your Account
						may be contrary to Canadian law, or damaging to us or another person. We
						reserve the right to impose an Account Freeze or terminate your Account in
						our sole discretion and your sole remedy for such an Account Freeze or
						termination shall be the return of your assets, minus account closure and
						investigation fees of EUR 300 minimum and up to 5% of the funds deposited
						on your account, subject to any law that requires us to do otherwise.
					</p>
				</div>
			</Segment>
			<Grid>
				<Grid.Row centered>
					<h1>Terms and Conditions - Vaults</h1>
				</Grid.Row>
			</Grid>

			<Segment className="policyContent">
				<p>
					Earn in Vaults, a feature within DTS Money Platform and App (&quot;DTS&quot;) permits
					eligible users (&quot;Vault Users&quot;) to participate in the consensus process of a
					proof-of-stake (&quot;PoS&quot;) blockchain protocol (&quot;Staking Services&quot;, &quot;Vault
					Services&quot;, &quot;Earn Service(s)&quot; or &quot;Services&quot;).
				</p>
				<p>
					Vault Users may contribute to the integrity and stability of the protocol’s
					underlying decentralized network by locking, also referred to as bonding,
					their Crypto Assets (&quot;Staked Crypto Assets&quot;) to validate, propose, sign,
					vote on and/or attest blocks in a PoS blockchain protocol (&quot;Staking&quot;). To
					perform Staking, holders of Crypto Assets can delegate or nominate Staking
					to Validators, which are selected according to the rules of the Staking
					protocol. In return for the Staking of Crypto Assets, Validators may
					receive a compensation paid out or distributed to them by the decentralized
					network protocol (&quot;Rewards&quot;).
				</p>
				<p>
					Vault Users instruct and engage ESCROWFY GMBH (&quot;ESCROWFY&quot;), a Switzerland
					incorporated Company with incorporation number CHE-281.608.494, supervised
					by the Verein zur Qualitätssicherung von Finanzdienstleistungen (The
					Financial Services Standards Association) (hereinafter &quot;VQF&quot;) as an Escrow
					Agent and Virtual Assets Service Provider with VQF Membership Number
					101009, and located at Bahnhofstrasse 7, 6300 ZUG, Switzerland, to perform,
					on their behalf and for their account, Staking Services for PoS blockchain
					protocols that are part of DTS Staking Services offering.
				</p>
				<p>
					For purposes of this agreement, the entity providing the Staking Services
					is ESCROWFY.
				</p>
				<p>
					These terms and conditions constitute a legally binding contract between
					you, as a Vault User, and ESCROWFY that provides you access to the Staking
					Services (the &quot;Agreement&quot;). This Agreement governs your access to and use
					of the Staking Services. By using the Staking Services, you accept, in
					addition to all of the terms and conditions that are otherwise set out in
					the General Terms and Conditions, the additional terms set out below in
					this Agreement.
				</p>
				<p>
					Please carefully review these terms before using, or continuing to use the
					Vault Services. These terms include important information about your
					relationship with ESCROWFY. Your use of the services is expressly
					conditioned on your consent to, and compliance with, the terms. By
					accessing or using our services, you agree to be bound by the terms. If you
					do not agree to any of the provisions of these terms you should immediately
					stop using the Services. We encourage you to read the terms carefully and
					use them to make informed decisions.
				</p>
				<p>
					The treatment of digital assets may change depending on the jurisdiction in
					which you reside. Please read the following terms carefully so that you
					understand these changes and can make informed financial decisions.
				</p>
				<p>
					<br />
					ESCROWFY reserves the right to implement, change, modify, or increase any
					fee, rates or other related cost in connection with your Vault Account or
					the use of any of the Services at any time. In the event a fee applies to
					you, we will notify you of the pricing of the fee prior to your providing
					authorization to complete the subject transaction or transfer. By accepting
					the Terms you hereby agree to pay all fees associated with or incurred by
					your use of the Vault Account or any of the Services.
				</p>
				<h1>Definitions</h1>
				<p>
					&quot;<strong>Vault Account</strong>&quot; or means a User’s designated user account
					on DTS, allowing a User to access and use the Services, view the User’s
					balance on his/her DTS Account, any rewards gained, and manage the User’s
					personal information and profile.
				</p>
				<p>
					Your DTS account is not a bank account, deposit account, savings accounts,
					checking account, or any other type of asset account and should not be
					characterized as a banking product or service. The use of terms such as
					&quot;account,&quot; &quot;account balance,&quot; &quot;withdraw&quot; and similar language in connection
					with the Staking Services does not imply or establish, and shall not be
					taken to suggest, any form of banking relationship, and such language is
					used herein as terms of convenience only in referring to users’
					relationship with Escrowfy.
				</p>
				<p>
					&quot;<strong>AML</strong>&quot; stands for Anti-Money Laundering, which means a set
					of procedures, laws, and regulations that are intended to stop the practice
					of generating income through illegal actions.
				</p>
				<p>
					&quot;<strong>Blockchain</strong>&quot; means a system in which records of
					transactions made in Digital Assets are maintained across several computers
					that are linked in a peer-to-peer network.
				</p>
				<p>
					&quot;<strong>Custody Wallet</strong>&quot; means a Virtual Wallet where all Eligible
					Digital Assets held therein are custodial assets maintained either by us or
					by a third-party institution or other entity selected by ESCROWFY (a &quot;Third
					Party Custodian&quot;).
				</p>
				<p>
					&quot;<strong>Digital Asset</strong>&quot; means a Digital representation of value in
					which encryption techniques are used to regulate the generation of Digital
					units and verify the transfer of assets, operating independently from a
					central bank.
				</p>
				<p>
					&quot;<strong>Eligible Digital Assets</strong>&quot; means the types of Digital
					Assets we may choose to designate for inclusion under one or more of the
					Services from time to time, which are subject to change and/or limitation
					in our sole discretion, based on business, regulatory and/or other
					considerations.
				</p>
				<p>
					&quot;<strong>Fiat</strong>,&quot; when used in reference to money or currency, means
					the coin and paper money of a country that is designated as legal tender,
					circulates, and is customarily used and accepted as a medium of exchange in
					the country of issuance.
				</p>
				<p>
					&quot;<strong>KYC</strong>&quot; stands for Know Your Customer (or Client), which
					means the process of a business verifying the identity of its customers or
					clients and assessing potential risks of illegal intentions for the
					business relationship.
				</p>
				<p>
					&quot;<strong>Stablecoin</strong>&quot; means a Digital Asset that is Pegged to a
					Fiat currency.
				</p>
				<p>
					&quot;<strong>Virtual Wallet</strong>&quot; or &quot;<strong>Virtual Wallet Address</strong>&quot; means an on-Blockchain virtual
					address in which Digital Assets can be held and transferred.
				</p>
				<h1>Eligibility</h1>
				<p>
					To be eligible to use the Staking Services, you must:
				</p>
				<ul>
					<li>
						Be in compliance with the terms and conditions;
					</li>
					<li>
						Be at least 18 years old or the legal age of majority in the
						jurisdiction in which you reside;
					</li>
					<li>
						Not be a resident of the United States of America;
					</li>
					<li>
						Not be subject to any sanctions imposed by any government, including
						without limitation those imposed by the United Nations, the European
						Union, any EU country, the UK Treasury, or the U.S. Office of Foreign
						Assets Control (&quot;OFAC&quot;); and
					</li>
					<li>
						Read, understand, and agree to our Privacy Policy.
					</li>
				</ul>
				<p>
					Even if you meet the eligibility criteria above, we reserve the right to
					refuse your request to use and access the Staking Services, to suspend your
					access to the Staking Services, and terminate your Account at any time in
					our sole discretion. Further, we reserve the right to change the
					eligibility criteria at any time. If we become aware that you are an
					ineligible Vault User or otherwise determine that you are in violation of
					any applicable terms and conditions, we may delete your Account immediately
					without notice and ban you from using any of our products and services. In
					addition to the other rights set forth herein and to those that may be
					available to us, you agree that you will indemnify and hold harmless
					ESCROWFY and its Affiliates from any legal or monetary consequences that
					arise from any unauthorized use, including without limitation, all uses
					associated with an ineligible Vault User.
				</p>
				<h1>Staking Plans and Remuneration</h1>
				<p>
					Rewards consist of (i) newly generated Crypto Assets distributed by a PoS
					blockchain protocol for performing blockchain validation tasks, which may
					include validating transactions, collecting and bundling transactions into
					blocks, proposing, signing, voting on and/or attesting blocks as well as
					protocol-related governance tasks, and/or (ii) Crypto Assets paid by PoS
					blockchain protocol users in the form of transaction fees.
				</p>
				<p>
					Pay-outs are distributed monthly, and their amount will depend on the
					Validator’s stake size, the actual successful participation in the
					consensus mechanism and the total amount of Staked Crypto Assets in the
					protocol as well as other factors defined by the specific PoS blockchain
					protocol.
				</p>
				<p>
					ESCROWFY shall receive the Remuneration of the Clients on their behalf and
					further distribute, pro-rated to every Client’s contribution, the Client’s
					Rewards.
				</p>
				<h1>Eligible Digital Assets</h1>
				<p>
					We may, from time to time and in our sole discretion, add and/or remove
					certain Digital Assets from our list of Eligible Digital Assets. If a
					Digital Asset is removed, it will no longer be available to be used in
					connection with our Services. We will seek in good faith to notify our
					Users of our intention to add and/or remove Digital Assets in connection
					with any of our Services as soon as commercially reasonable. However, under
					certain circumstances, such changes may be required to be made immediately
					and without prior notice (e.g. Regulatory matters). We may choose to
					disallow the use of any Eligible Digital Asset for certain Services, or
					treat any Digital Asset as an Eligible Digital Asset for certain Users or
					groups of Users for certain Services, in our sole discretion.
				</p>
				<h1>Fixed Plans</h1>
				<p>
					Each Fixed Plan requires a minimum amount to be invested (&quot;Minimum
					Quantity&quot;) which is disclosed in DTS.
				</p>
				<p>
					Each Fixed Plan will be for a fixed duration ranging from one (1) to
					thirty-six (36) months. We reserve the right to amend the terms of any
					Fixed Plan if such changes are immaterial and do not impact the potential
					rewards to be earned or the plan duration.
				</p>
				<p>
					The indicated rate is for informational purposes and we make no
					representations whatsoever that you will receive the rewards corresponding
					to the informational indicated rate.
				</p>
				<h1>Contribution</h1>
				<p>
					When participating in the consensus process of a proof-of-stake protocol
					through the intermediation of ESCROWFY, you grant ESCROWFY, subject to
					applicable law and for the duration of the period during which you elect to
					utilize the Earn Service, all right and title to such Assets, including
					ownership rights, and the right, without further notice to you, to hold
					such Assets in ESCROWFY own Account or Virtual Wallet or elsewhere, and to
					pledge, re-pledge, hypothecate, rehypothecate, sell, lend, or otherwise
					transfer or use any amount of such Assets, separately or together with
					other property, with all attendant rights of ownership, and for any period
					of time, and without retaining in ESCROWFY possession and/or control a like
					amount of Assets or any other monies or assets, and to use or invest such
					Assets in full discretion.
				</p>
				<p>
					You acknowledge that with respect to the use of Assets pursuant to this
					paragraph:
				</p>
				<ul>
					<li>
						You will not be able to exercise rights of ownership;
					</li>
					<li>
						ESCROWFY may receive compensation in connection with Staking; and
					</li>
					<li>
						If ESCROWFY becomes bankrupt, enter liquidation or is otherwise unable
						to repay its obligations, any Eligible Digital Asset used in the Earn
						Service may not be recoverable, and you may not have any legal remedies
						or rights in connection with ESCROWFY obligations to you other than
						your rights as a creditor of ESCROWFY under any applicable laws.
					</li>
				</ul>
				<h1>Withdrawal </h1>
				<p>
					You may terminate any Fixed Plan at any time, and request that ESCROWFY
					returns part or full of your Contribution and delivers any Rewards accrued
					from the Staking Services. In such an early termination, you will get the
					amount of your contribution minus the accumulated sum of all the Rewards
					earned since the activation of your Plan. E.g., You contributed for 1,000€
					in a 12-month plan and you already received 50€ as Rewards. If you want to
					cancel your Plan after 6 months, you will receive 950€ for the principal
					plus the 50€ of Rewards you already earned.
				</p>
				<p>
					ESCROWFY initiates the withdrawal process immediately following a
					withdrawal request when possible; however, we require up to sixty (60) days
					after you submit your withdrawal request to process the withdrawal.
				</p>
				<p>
					For every withdrawal request, you will be required to provide the details
					of the Account and/or Virtual Wallet to which you wish to receive your
					repayment of your Contribution. In the event that the details you provide
					are inaccurate, incomplete, or misleading, your Assets may be permanently
					lost. We will not be liable for any loss that results from inaccurate,
					incomplete, or misleading details that you may provide for such transfer.
					If the transfer address you specify is one to which we are unable to
					process transfers, we will have no liability for any resulting failure or
					delay in processing your requested withdrawal.
				</p>
				<p>
					ESCROWFY and our third-party partners may experience cyber-attacks, extreme
					market conditions, or other operational or technical difficulties which
					could result in the immediate halt of transactions either temporarily or
					permanently. Provided that ESCROWFY has taken reasonable commercial and
					operational measures to prevent such events in technical systems controlled
					by ESCROWFY, ESCROWFY is not and will not be responsible or liable for any
					loss or damage of any sort incurred by you as a result of such
					cyber-attacks, operational or technical difficulties or suspensions of
					transactions. Withdrawal limits based on amounts and/or frequency may apply
					from time to time, based on legal, regulatory, AML and/or security
					considerations. Our policies and procedures may require additional security
					and/or compliance checks that require additional time to complete.
				</p>
				<p>
					Every transmission request shall be deemed pending until accepted by us. We
					may refuse to accept such request, or delay the processing of an approved
					request for any reasonable reason, including but not limited to inaccurate
					or misleading information provided by you, or any doubt or suspicion of
					fraud, misrepresentation, a sanctioned transaction, money laundering,
					terrorism financing or other financial crime related to your ESCROWFY
					Account.
				</p>
				<h1>Fees</h1>
				<p>
					The price list is available on request to the support team.
				</p>
				<h1>Automatic Renewal</h1>
				<p>
					Upon expiration of a Fixed Plan, unless you expressed your wish to
					terminate the plan and deallocate your assets minimum sixty (60) days
					before the expiration date, the equivalent amount of your Staking
					contribution will automatically be reallocated in the same Fixed Plan and
					for the same period of time.
				</p>
				<p>
					If you wish to terminate an automatically renewed Plan, you can do it at
					any time by giving ESCROWFY a sixty (60) days’ notice to process your
					withdrawal. In such an early termination, you will get the amount of your
					contribution minus the accumulated sum of all the Rewards earned since the
					activation of your automatically renewed Plan. E.g., You contributed for
					1,000€ in a 12-month plan and you already received 50€ as Rewards. If you
					want to cancel your Plan after 6 months, you will receive 950€ for the
					principal plus the 50€ of Rewards you already earned.
				</p>
				<h1>Rights </h1>
				<p>
					We reserve the right to and at any time, without liability to you to:
				</p>
				<ul>
					<li>
						Refuse your request to open a Staking Account;
					</li>
					<li>
						Change the eligibility criteria for registration or use of the Staking
						Services;
					</li>
					<li>
						Close, suspend, limit, restrict, or terminate your access to the
						Staking Services, the Website, the App, or other products and services
						provided by our Affiliates; and
					</li>
					<li>
						Change, update, remove, cancel, suspend, disable, or discontinue any
						features, component, Content or incentive of the Staking Services.
					</li>
				</ul>
				<h1>Prohibited Activities </h1>
				<p>
					By accessing and using the Staking Services, you agree that you will not do
					any of the following:
				</p>
				<ul>
					<li>
						Engage in any activity that would violate or assist in the violation of
						any law, statute, regulation, or ordinance;
					</li>
					<li>
						Violate or breach any terms of this Agreement, any other agreement or
						set of applicable terms and conditions and our Privacy Policy;
					</li>
					<li>
						Provide us with any false, inaccurate, or misleading information or
						otherwise attempt to defraud us;
					</li>
					<li>
						Attempt to gain unauthorized access to our network, system, or website
						or App;
					</li>
					<li>
						Decompile, disassemble, or otherwise reverse engineer our Website, App,
						Staking Services, or any other related product, program, or system or
						in any way infringe on our intellectual property rights including, but
						not limited to, copyrights, patents, trademarks, or trade secrets;
					</li>
					<li>
						Engage in any form of data mining, indexing, scraping, surveying, or
						otherwise with respect to our Website, App, Staking Services, or any
						other related product, program, or system; or
					</li>
				</ul>
				<h1>Suspension and Termination</h1>
				<p>
					We reserve the right to suspend, restrict, or terminate your access to the
					Services if:
				</p>
				<ul>
					<li>
						We are required to do so by a court order, government order, or similar
						demand from authorities;
					</li>
					<li>
						We reasonably suspect you of engaging in Prohibited Activities as
						described in this Agreement; or
					</li>
					<li>
						We reasonably suspect you of otherwise being in violation of this
						Agreement or other applicable terms and conditions for products and
						services offered by ESCROWFY or its affiliates.
					</li>
				</ul>
				<p>
					Upon termination of your Account, you are entitled to recover your Assets
					unless we are prohibited from doing so by law or a court order or where we
					reasonably suspect that such funds were obtained through fraud or any
					unlawful means or in connection with any criminal activities.
				</p>
				<h1>Agreement</h1>
				<p>
					This Agreement terminates immediately upon you closing your Account or our
					termination of the Services, but such termination shall not prevent any
					party from seeking remedies against any other party for any breach of this
					Agreement occurring prior to termination.
				</p>
				<h1>Contact</h1>
				<p>
					If you have any questions regarding the Staking Services or need assistance
					in connection with the suspension or termination of your Earn Account, please contact us at    <a href="mailto:compliance@dtsmoney.com">compliance@dtsmoney.com</a>
				</p>
				<p>
					<strong>Miscellaneous Provisions</strong>
				</p>
				<p>
					<h1>Indemnification </h1>
				</p>
				<p>
					You will defend, indemnify, and hold harmless ESCROWFY, its affiliates, and
					each of their respective employees, officers, directors, and
					representatives from and against any claims, damages, losses, liabilities,
					costs, and expenses (including reasonable attorneys’ fees) arising out of
					or related to your breach of this Agreement, your violation of any statute,
					regulation, or ordinance, or the rights of any third party.
				</p>
				<p>
					<h1>Risk Disclaimers</h1>
				</p>
				<p>
					Before using any of our Services, you should ensure that you fully
					understand and can afford to undertake the risks involved. The risks listed
					below are intended to provide you with a general outline of the risks
					involved, but cannot capture all such risks.
				</p>
				<p>
					These Terms and your use of any of our Services do not create a fiduciary
					relationship between us and you. Your Account is not a checking or savings
					account, and it is not covered by any insurance against losses. ESCROWFY
					has no duty to inquire into, supervise, or determine the suitability of any
					transaction you initiate involving your Account. We may lend, sell, pledge,
					hypothecate, assign, invest, use, commingle or otherwise dispose of Assets
					and Eligible Digital Assets that are not held in a Custody Wallet to
					counterparties or hold the Eligible Digital Assets with counterparties, and
					we will use our best commercial and operational efforts to prevent losses.
				</p>
				<p>
					Digital Assets are not legal tender. ESCROWFY is not a bank or depository
					institution, and your Account is not a deposit account. Assets represented
					in your Account are not insured by any private or governmental insurance
					plan, and are not covered by any compensation scheme. Your Account does not
					constitute an investment contract or a security, is not transferable and
					may not be traded, exchanged or sold to any third party under any
					circumstances.
				</p>
				<p>
					ESCROWFY does not provide any legal, tax or financial advice and you are
					strongly advised to obtain independent legal, tax or financial advice prior
					to making any financial decision, including buying, trading, holding, or
					using Digital Assets. There are significant risks associated with Digital
					Assets, and you are solely responsible to make sure you understand such
					risks and assess whether such risks are appropriate for you. ESCROWFY does
					not make any offers, recommendations, or invitations for you to deal in
					Digital Assets or use any Services, and does not take into account your
					personal circumstances, financial situation, needs or goals. Before making
					any financial decision, you should carefully assess your financial
					situation and capacity, and only use funds that you can afford to lose.
					Before entering into any transaction or using any of the Services you
					should ensure that you understand and have made an independent assessment
					of the suitability and appropriateness of a transaction into which you are
					entering and the nature and extent of your exposure to risk of loss in
					light of your own objectives, financial and operational resources, and
					other relevant circumstances. Past performance is no guarantee of future
					results.
				</p>
				<p>
					Legislative and regulatory changes or actions may adversely affect the use,
					transfer, exchange, and value of Digital Assets. Transactions in Digital
					Assets may be irreversible, and, accordingly, losses due to fraudulent or
					accidental transactions may not be recoverable.
				</p>
				<p>
					The value of Digital Assets may be derived from the continued willingness
					of market participants to exchange Digital Assets for Fiat currencies or
					other Digital Assets. If such willingness is abolished for any reason, this
					may result in the potential for a permanent and total loss of value of a
					particular Digital Asset.
				</p>
				<p>
					The volatility and unpredictability of the price of Digital Assets may
					result in significant loss over a short period of time. The nature of
					Digital Assets may lead to an increased risk of fraud or cyber-attack,
					including rollback attacks or Blockchain reorganizations. The nature of
					Digital Assets means that any technological difficulties experienced by
					ESCROWFY or third parties may limit, delay or prevent the access or use of
					Digital Assets and/or cause losses of Digital Assets. Although ESCROWFY and
					its partners take precautionary measures to protect against cyber threats,
					circumstances may arise where losses or damages are incurred.
				</p>
				<p>
					In light of these risks, which are only some of the risks involved in using
					the Services and holding or trading in Digital Assets, and do not
					constitute an exhaustive list of such risks, you should carefully consider
					whether holding or trading Digital Assets in general and/or using our
					Services is suitable for you in light of your financial condition.
				</p>
				<h1>Acceptance of risks</h1>
				<p>
					Everything on our website and provided as part of our service offering is
					provided &quot;as is&quot; and &quot;as available&quot; without warranty of any kind including
					all implied warranties and conditions of merchantability and fitness for a
					particular purpose. We hereby disclaim all warranties and conditions of any
					kind, whether express, implied or statutory.
				</p>
				<p>
					Before using the Staking Services you should obtain legal, financial and
					tax advice.
				</p>
				<p>
					You agree that, while we strive to have error free and uninterrupted
					service, we do not guarantee the absence of interruptions nor shall you
					hold us liable for any damage such interruptions may cause outages and
					downtime may occur for various reasons.
				</p>
				<p>
					You acknowledge that dealing with Digital assets could be risky.
				</p>
				<p>
					You further acknowledge that there is a risk of malicious cyberattacks
					against our website and services, your account, your personal computing
					devices, smartphone and the third-party providers which may result in the
					loss or theft of your funds, information and/or Digital assets.
				</p>
				<p>
					You accept all risks associated with locking your assets in vaults for a
					defined period of time.
				</p>
				<p>
					You agree and acknowledge that we may suspend access to your account, or
					account functions for any reason, including for example, scheduled
					maintenance, your failure to comply with these terms, security or fraud
					concerns or for regulatory reasons.
				</p>
				<p>
					We make no representations or warranties as to the ongoing availability of
					our third-party service providers to operate the Staking Services. In the
					event third parties we rely on no longer offer or facilitate Earn Services,
					you may not be able to benefit from the rewards offered by the Staking
					Services.
				</p>
				<p>
					We reserve the right to provide your personal information uploaded or
					collected by our website or services, or otherwise provided to us, to third
					parties if required by law, and to cooperate with law enforcement
					authorities in the investigation of any criminal or civil matter. such
					personal information may include, but is not limited to, the information
					described in these terms and our privacy policy, including your name,
					wallet address and transaction history.
				</p>
				<p>
					If we are required by law to make any disclosure of your personal
					information, we may (but are not obligated to) provide you with written
					notice (to the extent permitted by law) prior to such disclosure so that
					you may take appropriate action.
				</p>
				<p>
					We also operate the website and services in conjunction with our
					independent contractors. Our independent contractors may have access to
					your personal information in providing services to us, or providing you
					with access to the website and services. We may use a variety of service
					providers, employees and servers in locations both inside and outside of
					Switzerland, which may make your personal information the subject of
					foreign laws and foreign legal proceedings.
				</p>
				<p>
					To the fullest extent permitted by law, without limiting the generality of
					this section, or any other section limiting our liability, you agree that
					we shall not be responsible for the acts or omissions, including the
					negligent acts or omissions, of our independent contractors or third-party
					service providers.
				</p>
				<h1>Limitation of liability</h1>
				<p>
					You agree that, to the fullest extent permitted by law, in no event will
					we, our affiliates, officers, directors, shareholders, employees,
					contractors, agents or suppliers be liable, howsoever caused (including by
					negligence or otherwise), for any direct, indirect, special, incidental,
					punitive, exemplary or consequential damages you suffer, whether or not we
					have been warned of the possibility of such damages and whether those
					damages were foreseeable or not.
				</p>
				<p>
					If you are dissatisfied with the website or services, or do not agree with
					any part of these terms, or have any other dispute or claim with or against
					us, our affiliates, officers, directors, shareholders, employees,
					contractors, agents, suppliers or licensors, then your sole and exclusive
					remedy is to withdraw your Digital Assets at the end of the fixed plan and
					discontinue accessing and using the Services.
				</p>
				<p>
					In addition to and separate from your agreement to not hold the above
					entities and persons liable for any damages, in the event a court or
					arbitrator of competent jurisdiction declines to uphold said clause, you
					agree that:
				</p>
				<ul>
					<li>
						in no circumstances shall the aggregate liability for any and all
						claims relating to or in any way arising from the use of our website or
						services, or in any way related to these terms, be more than the
						amounts paid by you to us as fees, if any and if implemented, or which
						we have earned as a result of your account;
					</li>
					<li>
						in no circumstances shall we be liable or responsible for any loss of
						profits or loss of expected revenue or gains;
					</li>
					<li>
						in no circumstances shall we be liable or responsible for any damages
						you suffer arising from the loss of an opportunity, including for
						example, the loss of an opportunity to buy, sell or profit from a
						Digital Asset;
					</li>
					<li>
						in no circumstances shall we be liable or responsible for any loss or
						damage whatsoever which does not arise directly as a result of our
						breach of this agreement (whether or not you are able to prove such
						loss or damage).
					</li>
				</ul>
				<p>
					You agree and acknowledge that we would not enter into this agreement or
					grant access to the website or services without the above restrictions and
					limitations on our liability.
				</p>
				<h1>Indemnification and hold harmless</h1>
				<p>
					You agree to indemnify us, our affiliates, employees, shareholders,
					directors, agents, representatives and contractors, and to defend and hold
					each of them harmless, from any and all claims and liabilities (including
					reasonable legal fees) which may arise from, (i) your violation of these
					terms, or any policy incorporated by reference; (ii) your violation of any
					third party right; (iii) any breach of a representation or warranty made by
					you to us, either in these terms, privacy policy or otherwise; or (iv) any
					breach by you of applicable laws, rules or regulations.
				</p>
				<h1>Tax Obligations</h1>
				<p>
					Uncertainty in the treatment of taxes related to Digital Assets may expose
					you to future or unknown tax obligations. It is your sole responsibility to
					determine what taxes, if any, arise from your use of the Staking Services.
					Further, you are solely responsible for reporting and paying any applicable
					taxes. Except to the extent that we are required by applicable law, we will
					not determine whether, or to what extent, you are liable for taxes, report
					them, or withhold them.
				</p>
				<h1>Entire Agreement </h1>
				<p>
					This Agreement (together with the Privacy Policy) represents the entire
					agreement between you and us with respect to the Staking Services and
					supersedes all prior representations, understandings, agreements, or
					communications between you and us regarding the Earn Services.
				</p>
				<h1>Amendments</h1>
				<p>
					We may amend or modify these Terms and Conditions from time to time by
					posting the updated Terms and Conditions on our Website/App and such
					amendment or modification shall take effect immediately. Your continued use
					of the Staking Services constitutes your acceptance of the updated
					Agreement; if you do not agree with the updated Agreement, your sole and
					exclusive remedy is to discontinue use of the Staking Services.
				</p>
				<h1>Severability </h1>
				<p>
					If any provision, in whole or in part, of this Agreement shall be
					determined to be invalid or unenforceable by a court of competent
					jurisdiction or a governmental body, such provision shall be changed and
					interpreted to accomplish its objectives to the greatest extent possible
					under applicable law and the validity and enforceability of any other
					provision of this Agreement shall not be affected.
				</p>
				<h1>Survival</h1>
				<p>
					All provisions of this Agreement which by their nature extend beyond its
					termination, including, without limitation, provisions pertaining to
					suspension and termination, shall survive such termination.
				</p>
				<h1>Waiver</h1>
				<p>
					The delay or failure of one party to require performance of any provision
					of this Agreement shall not constitute a waiver of that party’s right to
					require performance at any time thereafter. Further, the waiver of one
					party to seek recovery for the other party’s non- performance shall not
					constitute a waiver by the former party to seek recovery for any subsequent
					non-performance.
				</p>
				<h1>Assignment </h1>
				<p>
					You may not assign, delegate, or transfer any rights or obligations under
					this Agreement without our prior written consent. Any such attempted
					assignment, delegation, or transfer shall be null and void. We reserve the
					right to assign our rights without restriction, including without
					limitation to affiliates or subsidiaries, or to any successor in interest
					of any business associated with our Website/App or the Staking Services.
				</p>
				<h1>Third Party Rights </h1>
				<p>
					Other than the affiliates of ESCROWFY, a person who is not a party to this
					Agreement shall have no right under this Agreement.
				</p>
				<h1>Governing Law</h1>
				<p>
					This Agreement shall be governed by, construed and enforced in accordance
					with the laws of Switzerland.
				</p>
				<p>
					In respect of a claim by either you or us arising out of or in any way
					related to this Agreement, all documents incorporated by reference and your
					relationship with us, you agree to irrevocably and unconditionally submit
					to the exclusive jurisdiction of the courts and tribunals of Switzerland.
				</p>
				<h1>Force Majeure</h1>
				<p>
					We shall not be liable for any delay, error, interruption, or failure to
					perform any obligation under this Agreement where the delay or failure is
					directly or indirectly resulting from any cause beyond our control
					including, but not limited to i) acts of God, nature, court, or government;
					ii) failure or interruption in private or public telecommunications
					networks, communication channels, or information systems; iii) acts or
					omissions of a party for whom we are not responsible; iv) delay, failure,
					interruption in, or unavailability of third-party services and sites; v)
					strikes, lockouts, labor disputes, war, terrorist acts, or riots; and vi)
					viruses, malware, other malicious computer code, or the hacking of any part
					of our Website/App or the Staking Services.
				</p>
				<p>
					You understand and agree that your use of the Staking Services is at your
					own risk. This section is not exhaustive and does not disclose all the
					risks associated with Digital Assets and the use of our products and
					services. You should carefully consider whether such use is suitable for
					you in light of your circumstances and financial resources.
				</p>
				<h1>Disclosures</h1>
				<p>
					The regulatory status of Digital Assets is currently unsettled, varies
					among jurisdictions, and is subject to significant uncertainty. It is
					possible that in the future, certain laws, regulations, policies, or rules
					relating to Digital Assets, blockchain technology, or blockchain
					applications may be implemented which would directly or indirectly affect
					or restrict the services that we provide to you.
				</p>
				<p>
					You are responsible for determining whether the use of the Earn Services
					and any of our related services is legal in your jurisdiction and you shall
					not use them should such use be illegal in your jurisdiction. If you are
					uncertain, please seek independent legal advice.
				</p>
				<p>
					We may be forced to suspend, discontinue, or to change aspects of the
					Staking Services and any of our services in any jurisdictions without
					notice and for whatever reason if demanded by regulatory authorities. In
					such instances, Digital Assets in your Earn Account may be frozen for an
					indefinite period of time until the matter is resolved.
				</p>
				<h1>Cybersecurity</h1>
				<p>
					Digital Assets may be subject to expropriation, theft and/or fraud; hackers
					and other malicious groups or organizations may attempt to interfere with
					our network and/or system in various ways including malware attacks, denial
					of service attacks, consensus-based attacks, Sybil attacks, smurfing, and
					spoofing which may result in the loss of your Digital Assets or the loss of
					your ability to access or control the same. In such an event, we do not
					guarantee any remedy, refund, or compensation.
				</p>
				<h1>Change of terms </h1>
				<p>
					Please be aware that the terms and conditions governing the Services can
					change over time. We reserve the right to discontinue or make changes to
					any of the Services. We may change these Terms, and we may add to or delete
					from these Terms, and the updated version will supersede all prior
					versions. We will provide notice of changes, additions, and deletions, as
					required by law.
				</p>
			</Segment>
		</Container >
	);
}

export default TermsYESSY;
