import * as React from "react";

function SvgHomeIcon(props) {
  return (
    <svg
      width={20}
      height={22}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M7 21V11h6v10M1 8l9-7 9 7v11a2 2 0 01-2 2H3a2 2 0 01-2-2V8z" />
    </svg>
  );
}

export default SvgHomeIcon;
