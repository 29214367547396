import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Divider, Grid, Item, Segment } from 'semantic-ui-react';
import isEmpty from 'is-empty';
import * as Yup from 'yup';
import { confirmPhoneNumber, getCardSmsCountries, resendSmsCode } from '@/features/user/userSlice';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useHistory } from 'react-router-dom';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import './ConfirmPhoneNumber.css';
import ConfirmPhoneIcon from '@/icons/ConfirmPhone';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getRecaptureToken } from '@/helpers/recaptureUtils';
import { CountryPhoneCodesType } from '@/features/user/types';

const ConfirmPhoneNumber = () => {
    const { t } = useTranslation(['translations']);
    const [phoneNumberField, setPhoneNumberField] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>(null);
    const history = useHistory();
    const [smsCountries, setSmsCountries] = useState<CountryPhoneCodesType[]>([]);

    const phoneCodeOptions = smsCountries.map(c => ({ key: c.countryCode, value: c.phoneCode, text: `(${c.phoneCode}) ${c.countryName}` }));

    useEffect(() => {
        const get = async () => {
            try {
                const countries = await getCardSmsCountries();
                setSmsCountries(countries);
            }
            catch (e) { setSmsCountries([]); }

        };
        get();
    }, []);

    const { user } = useSelector(
        (state: RootState) => state.user
    );

    const initialValues = {
        phoneCode: '',
        number: '',
        code: ''
    };
    const validationSchema = Yup.object({
        phoneCode: Yup.string().required(t('form.validator.required')),
        number: Yup.string()
            .matches(/^([+]|[1-9])[0-9]+$/, t('form.validator.phoneinvalid'))
            .min(4, t('form.validator.phonemin'))
            .required(t('form.validator.required'))
            .test('phoneNumber',
                t('form.validator.phoneinvalid'),
                (value, ctx) => { console.log(`${ctx.parent.phoneCode}${value}`); return isValidPhoneNumber(`${ctx.parent.phoneCode}${value}`); }),
        code: Yup.string().when('codeRequired', {
            is: (codeRequired) => codeRequired === true,
            then: Yup.string().matches(/^\d{6}$/, t('errors.SIX_DIGITS_REQUIRED')).required(t('form.validator.required')),
            otherwise: Yup.string()
        })
    });


    const getData = (err) => {
        const { response } = err;
        if (response) {
            const { data } = response;
            return data;
        } else {
            const { data } = err;
            return data;
        }
    };

    const confirmPhone = async (formData, formikProps) => {
        const { setSubmitting, setFieldError, setFieldValue, setFieldTouched } = formikProps;
        const { phoneCode, number, code } = formData;
        const recaptchaToken = await getRecaptureToken();
        const phoneNumber = `${phoneCode}${number}`;
        try {
            if (!code) {
                await confirmPhoneNumber(recaptchaToken, `${phoneNumber}`);
            } else {
                await confirmPhoneNumber(recaptchaToken, `${phoneNumber}`, code);
            }
            history.push('/wallet');
        }
        catch (e) {
            console.log(e);
            const data = getData(e);
            if (data && data.errors && data.errors.length && (data.errors[0].error === 'required' && data.errors[0].error_param === 'smsCode')) {
                setPhoneNumber(`${phoneNumber}`);
                setFieldValue('codeRequired', true);
                setFieldTouched('code', false);
            } else if (data && data.errors && data.errors.length && (data.errors[0].error === 'invalid' && data.errors[0].error_param === 'smsCode')) {
                setFieldError('code', t('confirmPhone.invalidSmsCode'));
            } else if (data && data.errors && data.errors.length && (data.errors[0].error === 'duplicate' && data.errors[0].error_param === 'phoneNumber')) {
                setFieldError('number', t('confirmPhone.duplicatePhoneNumber'));
            }
        } finally {
            setSubmitting(false);
        }
    };

    useEffect(() => {
        if (user && user.confirmedMobileNumber !== null) history.push('/wallet');
    }, [user.confirmedMobileNumber]);

    const resendCode = async () => {
        try {
            const recaptchaToken = await getRecaptureToken();
            await resendSmsCode(`+${phoneNumber}`, recaptchaToken);
            showSuccessNotification('Code resended');
        } catch (e) {
            showErrorNotification(e);
        }
    };

    return (
        <Container id='confirmPhone' >
            <Segment className='segment'>
                <Grid >
                    <Grid.Row centered><ConfirmPhoneIcon /></Grid.Row>
                    <Grid.Row centered className='header'><h2>{t('confirmPhone.header')}</h2></Grid.Row>
                    <Grid.Row centered className='description'>{t('confirmPhone.description')}</Grid.Row>
                    <Grid.Row>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={confirmPhone}
                        >
                            {({ errors, isSubmitting, dirty }) => (
                                <Form className='form'>
                                    {!phoneNumber && <>

                                        <span className='label'>{t('confirmPhone.numberLabel')}</span>
                                        <Item.Group divided>
                                            <Item>
                                                <div style={{ paddingRight: '8px' }} >
                                                    <Select
                                                        name="phoneCode"
                                                        label={t('form.fields.countryCode')}
                                                        options={phoneCodeOptions}
                                                        errorPrompt
                                                        search 
                                                    />
                                                </div>
                                                <Input
                                                    autoComplete="off"
                                                    autoFocus
                                                    label={t('form.fields.phone')}
                                                    name="number"
                                                    type='number'
                                                    value={phoneNumberField}
                                                    onChange={(e, v) => setPhoneNumberField(v.value.replace(/\D/g, ''))}
                                                    errorPrompt

                                                />
                                            </Item>
                                        </Item.Group>
                                    </>}
                                    {phoneNumber && <>
                                        <span className='label'>{t('confirmPhone.codeLabel')}</span>
                                        <br />
                                        <span className='inputDescription' dangerouslySetInnerHTML={{ __html: t('confirmPhone.codeDescription', { phoneNumber }) }}></span>
                                        <Input
                                            autoComplete="off"
                                            autoFocus
                                            name="code"
                                            errorPrompt
                                        >
                                        </Input>
                                        <a className='resendLink' onClick={resendCode}>{t('confirmPhone.resendCodeLink')}</a>
                                    </>}
                                    <Divider />
                                    <SubmitButton className='button'
                                        disabled={isSubmitting || !isEmpty(errors) || !dirty}
                                        primary size="large" type="submit">
                                        {t('confirmPhone.confirmButton')}
                                    </SubmitButton>
                                </Form>)}
                        </Formik>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Container >
    );
};

export default ConfirmPhoneNumber;
