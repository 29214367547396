import React, { useState } from 'react';
import { Grid, Segment, Divider, Button, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface SendMoneyProps {
    onSend: (value: string) => void
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function Deposit({ onSend }: SendMoneyProps) {
	const { t } = useTranslation('translations');
	const [reference, setReference] = useState<string>('');

	const validationSchema = Yup.object({
	});
	const initialValues = {
		reference: '123456789',
	};

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setTimeout(() => {
			setSubmitting(false);
			setReference('123456789');
		}, 1000);
	};

	return (
		<Grid stackable columns="equal">
			<Grid.Column width={10}>
				{!reference && (<Segment>
					{/* <Header as="h2">{t('sendmoneyTranslation.data.depositheader')}</Header>
                    <Divider hidden /> */}
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={submit}
					>
						{() => (
							<Form>
								<Input
									fluid
									name="bank"
									label={t('sendmoneyTranslation.data.nameofbank')}
									errorPrompt
								/>
								<Input
									fluid
									name="name"
									label={t('sendmoneyTranslation.data.nameofaccount')}
									errorPrompt
								/>
								<Input
									fluid
									name="from"
									label={t('sendmoneyTranslation.data.walletid')}
									errorPrompt
								/>
								<Divider section />
								<SubmitButton primary fluid type="submit">{t('sendmoneyTranslation.activedata.continuebutton')}</SubmitButton>
							</Form>)}
					</Formik>
				</Segment>)}
				{reference && (<Segment>
					{/* <Header as="h2">{t('sendmoneyTranslation.data.depositheader')}</Header>
                    <Divider hidden /> */}
					<Formik
						initialValues={initialValues}
						validationSchema={validationSchema}
						onSubmit={submit}
					>
						{() => (
							<Form>
								<Input
									id="referenceNumber"
									fluid
									disabled
									action={<CopyToClipboard text={reference}>
										<Button onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}} icon="clone outline" />
									</CopyToClipboard>}
									name="reference"
									label={t('sendmoneyTranslation.data.referencenumber')}
									errorPrompt
								/>
								<Checkbox label="I’ve copied my Reference number" />
								<Divider section />
								<SubmitButton disabled primary fluid type="submit">{t('sendmoneyTranslation.activedata.continuebutton')}</SubmitButton>
							</Form>)}
					</Formik>
				</Segment>)}
			</Grid.Column>
		</Grid>
	);
}

export default Deposit;
