import React, { useEffect, useState } from 'react';
import {
    Modal, Segment, Divider, Grid, Button
} from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';


interface Props {
    open: boolean;
    onClose: (co?: boolean) => void;
}


const InactiveModal = ({ open, onClose }: Props): React.ReactElement => {
    const { t } = useTranslation('translations');
    const [timeRemaing, setTimeRemaing] = useState<number>(60);


    useEffect(() => {
        if(!open) return;
        let timeLeft;
        const getTimeLeft = () => {
            timeLeft && clearTimeout();
            timeLeft = setTimeout(() => {
                setTimeRemaing(timeRemaing - 1);
            }, 1 * 1000);
        };
        getTimeLeft();
        if (timeRemaing === 0) onClose(false);
        return () => {
            timeLeft && clearTimeout(timeLeft);
        };
    }, [timeRemaing, open]);

    return <Modal
        size="mini"
        open={open}
        onClose={() => onClose(false)}
        closeOnDimmerClick={false}	
        >
            <Modal.Header style={{textAlign: 'center'}}>{t('session.modal.header')}</Modal.Header>
        <Modal.Content>
            <Segment basic>
                <Grid>
                    <Grid.Row centered>

                        <p>{t('session.modal.text_part1')} <strong style={{fontSize: '1.2em'}}>0:{timeRemaing.toString().length === 1 ? `0${timeRemaing}` : timeRemaing}</strong>{t('session.modal.text_part2')}</p>
                    </Grid.Row>
                </Grid>
                <Divider />
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column><Button primary floated='right' content={t('session.modal.continueBtn')} onClick={() => {setTimeRemaing(60); onClose(true);}} /></Grid.Column>
                        <Grid.Column><Button secondary floated='left' content={t('session.modal.logoutBtn')} onClick={() => onClose(false)} /></Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </Modal.Content>
    </Modal>;
};

export default InactiveModal;
