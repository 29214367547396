import React from 'react';
import { Modal, Button, Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface ResendEmailProps {
    open: boolean,
    dimmer: boolean,
    onClose: () => void,
    resend: () => void
}

const ResendEmail = ({ open, onClose, resend, dimmer }: ResendEmailProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<Modal
			dimmer='inverted'
			size='mini'
			open={open}
			onClose={onClose}>
			<Dimmer dimmer='inverted' active={dimmer}><Loader /></Dimmer>
			<Modal.Header>{t('login.resend.header')}</Modal.Header>
			<Modal.Content>
				<p>{t('login.resend.description')}</p>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>{t('login.resend.closeBtn')}</Button>
				<Button primary onClick={resend}>{t('login.resend.resendBtn')}</Button>
			</Modal.Actions>
		</Modal>);
};

export default ResendEmail;
