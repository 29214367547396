import React from 'react';
import { Button, Grid, Icon, List } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './EmployeeVerificationConfirmation.css';

interface Props {
	front: any,
	back: any,
	utility: any,
	onConfirm: () => void
}

const IndividualVerificationConfirmation = ({ front, back, utility, onConfirm }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const history = useHistory();

	return (
		<Grid id='confirmation'>
			<Grid.Row><h1>{t('employee.verification.form.confirm.header')}</h1></Grid.Row>
			<Grid.Row>
				<List relaxed="very" verticalAlign='middle'>
					<List.Item>
						<List.Header>{t('employee.verification.form.confirm.front')}</List.Header>
						<div><Icon size="large" name="file outline" /> {front.name}</div>
					</List.Item>
					<List.Item>
						<List.Header>{t('employee.verification.form.confirm.back')}</List.Header>
						<div><Icon size="large" name="file outline" /> {back.name}</div>
					</List.Item>
					<List.Item>
						<List.Header>{t('employee.verification.form.confirm.utility')}</List.Header>
						<div><Icon size="large" name="file outline" /> {utility.name}</div>
					</List.Item>
				</List>
			</Grid.Row>
			<Grid.Row>
				<Grid.Column width={2} mobile={5}>
					<Button primary size='large' fluid onClick={() => history.push('/wallet')}>{t('employee.verification.form.confirm.discard')}</Button>
				</Grid.Column>
				<Grid.Column width={3} mobile={7}>
					<Button primary size='large' fluid onClick={() => onConfirm()}>{t('employee.verification.form.confirm.confirm')}</Button>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
};

export default IndividualVerificationConfirmation;
