import React from 'react';
import { Container, Grid, Segment } from 'semantic-ui-react';
import './PrivacyPolicy.css';

function PrivacyPolicyDTS(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Privacy Policy</h1></Grid.Row></Grid>
			<Segment className="policyContent">

				<div>
					<p>
						Welcome to DTS Money (“DTS”) Privacy Policy. Please take a moment to
						read it carefully before providing us with any personal information
						about you or any other person.
					</p>
					<p>
						DTS Money (hereinafter “DTS”) is a Platform operated by Xward Pay Inc.,
						(hereinafter “XWARD”, “we”, “our” or “us”). For the purposes of data
						protection law, XWARD is a data controller in respect of the personal
						data we receive from you, or otherwise collect about you, and we are
						responsible for ensuring that the use of your personal data is
						compliant with applicable data protection laws (‘General Data
						Protection Regulation’). As the controller of your personal data, we
						determine the “means” and the “purposes” of any processing activities
						that are carried out by us.
					</p>
					<p>
						The European representative of XWARD is Afacomp UAB, located A. Tumeno
						str. 4, 01109 Vilnius, Lithuania.
					</p>
				</div>
				<div>
					<br /><h1>
						Background
					</h1><br />
				</div>
				<div>
					<p>
						We need to collect and use certain types of information about the
						physical and legal persons that wish to participate in our services.
					</p>
				</div>
				<div>
					<p>
						The personal information must be collected and dealt with appropriately
						in accordance with the company AML Policy and in accordance with this
						Privacy Policy drawn up in accordance with the GDPR and International
						standards.
					</p>
					<p>In accordance with the provisions of GDPR and International standards,
						any User who provides his information under the present policy confirms
						that he understands and accepts the reason for the gathering of
						information and consents to the processing of information for the
						purposes of money laundering prevention.</p>
					<p>
						Any User is entitled to know who is responsible for the processing of
						his or her personal data.
					</p>
				</div>
				<div>
					<p>
						Any data collected during the course of our business whether it is
						collected on paper, stored in a computer database, or recorded on other
						material is subject to this policy and is protected under the
						applicable law and the GDPR provisions regarding data control and
						processing.
					</p>
				</div>
				<div>
					<br /><h1>
						What Does This Policy Cover?
					</h1><br />
				</div>
				<div>
					<p>
						This Privacy Policy applies only to your use of our website. The
						website may contain links to other websites. Please note that we have
						no control over how your data is collected, stored, or used by other
						websites and we advise you to check the privacy policies of any such
						websites before providing any data to them.
					</p>
				</div>
				<div>
					<br /><h1>
						What is Personal Data?
					</h1><br />
				</div>
				<div>
					<p>
						Personal data is defined by the Personal Information Protection and
						Electronic Documents Act as ‘any information relating to an
						identifiable person who can be directly or indirectly identified in
						particular by reference to an identifier’.
					</p>
					<p>
						Personal data is, in simpler terms, any information about you that
						enables you to be identified. Personal data covers information such as
						your name and contact details, but it also covers less obvious
						information such as identification numbers, electronic location data,
						and other online identifiers.
					</p>
				</div>
				<div>
					<br /><h1>
						What Data Do We Collect?
					</h1><br />
				</div>
				<div>
					<p>
						We collect personal information in the course of its business with you
						when you apply for the use of our products and/or services. The
						information is collected with your consent, or as permitted or required
						by law.
					</p>
					<p>
						Depending upon your use of our website, the personal information that
						you provide or that we ask for and collect could include the following:
					</p>
					<p>
						Identity Data:
					</p>
				</div>
				<div>
					<div>
						<ul>
							<li>
								<p>
									first name,
								</p>
							</li>
							<li>
								<p>
									last name,
								</p>
							</li>
							<li>
								<p>
									title,
								</p>
							</li>
							<li>
								<p>
									date of birth and gender,
								</p>
							</li>
							<li>
								<p>
									biometric information for facial recognition purposes,
								</p>
							</li>
							<li>
								<p>
									I.D documents such as national I.D card, passports, driving
									licences or other forms of I.D documents.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<p>
							Identity Related Data:
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									risk assessment,
								</p>
							</li>
							<li>
								<p>
									compliance assessment.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<p>
							Contact Data:
						</p>
					</div>
				</div>
				<div>
					<div>
						<ul>
							<li>
								<p>
									residence details,
								</p>
							</li>
							<li>
								<p>
									billing address,
								</p>
							</li>
							<li>
								<p>
									delivery address,
								</p>
							</li>
							<li>
								<p>
									email address and telephone numbers,
								</p>
							</li>
							<li>
								<p>
									proof of address documentation.
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div>
					<div>
						<p>
							Financial Data:
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									bank account details,
								</p>
							</li>
							<li>
								<p>
									payment card details,
								</p>
							</li>
							<li>
								<p>
									amounts associated with accounts,
								</p>
							</li>
							<li>
								<p>
									external account details,
								</p>
							</li>
							<li>
								<p>
									source of funds and related documentation.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<p>
							Transactional Data:
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									details about incoming and outgoing payments,
								</p>
							</li>
							<li>
								<p>
									other details of any transactions you enter into using the
									Services, Website or App.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<p>
							Technical Data:
						</p>
					</div>
				</div>
				<div>
					<div>
						<ul>
							<li>
								<p>
									internet connectivity data,
								</p>
							</li>
							<li>
								<p>
									IP address,
								</p>
							</li>
							<li>
								<p>
									operator and carrier data,
								</p>
							</li>
							<li>
								<p>
									login data,
								</p>
							</li>
							<li>
								<p>
									browser type and version,
								</p>
							</li>
							<li>
								<p>
									device type, category and model,
								</p>
							</li>
							<li>
								<p>
									time zone setting and location data,
								</p>
							</li>
							<li>
								<p>
									language data,
								</p>
							</li>
							<li>
								<p>
									browser plug-in types and versions,
								</p>
							</li>
							<li>
								<p>
									operating system and platform,
								</p>
							</li>
							<li>
								<p>
									diagnostics data such as crash logs and any other data we
									collect for the purposes of measuring technical
									diagnostics, and
								</p>
							</li>
							<li>
								<p>
									other information stored on or available regarding the
									devices you allow us access to when you visit the Website,
									or use the Services or the App.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<p>
							Profile Data:
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									your username and password,
								</p>
							</li>
							<li>
								<p>
									your identification number as our user,
								</p>
							</li>
							<li>
								<p>
									requests by you for products or services,
								</p>
							</li>
							<li>
								<p>
									your interests, preferences and feedback,
								</p>
							</li>
							<li>
								<p>
									other information generated by you when you communicate
									with our customer support.
								</p>
							</li>
						</ul>
					</div>
				</div>
				<div>
					<p>
						This information may be collected by way of paper documents,
						scanned or faxed copies, telephone, emails, verbal interviews, face
						to face interactions, or data received through our Website or App.
						This information will include the information collected by us about
						yourself, and any other individual with whom you deal through us,
						which include counterparties or senders or beneficiaries of funds
						or services.
					</p>
					<p>
						We collect this information through our employees, affiliates, and
						online resources, government agencies, or business partners, or
						consumer reporting agencies, or may be provided by yourself upfront
						when you approach us for applying to our services.
					</p>
					<p>
						We do not process “Sensitive Data” except when it comes to biometric
						data processed for the purpose of uniquely identifying a natural
						person.
					</p>
				</div>
				<div>
					<br /><h1>
						How Do We Use Your Personal Data?
					</h1><br />
				</div>
				<div>
				</div>
				<div>
					<div>
						<p>
							Under the Applicable law, we must always have a lawful basis for
							using personal data. This may be because the data is necessary for
							our provision of services to you and the performance of a contract
							with you, because you have consented to our use of your personal
							data, or because it is in our legitimate business interests to use
							it. Your personal data may be used for one of the following
							purposes:
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									Providing and managing your access to our Website or
									Application;
								</p>
							</li>
							<li>
								<p>
									Supplying our products and services to you;
								</p>
							</li>
							<li>
								<p>
									Communicating with you;
								</p>
							</li>
							<li>
								<p>
									Maintaining our own accounts and records;
								</p>
							</li>
							<li>
								<p>
									Complying with legal and regulatory obligations on our and
									your behalf; and
								</p>
							</li>
							<li>
								<p>
									Analyzing your use of our website to enable us to
									continually improve it and your user experience.
								</p>
							</li>
						</ul>
					</div>
					<div>
					</div>
					<div>
						<br /><h1>
							Option Out Right
						</h1><br />
					</div>
				</div>
				<div>
				</div>
				<div>
					<p>
						We may use your contact details and information related to your service
						preferences for marketing and solicitation purposes to send you offers
						or promotions of us or our affiliates. You may “opt out” or withdraw
						consent to disclosure of information for the purposes described in this
						section at any time by contacting us at&nbsp;
						<a
							href="mailto:dpo@dtsmoney.com"
							target="_blank"
							rel="noreferrer noopener"
						>
							dpo@dtsmoney.com
						</a>
						.
					</p>
				</div>
				<div>
					<br /><h1>
						How Long Will We Keep Your Personal Data?
					</h1><br />
				</div>
				<div>
					<p>
						We will keep your personal data for such a period as is required for us
						to provide services to you, or such a longer period as we may be
						required to process the data to ensure our compliance with all relevant
						laws and regulatory obligations to which we are subject.
					</p>
				</div>
				<div>
					<div>
						<br /><h1>
							How and Where Do We Store Your Personal Data?
						</h1><br />
					</div>
					<div>
						<p>
							Personal data is processed both manually and electronically and may
							be held in a number of different places in line with the operations
							of a typical business. The locations your personal data could be
							stored include, but are not limited to:
							<br />
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									Our databases and CRM;
								</p>
							</li>
							<li>
								<p>
									Emails on computers;
								</p>
							</li>
							<li>
								<p>
									Mobile phones and tablets;
								</p>
							</li>
							<li>
								<p>
									Contact address books;
								</p>
							</li>
							<li>
								<p>
									Business cards;
								</p>
							</li>
							<li>
								<p>
									Notebooks;
								</p>
							</li>
							<li>
								<p>
									Electronic documents on our file servers;
								</p>
							</li>
							<li>
								<p>
									Paper files in our offices;
								</p>
							</li>
							<li>
								<p>
									Cloud back-up and storage.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<p>
							The security of your personal data is important to us, and to
							protect your data we use and maintain appropriate technical and
							organizational measures to protect your personal data and to
							prevent the accidental or unlawful destruction, loss, alteration,
							unauthorized disclosure of or access to personal data transmitted,
							stored or otherwise processed. We have a framework of policies,
							procedures and training to cover information and cyber security,
							confidentiality and data protection.
						</p>
					</div>
				</div>
				<div>
					<br /><h1>
						Sharing and Transfer of Personal Data
					</h1><br />
				</div>
				<div>
					<p>
						We will only disclose personal data to third parties as is necessary to
						provide our services and/or to comply with your instructions. We may
						share your personal data with our affiliates and subsidiaries. We do
						not give or sell your personal information to third parties.
					</p>
				</div>
				<div>
					<p>
						In addition, we may be legally required to share certain personal data,
						which might include yours, if we are involved in legal proceedings or
						complying with legal obligations, a court order, or the instructions of
						a government authority.
					</p>
					<p>
						If any of your personal data is required by a third-party, we will take
						steps to ensure that your personal data is handled safely, securely,
						and in accordance with your rights, our obligations, and the
						third-party’s obligations under the law.
					</p>
				</div>
				<div>
					<div>
						<p>
							As some of our external third parties are based outside the EEA,
							the processing of your personal data may involve a transfer of data
							outside the EEA.
						</p>
					</div>
					<div>
						<p>
							Whenever we transfer your personal data out of the EEA, we ensure a
							similar degree of protection is afforded to it by ensuring at least
							one of the following safeguards is implemented:
						</p>
					</div>
					<div>
						<ul>
							<li>
								<p>
									the country to which we transfer your personal data has
									been deemed to provide an adequate level of protection for
									personal data by the European Commission;
								</p>
							</li>
							<li>
								<p>
									a specific contract approved by the European Commission
									which gives safeguards to the processing of personal data,
									the so called Standard Contractual Clauses.
								</p>
							</li>
						</ul>
					</div>
					<div>
						<br /><h1>
							Confidentiality
						</h1><br />
					</div>
				</div>
				<div>
					<p>
						We maintain physical, electronic, and procedural safeguards to protect
						personal information against loss, theft, and any form of unauthorized
						access. Only those who are authorized employees, agents, or
						representatives that require access to your personal information in
						order to fulfill their job requirements will have access to the
						information. Our obligations will become effective only when the
						information is received by us physically or by its internal systems,
						but not while it is in transmission out of or to our physical
						possession or internal systems; because data exchange over the internet
						or the telephone system or physical mail cannot be guaranteed to be
						perfectly secure, any personal information you submit to us or access
						electronically or over the telephone is done at your own risk, and we
						does not guarantee or warrant the security of information so
						transmitted to us. You should exercise caution to prevent any third
						parties, or parties fraudulently posing as us, from unlawfully
						intercepting your transmissions or causing you to disclose personal
						information.
					</p>
				</div>
				<div>
					<br /><h1>
						Consent
					</h1><br />
				</div>
				<div>
				</div>
				<div>
					<p>
						By using our website, mobile application and related services, you
						signify your acceptance of our privacy policy.
					</p>
					<p>
						This Privacy Statement provides our intentions in regard to your
						personal information collected by us from you. By providing us with
						personal information, we will assume your consent to our collection,
						use and disclosure of such information for the purposes described in
						this Privacy Policy wherever applicable. Please note that your consent
						implies the consent to use your email addresses, postal addresses, and
						other contact resources that you provide to us to communicate with you.
					</p>
					<p>
						Your consent may be given in various ways, such as: Orally,
						electronically or on a document in any form; or implied by voluntarily
						choosing to use our services; by not withdrawing consent to use your
						personal information for any identified purpose; by not using an “opt
						out” option provided for the specified purposes in this statement, if
						any. Such consent may be given by you directly or by your legal
						representative.
					</p>
					<p>
						Withdrawal of your consent will be entirely at your choice at any time,
						and will be effective, except in cases where we are subject to legal,
						contractual, regulatory, governmental, and/ or statutory obligations
						for record maintenance and disclosure; and except in case of
						transactions already initiated which need the use of your personal
						information for fulfillment. Note that in case of such withdrawal of
						consent we may no longer be able to provide certain of our products or
						services which require your personal information.
					</p>
				</div>
				<div>
					<br /><h1>
						Your Rights
					</h1><br />
				</div>
				<div>
					<p>
						Under this Privacy Policy, you have the following rights:
					</p>
				</div>
				<div>
					<div>
					</div>
					<div>
						<ol type="a">
							<li>
								<p>
									The right to be informed about our collection and use of
									your personal data.
								</p>
							</li>
							<li>
								<p>
									The right to access the personal data we hold about you –
									you have the right to obtain confirmation as to whether we
									process personal data about you and certain information
									about how and why we process your personal data.
								</p>
							</li>
							<li>
								<p>
									The right to have your personal data amended or rectified
									if any of your personal data held by us is inaccurate or
									incomplete.
								</p>
							</li>
							<li>
								<p>
									The right to erasure (also known as the right to be
									forgotten), i.e. the right to ask us to delete or otherwise
									dispose of any of your personal data that we have.
								</p>
							</li>
							<li>
								<p>
									The right to restrict the processing of your personal data.
								</p>
							</li>
							<li>
								<p>
									The right to object to us using your personal data for a
									particular purpose or purposes.
								</p>
							</li>
							<li>
								<p>
									The right to data portability. This means that, if you have
									provided personal data to us directly, we are using it with
									your consent or for the performance of a contract or the
									provision of services, and that data is processed using
									automated means, you can ask us for a copy of that personal
									data to re-use with another service or business.
								</p>
							</li>
							<li>
								<p>
									The right to file a complaint. If you reside in an EEA
									Member State, you have the right to make a complaint about
									the way we process your personal data to the supervisory
									authority in the EEA Member State of your habitual
									residence, place of work or place of the alleged
									infringement.
								</p>
							</li>
							<li>
								<p>
									Rights relating to automated decision-making and profiling.
									We do not use your personal data in this way.
								</p>
							</li>
						</ol>
					</div>
					<div>
					</div>
					<div>
						<p>
							For more information about our use of your personal data or
							exercising your rights as outlined above, or in the event that you
							wish to make a complaint, please contact us at&nbsp;
							<a
								href="mailto:dpo@dtsmoney.com"
								target="_blank"
								rel="noreferrer noopener"
							>
								dpo@dtsmoney.com
							</a>
						</p>
					</div>
				</div>
				<div>
					<br /><h1>
						Cookies
					</h1><br />
				</div>
				<div>
					<p>
						This section applies to our Website and mobile Application.
					</p>
					<p>
						By accessing the website, you agree that this section will apply
						whenever you access the website on any device.
					</p>
					<p>
						Any changes to this policy will be posted here. We reserve the right to
						vary this section from time to time and such changes shall become
						effective as soon as they are posted. Your continued use of the website
						constitutes your agreement to all such changes.
					</p>
					<p>
						This policy explains how cookies are used on our website in general –
						and, below, how you can control the cookies that may be used on these
						sites (not all of them are used on every site).
					</p>
				</div>
				<div>
					<br /><h3>
						What are cookies and how do we use them?
					</h3><br />
				</div>
				<div>
					<p>
						Cookies are small text files stored on your computer by your browser.
						They’re used for many things, such as remembering whether you’ve
						visited the site before, so that you remain logged in – or to help us
						work out how many new website visitors we get each month. They contain
						information about the use of your computer but don’t include personal
						information about you (they don’t store your name, for instance).
					</p>
					<p>
						We use cookies to provide the services and features offered on our
						websites, and to continually enhance the user experience.
					</p>
					<p>
						We may also use Google Analytics to track and analyze our site traffic
						in order to improve our website functionality by collecting data
						regarding how, when and where people use or access our website. Google
						Analytics does not collect personally identifiable data.
					</p>
				</div>
				<div>
					<br /><h3>
						How can you manage cookies?
					</h3><br />
				</div>
				<div>
					<div>
						<ul>
							<li>
								<strong>
									Removing cookies from your device
								</strong>
							</li>
						</ul>
					</div>
					<div>
						<p>
							You can delete all cookies that are already on your device by
							clearing the browsing history of your browser. This will remove all
							cookies from all websites you have visited. Be aware though that
							you may also lose some saved information (e.g. saved login details,
							site preferences).
						</p>
					</div>
					<div>
						<ul>
							<li>
								<strong>
									Managing site-specific cookies
								</strong>
							</li>
						</ul>
					</div>
					<div>
						<p>
							For more detailed control over site-specific cookies, check the
							privacy and cookie settings in your preferred browser.
						</p>
					</div>
					<div>
						<ul>
							<li>
								<strong>
									Blocking cookies
								</strong>
							</li>
						</ul>
					</div>
				</div>
				<div>
					<p>
						You can set most modern browsers to prevent any cookies being
						placed on your device, but you may then have to manually adjust
						some preferences every time you visit a site/page. And some
						services and functionalities may not work properly at all (e.g.
						profile logging-in).
					</p>
				</div>
				<div>
					<ul>
						<li>
							<strong>
								Managing our analytics cookies
							</strong>
						</li>
					</ul>
				</div>
				<div>
					<p>
						You can manage your preferences concerning cookies when you enter
						our Website.
					</p>
				</div>
				<div>
					<br /><h1>
						Changes to this Privacy Policy
					</h1><br />
				</div>
				<div>
					<p>
						We may change this Privacy Notice from time to time. This may be
						necessary, for example, if the law changes, or if we change our
						business in a way that affects personal data protection.
					</p>
					<p>
						Any changes will be posted on our website and you will be deemed to
						have accepted the terms of the Privacy Policy on your first use of our
						website following the alterations.
					</p>
					<p>
						This Policy was last updated on 26th October 2022.
					</p>
				</div>


			</Segment>
		</Container >
	);
}

export default PrivacyPolicyDTS;
