import React, { useCallback, useEffect, useState } from 'react';
import { RootState } from '@/rootReducer';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './Profile.css';
import { Button, Card, Container, Divider, Grid, Header, List, Popup, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import CrossLink from '../Security/CrossLink';
import Loading from '../../Shared/Loading';
import { formatDateWithPattern, formatDateWithPatternIgnoreTimezone, formatSimpleDateTime } from '@helpers/date';
import { logout, updateUser } from '@features/user/userSlice';
import { UserType } from '@features/user/types';
import { changePhone } from '@features/security/slice';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import EditMobile from './Profile/EditMobile';
import OpenBusinnes from './Profile/OpenBusiness';
import KYCForm from '@/components/KYC/KYCForm';
import AddEmployeeCode from '@/components/Business/AddEmployeeCode';
import { getMyCompanies } from '@/features/employee/slice';
import { Company } from '@/features/employee/types';
import { isVerifiedForPartner } from '@/features/identity/slice';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

const Profile = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [requiredTier, setRequiredTier] = useState<number>(1);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);
	const [openKyc, setOpenKyc] = useState<boolean>(false);
	const [firstOpen, setFirstOpen] = React.useState(false);
	const history = useHistory();
	const [countries] = useState(getNames('en'));
	const [editPhone, setEditPhone] = useState<boolean>(false);
	const [addCode, setAddCode] = useState<boolean>(false);
	const [myCompanies, setMyCompanies] = useState<Array<Company>>([]);
	const [partnerStatus, setPartnerStatus] = useState<boolean>(false);
	const dispatch = useDispatch();

	const { user, status } = useSelector(
		(state: RootState) => state.user
	);

	const { businessEnabled, businessRegistrationEnabled, allowMultipleBusinessRegistration } = useSelector(
		(state: RootState) => state.status
	);

	const goToBusiness = async () => {
		try {
			await dispatch(logout());
			return history.push('/auth/login?mode=' + UserType.BUSINESS);
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	const onCloseEditMobileModal = async (mobileNumber?: string) => {
		setEditPhone(false);
		if (mobileNumber) {
			try {
				const recaptchaToken = await getRecaptureToken(); 
				await changePhone(mobileNumber, recaptchaToken);
				dispatch(updateUser({ mobileNumber }));
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
	};

	const onCloseAddEmployeeCodeModal = async (code?: string) => {
		setAddCode(false);
		if (code) {
			showSuccessNotification(t('swal.BUSINESS_EMPLOYEE_CODE_TEXT'));
		}
	};

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		setKycTierVerified((kycTier >= requiredTier) || isKycTierRevoked);
	}, [user, requiredTier]);

	useEffect(() => {
		if (!user) return;
		setRequiredTier(user.type === UserType.INDIVIDUAL ? 1 : 2);
	}, [user]);

	useEffect(() => {
		const fetch = async () => {
			const response = await getMyCompanies();
			setMyCompanies(response);
		};
		fetch();
	}, []);

	const format = str => str.replace(/(^\w|\s\w)/g, m => m.toUpperCase());

	const getPartnerStatus = useCallback(() => {
		const get = async () => {
			const status = await isVerifiedForPartner();
			setPartnerStatus(status);
		};
		get();
	}, []);

	useEffect(() => {
		getPartnerStatus();
	}, [getPartnerStatus]);

	const onKYCclick = (e) => {
		e.preventDefault();
		if (partnerStatus && user.type !== UserType.BUSINESS) {
			history.push('/wallet/transfer');
		} else {
			setOpenKyc(true);
		}
	};

	if (!user) {
		return <Loading />;
	}
	return (
		<Container>
			{openKyc && <KYCForm open={openKyc} setOpen={setOpenKyc} />}
			<OpenBusinnes countries={countries} open={firstOpen} onClose={() => { setFirstOpen(false); }} />
			<EditMobile
				onClose={onCloseEditMobileModal}
				open={editPhone}
			/>
			<AddEmployeeCode
				onClose={onCloseAddEmployeeCodeModal}
				open={addCode}
			/>
			<Segment className="profilesegment" padded>
				<Grid stackable columns={2}  >
					<Grid.Column className="userdatacolumn">
						<Header className="profileHeader" >{t('profile.header')}</Header>
						<Card id="profile-card">
							<Card.Content>
								{user && user.type === UserType.INDIVIDUAL && <Card.Header>{format(user.firstName)} {format(user.lastName)}</Card.Header>}
								{user && user.type === UserType.BUSINESS && <Card.Header>{user.companyName}</Card.Header>}
							</Card.Content>
							<Divider hidden />
							<Card.Content>
								<List>
									<List.Item >
										<List.Content floated='right' className={user.disabled ? 'color-red' : 'color-green'}>{user.disabled ? t('profile.activedata.inactive') : t('profile.activedata.active')}</List.Content>
										<List.Content>{t('profile.data.status')}</List.Content>
									</List.Item>
									<List.Item>
										<List.Content floated='right'>{user.clientId}</List.Content>
										<List.Content>{t('profile.data.userid')}</List.Content>
									</List.Item>
									<List.Item>
										<List.Content floated='right' className={user.emailConfirmed ? 'color-green' : 'color-red'}>{user.emailConfirmed ? t('profile.activedata.verified') : t('profile.activedata.notverified')}</List.Content>
										<List.Content>{t('profile.data.email')}</List.Content>
									</List.Item>
									<List.Item>
										<Popup trigger={<List.Content floated='right' className={user.twoFactorAuthEnabled ? 'color-green' : 'color-red'}>{user.twoFactorAuthEnabled ? t('profile.activedata.active') : t('profile.activedata.inactive')}</List.Content>} hoverable>
											<CrossLink />
										</Popup>
										<List.Content>{t('profile.data.2fa')}</List.Content>
									</List.Item>
									{/* 									<List.Item>
										<List.Content floated='right' className={user.ipSettings ? 'color-green' : 'color-red'}>{user.ipSettings ? t('profile.activedata.active') : t('profile.activedata.inactive')}</List.Content>
										<List.Content>{t('profile.data.iprestriction')}</List.Content>
									</List.Item> */}
									<List.Item>
										<List.Content floated='right'>{formatSimpleDateTime(user.createdDate)}</List.Content>
										<List.Content>{t('profile.data.registrationdate')}</List.Content>
									</List.Item>
									<List.Item>
										<List.Content floated='right'>{formatSimpleDateTime(user.previousLoginDate)}</List.Content>
										<List.Content>{t('profile.data.previouslogin')}</List.Content>
									</List.Item>
								</List>
							</Card.Content>
						</Card>
					</Grid.Column>
					<Grid.Column className="profiledatacolumn">
						<Segment className="usersegment" basic>
							<List id="profile-data">
								{user && user.type === UserType.INDIVIDUAL && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.name')}</List.Header>
											<List.Description>{format(user.firstName)} {format(user.lastName)}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.birthdate')}</List.Header>
											<List.Description>{formatDateWithPatternIgnoreTimezone(user.birthDate, 'dd/MM/yyyy')}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.country')}</List.Header>
											<List.Description>{countries[alpha3ToAlpha2(user.addressCountry)]}</List.Description>
										</List.Content>
									</List.Item>
									<Divider /></div>}
								{user && user.type === UserType.BUSINESS && <div>
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.businessOwner')}</List.Header>
											<List.Description>{user.firstName} {user.lastName}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.regNumber')}</List.Header>
											<List.Description>{user.companyRegNumber}</List.Description>
										</List.Content>
									</List.Item>
									<Divider />
									<List.Item>
										<List.Content>
											<List.Header className="color-grey">{t('profile.data.address')}</List.Header>
											<List.Description>{user.companyAddress}</List.Description>
										</List.Content>
									</List.Item>
									<Divider /></div>}
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">{t('profile.data.email')}</List.Header>
										<List.Description>{user.email}</List.Description>
									</List.Content>
								</List.Item>
								<Divider />
								<List.Item>
									<List.Content>
										<List.Header className="color-grey">{t('profile.data.phonenumber')}</List.Header>
										<List.Description>{user.mobileNumber}
											{/* TODO: Enable back when card phone number update will work */}
											{/* {status && !status.confirmPhoneRequired && <Button onClick={() => { setEditPhone(true); }} size="small" primary basic icon='edit' className="link-button" />} */}
										</List.Description>
									</List.Content>
								</List.Item>
								{user && user.plan.upgradeAllowed && user.type === UserType.INDIVIDUAL && <List.Item>
									<List.Content>
										<List.Header className="color-grey">{t('plans.plan')}</List.Header>
										<List.Description>{user.plan.currentUserLevel.name} - <a className="upgradeLink" onClick={() => history.push('/wallet/upgrade-plan')} >{t('plans.upgrade')}</a></List.Description>
									</List.Content>
								</List.Item>}
								<div className='businessAccountItems'>
									<Divider />
									{businessEnabled && user && user.type === UserType.INDIVIDUAL && <div>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey"><div className='businessProfileLBL'>{!myCompanies.length ? t('profile.data.businessEmployeeCode') : t('profile.data.linkedCompanies')}</div></List.Header>
												{myCompanies.length > 0 && myCompanies.map(item => <List.Description className="myCompanies" key={item.userId}>
													<p className="companyName">{item.company}</p>
													<p>-</p>
													<p className="capitalize">{' ' + item.role.toLowerCase()}</p>
													<p>{item.approved ? '' : t('profile.data.waiting')}</p>
												</List.Description>)}
												<List.Description><a className="profileLink" onClick={() => setAddCode(true)}>{t('profile.data.addEmployeeCode')}</a></List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</div>}
									{user && user.type === UserType.INDIVIDUAL && <div>
										<List.Item>
											<List.Content>
												<List.Header className="color-grey"><div className='businessProfileLBL'>{t('profile.data.individual')}</div></List.Header>
												<List.Description>
													{kycTierVerified && <div className="color-green verification-label">{t('profile.activedata.verified')}</div>}
													{!kycTierVerified && <a onClick={onKYCclick} className="profileLink">{t('kyc.button.verify')}</a>}
												</List.Description>
											</List.Content>
										</List.Item>
										<Divider />
									</div>}
									{businessEnabled && user && user.type === UserType.INDIVIDUAL && <List.Item>
										<List.Content>
											<List.Header className="color-grey"><div className='businessProfileLBL'>{t('profile.data.businessAccount')}</div></List.Header>
											{(!user.hasBusiness || allowMultipleBusinessRegistration) && kycTierVerified && businessRegistrationEnabled && <List.Description><a className="profileLink" onClick={() => setFirstOpen(true)}>{t('profile.data.openBusiness')}</a></List.Description>}
										</List.Content>
									</List.Item>}
								</div>
							</List>
						</Segment>
					</Grid.Column>
				</Grid>
			</Segment>
			<Divider hidden />
		</Container >
	);
};

export default Profile;
