import React, { useEffect, useState } from 'react';
import { Container } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Token } from '@/features/tokens/types';
import { connect, getTokens, subscribe, unsubscribe } from '@/features/tokens/slice';
import { trimDecimals } from '@/helpers/round';
import PendingTransactionAccordion from './Components/Transactions/PendingTransactionAccordion';
import UserTransactionAccordion from './Components/Transactions/UserTransactionAccordion';
import TokenList from './Components/TokenList';

import './Tokens.css';


export const disableSell = (token: Token) => (!token.availableHoldings || trimDecimals(token.availableHoldings, token.precision) <= 0 || !token.sellEnabled);
export const tokenDescription = (token: Token) => token.name + ' (' + token.symbol + ' ' + trimDecimals(token?.availableHoldings, token.precision) + ')';

const Tokens = (): React.ReactElement => {

	const dispatch = useDispatch();
	const [tokenAction, setTokenAction] = useState(false);
	const { error } = useSelector((state: RootState) => state.tokens);

	useEffect(() => {
		dispatch(getTokens());
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			dispatch(getTokens());
		}, 30000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);




	if (error) return <div>Error: {error}</div>;


	return (
		<div>
			<Container id='tokens'>
				<TokenList setTokenAction={setTokenAction} tokenAction={tokenAction} />
				{!tokenAction && <PendingTransactionAccordion />}
				{!tokenAction && <UserTransactionAccordion />}
			</Container>
		</div>);
};

export default Tokens;
