import * as React from "react";

function SvgCheckCircleIcon(props) {
  return (
    <svg
      width={30}
      height={30}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={15} cy={15} r={12.5} fill="#00841D" />
      <path
        d="M9.375 15l3.75 3.75 8.125-8.125"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgCheckCircleIcon;
