import * as React from "react";

function SvgVectordown(props) {
  return (
    <svg
      width={13}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.083 7.5L6.5 12.917 11.916 7.5"
        stroke="#586879"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgVectordown;
