import { showInfoNotification } from '@/features/swal/slice';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const fileTypes = [
	'image/jpg',
	'image/jpeg',
	'image/png',
	'application/pdf',
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
];

const MAX_FILE_SIZE = 5000000;
const MAX_AMOUNT = 5000;

const useFileUpload = (maxFileCount: number) => {

	const { t } = useTranslation('translations');
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [, updateState] = useState({});
	const forceUpdate = useCallback(() => updateState({}), []);

	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > maxFileCount) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (files[i].size > MAX_FILE_SIZE) {
				showInfoNotification(t('swal.FILE_SIZE_TOO_LARGE', { fileName: files[i].name }));
				continue;
			}

			if (maxFileCount === 1) { //overwrite file
				if (fileTypes.indexOf(files[0].type) != -1) {
					setSelectedFiles([files[0]]);
				}
			}
			else {
				if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < maxFileCount) {
					selectedFiles.push(files[i]);
					setSelectedFiles(selectedFiles);
				}
			}
		}
		forceUpdate();
	};

	const isValidFiles = (amount: number): boolean => {
		if (amount >= MAX_AMOUNT) {
			return selectedFiles.length === 1;
		} else {
			return true;
		}
	};


	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
		forceUpdate();
	};


	const removeAllFilesFromState = () => {
		setSelectedFiles([]);
	};

	return {
		selectedFiles,
		uploadFiles,
		deleteAttachment,
		removeAllFilesFromState,
		setSelectedFiles,
		isValidFiles
	} as const;

};

export default useFileUpload;
