import React, { useCallback, useEffect, useState } from 'react';
import { Container, Header } from 'semantic-ui-react';
import Profile from './MyAccount/Profile'; 
import CardFees from './MyAccount/CardFees';
import EBankingFees from './MyAccount/EBankingFees';
import { getProcessFees } from '@features/fees/slice';
import '../Dashboard/MyAccount/ProfileTable.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';

const Account = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const [processFees, setProcessFees] = useState();
	const { user } = useSelector(
		(state: RootState) => state.user
	);
	const getFees = useCallback(() => {
		const get = async () => {
			try {
				const turnovers = await getProcessFees();
				setProcessFees(turnovers);
			}
			catch (e) {
				console.log(e);
			}
		};
		get();
	}, []);

	useEffect(() => {
		getFees();
	}, []);

	return (
		<Container>
			<Header className='myAccountHeader'>{t('navbar.account.details')}</Header>
			<Profile />
			{/* {(user && user.type) !== 'EMPLOYEE' && <> 
				<CardFees processes={processFees} />
				<EBankingFees processes={processFees} />
			</>
			} */}

		</Container>
	);
};

export default Account;
