import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import request from '../../services/request';
import { AppThunk } from '../../store';
import { Faq, FaqStore } from './types';

const initialState: FaqStore = {
	loading: false,
	list: [],
	error: null
};

const slice = createSlice({
	name: 'faq',
	initialState,
	reducers: {
		setFaqs(state, action: PayloadAction<Array<Faq>>) {
			state.list = action.payload;
		},
	}
});

export const { setFaqs } = slice.actions;

export const getFaqs = (language: string, countryCode: string): AppThunk => {
	return async dispatch => {
		try {
			let response;
			if (countryCode) {
				response = await request.get(`/api/faq/list/${language}/${countryCode}`);
			} else {
				response = await request.get(`/api/faq/list/${language}`);
			}
			const { data } = response;
			dispatch(setFaqs(data));
			return data;
		} catch (e) {
			console.log(e);
		}
	};
};

export default slice.reducer;
