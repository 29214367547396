import React, { useCallback, useEffect, useState } from 'react';
import './Earn.css'; 
import { Dimmer, Divider, Loader } from 'semantic-ui-react';
import VaultsList from './Components/VaultsList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getVaults } from '@/features/earn/slice';
import { showErrorNotification } from '@/features/swal/slice';
import TransactionStatements from '../Banking/TransactionStatements';
import IAcceptVaultModal from './Components/IAcceptVaultModal';
import { useCookies } from 'react-cookie';
import { Agents } from '@/helpers/globalTypes';


const Earn = (): React.ReactElement => { 
	const dispatch = useDispatch();
	const [cookies, setCookie] = useCookies(['earnAccept']);
	const { agent } = useSelector(
		(state: RootState) => state.status
	);
	const [showIAccept, setShowIAccept] = useState<boolean>(agent === Agents.DTSUS ? false : cookies.earnAccept === 'true' ? false : true); 
	const { currentVault } = useSelector(
		(state: RootState) => state.tabs
	);

	const { list, loading } = useSelector(
		(state: RootState) => state.vaults
	);

	const fetchData = useCallback(() => {
		const get = async () => {
			try {
				await dispatch(getVaults());
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	}, [dispatch]);

	useEffect(() => {
		fetchData();
	}, [dispatch]);

	return (
		<>
			<IAcceptVaultModal 
				open={showIAccept}
				onClose={() => {setShowIAccept(false); setCookie('earnAccept', true);}}
			/>
			<Dimmer active={!!loading} inverted>
				<Loader />
			</Dimmer>
			{list.length > 0 && <VaultsList vaults={list} />}
			{currentVault && <Divider hidden />}
			{currentVault && <TransactionStatements account={null} type='VAULT' />}
		</>
	);
};

export default Earn;
