import React, { useEffect, useState } from 'react';
import { Container, Dimmer, Icon, Loader, Modal, Segment } from 'semantic-ui-react';
import { getUserAttachmentImage } from '@features/files/slice';
import './ShowDocument.css';

interface Props {
	attachmentKey: any,
	employeeId: any,
	name: any
}

const ShowDocument = ({ attachmentKey, employeeId, name }: Props): React.ReactElement => {
	const [open, setOpen] = React.useState(false);
	const [loading, setLoading] = useState(false);

	const getImage = async (attachmentKey) => {
		setLoading(true);
		const img = await getUserAttachmentImage(employeeId, attachmentKey);
		loadImage(img).then(img => document.getElementById(attachmentKey).appendChild(img as Node)).finally(() => setLoading(false));
	};

	function loadImage(url) {
		return new Promise((resolve, reject) => {
			const img = new Image();
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			img.addEventListener('load', e => resolve(img));
			img.addEventListener('error', () => {
				reject(new Error(`Failed to load image's URL: ${url}`));
			});
			img.src = url;
		});
	}

	useEffect(() => {
		if(!open) return;
		getImage(attachmentKey);
	},[open]);

	return (
		<Modal
			key={attachmentKey}
			id='showEmployeeDocumentModal'
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			closeIcon={<Icon className='inside' name='close' color='black' />}
			trigger={<a>{name}</a>}
		>
			<Modal.Content>
				<Container fluid>
					<Segment basic>
						<Dimmer active={loading} inverted>
							<Loader />
						</Dimmer>
						<div id={attachmentKey} className="employee-document"></div>
					</Segment>
				</Container>
			</Modal.Content>
		</Modal>
	);
};

export default ShowDocument;
