import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Accordion, Item } from 'semantic-ui-react';
import { DeviceInfo, SwitchBody, User, UserType } from '@/features/user/types';
import { showErrorNotification } from '@/features/swal/slice';
import { useDispatch } from 'react-redux';
import { getDeviceInfo } from '@/helpers/browser';
import { switchUser } from '@/features/user/userSlice';
import { useHistory } from 'react-router-dom';
import { Briefcase, VectorDown, BriefcaseMobile } from '@services/icons';
import './Navbar.css';

type Props = {
	user: User,
	isMobile?: boolean
}

const SwitchMenuItem = ({ user, isMobile }: Props): React.ReactElement => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [deviceInfo, setDeviceInfo] = useState<DeviceInfo>(null);
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const SWITCH_REPRESENTATION_ACTION = {
		type: 'SWITCH_REPRESENTATION'
	};
	
	useEffect(() => {
		async function getBrowserInfo() {
			setDeviceInfo(await getDeviceInfo());
		}
		getBrowserInfo();
	}, []);

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};


	const onSwitchUser = async (clientId: string) => {
		try {
			const payload: SwitchBody = { clientId, deviceInfo: deviceInfo };

			await dispatch(switchUser(payload));
			dispatch(SWITCH_REPRESENTATION_ACTION);
			history.push('/wallet');
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	if (user?.switchOptions?.length === 0) { return <div />; }

	return (isMobile ?
		<Menu.Item className='accountMenuItem navbarItemMobile switch-item'>
			<Accordion id='businessSwitchAccordion' fluid >
				<Accordion.Title
					index={1}
					onClick={handleClick}
					className='accountAccordionTitle'
				>
					<div className='accountMobileTitleDiv'>
						<BriefcaseMobile className='mobileNavbarIcon' />
						<div className='accountTitleDiv'>{user.type === UserType.BUSINESS ? user.companyName : user.firstName}</div>
						<VectorDown className='myAccountMobileVector' />
					</div>
				</Accordion.Title>
				<Accordion.Content active={activeIndex === 1}>
					{user.switchOptions.map(item => { return <Item key={item.clientId} className='accordionAccountItem' onClick={() => onSwitchUser(item.clientId)}>{`${item.name} (${item.clientId})`}</ Item>; })}
				</Accordion.Content>
			</Accordion>
		</Menu.Item>
		:
		<Dropdown item classname='item-navbarDropdown' trigger={(
			<Menu.Item className="navbaritem item-navbarDropdown" >
				<Briefcase width="24px" height="24px" />
				{user.type === UserType.BUSINESS ? user.companyName : user.firstName}
			</Menu.Item>)}>
			<Dropdown.Menu>
				{user.switchOptions.map(item => { return <Dropdown.Item key={item.clientId} onClick={() => onSwitchUser(item.clientId)}>{`${item.name} (${item.clientId})`}</Dropdown.Item>; })}
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default SwitchMenuItem;

