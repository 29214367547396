import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const CrossLink = (): React.ReactElement => {
    const { t } = useTranslation('translations');
    return (
        <div>
            <p>{t('profile.crosslink.header')}</p>
            <Button primary basic as={ Link } to='/wallet/security'>{t('profile.crosslink.button')}</Button>
        </div>
    );
};

export default CrossLink;
