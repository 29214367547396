import React from 'react';
import { Button, Grid, Radio } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface Props {
	type: string,
	onSelect: (event, data) => void,
	onContinue: () => void
}

const SelectDocumentType = ({ type, onSelect, onContinue }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	return (
		<Grid>
			<Grid.Row><h2>{t('employee.verification.form.document_type.header')}</h2></Grid.Row>
			<Grid.Row>{t('employee.verification.form.document_type.description')}</Grid.Row>
			<Grid.Row>
				<Radio value='ID_CARD' label={t('employee.verification.form.document_type.id_card')} checked={type === 'ID_CARD'} onChange={(event, data) => onSelect(event, data)}/>
			</Grid.Row>
			<Grid.Row>
				<Radio value='PASSPORT' label={t('employee.verification.form.document_type.passport')} checked={type === 'PASSPORT'} onChange={(event, data) => onSelect(event, data)}/>
			</Grid.Row>
			<Grid.Row>
				<Button className='continue-button' disabled={!type} primary size='large' onClick={() => onContinue()}>{t('employee.verification.form.document_type.button')}</Button>
			</Grid.Row>
		</Grid>
	);
};

export default SelectDocumentType;
