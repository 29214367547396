import React, { useEffect, useState } from 'react';
import { Accordion, Button, Grid, Icon, Image, List, Table } from 'semantic-ui-react';
import PlusOutline from '@icons/PlusOutline';
import NumberFormat from 'react-number-format';
import { changeRole, getEmployeeInitials, unlinkCard, unlinkEmployee } from '@/features/employee/slice';
import { Employee } from '@/features/employee/types';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import EditRole from './EditRole';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@helpers/globalTypes';

interface EmployeeCardProps {
	card: any;
	removeCard: (cardId: number) => void;
}

export function EmployeeCard({ card, removeCard }: EmployeeCardProps): React.ReactElement {
	const { t } = useTranslation('translations');
	return (
		<Table.Row className='employeeCardRow'>
			<Table.Cell>
				<div className='microCardCell'>
					<Image className="microCard" src={card.logo} />{card.name}
				</div>
			</Table.Cell>
			<Table.Cell><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${card.currency} `} value={card.availableBalance} /></Table.Cell>
			<Table.Cell><Button onClick={() => removeCard(card.cardId)} className='removeBTN' content={t('business.employees.remove')} /></Table.Cell>
		</Table.Row>
	);
}

interface EmployeeProps {
	employee: Employee,
	assingCardForEmployee: (employee: Employee) => void;
}
export default function ActiveEmployee({ employee, assingCardForEmployee }: EmployeeProps): React.ReactElement {
	const [activeIndex, setActiveIndex] = useState<number>(0);
	const [initials, setInititals] = useState<string>(null);
	const [editRole, setEditRole] = useState<boolean>(false);
	const { t } = useTranslation('translations');
	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const handleClick = (e, titleProps) => {
		const { index } = titleProps;
		const newIndex = activeIndex === index ? -1 : index;

		setActiveIndex(newIndex);
	};

	useEffect(() => {
		setInititals(getEmployeeInitials(employee));
	}, [employee]);

	const unlink = async () => {
		try {
			await unlinkEmployee(employee);
			showSuccessNotification(t('swal.EMPLOYEE_UNLINKED'));
		}
		catch (e) {
			showErrorNotification(e);
		}
	};

	const onCloseEditRoleModal = async (role?: string) => {
		setEditRole(false);
		if (role) {
			try {
				await changeRole(employee, role);
			}
			catch (e) {
				showErrorNotification(e);
			}
		}
	};

	const onCardRemove = async (cardId: number) => {
		try {
			await unlinkCard(employee.employeeId, cardId);
			showSuccessNotification(t('swal.CARD_UNLINKED'));
		} catch (e) {
			showErrorNotification(e);
		}
	};

	return (
		<div>
			<EditRole
				onClose={onCloseEditRoleModal}
				open={editRole}
				role={employee.role}
			/>

			<Accordion id='employeeAccordion' fluid >
				<Accordion.Title
					index={1}
					onClick={handleClick}
				>

					<Grid container columns={1}>
						<Grid.Row columns={1}>
							<div className='employeeName' data-letters={initials}>{employee.firstName + ' ' + employee.lastName}</div>
							<Icon className='accordionOpenIcon' name='chevron down' />
						</Grid.Row>
					</Grid>


				</Accordion.Title>
				<Accordion.Content active={activeIndex === 1}>
					<Grid container columns={4}>
						<Grid.Row only="computer tablet" columns={status.source === Agents.DTSCB ? 4 : 3}>
							<Grid.Column>
								<List className='employeeDataList'>
									<List.Item>
										<List.Header>{t('business.employees.email')}</List.Header>
										<span>{employee.email}</span>
									</List.Item>
								</List>
							</Grid.Column>
							<Grid.Column>
								<List>
									<List.Item>
										<List.Header>{t('business.employees.role')}</List.Header>
										<Grid.Row columns={1}>
											<span>{employee.role}</span>
											<Button onClick={() => setEditRole(true)} size="large" primary basic icon='edit' id="edit-role" />
										</Grid.Row>
									</List.Item>
								</List>
							</Grid.Column>
							{status.source === Agents.DTSCB && <Grid.Column>
								<List>
									<List.Item>
										<List.Header>{t('business.employees.employeeNumber')}</List.Header>
										<Grid.Row columns={1}>
											<span>{employee.employeeNumber}</span>
										</Grid.Row>
									</List.Item>
								</List>
							</Grid.Column>
							}
							<Grid.Column>
								<List>
									<List.Item>
										<List.Header>{t('business.employees.code')}</List.Header>
										<span>{employee.referenceCode}</span>
									</List.Item>
								</List>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row only="mobile">
							<List className='employeeDataList'>
								<List.Item>
									<List.Header>{t('business.employees.email')}</List.Header>
									<span>{employee.email}</span>
								</List.Item>
							</List>
						</Grid.Row>
						<Grid.Row only="mobile">
							<List>
								<List.Item>
									<List.Header>{t('business.employees.role')}</List.Header>
									<Grid.Row columns={1}>
										<span>{employee.role}</span>
										<Button onClick={() => setEditRole(true)} size="large" primary basic icon='edit' id="edit-role" />
									</Grid.Row>
								</List.Item>
							</List>
						</Grid.Row>
						{status.source === Agents.DTSCB && <Grid.Row only="mobile">
							<List>
								<List.Item>
									<List.Header>{t('business.employees.employeeNumber')}</List.Header>
									<span>{employee.employeeNumber}</span>
								</List.Item>
							</List>
						</Grid.Row>}
						<Grid.Row only="mobile">
							<List>
								<List.Item>
									<List.Header>{t('business.employees.code')}</List.Header>
									<span>{employee.referenceCode}</span>
								</List.Item>
							</List>
						</Grid.Row>
						<Grid.Row columns={1}>
							<Grid.Column width={16}>
								<List>
									<List.Item>
										<List.Header>{t('business.employees.employeeCards')}</List.Header>
										<Table className='employeeCardTable' basic='very'>
											<Table.Body>
												{employee.linkedCards.length > 0 && employee.linkedCards.map(card => <EmployeeCard removeCard={onCardRemove} key={card.cardId} card={card} />)}
												{employee.linkedCards.length === 0 && <Table.Row>
													{t('business.employees.noLinkedCards')}
												</Table.Row>}
												<Table.Row>
													<Table.Cell>
														<Button onClick={() => assingCardForEmployee(employee)} className='assignCardBTN' primary icon='plus circle'><PlusOutline className='plusOutlineIcon' />{t('business.employees.assignCard')}</Button>
													</Table.Cell>
												</Table.Row>
											</Table.Body>
										</Table>
									</List.Item>
								</List>
							</Grid.Column>
						</Grid.Row>
						<Grid.Row columns={1}>
							<Grid.Column>
								<Button onClick={unlink} icon="trash alternate outline" primary content={t('business.employees.unlinkEmployee')} />
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Accordion.Content>
			</Accordion>
		</div>
	);
}



