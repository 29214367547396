import React, { useState, useEffect } from 'react';
import { Grid, Segment, Header, Divider, Menu } from 'semantic-ui-react';
import { CardType } from '@features/card/types';
import CardDesign from './CardDesign';
import CardDelivery from './CardDelivery';
import CardConfirmation from './CardConfirmation';
import CardCreated from './CardCreated';
import { runOperation } from '@features/operations/slice';
import { showErrorNotification } from '@features/swal/slice';
import { useTranslation } from 'react-i18next';

interface Props {
	type: CardType,
	options: any[],
	onStart: (disable: boolean) => void
}

type Material = 'PLASTIC' | 'METAL';

enum Step {
	DESIGN = 1, /* SECURITY = 2, */ ADDRESS = 2, CONFIRMATION = 3
}

function NewCard({ type, options, onStart }: Props): React.ReactElement {
	const [step, setStep] = useState<Step>(Step.DESIGN);
	const [activeItem, setActiveItem] = useState<Material>('PLASTIC');
	const [operation, setOperation] = useState<any>(null);
	const [payment, setPayment] = useState<any>(null);
	const [result, setResult] = useState<any>(null);
	const [operations, setOperations] = useState<any[]>([]);
	const [deliveryAddress, setDeliveryAddress] = useState<any>({});
	const [availableItems, setAvailableItems] = useState<Material[]>([]);
	const { t } = useTranslation('translations');

	useEffect(() => { payment && submit(); }, [payment]);

	useEffect(() => {
		if (!options) return;
		if (type === CardType.VIRTUAL) return setOperations(options);
		setOperations(options.filter(o => activeItem === 'PLASTIC' ? !o.processProperties.metal : o.processProperties.metal));
	}, [options, type, activeItem]);

	useEffect(() => {
		const available = [];
		const metalAvailable = options.find(option => option.processProperties.metal);
		if (metalAvailable) {
			available.push('METAL');
		}
		const plasticAvailable = options.find(option => !option.processProperties.metal);
		if (plasticAvailable) {
			available.push('PLASTIC');
			setActiveItem('PLASTIC');
		} else {
			setActiveItem('METAL');
		}
		setAvailableItems(available);
	}, [options]);

	const getItemName = (item) => {
		if (item == 'METAL') {
			return t('cards.newCard.yourcard.metal');
		} else {
			return t('cards.newCard.yourcard.plastic');
		}
	};


	const submit = async () => {
		const payload: any = {
			feeAccountId: payment.account,
			smsPhoneNumber: payment.phoneNumber
		};
		if (payment.employeeId !== null) {
			payload.employeeId = payment.employeeId;
		}

		const add = `${deliveryAddress.address??''} ${deliveryAddress.address2??''}`;
		if (type === CardType.PHYSICAL) {
			payload.deliveryAddress = { city: deliveryAddress.city, country: deliveryAddress.country, address: add.trim(), zip: deliveryAddress.postalCode };
			if (payment?.orderFee?.proc) {
				payload.shipProc = payment.orderFee.proc;
			}
		}
		try {
			const result = await runOperation(operation?.proc, payload);
			setResult(result);
		}
		catch (e) {
			showErrorNotification(e);
		}
		finally {
			payment.setSubmitting(false);
		}
	};

	const onContinue = (step: Step | null, payload: any) => {
		const { action, data } = payload;

		switch (action) {
			case Step.DESIGN:
				setOperation(data);
				onStart(true);
				break;
			case Step.ADDRESS:
				setDeliveryAddress(data);
				break;
			case Step.CONFIRMATION:
				setPayment(data);
				break;
		}

		step && setStep(step);
	};

	if (result) {
		return <CardCreated onRestart={() => {
			setStep(Step.DESIGN);
			setResult(null);
			onStart(false);
		}} />;
	}

	return (
		<Grid id="newcard" container>
			<Grid.Column width={16}>
				<Segment padded>
					<Header>{type === CardType.PHYSICAL ? t('cards.newCard.phys') : t('cards.newCard.virt')}</Header>
					<Header.Subheader>{t('cards.newCard.yourcard.step', {
						step: type === CardType.VIRTUAL && step === Step.CONFIRMATION ? 2 : step,
						ofStep: type === CardType.PHYSICAL ? '3' : '2'
					})}</Header.Subheader>
					<Divider hidden />
					{step === Step.DESIGN && type === CardType.PHYSICAL && <div>
						<Menu className="yourcardMenu" fluid widths={availableItems.length === 1 ? 1 : 2}>
							{availableItems.map(item => {
								return (
									<Menu.Item key={item}
										active={activeItem === item}
										onClick={() => { setActiveItem(item); }}>
										<p>{getItemName(item)}</p>
									</Menu.Item>
								);
							})}
						</Menu>
						<Divider hidden />
					</div>}
					{step === Step.DESIGN && <CardDesign options={operations} onSubmit={(payload) => onContinue(type === CardType.PHYSICAL ? Step.ADDRESS : Step.CONFIRMATION, payload)} />}
					{step === Step.ADDRESS && <CardDelivery onSubmit={(payload) => onContinue(Step.CONFIRMATION, payload)} />}
					{step === Step.CONFIRMATION && <CardConfirmation operation={operation} onSubmit={(payload) => onContinue(null, payload)} />}
				</Segment>
			</Grid.Column>
		</Grid>
	);
}

export default NewCard;
