import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import request from '../../services/request';
import { FormStore, KYB } from './types';

const initialState: FormStore = {
	form: null,
	loading: false,
	error: null
};

const kybSlice = createSlice({
	name: 'kyb',
	initialState,
	reducers: {
		setForm(state, action: PayloadAction<KYB>) {
			state.form = action.payload;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setError(state, action: PayloadAction<string>) {
			state.error = action.payload;
		}
	}
});

export const { setForm, setLoading, setError } = kybSlice.actions;

export const getForm = (form: string, language: string): AppThunk => {
	return async dispatch => {
		dispatch(setLoading(true));
		try {
			const response = await request.get(`/api-advisionary/kyb-form/${form}/${language}`);
			const { data } = response;
			dispatch(setForm(data)); 
		} catch(e) {
			dispatch(setError(e.toString()));
		} finally {
			dispatch(setLoading(false));
		}
	};
};


export const completeForm = async (values: any, files: any) => {
	const response = await request.post('/api-advisionary/kyb-form', {values, files});
	const { data } = response;
	return data;
};

export const postFile = async (payload: FormData): Promise<any> => {
	const response = await request.post('/api-advisionary/kyb-form/file', payload);
	const { data } = response;
	return data;
};

export default kybSlice.reducer;
