import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import translations from './locales/Translations';

const languages = Object.keys(translations);
const defaultLanguage = process.env.REACT_APP_DEFAULT_LANGUAGE ? process.env.REACT_APP_DEFAULT_LANGUAGE : 'en';

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.use(XHR)
	.init({
		lng: defaultLanguage,
		fallbackLng: 'en',
		react: {
			wait: true
		}
	});

languages.forEach(language => {
	i18n.addResourceBundle(language, 'translations', translations[language]);
});

export default i18n;
