import * as React from "react";

function SvgShieldFilled(props) {
  return (
    <svg
      width={35}
      height={42}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5 41s16-8 16-20V7l-16-6-16 6v14c0 12 16 20 16 20z"
        fill="#0084A0"
        stroke="#EB8A34"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgShieldFilled;
