import React from 'react';
import MaintenanceBackgroundImage from '@/assets/images/MaintenanceBackgroundImage.png';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getMaintenance } from '@/features/maintenance/maintenanceSlice';
import { Button, Header } from 'semantic-ui-react';

const MaintenanceMode = () => {
    const { t } = useTranslation('translations');
    const history = useHistory();
    const dispatch = useDispatch();

    const handleReload = () => {
        dispatch(getMaintenance());
        history.push('/auth');
    };

    return (
        < div
            style={{
                backgroundImage: `url(${MaintenanceBackgroundImage})`,
                width: '100vw',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPositionY: 'bottom',
                backgroundPositionX: 'center',
                textAlign: 'center',
            }
            }>
            <div style={{ marginTop: '10%' }}>
                <Header as="h1" style={{ color: 'white' }} > {t('dashboard.maintenance.mode')}</Header>
                <div style={{ color: 'white', fontSize: '16px', paddingTop: '16px', paddingBottom: '44px' }}>  {t('dashboard.maintenance.soon')}</div>
                <Button primary onClick={handleReload}>
                    {t('form.buttons.reloadPage')}
                </Button>
            </div>
        </div >
    );
};

export default MaintenanceMode;
