import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { AccountProviderType, Operations } from '@features/operations/types';
import { getOperationsByType, runOperation } from '@features/operations/slice';
import { showErrorNotification } from '@features/swal/slice';
import { Formik } from 'formik';
import { Button, Card, Grid, Header, Icon, Menu, Segment } from 'semantic-ui-react';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import isEmpty from 'is-empty';
import AccountCreated from './AccountCreated';
import Load from './Load';
import Error from '../../Shared/Error';
import './CreateAccount.css';

enum AccountType {
	VIRTUAL = 'VIRTUAL',
	PHYSICAL = 'PHYSICAL'
}

interface OperationProps {
	operation: any,
	onClick: () => void,
	type: AccountType
}

function SelectedOperation({ operation, type, onClick }: OperationProps) {
	const headerText = type === AccountType.VIRTUAL ? 'Virtual Account' : 'Physical Account';
	const { t } = useTranslation('translations');
	return (<Card>
		<Card.Content>
			<Card.Header>
				{headerText} {operation.processProperties && operation.processProperties.ccy}
			</Card.Header>
			<Card.Description>
				Currency: {operation.processProperties && operation.processProperties.ccy}
			</Card.Description>
		</Card.Content>
		<Card.Content extra>
			<Button basic primary fluid onClick={onClick}><Icon name='chevron left' />{t('inbox.back')}</Button>
		</Card.Content>
	</Card>);
}

function OperationItem({ operation, onClick }: OperationProps) {
	const { t } = useTranslation('translations');
	//const headerText = type === AccountType.VIRTUAL ? t('createaccounttab.tab.virtual') : t('createaccounttab.tab.physical');
	const headerText = operation.name;
	return (<Card
		onClick={onClick}
		link
		key={`${operation.proc}`}>
		<Card.Content>
			<Card.Header>
				{headerText} {operation.processProperties && operation.processProperties.ccy}
			</Card.Header>
			<Card.Description>
				{t('createaccounttab.currency')}: {operation.processProperties && operation.processProperties.ccy}
			</Card.Description>
		</Card.Content>
	</Card>);
}

interface Props {
	from: string
}


function CreateAccount({ from }: Props): React.ReactElement {
	const { t } = useTranslation('translations');
	const history = useHistory();
	const [loading, setLoading] = useState<boolean>(true);
	const [error, setError] = useState<any>(null);
	const [accountType, setAccountType] = useState<AccountType>(AccountType.VIRTUAL);
	const [options, setOptions] = useState<any>([]);
	const [filteredOptions, setFilteredOptions] = useState<any>([]);
	const [operation, setOperation] = useState<any>(null);
	const [result, setResult] = useState<any>(null);
	const [availableOptions, setAvailableOptions] = useState([]);

	const { operations } = useSelector(
		(state: RootState) => state.operations
	);

	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required'))
	});

	const initialValues = {
		name: ''
	};

	const getAccountType = (operation) => {
		if ([AccountProviderType.IBAN, AccountProviderType.POOLING_IBAN].indexOf(operation.providerType) !== -1) {
			return AccountType.PHYSICAL;
		}
		if ([AccountProviderType.POOLING].indexOf(operation.providerType) !== -1) {
			return AccountType.VIRTUAL;
		}
		return null;
	};

	const selectType = (type: AccountType) => {
		setAccountType(type);
		setOperation(null);
		setResult(null);
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { name } = formData;
		try {
			const result = await runOperation(operation.proc, { name });
			setResult(result);
			setAccountType(null);
		}
		catch (e) {
			showErrorNotification(e);
		} finally {
			setSubmitting(false);
		}
	};

	useEffect(() => {
		const available = [];
		const virtualAvailable = options.find(option => getAccountType(option) === AccountType.VIRTUAL);
		const physicalAvailable = options.find(option => getAccountType(option) === AccountType.PHYSICAL);
		if (virtualAvailable) {
			available.push(AccountType.VIRTUAL);
		}
		if (physicalAvailable) {
			available.push(AccountType.PHYSICAL);
		}
		if (!virtualAvailable) {
			selectType(AccountType.PHYSICAL);
		}
		setAvailableOptions(available);
	}, [options]);

	useEffect(() => {
		if (operations.indexOf(Operations.CREATE_ACCOUNT) === -1) {
			history.push(from);
		}
	}, [operations, from, history]);

	useEffect(() => {
		const get = async () => {
			if (operations.indexOf(Operations.CREATE_ACCOUNT) !== -1) {
				try {
					const response = await getOperationsByType(Operations.CREATE_ACCOUNT);
					setOptions(response);
				} catch (e) {
					setError(e);
				} finally {
					setLoading(false);
				}
			}
		};
		get();
	}, [operations]);

	useEffect(() => {
		setFilteredOptions(options.filter((option) => accountType === getAccountType(option)));
	}, [accountType, options]);

	return (
		<div id="createaccount">
			<Grid stackable columns="equal">
				<Grid.Column width={5}>
					<Menu fluid vertical className="vertical-menu">
						{availableOptions.includes(AccountType.VIRTUAL) && <Menu.Item name={t('createaccounttab.tab.virtual')} active={accountType === AccountType.VIRTUAL} icon="chevron right" onClick={() => { selectType(AccountType.VIRTUAL); }} />}
						{availableOptions.includes(AccountType.PHYSICAL) && <Menu.Item name={t('createaccounttab.tab.physical')} active={accountType === AccountType.PHYSICAL} icon="chevron right" onClick={() => { selectType(AccountType.PHYSICAL); }} />}
					</Menu>
				</Grid.Column>
				<Grid.Column width={11}>
					{!!result && <AccountCreated />}
					{!result && <Segment>
						<Header as="h3">{accountType === AccountType.VIRTUAL ? t('createaccounttab.tab.virtual') : t('createaccounttab.tab.physical')}</Header>
						{!!operation && (
							<Grid stackable columns="equal">
								<Grid.Row>
									<Grid.Column>
										<SelectedOperation operation={operation} type={accountType} onClick={() => { setOperation(null); }} />
									</Grid.Column>
									<Grid.Column>
										<Formik
											initialValues={initialValues}
											validationSchema={validationSchema}
											onSubmit={submit}
										>
											{({ errors, isSubmitting, dirty }) => (
												<Form>
													<Input className="accountnameinput"
														fluid
														label={t('createaccounttab.virtualaccount.name')}
														placeholder={t('createaccounttab.virtualaccount.nameplaceholder')}
														errorPrompt
														name='name'
													/>
													<SubmitButton disabled={isSubmitting || !isEmpty(errors) || !dirty} type="submit" primary fluid>{t('createaccounttab.btn')}</SubmitButton>
												</Form>)}
										</Formik>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						)}
						{!operation && <Card.Group>
							{filteredOptions.map(option => (
								<OperationItem
									key={`${Math.random()}`}
									operation={option}
									type={accountType}
									onClick={() => { setOperation(option); }}
								/>
							))}
						</Card.Group>}
						{loading && <Load />}
						{error && <Error />}
					</Segment>}
				</Grid.Column>
			</Grid>
		</div>
	);
}

export default CreateAccount;
