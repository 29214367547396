import * as React from "react";

function SvgSendMoneyIcon(props) {
  return (
    <svg
      width={37}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18.25} cy={18} r={18} fill="#0084A0" />
      <path
        d="M11 18h14m0 0l-7-7m7 7l-7 7"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgSendMoneyIcon;
