import React from 'react';
import { Segment, Divider, Button, Header, List, Grid, Message } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import './SendMoneyConfirmation.css';
import { useTranslation } from 'react-i18next';
import './CospayAccount.css';
import useFee from '@/helpers/customHook/useFee';

type ConfirmationProps = {
	onConfirm: () => void,
	payload: any,
	submitting: boolean,
	process: any,
	availableAmount: number,
	inModal?: boolean
}

const CospayAccountConfirmation = ({ onConfirm, payload, submitting, process, availableAmount, inModal }: ConfirmationProps) => {

	const { t } = useTranslation('translations');
	const { fee } = useFee(payload.from.accountId, process.proc, payload.amount);
	const insufficientFunds = availableAmount < (payload.amount + fee);

	return (
		<div id="transferBetweenAccountsConfirmation">
			<Grid>
				<Grid.Column width={16}>
					<Segment padded={inModal ? false : true} className={inModal ? 'withoutBorder' : ''}>
						<Header as="h3">{t('sendmoney.data.header')}</Header>
						<Divider hidden />
						<List relaxed>
							<List.Item>
								<List.Header className="listitemheader">{t('sendmoney.data.amount')}</List.Header>
								<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${payload.from.currency} `} value={payload.amount} />
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.currency')}</List.Header>
								{payload.from.currency}
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.transferfee')}</List.Header>
								<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${payload.from.currency} `} value={fee} />
							</List.Item>
							<List.Item>
								<List.Header>{t('sendmoney.data.youpay')}</List.Header>
								<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${payload.from.currency} `}
									value={parseFloat(payload.amount) + fee} />
							</List.Item>
							<Divider />
							<List.Item>
								<List.Header>{t('sendmoney.data.account')}</List.Header>
								WalletID: {payload.from.walletId}
							</List.Item>
						</List>
						<Divider section />
						<Button loading={submitting} disabled={submitting || insufficientFunds} onClick={onConfirm} primary fluid>{t('sendmoney.data.confirmbutton')}</Button>
						{insufficientFunds && <Message negative>{t('sendmoneyTranslation.data.insufficient')}</Message>}
					</Segment>
				</Grid.Column>
			</Grid>
		</div>);
};

export default CospayAccountConfirmation;
