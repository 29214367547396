import React, { useEffect, useState } from 'react';
import { Button, Dimmer, Grid, Header, Icon, Loader, Segment } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getMyCompanies } from '@features/employee/slice';

interface Props {
	loadingMessage?: string,
}

const EmployeeVerification = ({ loadingMessage }: Props): React.ReactElement => {
	const history = useHistory();
	const { t } = useTranslation('translations');
	const [pending, setPending] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const fetch = async () => {
			const response = await getMyCompanies();
			if(response.length > 0) {
				for (const company of response) {
					const { userAttachments } = company;
					if(userAttachments && userAttachments.length > 0) {
						setPending(true);
						break;
					}
				}
			}
			setLoading(false);
		};
		fetch();
	}, []);

	return (
		<Segment padded="very">
			<Dimmer active={!!loadingMessage || loading} inverted>
				<Loader>{loadingMessage}</Loader>
			</Dimmer>
			{(!pending && !loading) && <Grid>
				<Grid.Column textAlign="center">
					<Header>
						{t('employee.verification.header')}
					</Header>
					<p>{t('employee.verification.details')}</p>
					<br/>
					<Button
						onClick={() => history.push('/wallet/verify')}
						className="createVirtCardBtn"
						primary>
						<Icon name="plus circle" />
						{t('employee.verification.button')}</Button>
				</Grid.Column>
			</Grid>
			}
			{(pending && !loading) && <Grid>
				<Grid.Column textAlign="center">
					<Header>
						{t('employee.verification.pending.header')}
					</Header>
					<p>{t('employee.verification.pending.description')}</p>
				</Grid.Column>
			</Grid>
			}

		</Segment>
	);
};

export default EmployeeVerification;
