import React, { useEffect, useState } from 'react';
import { Grid, Checkbox } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Agents } from '@/helpers/globalTypes';

type ComplianceProps = {
	toc: boolean,
	setToc: (toc: boolean) => void,
	aml: boolean,
	setAml: (aml: boolean) => void,
	politicalExposed: boolean,
	setPoliticalExposed: (politicalExposed: boolean) => void,
	usResident: boolean,
	setUsResident: (usResident: boolean) => void,
}

/* eslint-disable  @typescript-eslint/no-unused-vars */ 
const Compliance = ({ toc, setToc, aml, setAml, usResident, setUsResident, politicalExposed, setPoliticalExposed }: ComplianceProps) => {
	const { t } = useTranslation('translations');
	const [tocLabel, setTocLabel] = useState(<label>{t('registration.links.toc.label')} <a href='/legal/terms'>{t('registration.links.toc.link')}</a></label>);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	useEffect(() => {
		if(agent === Agents.DTS ) {
			setTocLabel(<label>{t('registration.links.toc.labelWl', {agent: 'DTS Money'})} <a href='/legal/terms'>{t('registration.links.toc.link')}</a> and <a href='/legal/privacypolicy'>{t('registration.links.privacy.link')}</a></label>);
		} else if (agent === Agents.CUSTODYFY) {
			setTocLabel(<label>{t('registration.links.toc.labelWl', {agent: 'Custodyfy’s'})} <a href='/legal/terms'>{t('registration.links.toc.link')}</a>, <a href='/legal/termsofuse'>{t('registration.links.termsofuse.link')}</a>, and <a href='/legal/privacypolicy'>{t('registration.links.privacy.link')}</a></label>);
		} else if (agent === Agents.DTSUS) {
			setTocLabel(<label>{t('registration.links.toc.labelWl', {agent: 'DTS Money Americas'})} <a href='/legal/termsofuse'>{t('registration.links.termsofuse.link')}</a> and <a href='/legal/privacypolicy'>{t('registration.links.privacy.link')}</a></label>);
		} else if (agent === Agents.OLAMIT) {
			setTocLabel(<label>{t('registration.links.toc.labelWl', {agent: 'Olamit'})} <a href='/legal/terms'>{t('registration.links.toc.link')}</a>, <a href='/legal/termsofuse'>{t('registration.links.termsofuse.link')}</a>, and <a href='/legal/privacypolicy'>{t('registration.links.privacy.link')}</a></label>);
		}
	}, []);

	return (
		<Grid columns={1} padded='vertically'>
			<Grid.Column floated="left">
				<Grid.Column width={12}>
					<Checkbox fitted
						label={<label>{t('registration.links.political.label')} </label>}
						checked={politicalExposed}
						onChange={() => { setPoliticalExposed(!politicalExposed); }} />
				</Grid.Column>
				{agent !== Agents.DTSUS && <Grid.Column width={12}>
					<Checkbox
						fitted
						label={<label>{t('registration.links.us.label')}</label>}
						checked={usResident}
						onChange={() => { setUsResident(!usResident); }} />
				</Grid.Column>}
				<Grid.Column width={12}> <Checkbox fitted
					name="toc"
					label={tocLabel}
					checked={toc} onChange={() => { setToc(!toc); }} />
				</Grid.Column>
				{/* <Grid.Column width={12}>  <Checkbox fitted
					name="aml"
					label={<label>{t('registration.links.aml.label')} <a href='/'>{t('registration.links.aml.link')}</a></label>}
					checked={aml} onChange={() => { setAml(!aml); }} />
				</Grid.Column> */}
			</Grid.Column>
		</Grid>
	);
};

export default Compliance;
