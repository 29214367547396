import React, { useEffect, useState } from 'react';
import { Segment, Grid, Divider, Icon, Container, Header, Breadcrumb } from 'semantic-ui-react';
import '@/components/Business/MyEmployees.css';
import { Formik } from 'formik';
import { SubmitButton, Select, Form } from 'formik-semantic-ui-react';
import { isEmpty } from 'lodash';
import { Employee } from '@/features/employee/types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getAvailableCards } from '@/features/employee/slice';


interface Props {
	employee?: Employee,
	employees?: Employee[],
	onSend: () => void,
	onBackClick: () => void
}

const AssignCard = ({ onBackClick, onSend, employee, employees }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [availableCards, setAvailableCards] = useState([{}]);

	const validationSchema = Yup.object({
		employeeId: Yup.string().required(t('form.validator.required')),
		cardId: Yup.string().required(t('form.validator.required')),
	});

	const initialValues = {
		employeeId: employee !== null ? employee.employeeId : '',
		cardId: ''
	};


	useEffect(() => {
		const fetchAvalableCards = async () => {
			const available = await getAvailableCards();
			setAvailableCards(available.map(item => ({ value: item.cardId, key: item.cardId, text: `${item.name} (${item.currency} ${item.availableBalance})` })));
		};
		fetchAvalableCards();
	}, []);

	return (
		<div id="assignCard">
			<Container>
				<Breadcrumb size="large">
					<Breadcrumb.Section className="backchevronitem" link onClick={onBackClick}><Icon name="chevron left" className="backIcon" />{t('business.back')}</Breadcrumb.Section>
				</Breadcrumb>
				<Header as='h1' content={t('business.assignCard.assignCard')}></Header>
				<Grid stackable>
					<Grid.Column width={8}>
						<Segment padded>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={onSend}
								render={({ isSubmitting, errors, dirty }) => {
									return (
										<Form>
											<Select
												name="employeeId"
												placeholder={t('business.assignCard.employeePlaceholder')}
												label={t('business.assignCard.employee')}
												options={employees.map(item => ({ value: item.employeeId, key: item.employeeId, text: `${item.firstName} ${item.lastName}` }))}
												fluid
												errorPromt
											/>
											<Divider hidden />
											<Select
												name="cardId"
												placeholder={t('business.assignCard.cardPlaceholder')}
												label={t('business.assignCard.card')}
												fluid
												options={availableCards}
												errorPromt
											/>
											<Divider section />
											<SubmitButton primary
												fluid
												type="submit"
												disabled={isSubmitting || !isEmpty(errors) || !dirty}>
												{t('form.buttons.confirm')}
											</SubmitButton>
										</Form>);
								}}
							/>
						</Segment>
					</Grid.Column>
				</Grid>
			</Container>
		</div>

	);
};

export default AssignCard;
