import React, { useState, useEffect, useCallback } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'is-empty';
import { alpha2ToAlpha3, alpha3ToAlpha2, getNames } from 'i18n-iso-countries';
import { Icon, Modal, Message, Grid, Button, Radio } from 'semantic-ui-react';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { getToken, profileUpdate } from '@features/kyc/slice';
import { useKYCTier } from './hooks';
import { KycStatus } from './types';
import SumSubForm from './SumSubForm';
import { UserType } from '@/features/user/types';
import OnfidoForm from '../KYCOnfido/OnfidoForm';
import { showErrorNotification } from '@/features/swal/slice';
import OnfidoStatus from '../KYCOnfido/OnfidoStatus';
import { useDropzone } from 'react-dropzone';
import { postUserPoa } from '@/features/files/slice';
import './KYC.css';
import AdvisionaryKybStatus from './AdvisionaryKybStatus';
import AdvisionaryKybForm from './AdvisionaryKybForm';
import ScheduleVideoCall from './ScheduleVideoCall';
import usStates from './usstates.json';

interface ModalProps {
	open: boolean,
	setOpen: (value: boolean) => void
}

interface Country {
	code: string,
	label: string,
}

const KYCModal = ({ open, setOpen }: ModalProps): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const contriesList = getNames('en');
	const [countries] = useState<Array<Country>>(
		Object.keys(contriesList)
			.map($code => ({
				code: $code,
				label: contriesList[$code],
			})));
	const [showAddress, setShowAddress] = useState<boolean>(false);
	const [showOptions, setShowOptions] = useState<boolean>(false);
	const [finalRejection, setFinalRejection] = useState<boolean>(false);
	const [option, setOption] = useState<'standard' | 'video'>(null);
	const [error, setError] = useState<boolean>(false);
	const [userCountry, setUserCountry] = useState<string>(null);
	const [residenceProof, setResidenceProof] = useState(null);
	const [rejected, setRejected] = useState<number>(0);
	const { user, status } = useSelector(
		(state: RootState) => state.user
	);
	const { token } = useSelector(
		(state: RootState) => state.kyc
	);

	const kycService = user.type === UserType.INDIVIDUAL ? status.kycProvider : status.kybProvider;
	const tier = user.type === UserType.INDIVIDUAL ? useKYCTier(user.kyc, 1) : useKYCTier(user.kyc, 2);

	const checkCount = tier?.checkCount ?? 0;
	//for onfidu kyc can be run 2 times if first time rejection received
	const allowToStartKFY = tier === null ? true : ([KycStatus.REJECTED, KycStatus.CREATED].includes(tier.status) && checkCount < 2) || tier.expiredInMonth === true;
	const showAdvisionaryForm = tier === null ? true : [KycStatus.REJECTED].includes(tier.status);

	useEffect(() => {
		const { country } = user;
		const countryCode = alpha3ToAlpha2(country);
		setUserCountry(countryCode);
	}, [user, contriesList]);

	useEffect(() => {
		if ((tier === null || tier.status === KycStatus.PAID) && kycService !== 'advisionary') {
			setShowAddress(true);
		}
		if (tier !== null && tier.status !== KycStatus.PAID) {
			setShowAddress(false);
			if (tier.video && tier.status === 'REJECTED' && tier.final) {
				setFinalRejection(true);
				return;
			}

			if (tier.video) {
				setOption('video');
			} else {

				if (status.amlVideo.amlVideoAvailable && tier.status === 'REJECTED' && checkCount >= 2) {
					setRejected(2);
					setOption(null);
					setShowOptions(true);
					return;
				} else if (status.amlVideo.amlVideoAvailable && tier.status === 'REJECTED' && checkCount < 2) {
					setRejected(1);
					setOption(null);
					setShowOptions(true);
					return;
				}

				if (kycService === 'onfido' && allowToStartKFY) {
					dispatch(getToken(kycService));
				}
				else if (kycService === 'sumsub') {
					dispatch(getToken(kycService));
				}
				setOption('standard');
			}
		}
	}, [dispatch, tier]);

	useEffect(() => {
		console.log('Token: ', token);
		if (!option || token) return;
		if (option === 'video') {
			//dispatch(getToken('video-sumsub'));
		} else {
			if (kycService === 'onfido' && allowToStartKFY) {
				dispatch(getToken(kycService));
			}
			else if (kycService === 'sumsub') {
				dispatch(getToken(kycService));
			}
		}
	}, [option, dispatch]);

	const initialValues = {
		country: userCountry,
		state: '',
		street: '',
		town: '',
		postalCode: '',
		firstName: user.firstName ?? '',
		lastName: user.lastName ?? ''
	};

	const validationSchema = Yup.object({
		country: Yup.string().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		street: Yup.string().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		town: Yup.string().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		postalCode: Yup.string().required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		firstName: Yup.string().required(t('form.validator.required')),
		lastName: Yup.string().required(t('form.validator.required')),
		state: Yup.string().when('country', {
			is: 'US',
			then: Yup.string().required(t('form.validator.required')),
			otherwise: Yup.string().notRequired(),
		})
	});

	const handleUtilityUpload = (file) => {
		setResidenceProof(file);
	};

	const next = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setError(false);
		try {
			const { country, street, town, postalCode, firstName, lastName, state } = formData;
			const countyCode = alpha2ToAlpha3(country);

			await dispatch(profileUpdate({
				country: countyCode, street, town, postalCode, firstName, lastName, state
			}));

			const poaFile = new FormData();
			poaFile.append('file', residenceProof);
			await postUserPoa(poaFile);


			//await dispatch(getToken(kycService));
			setShowAddress(false);
			if (status.amlVideo.amlVideoAvailable) {
				setShowOptions(true);
			} else {
				setOption('standard');
			}
		} catch (e) {
			setError(true);
			setOpen(false);
			showErrorNotification(e);
		} finally {
			setSubmitting(true);
		}

	};

	const handleMethodChange = (method: 'standard' | 'video') => {
		setShowOptions(false);
		setOption(method);
	};

	return (
		<Modal
			onClose={() => setOpen(false)}
			onOpen={() => setOpen(true)}
			open={open}
			id='kycModal'
			closeOnDimmerClick={false}
		>
			<Modal.Header>{t('kyc.form.header', { type: user.type === UserType.BUSINESS ? 'Business' : 'Individual' })}</Modal.Header>
			{showAddress && (<Modal.Content>
				<Message icon>
					<Icon name='chevron right' />
					<Message.Content>{t('kyc.form.helper')}</Message.Content>
				</Message>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={next}
				>
					{({ errors, isSubmitting, dirty, values }) => (
						<Form
							size="large"
							error={false}>
							{!(user.firstName) && <Input
								fluid
								name="firstName"
								label={t('form.fields.firstname')}
								errorPrompt
							/>
							}
							{!(user.lastName) && <Input
								fluid
								name="lastName"
								label={t('form.fields.lastname')}
								errorPrompt
							/>}
							<Select
								fluid
								name="country"
								label={t('form.fields.country')}
								placeholder={t('form.placeholder.country')}
								errorPrompt
								list="countries"
								options={countries.map(c => ({ key: c.code, value: c.code, text: c.label }))}
								clearable
								search
							/>
							{values.country === 'US' && <Select
								fluid
								name="state"
								label={t('form.fields.state')}
								clearable
								options={usStates.map(s => ({ key: s.abbreviation, value: s.abbreviation, text: s.name }))}
								errorPrompt
							/>}
							<Input
								fluid
								name="street"
								label={t('form.fields.justAddress')}
								errorPrompt
							/>
							<Input
								fluid
								name="town"
								label={t('form.fields.town')}
								errorPrompt
							/>
							<Input
								fluid
								name="postalCode"
								label={t('form.fields.postalcode')}
								errorPrompt
							/>
							{user?.poa?.required && user?.poa?.status !== 'PENDING' && <UploadPoa onUpload={handleUtilityUpload} />}
							{error && (<Message
								error
								header='Action Forbidden'
								content={''} />)}
							<div className='formBTNS'>
								<SubmitButton
									disabled={isSubmitting || !isEmpty(errors) || !dirty || (!residenceProof && user.poa.required && user?.poa?.status !== 'PENDING')}
									primary size="large" type="submit">
									{t('form.buttons.continue')}
								</SubmitButton>
								<Button size="large" onClick={() => setOpen(false)}>{t('form.buttons.close')}</Button>
							</div>
						</Form>)}
				</Formik>
			</Modal.Content>)}
			{finalRejection && <Modal.Content>
				<Message>{t('kyc.finalRejection')}</Message>
				<Button size="large" onClick={() => setOpen(false)}>{t('form.buttons.close')}</Button>
			</Modal.Content>}
			{!finalRejection && showOptions && <ChooseVerificationMethod onContinue={handleMethodChange} onClose={() => setOpen(false)} rejected={rejected} />}
			{!finalRejection && kycService === 'advisionary' && showAdvisionaryForm && <AdvisionaryKybForm onClose={() => setOpen(false)} />}
			{!finalRejection && kycService === 'advisionary' && !showAdvisionaryForm && <AdvisionaryKybStatus onClose={() => setOpen(false)}></AdvisionaryKybStatus>}
			{!finalRejection && option === 'standard' && kycService === 'sumsub' && token && <SumSubForm token={token} onClose={() => setOpen(false)}></SumSubForm>}
			{/* {!finalRejection && option === 'video' && token && <SumSubVideoForm token={token} onClose={() => setOpen(false)}></SumSubVideoForm>} */}
			{!finalRejection && option === 'video' && <ScheduleVideoCall onClose={() => setOpen(false)}></ScheduleVideoCall>}
			{!finalRejection && option === 'standard' && kycService === 'onfido' && allowToStartKFY && token && <OnfidoForm token={token} onClose={() => setOpen(false)}></OnfidoForm>}
			{!finalRejection && !showOptions && kycService === 'onfido' && !allowToStartKFY && option !== 'video' && <OnfidoStatus kycStatus={tier?.status} checkCount={tier?.checkCount} onClose={() => setOpen(false)}></OnfidoStatus>}
		</Modal>
	);
};

interface UploadPoaProps {
	onUpload: (file) => void
}

const UploadPoa = ({ onUpload }: UploadPoaProps) => {
	const { t } = useTranslation('translations');

	const onDrop = useCallback((acceptedFiles) => {
		onUpload(acceptedFiles[0]);
	}, []);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
		onDrop, accept: 'image/png,image/jpg,image/jpeg,application/pdf', multiple: false, maxSize: 10485760,
		onDropRejected(fileRejections) {
			fileRejections.map(file => {
				file.errors.map(err => {
					if (err.code === 'file-too-large') {
						showErrorNotification(err, 'File size too large');
					}
				});
			});
		},
	});

	const removeAll = useCallback(() => {
		onUpload(null);
		acceptedFiles.splice(0);
	}, [onUpload]);

	return (
		<>
			<Grid className='uploadPoa' style={{ marginLeft: '0', marginRight: '0', marginBottom: '1em', marginTop: '1em' }}>
				<Grid.Row><strong>{t('poa.form.label')}</strong></Grid.Row>
				<Grid.Row>
					<span>
						{t('poa.form.help')}
					</span></Grid.Row>
				<Grid.Row>
					<div {...getRootProps()} id='file-container'>
						<input {...getInputProps()} id='file-drop' />
						<span><Icon name='upload' /> {t('poa.form.dropzone')}</span>
					</div>
				</Grid.Row>
				{acceptedFiles.length > 0 &&
					<Grid.Row>
						<Icon size="large" name="file outline" />
						<div className="fileName">{acceptedFiles[0].name}</div>
						<Icon className="fileName" size="large" name='trash' link onClick={() => removeAll()} />
					</Grid.Row>}
			</Grid>
		</>
	);
};

interface ChooseVerificationMethodProps {
	onContinue: (method: 'standard' | 'video') => void
	onClose: () => void
	rejected: number
}

const ChooseVerificationMethod = ({ onContinue, onClose, rejected }: ChooseVerificationMethodProps) => {
	const [method, setMethod] = useState<'standard' | 'video'>(rejected === 2 ? 'video' : 'standard');
	const { t } = useTranslation('translations');
	const [workingHours, setWorkingHours] = useState<boolean>(false);
	const [startWorkingHours, setStartWorkingHours] = useState<string>('');
	const [endWorkingHours, setEndWorkingHours] = useState<string>('');
	const sobdInUtc = 7;
	const eobdInUtc = 15;

	useEffect(() => {
		const now = new Date();
		const diff = now.getTimezoneOffset() / 60;
		const sobdInLocal = sobdInUtc - diff;
		const eobdInLocal = eobdInUtc - diff;

		setStartWorkingHours(`${sobdInLocal}:00`);
		setEndWorkingHours(`${eobdInLocal}:00`);
	}, []);

	useEffect(() => {
		const calculate = () => {
			const now = new Date();
			const hours = now.getUTCHours();
			const day = now.getUTCDay();

			if ((sobdInUtc <= hours && eobdInUtc > hours) && day <= 5) {
				setWorkingHours(true);
			} else {
				setWorkingHours(false);
			}
		};
		calculate();
		const timer = setInterval(() => {
			calculate();
		}, 60 * 1000);

		return () => clearInterval(timer);
	}, []);


	return (
		<Modal.Content>
			<Grid columns={1} padded style={{ width: '50%' }}>
				{rejected === 1 && <Grid.Row style={{ paddingBottom: '1em', color: 'red' }}>{t('kyc.choose.manualRejection')}</Grid.Row>}
				{rejected === 2 && <Grid.Row style={{ paddingBottom: '1em', color: 'red' }}>{t('kyc.choose.manualRejectionFinal')}</Grid.Row>}
				<Grid.Row><strong>{t('kyc.choose.title')}</strong></Grid.Row>
				<Grid.Row>
					<Radio
						label={t('kyc.choose.manualRadio')}
						name='radioGroup'
						value='standard'
						checked={method === 'standard'}
						onChange={() => setMethod('standard')}
						disabled={rejected === 2}
					/>
					<p style={{ marginTop: '0.5em' }}>{t('kyc.choose.manualDesc')} <strong>{t('kyc.choose.manualDescStrong')}</strong></p>
				</Grid.Row>
				<Grid.Row>
					<Radio
						label={t('kyc.choose.videoRadio')}
						name='radioGroup'
						value='video'
						checked={method === 'video'}
						onChange={() => setMethod('video')}
						disabled={!workingHours}
					/>
					<p style={{ marginTop: '0.5em' }}>{t('kyc.choose.videoDesc')} <strong>{t('kyc.choose.videoDescStrong')}</strong></p>
					{!workingHours && <p>{t('kyc.choose.workingHours', { start: startWorkingHours, end: endWorkingHours })}</p>}
				</Grid.Row>
				<Grid.Row>
					<Button primary onClick={() => onContinue(method)} disabled={!workingHours && method === 'video'}>{t('kyc.choose.continueBtn')}</Button>
					<Button onClick={onClose}>{t('form.buttons.close')}</Button>
				</Grid.Row>
			</Grid>
		</Modal.Content>
	);
};

export default KYCModal;
