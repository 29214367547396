import { Token, TokenAction } from '@/features/tokens/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Container, Grid, Header, Icon, Menu } from 'semantic-ui-react';
import SendTokenDetailsForm, { SendToExternalPayload } from './SendToExternalForm';
import SendToUserForm, { SendToUserPayload } from './SendToUserForm';
import SendToUserConfirmation from './SendToUserConfirmation';
import SendToExternalConfirmation from './SendToExternalConfirmation';

interface Props {
	onBackClick: () => void
	token: Token,
} 

const TokenSend = ({ onBackClick, token }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const [action, setAction] = useState<TokenAction>(TokenAction.SEND_TO_USER);
	const [payloadToUser, setPayloadToUser] = useState<SendToUserPayload>();
	const [payloadToExternal, setPayloadToExternal] = useState<SendToExternalPayload>();
	const [step, setStep] = useState<number>(1);

	const confirmToken = (value: any) => {
		action === TokenAction.SEND_TO_EXTERNAL ? setPayloadToExternal(value) : setPayloadToUser(value);
		setStep(2);
	};

	const submitToken = () => {
		onBackClick();
	};

	const sendBack = () => {
		if (step == 2) { setStep(1); }
		else { onBackClick(); }
	};

	return (
		<div className='token-trade'>
			<Container>
				<Breadcrumb size='large'>
					<Breadcrumb.Section className='backchevronitem' link onClick={sendBack}><Icon
						name='chevron left' className='backIcon' /> {t('inbox.back')}</Breadcrumb.Section>
				</Breadcrumb>
				<Header as='h1'>{t('tokens.tokenActions.SEND')}</Header>
				<Grid>
					<Grid.Column width={5} className='token-menu'>
						<Menu className="vertical-menu" fluid vertical>
							<Menu.Item key='send-token'
								icon="chevron right"
								name={t('sendmoneyTranslation.data.cospayuserheader')}
								disabled={step !== 1 && action !== TokenAction.SEND_TO_USER}
								active={action === TokenAction.SEND_TO_USER}
								onClick={() => setAction(TokenAction.SEND_TO_USER)}
							/>
							<Menu.Item key='send-token-external'
								icon="chevron right"
								name={t('tokens.header.externalWallet')}
								disabled={step !== 1 && action !== TokenAction.SEND_TO_EXTERNAL}
								active={action === TokenAction.SEND_TO_EXTERNAL}
								onClick={() => setAction(TokenAction.SEND_TO_EXTERNAL)}
							/>
						</Menu>
					</Grid.Column>
					{action === TokenAction.SEND_TO_USER && step === 1 &&
						<SendToUserForm
							payload={payloadToUser}
							token={token}
							onConfirm={confirmToken}
						/>
					}
					{action === TokenAction.SEND_TO_USER && step === 2 &&
						<SendToUserConfirmation
							payload={payloadToUser}
							onConfirm={submitToken}
						/>
					}
					{action === TokenAction.SEND_TO_EXTERNAL && step === 1 &&
						<SendTokenDetailsForm
							payload={payloadToExternal}
							token={token}
							onConfirm={confirmToken}
						/>
					}{action === TokenAction.SEND_TO_EXTERNAL && step === 2 &&
						<SendToExternalConfirmation
							payload={payloadToExternal}
							onConfirm={submitToken}
						/>
					} 
				</Grid>
			</Container>
		</div>
	);
};

export default TokenSend;
