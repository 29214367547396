import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { Operations } from '@features/operations/types';
import { useTranslation } from 'react-i18next';

interface Props {
	type: string
}

const HeaderButton = ({ type }: Props): ReactElement => {
	const { t } = useTranslation(['translations']);
	const history = useHistory();
	const { path } = useRouteMatch();
	const [disabled, setDisabled] = useState<boolean>(true);

	const { operations } = useSelector(
		(state: RootState) => state.operations
	);

	const create = () => {
		history.push(`/wallet/create/${type}?from=` + path);
	};

	useEffect(() => {
		if (operations.indexOf(Operations.CREATE_ACCOUNT) !== -1) {
			setDisabled(false);
		}
	}, [operations]);

	return (
		<div style={{ minWidth: 272, display: 'block', margin: 0 }} className='ui three column middle aligned grid new'>
			<Grid.Row style={{ height: '100%' }}>
				<Button
					disabled={disabled}
					onClick={create}
					style={{ textAlign: 'center' }} basic primary>
					<Icon name='plus circle' size='large' />
					<div>{t('headerButton.create')} {t('headerButton.' + type)}</div>
				</Button>
			</Grid.Row>
		</div>
	);
};

export default HeaderButton;
