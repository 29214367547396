import React from 'react';
import { Container, Grid, List, Segment } from 'semantic-ui-react';
import './Terms.css';

function Terms(): React.ReactElement {
	return (
		<Container>
			<Grid>
				<Grid.Row centered><h1>Terms and Conditions</h1></Grid.Row>
			</Grid>
			<Segment className="policyContent">

				<p>These Terms and conditions govern the use of the Payment Services owned by DTS Circle Bank the
					company which owns the platform of the Payment Services. These Terms and conditions are supplied by
					DTS Circle Bank (“we”, “us”, “our”) to any person whose application we approve (“Customer”, “you”,
					“your”). By applying for your Account and Card, you confirm that you have read, understood, and
					agreed with the terms and conditions.</p>

				<h2>Definitions & Interpretation</h2>

				<List bulleted>
					<List.Item>“Account” or “E-Wallet” a non-deposit non-interest bearing pre-paid electronic account
						associated at times to a Card which is maintained for the sole purpose of enabling Transactions;
					</List.Item>
					<List.Item>“Account Information Service” means an online service to provide consolidated
						information on one or more payment accounts held by the Payment Service user with another
						payment service provider or with more than one payment service provider;
					</List.Item>
					<List.Item>“Account Information Service Provider” or “AISP” the supplier of an Account Information
						Service;
					</List.Item>
					<List.Item>“Applicable Exchange Rate” the exchange rate available on our website;
					</List.Item>
					<List.Item>“ATM” An automated teller machine is an electronic telecommunications device that
						enables Customers to perform financial transactions, particularly cash withdrawal, without the
						need for a human cashier, clerk or bank teller;
					</List.Item>
					<List.Item>“Available Balance” the amount of money or E-money not yet spent or redeemed;
					</List.Item>
					<List.Item>“Business Day” Monday to Friday, 0900hrs to 1700hrs GMT, excluding bank and public
						holidays in the UK;
					</List.Item>
					<List.Item>“Card” a prepaid Mastercard debit card issued by us and linked to your Account, which
						can be used to spend your Available Balance wherever Mastercard cards are accepted;
					</List.Item>
					<List.Item>“Card Replacement Fee” has the meaning given in the attached Fees & Limits Schedule;
					</List.Item>
					<List.Item>“Card Scheme” the operator of the payment scheme under which we issue each Card;
					</List.Item>
					<List.Item>“Customer Due Diligence” the process we are required to go through to verify the
						identity of our Customers;
					</List.Item>
					<List.Item>“Customer Funds Account” the segregated bank account where we hold relevant funds
						corresponding to your Available Balance in accordance with the safeguarding provisions of the
						Electronic Money Regulations 2011;
					</List.Item>
					<List.Item>“E-money” monetary value to your Account on receipt of funds on your behalf in our
						Customer Funds Account, equal to the amount of funds received;
					</List.Item>
					<List.Item>“European Economic Area” or “EEA” means the European Union (“EU”) member states, as well
						as Iceland, Liechtenstein and Norway;
					</List.Item>
					<List.Item>“Fees” the fees payable by you for the Payment Services as specified in the attached
						Fees & Limits Schedule;
					</List.Item>
					<List.Item>“IBAN” means an International Bank Account Number used to identify bank accounts for the
						purposes of international payments;
					</List.Item>
					<List.Item>“Merchant” a retailer who accepts Payment for the sale of goods or services to you;
					</List.Item>
					<List.Item>“Payment” means a payment for goods or services using a Card;
					</List.Item>
					<List.Item>“Payment Initiation Service” means an online service to initiate a payment order at the
						request of the payment service user with respect to a payment account held at another payment
						service provider;
					</List.Item>
					<List.Item>“Payment Initiation Service Provider” or (“PISP”) is a payment service provider who
						supplies a Payment Initiation Service;
					</List.Item>
					<List.Item>“Payment Services” means the services supplied by us to you under this Agreement,
						including issuing Cards, Accounts and IBANs; and executing Transactions;
					</List.Item>
					<List.Item>“SEPA Transfer” A facility available whereby you instruct us to send Euros to a bank
						account elsewhere in the Single Euro Payments Area (“SEPA”), quoting the IBAN of the intended
						recipient of the funds;
					</List.Item>
					<List.Item>“Simplified Due Diligence” means a lighter form of Customer Due Diligence, resulting in
						certain lower Card and Account limits;
					</List.Item>
					<List.Item>“Transaction” a Transfer or a SEPA Transfer, ATM withdrawal;
					</List.Item>
					<List.Item>“Transfer” a transfer of money or E-money from one Account to another Account.
					</List.Item>
				</List>

				<h2>2. Contact and Regulatory Information</h2>

				<p>2.1. Your Card or Account can be managed online at https://ib.dtscirclebank.com/auth or at the
					application for Android and IOS which is available on your device (the “App”).</p>
				<p>2.2. Owner of the platform (the “DTSCB”) and direct service provider is DTS Circle Bank,
					incorporated and registered in Comoros, which conducts banking activities in accordance with the
					Comoros International Banking Authority Act Number 17-008/02/CIBA/AU of 2017 & Law Number 17-008/AU,
					authorized by The Central Bank of Comoros. License number L 21998/DTS.</p>
				<p>DTS Circle Bank provides services such as opening account with IBAN, debit card issuance, wallet
					management (with all the major cryptocurrencies), custody trust and exchange.</p>
				<p>2.3. Different types of services are provided by the DTS Circle Bank partners:</p>
				<p>2.3.1. Azure Psystems Ltd, legal entity code 12108869, registered address 102 Langdale House 11
					Marshalsea Road, London, United Kingdom, SE1 1EN. The company is authorized and licensed to conduct
					financial activities such as providing IBAN, manage electronic payments, issue debit and credit
					cards, through mobile banking and e-wallets, both to private and corporate clients in accordance
					with the FCA - Financial Conduit Authority in the United Kingdom;</p>
				<p>2.3.2. UAB “Dovre Forvaltning”, legal entity code 302589746, registered address Konstitucijos ave.
					7, Vilnius, Lithuania. The company is authorized and licensed to conduct activity of financial
					advisory and management of colllective scheme (UCITS FUNDS) and individual managed accounts,
					regulated by the Central Bank of Lithuania;</p>

				<p>2.3.3. Cospay Ltd, legal entity code 07805564, registered address One, Bartholomew Close, London,
					United Kingdom, EC1A 7BL. The company operates as Electronic Money institution and Credit Cards for
					Corporate Customers.</p>

				<h2>3. Type of Service, Eligibility and Account</h2>

				<p> 3.1. Your Card is a debit card and is issued by one of our service partners. The DTSCB Card is
					followed by separate Card account on your DTSCB profile which will show you the balance of your
					DTSCB Card. The DTSCB Card shall be topped up from your Account using the applicable functionality
					on the DTSCB. </p>
				<p> 3.2. Your Payment Services may not be activated unless we have been provided with the required
					information so that we may identify you and can comply with all applicable Simplified Customer Due
					Diligence requirements. We shall keep records of such information and documents in accordance with
					all applicable legal and regulatory requirements. </p>
				<p> 3.3. Reference to a currency (e.g. Euros €) shall mean that amount or the local currency equivalent
					in which your Card is denominated. </p>
				<p> 3.4. Any Transaction on your Card in a currency other than the currency in which your Card is
					denominated, will require a currency conversion using an Applicable Exchange Rate. </p>
				<p> 3.5. The Available Balance on your Card and/or Account will not earn any interest. </p>
				<p> 3.6. The Payment Services are prepaid payment services and you must therefore ensure that you have a
					sufficient Available Balance from time to time to pay for your Transactions and applicable Fees. If
					for any reason a Transaction is processed, and the Transaction amount exceeds the Available Balance,
					you must repay us the amount of such excess immediately and we shall be entitled to stop any
					existing or subsequent Transactions from proceeding. </p>
				<p> 3.7. This Agreement does not give you any rights against the Card Schemes, its affiliates or any
					third party. </p>
				<p>3.8. You must meet the following criteria in order to create a DTSCB profile:
					<List>
						<List.Item>You must be at least 18 years old or older and be resident in a country where DTS
							Circle Bank is available;</List.Item>
						<List.Item>You must be an individual and not acting on behalf of a business. If you wish to open
							a business account with us please click on the button Business when you sign up;</List.Item>
						<List.Item>You must only open one account with us;</List.Item>
						<List.Item>You did not have a previous account with us that was blocked or closed.</List.Item>
					</List>
				</p>

				<p> 3.9. Unless you are at least 18 years old, you are not authorised to enter into these Terms or to
					use the Payment Service without the parent or parental authority having understood and accepted
					these Terms. </p>

				<p> If you are a parent or a person exercising parental authority authorising a person under 18 years of
					age (&quot;<b>Minor</b>&quot;) to use the Payment Service, you accept and agree to: (i) supervise
					the Minor&apos;s use of the Payment Service; (ii) assume all risks associated with the Minor’s use
					of the Payment Service, (iii) assume any liability resulting from the Minor’s use of the Services;
					(iv) ensure the accuracy and truthfulness of all information submitted by you or the Minor; and (v)
					assume responsibility and be bound by these Terms for the Minor’s access and use of the Payment
					Service. </p>

				<p> 4.0. Each time you seek access the Account we will be asking for your password with a combination of
					letters, numbers and special characters as well as 2-factor authentication. As long as the correct
					password is entered and 2-factor authentication is confirmed, we will assume that you are the person
					giving instructions and making Transactions and you will be liable for them. We can refuse to act on
					any instruction that we believe: (i) was unclear; (ii) was not given by you; or (iii) might cause us
					to breach a legal or other duty; or if we believe the Payment Service is being used for an illegal
					purpose. </p>


				<h2>4. Service Limits & SEPA Transfer Payment</h2>
				<p> 4.1. Transactions may be restricted by Card or Account type, individual usage patterns and payment
					risk profiles. Cards are issued in accordance with regulatory limits and conditions. Limits relating
					to the use of Cards can be found Fees & Limits Schedule and on our Website. For anti-money
					laundering and anti-fraud reasons we reserve our rights to change particular payment restrictions
					(including from those published or included herein) without notice and to the extent required to
					meet our regulatory obligations. </p>
				<p> 4.2. Simplified Due Diligence may be restricted to domestic ATM access, along with reduced annual
					maximum load limits and capped annual withdrawal limits. These limits will be subject to Scheme and
					regulatory requirements. </p>
				<p> 4.3. You can make a Transfer to another Account by signing into your Account and following the
					relevant instructions. </p>
				<p> 4.4. When sending funds to your Account with us, we recommend that you or other senders make a SEPA
					Transfer. </p>
				<p> 4.5. We cannot be held liable for the payment process or fees associated with bank(s) and or
					intermediary bank(s) to process payments from you to us. Any fee(s) charged by third parties, not
					limited to receiving, processing or crediting a payment for you will be deducted by us before
					crediting the remaining balance to you. </p>
				<p> 4.6. You are responsible for checking and confirming payment details and fees before making a
					payment to us or to your Account. </p>
				<p> 4.7. You may be asked to provide us with evidence of source of funds in order for us to meet our
					regulatory requirements, in which case you agree to provide that evidence promptly. You represent
					and warrant to us that the evidence you provide to us is up to date, complete and accurate. </p>
				<p> 4.8. Where so enabled you may change your PIN at selected ATM’s subject to a Fee. </p>
				<p> 4.9. You may incur a charge for unpaid SEPA Transfers if there are not enough funds in your Account
					to pay an incoming SEPA Transfer request. </p>

				<h2>5. Use of the Payment Services</h2>

				<p> 5.1. You may access your Account information by logging into your Account through website
					https://ib.dtscirclebank.com/auth or the application for Android and IOS. From here you will be able
					to view details on your Transactions, including dates, currencies, charges or exchange rates
					applied. This information is accessible at any time and can be stored and reproduced as
					necessary. </p>
				<p> 5.2. You can use the Payment Services up to the amount of the Available Balance for
					Transactions. </p>
				<p> 5.3. If the Available Balance is insufficient to pay for a Payment, some Merchants will not permit
					you to combine use of a Card or Account with other payment methods. </p>
				<p> 5.4. The value of each Transaction and the amount of any Fees payable by you under this Agreement
					will be deducted from the Available Balance. </p>
				<p> 5.5. Once a Transaction is authorised, the relevant payment order may not be withdrawn (or revoked)
					by you after the time it is received by us. A Transaction will be deemed to have been received by us
					at the time you authorise the Transaction as follows: i. for Payments and ATM Transactions, at the
					time we receive the payment order for the Transaction from the merchant acquirer or ATM operator,
					and ii. A payment order for a Transfer or SEPA Transfer is provided to and received by us at the
					time it is issued by you via the Account. </p>
				<p> 5.6. Where a revocation of an authorised payment is agreed between us and you, we may charge a Fee
					for revocation. </p>
				<p> 5.7. We will ensure that the amount of a SEPA Transfer is credited to the payment service provider
					of the payee by end of the Business Day following the time of the receipt of your payment order. If
					the payment service provider of the Merchant is located outside the EEA, we will affect payment as
					soon as possible and in any event as required by applicable law. </p>
				<p> 5.8. In order to protect you and us from fraud, Merchants may seek electronic authorisation before
					processing any Payment. If a Merchant is unable to get an electronic authorisation, they may not be
					able to authorise your Payment. </p>
				<p> 5.9. We may refuse to authorise any use of the Payment Services which could breach these terms and
					conditions or if we have reasonable grounds for suspecting that you or a third party have committed
					or are planning to commit fraud or any other illegal or un-permitted use of the Payment
					Services. </p>
				<p> 5.10. Your ability to use or access the Payment Services may occasionally be interrupted, for
					example if we need to carry out maintenance on our Systems. Please contact Customer Services via our
					website to notify us of any problems you are experiencing using your Card or Account and we will
					endeavour to resolve any problem. </p>
				<p> 5.11. Except as required by law, we shall not be responsible, and you will be solely responsible,
					for compiling and retaining your own copy of the data in your Account and your activities in
					connection with these terms of use. Upon the termination of services for any reason, we shall have
					no obligation to store, retain, report, or otherwise provide any copies of, or access to, the
					Transaction data or any records, documentation or other information in connection with any
					Transactions or the Account unless otherwise required by law. </p>
				<p> 5.12. You agree to only use the Payment Services for lawful purposes and to adhere at all times to
					all laws, rules and regulations applicable to the use of the Payment Services, including the terms
					of use. </p>
				<p> 5.13. You may not use the Payment Services for any of the following:
					<List bulleted>
						<List.Item> For any illegal purpose, including fraud and money laundering, and for any purpose
							prohibited by regulation, statute, ordinance, or other governmental or regulatory
							limitation;
						</List.Item>
						<List.Item> For any debt-collection purpose;
						</List.Item>
						<List.Item> To circumvent merchant restrictions or obtain goods or services without paying;
						</List.Item>
						<List.Item> For commercial speculative trading purposes;
						</List.Item>
						<List.Item> To manipulate the price of any asset or currency;
						</List.Item>
						<List.Item> In any guise likely to result in complaints, reversals, chargebacks, fees, fines,
							penalties, or other liability toward DTSCB, other users, third parties, or yourself;
						</List.Item>
						<List.Item> To provide yourself with a cash advance from your Card, or help others to do so;
						</List.Item>
						<List.Item> In ways that bring a negative balance in any currency;
						</List.Item>
						<List.Item> To burden or overload our infrastructure, facilitate any viruses, malware, malicious
							code or other form of IT attack or attempt to gain access to our systems and information;
						</List.Item>
						<List.Item> To interfere with the DTSCB Service in any manner;
						</List.Item>
						<List.Item> To copy or reproduce our content or services in any manner;
						</List.Item>
						<List.Item> To interfere with our third party providers in any manner;
						</List.Item>
						<List.Item> To test credit card behaviours;
						</List.Item>
						<List.Item> To circumvent our policies and procedures;
						</List.Item>
						<List.Item> For use with any automatic trading, API, crawler scripts, or other methods
							inconsistent with ordinary use of the DTSCB Service;
						</List.Item>
						<List.Item> For the purpose of crowdfunding, or receiving the proceeds of a crowdfunding
							campaign;
						</List.Item>
						<List.Item> To harass our employees, agents, or other users; and
						</List.Item>
						<List.Item> To trade FX for speculative purposes or FX arbitrage.
						</List.Item>
					</List>

				</p>
				<p> 5.14. You guarantee to always have a zero or positive balance in your Card account. If your Card
					account goes into a negative balance, you promise to repay the negative balance immediately without
					any notice from us. We may send you reminders or take such other reasonable actions to recover the
					negative balance from you, for example, we may use a debt collection service or take further legal
					actions. We will charge you the additional Fees, as well as for any costs we may incur as a result
					of these additional collection efforts. </p>


				<h2> 6. Access by Third Party Providers </h2>
				<p> 6.1. You may consent to regulated third party providers (PISPs or AISPs) accessing your Account
					online to make payments or obtain information about balances or Transactions on your Card and/or
					Account. </p>
				<p> 6.2. The PISPs and/or AISPs must be appropriately registered and authorised in accordance with PSD2.
					You should check with the regulatory authority of the relevant country before giving consent to the
					relevant PISP/AISP. </p>
				<p> 6.3. Any consent you give to a third-party provider is an agreement between you and it, we will have
					no liability for any loss whatsoever, as a result of any such agreement. </p>
				<p> 6.4. Before giving consent, you should satisfy yourself as to what degree of access you are
					consenting to, how it will be used and who it may be passed on to. </p>
				<p> 6.5. You should make yourself aware of any rights to withdraw the consent of access from the
					third-party provider and what process it has in place to remove access. </p>
				<p> 6.6. To the extent permitted by law or regulation and subject to any right to refund you may have,
					between you and us, we are not responsible for any actions that the relevant third party takes in
					relation to suspending or terminating your use of their service or for any resulting losses. We are
					also not responsible for, or a party to, any agreement that you enter into with any relevant third
					party. You should make sure that you read and comply with such agreement or other applicable
					policies and note that these terms of use will continue to apply between us including to any Payment
					Services and our Fees as stated continue to apply. </p>
				<p> 6.7. Where appropriate, we may deny access to your Account, to any third party where we consider
					such access to be a risk of money laundering or terrorism financing, fraud or other criminal
					activity. Should we need to take these actions and where possible, we will give reasons for doing
					so, unless restricted by law or for internal security reasons. </p>

				<h2>7. Condition of Use at Certain Merchants</h2>

				<p> 7.1. In some circumstances we or Merchants may require you to have an Available Balance in excess of
					the Payment amount. </p>
				<p> 7.2. In some circumstances Merchants may require verification that your Available Balance will cover
					the Payment amount and initiate a hold on your Available Balance in that amount, examples include
					rental cars. In the event a Merchant places a pre-authorisation on your Account, you will not have
					access to these funds until the Payment is completed or released by the Merchant which may take up
					to 30 days. </p>
				<p> 7.3. If you use your Card at an automated petrol station, subject to Merchant acceptance, your Card
					may need to be pre-authorised for a pre-determined amount in the relevant currency. If you do not
					use the whole pre-authorisation or do not have Available Balance to obtain a pre-authorisation, it
					is possible that the pre-authorised amount will be held for up to 30 days before becoming available
					to you again. </p>
				<p> 7.4. Some Merchants may not accept payment using our Payment Services. It is your responsibility to
					check the policy with each Merchant. We accept no liability if a Merchant refuses to accept payment
					using our Payment Services. </p>
				<p> 7.5. In relation to any dispute between you and a Merchant, you are able to satisfy us that you have
					already made all efforts to resolve the dispute with the relevant Merchant, we will attempt to
					assist you so far as is reasonably practicable. We may charge you a chargeback processing fee as
					referenced in the Fees & Limits Schedule provided to you with this document, for any such assistance
					we may give you with any dispute. If there is an un-resolvable dispute with a Merchant in
					circumstances where the Card has been used for a Payment, you will be liable for the Payment and
					will have to resolve this directly with the relevant Merchant. </p>

				<h2> 8. Right to Cancel (“Cooling-Off”) </h2>
				<p> 8.1. When you purchase the Payment Services, then you have a “Cooling Off” period of 14 days
					beginning on the date of the successful registration of your Account, to withdraw and cancel the
					Payment Services, without any penalty but subject to deduction of any reasonable costs incurred by
					us in the performance of any part of the provision of services before you cancel. You must contact
					us within this 14-day period and inform us that you wish to withdraw, and you must not use the
					Payment Services. We will then cancel the Payment Services and reimburse the amount of Available
					Balance on the Account to you. However, we reserve the right to hold Available Balance for up to 30
					business days from receipt of your instructions before returning the balance, to ensure that details
					of all Transactions have been received. </p>
				<p> 8.2. After the Cooling Off period, you may only terminate the Payment Services as described in
					Clause 9 and Clause 10. </p>

				<h2> 9. Expiry & Redemption </h2>
				<p> 9.1. Your Card has an expiry date printed on it (the “Expiry Date”). The Card will no longer be
					usable following the Expiry Date, and you must not use it after that time, but you will still be
					able to receive and send funds to and from the Account associated with the Card. </p>
				<p> 9.2. If a Card expires before your Available Balance is exhausted, you can contact Customer Services
					to request a replacement Card, provided you do so 14 days before the Expiry Date printed on your
					Card and subject to payment of a Fee (where specified). We reserve the right to issue you with a
					replacement for an expired Card even if you have not requested one. If you have not requested a
					replacement Card, you will not be charged a Card Replacement Fee. </p>
				<p> 9.3. We shall have the absolute right to set-off, transfer, or apply sums held in the Account(s) or
					Cards in or towards satisfaction of all or any liabilities and Fees owed to us that have not been
					paid or satisfied when due. </p>
				<p> 9.4. We shall have the absolute right to close your Account and submit a chargeback claim for the
					relevant Transactions if your Account is in negative standing for more than 60 days. If our
					chargeback is successful, funds paid to your Account may only be used to credit your Card or
					Account, and your Account will remain closed. </p>
				<p> 9.5. We shall have the absolute right to close any Unverified Account that has not received any
					funds within 30 days since Account registration. </p>
				<p> 9.6. If your Account is inactive (including without limitation no access to the account or payment
					Transactions) for at least 2 consecutive years and has an Available Balance, we may (but we are not
					obliged to) notify you by sending an e-mail to your registered e-mail address and give you the
					option of keeping your Account open and maintaining or redeeming the Available Balance. If you do
					not respond to our notice within thirty (30) days, we will automatically close your Account and
					initiate a Transfer of your Available Balance to the last payment account notified by you to us
					(your “Nominated Bank Account”) or by cheque to your last known address. </p>


				<h2> 10. Termination or Suspension of Your Account and/or Processing of Transactions </h2>


				<p>10.1. We may end or suspend your use of the DTSCB Payment Services without notice to you, at any
					time, and with immediate effect in the following circumstances:</p>
				<p>
					<List bulleted>
						<List.Item> You have provided us with false or misleading information;
						</List.Item>
						<List.Item> We determine, at our sole discretion, that your use of your DTSCB Profile, DTSCB
							Account is, or potentially is, somehow, detrimental or harmful to Us;
						</List.Item>
						<List.Item> You have breached these Terms or engaged in any restricted activity;
						</List.Item>
						<List.Item> We suspect that you have breached these Terms or otherwise acted fraudulently,
							including during the AML/KYC verification process;
						</List.Item>
						<List.Item> There is suspicion of unauthorised or fraudulent access to or use of your Account or
							that any of its security features have been compromised, including the unauthorised or
							fraudulent initiation of a Transaction;
						</List.Item>
						<List.Item> We are unable to verify your identity or any other information pertaining to you,
							your Account or a Transaction;
						</List.Item>
						<List.Item> We are required to do so to comply with a court order, law, regulation, regulatory
							decree or ombudsman’s orders;
						</List.Item>
						<List.Item> You have been declared bankrupt;
						</List.Item>
						<List.Item> You have been declared deceased.
						</List.Item>
					</List>
				</p>
				<p> 10.2. We exclude all liability for any losses incurred whenever we have suspended an account in
					accordance with the Paragraph 10.1. DTSCB is not required and may be prohibited by law to provide
					any further information regarding your account closure or suspension. We may also terminate your
					DTSCB profile and your use of the DTSCB Service at any time for any reason we may think appropriate
					by giving you one week notice. </p>
				<p> 10.3. Additionally to the Clause 10.1. we may (i) Contact and/or warn third parties in connection
					with your actions, including other users, your bank or card issuer, law enforcement or regulatory
					bodies; (ii) Refuse to complete transactions; (iii) Fully or partially reverse a transaction or
					exchange; and (iv) Pursue legal action against you. </p>
				<p> 10.4. Your use of the Payment Services will also end when your ability to initiate all Transactions
					ceases. </p>
				<p> 10.5. Where it is practicable and lawful for us to do so or would not compromise reasonably
					justified security reasons, we will notify you via email of the suspension or restriction and the
					reasons for it before such measures take place or immediately thereafter. </p>
				<p> 10.6. We will reinstate your Account or execute the relevant Transaction(s) as soon as practicable
					after the reasons pursuant to Clause 10.1. no longer apply or exist. </p>
				<p> 10.7. If you wish to terminate the Payment Services at any time, you must request termination and
					the return of your Available Balance by email to Us from the email address registered in your
					Account. Our Customer Services department will then suspend all further use of your Payment
					Services. </p>
				<p> 10.8. Once we have received all the necessary information from you (including any Customer Due
					Diligence) and all Transactions and applicable fees and charges have been processed, we will refund
					you any Available Balance less any fees and charges payable to Us, provided that: i. you have not
					acted fraudulently or with gross negligence or in such a way as to give rise to reasonable suspicion
					of fraud or gross negligence; and ii. we are not required to withhold your Available Balance by law
					or regulation, or at the request of the police, a court or any regulatory authority. </p>
				<p> 10.9. Once the Payment Services have been terminated, it will be your responsibility to destroy the
					Card(s) that were provided to you. </p>
				<p> 10.10. If, following reimbursement of your Available Balance, any further Transactions are found to
					have been made or charges or Fees incurred using the card or we receive a reversal of any prior
					funding Transaction, we will notify you of the amount and you must immediately repay to us such
					amount on demand as a debt. </p>

				<h2>11. Loss or Theft of your Card or Misappropriation of Your Account</h2>
				<p> 11.1. If your Card is lost or stolen or if you think someone is using your Card, PIN and/or
					Passwords without your permission or if your Card is damaged or malfunctions: i. you must contact us
					as soon as possible and you must provide us with your Account or Card number and either your
					Username and Password or some other identifying details acceptable to us so that we can be sure we
					are speaking to you; and ii. provided we have obtained your consent to close the Account, we will
					then provide you with a replacement Card with a corresponding new Account loaded with an amount
					equivalent to your last Available Balance. </p>
				<p> 11.2. If you subsequently find or retrieve a Card that you have reported lost or stolen, you must
					immediately destroy the found Card by cutting it in half through the magnetic stripe and chip. </p>
				<p> 11.3. You agree to help us, our agents, regulatory authorities and the police if your Card is lost,
					stolen or if we suspect that the Payment Services are being misused. </p>

				<h2>12. Liability for Unauthorised or Incorrectly Executed Transactions</h2>

				<p> 12.1. Subject to Clauses 12.2 and 12.3 we will reimburse you in full for all unauthorised
					Transactions sent from your Account immediately and in any event no later than the end of the
					following Business Day after noting or being notified of the Transaction (except where we have
					reasonable grounds for suspecting fraud), provided that you have informed us of the unauthorised
					Transaction without undue delay after becoming aware of the Transaction and in any event, no later
					than 13 months after the Transaction was executed. Where applicable, we shall restore your Account
					to the state in which it would have been had the unauthorised Transaction not taken place, so that
					that the credit value date shall be no later than the date the amount had been debited. </p>
				<p> 12.2. You are liable for any losses incurred by an unauthorised Transaction if you have acted
					fraudulently or failed either intentionally or through gross negligence, to use your Account in
					accordance with the terms of these terms of use or to keep your passwords confidential and
					secure. </p>
				<p> 12.3. You shall not be liable for losses incurred by an unauthorised Transaction which takes place
					after you have notified Us of a compromise of your passwords, unless you have acted fraudulently, or
					where we have failed to provide you with the means to notify us in the agreed manner without delay
					on you becoming aware of the loss, theft, misappropriation or unauthorised use of your Card or
					Account. </p>
				<p> 12.4. We shall not be liable for a refund or losses incurred by an incorrectly or non-executed
					payment Transaction if the details of the payee’s account provided by you were incorrect or we can
					prove that the full amount of the Transaction was duly received by the payment service provider of
					the payee. </p>
				<p> 12.5. We shall not be liable for any unauthorised or incorrectly executed Transactions in case the
					Transaction was affected by abnormal and unforeseeable circumstances beyond our reasonable control
					or where we acted in accordance with a legal obligation. </p>
				<p> 12.6. Where we are liable for the incorrect execution of a Transfer or SEPA Transfer that you
					receive under this Agreement, we shall immediately place the amount of the Transaction at your
					disposal in accordance and credit the corresponding amount to your Account no later than the date on
					which the amount would have been value dated, had the Transaction been correctly executed. </p>
				<p> 12.7. Where we are liable for the incorrect execution of a Payment, Transfer or SEPA Transfer by you
					as payer, we shall, without undue delay, refund to you the amount of the non-executed or defective
					Transaction, and, where applicable, restore the debited Account to the state in which it would have
					been had the defective Transaction not taken place. </p>
				<p> 12.8. In the case of a non-executed or defectively executed Payment, Transfer or SEPA Transfer by
					you as payer, we shall, regardless of whether we are liable, on request, make immediate efforts to
					trace the Transaction and notify you of the outcome, free of charge. </p>
				<p> 12.9. A Payment initiated by or through a payee (e.g. a Merchant) shall be considered to be
					unauthorised if you have not given your consent for the Payment to be made. If you believe that a
					Payment has been made without your consent you should contact Us. </p>
				<p> 12.10. A claim for a refund of an authorised Payment initiated by or through a payee (e.g. a
					Merchant) where the authorisation did not specify an exact amount of payment Transaction (and the
					amount of the Payment exceeded the amount that you reasonably could have expected taking into
					account your previous spending pattern, these terms of use and the circumstances of the case), must
					be made within 8 weeks from the date on which the funds were deducted from your Available Balance.
					Within 10 Business Days of receiving your claim for a refund or within 10 Business Days of receiving
					further information from you, we will either refund the full amount of the Payment as at the date on
					which the amount of the Payment was debited or provide you with justification for refusing the
					refund. </p>
				<p> 12.11. The right to a refund under this Clause 12 does not apply where you have given consent
					directly to us for the Payment to be made and, if applicable, information on the Payment was
					provided or made available to you by us or the payee in an agreed manner for at least four weeks
					before the due date. </p>
				<p> 12.12. If you are not satisfied with the justification provided for refusing the refund or with the
					outcome of your claim for a refund, you may submit a complaint to us or contact the complaints
					authority as described in Clause 14. </p>
				<p> 12.13. If at any time we have incorrectly deducted money from your Available Balance, we shall
					refund the amount to you. If we subsequently establish that the refunded amount had been correctly
					deducted, we may deduct it from your Available Balance and may charge you a fee. If you do not have
					sufficient Available Balance, you must repay us the amount immediately on demand. </p>
				<p> 12.14. Where any request, Transaction, disputed Transaction, arbitration, or reversed Transaction
					involves third party costs, you remain liable for these and they will be deducted from your Account
					or otherwise charged to you. </p>

				<h2>13. General Liability</h2>
				<p> 13.1. To ensure the safety of your DTSCB profile and Account, you have the following duties: </p>
				<p>
					<List bulleted>
						<List.Item> Use a strong password for your DTSCB Profile;
						</List.Item>
						<List.Item> Keep your phone safe and ensuring it is password-protected at all times;
						</List.Item>
						<List.Item> Enable 2-factor authentication to access your DTSCB Profile;
						</List.Item>
						<List.Item> Keep your DTSCB Card pin and other unique numbers safe and secure;
						</List.Item>
						<List.Item> Close the App when you are not using it;
						</List.Item>
						<List.Item> Log out of the DTSCB website when you are not using it;
						</List.Item>
						<List.Item> Change your mobile, card pin and DTSCB Account passwords regularly and not sharing
							these with anyone;
						</List.Item>
						<List.Item> Keep your email account secure at all time.
						</List.Item>
					</List>
				</p>

				<p> 13.2. If you believe your mobile, DTSCB Card, or the login details for your DTSCB Profile have been
					lost, stolen, or accessed without authorisation, you must contact us immediately at
					support@dtscirclebank.com. </p>

				<p>13.3. You are responsible for:</p>
				<p>
					<List bulleted>
						<List.Item> All charges and other amounts incurred through your use of the DTSCB Payment
							Services at any time, including any amounts outstanding after you stop using the DTSCB
							Payment Services or if we suspend or cancel your DTSCB Profile; and
						</List.Item>
						<List.Item> Complying with any and all laws, rules and regulations of your jurisdiction that may
							apply to you in connection with your use of the DTSCB Payment Services, including but not
							limited to activities of import and export, taxes or foreign currency transactions. We will
							not be responsible for the execution of tax obligations, or calculation and transfer of
							taxes applied to you.
						</List.Item>
					</List>
				</p>
				<p> 13.4. Without prejudice to Paragraph 12: </p>
				<p>
					<List>
						<List.Item> Neither party shall be liable to the other for indirect or consequential loss or
							damage (including without limitation loss of business, profits or revenues), incurred in
							connection with these terms of use, whether arising in contract, tort (including
							negligence), breach of statutory duty or otherwise;
						</List.Item>
						<List.Item> we shall not be liable:
						</List.Item>
						<List.Item> (ii)1. If you are unable to use the Card or Payment Services for any valid reason
							stated in these terms of use;
						</List.Item>
						<List.Item> (ii)2. For any fault or failure beyond our reasonable control relating to the use of
							the Payment Services, including but not limited to, a lack of Available Balance or fault in
							or failure of data processing systems;
						</List.Item>
						<List.Item> (ii)3. For any loss, fault or failure relating to the use of a Third-Party Provider;
						</List.Item>
						<List.Item> (ii)4. If a Merchant refuses to accept a Payment or fails to cancel an authorisation
							or pre-authorisation;
						</List.Item>
						<List.Item> (ii)5. For the goods or services that are purchased with your Card;
						</List.Item>
						<List.Item> (ii)6. For any dispute you might have with a Merchant or other user of the Payment
							Service where you acted with:
							<List>
								<List.Item>
									(ii)6.1. Undue delay;
								</List.Item>
								<List.Item>
									(ii)6.2. Fraudulently; or
								</List.Item>
								<List.Item>
									(ii)6.3. With gross negligence. (including where losses arise due to your failure to
									keep us notified of your correct personal details).
								</List.Item>
							</List>
						</List.Item>
					</List>
				</p>
				<p> 13.5. You agree that you will not use the Payment Services in an illegal manner, and you agree to
					indemnify us against any claim or proceeding brought about by such illegal use of the Payment
					Services by you, your Authorised Person(s) and Additional Cardholder(s). </p>
				<p> 13.6. You are solely responsible for your interactions with Merchants or other users of the Payment
					Service. We reserve the right, but have no obligation, to monitor or mediate such disputes. </p>
				<p> 13.7. To the fullest extent permitted by relevant law, Our total liability under or arising from
					these terms of use be limited as follows: i. where your Card is faulty due to our default, our
					liability shall be limited to replacement of the Card or, at our choice, repayment to you of the
					Available Balance; an ii. in all other circumstances of our default, our liability will be limited
					to repayment of the amount of the Available Balance. </p>
				<p> 13.8. Nothing in these terms of use shall exclude or limit either Party’s liability in respect of
					death or personal injury arising from that party’s negligence or fraudulent misrepresentation. </p>
				<p> 13.9. No party shall be liable for or be considered in breach of these terms of use on account of,
					any delay or failure to perform as required by these terms of use as a result of any causes or
					conditions which are beyond such Party’s reasonable control. </p>
				<p> 13.10. The DTSCB Payment Services allows you to purchase services and products, and provides you
					with content and information that are owned or developed also by third parties, or that operate on
					or are supported by third party networks. As we do not have any control over these services or
					products that you purchase, or content you view using the DTSCB Payment Services, we are not
					responsible for them in any way. </p>
				<p> 13.11. We also give you no guarantee that the DTSCB Payment Services fits your need 100%. To the
					extent we are able to do so, we exclude any commitments that may be implied by law. For any claim,
					our responsibility to you will be limited to any amounts you have paid us in the 12 months preceding
					your claim. If you have not paid us anything, we will not be responsible for any claim arising from
					you out of the provision of the DTSCB Payment Services. </p>

				<h2> 14. Apple App Store Provisions </h2>
				<p> 14.1. This paragraph applies where the App has been acquired from the Apple App Store. You
					acknowledge and agree that the Terms are solely between you and DTSCB, not Apple, Inc. (“Apple”) and
					that Apple has no responsibility for the App or content thereof. Your use of the App must comply
					with the App Store Terms of Service. </p>
				<p> 14.2. You acknowledge that Apple has no obligation whatsoever to furnish any maintenance and support
					services with respect to the App. In the event of any failure of the App to conform to any
					applicable warranty, you may notify Apple, and (where applicable) Apple will refund the purchase
					price for the App to you; to the maximum extent permitted by applicable law, Apple will have no
					other warranty obligation whatsoever with respect to the App, and any other claims, losses,
					liabilities, damages, costs or expenses attributable to any failure to conform to any warranty will
					be solely governed by the Terms and any law applicable to DTSCB as provider of the App. </p>
				<p> 14.3. You acknowledge that Apple is not responsible for addressing any claims of you or any third
					party relating to the App or your possession and/or use of the App, including, but not limited to:
					(i) product liability claims; (ii) any claim that the App fails to conform to any applicable legal
					or regulatory requirement; and (iii) claims arising under consumer protection or similar
					legislation; and all such claims are governed solely by the Terms and any law applicable to DTSCB as
					provider of the software. </p>
				<p> You acknowledge that, in the event of any third-party claim that the App or your possession and use
					of that App infringes that third party’s intellectual property rights, DTSCB, not Apple, will be
					solely responsible for the investigation, defence, settlement and discharge of any such intellectual
					property infringement claim to the extent required by the Terms. </p>
				<p> 14.4. You represent and warrant that: </p>
				<p>
					<List bulleted>
						<List.Item> You are not located in a country that is subject to U.S. Government embargo, or that
							has been designated by the U.S. Government as a “terrorist supporting” country;
						</List.Item>
						<List.Item> You are not listed on any U.S. Government list of prohibited or restricted parties.
						</List.Item>
					</List>
				</p>
				<p> 14.5. You and DTSCB acknowledge and agree that Apple, and Apple’s subsidiaries, are third-party
					beneficiaries of the Terms of Service as relates to your license of the App, and that, upon your
					acceptance of the terms and conditions of the Terms of Service, Apple will have the right (and will
					be deemed to have accepted the right) to enforce the Terms of Service as relates to your license of
					the App against you as a third-party beneficiary thereof </p>

				<h2> 15. Downloading Our App from other App Stores </h2>
				<p> 15.1. Where you download our App from any other app store or distribution platform other than the
					Apple App Store, including the Google Play Store (the “Distribution Platform”) you agree that: </p>
				<p>
					<List bulleted>
						<List.Item> the Terms are between you and DTSCB, and not with the provider of the Distribution
							Platform (“Store Provider”);
						</List.Item>
						<List.Item> Your use of the App must comply with the Store Provider’s then-current Distribution
							Platform Terms of Service;
						</List.Item>
						<List.Item> The Store Provider is only a provider of the Distribution Platform where you
							obtained the App;
						</List.Item>
						<List.Item> DTSCB, and not the Store Provider, is solely responsible for the App;
						</List.Item>
						<List.Item> The Store Provider has no obligation or liability to you with respect to the App or
							the Terms;
						</List.Item>
						<List.Item> You acknowledge and agree that the Store Provider is a third-party beneficiary to
							the Terms as it relates to the App.
						</List.Item>
					</List>
				</p>

				<h2>16. Dispute Resolution</h2>
				<p> 16.1. We are committed to providing an excellent customer experience for all our Customers. If we do
					not meet your expectations in any way, we want to have the opportunity to put things right. </p>
				<p> 16.2. In the first instance, your initial communication will be with Our Customer Services Team who
					can be contacted by email to compliance@dtscirclebank.com . Our Customer Services Team will listen
					to your needs and will do their best to solve your issue promptly and fairly. Reply to any
					complaints will be provided within 15 business days from receiving them, except in exceptional
					circumstances beyond our control. In the case, the answer will require more time, we will inform you
					about the time length. We value the opportunity to review the way we do business and help us meet
					our customers’ expectations. </p>

				<h2> 17. Changes to the Terms and Conditions </h2>
				<p> 17.1. We may update or amend these terms and conditions (including our Fees & Limits Schedule).
					Notice of any changes will be given on our Website, or by e-mail notification, or by SMS at least 2
					months in advance. By continuing to use the Payment Services after the expiry of the 2-month notice
					period you acknowledge that you indicate your acceptance to be bound by the updated or amended terms
					and conditions. If you do not wish to be bound by them, you should stop using the Payment Services
					and terminate these terms of use in accordance with provisions of these Terms and conditions before
					the changes take effect. </p>
				<h2> 18. Miscellaneous </h2>
				<p> 18.1. Nothing in this Agreement is intended to confer a benefit on any person who is not a party to
					it, and no such person has any right under the Contracts (Rights of Third Parties) Act 1999 to
					enforce any terms of these terms of use, but this Clause do not affect a right or remedy of a third
					party which exists or is available apart from that Act. </p>
				<p> 18.2. Any waiver or concession we may allow you, will not affect our strict rights and your
					obligations under these terms of use. </p>
				<p> 18.3. These terms of use and the documents referred to in it, constitute the entire agreement and
					understanding of the parties and supersede any previous agreement between the parties relating to
					the subject matter of these terms of use. </p>

				<h2>19. Funds Protection</h2>
				<p> 19.1. All relevant funds corresponding to your Available Balance are segregated from our funds and
					held in the Customer Funds Account in accordance with the safeguarding requirements of the
					Electronic Money Regulations 2011 by law. In the event that we became insolvent those funds are
					protected against claims made by any of our creditors. </p>

				<h2> 20. Regulation & Law </h2>
				<p> 20.1. These terms of use shall be governed by and interpreted in accordance with the laws of the
					United Kingdom, and any dispute or claim in relation to services and/or terms of use or any other
					documentation shall be subject to the non-exclusive jurisdiction of the English courts. However, if
					you reside outside of the United Kingdom, you cannot bring an action in your country of residence
					and need to place a dispute in the English court. </p>
			</Segment>
		</Container>
	);
}

export default Terms;
