export enum Agents {
	AZURE = 'AZURE',
	AZUREPAY = 'AZUREPAY',
    COSPAY = 'COSPAY',
	WALLEXCARD = 'WALLEXCARD',
	DTSCB = 'DTSCB',
	DTS = 'DTS',
	DTSUS = 'DTSUS',
	TYNCOFEX = 'TYNCOFEX',
	WZ = 'WZ',
	CUSTODYFY = 'CUSTODYFY',
	SALAZAR = 'SALAZAR',
	TUTUS = 'TUTUS',
	YESSY = 'YESSY',
	OLAMIT = 'OLAMIT',
	GAVICO = 'GAVICO'
}
