import React, { useEffect } from 'react';
import { Divider, Grid, Header, Segment } from 'semantic-ui-react';
import { Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Token } from '@/features/tokens/types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { showInfoNotification } from '@/features/swal/slice';
import { tokenDescription } from '../../Tokens';
import FileUpload from '@/components/Shared/FileUpload';
import useFileUpload from '@/helpers/customHook/useFileUpload';
import { isValidCyptoAddress } from '@/features/tokens/slice';

export interface SendToExternalPayload {
	token: Token,
	amount: number,
	reference: string,
	beneficiaryName: string,
	beneficiaryCryptoAddress: string,
	beneficiaryCryptoNetwork: string,
	beneficiaryIdentityNo?: string,
	selectedFile?: any,
}

interface Props {
	token: Token,
	payload: SendToExternalPayload,
	onConfirm: (value: SendToExternalPayload) => void
}

const SendToExternalForm = ({ token, payload, onConfirm }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');


	const initialValues = {
		userToken: tokenDescription(payload?.token ?? token),
		address: payload?.beneficiaryCryptoAddress ?? '',
		network: payload?.beneficiaryCryptoNetwork ?? '',
		amount: payload?.amount ?? '',
		beneficiaryName: payload?.beneficiaryName ?? '',
		beneficiaryIdentityNo: payload?.beneficiaryIdentityNo ?? '',
		reference: payload?.reference ?? '',
	};

	const fileUpload = useFileUpload(1);

	useEffect(() => {
		if (payload?.selectedFile && fileUpload.selectedFiles.length === 0) {
			fileUpload.setSelectedFiles([payload.selectedFile]);
		}
	}, [payload?.selectedFile]);


	const rawCurrentHoldings = parseFloat(token?.availableHoldings ?? '0');

	const validationSchema = Yup.object({
		network: Yup.string().required(t('form.validator.required')),
		reference: Yup.string().required(t('form.validator.required')),
		address: Yup.string().required(t('form.validator.required')),
		beneficiaryName: Yup.string().required(t('form.validator.required')),
		amount: Yup.number().label(t('tokens.header.withdrawalAmount'))
			.required(t('form.validator.required'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.min(0.00001)
			.test('amount', t('tokens.header.maxTokens', { amount: rawCurrentHoldings }), (value) => value <= rawCurrentHoldings),
	});

	const submit = async (formData, formikProps) => {
		const { amount, address, network, reference, beneficiaryName, beneficiaryIdentityNo } = formData;
		const fiat = amount * parseFloat(token.price);
		const { setSubmitting, setFieldError } = formikProps;
		if (!fileUpload.isValidFiles(fiat)) {
			showInfoNotification('sendmoneyTranslation.data.documentRequired');
			setSubmitting(false);
			return;
		}
		const addressIsValid = await isValidCyptoAddress(address, network);

		if (addressIsValid) {
			onConfirm({
				amount,
				reference,
				beneficiaryName,
				beneficiaryIdentityNo,
				beneficiaryCryptoAddress: address,
				beneficiaryCryptoNetwork: network,
				selectedFile: fileUpload.selectedFiles[0], token: token
			});
		} else {
			setFieldError('address', t('tokens.header.cryptoAddressInvalid'));
		}

		setSubmitting(false);
	};


	const networkOptions = token.networksArray?.map((item, idx) => { return { key: idx, text: item, value: item }; }) || [];

	return (
		<Grid.Column width={11}>
			<Segment className='token-trade-window' id='send-token-details-form'>
				<Header>{t('tokens.header.externalWallet')}</Header>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting }) => {
						return (
							<Form >
								<Input fluid
									className="token-disabled"
									name="userToken"
									label={t('tokens.header.token')}
									disabled={true}
									type="text"
								/>
								<Select fluid
									name='network'
									label={t('tokens.header.network')}
									options={networkOptions}
									errorPrompt
								/>
								<Input fluid
									name="address"
									label={t('tokens.header.withdrawalAddress')}
									errorPrompt
								/>
								<Input fluid
									name="amount"
									label={t('tokens.header.withdrawalAmount')}
									type="number"
									placeholder={'0.00 '+token.symbol}
									errorPrompt
									onWheel={(e) => e.target.blur()}
								/>
								<Divider hidden />
								<Header dividing >{t('tokens.header.details')}</Header>
								<Divider hidden />
								<Input fluid
									name="beneficiaryName"
									label={t('tokens.header.beneficiaryName')}
									errorPrompt
								/>
								<Input fluid
									name="beneficiaryIdentityNo"
									label={t('tokens.header.beneficiaryId')}
									errorPrompt
								/>
								<TextArea
									fluid
									name="reference"
									errorPrompt
									label={t('tokens.header.reference')}
									maxLength="140"
								/>
								<FileUpload
									selectedFiles={fileUpload.selectedFiles}
									hideMaxSize={true}
									reverse={true}
									instruction={<><b> {t('tokens.header.paymentDocument')}</b> <br />{t('tokens.header.fileInstruction')}</>}
									uploadFiles={fileUpload.uploadFiles}
									deleteAttachment={fileUpload.deleteAttachment} />
								<SubmitButton
									className='tokens-trade-submit'
									disabled={!isEmpty(errors) || isSubmitting}
									primary fluid size="large" type="submit">
									{t('form.buttons.submit')}
								</SubmitButton>
							</Form>
						);
					}}
				</Formik>
			</Segment>
		</Grid.Column>
	);
};


export default SendToExternalForm;
