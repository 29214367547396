import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import './DocumentFront.css';
import { Button, Grid, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

interface Props {
	onContinue: () => void,
	onUpload: (file) => void
}

const ResidenceProof = ({ onContinue, onUpload }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');

	const onDrop = useCallback((acceptedFiles) => {
		acceptedFiles.forEach((file) => {
			onUpload(file);
		});
	}, []);

	const removeAll = useCallback(() => {
		onUpload(null);
		acceptedFiles.splice(0);
	}, [onUpload]);

	const { acceptedFiles, getRootProps, getInputProps } = useDropzone({ onDrop });
	const files = acceptedFiles.map(file => (
		<span key={file.name}>
			{file.name}
		</span>
	));
	return (
		<>
			<Grid>
				<Grid.Row><h2>{t('employee.verification.form.utility.header')}</h2></Grid.Row>
				<Grid.Row>
					<span>
						{t('employee.verification.form.utility.description1')}
						<b>{t('employee.verification.form.utility.description2')}</b>
						{t('employee.verification.form.utility.description3')}
					</span></Grid.Row>
				<Grid.Row>
					<div {...getRootProps()} id='file-container'>
						<input {...getInputProps()} id='file-drop' />
						<span><Icon name='upload' /> {t('employee.verification.form.utility.upload')}</span>
					</div>
				</Grid.Row>
				{files.length > 0 &&
					<Grid.Row>
						<Icon size="large" name="file outline" />
						<div className="fileName">{files}</div>
						<Icon className="fileName" size="large" name='trash' link onClick={() => removeAll()} />
					</Grid.Row>}
				<Grid.Row>
					<Grid.Column width={3} mobile={6}>
						<Button disabled={files.length === 0} primary size='large' fluid onClick={() => onContinue()}>{t('authenticator.next')}</Button>
					</Grid.Column>
				</Grid.Row>
			</Grid>
		</>
	);
};

export default ResidenceProof;
