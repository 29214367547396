
import { postTransferFee } from '@/features/fees/slice';
import { TransferFeePayloadType } from '@/features/fees/types';
import { showErrorNotification } from '@/features/swal/slice';
import { useEffect, useState } from 'react';

const useFee = (accountId: number, proc: string, amount: number) => {

	const [fee, setFee] = useState<number>(0);
	const [feeError, setFeeError] = useState<boolean>(false);

	const payload: TransferFeePayloadType = {
		accountId,
		proc,
		amount
	};

	const getFee = async (feePayload: TransferFeePayloadType) => {
		try { 
			if ((accountId) && (amount) && (proc)) {
				const result = await postTransferFee(feePayload);
				setFee(result.fee);
				setFeeError(false);
			}
		}
		catch (e) {
			setFeeError(true);
			showErrorNotification(e);
		}
	};

	useEffect(() => {
		getFee(payload);
	}, [accountId, proc, amount]);


	return { fee, feeError };
};

export default useFee;
