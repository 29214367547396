import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Image, Item } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

interface FeeProps {
	option: any,
	feeType: FeeType
}

enum FeeType {
	CREATE, DELIVERY, MONTHLY
}

const Fee = ({ option, feeType }: FeeProps): React.ReactElement => {
	const [fee, setFee] = useState<any>(null);
	const [fees, setFees] = useState<any>(null);
	const { t } = useTranslation('translations');

	useEffect(() => {
		if(feeType === FeeType.CREATE) {
			setFees(option.fees);
		}
		if(feeType === FeeType.DELIVERY) {
			const { subProcesses } = option;
			if(!subProcesses) {
				setFees([]);
			} else {
				const shipmentKey = Object.keys(subProcesses).find(k => subProcesses[k].type === 'SHIPMENT');
				const shipmentProc = subProcesses[shipmentKey];
				if(!shipmentProc) {
					setFees([]);
				} else {
					setFees(subProcesses[shipmentKey].fees);
				}
			}
		}
		if(feeType === FeeType.MONTHLY) {
			setFees(option.fees);
		}
	}, [option]);

	useEffect(() => {
		if (!fees) return;
		const fixedFee = fees.find(fee => fee.type === 'FIXED' || fee.type === 'FIXED_MONTH');
		setFee(fixedFee);
	}, [fees]);

	const getValue = () => {
		if(feeType === FeeType.CREATE) {
			return fee.feeAmount;
		}
		if(feeType === FeeType.DELIVERY) {
			return fee.feeAmount;
		}
		if(feeType === FeeType.MONTHLY) {
			if(fee.type === 'FIXED_MONTH') {
				return fee.feeMonth;
			} else {
				return 0;
			}
		}
		return 'N/A';
	};

	const getPrefix = () => {
		if(feeType === FeeType.CREATE) {
			return `${t('cards.issue')}: ${fee.ccy} `;
		}
		if(feeType === FeeType.DELIVERY) {
			return `${t('cards.delivery')}: ${fee.ccy} `;
		}
		if(feeType === FeeType.MONTHLY) {
			return `${t('cards.monthly')}: ${fee.ccy} `;
		}
		return 'N/A';
	};

	if (!fee) return <span></span>;

	return <NumberFormat
		displayType={'text'}
		decimalScale={2}
		fixedDecimalScale={true}
		thousandsGroupStyle='thousand'
		thousandSeparator={true}
		prefix={getPrefix()}
		value={getValue()} />;
};

interface Props {
	onSubmit: (payload: any) => void,
	options: any[]
}

const CardDesign = ({ onSubmit, options }: Props): React.ReactElement => {
	const [operation, setOperation] = useState<any>(null);
	const { t } = useTranslation('translations');
	useEffect(() => {
		setOperation(null);
	}, [options]);

	return (<div>
		<Item.Group>
			{options.map(option => (
				<Item
					onClick={() => setOperation(option.proc)}
					key={option.proc}
					className={`yourcardItem ${operation === option.proc ? 'active' : ''}`}>
					<Image  className="newcardOption" src={option.config.serviceProviderLogo} />
					<Item.Content>
						<Item.Header>{option.name}</Item.Header>
						<Item.Meta>
							<Fee option={option} feeType={FeeType.CREATE}/>
							<Divider hidden fitted/>
							<Fee option={option} feeType={FeeType.DELIVERY}/>
							<Divider hidden fitted/>
							<Fee option={option} feeType={FeeType.MONTHLY}/>
						</Item.Meta>
					</Item.Content>
					<Checkbox radio checked={operation === option.proc}></Checkbox>
				</Item>
			))}
		</Item.Group>
		<Divider clearing />
		<Button disabled={!operation} onClick={() => onSubmit({ action: 1, data: options.find(o => o.proc === operation) })} className="mainbasicbtn" fluid basic primary>{t('cards.continue')}</Button>
	</div>
	);
};

export default CardDesign;
