import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Header, Icon, Message, Comment, Divider, Grid, Button } from 'semantic-ui-react';
import { showErrorNotification, showSuccessNotification } from '@features/swal/slice';
import { downloadAttachment, getAuthorInitials, replyTicket } from '@features/tickets/slice';
import { Ticket, TickerRequestMoney, TicketAttachment, TicketMessage, TicketType } from '@features/tickets/types';
import { formatDateWithPattern } from '@helpers/date';
import SendMoney from '@/components/Banking/Shared/SendMoney';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { User } from '@/features/user/types';
import { Formik } from 'formik';
import { Form, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import { isEmpty } from 'lodash';
import * as Yup from 'yup';

interface Props {
	message: Ticket
}

const Chat = ({ message }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [sendMoneyModal, setSendMoneyModal] = useState<boolean>(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const hiddenFileInput = React.useRef(null);
	const { user } = useSelector(
		(state: RootState) => state.user
	);
	const fileTypes = [
		'image/jpg',
		'image/jpeg',
		'image/png',
		'application/pdf',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	];

	const validationSchema = Yup.object({
		message: Yup.string().required(t('form.validator.required')),
	});

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const uploadFiles = (files: any) => {
		if (files.length === 0 || files.length > 5) {
			return;
		}

		for (let i = 0; i < files.length; i++) {
			if (files[i].size > 5000000) {
				showSuccessNotification(t('swal.FILE_SIZE_TOO_LARGE', { fileName: files[i].name }));
				continue;
			}
			if (fileTypes.indexOf(files[i].type) != -1 && selectedFiles.length < 5) {
				selectedFiles.push(files[i]);
				setSelectedFiles(selectedFiles);
			}
		}
	};

	const handleUploadClick = () => {
		hiddenFileInput.current.click();
	};

	const deleteAttachment = (index: number) => {
		selectedFiles.splice(index, 1);
		setSelectedFiles(selectedFiles);
	};

	const onSend = (value, { setSubmitting, setErrors, resetForm }) => {
		const get = async () => {
			try {
				if (value.message === undefined) {
					return;
				}
				const formData = new FormData();
				formData.append('ticketId', message.id.toString());
				formData.append('message', value.message);

				if (value.files) {
					value.files.map(file => {
						formData.append('files', file);
					});
				}

				await replyTicket(formData);
				showSuccessNotification('Reply successfully sent');
				resetForm({});
				setSelectedFiles([]);
				//fetchData();
			} catch (e) {
				showErrorNotification(e);
				setErrors({ submit: e.message });
				setSubmitting(false);
			}
		};
		get();
	};

	return (<div id="messageDetails">
		<SendMoney message={message as TickerRequestMoney} open={sendMoneyModal} onClose={() => { setSendMoneyModal(false); }} />
		<Container>
			<Comment.Group size='large' style={{ maxWidth: 'unset' }}>
				<Header as='h3' dividing>
					Subject: {message.title}
				</Header>
				<div className={(message.incidentType === TicketType.INCIDENT || message.incidentType === TicketType.STOCK_ORDER) ? 'incidentMessages' : 'messages'} >
					{message.ticketMessages.map((entry, index) => (
						<ChatMessage message={entry} key={index + entry.createdDate} ticketId={message.id} user={user} />
					))}
				</div>
			</Comment.Group>


			{message.incidentType === TicketType.TRANSFER_REQUEST && (
				<Button onClick={() => { setSendMoneyModal(true); }} content={t('inbox.sendPayment')} primary />
			)}

			{(message.incidentType === TicketType.INCIDENT || message.incidentType === TicketType.STOCK_ORDER) &&
				<><Divider />
					<Formik
						initialValues={{
							message: ''
						}}
						onSubmit={onSend}
						validationSchema={validationSchema}
						render={({ setFieldValue, isSubmitting, errors, dirty }) => {
							return (
								<Form>
									<TextArea
										label='Message'
										name="message"
										style={{ minHeight: 100 }}
										errorPrompt
									/>
									<Divider hidden />

									<input type="file"
										multiple
										accept="image/jpg, image/png, image/jpeg, .pdf, .doc, .docx"
										name="files"
										ref={hiddenFileInput}
										onChange={e => {
											uploadFiles(e.currentTarget.files);
											setFieldValue('files', selectedFiles);
										}}
										style={{ display: 'none' }}
									/>
									<Grid columns={2} style={{ paddingBottom: '1rem' }}>
										<Grid.Column>
											{selectedFiles.length > 0 && (
												<Grid className="uploadedFiles" style={{ justifyContent: 'left' }} >
													{selectedFiles.map((file, index) =>
														<Grid.Row className="uploadedFile" key={file.name + index} style={{ paddingTop: 0 }}>
															<Icon size="large" name="file outline" />
															<div className="fileName">{file.name}</div>
															<Icon className="deleteAttachment"
																onClick={() => {
																	deleteAttachment(index);
																	setFieldValue('files', selectedFiles);
																}}
																size="large"
																name="times" />
														</Grid.Row>
													)}
												</Grid>
											)}
										</Grid.Column>

										<Grid.Column>
											<Grid style={{ justifyContent: 'right' }}>
												<Button type="button"
													onClick={handleUploadClick}
													content='Attach files'
													icon="upload"
												/>
												<SubmitButton primary
													type="submit"
													disabled={isSubmitting || !isEmpty(errors) || !dirty}>
													Send
												</SubmitButton>
											</Grid>
										</Grid.Column>
									</Grid>
								</Form>);
						}}
					/></>}
		</Container>
	</div>);
};

export interface MessageProps {
	message: TicketMessage,
	ticketId: number,
	user: User,
}

const ChatMessage = ({ message, ticketId, user }: MessageProps): React.ReactElement => {

	const download = (id: number, attachment: TicketAttachment) => {
		const get = async () => {
			try {
				downloadAttachment(id, attachment);
			} catch (e) {
				showErrorNotification(e);
			}
		};
		get();
	};

	return (
		<Message style={{ marginLeft: message.authorYou ? 'auto' : 'unset', background: message.authorYou ? 'rgb(229 233 237)' : '#F8F8F9' }} className="chatMessage">
			<Comment>
				<Comment.Avatar as='div' data-letters={message.authorYou ? getAuthorInitials(user) : message.author.charAt(0).toUpperCase()} />
				<Comment.Content>
					<Comment.Author>
						{message.author}
					</Comment.Author>
					<Comment.Metadata>
						<span>{formatDateWithPattern(message.createdDate, 'dd/MM/yyyy hh:mm:ss')}</span>
					</Comment.Metadata>
					<Comment.Text dangerouslySetInnerHTML={{ __html: message.message }} />
				</Comment.Content>
				{message.ticketAttachments.length > 0 && <Comment.Actions>
					<br />
					{message.ticketAttachments.map(attachment =>
						<>
							<Comment.Action
								onClick={() => download(ticketId, attachment)}
								key={attachment.key}
							>
								<Icon size="small" name="file outline" />{attachment.name}
							</Comment.Action>
						</>
					)}
				</Comment.Actions>}
			</Comment>
		</Message>
	);
};

export default Chat;
