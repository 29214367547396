export enum TokenAction {
	BUY = 'BUY', SELL = 'SELL', SEND_TO_USER = 'SEND_TO_USER', SEND_TO_EXTERNAL = 'SEND_TO_EXTERNAL'  
}

 
export enum TokenFeeType {
	VOLUME = 'VOLUME',
	FIXED = 'FIXED'
}


export interface TokenStore {
	tokens: Token[],
	loading: boolean,
	estimatedBalance: number,
	error: any,
	transactionList: Array<TokenTransaction>,
	updateTransactions: boolean,
	pendingTransactionList: Array<TokenPendingTransaction>,
	pendingTransactionCount: number,

}

export interface Token {
	id: string,
	accountId?: number,
	symbol: string,
	name: string,
	description: string,
	price: string,
	currency: string,
	website?: string,
	logo?: string,
	holdings?: string,
	availableHoldings?: string,
	holdingsOnHold?: string,
	precision: number,
	balance?: number,
	hidden?: boolean,
	network?: string,
	sellPrice: string,
	tradingView?: boolean,
	proc: string,
	subProcesses: any,
	networksArray: string[],
	sendEnabled: boolean,
	buyEnabled: boolean,
	sellEnabled: boolean,
}

export interface TokenRequest {
	token: Token,
	quantity: number,
	action: TokenAction
}

export interface RequestTransactionsPayload {
	from: string,
	to: string,
	search?: string
}

export interface TokenOrderPayload {
	id: string,
	amount: number,
	direction: TokenAction,
	operation: string,
	data: any
}

export interface TokenTransferPayload {
	accountFromId: number,
	amount: number,
	tokenId: string,
	operation: string,
	walletId: string,
	reference?: string,
	attachments?: any
}

export interface TokenWithdrawPayload {
	accountId: number,
    amount: number,
    tokenId: string,
    operation: string,  
    saveToContacts: false,  
    reference: string,
	attachments?: any,
    beneficiaryName: string,
    beneficiaryCryptoAddress: string,
    beneficiaryCryptoNetwork: string,
    beneficiaryIdentityNo?: string,
    beneficiaryAccountId?: number,  
}


export enum TokenTransactionStatus {
	REQUEST = 'REQUEST',
	OFFER = 'OFFER',
	USED = 'USED'
}

export interface TokenTransaction {
	id: string,
	referenceNumber: string,
	activity: string,
	asset: string,
	date: string,
	status: TokenTransactionStatus,
	amount: number
	price: number
}

export interface TokenPendingTransaction {
	id: string,
	createdDate: string,
	expirationTime: string,
	fromAmount: number,
	fromCcy: string,
	toAmount: number,
	toCcy: string,
	rate: number,
	status: TokenTransactionStatus,
	properties: { direction: TokenAction }
}
