import React, { useEffect, useState } from 'react';
import { Modal, Segment, Divider, List } from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { runOperation } from '@features/operations/slice';
import { showErrorNotification } from '@features/swal/slice';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Vault } from '@/features/earn/types';
import NumberFormat from 'react-number-format';
import './VaultsList.css';
import { Agents } from '@/helpers/globalTypes';
import { containsDecimals, maxTwoDecimals } from '@/helpers/number';
import useFee from '@/helpers/customHook/useFee';
import { getTokens } from '@/features/tokens/slice';
import { tokenDescription } from '@/components/Tokens/Tokens';

interface Props {
	open: boolean;
	onClose: (update?: boolean) => void;
	vault: Vault;
}

const DepositVaultModal = ({ vault, open, onClose }: Props): React.ReactElement => {
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [_accounts, setAccounts] = useState<Array<any>>([]);
	const [amount, setAmount] = useState<any>(0);
	const [fromAccount, setFromAccount] = useState<any>(null);
	const { list } = useSelector(
		(state: RootState) => state.accounts
	);
	const { accounts } = useSelector(
		(state: RootState) => state.trust
	);

	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const { tokens } = useSelector((state: RootState) => state.tokens);

	const fiatCurrencies = ['EUR', 'USD', 'GBP'];
	const { fee } = useFee(fromAccount?.accountId, vault?.proc, amount);
	const youPay = parseFloat(amount) + fee;
	const insufficientFunds = fromAccount?.availableBalance < youPay;


	const validationSchema = Yup.object({
		amount: Yup.number().label('Amount').required(t('form.validator.required'))
			.min(vault?.config?.minDepositAmount ?? 500, t('vaults.minDeposit', { ccy: vault.processProperties.currencySymbol, amount: vault?.config?.minDepositAmount ?? 500 }))
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('tokens.wholeNumber'), (value) => (fromAccount?.precision === 0 && containsDecimals(value) ? false : true)),
		from: Yup.string().required(t('form.validator.required')),
	});

	const handleSetFromAccountId = (accountId: number) => {
		const acc = _accounts.find(p => p.accountId === accountId);
		setFromAccount(acc);
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { from } = formData;

		try {
			await runOperation(vault.proc, { amount, accountId: from });
			onClose(true);
		} catch (err) {
			showErrorNotification(err);
			onClose(false);
		} finally {
			setSubmitting(false);
		}

	};

	useEffect(() => {
		if (!vault || !list || !tokens) return;

		if (fiatCurrencies.includes(vault.processProperties.ccy)) {
			if (status.source === Agents.DTS && vault.processProperties.ccy === 'EUR') {
				setAccounts(list.filter(account => vault.processProperties.ccy === account.currency && account.providerType === 'POOLING'));
			} else if (status.source === Agents.DTSUS) {
				setAccounts(accounts.filter(account => vault.processProperties.ccy === account.currency));
			} else {
				setAccounts(list.filter(account => vault.processProperties.ccy === account.currency));
			}
		}

		if (!fiatCurrencies.includes(vault.processProperties.ccy)) {
			dispatch(getTokens());
			setAccounts(tokens.filter(token => vault.processProperties.ccy === token.symbol&&!!(token.accountId)));
		}
	}, [vault, list]); //tokens removed from array as they are ftched inside

	return <Modal
		size="mini"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Segment basic>
				<Formik
					enableReinitialize
					initialValues={{ amount: 0, from: '' }}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting, dirty }) => (
						<Form
							size="large"
							id="deposit-vault">
							<Input
								label={t('sendmoney.data.amount')}
								fluid
								name="amount"
								value={amount}
								type='number'
								onChange={(e, v) => setAmount(v.value)}
								errorPrompt
							/>
							<Select
								fluid
								name="from"
								label={t('sendmoneyTranslation.data.from')}
								options={_accounts.map(item =>
								({
									value: item.accountId,
									key: item.accountId,
									text: fiatCurrencies.includes(vault.processProperties.ccy) ?
										`${item.name} (${item.currency} ${item.availableBalance})` :
										tokenDescription(item)
								}))}
								onChange={(e, v) => handleSetFromAccountId(parseInt(v.value.toString()))}
								errorPrompt
							/>
							<Input
								label={t('sendmoney.data.timePeriod')}
								fluid
								disabled
								name="period"
								value={`${vault.config.depositPeriodMonths} months`}
								errorPrompt
							/>
							<Divider />
							<List className='confirm-list'>
								<List.Header>{t('sendmoney.data.transferfee')}</List.Header>
								<List.Item>
									<NumberFormat
										displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										prefix={`${vault.processProperties.currencySymbol ?? vault.processProperties.ccy} `}
										value={fee} />
								</List.Item>
							</List>
							<List className='confirm-list'>
								<List.Header>{t('sendmoney.data.youpay')}</List.Header>
								<List.Item>
									<NumberFormat displayType={'text'}
										decimalScale={2}
										fixedDecimalScale={true}
										thousandsGroupStyle='thousand'
										thousandSeparator={true}
										prefix={`${vault.processProperties.currencySymbol ?? vault.processProperties.ccy} `}
										value={youPay} />
								</List.Item>
							</List>
							{insufficientFunds && <div id='insufficientFunds'> {t('sendmoneyTranslation.data.insufficient')}</div>}
							<Divider />
							<SubmitButton
								disabled={isSubmitting || !isEmpty(errors) || !dirty || insufficientFunds}
								fluid
								primary size="large" type="submit">
								{t('sendmoney.activedata.deposit')}
							</SubmitButton>
						</Form>)}
				</Formik>
			</Segment>
		</Modal.Content>
	</Modal>;
};

export default DepositVaultModal;
