import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '@/store';
import request from '@services/request';
import { Stock, StockAccount } from '@features/stocks/types';


const initialState = {
	loading: false,
	account: null,
	stocks: [],
	error: null,
	stocksLoading: false
};


const stocksSlice = createSlice({
	name: 'stocks',
	initialState,
	reducers: {
		setAccount(state, action: PayloadAction<StockAccount>) {
			state.account = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setStocks(state, action: PayloadAction<Stock[]>) {
			state.stocks = action.payload;
		},
		setStocksLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.stocksLoading = payload;
		},
	}
});

export const { setAccount, setLoading, setStocks, setStocksLoading } = stocksSlice.actions;

export const getAccounts = (): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setLoading(true));
			const response = await request.get('/api/accounts');
			const { data } = response;
			data.forEach(account => {
				if (account.providerType === 'STOCKS') {
					dispatch(setAccount(account));
				}
			});
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const getStocks = (): AppThunk => {
	return async (dispatch) => {
		try {
			dispatch(setStocksLoading(true));
			const response = await request.get('/api/stock');
			const { data } = response;
			dispatch(setStocks(data));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setStocksLoading(false));
		}
	};
};

export const getAutocomplete = async (query: string) => {
	const response = await request.get(`/api/stock/autocomplete/${query}/US`);
	const { data } = response;
	const { quotes } = data;
	return quotes;
};

export default stocksSlice.reducer;
