import React, { useEffect, useState } from 'react';
import { Breadcrumb, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useDispatch, } from 'react-redux';
import { getPendingTransactions } from '@/features/tokens/slice';
import PendingTransactions from './PendingTransactions';

const PendingTransactionAccordion = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [showPendingTrx, setShowPendingTrx] = useState<boolean>(false);


	useEffect(() => {
		dispatch(getPendingTransactions());
	}, []);

	return (<Segment className='token-segment-wrap'>
		<Grid padded onClick={() => setShowPendingTrx(!showPendingTrx)} className='token-trx-header'>
			<Grid.Column floated='left' width={5}>
				<Header >{t('tokens.header.pendingTransactions')}</Header>
			</Grid.Column >
			<Grid.Column floated='right' width={1}>
				<Breadcrumb size='large'>
					<Breadcrumb.Section ><Icon
						name={showPendingTrx ? 'chevron down' : 'chevron right'} /></Breadcrumb.Section>
				</Breadcrumb>
			</Grid.Column >
		</Grid>
		{showPendingTrx && <PendingTransactions />}
	</Segment>);
};

export default PendingTransactionAccordion;
