import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { Button, Checkbox, Divider, Grid, Header, Icon, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Input, Select, SubmitButton, TextArea } from 'formik-semantic-ui-react';
import CospayUserConfirmation from './CospayUserConfirmation';
import { TransferType } from '../SendMoney';
import { runOperation } from '@features/operations/slice';
import { getErrorWithParams, showErrorNotification, showInfoNotification } from '@features/swal/slice';
import { lookupContact } from '@features/contacts/slice';
import './CospayUser.css';
import { ContactTypes } from '@/components/Dashboard/Contacts/CreateContact';
import { Contact } from '@/features/contacts/types';
import { isEmpty } from 'lodash';
import { maxTwoDecimals } from '@/helpers/number';
import { AccountLimits } from '@/features/account/types';

type Props = {
	onSend: (type: TransferType, value: any) => void,
	account: any,
	subProcesses: any,
	accountLimits: AccountLimits
}

const CospayUser = ({ onSend, account, subProcesses, accountLimits }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [payload, setPayload] = useState<any>();
	const [process, setProcess] = useState<any>();
	const [addToContacts, setAddToContacts] = useState<boolean>(false);
	const [walletInContacts, setWalletInContacts] = useState<boolean>(false);
	const [walletId, setWalletId] = useState<string>(null);
	const [paymentSubmitting, setPaymentSubmitting] = useState<boolean>(false);
	const [internalContacts, setInternalContacts] = useState<Array<Contact>>([]);


	useEffect(() => {
		if (!subProcesses) return;
		setProcess(subProcesses[Object.keys(subProcesses).find(key => subProcesses[key]['type'] === 'TRANSFER')]);
	}, [subProcesses]);

	const { contacts, error } = useSelector(
		(state: RootState) => state.contacts
	);

	useEffect(() => {
		if (!contacts) {
			return;
		}
		setInternalContacts(contacts.filter(entry => entry.paymentType === ContactTypes.LOCAL && entry.type === 'BANK'));
	}, [contacts]);

	const validationSchema = Yup.object({
		amount: Yup.number().required(t('form.validator.required'))
			.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
			.min(0.01, t('sendmoneyTranslation.data.wrongAmount'))
			.test('amount', t('sendmoneyTranslation.data.insufficient'),
				(value) => value <= account.availableBalance)
			.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value))
			.test('amount', t('sendmoneyTranslation.data.limitReached', { ccy: accountLimits.ccy, availableBalanceNow: accountLimits.availableBalanceNow }), (value) => value <= accountLimits.availableBalanceNow),
		from: Yup.string().required(t('form.validator.required')),
		walletId: Yup.string().required(t('form.validator.required')),
		details: Yup.string().max(140, t('sendmoneyTranslation.data.canNotExceedHundred'))
	});

	const initialValues = {
		walletId: '',
		from: account.name + ' (' + account.currency + ' ' + account.availableBalance + ')',
		amount: '',
		details: ''
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting, setFieldError } = formikProps;

		const { walletId } = formData;
		try {

			let user = null;
			try {
				user = await lookupContact(walletId, account.currency);
 
				if (user.proc !== null) {
					if ((account.type !== user.accountType || account.currency != user.currency)) {
						setFieldError('walletId', t('sendmoneyTranslation.data.wrongProcess'));
						return;
					}
				}
			} catch (e) {
				const err = getErrorWithParams(e);
				if (err && err.error === 'notFound' && err.error_param === 'walletId') {
					setFieldError('walletId', t('sendmoneyTranslation.data.userNotFound'));
				} else if (err && err.error === 'your' && err.error_param === 'walletId') {
					setFieldError('walletId', t('sendmoneyTranslation.data.belongsToYou'));
				} else {
					await showErrorNotification(e);
				}
				return;
			}

			setPayload({ ...formData, ...{ currency: account.currency }, ...{ user }, fromAccountId: account.accountId });
		}
		catch (e) {
			await showErrorNotification(e);
		}
		finally {
			setSubmitting(false);
		}
	};

	const send = async () => {
		setPaymentSubmitting(true);
		try {
			const attachments: Map<string, string> = new Map<string, string>();
			await runOperation(process.proc, { accountFromId: `${account.accountId}`, accountToId: `${payload.user.accountId}`, amount: parseFloat(payload.amount), attachments: Object.fromEntries(attachments), details: payload.details, saveToContacts: addToContacts });
			setPaymentSubmitting(false);
			onSend(TransferType.CONTACT, { ...payload, ...{ currency: account.currency } });
		} catch (e) {
			setPaymentSubmitting(false);
			await showErrorNotification(e);
			setPayload(null);
		}
	};

	useEffect(() => {
		if (internalContacts.some((c) => c.walletId === walletId)) {
			setWalletInContacts(true);
			setAddToContacts(false);
		} else {
			setWalletInContacts(false);
		}
	}, [walletId, internalContacts]);

	useEffect(() => { error && showErrorNotification(error); }, [error]);

	if (payload) {
		return (
			<div id="sendmoneyuser">
				<CospayUserConfirmation submitting={paymentSubmitting} payload={payload} process={process} onConfirm={send} files={[]} availableAmount={account.availableBalance} />
			</div>
		);
	}

	return (
		<div id="sendmoneyuser">
			<Grid>
				<Grid.Column width={16}>
					<Segment padded>
						<Header className="cospayuserheader" as="h3">{t('sendmoneyTranslation.data.cospayuserheader')}</Header>
						<Divider hidden />
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting }) => (
								<Form>
									<Input
										fluid
										id="account"
										name="from"
										label={t('sendmoneyTranslation.data.from')}
										disabled
									/>
									<Input
										fluid
										name="amount"
										label={t('sendmoneyTranslation.data.transferamount')}
										type="number"
										placeholder='0.00'
										onWheel={(e) => e.target.blur()}
										errorPrompt
									/>
									<TextArea
										fluid
										name="details"
										errorPrompt
										label={t('sendmoneyTranslation.data.reference')}
										maxLength="140"
									/>
									<Select
										className="userdropdown"
										placeholder="Select user"
										label={t('sendmoneyTranslation.data.cospayuser')}
										fluid
										name="walletId"
										onChange={(e, v) => setWalletId(v.value.toString())}
										options={internalContacts && internalContacts.map((c, index) => ({ key: c.walletId + index, text: c.name, value: c.walletId }))}
										errorPrompt
									/>
									<Input
										fluid
										name="walletId"
										onChange={(e, v) => setWalletId(v.value)}
										label={t('sendmoneyTranslation.data.walletid')}
										errorPrompt
									/>
									<Checkbox
										checked={addToContacts}
										disabled={walletInContacts}
										onChange={() => { setAddToContacts(!addToContacts); }}
										label={t('sendmoneyTranslation.data.addtocontacts')} />
									{walletInContacts && <p>{t('sendmoneyTranslation.data.alreadyInContacts')}</p>}


									<Divider section />
									<SubmitButton disabled={isSubmitting || !isEmpty(errors)} primary fluid type="submit">{t('sendmoneyTranslation.activedata.button')}</SubmitButton>
								</Form>)}
						</Formik>
					</Segment>
				</Grid.Column>
			</Grid>
		</div>
	);
};
export default CospayUser;
