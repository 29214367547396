import React from 'react';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { Container, Grid, } from 'semantic-ui-react';
import GoogleAuth from './Security/GoogleAuth';
import Password from './Security/Password';
import Balance from './Security/Balance';
import AccountActivity from './Security/AccountActivity';
import LoadingPage from '../../pages/LoadingPage';
import './Security.css';
import ChatApp from './Security/ChatApp';

const Security = (): React.ReactElement => {
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const { chatAppEnabled } = useSelector(
		(state: RootState) => state.status
	);

	if (!user) {
		return <LoadingPage />;
	}

	return (<Container>
		<Grid stackable columns={2}>
			<Grid.Column>
				<Password />
				<GoogleAuth />
				<Balance />
			</Grid.Column>
			<Grid.Column>
				{/* <IPRestrictions /> */}
				<AccountActivity />
				{chatAppEnabled && <ChatApp />}
			</Grid.Column>
		</Grid>
	</Container>);
};

export default Security;
