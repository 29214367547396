import request from '@services/request';
import { FeeInfo, TransferFeePayloadType, Process } from '@features/fees/types';

export const getProcessFees = async () => {
	const response = await request.get('/api/fees');
	const { data } = response;
	return data;
};

export const getTurnoverLimits = async () => {
	const response = await request.get('/api/fees/limits/BANK');
	const { data } = response;
	return data;
};

export const getTiers = async () => {
	const response = await request.get('/api/fees/levels');
	const { data } = response;
	return data;
};

export const postTransferFee = async (payload: TransferFeePayloadType): Promise<FeeInfo> => {
	const response = await request.post('/api/fees/payinfo', payload);
	const { data } = response;
	return data;
};


export const getCurrencySign = (currency: string): string => {
	switch (currency) {
		case 'EUR': return '€';
		case 'GBP': return '£';
		case 'USD': return '$';
		default: return currency;
	}
};

export const searchSubProcess = (value, type: string, subType?: string) => {
	if (subType) {
		const { fees } = value;
		if (fees && fees.length >= 1) {
			return value.type === type && value.fees[0].paymentType === subType;
		} else {
			return false;
		}
	} else {
		return value.type === type;
	}
};

export const getSubprocessFee = (subProcesses, type: string, subType?: string) => {
	if (subProcesses) {
		const processList = Object.keys(subProcesses).map((key) => subProcesses[key]);
		const subProcessFees: any = processList.filter(entry => searchSubProcess(entry, type, subType));
		if (subProcessFees && subProcessFees.length >= 1) {
			const { fees } = subProcessFees[0];
			if (fees && fees.length >= 1) {
				const fee = fees[0];
				if (fee.type === 'VOLUME' || fee.type === 'PAYMENT') {
					return `${fee.feePercent}% (min. ${fee.minFee} ${getCurrencySign(fee.ccy)})`;
				}
				if (fee.type === 'FIXED_MONTH') {
					return `${fee.feeAmount} ${getCurrencySign(fee.ccy)}`;
				}
				return `${fee.feeAmount} ${getCurrencySign(fee.ccy)}`;
			} else {
				return 'N/A';
			}
		} else {
			return 'N/A';
		}
	} else {
		return 'N/A';
	}
};

export const getProcessFee = (process: Process, label: string): string => {
	const { fees } = process;
	if (fees && fees.length >= 1) {
		const fee = fees[0];
		if (fee.type === 'FIXED') {
			return `${fee.feeAmount} ${getCurrencySign(fee.ccy)} (${label}: ${fee.feeMonth} ${getCurrencySign(fee.ccy)})`;
		}
		return `${fees[0].feeAmount} ${getCurrencySign(fees[0].ccy)}`;
	} else {
		return 'N/A';
	}
};
