import { tokenDescription } from '@/components/Tokens/Tokens'; 
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { trimDecimals } from '../round';

const useTokens = () => {

	const { tokens } = useSelector((state: RootState) => state.tokens);

	const tokenOptions = tokens?.filter(token => trimDecimals(token.availableHoldings, token.precision) > 0 && token.sellEnabled)
	.map((token) => {
		return {
			key: token.id + '-' + token.accountId,
			text: tokenDescription(token),
			value: token.id + '-' + token.accountId,
		};
	}) || [];



	return { tokens, tokenOptions };
};

export default useTokens;
