import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Icon, Label, List, Message } from 'semantic-ui-react';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { alpha2ToAlpha3, getNames } from 'i18n-iso-countries';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import '../../Dashboard/CardTab.css';
import FormikDropdown from '@/components/Shared/FormikDropdown';

interface Address {
	country?: string,
	city?: string,
	address?: string,
	address2?: string,
	postalCode?: string
}
interface Props {
	onSubmit: (payload: any) => void
}

const CardDelivery = ({ onSubmit }: Props): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const [edit, setEdit] = useState<boolean>(false);
	const [currentAddressMissing, setCurrentAddressMising] = useState<boolean>(false);
	const [currentAddress, setAddress] = useState<Address>({});
	const [countries, setCountries] = useState<any>({});
	const [defaultValue, setDefaultValue] = useState(null);
	const [countryOptions, setCountryOptions] = useState<any>();
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	const validationSchema = Yup.object({
		city: Yup.string()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z-'`\s])+$/, t('form.validator.latinAlpha')),
		address: Yup.string()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		address2: Yup.string()
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		postalCode: Yup.string()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
		country: Yup.string()
			.required(t('form.validator.required'))
			.matches(/^([a-zA-Z0-9-'`\s])+$/, t('form.validator.latin')),
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		setEdit(false);
		setAddress({ ...currentAddress, ...formData });
	};

	useEffect(() => {
		user && setAddress({
			country: user.addressCountry,
			city: user.city,
			address: user.address,
			address2: user.address2,
			postalCode: user.postalCode
		});
		const list = getNames('en');
		const countries = {};
		Object.keys(list).forEach(k => countries[alpha2ToAlpha3(k)] = list[k]);
		const countryOptions = Object.keys(countries)
			.map($code => ({
				key: $code,
				value: $code,
				text: countries[$code],
			}));
		setCountryOptions(countryOptions);
		setCountries(countries);
	}, [user]);

	useEffect(() => {
		if (!countryOptions) return;
		if (currentAddress.country) {
			setDefaultValue(currentAddress.country);
		} else {
			setDefaultValue(user.country);
		}
	}, [user, currentAddress, countryOptions]);

	useEffect(() => {
		if(!currentAddress || !currentAddress.address || !currentAddress.postalCode || !currentAddress.city || !currentAddress.country) {
			setCurrentAddressMising(true);
		} else {
			setCurrentAddressMising(false);
		}
	}, [currentAddress]);

	return (
		<Grid id="newcard" container>
			<Grid.Column className="newcardmainCLMN">
				<Grid.Column>
					{!edit && <List>
						<Label>{t('cards.newCard.delivery.addressLabel')}</Label>
						<List.Item>{currentAddress.address}</List.Item>
						{currentAddress.address2 && <List.Item>{currentAddress.address2}</List.Item>}
						<List.Item>{currentAddress.city}</List.Item>
						<List.Item>{countries[currentAddress.country] || currentAddress.country}</List.Item>
						<List.Item>{currentAddress.postalCode}</List.Item>
						<Button className="primarybasicbtn" onClick={() => { setEdit(true); }} primary basic>{t('cards.newCard.delivery.deliveryBTN')}</Button>
					</List>}
					{edit && (
						<Formik
							initialValues={{
								address: (currentAddress && currentAddress.address) || '',
								address2: (currentAddress && currentAddress.address2) || '',
								postalCode: (currentAddress && currentAddress.postalCode) || '',
								city: (currentAddress && currentAddress.city) || '',
								country: (currentAddress && currentAddress.country || currentAddress.country) || '',
							}}
							validationSchema={validationSchema}
							onSubmit={submit}
						>
							{({ errors, isSubmitting }) => (
								<Form
									size="large">
									<Input
										fluid
										name="address"
										label={t('form.fields.address')}
										errorPrompt
									/>
									<Input
										fluid
										name="address2"
										label={t('form.fields.address2')}
										errorPrompt
									/>
									<FormikDropdown label={t('form.fields.country')} name='country' options={countryOptions} fluid={true} defaultValue={defaultValue} />
									<Grid stackable columns="equal">
										<Grid.Column>
											<Input
												fluid
												name="city"
												label={t('form.fields.town')}
												errorPrompt
											/></Grid.Column>
										<Grid.Column>
											<Input
												fluid
												name="postalCode"
												label={t('form.fields.postalcode')}
												errorPrompt
											/></Grid.Column>
									</Grid>
									<Divider hidden />
									<SubmitButton
										basic
										primary
										disabled={isSubmitting || !isEmpty(errors)}
										type="submit">
										{t('cards.newCard.delivery.confirm')}
									</SubmitButton>
								</Form>)}
						</Formik>)}
				</Grid.Column>
				<Divider clearing />
				<Button disabled={edit || currentAddressMissing} onClick={() => onSubmit({ action: 2, data: currentAddress })} className="mainbasicbtn" fluid primary basic>{t('form.buttons.continue')}</Button>
				{currentAddressMissing && <Message attached='top'>
					<Icon name='info' />
					{t('cards.newCard.delivery.missingDelivery')}
				</Message>}
			</Grid.Column>
		</Grid>
	);
};

export default CardDelivery;
