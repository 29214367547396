import React, { useState, useEffect } from 'react';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { Segment, Header, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { hideBalance } from '@features/security/slice';
import { showErrorNotification } from '@features/swal/slice';

const Balance = (): React.ReactElement => {
	const [disabled, setDisabled] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const { t } = useTranslation('translations');
	const { user } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		if (user) {
			setDisabled(!user.hideValues);
		}
	}, [user]);

	const toggle = async () => {
		setLoading(true);
		try {
			await hideBalance({ hideValues: disabled });
		} catch (e) {
			showErrorNotification(e);
		} finally { setLoading(false); }
	};

	return (
		<Segment padded>
			<Header size='medium'>{t('balance.data.header')}</Header>
			<p>{t('balance.data.enable')}</p>
			<p><b>{t('balance.data.status')}</b> {disabled ? t('balance.data.disabled') : t('balance.data.enabled')}</p>
			<Button primary basic loading={loading} onClick={toggle}>{disabled ? t('balance.button.enable') : t('balance.button.disable')}</Button>
		</Segment >
	);
};

export default Balance;
