import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../rootReducer';
import { closeModal } from '@features/swal/slice';
import { Modal, Button } from 'semantic-ui-react';

const Swal = ():React.ReactElement => {
	const dispatch = useDispatch();
	const { open, header, content } = useSelector(
		(state: RootState) => state.swal
	);

	return (<Modal
		size='mini'
		open={open}
		onClose={() => dispatch(closeModal())}>
		<Modal.Header>{header}</Modal.Header>
		<Modal.Content>
			<p>{content}</p>
		</Modal.Content>
		<Modal.Actions>
			<Button primary onClick={() => dispatch(closeModal())}>Close</Button>
		</Modal.Actions>
	</Modal>);
};

export default Swal;
