import * as React from "react";

function SvgRequestMoneyIcon(props) {
  return (
    <svg
      width={37}
      height={36}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={18} cy={18} r={18} fill="#364657" />
      <path
        d="M18.25 11v14m-7-7h14"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgRequestMoneyIcon;
