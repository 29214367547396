import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

interface Props {
	url: string
}

const CardTransferRedirect = ({ url }: Props) => {
	React.useEffect(() => {
		window.location.replace(url);
	}, [url]);

	return <Dimmer active={true} inverted>
		<Loader />
	</Dimmer>;
};

export default CardTransferRedirect;
