import { Token, TokenAction } from '@/features/tokens/types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Container, Grid, Header, Icon, Menu } from 'semantic-ui-react';
import { disableSell } from '../../Tokens';
import BuyTokenForm from './BuyTokenForm';
import SellTokenForm from './SellTokenForm'; 

interface Props {
	onBackClick: () => void
	token: Token,
	tokens: Token[] 
}

const TokenTrade = ({ onBackClick,  token }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [action, setAction] = useState<TokenAction>(TokenAction.BUY);
	const sellDisabled = disableSell(token);

	return (
		<div className='token-trade'>
			<Container>
				<Breadcrumb size='large'>
					<Breadcrumb.Section className='backchevronitem' link onClick={onBackClick}><Icon
						name='chevron left' className='backIcon' /> {t('inbox.back')}</Breadcrumb.Section>
				</Breadcrumb>
				<Header as='h1'>{t('tokens.tokenActions.BUY')} / {t('tokens.tokenActions.SELL')}</Header>
				<Grid>
					<Grid.Column width={5} className='token-menu'>
						<Menu className="vertical-menu" fluid vertical>
							<Menu.Item key={'buy-token'}
								active={action === TokenAction.BUY}
								onClick={() => setAction(TokenAction.BUY)}
								disabled={!token.buyEnabled}
								icon="chevron right"
								name='Buy'
							/>
							<Menu.Item key={'sell-token'}
								active={action === TokenAction.SELL}
								onClick={() => setAction(TokenAction.SELL)}
								disabled={sellDisabled}
								icon="chevron right"
								name='Sell'
							/>
						</Menu>
					</Grid.Column>
					{action === TokenAction.SELL &&
						<SellTokenForm
							onComplete={onBackClick}
							initToken={token}

						/>}
					{action === TokenAction.BUY &&
						<BuyTokenForm
							onComplete={onBackClick}
							initToken={token}

						/>}
				</Grid>
			</Container>
		</div>
	);
};

export default TokenTrade;
