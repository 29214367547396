import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { BankAccount, BankAccountStore } from './types';
import { SocketService } from '@services/socketService';
import { Subscription } from 'rxjs';
import request from '@services/request';

const findAccountById = (accountId, list) => {
	const id = list.findIndex(account => account.accountId === accountId);
	return id;
};

let cancelSource;

const initialState: BankAccountStore = {
	loading: false,
	list: [],
	error: null
};

const accountSlice = createSlice({
	name: 'account',
	initialState,
	reducers: {
		setAccounts(state, action: PayloadAction<Array<BankAccount>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		}
	}
});

export const { setAccounts, setLoading, setError } = accountSlice.actions;

export const bankAccountsSelector = (state: { bankAccountStore: BankAccountStore }) =>
	state.bankAccountStore;

export const getAccounts = (): AppThunk => {
	return async (dispatch, state) => {
		const { accounts } = state();
		const { list } = accounts;
		const currentAccounts = JSON.parse(JSON.stringify(list));
		try {
			const response = await request.get('/api/accounts');
			const { data } = response;
			data.forEach(account => {
				if((account.providerType === 'POOLING' || account.providerType === 'IBAN' || account.providerType === 'POOLING_IBAN'|| account.providerType === 'WALLET')
					&& account.status !== 'TERMINATED') {
					const id = findAccountById(account.accountId, currentAccounts);
					if (id < 0) {
						currentAccounts.push(account);
					} else {
						currentAccounts[id] = account;
					}
				}
			});
			dispatch(setAccounts(currentAccounts));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const clearAccounts = (): AppThunk => {
	return async dispatch => {
		dispatch(setAccounts([]));
	};
};

export const getAccount = async (id: number, source?: any) => {
	cancelSource = source ? source : { token: null };
	const response = await request.get('/api/accounts/' + id, {
		cancelToken: source ? source.token : null
	});
	const { data } = response;
	return data;
};

export const getAccountLimit = async (id: number,) => {
	const response = await request.get('/api/accounts/limits/' + id);
	const { data } = response;
	return data;
};


export const updateLabel = async (accountId: number, label: string) => {
	const response = await request.post('/api/accounts/label', {
		accountId, label
	});
	const { data } = response;
	return data;
};

export const cancelToken = async () => {
	if (cancelSource && cancelSource.token) cancelSource.cancel();
};

let socketService: SocketService;
let updateSubscriber: Subscription;

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('account');
	}
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		updateSubscriber = socketService.listen('bank.account', {}).subscribe(() => {
			dispatch(getAccounts());
		});
	};
};

export const unsubscribe = (): void => {
	updateSubscriber.unsubscribe();
};

export default accountSlice.reducer;
