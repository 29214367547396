import * as React from "react";

function SvgStatusInactive(props) {
  return (
    <svg
      width={24}
      height={25}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={12} cy={12.5} r={12} fill="#CFD7DF" />
      <circle cx={12} cy={12.5} r={6} fill="#fff" />
    </svg>
  );
}

export default SvgStatusInactive;
