import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, Container, Grid, Header, Icon, Menu } from 'semantic-ui-react';
import DepositCrypto from '@/components/Crypto/Transfer/DepositCrypto';
import WithdrawCrypto from '@/components/Crypto/Transfer/WithdrawCrypto';

interface Props {
	onBackClick: () => void
}

enum Action {
	DEPOSIT,WITHDRAW
}

const TransferCrypto = ({ onBackClick }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [action, setAction] = useState<Action>(Action.DEPOSIT);


	return (
		<div id='crypto-trade'>
			<Container>
				<Breadcrumb size='large'>
					<Breadcrumb.Section className='backchevronitem' link onClick={ onBackClick }><Icon
						name='chevron left' className='backIcon'/> { t('inbox.back') }</Breadcrumb.Section>
				</Breadcrumb>
				<Header as='h1'>Send / Receive</Header>
				<Grid>
					<Grid.Column width={5}>
						<Menu className="vertical-menu" fluid vertical>
							<Menu.Item key={'key'}
								active={action === Action.DEPOSIT}
								onClick={() => setAction(Action.DEPOSIT) }
								icon="chevron right"
								name='Deposit'
							/>
							<Menu.Item key={'key'}
								active={action === Action.WITHDRAW}
								onClick={() => setAction(Action.WITHDRAW) }
								icon="chevron right"
								name='Withdrawal'
							/>
						</Menu>
					</Grid.Column>
					{action === Action.DEPOSIT && <DepositCrypto />}
					{action === Action.WITHDRAW && <WithdrawCrypto/>}
				</Grid>
			</Container>
		</div>
	);
};

export default TransferCrypto;
