import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Card, Container, Grid, Icon, Segment, Table, TableHeader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { AvailablePlan } from '@/features/user/types';
import Loading from '@/components/Shared/Loading';
import ChoosePlan from './Profile/ChoosePlan';
import { showSuccessNotification } from '@/features/swal/slice';
import './UpgradePlan.css';

const UpgradePlan = (): React.ReactElement => {
    const { t } = useTranslation('translations');
    const history = useHistory();
    const [availablePlans, setAvailablePlans] = useState<Array<AvailablePlan>>(null);
    const [choosePlan, setChoosePlan] = useState<boolean>(false);
    const [choosedPlan, setChoosedPlan] = useState<AvailablePlan>(null);

    const { user } = useSelector(
        (state: RootState) => state.user
    );


    const handleBackClick = () => {
        history.push('/wallet');
    };

    useEffect(() => {
        if (!user) return;
        const plans = [...user.plan.availablePlans];
        plans.sort((a, b) => a.priority - b.priority);
        setAvailablePlans(plans);
    }, [user]);

    const onCloseChoosePlan = async (accountFrom?: number, plan?: AvailablePlan) => {
        setChoosePlan(false);
        setChoosedPlan(null);
        if (accountFrom && plan) {
            showSuccessNotification('You upgraded your plan');
        }
    };

    if (!availablePlans) return <Loading />;
    return (
        <Container id='upgrade-plan'>
            <ChoosePlan
                onClose={onCloseChoosePlan}
                open={choosePlan}
                plan={choosedPlan}
            />
            <Breadcrumb size="large">
                <Breadcrumb.Section className="backchevronitem" link onClick={() => handleBackClick()}><Icon name="chevron left" className="backIcon" />{t('business.back')}</Breadcrumb.Section>
            </Breadcrumb>
            <Segment>
                <Table textAlign='center' basic='very' className='computer tablet'>
                    <TableHeader>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            {availablePlans.map(plan => (
                                <Table.HeaderCell key={plan.userLevelId}>
                                    <PlanCard plan={plan} selectedPlan={user.plan.currentUserLevel.id} setChoosedPlan={setChoosedPlan} setChoosePlan={setChoosePlan} />
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </TableHeader>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell><strong>{t('plans.transactions')}</strong></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.incomingTransactions')}</Table.Cell>
                            <Table.Cell>{t('plans.free')}</Table.Cell>
                            <Table.Cell>{t('plans.free')}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.outgoingTransactions')}</Table.Cell>
                            <Table.Cell>€ 5</Table.Cell>
                            <Table.Cell>{t('plans.free')}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.betweenUsers')}</Table.Cell>
                            <Table.Cell>€ 1</Table.Cell>
                            <Table.Cell>{t('plans.free')}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell><strong>{t('plans.virtualCard')}</strong></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.issue')}</Table.Cell>
                            <Table.Cell>€ 1</Table.Cell>
                            <Table.Cell>{t('plans.free')}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.singlePayment')}</Table.Cell>
                            <Table.Cell>€ 2 000</Table.Cell>
                            <Table.Cell>€ 2 000</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.dailyLimit')}</Table.Cell>
                            <Table.Cell>€ 5 000</Table.Cell>
                            <Table.Cell>€ 5 000</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.monthlyLimit')}</Table.Cell>
                            <Table.Cell>€ 20 000</Table.Cell>
                            <Table.Cell>€ 20 000</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.monthlyMaitenance')}</Table.Cell>
                            <Table.Cell>€ 2</Table.Cell>
                            <Table.Cell>{t('plans.free')}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell><strong>{t('plans.physicalCardBlack')}</strong></Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.issue')}</Table.Cell>
                            <Table.Cell>X</Table.Cell>
                            <Table.Cell>{t('plans.free')}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.singlePayment')}</Table.Cell>
                            <Table.Cell>X</Table.Cell>
                            <Table.Cell>€ 5 000</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.dailyLimit')}</Table.Cell>
                            <Table.Cell>X</Table.Cell>
                            <Table.Cell>€ 10 000</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.monthlyLimit')}</Table.Cell>
                            <Table.Cell>X</Table.Cell>
                            <Table.Cell>€ 30 000</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>{t('plans.monthlyMaitenance')}</Table.Cell>
                            <Table.Cell>X</Table.Cell>
                            <Table.Cell>{t('plans.free')}</Table.Cell>
                        </Table.Row>

                    </Table.Body>
                    <Table.Footer >
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            {availablePlans.map(plan => (
                                <Table.HeaderCell key={plan.userLevelId}>
                                    <PlanCard plan={plan} selectedPlan={user.plan.currentUserLevel.id} setChoosedPlan={setChoosedPlan} setChoosePlan={setChoosePlan} />
                                </Table.HeaderCell>
                            ))}
                        </Table.Row>
                    </Table.Footer>
                </Table>
                <Grid className='mobile' textAlign='center'>
                    {availablePlans.map(plan => (
                        <Grid.Column key={plan.userLevelId} width={8}>
                            <PlanCard plan={plan} selectedPlan={user.plan.currentUserLevel.id} setChoosedPlan={setChoosedPlan} setChoosePlan={setChoosePlan} />
                        </Grid.Column>
                    ))}
                    <Grid.Column width={16}><strong>{t('plans.transactions')}</strong></Grid.Column>
                    <Grid.Column width={16}>{t('plans.incomingTransactions')}</Grid.Column>
                    <Grid.Column width={8}>{t('plans.free')}</Grid.Column>
                    <Grid.Column width={8}>{t('plans.free')}</Grid.Column>
                    <Grid.Column width={16}>{t('plans.outgoingTransactions')}</Grid.Column>
                    <Grid.Column width={8}>€ 5</Grid.Column>
                    <Grid.Column width={8}>{t('plans.free')}</Grid.Column>
                    <Grid.Column width={16}>{t('plans.betweenUsers')}</Grid.Column>
                    <Grid.Column width={8}>€ 1</Grid.Column>
                    <Grid.Column width={8}>{t('plans.free')}</Grid.Column>

                    <Grid.Column width={16}><strong>{t('plans.virtualCard')}</strong></Grid.Column>
                    <Grid.Column width={16}>{t('plans.issue')}</Grid.Column>
                    <Grid.Column width={8}>€ 1</Grid.Column>
                    <Grid.Column width={8}>{t('plans.free')}</Grid.Column>
                    <Grid.Column width={16}>{t('plans.dailyLimit')}</Grid.Column>
                    <Grid.Column width={8}>€ 500</Grid.Column>
                    <Grid.Column width={8}>€ 500</Grid.Column>
                    <Grid.Column width={16}>{t('plans.monthlyLimit')}</Grid.Column>
                    <Grid.Column width={8}>€ 1 500</Grid.Column>
                    <Grid.Column width={8}>€ 1 500</Grid.Column>
                    <Grid.Column width={16}>{t('plans.monthlyMaitenance')}</Grid.Column>
                    <Grid.Column width={8}>€ 2</Grid.Column>
                    <Grid.Column width={8}>{t('plans.free')}</Grid.Column>


                    <Grid.Column width={16}><strong>{t('plans.physicalCardBlack')}</strong></Grid.Column>
                    <Grid.Column width={16}>{t('plans.issue')}</Grid.Column>
                    <Grid.Column width={8}>X</Grid.Column>
                    <Grid.Column width={8}>{t('plans.free')}</Grid.Column>
                    <Grid.Column width={16}>{t('plans.dailyLimit')}</Grid.Column>
                    <Grid.Column width={8}>X</Grid.Column>
                    <Grid.Column width={8}>€ 1 000</Grid.Column>
                    <Grid.Column width={16}>{t('plans.monthlyLimit')}</Grid.Column>
                    <Grid.Column width={8}>X</Grid.Column>
                    <Grid.Column width={8}>€ 2 500</Grid.Column>
                    <Grid.Column width={16}>{t('plans.monthlyMaitenance')}</Grid.Column>
                    <Grid.Column width={8}>X</Grid.Column>
                    <Grid.Column width={8}>{t('plans.free')}</Grid.Column>

                    <Grid.Column width={16}><strong>{t('plans.physicalCardGold')}</strong></Grid.Column>
                    <Grid.Column width={16}>{t('plans.issue')}</Grid.Column>
                    <Grid.Column width={8}>X</Grid.Column>
                    <Grid.Column width={8}>€ 30</Grid.Column>
                    <Grid.Column width={16}>{t('plans.dailyLimit')}</Grid.Column>
                    <Grid.Column width={8}>X</Grid.Column>
                    <Grid.Column width={8}>€ 10 000</Grid.Column>
                    <Grid.Column width={16}>{t('plans.monthlyLimit')}</Grid.Column>
                    <Grid.Column width={8}>X</Grid.Column>
                    <Grid.Column width={8}>€ 30 00</Grid.Column>
                    <Grid.Column width={16}>{t('plans.monthlyMaitenance')}</Grid.Column>
                    <Grid.Column width={8}>X</Grid.Column>
                    <Grid.Column width={8}>€ 5</Grid.Column>

                    {availablePlans.map(plan => (
                        <Grid.Column key={plan.userLevelId} width={8}>
                            <PlanCard plan={plan} selectedPlan={user.plan.currentUserLevel.id} setChoosedPlan={setChoosedPlan} setChoosePlan={setChoosePlan} />
                        </Grid.Column>
                    ))}
                </Grid>
            </Segment>
        </Container >
    );
};

interface PlanCardProps {
    plan: AvailablePlan;
    selectedPlan: number;
    setChoosePlan: (value: boolean) => void;
    setChoosedPlan: (value: AvailablePlan) => void;
}

const PlanCard = ({ plan, selectedPlan, setChoosePlan, setChoosedPlan }: PlanCardProps) => {
    const { t } = useTranslation('translations');

    return (
        <Card style={{ marginRight: 'auto', marginLeft: 'auto', boxShadow: 'none' }} >
            <Card.Content textAlign='center'>
                <Card.Header>{plan.name}</Card.Header>
                <Card.Meta>
                    {plan.price === null || plan.price === 0 ? `${t('plans.free')}/${t('plans.month')}` : `${plan.currencySymbol} ${plan.price}/${t('plans.month')}`}
                </Card.Meta>
                <Card.Description>
                    {selectedPlan === plan.userLevelId ? <Button className='plan button' secondary disabled content={t('plans.selectedPlan')} /> : <Button className='plan button' primary onClick={() => { setChoosePlan(true); setChoosedPlan(plan); }} content={t('plans.getPlan')} />}

                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default UpgradePlan;
