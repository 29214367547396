import React, { useState } from 'react';
import { Divider, Grid, Header, List, Segment } from 'semantic-ui-react';
import { Form, Input, Select, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Token, TokenAction } from '@/features/tokens/types';
import { useTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import {  getTokens, postTokenOrder } from '@/features/tokens/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { BankAccount } from '@/features/account/types'; 
import useAccounts from '@/helpers/customHook/useAccounts';
import { maxTwoDecimals } from '@/helpers/number';
import useFee from '@/helpers/customHook/useFee';

interface Props {
	initToken: Token,
	onComplete?: () => void
}


const BuyTokenForm = ({ initToken, onComplete }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();

	const [fiatAmount, setFiatAmount] = useState<number>(0);
	const [currentToken, setCurrentToken] = useState<Token>(initToken);
	const [currentAccount, setCurrentAccount] = useState<BankAccount>();

	
	const currency = currentToken.currency;

	const { tokens } = useSelector((state: RootState) => state.tokens);
	const { accountOptions, accountList } = useAccounts(currency);

	const subProcess = currentToken?.subProcesses[Object.keys(currentToken?.subProcesses).find(key => currentToken?.subProcesses[key]['type'] === 'OWN_TRANSFER')];
	const tokenOptions = tokens?.filter(token => token.buyEnabled === true).map((token) => {
		return {
			key: token.id + '-' + token.accountId,
			text: token.name + ' (' + token.symbol + ')',
			value: token.id + '-' + token.accountId,
		};
	}) || [];

	const initialValues = {
		token: initToken.id + '-' + initToken.accountId,
		fiatAmount: '',
		fromAccount: ''
	};

	const aproxTokenAmount = (fiatAmount ?? 0) / parseFloat(currentToken.price);
	const { fee } = useFee(currentAccount?.accountId, subProcess?.proc, fiatAmount);
	const totalMoney = (fiatAmount + fee);
	const maxFiat = (currentAccount?.availableBalance - fee) ?? 0;

	const validationSchema =
		Yup.object({
			token: Yup.string().required(t('form.validator.required')),
			fromAccount: Yup.string().required(t('form.validator.required')).test('fromAccount', t('form.validator.required'), (value) => accountList.some(p => p.accountId.toString() === value)),
			fiatAmount: Yup.number().label('FIAT').required(t('form.validator.required'))
				.moreThan(0, t('sendmoneyTranslation.data.moreThanZero'))
				.min(10)
				.test('fiatAmount', t('form.error.insufficientFund'), (value, ctx) => ctx.parent.fromAccount === undefined || value <= maxFiat)
				.test('amount', t('sendmoneyTranslation.data.wrongAmount'), (value) => maxTwoDecimals(value)),
		});

	const confirmToken = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		const { fromAccount } = formData;
		setSubmitting(true);
		const tokenAccount = currentToken?.accountId ? { accountToId: currentToken.accountId } : { accountToCreateProc: currentToken?.proc };

		const dataPayload = {
			accountFromId: fromAccount,
			...tokenAccount
		};

		const order = {
			operation: subProcess.proc,
			tokenProc: currentToken.proc,
			id: currentToken.id,
			direction: TokenAction.BUY,
			amount: fiatAmount,
			data: dataPayload
		};
		try {
			await postTokenOrder(order);
			showSuccessNotification(t('tokens.tokenRequestCreated'));
			dispatch(getTokens());
		} catch (err) {
			showErrorNotification(err);
		} finally {
			setSubmitting(false);
			onComplete && onComplete();
		}
	};



	const handleTokenChange = (event, data) => {
		const newToken = tokens.find(p => p.id + '-' + p.accountId === data.value);
		setCurrentToken(newToken);
	};

	const handleAccountChange = (event, data) => {
		const newAccount = accountList.find(p => p.accountId === data.value);
		setCurrentAccount(newAccount); 
	};


	return (
		<Grid.Column width={11}>
			<Segment className='token-trade-window'>
				<Header className='stage'>{t('tokens.tokenActions.BUY')}</Header>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={confirmToken}
				>
					{({ errors, isSubmitting, setFieldTouched }) => {
						return (
							<Form autoComplete="off">
								<Select
									id='token'
									name='token'
									onChange={(event, data) => { handleTokenChange(event, data); setTimeout(() => setFieldTouched('fromAccount', true)); }}
									errorPrompt
									options={tokenOptions} />
								<Select
									fluid
									name='fromAccount'
									label={t('tokens.header.fromAccount')}
									onChange={(event, data) =>{ handleAccountChange(event, data);setTimeout(() => setFieldTouched('fiatAmount', true));}}
									errorPrompt
									options={accountOptions} />

								<Input name="fiatAmount"
									label={t('tokens.header.fiat')}
									type="number"
									onWheel={(e) => e.target.blur()}
									placeholder="0.00"
									errorPrompt
									onChange={e => { setFiatAmount(parseFloat(e.currentTarget.value)); }}
								/>
								<List relaxed >
									<List.Header>{t('tokens.header.buyprice')}</List.Header>
									<List.Item>
										<NumberFormat displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`≈ ${currency} `} value={currentToken.price} />
									</List.Item>
								</List>
								<List relaxed >
									<List.Header>{t('tokens.header.youreceive')}</List.Header>
									<List.Item>
										<NumberFormat displayType={'text'}
											decimalScale={currentToken.precision}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix='≈ '
											suffix={` ${currentToken.symbol}`}
											value={aproxTokenAmount} />
									</List.Item>
								</List>
								<List relaxed >
									<List.Header>{t('tokens.header.fee')}</List.Header>
									<List.Item>
										<NumberFormat displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`${currency} `} value={fee} />
									</List.Item>
								</List>
								<List relaxed>
									<List.Header>{t('tokens.header.youpay')}</List.Header>
									<List.Item>
										<NumberFormat displayType={'text'}
											decimalScale={2}
											fixedDecimalScale={true}
											thousandsGroupStyle='thousand'
											thousandSeparator={true}
											prefix={`${currency} `} value={totalMoney} />

									</List.Item>
									{totalMoney < 0 &&
										<div className="color-red token-error" >
											{t('sendmoneyTranslation.data.moreThanZero')}
										</div>
									}
								</List>
								<Divider className='tokens-trade-divider' />
								<SubmitButton
									className='tokens-trade-submit'
									disabled={!isEmpty(errors) || isSubmitting || totalMoney < 0}
									primary fluid size="large" type="submit">
									{t('tokens.btn.submit')}
								</SubmitButton>
							</Form>
						);
					}}
				</Formik>
			</Segment>
		</Grid.Column>
	);
};


export default BuyTokenForm;
