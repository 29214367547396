export interface TransactionsPayload {
	accountId?: string,
	from: string,
	to: string,
	search: string,
	type?: string
}

export interface TemplatePayload {
	templateIdentifier: string,
	payload: any[],
	walletId?: string,
	accountType?: string
}

export interface StatementExportPayload {
	templateIdentifier: string,
	transactionNumber: string,
	walletId?: string,
	accountType?: string
}

export enum TransactionType {
	DEPOSIT = 'DEPOSIT',
	WITHDRAW = 'WITHDRAW',
	TRANSFER = 'TRANSFER',
	PAYMENT = 'PAYMENT',
	EXCHANGE = '',
	PURCHASE = 'PURCHASE',
	CURRENCY_FEE = 'CURRENCY_FEE',
	SYSTEM_FEE = 'SYSTEM_FEE',
	SERVICE_FEE = 'SERVICE_FEE',
	COMMISSION = 'COMMISSION',
	CHARGEBACK = 'CHARGEBACK',
	CARD_TOP_UP = 'CARD_TOP_UP',
	CARD_PULL_OUT = 'CARD_PULL_OUT',
	OWN_TRANSFER = 'OWN_TRANSFER',
	FX_TRANSACTION = 'FX_TRANSACTION',
	NP = 'NP'
}

export enum TransactionStatus {
	COMPLETED = 'COMPLETED',
	NEW = 'NEW',
	APPROVAL = 'APPROVAL',
	APPROVED_BY_RISK_MANAGER = 'APPROVED_BY_RISK_MANAGER',
	APPROVED_BY_COMPLIANCE_MANAGER = 'APPROVED_BY_COMPLIANCE_MANAGER',
	EMAIL_APPROVAL = 'EMAIL_APPROVAL',
	PENDING = 'PENDING',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	CANCELED = 'CANCELED',
	ERROR = 'ERROR',
	FAILED = 'FAILED'
}


export interface TransactionsLazyPayload {
	accountId?: string,
	from?: string,
	to?: string,
	search: string,
	skip?: number,
	take?: number,
	initial?: boolean
}
