import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Header, List, Segment, Select, Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { getCurrencySign } from '@features/fees/slice';
import { Agents } from '@helpers/globalTypes';
import DeleteBulkTopUpEntryModal from '@/components/Banking/Card/DeleteBulkTopUpEntryModal';
import EditBulkTopUpEntryModal from '@/components/Banking/Card/TopUp/Bulk/EditBulkTopUpEntryModal';
import { confirmBulkTopUp } from '@features/card/slice';
import { BulkEntry, BulkError } from '@features/card/types';

interface Props {
	transfers: any,
	employees: any,
	onDiscard: () => void,
	onChange: () => void
}

const BulkTopUpConfirmation = ({ transfers, employees, onDiscard, onChange }:Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [details, setDetails] = useState<BulkEntry[]>([]);
	const [accounts, setAccounts] = useState(null);
	const [selectedDetail, setSelectedDetail] = useState<BulkEntry>(null);
	const [deleteOpen, setDeleteOpen] = useState(false);
	const [editOpen, setEditOpen] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState(null);

	const { list } = useSelector(
		(state: RootState) => state.accounts
	);
	const { status } = useSelector(
		(state: RootState) => state.user
	);

	const getInfoMessage = (error: BulkError): string => {
		const idMessage = status.source === Agents.DTSCB ? 'employee number' : 'employee code';

		switch (error) {
		case BulkError.DONT_BELONG_TO_COMPANY:
			return `Could not find specified user. Please check ${idMessage}.`;
		case BulkError.INACTIVE_CARD:
			return 'Selected card is not active.';
		case BulkError.INACTIVE_USER:
			return 'Selected user is not active.';
		case BulkError.MULTIPLE_CARDS:
			return 'Selected user has multiple cards assigned. Choose the correct card using edit function.';
		case BulkError.NO_CARD:
			return 'Selected user has no cards assigned.';
		case BulkError.TECHNICAL:
			return 'Technical issue occurred. Please contact our support.';
		default: return 'Unknown issue occurred. Please contact our support.';
		}
	};

	const getErrorClass = (error: BulkError): string => {
		if(!error) return '';
		if(error === BulkError.MULTIPLE_CARDS) {
			return 'warning';
		} else {
			return 'error';
		}
	};

	const validFunds = (): boolean => {
		if(!selectedAccount) return;
		const account = list.find(account => account.accountId === selectedAccount);
		const { balance } = account;
		return balance > transfers.totalAmount;
	};

	const containsErrors = (): boolean => {
		const { topups } = transfers;
		topups.forEach( entry => {
			if(entry.error) {
				return true;
			}
		});
		return false;
	};

	useEffect(() => {
		const accountOptions = [];

		list.forEach( (account) => {
			if(account.status === 'ACTIVE') {
				accountOptions.push({
					key: account.accountId,
					value: account.accountId,
					text: `${account.name} (${account.currency} ${account.availableBalance})`
				});
			}
		});
		setAccounts(accountOptions);
	}, []);

	useEffect(() => {
		const { topups } = transfers;
		setDetails(topups);
	}, []);

	const onDelete = ( detail: BulkEntry) => {
		setSelectedDetail(detail);
		setDeleteOpen(true);
	};

	const onEdit = (detail: BulkEntry) => {
		setSelectedDetail(detail);
		setEditOpen(true);
	};

	const onConfirm = async () => {
		await confirmBulkTopUp(transfers.sessionId);
	};

	return (
		<div id='bulk-top-up-confirm'>
			{selectedDetail && <DeleteBulkTopUpEntryModal detail={selectedDetail} bulkId={transfers.sessionId} onClose={setDeleteOpen} isOpen={deleteOpen} reFetch={onChange}/>}
			{selectedDetail && <EditBulkTopUpEntryModal detail={selectedDetail} bulkId={transfers.sessionId} onClose={setEditOpen} isOpen={editOpen} reFetch={onChange} employees={employees}/>}
			<Segment className='container'>
				<Grid padded>
					<Grid.Row>
						<Header className="section-header">{ t('cards.bulk.header') }</Header>
					</Grid.Row>
					<Grid.Row>
						<span className='label'>From account</span>
						<Select fluid options={accounts} value={selectedAccount} onChange={(e, v) => setSelectedAccount(v.value) } placeholder='Select account'/>
						{!validFunds() && <span className='warning'>Not enough funds.</span>}
					</Grid.Row>
					<Grid.Row>
						<Table>
							<Table.Header>
								<Table.HeaderCell>Amount</Table.HeaderCell>
								<Table.HeaderCell>Employee Name</Table.HeaderCell>
								<Table.HeaderCell>{status.source === Agents.DTSCB ? 'Employee number' : 'Employee code'}</Table.HeaderCell>
								<Table.HeaderCell>Card name</Table.HeaderCell>
								<Table.HeaderCell>Edit</Table.HeaderCell>
								<Table.HeaderCell>Delete</Table.HeaderCell>
							</Table.Header>
							<Table.Body>
								{details.map((detail) => {
									return <>
										<Table.Row key={detail.employeeRef} className={getErrorClass(detail.error)}>
											<Table.Cell>{detail.amount} {getCurrencySign(transfers.ccy)}</Table.Cell>
											<Table.Cell>{detail.employeeName}</Table.Cell>
											<Table.Cell>{detail.employeeRef}</Table.Cell>
											<Table.Cell>{detail.cardName}</Table.Cell>
											<Table.Cell><a onClick={() => onEdit(detail)}>Edit</a></Table.Cell>
											<Table.Cell><a onClick={() => onDelete(detail)}>Delete</a></Table.Cell>
										</Table.Row>
										{detail.error && <Table.Row key={detail.employeeRef + 'message'}>
											<Table.Cell colSpan={ 6 } className={getErrorClass(detail.error) + ' center'}>{getInfoMessage(detail.error)}</Table.Cell>
										</Table.Row>
										}
									</>;
								})}
							</Table.Body>
						</Table>
					</Grid.Row>
					<Grid.Row>
						<List className='total'>
							<List.Header className='total-header'>
								Total amount
							</List.Header>
							<List.Item className='total-amount'>
								{transfers.totalAmount} {getCurrencySign(transfers.ccy)}
							</List.Item>
						</List>
					</Grid.Row>
					<Divider/>
					<Grid.Row>
						<Grid.Column width={5}>
							<Button onClick={onDiscard} basic fluid>Discard</Button>
						</Grid.Column>
						<Grid.Column width={11}>
							<Button disabled={!validFunds() || containsErrors()} onClick={onConfirm} primary fluid>Confirm payment</Button>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Segment>
		</div>
	);
};

export default BulkTopUpConfirmation;
