import React, { useState } from 'react';
import {
	Button,
	Grid,
	Modal, Segment
} from 'semantic-ui-react';
import isEmpty from 'is-empty';
import { Formik } from 'formik';
import { Form, Radio, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

interface Props {
	role: string;
	open: boolean;
	onClose: (value?: string) => void
}

enum Role {
	ACCOUNTANT = 'ACCOUNTANT',
	EMPLOYEE = 'EMPLOYEE'
}

const EditRole = ({ role, open, onClose }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const [selectedRole, setSelectedRole] = useState<string>(role);

	const initialValues = {
		role: selectedRole
	};

	const validationSchema = Yup.object({
	});

	const submit = (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		setSubmitting(false);
		const { role } = formData;
		onClose(role);
	};

	return <Modal
		size="mini"
		open={open}
		onClose={() => onClose()}>
		<Modal.Content>
			<Segment basic>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={submit}
				>
					{({ errors, isSubmitting, dirty }) => (
						<Form
							size="large">
							<Grid>
								<Grid.Row columns={1}>
									<div className='employeeRadioDiv'>
										<Radio
											name='role'
											className='employeeRadio'
											label={t('business.createNewEmployee.employee')}
											value={Role.EMPLOYEE}
											checked={selectedRole === Role.EMPLOYEE}
											onChange={() => setSelectedRole(Role.EMPLOYEE)} />
										<Radio
											name='role'
											className='employeeRadio'
											label={t('business.createNewEmployee.accountant')}
											value={Role.ACCOUNTANT}
											checked={selectedRole === Role.ACCOUNTANT}
											onChange={() => setSelectedRole(Role.ACCOUNTANT)} />
									</div>
								</Grid.Row>
							</Grid>
							<div className='editBTNS'>
								<SubmitButton
									disabled={isSubmitting || !isEmpty(errors) || !dirty}
									primary size="large" type="submit">
									{t('form.buttons.save')}
								</SubmitButton>
								<Button className='discardBTN' content={t('form.buttons.cancel')} onClick={() => onClose()} />
							</div>
						</Form>)}
				</Formik>
			</Segment>
		</Modal.Content>
	</Modal>;
};

export default EditRole;
