import React, { useEffect, useState } from 'react';
import { Button, Header, Segment, Image } from 'semantic-ui-react';
import { RootState } from '@/rootReducer';
import { useSelector } from 'react-redux';
import { linkChatApp } from '@/features/security/slice';

const ChatApp = (): React.ReactElement => {
	const [qrCode, setQrCode] = useState(null);
	const [timeLeft, setTimeLeft] = useState(0);

	const { user } = useSelector(
		(state: RootState) => state.user
	);

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (qrCode !== null) {
				manage();
			}
		}, 60000);

		return () => clearTimeout(timeout);
	}, [qrCode]);

	useEffect(() => {
		const timer = setTimeout(() => {
			setTimeLeft(timeLeft - 1);
		}, 1000);

		return () => clearTimeout(timer);
	}, [timeLeft]);

	const manage = async () => {
		try {
			const data = await linkChatApp();
			setQrCode(data);
			setTimeLeft(60);
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<Segment padded>
			<Header size='medium'>Link to chat app</Header>
			<p>You can link your account to chat application</p>
			{user && qrCode === null && <Button primary basic onClick={manage}>Link</Button>}
			{qrCode && <div>For your safety we will generate new QR code after {timeLeft} seconds</div>}
			{qrCode && <Image src={qrCode} style={{ margin: '0 auto' }} />}
		</Segment>
	);
};

export default ChatApp;
