
import { detect } from 'detect-browser';
import * as Fingerprint2 from 'fingerprintjs2';
import { DeviceInfo } from '../features/user/types';

const getDeviceInfo = (): Promise<DeviceInfo> => {
	return new Promise((resolve) =>
		Fingerprint2.get({
			excludes: {
				adBlock: true,
				userAgent: true,
				language: true,
				colorDepth: true,
				deviceMemory: true,
				pixelRatio: true,
				hardwareConcurrency: true,
				screenResolution: true,
				availableScreenResolution: true,
				timezoneOffset: true,
				timezone: true,
				sessionStorage: true,
				localStorage: true,
				indexedDb: true,
				addBehavior: true,
				openDatabase: true,
				cpuClass: true,
				// platform: true,
				doNotTrack: true,
				plugins: true,
				// canvas: true,
				webgl: true,
				webglVendorAndRenderer: true,
				hasLiedLanguages: true,
				hasLiedResolution: true,
				hasLiedOs: true,
				hasLiedBrowser: true,
				touchSupport: true,
				// fonts: true,
				fontsFlash: true,
				audio: true,
				enumerateDevices: true
			}
		}, (components) => {
			const browser = detect();
			const values = components.map(function (component) { return component.value; });
			const fingerprintHash = Fingerprint2.x64hash128(values.join(''), 31);
			resolve({
				browser: browser.name,
				platform: browser.os,
				hash: fingerprintHash
			});
		}));
};

export { getDeviceInfo };
