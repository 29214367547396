import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { next, reject2FA } from '@features/2fa/slice';
import { Divider, Grid, Icon, Label, Message, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import isEmpty from 'is-empty';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import './TwoFA.css';

const TwoFA = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const [error, setError] = useState<string>();
	const { open, message } = useSelector(
		(state: RootState) => state.twoFA
	);

	const initialValues = {
		authenticatorCode: ''
	};

	const validationSchema = Yup.object({
		authenticatorCode: Yup.string().matches(/^\d{6}$/, t('errors.SIX_DIGITS_REQUIRED')).required(t('form.validator.required'))
	});

	const confirm = async (formData, formikProps) => {
		const { setSubmitting, resetForm } = formikProps;
		setError(null);
		try {
			const { authenticatorCode } = formData;
			dispatch(next(authenticatorCode));
		}
		catch (e) {
			setSubmitting(false);
			setError(e.message);
		} finally {
			resetForm({});
		}
	};

	return (
		<Modal
			className="twofamodal"
			dimmer='inverted'
			size='tiny'
			open={open}
			onClose={() => dispatch(reject2FA())}>
			<Modal.Header className="twofaheader">
				<Grid>
					<Grid.Row columns={2}>
						<Grid.Column>{t('form.twofa.header')}</Grid.Column>
						<Grid.Column >
							<Icon className='closeIcon' style={{ float: 'right' }} name='close' onMouseDown={() => dispatch(reject2FA())} />
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Modal.Header>
			<Modal.Content className="twofacontent">
				{error && <Message negative>{error}</Message>}
				{message && <Message negative>{message}</Message>}
				<Label className="twofapasswordlabel">Password</Label>
				<p className="digitp">{t('form.twofa.digitp')}</p>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={confirm}
				>
					{({ errors, isSubmitting, dirty }) => (
						<Form
							className="twofaform"
							size="large"
							style={{ margin: '0 auto 2rem' }}
						>
							<Input
								autoComplete="off"
								fluid
								autoFocus
								name="authenticatorCode"
								placeholder={t('form.placeholder.code')}
								errorPrompt
							/>
							<a className="twofaproblem" href="https://support.google.com/accounts/answer/185834?hl=en"><p className="twofaproblemp">{t('form.twofa.problemp')}</p></a>
							<Divider clearing />
							<Grid>
								<Grid.Row columns='equal'>
									<Grid.Column>
										<SubmitButton
											disabled={isSubmitting || !isEmpty(errors) || !dirty}
											primary size="large" type="submit">
											{t('form.buttons.submit')}
										</SubmitButton>
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Form>)}
				</Formik>
			</Modal.Content>
		</Modal>
	);
};

export default TwoFA;
