import React, { useEffect, useState } from 'react';
import './Accounts.css';
import { RootState } from '@/rootReducer';
import { Divider, Segment } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { connect, subscribe, unsubscribe } from '@features/card/slice';
import { UserType } from '@features/user/types';
import AccountsPlaceholder from './Shared/AccountsPlaceholder';
import AccountsList from './Shared/ScrollableTabs/AccountsList';
import TransactionStatements from './TransactionStatements';
import EmployeeVerification from '@/components/Business/EmployeeVerification';
import { useTranslation } from 'react-i18next';

const Cards = (): React.ReactElement => {
	const dispatch = useDispatch();
	const { t } = useTranslation('translations');
	const [type] = useState<string>('card');
	const [requiredTier, setRequiredTier] = useState<number>(1);
	const [kycTierVerified, setKycTierVerified] = useState<boolean>(false);
	const [accountsLoading, setAccountsLoading] = useState<boolean>(true);

	const { loading, list, error } = useSelector(
		(state: RootState) => state.cards
	);
	const { user } = useSelector(
		(state: RootState) => state.user
	);
	const { currentAccount } = useSelector(
		(state: RootState) => state.tabs
	);

	useEffect(() => {
		connect();
		dispatch(subscribe());
		return () => {
			unsubscribe();
		};
	}, [dispatch]);

	useEffect(() => {
		if (!user) return;
		const reqTier = user.type === UserType.INDIVIDUAL || user.type === UserType.EMPLOYEE ? 1 : 2;
		setRequiredTier(reqTier);
	}, [user]);

	useEffect(() => {
		if (!user) return;
		const kycTier = user.kycTier || null;
		const isKycTierRevoked = (user.kycTier === 0) && (user.previousKycTier > 0);
		setKycTierVerified((kycTier >= requiredTier) || isKycTierRevoked);
		setAccountsLoading(!!user && loading);
	}, [user, requiredTier, loading]);


	if (accountsLoading || !user) return <AccountsPlaceholder
		loadingMessage='Loading cards'
		type={type} />;
	if (error) return <div>Error: {error}</div>;

	if(user.type === 'EMPLOYEE' && !kycTierVerified) {
		return <EmployeeVerification/>;
	}

	if (!kycTierVerified && user.onBehalfOf === null) {
		return <AccountsPlaceholder type={type} kyc={true} />;
	}

	if(user.type === 'EMPLOYEE' && (!list || !list.length)) {
		return 	<Segment padded="very">
			{t('cards.notAssigned')}
		</Segment>;
	}

	if ((!list || !list.length)) return <AccountsPlaceholder type={type} />;

	return (
		<div>
			<AccountsList type={type} accounts={list} />
			{currentAccount && <Divider hidden />}
			{currentAccount && <TransactionStatements account={currentAccount} tabType={type}/>}
		</div>);
};

export default Cards;
