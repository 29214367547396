import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { Card, CardsStore } from './types';
import { SocketService } from '../../services/socketService';
import { Subscription } from 'rxjs';
import request from '../../services/request';
import { isEmpty } from 'lodash';

const findCardById = (cardId, list) => {
	const id = list.findIndex(card => card.cardId === cardId);
	return id;
};

let cancelSource;

const initialState: CardsStore = {
	loading: true,
	list: [],
	error: null
};

const slice = createSlice({
	name: 'card',
	initialState,
	reducers: {
		setCards(state, action: PayloadAction<Array<Card>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		},
		removeCard: (state, { payload }: PayloadAction<number>) => {
			const { list } = state;
			state.list = list.filter(card => card.cardId != payload);

		}
	}
});

export const { setCards, setLoading, setError, removeCard } = slice.actions;

export const bankAccountsSelector = (state: { bankAccountStore: CardsStore }) =>
	state.bankAccountStore;

export const getCards = (): AppThunk => {
	return async (dispatch, state) => {
		const { cards } = state();
		const { list } = cards;
		const currentCards = JSON.parse(JSON.stringify(list));
		try {
			const response = await request.get('/api/cards');
			const { data } = response;
			data.forEach(card => {
				const id = findCardById(card.cardId, currentCards);
				if (id < 0) {
					currentCards.push(card);
				} else {
					currentCards[id] = card;
				}
			});
			dispatch(setCards(currentCards));
			return data;
		} catch (e) {
			console.log(e);
		} finally {
			dispatch(setLoading(false));
		}
	};
};

export const clearAccounts = (): AppThunk => {
	return async dispatch => {
		dispatch(setCards([]));
	};
};

export const getCard = async (id: number, source?: any) => {
	cancelSource = source ? source : { token: null };
	const response = await request.get('/api/cards/' + id, {
		cancelToken: source ? source.token : null
	});
	const { data } = response;
	return data;
};

export const deleteBulkTopUpEntry = async (id: number, bulkId: number) => {
	const response = await request.delete(`/api/cards/bulk-top-up/${bulkId}/entries/${id}`);
	const { data } = response;
	return data;
};

export const editBulkTopUpEntry = async (id: number, bulkId: number, payload: any) => {
	const response = await request.put(`/api/cards/bulk-top-up/${bulkId}/entries/${id}`, payload);
	const { data } = response;
	return data;
};

export const confirmBulkTopUp = async (bulkId: number) => {
	const response = await request.post(`/api/cards/bulk-top-up/${bulkId}`);
	const { data } = response;
	return data;
};

export const getBulkTopUp = async (bulkId: number) => {
	const response = await request.get(`/api/cards/bulk-top-up/${bulkId}`);
	const { data } = response;
	return data;
};

export const cancelToken = async () => {
	if (cancelSource && cancelSource.token) cancelSource.cancel();
};

let socketService: SocketService;
let updateSubscriber: Subscription;
let removeSubscriber: Subscription;

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('cards');
	}
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		updateSubscriber = socketService.listen('card.data', {}).subscribe(() => {
			dispatch(getCards());
		});
		removeSubscriber = socketService.listen('card.removed', {}).subscribe((data) => {
			if (isEmpty(data)) return;
			dispatch(removeCard(data));
		});
	};
};

export const unsubscribe = (): void => {
	updateSubscriber.unsubscribe();
	removeSubscriber.unsubscribe();
};

export default slice.reducer;
