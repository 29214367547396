import { cancelMeeting, connect, getReservedTimeSlots, getUserReservation, scheduleMeeting, subscribe, unsubscribe } from '@/features/scheduleMeeting/slice';
import { showErrorNotification, showSuccessNotification } from '@/features/swal/slice';
import { convertDate } from '@/helpers/date';
import { RootState } from '@/rootReducer';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ScheduleMeeting, StartTimeEventEmit } from 'react-schedule-meeting';
import { Modal, Button, Select, Grid, Divider, Label, Icon } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface OnfidoStatusProps {
    onClose: () => void
}

const ScheduleVideoCall = ({ onClose }: OnfidoStatusProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translations');
    const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
    const [preferedLanguage, setPreferedLanguage] = useState<string>('en');
    const [reschedule, setReschedule] = useState<boolean>(false);

    const { booked, reservation } = useSelector(
        (state: RootState) => state.kycCalendar
    );

    useEffect(() => {
        connect();
        dispatch(subscribe());
        return () => {
            unsubscribe();
        };
    }, [dispatch]);


    const fetchBooked = useCallback(() => {
        const get = async () => {
            try {
                await dispatch(getReservedTimeSlots());
            } catch (e) {
                console.log(e);
            }
        };
        get();
    }, [dispatch]);

    useEffect(() => {
        fetchBooked();
    }, [fetchBooked]);

    const fetchReservation = useCallback(() => {
        const get = async () => {
            try {
                await dispatch(getUserReservation());
            } catch (e) {
                console.log(e);
            }
        };
        get();
    }, [dispatch]);

    useEffect(() => {
        fetchReservation();
    }, [fetchReservation]);


    const getAvailableDays = () => {
        const dateArray = [];
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        const end = new Date();
        end.setDate(end.getDate() + 61);
        while (currentDate <= end) {
            if (currentDate.getDay() !== 6 && currentDate.getDay() !== 0) {
                dateArray.push(new Date(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    };

    useEffect(() => {
        setAvailableTimeSlots(getAvailableTimeslots());
    }, [booked]);

    const getAvailableTimeslots = () => {
        const array = [];
        getAvailableDays().map((day, index) => {
            let start = day.setUTCHours(8, 0, 0, 0);
            let start2 = day.setUTCHours(14, 0, 0, 0);
            const finalEnd = day.setUTCHours(10, 0, 0, 0);
            const finalEnd2 = day.setUTCHours(16, 0, 0, 0);

            while ((start + 15 * 60 * 1000) < finalEnd) {
                const obj = {
                    id: start,
                    startTime: start,
                    endTime: start + 15 * 60 * 1000
                };
                if (booked.filter(f => parseInt(f) === start).length === 0) array.push(obj);



                start += 15 * 60 * 1000;
            }

            while ((start2 + 15 * 60 * 1000) < finalEnd2) {
                const obj = {
                    id: start2,
                    startTime: start2,
                    endTime: start2 + 15 * 60 * 1000
                };
                if (booked.filter(f => parseInt(f) === start2).length === 0) array.push(obj);



                start2 += 15 * 60 * 1000;
            }

            return array;
        });
        return array;
    };

    const onScheduleMeeting = async (startTime: StartTimeEventEmit) => {
        try {
            await scheduleMeeting(parseInt(startTime.availableTimeslot.id.toString()), preferedLanguage);
            setReschedule(false);
            fetchReservation();
            showSuccessNotification(t('kyc.schedule.notificationMeetingScheduled'));
        } catch (err) {
            showErrorNotification(err);
        }
    };

    const onCandelMeeting = async () => {
        try {
            await cancelMeeting(reservation.id);
            fetchReservation();
            showSuccessNotification(t('kyc.schedule.notificationMeetingCanceled'));
        } catch (err) {
            showErrorNotification(err);
        }
    };

    return (
        <Modal.Content>
            {reservation && !reschedule && <Grid centered>
                <Grid.Row>
                    {reservation.status === 'PENDING' && <div>{t('kyc.schedule.meetingPending', { date: convertDate(new Date(parseInt(reservation.appointmentId)), 'dd MMM yyyy HH:mm') })}</div>}
                    {reservation.status === 'APPROVED' && <Grid>
                        <Grid.Row centered style={{paddingBottom: '0'}}>
                            <div>{t('kyc.schedule.meetingApproved', { date: convertDate(new Date(parseInt(reservation.appointmentId)), 'dd MMM yyyy HH:mm') })}</div>
                        </Grid.Row>
                        <Grid.Row centered>
                            <CopyToClipboard text={reservation.link}>
                                <Label size='large'><a href={reservation.link}>{reservation.link}</a> <Icon style={{ cursor: 'pointer' }} name='clone outline' /></Label>
                            </CopyToClipboard>
                        </Grid.Row>
                    </Grid>}
                    {reservation.status === 'CANCELED' && <div>{t('kyc.schedule.meetingCanceled', { date: convertDate(new Date(parseInt(reservation.appointmentId)), 'dd MMM yyyy HH:mm') })}</div>}
                </Grid.Row>
                {reservation.status !== 'CANCELED' && <Grid.Row>
                    <Button primary onClick={onCandelMeeting}>{t('kyc.schedule.cancelMeetingBtn')}</Button>
                </Grid.Row>}
                {reservation.status === 'CANCELED' && <Grid.Row>
                    <Button primary onClick={() => setReschedule(true)}>{t('kyc.schedule.rescheduleNewBtn')}</Button>
                </Grid.Row>}
            </Grid>}




            {(!reservation || reschedule) && <><div style={{ textAlign: 'center' }}>
                <p>{t('kyc.schedule.preferedLanguage')}</p>
                <Select
                    options={[{ key: 'en', value: 'en', text: 'English' }, { key: 'it', value: 'it', text: 'Italiana' }, { key: 'fr', value: 'fr', text: 'Française' }, { key: 'es', value: 'es', text: 'España' }]}
                    value={preferedLanguage}
                    onChange={(e, v) => setPreferedLanguage(v.value.toString())}
                />
            </div>
                <ScheduleMeeting
                    borderRadius={10}
                    primaryColor="#3f5b85"
                    eventDurationInMinutes={15}
                    availableTimeslots={availableTimeSlots}
                    onStartTimeSelect={(startTime) => onScheduleMeeting(startTime)}
                    startTimeListStyle='scroll-list'
                    lang_cancelButtonText={t('kyc.schedule.cancelBtn')}
                    lang_confirmButtonText={t('kyc.schedule.confirmBtn')}
                />
            </>}
            <Divider />
            <Grid centered>
                <Grid.Row>
                    <Button onClick={() => { onClose(); setReschedule(false); }}>{t('form.buttons.close')}</Button>
                </Grid.Row>
            </Grid>
        </Modal.Content>
    );
};

export default ScheduleVideoCall;
