import React from 'react';
import { Container, Loader, Grid, Image } from 'semantic-ui-react';
import Swal from '../components/Swal';
import logo from '../assets/navbarLogo.svg';
import './LoadingPage.css';

const LoadingPage = (): React.ReactElement =>
	<Container fluid className="content-wrapper" id="loading">
		<Swal />
		<Grid id="login" verticalAlign="middle" centered stackable divided="vertically">
			<Grid.Column textAlign="center">
				<Image src={logo} className="logo" />
				<div className="ui hidden divider"></div>
				<Loader active inline='centered' size='large' />
			</Grid.Column>
		</Grid>
	</Container>;

export default LoadingPage;
