export const roundTwo = (num) => Math.round((num + Number.EPSILON) * 100) / 100;

/* rounds down */
export const trimDecimals = (num: string, precision: number) => {
	const valString = num;

	if (valString) {
		const valNum = parseFloat(num ?? '0');
		//round down
		const r = (Math.floor(valNum * Math.pow(10, precision)) / Math.pow(10, precision));

		return r;
	}
	else { return '0'; }
};


export const roundAny = (num: number, precision: number) => {
	const d = Math.pow(10, precision);
	const r = Math.round((num + Number.EPSILON) * d) / d;
	return r;
};

export const roundFlexible = (amount: number): string => {
	const valString = amount.toFixed(16);

	if (valString) {
		//all decimal zeros + 2 decimal places
		const precisionFirstTwoNumbers = valString.includes('.') && valString.match(/(\.0*)/)[0].length - 1 > 2 ? valString.match(/(\.0*)/)[0].length + 1 : 2;
		const precision = Math.max(precisionFirstTwoNumbers, 8);
		//convert to string and remove trailing zeros
		const newnumber = amount.toFixed(precision).replace(/\.?0+$/, '');
		return newnumber;
	}
	else { return '0'; }
};
