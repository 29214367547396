import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../store';
import { Transaction, TransactionStore } from './types';
import { SocketService } from '../../services/socketService';
import { Subscription } from 'rxjs';

const initialState: TransactionStore = {
	loading: false,
	list: [],
	error: null
};

const transactionSlice = createSlice({
	name: 'transaction',
	initialState,
	reducers: {
		setTransactions(state, action: PayloadAction<Array<Transaction>>) {
			state.list = action.payload;
		},
		setLoading: (state, { payload }: PayloadAction<boolean>) => {
			state.loading = payload;
		},
		setError: (state, { payload }: PayloadAction<string>) => {
			state.error = payload;
		}
	}
});

export const { setTransactions, setLoading, setError } = transactionSlice.actions;

export const transactionsSelector = (state: { transactionStore: TransactionStore }) =>
	state.transactionStore;

let socketService: SocketService;
let dataSubscriber: Subscription;
let updateSubscriber: Subscription;
let newSubscriber: Subscription;

function dataAdapter(data: any): any {
	return data;
}

function updateAdapter(data: any): any {
	return data;
}

function newAdapter(data: any): any {
	return data;
}

export const connect = (): void => {
	if (!socketService) {
		socketService = new SocketService('transactions');
	}
};

export const subscribe = (): AppThunk => {
	return async dispatch => {
		dispatch(setLoading(true));
		try {
			dataSubscriber = socketService.listen('dataR', []).subscribe((data) => {
				console.dir(data);
				dispatch(setTransactions(dataAdapter(data)));
				dispatch(setLoading(false));
			});
			updateSubscriber = socketService.listen('update', {}).subscribe((data) => {
				updateAdapter(data);
			});
			newSubscriber = socketService.listen('new', {}).subscribe((data) => {
				newAdapter(data);
			});
			socketService.send('data');
		} catch (error) {
			dispatch(setError(error));
			dispatch(setLoading(false));
		}
	};
};

export const unsubscribe = (): void => {
	dataSubscriber.unsubscribe();
	updateSubscriber.unsubscribe();
	newSubscriber.unsubscribe();
};

export default transactionSlice.reducer;
