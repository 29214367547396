import React, { useEffect, useState } from 'react';
import { Button, Divider, Grid, Segment, Input as SEInput } from 'semantic-ui-react';
import '@/components/Dashboard/MyContacts.css';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Form, Input, SubmitButton } from 'formik-semantic-ui-react';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { ContactTypes } from './CreateContact';
import { addContact, getDataByIban } from '@/features/contacts/slice';
import { showErrorNotification } from '@/features/swal/slice';
import { useHistory } from 'react-router';
import { getNames, getAlpha3Code } from 'i18n-iso-countries';
import { electronicFormatIBAN, isValidIBAN } from 'ibantools';
import { RootState } from '@/rootReducer';


const NewSepaContact = (): React.ReactElement => {
	const { t } = useTranslation(['translations']);
	const dispatch = useDispatch();
	const history = useHistory();
	const [countries] = useState(getNames('en'));
	const [iban, setIban] = useState<string>('');
	const [onBlurIban, setOnBlurIban] = useState<string>('');
	const [isValid, setIsValid] = useState<boolean>(false);
	const [contactName, setContactName] = useState<string>('');
	const [identificationNo, setIdentificationNo] = useState<string>(null);
	const [beneficiaryData, setBenificiaryData] = useState<any>();
	const [isToBusiness, setIsToBusiness] = useState<boolean>(false);

	const { contacts } = useSelector(
		(state: RootState) => state.contacts
	);


	useEffect(() => {
		if (isValid) {
			const getIbanData = async () => {
				const beneficiaryData = await getDataByIban(iban);
				setBenificiaryData(beneficiaryData);
			};
			getIbanData();
		}
		else {
			setBenificiaryData(null);
		}

	}, [isValid]);

	const handleIbanChange = (...args) => {
		const iban = electronicFormatIBAN(args[1].value);
		setIban(iban);
	};

	const validationSchema = Yup.object({
		name: Yup.string().required(t('form.validator.required')),
		account: Yup.string().required(t('form.validator.required'))
			.test('already',
				t('sendmoneyTranslation.data.alreadyInContacts'),
				(value) => isNotInContacts(value))
			.test('account',
				t('sendmoneyTranslation.data.invalidIban'),
				(value) => {
					//simulate blur, formik semantic input does not support  onBlur 
					setOnBlurIban(value);
					if (!!value && value !== onBlurIban) {
						const tmp = isValidIBAN(electronicFormatIBAN(value));
						setIsValid(tmp);
						return tmp;
					} else {
						return isValid;
					}
				})
	});

	const isNotInContacts = (account: string) => {
		const alreadyInContacts = contacts.filter(entry => entry.paymentType === ContactTypes.SEPA && entry.account === account);

		if (alreadyInContacts.length > 0) {
			return false;
		} else {
			return true;
		}
	};

	const submit = async (formData, formikProps) => {
		const { setSubmitting } = formikProps;
		try {
			const { name, account } = formData;

			await dispatch(addContact({
				name, paymentType: ContactTypes.SEPA, account,
				bank: beneficiaryData?.bank ?? '',
				address: beneficiaryData?.address ?? '',
				country: beneficiaryData?.country ? getAlpha3Code(countries[beneficiaryData.country], 'en') : '',
				identification: identificationNo,
				bic: beneficiaryData?.bic ?? '',
				business: isToBusiness
			}));
			history.push('/wallet/contacts');
		} catch (error) { showErrorNotification(error); }
		finally { setSubmitting(false); }
	};



	return (
		<Grid id='createContact' container>
			<Grid.Column width={16}>
				<Segment padded>
					<Formik
						initialValues={{
							identificatio: identificationNo,
							name: contactName,
							account: iban
						}}
						validationSchema={validationSchema}
						onSubmit={submit}
						validateOnBlur={true}
						validateOnChange={false}
					>{({ errors, isSubmitting }) => (<Form
						size="large">
						<div className='field'>
							<label>{t('contacts.create.beneType')}</label>
							<Button.Group>
								<Button type='button' active={!isToBusiness} content={t('contacts.create.individual')} onClick={() => setIsToBusiness(false)} primary={!isToBusiness} />
								<Button.Or />
								<Button type='button' active={isToBusiness} content={t('contacts.create.business')} onClick={() => setIsToBusiness(true)} primary={isToBusiness} />
							</Button.Group>
						</div>
						<Input
							label={isToBusiness ? t('contacts.create.companyName') : t('contacts.create.name')}
							fluid
							name="name"
							placeholder={isToBusiness ? 'Company LTD' : 'John Doe'}
							onChange={(...args) => setContactName(args[1].value)}
							errorPrompt
						/>
						<Input
							label={t('contacts.create.iban')}
							fluid
							name="account"
							onChange={handleIbanChange}
							errorPrompt
						/>
						<Input
							label={t('contacts.create.bank')}
							fluid
							value={beneficiaryData?.bank ?? ''}
							name="bank"
							disabled
						/>
						<Input
							label={t('contacts.create.country')}
							name='country'
							value={beneficiaryData?.country ?? ''}
							fluid
							disabled
						/>
						<Input
							label={t('contacts.create.address')}
							fluid
							value={beneficiaryData?.address ?? ''}
							name="address"
							disabled
						/>
						<Input
							label={t('contacts.create.bicSwift')}
							fluid
							name="bic"
							value={beneficiaryData?.bic ?? ''}
							disabled
						/>
						<Input
							label={isToBusiness ? t('contacts.create.companyRegNumber') : t('sendmoneyTranslation.activedata.identity')}
							fluid
							name="identification"
							onChange={(...args) => setIdentificationNo(args[1].value)}
						/>
						<Divider section />
						<SubmitButton
							disabled={isSubmitting || !isEmpty(errors)}
							primary fluid type="submit">
							{t('contacts.create.save')}
						</SubmitButton>
					</Form>)}
					</Formik>
				</Segment>
			</Grid.Column>
		</Grid>
	);

};
export default NewSepaContact;



