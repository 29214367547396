export enum KycStatus {
	CREATED = 'CREATED',
	PENDING = 'PENDING',
	VERIFIED = 'VERIFIED',
	IN_PROGRESS = 'IN_PROGRESS',
	REJECTED = 'REJECTED',
	PAID = 'PAID'
}

export class KycTier {
	public tier: number;
	public status: KycStatus;
	public createdDate: Date;
	public updatedDate: Date;
	public rejectionReason: string;
	public final: boolean;
	public checkCount: number;
	public video: boolean;
	public expiredInMonth: boolean;
}
