export interface Card {
	name: string,
	walletId: string, 
	accountId: number,
	cardId: number,
	number: string,
	balance: number,
	balanceOnHold: number,
	availableBalance?: number,
	currency: string,
	status: CardStatuses,
	expirationDate?: string,
	detail?: any,
	providerType?: string,
	logo: string,
	logoTextColor?: string,
	cardholder?: string
	deliveryAddress: {
		country: string,
		city: string,
		address: string,
		zip: string
	}
	assignedTo?: {
		employeeId: number,
		firstName: string,
		lastName: string,
		businessId: number,
		company: string
	},
	phone: string,
	virtual: boolean
}

export interface CardsStore {
	loading: boolean,
	list: Array<Card>,
	error: string
}

export enum CardType {
	PHYSICAL, VIRTUAL
}

export enum CardStatuses {
	REQUESTED = 'REQUESTED',
	PENDING = 'PENDING',
	ORDERED = 'ORDERED',
	ORDER_COMPLETED = 'ORDER_COMPLETED',
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	SUSPENDED = 'SUSPENDED',
	ACTIVE = 'ACTIVE',
	ACTIVATING = 'ACTIVATING',
	INACTIVE = 'INACTIVE',
	LOST = 'LOST',
	STOLEN = 'STOLEN',
	EXPIRED = 'EXPIRED',
    FROZEN = 'FROZEN'
}

export enum CardSubProcesses {
	CARD_ACTIVATE = 'CARD_ACTIVATE',
	CARD_GET_CVV = 'CARD_GET_CVV',
	CARD_GET_PIN = 'CARD_GET_PIN',
	CARD_PULL_OUT = 'CARD_PULL_OUT',
	CARD_SUSPEND = 'CARD_SUSPEND',
	CARD_TOP_UP = 'CARD_TOP_UP',
	CARD_UPDATE_PASSPHRASE = 'CARD_UPDATE_PASSPHRASE',
	CARD_UPDATE_PHONE = 'CARD_UPDATE_PHONE'
}

export interface BulkEntry {
	id: number,
	amount: number,
	employeeName: string,
	employeeRef: string,
	cardName: string,
	sessionId: number,
	userID: number,
	selectedCardId: number,
	cardSelection: {
		id: number,
		cardName: string
	}[]
	error: BulkError
}

export enum BulkError {
	INACTIVE_USER = 'INACTIVE_USER',
	INACTIVE_CARD = 'INACTIVE_CARD',
	NO_CARD = 'NO_CARD',
	MULTIPLE_CARDS = 'MULTIPLE_CARDS',
	DONT_BELONG_TO_COMPANY = 'DONT_BELONG_TO_COMPANY',
	TECHNICAL = 'TECHNICAL'
}
