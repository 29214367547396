import React, { ReactNode } from 'react';
import './Services.css';
import { Tab } from 'semantic-ui-react';
import { matchPath, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/rootReducer';
import { UserType } from '@/features/user/types';
import { Agents } from '@/helpers/globalTypes';

interface Props {
	panes: {
		menuItem: {
			as: React.ReactElement,
			content: string,
			to: string,
			exact: boolean,
			key: string
		},
		render: () => ReactNode
	}[]
}

const ServicesTabs = ({ panes }: Props): React.ReactElement => {

	const { path } = useRouteMatch();
	const { user } = useSelector(
		(state: RootState) => state.user
	);
	const { agent } = useSelector(
		(state: RootState) => state.status
	);
	const index = panes.findIndex(pane => {
		return !!matchPath(window.location.pathname, {
			path: pane.menuItem.to,
			exact: true
		});
	});

	return (
		<Switch>
			{panes.length === 1 && <Redirect path={`${path}`} exact to={`${path}/inbox`}/>}
			{(user && user.type === UserType.INDIVIDUAL && agent === Agents.AZURE) && <Redirect path={`${path}`} exact to={`${path}/verification`}/>}
			{agent === Agents.DTSUS && <Redirect path={`${path}`} exact to={`${path}/trust`}/>}
			{(user && user.type === 'EMPLOYEE') && <Redirect path={`${path}`} exact to={`${path}/cards`}/>}
			{(user && user.type !== 'EMPLOYEE') && <Redirect path={`${path}`} exact to={`${path}/accounts`}/>}
			<Tab className="services" defaultActiveIndex={index} menu={{ secondary: true, pointing: true }} panes={panes}  />
		</Switch>
	);
};

export default ServicesTabs;
