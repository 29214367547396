import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { confirmEmail } from '@features/user/userSlice';
import { getErrorWithParams, openModal, showErrorNotification } from '@features/swal/slice';
import {
	useParams, Route, Redirect
} from 'react-router-dom';
import LoadingPage from '../../pages/LoadingPage';
import { useTranslation } from 'react-i18next';
import { getRecaptureToken } from '@/helpers/recaptureUtils';

interface ParamTypes {
	code: string
}

const Email = (): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const { code } = useParams<ParamTypes>();
	const [loading, setLoading] = useState<boolean>(true);
	const [token, setToken] = useState<string>();

	useEffect(() => {
		async function confirm() {
			try {
				const recaptchaToken = await getRecaptureToken();
				setToken(token);
				if (recaptchaToken) {
					await dispatch(confirmEmail(code, recaptchaToken));
					dispatch(openModal({ header: t('swal.CONFIRM_EMAIL'), content: t('swal.CONFIRM_EMAIL_TEXT') }));
				}
			} catch (e) {
				const err = getErrorWithParams(e);
				if (err && err.error === 'invalid' && err.error_param === 'code') {
					dispatch(openModal({ header: 'Confirmation error', content: 'Sorry, you provided wrong confirmation link.' }));
				} else if (err && err.error === 'alreadyConfirmed' && err.error_param === 'code') {
					dispatch(openModal({ header: 'Confirmation', content: 'Your email already confirmed, please try to login.' }));
				} else {
					showErrorNotification(e);
				}
			} finally {
				setLoading(false);
			}
		}
		confirm();
	}, [dispatch, token]);

	if (loading) return <LoadingPage />;
	return (
		<div> <Route><Redirect to={'/wallet'} /> </Route>
		</div >);
};

export default Email;
