import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RootState } from '@/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Dropdown, Grid, Icon, List, Loader, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios';
import { cancelToken, getAccount } from '@features/account/slice';
import { setAccount } from '@features/tabs/slice';
import './TrustAccountDetails.css';
import AccountError from './Shared/AccountError';
import NumberFormat from 'react-number-format';
import { SendMoneyIcon } from '@icons/index';
import { Agents } from '@/helpers/globalTypes';
import { TrustAccount } from '@features/trust/types';
import { UserType } from '@/features/user/types';

const extractSubprocesses = (subProcesses) => {
	return Object.values(subProcesses).map(value => value['type']);
};

type Props = {
	trustAccount: TrustAccount
}

const TrustAccountDetails = ({ trustAccount }: Props): React.ReactElement => {
	const { t } = useTranslation('translations');
	const dispatch = useDispatch();
	const history = useHistory();
	const [config, setConfig] = useState<any>(null);

	const { agent } = useSelector(
		(state: RootState) => state.status
	);

	const { accounts } = useSelector(
		(state: RootState) => state.trust
	);

	const { status, user } = useSelector(
		(state: RootState) => state.user
	);

	const [subProcesses, setSubProcesses] = useState<any>([]);
	const [showBankDetails, setShowBankDetails] = useState<boolean>(true);
	const [, setSource] = useState<any>(null);
	const [error, setError] = useState<string>(null);
	const [dropdownRef] = useState<any>(React.createRef());
	const [, setMobileMenuOpen] = useState<boolean>(false);
	const [hideCreateAccount, setHideCreateAccount] = useState<boolean>(false);

	const getDetails = useCallback(() => {
		setError(null);
		cancelToken();
		const get = async () => {
			setSubProcesses([]);
			const CancelToken = axios.CancelToken;
			const source = CancelToken.source();
			setSource(source);
			try {
				const detail = await getAccount(trustAccount.accountId, source);
				setSubProcesses(extractSubprocesses(detail.subProcesses));
				setConfig(detail.config);
				setError(null);
			}
			catch (e) {
				if (e && e.data) {
					setError(e.data.message);
				}
			}
		};
		get();
	}, [trustAccount.accountId]);

	useEffect(() => {
		getDetails();
	}, [getDetails]);

	useEffect(() => {
		if (user.type !== UserType.BUSINESS || user.onBehalfOf === null) {
			if (!user.accountSettings.unlimited && (accounts.length >= user.accountSettings.maxCount)) {
				setHideCreateAccount(true);
			}
		}
	}, []);

	useEffect(() => {
		if (status.source === Agents.WALLEXCARD && trustAccount.providerType === 'POOLING') {
			setShowBankDetails(false);
		} else {
			setShowBankDetails(true);
		}
	}, [trustAccount.accountId]);

	const isSendMoneyEnabled = () => {
		if (subProcesses.indexOf('PAYMENT') !== -1) return true;
		return subProcesses.indexOf('TRANSFER') !== -1;
	};

	if (error) return <AccountError message={error} />;

	return (
		<div id="bankaccountdetails">
			<Grid padded>
				<Grid.Row className="mobileaccountdetailsRow" only="mobile">
					<Grid.Column>
						<Segment className="myaccountsegment" >
							<Dropdown
								ref={dropdownRef}
								value={trustAccount.accountId}
								closeOnChange
								as={() => {
									return (<div style={{ width: '100%' }} id="mobile-account-dropdown" onClick={() => {
										console.log('click');
										setMobileMenuOpen(true);
									}}>
										<div>
											<div className="tab-name">{trustAccount.name}</div>
											<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${trustAccount.currency} `} value={trustAccount.balance} /></div>
											<div className="tab-hold">{t('cards.balanceOnHold')}<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${trustAccount.currency} `} value={trustAccount.balanceOnHold} /></div>
										</div>
										<Dropdown fluid icon='chevron down'>
											<Dropdown.Menu fluid>
												{accounts.map(account => <Dropdown.Item fluid onClick={() => {
													setMobileMenuOpen(false);
													dispatch(setAccount(account));
												}} key={account.accountId}>
													<div style={{ width: '100%' }}>
														<div>
															<div className="tab-name">{account.name}</div>
															<div className="tab-value"><NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${account.currency} `} value={account.balance} /></div>
															<div className="tab-hold">{t('cards.balanceOnHold')}<NumberFormat displayType={'text'} decimalScale={2} fixedDecimalScale={true} thousandsGroupStyle='thousand' thousandSeparator={true} prefix={`${account.currency} `} value={account.balanceOnHold} /></div>
														</div>
													</div>
												</Dropdown.Item>)}
											</Dropdown.Menu>
										</Dropdown>
									</div>);
								}} >
							</Dropdown>
						</Segment>
					</Grid.Column>
				</Grid.Row>
				{/* Mobile only buttons */}
				<Grid.Row only="mobile">
					<Grid.Column className="recievemoneyClmn" width="8">
						{/* <Button className="recievebuttonmobile" fluid basic as={Link} to={`/wallet/transfers/request?account=${trustAccount.accountId}`} >
							<RequestMoneyIcon />
							<br />
							<Button.Content>{t('banking.data.requestmoneybutton')}</Button.Content>
						</Button> */}
					</Grid.Column>
					<Grid.Column className="sendmoneyClmn" width="8">
						{isSendMoneyEnabled() && <Button className="sendbuttonmobile" fluid basic as={Link} disabled={!isSendMoneyEnabled()} to={`/wallet/transfers/trustee?account=${trustAccount.accountId}`}>
							<SendMoneyIcon />
							<br />
							<Button.Content>{t('trusts.sendMoneyButton')}</Button.Content>
						</Button>}
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="walletidBTNRow" only="mobile">
					<Grid.Column>
						<CopyToClipboard id="wallet-clipboard-button" text={trustAccount.walletId}>
							<Button basic primary onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
							}} icon="clone outline">{t('banking.data.walletid')} {trustAccount.walletId}</Button>
						</CopyToClipboard>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row className="walletidBTNRow" only="mobile">
					{!hideCreateAccount && <Grid.Column>
						<Button labelPosition="left" icon="plus circle" primary fluid content={t('create.createNewAccount')} onClick={() => history.push('/wallet/create/account')} />
					</Grid.Column>}
				</Grid.Row>
				{/* Tablet and computer only buttons */}
				<Grid.Row only="computer tablet">
					<Grid.Column width={11}>
						{/* <Button as={Link} to={`/wallet/transfers/request?account=${trustAccount.accountId}`} className="buttonmargin" secondary content={t('banking.data.requestmoneybutton')} icon='plus' labelPosition='left' /> */}
						{isSendMoneyEnabled() && <Button as={Link} to={`/wallet/transfers/trustee?account=${trustAccount.accountId}`} disabled={!isSendMoneyEnabled()} className="buttonmargin" primary content={agent === Agents.DTSUS ? t('trusts.sendMoneyButtonDtsus') : t('trusts.sendMoneyButton')} icon='right arrow' labelPosition='left' />}
					</Grid.Column>
					<Grid.Column width={4} floated="right">
						<List relaxed="very" verticalAlign='middle'>
							<List.Item className='walletIDItem'>
								<List.Header>{t('banking.data.walletid')}</List.Header>
								<List.Content>
									{trustAccount.walletId}
									<CopyToClipboard id="wallet-clipboard-button" text={trustAccount.walletId}>
										<Button basic primary onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
										}} icon="clone outline" />
									</CopyToClipboard>
								</List.Content>
							</List.Item>
						</List>
					</Grid.Column>
				</Grid.Row>
				{showBankDetails && <>
					{agent !== Agents.DTSUS && <Grid.Row>
						<Grid.Column>
							<Grid className='info-notification' columns={2}>
								<Grid.Row>
									<Grid.Column style={{ width: 'fit-content', paddingRight: '0' }} only='computer tablet'>
										<Icon size='large' name='info circle' color='blue' />
									</Grid.Column>
									<Grid.Column width={16} only='mobile' style={{ display: 'flex', justifyContent: 'center', paddingBottom: '1rem' }}>
										<Icon size='large' name='info circle' color='blue' />
									</Grid.Column>
									<Grid.Column width={15}>
										<span dangerouslySetInnerHTML={{ __html: t('trusts.text') }} />
										<br />
										<Divider hidden />
										<span dangerouslySetInnerHTML={{ __html: t('trusts.important') }} />
									</Grid.Column>
								</Grid.Row>
							</Grid>
						</Grid.Column>
					</Grid.Row>}


					<Grid className='bacnkaccountDataGrid' container stackable>
						<Grid.Column widescreen={6}>
							<List className="bankingList" relaxed="very" verticalAlign='middle'>
								<List.Item>
									<List.Header>{t('banking.data.name')}</List.Header>
									<div className="accountdetailsLI">{trustAccount?.recipient}</div>
								</List.Item>
								<List.Item>
									<List.Header>{t('banking.data.bank')}</List.Header>
									<div className="accountdetailsLI">{trustAccount.bankName || <Loader active inline size="mini" />}</div>
								</List.Item>
							</List>
						</Grid.Column>
						<Grid.Column widescreen={6}>
							<List className="bankingList" relaxed="very" verticalAlign='middle'>
								{config && !config.usTrust && <List.Item>
									<List.Header>{t('banking.data.iban')}</List.Header>
									<div className="accountdetailsLI">{trustAccount.iban || <Loader active inline size="mini" />}</div>
								</List.Item>}
								{config && config.usTrust && <List.Item>
									<List.Header>{t('banking.data.accountNr')}</List.Header>
									<div className="accountdetailsLI">{config.accountNumber || <Loader active inline size="mini" />}</div>
								</List.Item>}
								<List.Item>
									<List.Header>{t('banking.data.reference')}</List.Header>
									<div className="accountdetailsLI">{config?.referenceDetails ? `${config?.referenceDetails}, ${trustAccount.walletId}` : trustAccount.walletId}</div>
								</List.Item>
							</List>
						</Grid.Column>
						<List className="bankingList" relaxed="very" verticalAlign='middle'>
							{config && config.usTrust && <List.Item>
								<List.Header>{t('banking.data.routingNr')}</List.Header>
								<div className="accountdetailsLI">{config.routingNumber || <Loader active inline size="mini" />}</div>
							</List.Item>}
							<List.Item>
								<List.Header>{t('banking.data.swift/bic')}</List.Header>
								<div className="accountdetailsLI">{trustAccount.swift || <Loader active inline size="mini" />}</div>
							</List.Item>
						</List>
					</Grid>
				</>}
			</Grid>
		</div >
	);
};

export default TrustAccountDetails;
