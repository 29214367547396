import React, { useState } from 'react';
import { Breadcrumb, Button, Icon, Message, Table, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Token } from '@/features/tokens/types';
import NumberFormat from 'react-number-format';
import SymbolOverview from '@/components/Stocks/Components/SymbolOverview';
import { disableSell } from '../Tokens';
import { trimDecimals } from '@/helpers/round';

interface Props {
	token: Token,
	onTradeToken: () => void,
	onSendToken: () => void
}

const TokenInformation = ({ token, onTradeToken, onSendToken }: Props): React.ReactElement => {

	const [showDetails, setShowDetails] = useState(false);
	const { t } = useTranslation('translations');

	const sellDisabled = disableSell(token);

	return (
		<Table.Body>
			<Table.Row>
				<Table.Cell>
					<div className='token-name-cell'>
						<Image className='token-avatar' avatar src={token.logo} />
						<div className='token-name-block'>
							<div className='token-bold'>{token.name}</div>
							<div>{token.symbol}</div>
						</div>
					</div>
				</Table.Cell>
				<Table.Cell>
					<strong>
						<NumberFormat displayType={'text'}
							decimalScale={2}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={`${token.currency} `} value={token.price} />
					</strong>
				</Table.Cell>
				<Table.Cell>
					<strong>
						<NumberFormat displayType={'text'}
							decimalScale={2}
							fixedDecimalScale={true}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={`${token.currency} `} value={token.sellPrice} />
					</strong>
				</Table.Cell>
				<Table.Cell>
					{token.availableHoldings && trimDecimals(token.availableHoldings, token.precision) > 0 &&
						<NumberFormat displayType={'text'}
							fixedDecimalScale={false}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={`${token.symbol} `}
							value={trimDecimals(token.availableHoldings, token.precision)} />}
				</Table.Cell>
				<Table.Cell>
					{token.holdingsOnHold && trimDecimals(token.holdingsOnHold, token.precision) < 0 &&
						<NumberFormat displayType={'text'}
							fixedDecimalScale={false}
							thousandsGroupStyle='thousand'
							thousandSeparator={true}
							prefix={`${token.symbol} `}
							value={trimDecimals(Math.abs(parseFloat(token.holdingsOnHold)).toString(), token.precision)}
						/>}
				</Table.Cell>
				<Table.Cell>
					{(token.buyEnabled || token.sellEnabled) && <Button primary content={t('tokens.tokenActions.TRADE')}
						onClick={onTradeToken}
						disabled={!token.buyEnabled && !token.sellEnabled} />}
				</Table.Cell>
				<Table.Cell>
					{token.sendEnabled && <Button content={t('tokens.tokenActions.SEND')}
					onClick={onSendToken}
					disabled={sellDisabled || !token.sendEnabled} />}
				</Table.Cell>
				<Table.Cell>
					<Breadcrumb size="large">
						<Breadcrumb.Section onClick={() => setShowDetails(!showDetails)}><Icon
							name={showDetails ? 'chevron down' : 'chevron right'} /></Breadcrumb.Section>
					</Breadcrumb>
				</Table.Cell>
			</Table.Row>
			{showDetails &&
				<Table.Row>
					{token.tradingView &&
						<Table.Cell colSpan={3}>
							<SymbolOverview widgetProps={{
								symbols: [
									[
										'KRAKEN:' + token.symbol + token.currency
									]
								],
								chartOnly: true
							}} containerKey={'KRAKEN:' + token.symbol + token.currency} />
						</Table.Cell>}
					<Table.Cell colSpan={token.tradingView ? 5 : 8}>
						<Message >
							{token.website &&
								<>
									<div className='token-bold'>{t('tokens.header.website')}</div>
									<div>{token.website}</div>
									<br />
								</>
							}
							<div className='token-bold'>{t('tokens.header.description')}:</div>
							<br />
							<div className='display-linebreak'>{token.description}</div>
						</Message>
					</Table.Cell>
				</Table.Row>
			}
		</Table.Body>
	);
};

export default TokenInformation;
